import * as React from 'react';
import styled from 'styled-components';
import {AlbumFileComment} from '../../state/album/selectors';
import {isMobileDevice} from '../../utilities/device';
import {CommentInputComponent} from './CommentInputComponent';
import {CommentsListComponent} from './CommentsListComponent';

type Props = {
    fileID: FileID,
    albumID: JobID,
    comments: AlbumFileComment[],
    commentsCanBeAdded: boolean,
    isPendingNewComment: boolean,
};

const CommentsWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
`;

const CommentsListWrapper = styled.div`
    flex: 1;
    padding: 16px 16px 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
`;

export class CommentsComponent extends React.Component<Props> {
    private list = React.createRef<HTMLDivElement>();
    private doScrollToBottomOnUpdate = false;

    public componentDidMount() {
        if (this.list.current) {
            this.list.current.scrollTop = this.list.current.scrollHeight;
            if (isMobileDevice.iOS()) {
                this.list.current.addEventListener('touchmove', this.handleScroll);
            } else if (isMobileDevice.any()) {
                this.list.current.addEventListener('scroll', this.handleScroll);
            } else {
                this.list.current.addEventListener('mousewheel', this.handleScroll);
            }
        }
    }

    public componentWillReceiveProps(newProps: Props) {
        if (newProps.isPendingNewComment) {
            this.doScrollToBottomOnUpdate = true;
        }
        if (this.list.current && newProps.comments.length > this.props.comments.length) {
            const didCurrentUserComment = newProps.comments.reduce((_a, b) => b).currentUserCan.editComment;
            const amAtBottom: boolean
                = this.list.current.scrollTop === this.list.current.scrollHeight - this.list.current.clientHeight;
            if (didCurrentUserComment || amAtBottom) {
                this.doScrollToBottomOnUpdate = true;
            }
        }
    }

    public componentDidUpdate() {
        if (this.list.current && this.doScrollToBottomOnUpdate) {
            this.list.current.scrollTop = this.list.current.scrollHeight;
            this.doScrollToBottomOnUpdate = false;
        }
    }
    private handleScroll = (e: Event) => {
        e.stopPropagation();
    }

    public render() {
        let comments = null;
        if (this.props.commentsCanBeAdded) {
            comments = (
                <CommentInputComponent
                    albumID={this.props.albumID}
                    fileID={this.props.fileID}
                    maxHeight={216}
                />
            );
        }
        return (
            <CommentsWrapper>
                <CommentsListWrapper innerRef={this.list}>
                    <CommentsListComponent
                        showNumberOfComments={this.props.comments.length}
                        comments={this.props.comments}
                        isPendingNewComment={this.props.isPendingNewComment}
                    />
                </CommentsListWrapper>
                {comments}
            </CommentsWrapper>
        );
    }
}
