import styled from 'styled-components';
import {colors, fontSize} from '../../assets/styleConstants';

type TextProps = {
    fontSize?: string,
};

export const TextInput = styled.input`
    font-size: ${(props: TextProps) => props.fontSize || fontSize.medium_16};
    color: ${colors.captureGrey800};
    outline: none;
    appearance: none;
    border: 0;
    padding: 4px 0;
    width: 100%;

    &::placeholder {
        color: ${colors.captureGrey300};
    }
`;

// TODO: Replace withComponent solution when styled-components issue #1315
// is solved by https://github.com/styled-components/styled-components/pull/1739
/* export const TextArea = TextInput.withComponent('textarea').extend`
    resize: none;
`; */

export const TextArea = styled.textarea`
    font-size: ${(props: TextProps) => props.fontSize || fontSize.medium_16};
    color: ${colors.captureGrey800};
    outline: none;
    appearance: none;
    border: 0;
    padding: 4px 0;
    width: 100%;

    &::placeholder {
       color: ${colors.captureGrey300};
    }

    resize: none;
`;
