import * as React from 'react';

export const AlbumIllustration: React.SFC = () => (
    <svg width="298px" height="199px" viewBox="0 0 298 199" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(18.000000, 0.000000)">
            <path d="M130.795848,93.9151564 C130.795848,81.650656 164.813579,71.6955017 203.460208,71.6955017 L203.460208,176.396262 C164.813579,176.396262 130.795848,186.351417 130.795848,198.615917 C130.795848,186.351417 96.7781162,176.396262 58.1314879,176.396262 L58.1314879,71.7267091 C96.7781162,71.7267091 130.795848,81.6818634 130.795848,93.9463638 L130.795848,93.9151564 Z M130.795848,93.9151564 L130.795848,197.804525 L130.795848,93.9151564 Z" stroke="#FFFFFF" strokeWidth="2.8" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M138.334291,97.079263 C141.909,94.6812648 145.821833,92.8137322 149.947468,91.5364791 C153.990596,90.1215035 158.135272,89.0058741 162.346954,88.1988888 C162.930498,88.0925702 163.531795,88.2476204 163.987685,88.6219673 C164.443575,88.9963141 164.70678,89.5511354 164.70588,90.1358832 L164.70588,120.740394 C164.707341,121.689831 164.020261,122.504764 163.072777,122.677388 C159.025403,123.431226 155.042055,124.4872 151.157174,125.836179 C147.500326,127.072332 143.967104,128.638507 140.60249,130.514766 C139.983906,130.881138 139.212786,130.88993 138.585764,130.537759 C137.958743,130.185589 137.573498,129.527319 137.578225,128.816171 L137.578225,98.6586584 C137.566235,98.0447657 137.84589,97.4605775 138.334291,97.079263 Z" fill="#FFFFFF" fillRule="nonzero" />
            <path d="M138.954784,97.8809844 C138.698781,98.0654452 138.546546,98.3658625 138.546721,98.6862418 L138.546721,128.838658 C138.545289,129.187956 138.724901,129.511862 139.018678,129.689769 C139.312455,129.867675 139.676378,129.872925 139.97494,129.703564 C143.272919,127.789986 146.736763,126.192554 150.322236,124.931668 C154.123623,123.566252 158.02123,122.499424 161.981161,121.740463 C162.432384,121.655139 162.761874,121.255562 162.768138,120.786084 L162.768138,90.1266539 C162.770443,89.8168644 162.630064,89.524045 162.389234,89.3362937 C162.148405,89.1485425 161.835237,89.0877753 161.543951,89.1722748 C157.584907,89.9615081 153.688055,91.0480658 149.885026,92.4231287 C146.000095,93.6849845 142.317374,95.523897 138.954784,97.8809844 Z" stroke="#FFFFFF" strokeWidth="0.66" />
            <path d="M138.451051,141.646737 C142.006323,139.227859 145.902004,137.340161 150.012811,136.044353 C154.039652,134.649588 158.165949,133.553888 162.357398,132.766363 C162.938359,132.660044 163.536993,132.815094 163.990865,133.189441 C164.444737,133.563788 164.706776,134.118609 164.70588,134.703357 L164.70588,165.307868 C164.707334,166.257305 164.023297,167.072238 163.080008,167.244862 C159.050553,167.9987 155.084841,169.054674 151.217161,170.403653 C147.535195,171.632972 143.97701,173.199275 140.588772,175.08224 C139.972927,175.448612 139.205222,175.457404 138.580976,175.105234 C137.95673,174.753063 137.573191,174.094793 137.577897,173.383645 L137.577897,143.226132 C137.594118,142.590986 137.919338,142.002715 138.451051,141.646737 Z" fill="#FFFFFF" fillRule="nonzero" />
            <path d="M138.954772,141.360457 C138.698777,141.544917 138.546546,141.845333 138.546721,142.165711 L138.546721,172.437278 C138.545289,172.786575 138.724896,173.110479 139.018665,173.288385 C139.312434,173.466291 139.676346,173.471541 139.9749,173.30218 C143.272787,171.388612 146.736536,169.791187 150.321909,168.530308 C154.12319,167.164898 158.020689,166.098075 161.98051,165.339118 C162.431721,165.253794 162.761201,164.854219 162.767466,164.384743 L162.767466,133.755285 C162.779207,133.440303 162.642796,133.138624 162.400572,132.943879 C162.158348,132.749135 161.83962,132.684891 161.543312,132.771087 C157.584378,133.560316 153.687635,134.646869 149.884711,136.021925 C146.006507,137.246611 142.324219,139.045158 138.954772,141.360457 Z" stroke="#FFFFFF" strokeWidth="0.66" />
            <path d="M170.02901,88.2051391 C173.790003,86.9382362 177.641735,85.9555757 181.551387,85.2655303 C185.922782,84.4512573 190.374505,84.1454445 194.816893,84.3542516 C195.859052,84.4014327 196.679278,85.2563984 196.6782,86.2943934 L196.6782,117.336662 C196.6782,118.408173 195.805181,119.276804 194.728259,119.276804 L194.521447,119.276804 C190.634284,118.891003 186.713911,118.989754 182.851347,119.570765 C178.867689,120.154427 174.921695,120.969106 171.033525,122.01064 C170.447049,122.166195 169.821216,122.041156 169.340558,121.672391 C168.859901,121.303626 168.579238,120.733193 168.581326,120.12929 L168.581326,90.0276965 C168.604628,89.1680991 169.193834,88.4263185 170.02901,88.2051391 Z" fill="#FFFFFF" fillRule="nonzero" />
            <path d="M170.207917,89.0913374 C169.814899,89.2280728 169.551626,89.5931341 169.550202,90.0033451 L169.550202,120.129019 C169.547837,120.434604 169.691823,120.72345 169.93884,120.908653 C170.185857,121.093857 170.507071,121.153799 170.80584,121.070446 C174.740752,120.030018 178.733591,119.21471 182.764304,118.628619 C186.722816,118.044947 190.739563,117.946129 194.722768,118.334423 C195.267637,118.334423 195.709341,117.89976 195.709341,117.363576 L195.709341,86.2964751 C195.710301,85.7824533 195.303949,85.3567802 194.78256,85.3256282 C190.357979,85.1141758 185.923425,85.4103658 181.568458,86.2082163 C177.714145,86.8904667 173.916802,87.8541709 170.207917,89.0913374 Z" stroke="#FFFFFF" strokeWidth="0.66" />
            <path d="M170.031204,131.846284 C173.791888,130.580972 177.643302,129.599545 181.552632,128.910366 C185.921356,128.077447 190.372732,127.752312 194.817046,127.941513 C195.85912,127.988635 196.679278,128.842527 196.6782,129.879219 L196.6782,160.941229 C196.6782,162.011394 195.805253,162.878935 194.728419,162.878935 L194.521625,162.878935 C190.634782,162.493618 186.714731,162.592246 182.852486,163.172526 C178.869156,163.755455 174.923486,164.569112 171.035637,165.609338 C170.449209,165.764698 169.823428,165.639816 169.34281,165.271514 C168.862192,164.903212 168.581552,164.333495 168.58364,163.730351 L168.58364,133.813349 C168.538962,132.900435 169.142259,132.080627 170.031204,131.846284 Z" fill="#FFFFFF" fillRule="nonzero" />
            <path d="M170.207917,132.719373 C169.814899,132.856108 169.551626,133.22117 169.550202,133.631381 L169.550202,163.727635 C169.547837,164.03322 169.691823,164.322066 169.93884,164.507269 C170.185857,164.692472 170.507071,164.752415 170.80584,164.669062 C174.740752,163.628634 178.733591,162.813326 182.764304,162.227235 C186.722816,161.643563 190.739563,161.544745 194.722768,161.933039 C195.267637,161.933039 195.709341,161.498376 195.709341,160.962192 L195.709341,129.895091 C195.710301,129.381069 195.303949,128.955396 194.78256,128.924244 C190.357979,128.712792 185.923425,129.008982 181.568458,129.806832 C177.713363,130.498696 173.916006,131.472237 170.207917,132.719373 Z" stroke="#FFFFFF" strokeWidth="0.66" />
            <path d="M172.322598,135.767705 L181.265904,133.343381 L191.759382,132.804642 C192.02041,132.788276 192.277186,132.876938 192.472969,133.051038 C192.668752,133.225138 192.787426,133.470342 192.802768,133.73247 L192.802768,158.664104 C192.802768,159.209589 192.362322,159.651792 191.819005,159.651792 L182.279478,159.651792 L172.68033,161.776817 C172.390822,161.839917 172.088406,161.768671 171.857073,161.582866 C171.625739,161.397061 171.490123,161.116484 171.487889,160.819059 L171.487889,136.725463 C171.496884,136.244803 171.849278,135.840461 172.322598,135.767705 Z M172.322598,92.0999335 L181.265904,89.6756089 L191.759382,89.1368702 C192.02041,89.120504 192.277186,89.2091666 192.472969,89.3832662 C192.668752,89.5573658 192.787426,89.8025705 192.802768,90.0646981 L192.802768,114.996332 C192.802768,115.541817 192.362322,115.98402 191.819005,115.98402 L182.279478,115.98402 L172.68033,118.109045 C172.390822,118.172145 172.088406,118.100899 171.857073,117.915094 C171.625739,117.729289 171.490123,117.448712 171.487889,117.151287 L171.487889,93.0277614 C171.496884,92.5471012 171.849278,92.1427589 172.322598,92.0700036 L172.322598,92.0999335 Z" fill="#B2DEF2" fillRule="nonzero" />
            <rect fill="#B2DEF2" fillRule="nonzero" transform="translate(65.882353, 146.297578) rotate(-83.000000) translate(-65.882353, -146.297578) " x="48.4429066" y="128.858131" width="34.8788927" height="34.8788927" rx="0.33" />
            <rect fill="#FFFFFF" fillRule="nonzero" transform="translate(62.491349, 142.906574) rotate(-83.000000) translate(-62.491349, -142.906574) " x="44.567474" y="124.982699" width="35.8477509" height="35.8477509" rx="0.66" />
            <rect stroke="#FFFFFF" strokeWidth="0.66" transform="translate(62.491349, 141.937716) rotate(-83.000000) translate(-62.491349, -141.937716) " x="45.5363322" y="124.982699" width="33.9100346" height="33.9100346" rx="0.66" />
            <rect fill="#B2DEF2" fillRule="nonzero" transform="translate(103.667820, 113.356401) rotate(-10.000000) translate(-103.667820, -113.356401) " x="86.2283737" y="95.916955" width="34.8788927" height="34.8788927" rx="0.33" />
            <rect fill="#FFFFFF" fillRule="nonzero" transform="translate(100.276817, 110.934256) rotate(-10.000000) translate(-100.276817, -110.934256) " x="82.3529412" y="93.0103806" width="35.8477509" height="35.8477509" rx="0.66" />
            <rect stroke="#FFFFFF" strokeWidth="0.66" transform="translate(100.276817, 110.934256) rotate(-10.000000) translate(-100.276817, -110.934256) " x="83.3217993" y="93.9792388" width="33.9100346" height="33.9100346" rx="0.66" />
            <ellipse stroke="#FFFFFF" strokeWidth="0.66" fill="#FFFFFF" fillRule="nonzero" cx="165.67474" cy="3.39100346" rx="2.90657439" ry="3.39100346" />
            <ellipse stroke="#FFFFFF" strokeWidth="0.66" fill="#FFFFFF" fillRule="nonzero" cx="277.093426" cy="50.8650519" rx="2.90657439" ry="3.39100346" />
            <ellipse stroke="#FFFFFF" strokeWidth="0.66" fill="#FFFFFF" fillRule="nonzero" cx="55.2249135" cy="44.083045" rx="2.90657439" ry="3.39100346" />
            <path d="M5.81314879,86.2283737 L5.81314879,84.7750865 C5.81314879,83.9724581 5.16248995,83.3217993 4.35986159,83.3217993 C3.55723324,83.3217993 2.90657439,83.9724581 2.90657439,84.7750865 L2.90657439,86.2283737 L1.4532872,86.2283737 C0.650658841,86.2283737 1.61347291e-16,86.8790325 0,87.6816609 C-1.61347291e-16,88.4842893 0.650658841,89.1349481 1.4532872,89.1349481 L2.90657439,89.1349481 L2.90657439,90.5882353 C2.90657439,91.3908637 3.55723324,92.0415225 4.35986159,92.0415225 C5.16248995,92.0415225 5.81314879,91.3908637 5.81314879,90.5882353 L5.81314879,89.1058824 L7.26643599,89.1058824 C8.06906434,89.1058824 8.71972318,88.4552235 8.71972318,87.6525952 C8.71972318,86.8499668 8.06906434,86.199308 7.26643599,86.199308 L5.81314879,86.199308 L5.81314879,86.2283737 Z" stroke="#FFFFFF" fill="#FFFFFF" fillRule="nonzero" />
            <path d="M89.1349481,2.90657439 L89.1349481,1.4532872 C89.1349481,0.650658841 88.4842893,0 87.6816609,0 C86.8790325,0 86.2283737,0.650658841 86.2283737,1.4532872 L86.2283737,2.90657439 L84.7750865,2.90657439 C83.9724581,2.90657439 83.3217993,3.55723324 83.3217993,4.35986159 C83.3217993,5.16248995 83.9724581,5.81314879 84.7750865,5.81314879 L86.2283737,5.81314879 L86.2283737,7.26643599 C86.2283737,8.06906434 86.8790325,8.71972318 87.6816609,8.71972318 C88.4842893,8.71972318 89.1349481,8.06906434 89.1349481,7.26643599 L89.1349481,5.78408304 L90.5882353,5.78408304 C91.3908637,5.78408304 92.0415225,5.1334242 92.0415225,4.33079585 C92.0415225,3.52816749 91.3908637,2.87750865 90.5882353,2.87750865 L89.1349481,2.87750865 L89.1349481,2.90657439 Z" stroke="#FFFFFF" fill="#FFFFFF" fillRule="nonzero" />
            <path d="M246.089965,101.730104 L246.089965,100.276817 C246.089965,99.4741883 245.439307,98.8235294 244.636678,98.8235294 C243.83405,98.8235294 243.183391,99.4741883 243.183391,100.276817 L243.183391,101.730104 L241.730104,101.730104 C240.927475,101.730104 240.276817,102.380763 240.276817,103.183391 C240.276817,103.986019 240.927475,104.636678 241.730104,104.636678 L243.183391,104.636678 L243.183391,106.089965 C243.183391,106.892594 243.83405,107.543253 244.636678,107.543253 C245.439307,107.543253 246.089965,106.892594 246.089965,106.089965 L246.089965,104.607612 L247.543253,104.607612 C248.345881,104.607612 248.99654,103.956954 248.99654,103.154325 C248.99654,102.351697 248.345881,101.701038 247.543253,101.701038 L246.089965,101.701038 L246.089965,101.730104 Z" stroke="#FFFFFF" fill="#FFFFFF" fillRule="nonzero" />
            <path d="M212.044019,37.2228024 L212.732647,36.5030346 C212.897314,36.102925 212.795665,35.637448 212.48121,35.3516441 C212.166755,35.0658402 211.711616,35.0252593 211.355391,35.2512644 L210.666763,35.9710322 L210.008075,35.2512644 C209.777756,34.9530973 209.404834,34.8184606 209.047552,34.9044852 C208.69027,34.9905097 208.411286,35.2821087 208.328983,35.6555473 C208.246681,36.0289858 208.375492,36.4187716 208.66076,36.6595058 L209.409268,37.2228024 L208.72064,37.9425703 C208.41293,38.3409268 208.442253,38.9177288 208.78862,39.2797582 C209.134987,39.6417876 209.686834,39.6724369 210.067956,39.3508118 L210.756584,38.6310439 L211.445212,39.3508118 C211.675531,39.6489788 212.048453,39.7836155 212.405735,39.697591 C212.763018,39.6115664 213.042001,39.3199674 213.124304,38.9465288 C213.206607,38.5730903 213.077795,38.1833045 212.792528,37.9425703 L212.044019,37.2228024 Z" stroke="#FFFFFF" fill="#FFFFFF" fillRule="nonzero" />
            <path d="M157.261013,51.7869687 L157.67419,51.3551079 C157.772991,51.1150422 157.712001,50.835756 157.523328,50.6642736 C157.334655,50.4927913 157.061572,50.4684428 156.847837,50.6040458 L156.43466,51.0359065 L156.039447,50.6040458 C155.901256,50.4251456 155.677502,50.3443636 155.463133,50.3959783 C155.248764,50.447593 155.081374,50.6225524 155.031992,50.8466156 C154.98261,51.0706787 155.059898,51.3045502 155.231058,51.4489907 L155.680163,51.7869687 L155.266986,52.2188294 C155.08236,52.4578433 155.099954,52.8039245 155.307774,53.0211421 C155.515594,53.2383598 155.846702,53.2567493 156.075376,53.0637743 L156.488552,52.6319135 L156.901729,53.0637743 C157.039921,53.2426745 157.263674,53.3234565 157.478043,53.2718418 C157.692413,53.2202271 157.859803,53.0452676 157.909184,52.8212045 C157.958566,52.5971414 157.881279,52.3632699 157.710119,52.2188294 L157.261013,51.7869687 Z" stroke="#FFFFFF" fill="#FFFFFF" fillRule="nonzero" />
        </g>
    </svg>
)