import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {Pages} from '../../routing';
import {NavigateTo} from '../../state/routing/actions';

const OptionEntry = styled.div`
    font-size: ${fontSize.small_14};
    padding: 12px 0 4px 0;
    cursor: pointer;
    color: ${colors.captureGrey800};

    &:hover {
        color: ${colors.captureBlue};
    }
`;

type DispatchProps = {
    sharedWasSelected: () => any,
    privateWasSelected: () => any,
};

class CreateAlbumButtonContent extends React.Component<DispatchProps> {

    public render() {
        return (
            <div>
                <OptionEntry onClick={this.props.privateWasSelected}>{_('private_album')}</OptionEntry>
                <OptionEntry onClick={this.props.sharedWasSelected}>{_('shared_album')}</OptionEntry>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    privateWasSelected: () => dispatch(NavigateTo(Pages.CreatePrivateAlbum)),
    sharedWasSelected: () => dispatch(NavigateTo(Pages.CreateSharedAlbum)),
});

export const AlbumPrivacyModeSelector = connect(null, mapDispatchToProps)(CreateAlbumButtonContent);
