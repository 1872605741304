export const bytesToSize = (bytes: number, decimals: number = 2): string => {
    if (bytes <= 0) {
        return '0 Bytes';
    }
    const c = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(c));
    if (i < 2) {
        decimals = 0;
    }
    return ((bytes / Math.pow(c, i)).toFixed(decimals)) + ' ' + sizes[i];
};
