import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {_} from '../../assets/localizedStrings';
import {AlbumSortModeChanged} from '../../state/album/actions';
import {AlbumSortMode, getAlbumSortMode} from '../../state/album/selectors';
import {State} from '../../state/store';
import {isMobileMode} from '../../state/viewMode/selectors';
import {CenteredChoiceMenu, SlideUpChoiceMenu} from '../Common/ChoiceMenu';
import {RadioButtonGroup} from '../Common/RadioButtonGroup';

type OwnProps = {
    albumID: JobID,
    onClose: () => any,
};

type StateProps = {
    isMobile: boolean,
    currentAlbumSortMode: AlbumSortMode,
};

type DispatchProps = {
    doSelectSortMode: (sortMode: AlbumSortMode) => any,
};

type Props = OwnProps & StateProps & DispatchProps;

class AlbumSortModePromptComp extends React.Component<Props> {
    private albumSortOptions: Array<{text: string; value: AlbumSortMode}> = [
        {text: _('sort_album_files_by_added'), value: 'added_time_desc'},
        {text: _('sort_album_files_by_newest_first'), value: 'create_time_desc'},
        {text: _('sort_album_files_by_oldest_first'), value: 'create_time_asc'},
    ];

    private handleSelectSortMode = (sortMode: AlbumSortMode) => {
        this.props.doSelectSortMode(sortMode);
        this.props.onClose();
    }

    public render() {
        const ChoiceMenuOverlay = this.props.isMobile ? SlideUpChoiceMenu : CenteredChoiceMenu;
        return (
            <ChoiceMenuOverlay
                title={_('sort_album_files_title')}
                onClose={this.props.onClose}
            >
                <RadioButtonGroup
                    value={this.props.currentAlbumSortMode}
                    options={this.albumSortOptions}
                    onSelectOption={this.handleSelectSortMode}
                />
            </ChoiceMenuOverlay>
        );
    }
}

const stateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    isMobile: isMobileMode(state),
    currentAlbumSortMode: getAlbumSortMode(state, ownProps.albumID),
});
const dispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    doSelectSortMode: (sortMode: AlbumSortMode) => {
        dispatch(AlbumSortModeChanged({jobID: ownProps.albumID, sortMode}));
    },
});

export const AlbumSortModePrompt = connect(stateToProps, dispatchToProps)(AlbumSortModePromptComp);
