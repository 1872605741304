import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {CarouselViewerNode} from '.';
import {loveAlbumFile, setAlbumCoverPhoto, unloveAlbumFile} from '../../API/album';
import {copyAlbumFilesToTimeline, downloadSingleFile} from '../../API/job';
import {colors} from '../../assets/styleConstants';
import {Pages} from '../../routing';
import {NavigateTo} from '../../state';
import {getCoverPhotoIDByAlbum} from '../../state/albumList/selectors';
import {
    CarouselViewSideModuleClosed, CarouselViewSideModuleOpened,
    ChangedCurrentCarouselFile, FullscreenModeEntered, FullscreenModeExited, SideModuleContentType,
} from '../../state/carouselViewer/actions';
import {
    getCurrentSideModuleContent,
    isFullscreenMode,
    isSideModuleExpanded,
} from '../../state/carouselViewer/pureSelectors';
import {
    getCurrentThumbFiles, getCurrentThumbIndex,
    getCurrentViewerNode, getFullscreenAlbumFileSizeProvider,
    ThumbFile,
} from '../../state/carouselViewer/selectors';
import {isLoggedIn} from '../../state/currentUser/selectors';
import {FileDeletionInitiated, FileDimensionsDiscovered} from '../../state/files/actions';
import {CaptureFile} from '../../state/files/reducer';
import {FilesOptionsTriggered} from '../../state/FilesOptions/actions';
import {State} from '../../state/store';
import {getViewportHeight, isMobileMode} from '../../state/viewMode/selectors';
import {CarouselView} from './CarouselView';
import {SideModule} from './SideModule';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${colors.darkBackground};

    position: relative;
    display:flex;
    justify-content: center;
    align-items: center;
`;

type ImageProps = {isSideModuleExpanded: boolean, isMobileMode: boolean};
const ImageComponentWrapper = styled.div`
    position: absolute;
    top: 0;
    right: ${(props: ImageProps) => props.isSideModuleExpanded ? props.isMobileMode ? 0 : '360px' : 0};
    left: 0;
    bottom: 0;
`;

type StateProps = {
    isMobileMode: boolean,
    viewportHeight: number,
    fullscreenAlbumFileSizeProvider: (file: CaptureFile) => any,
    currentViewerNode: CarouselViewerNode|undefined,
    thumbFiles: ThumbFile[],
    currentThumbIndex: number,
    coverPhotoByAlbum: DictionaryOf<FileID>,
    isSideModuleExpanded: boolean,
    currentSideModuleContent?: SideModuleContentType,
    isInFullscreenMode: boolean,
    isLoggedIn: boolean,
};

type DispatchProps = {
    navigateToFile: (jobID: JobID, fileID: FileID) => any,
    onImageDimensionsDiscovered: (imageID: FileID, width: number, height: number) => any,
    onDeleteInitiate: (fileID: FileID) => any,
    setCoverPhoto: (albumID: JobID, fileID: FileID) => any,
    onLoveReactionChanged: (albumID: JobID, fileID: FileID, ifLove: boolean) => any,
    doSideModuleExpand: (contentType: SideModuleContentType) => any,
    doSideModuleCollapse: () => any,
    onFullscreenModeEnter: () => any,
    onFullscreenModeExit: () => any,
    onAddImageToAlbum: () => any,
    onDownloadFile: (jobID: JobID, fileID: FileID) => any,
    doCopyToTimeline: (file: CaptureFile) => any,
    goToRequireLoginCopyFile: (jobID: JobID, file: FileID) => any,
    onShareFile: () => void,
};

type Props = StateProps & DispatchProps;

type PlacementState = {viewportDiff: number};

class _CarouselViewPlacement extends React.Component<Props, PlacementState> {
    private wrapper = React.createRef<HTMLDivElement>();

    public state = {
        viewportDiff: 0,
    };

    private handleImageDimensionsDetected = (w: number, h: number) => {
        if (this.props.currentViewerNode && this.props.currentViewerNode.file.height === undefined) {
            this.props.onImageDimensionsDiscovered(this.props.currentViewerNode.file.fileID, w, h);
        }
    }

    private handleDeleteInitiate = () => {
        if (this.props.currentViewerNode !== undefined) {
            this.props.onDeleteInitiate(this.props.currentViewerNode.file.fileID);
        }
    }

    private copyToTimeline = () => {
        if (this.props.currentViewerNode) {
            const {file} = this.props.currentViewerNode;
            if (this.props.isLoggedIn) {
                this.props.doCopyToTimeline(file);
            }
            else {
                this.props.goToRequireLoginCopyFile(file.jobID, file.fileID);
            }
        }
    }

    private onSideModuleToggle = (contentType: SideModuleContentType) => {
        if (
            (this.props.isSideModuleExpanded && this.props.currentSideModuleContent !== contentType)
            || !this.props.isSideModuleExpanded
        ) {
            this.props.doSideModuleExpand(contentType);
        }
        else {
            this.props.doSideModuleCollapse();
        }
    }

    private getViewportDiff = () => {
        return this.wrapper.current ? this.wrapper.current.clientHeight - this.props.viewportHeight : 0;
    }

    public componentDidUpdate() {
        // check diff between actual viewport and container height only mobile mode
        if (this.props.currentViewerNode && this.props.isMobileMode) {
            const viewportDiff = this.getViewportDiff();
            if (viewportDiff !== this.state.viewportDiff) {
                this.setState({
                    viewportDiff,
                });
            }
        }
    }

    public render() {
        const node = this.props.currentViewerNode;
        if (node !== undefined) {

            return (
                <Wrapper innerRef={this.wrapper}>
                    <ImageComponentWrapper
                        isSideModuleExpanded={this.props.isSideModuleExpanded}
                        isMobileMode={this.props.isMobileMode}
                    >
                        <CarouselView
                            currentViewerNode={node}
                            thumbFiles={this.props.thumbFiles}
                            currentThumbIndex={this.props.currentThumbIndex}
                            viewportDiff={this.state.viewportDiff}
                            viewerNodeSize={this.props.fullscreenAlbumFileSizeProvider(node.file)}
                            onNavigateToFile={this.props.navigateToFile}
                            onImageDimensionsDetected={this.handleImageDimensionsDetected}
                            onDeleteInitiate={this.handleDeleteInitiate}
                            onSetAsAlbumCoverPhoto={this.props.setCoverPhoto}
                            currentCoverPhoto={this.props.coverPhotoByAlbum[node.file.jobID]}
                            onLoveChanged={(ifLove: boolean) => this.props.onLoveReactionChanged(node.file.jobID, node.file.fileID, ifLove)}
                            doSideModuleToggle={this.onSideModuleToggle}
                            isSideModuleExpanded={this.props.isSideModuleExpanded}
                            isInFullscreenMode={this.props.isInFullscreenMode}
                            onFullscreenModeExit={this.props.onFullscreenModeExit}
                            onFullscreenModeEnter={this.props.onFullscreenModeEnter}
                            onAddImageToAlbum={this.props.onAddImageToAlbum}
                            onDownloadFile={this.props.onDownloadFile}
                            onShareFile={this.props.onShareFile}
                            doCopyToTimeline={this.copyToTimeline}
                        />
                    </ImageComponentWrapper>
                    <SideModule
                        currentSideModuleContent={this.props.currentSideModuleContent}
                        onCollapse={this.props.doSideModuleCollapse}
                        viewerNode={node}
                        mobileMode={this.props.isMobileMode}
                    />
                </Wrapper>
            );
        }

        return null;
    }
}

const mapStateToProps = (state: State): StateProps => ({
    isMobileMode: isMobileMode(state),
    viewportHeight: getViewportHeight(state),
    fullscreenAlbumFileSizeProvider: getFullscreenAlbumFileSizeProvider(state),
    currentViewerNode: getCurrentViewerNode(state),
    thumbFiles: getCurrentThumbFiles(state),
    currentThumbIndex: getCurrentThumbIndex(state),
    coverPhotoByAlbum: getCoverPhotoIDByAlbum(state),
    isSideModuleExpanded: isSideModuleExpanded(state),
    currentSideModuleContent: getCurrentSideModuleContent(state),
    isInFullscreenMode: isFullscreenMode(state),
    isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    navigateToFile: (jobID: JobID, fileID: FileID) => dispatch(ChangedCurrentCarouselFile({jobID, fileID})),
    onImageDimensionsDiscovered: (fileID: FileID, width: number, height: number) => {
        dispatch(FileDimensionsDiscovered([{fileID, width, height}]));
    },
    onDeleteInitiate: (fileID: FileID) => dispatch(FileDeletionInitiated(fileID)),
    setCoverPhoto: (albumID: JobID, fileID: FileID) => setAlbumCoverPhoto(dispatch, albumID, fileID),
    doCopyToTimeline: (file: CaptureFile) => copyAlbumFilesToTimeline(dispatch, file.jobID, [file.fileID]),
    onLoveReactionChanged: (albumID: JobID, fileID: FileID, ifLove: boolean) => {
        if (ifLove) {
            loveAlbumFile(dispatch, albumID, fileID);
        } else {
            unloveAlbumFile(dispatch, albumID, fileID);
        }
    },
    doSideModuleExpand: (contentType: SideModuleContentType) => dispatch(CarouselViewSideModuleOpened(contentType)),
    doSideModuleCollapse: () => dispatch(CarouselViewSideModuleClosed()),
    onFullscreenModeEnter: () => dispatch(FullscreenModeEntered()),
    onFullscreenModeExit: () => dispatch(FullscreenModeExited()),
    onAddImageToAlbum: () => {dispatch(FilesOptionsTriggered({type: 'add_to_album'})); },
    onDownloadFile: (jobID: JobID, fileID: FileID) => downloadSingleFile(dispatch, jobID, fileID),
    goToRequireLoginCopyFile: (jobID: JobID, fileID: FileID) => dispatch(NavigateTo(Pages.CopyAlbumRequireLogin(jobID, fileID))),
    onShareFile: () => dispatch(FilesOptionsTriggered({type: 'share'})),
});

export const CarouselViewPlacement = connect(mapStateToProps, mapDispatchToProps)(_CarouselViewPlacement);
