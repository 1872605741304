import * as React from 'react';
import styled from 'styled-components';
import {isMobileDevice} from '../../utilities/device';
import {CheckFilledIcon} from '../Icons/CheckIcon';
import {CircleIcon} from '../Icons/CircleIcon';
import {IconProps} from '../Icons/index';
import {IconButton} from './IconTextButton';

const SelectIndicator = styled.div`
    position: relative;
    cursor: pointer;

    svg {
        opacity: ${(props: {opacity: number}) => props.opacity};
    }
    ${!isMobileDevice.any() ? `
        &:hover {
            svg {
                opacity: 1;
            }
            .item-select-indicator {
                visibility: visible;
            }
        }
    ` : ''}
`;
const SelectPlaceholder = styled.div`
    position: absolute;
    cursor: pointer;

    svg {
        opacity: 0.8;
    }
`;
const CheckMark = styled.div.attrs({
    className: 'item-select-indicator',
})`
    visibility: ${(props: {selected: boolean}) => props.selected ? 'visible' : 'hidden'};
`;

type Props = {
    onClick: (event: any) => any,
    isSelected: boolean,
    showPlaceholder: boolean,
    strokeColor?: string,
    fillColor?: string,
};

export const SelectionIndicator: React.SFC<Props> = (props) => {
    const Icon: React.SFC<IconProps> = () =>
        <CheckFilledIcon size={24} color={props.fillColor} stroke={props.strokeColor}/>;
    const placeholder = props.showPlaceholder && (
        <SelectPlaceholder onClick={props.onClick}>
            <CircleIcon size={24} color={props.fillColor}/>
        </SelectPlaceholder>
    );

    return (
        <SelectIndicator opacity={(props.isSelected || props.showPlaceholder) ? 1 : 0.5}>
            {placeholder}
            <CheckMark selected={props.isSelected}>
                <IconButton
                    onClick={props.onClick}
                    icon={Icon}
                    color={props.fillColor}
                />
            </CheckMark>
        </SelectIndicator>
    );
};

export const VisibleSelectionIndicator: React.SFC<Props> = (props) => {
    const Icon: React.SFC<IconProps> = () =>
        <CheckFilledIcon size={24} color={props.fillColor} stroke={props.strokeColor}/>;
    return (
        <SelectIndicator opacity={(props.isSelected || props.showPlaceholder) ? 1 : 0.5}>
            <IconButton
                onClick={props.onClick}
                icon={Icon}
                color={props.fillColor}
            />
        </SelectIndicator>
    );
};
