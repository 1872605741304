import styled from 'styled-components';
import {colors, fontSize} from '../../assets/styleConstants';

export const HeaderText = styled.div`
    font-size: ${fontSize.large_24};
    font-weight: bold;
    color: ${colors.captureGrey800};
    margin: 24px auto;
    text-align: center;
`;

export const SubHeaderText = styled.div`
    font-size: ${fontSize.medium_16};
`;
