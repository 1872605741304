import {LOGGED_IN_SITE_ADDRESS} from '../../../config/constants';
import {PageDescription} from '../state';
import {Dispatch} from '../state/common/actions';
import {
    CurrentWebVersionWasSeen, FetchedAccountInfo,
    FetchedLastWebVersionSeen, StartFetchingAccountInfo,
    UnableToFetchAccountInfo, UserLoggedOut,
} from '../state/currentUser/actions';
import {currentAppVersion} from '../state/currentUser/selectors';
import {
    FetchedHostDirectory, FetchingHostDirectoryFailed, FetchingHostDirectoryStarted,
    HostDirectory,
} from '../state/hosts/actions';
import {localStorageSet} from '../utilities/webStorage';
import {clearLoginInfo, getAuthToken, getStoredServiceDict} from './externals';
import {getServiceProvider} from './HostProvider';
import {fetchDefaultJobID} from './job';
import {getLoginServiceForApp} from './services';
import {AppService} from './services/AppService';
import {ServiceDictResponse} from './services/LoginService';

export function fetchAccountInfoNicely(dispatch: Dispatch): Promise<any> {
    const authToken = getAuthToken();
    const dict = getStoredServiceDict();
    if (!authToken || !dict) {
        clearLoginInfo();
        dispatch(UnableToFetchAccountInfo());
        return Promise.reject(Error('UnableToFetchAccountInfo'));
    }
    return fetchAccountInfo(dispatch, new AppService(dict.appHost, authToken), authToken);
}

// Inner method that allows testing with mocked service and no localStorage-variables. Use fetchAccountInfoNicely
export const fetchAccountInfo = (dispatch: Dispatch, service: AppService, authToken: string) => {
    dispatch(StartFetchingAccountInfo());

    return service.getAccountInfo().then(
        (accountInfo) => {
            dispatch(FetchedAccountInfo({
                files_disabled: accountInfo.files_disabled,
                kissmetrics_id: accountInfo.kissmetrics_id,
                logged_in_as: accountInfo.logged_in_as,
                max_space: parseInt(accountInfo.max_space, 10),
                name: accountInfo.name,
                used_space: parseInt(accountInfo.used_space, 10),
                username: accountInfo.username,
                uuid: accountInfo.uuid,
                auth_token: authToken,
                isBeingShutDown: accountInfo.shutdown_info !== undefined,
            }));
            getLastSeenVersion(dispatch, service);
            fetchDefaultJobID(dispatch);
        },
        (error) => {
            clearLoginInfo();
            dispatch(UnableToFetchAccountInfo());
            throw error;
        });
};

export const getLastSeenVersion = async (dispatch: Dispatch, appService?: AppService) => {
    try {
        const service = appService || await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        const response = await service.getUserOption('lastWebVersionSeen');
        dispatch(FetchedLastWebVersionSeen(response ? parseInt(response, 10) : -1));
    }
    catch (e) {}
};

export const setLastSeenVersion = async (dispatch: Dispatch) => {
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        await service.setUserOption('lastWebVersionSeen', currentAppVersion.toString());
        dispatch(CurrentWebVersionWasSeen(currentAppVersion));
    }
    catch (e) {}
};

export const requireAccountInfo = (dispatch: Dispatch, gotoIfLoginFail?: string): Promise<any> => {
    return fetchAccountInfoNicely(dispatch).catch(
        () => loginAndGoToUrl(window.location.href, gotoIfLoginFail),
    );
};
export const loadAccountInfoIfAvailable = (dispatch: Dispatch): void => {
    fetchAccountInfoNicely(dispatch).catch(
        () => {/* We didn't get account info - that is okay */},
    );
};

export const logout = async (dispatch: Dispatch) => {
    // TODO: TESTS etc
    const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
    try {
        await service.logout();
        clearLoginInfo();
        dispatch(UserLoggedOut());
    } catch (e) {
        /* What if logout fails */
    }
};

export function fetchHostDirectory(dispatch: Dispatch, job: JobID): Promise<HostDirectory> {
    dispatch(FetchingHostDirectoryStarted(job));
    return getLoginServiceForApp().getServiceDictForJob(job).then(
        (response: ServiceDictResponse) => {
            const hosts: HostDirectory = {
                appHost: response.service['app-host'],
                thumbHost: response.service['thumb-host'],
                videoHost: response.service['video-host'],
                pollHost: response.service['poll-host'].replace(/^(p\d+)/, 'ps'),  // the operations-team expect us to substitute pN.* to ps.* when using https
            };
            dispatch(FetchedHostDirectory({job, hosts}));
            return hosts;
        },
        (error) => {
            dispatch(FetchingHostDirectoryFailed(job));
            throw error;
        });
}

export const doLogin = () => loginAndGoToUrl(location.href);
export const loginAndGoToUrl = (targetPath: URLstring, ifFail?: URLstring) => {
    localStorageSet('locationBeforeLogin', targetPath);
    if (ifFail) {
        localStorageSet('locationIfLoginFails', ifFail);
    }

    window.location.href = getLoginServiceForApp().getLoginUrl();

    // Return a never-resolving Promise as we will be redirecting insteadof returning any value.
    return new Promise(() => {});
};

export const loginAndGoTo = (targetPage: PageDescription, ifFail?: URLstring) => {
    loginAndGoToUrl(LOGGED_IN_SITE_ADDRESS + targetPage.url, ifFail || window.location.href).catch();
};
