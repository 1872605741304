import * as React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {isCarouselViewOpen} from '../../state/carouselViewer/pureSelectors';
import {State} from '../../state/store';
import {
    getUploadStatusTopPosition, isStatusBoxVisible,
} from '../../state/uploader/selectors';
import {isMobileMode} from '../../state/viewMode/selectors';
import {ToastsComponentPlacement} from '../Toast/ToastsComponentPlacement';

type StatusContainerProps = {translateAmount: number, isMobileMode: boolean};
const StatusContainer = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    left: ${(props: StatusContainerProps) => props.isMobileMode ? 0 : '40px' };
    ${(props) => props.isMobileMode ? 'right: 0' : '' };
    bottom: 0;
    transition: transform ease-out 0.3s;
    transform: translateY(${(props) => props.translateAmount}px);

    z-index: 1000;
`;

type StateProps = {
    isMobileMode: boolean,
    isUploaderVisible: boolean,
    uploaderSize: number,
};

export const _StatusNotificationPlacement: React.SFC<StateProps> = (props) => {
    return (
        // TODO: Drop the mobile mode check when a mobile uploader card is implemented in the future
        <StatusContainer
            translateAmount={props.isUploaderVisible && !props.isMobileMode ? -props.uploaderSize : 0}
            isMobileMode={props.isMobileMode}
        >
            <ToastsComponentPlacement />
        </StatusContainer>
    );
};

const mapStateToProps = (state: State): StateProps => ({
    isMobileMode: isMobileMode(state),
    isUploaderVisible: isStatusBoxVisible(state) && !isCarouselViewOpen(state),
    uploaderSize: getUploadStatusTopPosition(state),
});

export const StatusNotificationPlacement = connect(mapStateToProps)(_StatusNotificationPlacement);
