import * as React from 'react';
import styled from 'styled-components';
import {colors, fontSize} from '../../assets/styleConstants';
import {AlbumJobInfo} from '../../state/albumList/selectors';
import {AnimatedGradient} from '../Common/AnimatedGradient';
import {Avatar} from '../Common/Avatar';
import {Bullet} from '../Common/Bullet';
import {ButtonProps} from '../Common/Button';
import {CloudsAndSunIllustrationAnimated} from '../Common/CloudsAndSunIllustrationAnimated';
import {WhiteHoverEffect} from '../Common/HoverEffects';
import {MaybeNumberOf} from '../Common/MaybeNumberOf';
import {OverflowMenu} from '../Common/OverflowMenu';
import {localizedDateStringShort} from '../Common/TimeStrings';
import {CommentIcon} from '../Icons/CommentIcon';
import {HeartIcon} from '../Icons/HeartIcon';
import {PeopleIcon} from '../Icons/PeopleIcon';
import {PhotoIcon} from '../Icons/PhotoIcon';

const AlbumWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
    background-color: ${colors.captureGrey500};
    background-size: cover;
    background-position: center;
    cursor: pointer;
    ${WhiteHoverEffect};
`;

const OverflowMenuWrapper = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
`;

const AlbumInfo = styled.div`
    height: 129px;
    width: 100%;
    align-self: flex-end;
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.7));
    padding: 30px 12px 12px;
    box-sizing: border-box;
    color: white;
`;

const AvatarWrapper = styled.div`
    margin-right: 6px;
    display: inline-flex;
`;

const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: ${fontSize.small_12};
    text-shadow: rgba(0,0,0,0.78) 0px 1px 2px;
`;

const OwnerName = styled.span`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const Title = styled.div`
    width: 100%;
    height: 27px;
    font-size: ${fontSize.medium_18};
    font-weight: bold;
    margin: 5px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const BottomElements = styled.div`
    display: flex;
    justify-content: space-between;
`;
type Props = AlbumJobInfo & {
    overflowMenuItems?: ButtonProps[],
};
export class AlbumListEntry extends React.Component<Props, {}> {

    private getOverflowMenu = (menuItems: ButtonProps[]) => {
        if (menuItems.length > 0) {
            return (
                <OverflowMenuWrapper>
                    <OverflowMenu
                        menuOptions={menuItems}
                        iconColor={'white'}
                        shadowColor={'black'}
                    />
                </OverflowMenuWrapper>
            );
        }
    }
    public render() {

        let numberOfsElements = null;
        if (this.props.numberOf) {
            const {files, loves, comments} = this.props.numberOf;
            numberOfsElements = (
                <div>
                    <MaybeNumberOf icon={PhotoIcon} numberOf={files} />
                    <MaybeNumberOf icon={HeartIcon} numberOf={loves} />
                    <MaybeNumberOf icon={CommentIcon} numberOf={comments} />
                </div>
            );
        }

        return (
            <AlbumWrapper
                style={{backgroundImage: `url('${this.props.coverPhoto}')`}}
            >
                {this.props.overflowMenuItems && this.getOverflowMenu(this.props.overflowMenuItems)}
                <AlbumInfo>
                    <InfoWrapper>
                        <AvatarWrapper>
                            <Avatar
                                name={this.props.owner ? this.props.owner.name : ''}
                                img={this.props.owner ? this.props.owner.profilePicture : undefined}
                                size={24}
                                color={'white'}
                            />
                        </AvatarWrapper>
                        <OwnerName>{this.props.owner ? this.props.owner.name : '...'}</OwnerName>
                        <Bullet/>
                        <span>{localizedDateStringShort(this.props.dateCreated)}</span>
                    </InfoWrapper>
                    <Title>{this.props.title}</Title>
                    <BottomElements>
                        {numberOfsElements}
                        {this.props.isShared && <PeopleIcon color={'white'} size={24}/>}
                    </BottomElements>
                </AlbumInfo>
            </AlbumWrapper>
        );
    }
}

const AlbumPlaceholderWrapper = AlbumWrapper.extend`
    background-color: white;
`;
const AlbumInfoPlaceholder = AlbumInfo.extend`
    background: transparent;
    height: 100px;
    padding-top: 0;
`;
const AvatarPlaceholder = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 16px;
`;
const Gradient = AnimatedGradient.extend`
    width: 206px;
`;
const Bar = styled.div`
    border-radius: 3px;
    height: 16px;
    overflow: hidden;
`;
const NamePlaceholder = Bar.extend`
    width: 72px;
`;

const TitlePlaceholder = Bar.extend`
    width: 206px;
    margin: 8px 0 16px 0;
`;
const InfoPlaceholder = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 6px;
    margin-right: 24px;
    overflow: hidden;
    display: inline-block;
`;
const Illustration = styled.div`
    margin-right: 24px;
    margin-bottom: 4px;
`;

export const AlbumListEntryPlaceholder: React.SFC = () => {
    const animatedGradient = <Gradient color1={colors.captureBlue50} color2={colors.captureBlue100}/>;
    const infoPlaceholder = <InfoPlaceholder>{animatedGradient}</InfoPlaceholder>;

    return (
        <AlbumPlaceholderWrapper>
            <Illustration>
                <CloudsAndSunIllustrationAnimated />
            </Illustration>
            <AlbumInfoPlaceholder>
                <InfoWrapper>
                    <AvatarPlaceholder>{animatedGradient}</AvatarPlaceholder>
                    <NamePlaceholder>{animatedGradient}</NamePlaceholder>
                </InfoWrapper>
                <TitlePlaceholder>{animatedGradient}</TitlePlaceholder>
                <div>
                    {infoPlaceholder}
                    {infoPlaceholder}
                    {infoPlaceholder}
                </div>
            </AlbumInfoPlaceholder>
        </AlbumPlaceholderWrapper>
    );
};
