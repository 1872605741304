import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {loveAlbumFile, setAlbumCoverPhoto, unloveAlbumFile} from '../../API/album';
import {copyAlbumFilesToTimeline, downloadSingleFile} from '../../API/job';
import {Pages} from '../../routing';
import {NavigateTo} from '../../state';
import {ClickedAlbumImage, DisplayListOfLoversInitiated} from '../../state/album/actions';
import {AlbumFile, getImageHeightProvider, maxAlbumContainerWidth} from '../../state/album/selectors';
import {getCoverPhotoIDByAlbum} from '../../state/albumList/selectors';
import {isLoggedIn} from '../../state/currentUser/selectors';
import {
    FileDeletionInitiated, FileDimensions,
    FileDimensionsDiscovered,
} from '../../state/files/actions';
import {State} from '../../state/store';
import {AlbumFileComponent} from './AlbumFileComponent';

type StateProps = {
    imageHeight: (file: AlbumFile) => number,
    coverPhotoID: FileID,
    isLoggedIn: boolean,
};

type DispatchProps = {
    onImageClick: (imageID: ImageID) => any,
    onMultipleImageDimensionsDiscovered: (multipleDimensions: FileDimensions[]) => any,
    onDeleteInitiated: (fileID: FileID) => any,
    onLoveReactionChanged: (fileID: FileID, ifLove: boolean) => any,
    setAsCoverPhoto: (fileID: FileID) => any,
    doCopyToTimeline: (file: AlbumFile) => any,
    doFileDownload: (jobID: JobID, fileID: FileID) => any,
    goToRequireLoginCopyFile: (file: FileID) => any,
    doShowLoveList: (fileID: FileID) => any,
};

type OwnProps = {
    albumID: JobID,
    files: AlbumFile[],
    mobileMode: boolean,
    userCanAddComment?: boolean, // Defaults to true
    userCanLove?: boolean, // Defaults to true
    isShared: boolean,
};

type Props = StateProps & DispatchProps & OwnProps;
const Wrapper = styled.div`
    max-width: ${maxAlbumContainerWidth}px;
    margin: 0 auto;
`;

class AlbumFileListComponent extends React.Component<Props, {}> {
    private updateDimensionsQueue: FileDimensions[] = [];
    private fileEventHandlers: DictionaryOf<any> = {};

    public render() {
        const files = this.props.files.map((file) => {
            const handlers = this.getFileEventHandlers(file);
            if (file.height !== undefined) {
                handlers.onImageDimensionsDetected = undefined;
            }

            return (
                <AlbumFileComponent
                    key={file.fileID}
                    file={file}
                    height={this.props.imageHeight(file)}
                    mobileMode={this.props.mobileMode}
                    userCanAddComment={this.props.userCanAddComment !== false}
                    userCanLove={this.props.userCanLove !== false}
                    currentCoverPhoto={this.props.coverPhotoID}
                    isShared={this.props.isShared}
                    {...handlers}
                />
            );
        });

        return <Wrapper>{files}</Wrapper>;
    }

    private handleImageDimensionsDetected = (fileID: FileID) => {
        return (width: number, height: number) => {
            if (this.updateDimensionsQueue.length === 0) {
                window.setTimeout(() => {
                    this.props.onMultipleImageDimensionsDiscovered(this.updateDimensionsQueue);
                    this.updateDimensionsQueue = [];
                }, 1000);
            }

            this.updateDimensionsQueue.push({
                fileID,
                width,
                height,
            });
        };
    }
    private copyToTimeline = (file: AlbumFile) => {
        if (this.props.isLoggedIn) {
            this.props.doCopyToTimeline(file);
        }
        else {
            this.props.goToRequireLoginCopyFile(file.fileID);
        }
    }

    private getFileEventHandlers = (file: AlbumFile) => {
        if (this.fileEventHandlers[file.fileID] === undefined) {
            this.fileEventHandlers[file.fileID] = {
                onImageDimensionsDetected: this.handleImageDimensionsDetected(file.fileID),
                onImageClick: () => {
                    this.props.onImageClick(file.fileID);
                },
                onDeleteInitiate: () => {
                    this.props.onDeleteInitiated(file.fileID);
                },
                doFileDownload: () => {
                    this.props.doFileDownload(file.jobID, file.fileID);
                },
                doCopyToTimeline: () => {
                   this.copyToTimeline(file);
                },
                onLoveReactionChanged: (ifLove: boolean) => {
                    this.props.onLoveReactionChanged(file.fileID, ifLove);
                },
                doShowLoveList: () => {
                    this.props.doShowLoveList(file.fileID);
                },
                setAsCoverPhoto: () => {
                    this.props.setAsCoverPhoto(file.fileID);
                },
            };
        }

        return this.fileEventHandlers[file.fileID];
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
    return {
        imageHeight: getImageHeightProvider(state),
        coverPhotoID: getCoverPhotoIDByAlbum(state)[ownProps.albumID],
        isLoggedIn: isLoggedIn(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    onDeleteInitiated: (fileID: FileID) => dispatch(FileDeletionInitiated(fileID)),
    doFileDownload: (albumID: JobID, file: FileID) => downloadSingleFile(dispatch, albumID, file),
    onImageClick: (imageID: ImageID) => {
        dispatch(NavigateTo(Pages.AlbumCarousel(ownProps.albumID, imageID)));
        dispatch(ClickedAlbumImage({albumID: ownProps.albumID, imageID}));
    },
    onMultipleImageDimensionsDiscovered: (multipleDimensions: FileDimensions[]) => {
        dispatch(FileDimensionsDiscovered(multipleDimensions));
    },
    onLoveReactionChanged: (fileID: FileID, ifLove: boolean) => {
        if (ifLove) {
            loveAlbumFile(dispatch, ownProps.albumID, fileID);
        } else {
            unloveAlbumFile(dispatch, ownProps.albumID, fileID);
        }
    },
    doShowLoveList: (fileID: FileID) => dispatch(DisplayListOfLoversInitiated(ownProps.albumID, fileID)),
    doCopyToTimeline: (file: AlbumFile) => copyAlbumFilesToTimeline(dispatch, file.jobID, [file.fileID]),
    setAsCoverPhoto: (fileID: FileID) => setAlbumCoverPhoto(dispatch, ownProps.albumID, fileID),
    goToRequireLoginCopyFile: (fileID: FileID) => dispatch(NavigateTo(Pages.CopyAlbumRequireLogin(ownProps.albumID, fileID))),
});

export const AlbumFileList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlbumFileListComponent);
