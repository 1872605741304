import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {AlbumJobInfo} from '../../state/albumList/selectors';
import {PlusIcon} from '../Icons/PlusIcon';
import {WhiteHoverEffect} from './HoverEffects';

type AlbumSelectListProps = {
    albums: AlbumJobInfo[],
    onSelect: (id: JobID) => void,
    selectedID: JobID|undefined,
    isMobile: boolean,
    onCreateNew: () => any,
};

type WrapperProps = AlbumJobInfo & {
    onSelect: (id: JobID) => void,
    isSelected: boolean,
    isMobile: boolean,
};

const AlbumListContainer = styled.div`
    width: ${(props: {width: string}) => props.width};
    display: flex;
    flex-wrap: wrap;
    flex-basis: auto;
`;

const Item = styled.div`
    display: flex;
    width: ${(props: {isMobile: boolean}) => props.isMobile ? '100%' : '152px'};
    height: ${(props) => props.isMobile ? '128px' : '152px'};
    margin: 2px;
    border-radius: 5px;
    border-color: ${colors.captureBlue};
    box-sizing: border-box;
    cursor: pointer;
    ${WhiteHoverEffect};
`;

type AlbumItemProps = {isSelected: boolean, isMobile: boolean};
const AlbumItemWrapper = styled(Item)`
    position: relative;
    align-content: flex-end;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
    background-color: ${colors.captureGrey500};
    background-size: cover;
    background-position: center;
    opacity: ${(props: AlbumItemProps) => props.isSelected ? '0.7' : '1'};
    border: ${(props) => props.isSelected ? '3px solid' : '0'};
`;
const CreateNewAlbumItem = styled(Item)`
    border-width: 1px;
    border-style: dashed;
    color: ${colors.captureBlue};
    font-size: ${fontSize.small_14};
    font-weight: bold;

    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const InfoArea = styled.div`
    width: 100%;
    align-self: flex-end;
    background: linear-gradient(rgba(0,0,0,0) 15%, rgba(0,0,0,1));
    padding: ${(props: {isSelected: boolean}) => props.isSelected ? '20px 5px 5px' : '20px 8px 8px'};
    box-sizing: border-box;
    color: white;
    border-radius: inherit;
`;

const Title = styled.div`
    width: 100%;
    font-size: ${fontSize.small_14};
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: flex-end;
`;

// avoids creating a function in album select's render
class AlbumItem extends React.Component<WrapperProps> {
    private setSelectedAlbum = () => {
        this.props.onSelect(this.props.id);
    }
    public render(): JSX.Element {
       return (
        <AlbumItemWrapper
            style={{backgroundImage: `url('${this.props.coverPhoto}')`}}
            onClick={this.setSelectedAlbum}
            isSelected={this.props.isSelected}
            isMobile={this.props.isMobile}
        >
          <InfoArea isSelected={this.props.isSelected}>
                <Title>{this.props.title}</Title>
           </InfoArea>
        </AlbumItemWrapper>
       );
    }
}

export class AlbumSelectList extends React.Component<AlbumSelectListProps> {
    private setSelectedAlbum = (id: JobID) => {
        this.props.onSelect(id);
    }
    public render(): JSX.Element {

        const albumItems = this.props.albums.map((album) => {
            return (
                <AlbumItem
                    key={album.id}
                    {...album}
                    onSelect={this.setSelectedAlbum}
                    isSelected={this.props.selectedID === album.id}
                    isMobile={this.props.isMobile}
                />
            );
        });

        const containerWidth = 468; // (AlbumItem width + margins) * 3
        return (
            <AlbumListContainer width={this.props.isMobile ? '100%' : containerWidth + 'px'}>
                <CreateNewAlbumItem isMobile={this.props.isMobile} onClick={this.props.onCreateNew}>
                    <PlusIcon size={24} color={colors.captureBlue} />
                    {_('new_album')}
                </CreateNewAlbumItem>
                {albumItems}
            </AlbumListContainer>
        );
    }
}
