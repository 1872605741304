import {Middleware} from 'redux';
import {isType} from '../common/actions';
import {getUsedStorageRatio} from '../currentUser/selectors';
import {FilesCopiedToTimelineFailed} from '../files/actions';
import {JobCopiedToTimelineFailed} from '../job/actions';
import {State} from '../store';
import {AddedMoreFilesThanAvailableStorage, FileWasAddedToUploadQueue, UploaderFinished} from '../uploader/actions';
import {getEnquedFiles, isCurrentlyUploading} from '../uploader/selectors';
import {PopupTriggered} from './actions';

export const popupMiddleware: Middleware = (store) => {
    let usedQuotaAsUploadStart = 0;

    return (next) => (action) => {
        const state: State = store.getState();

        if (isType(action, AddedMoreFilesThanAvailableStorage)) {
            store.dispatch(PopupTriggered('AddedMoreFilesThanAvailableStorage'));
        }

        if (
            isType(action, FileWasAddedToUploadQueue)
            && getEnquedFiles(state).length === 0
            && !isCurrentlyUploading(state)
        ) {
            usedQuotaAsUploadStart = getUsedStorageRatio(state);
        }

        if (isType(action, UploaderFinished)) {
            if (usedQuotaAsUploadStart < 0.95 && getUsedStorageRatio(state) > 0.95) {
                store.dispatch(PopupTriggered('UsedMoreThan95PercentOfStorage'));
            }
            else if (usedQuotaAsUploadStart < 0.80 && getUsedStorageRatio(state) > 0.80) {
                store.dispatch(PopupTriggered('UsedMoreThan80PercentOfStorage'));
            }
        }

        if (isType(action, FilesCopiedToTimelineFailed) || isType(action, JobCopiedToTimelineFailed)) {
            if (action.payload.reason === 'out_of_storage') {
                store.dispatch(PopupTriggered('AddedMoreFilesThanAvailableStorage'));
            }
        }

        next(action);
    };
};
