import * as React from 'react';
import styled from 'styled-components';

type WrapperProps = {translate: number};
const Wrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;

    transform: translateX(${(props: WrapperProps) => props.translate}px);
    transition: transform 0.3s ease-out;
`;

type Props = {
    toggleButton: React.SFC<{isExpanded: boolean, onClick: ()=>any, isMobile: boolean}>,
    canToggle: boolean,
    translateAmount: number,
    isMobile: boolean,
};

type ComponentState = {
    isExpanded: boolean,
};

export class SlideInHoverWrapper extends React.Component<Props, ComponentState> {
    public state: ComponentState = {
        isExpanded: false,
    };

    private handleToggleClicked = () => {
        this.setState((prevState) => ({...prevState, isExpanded: !prevState.isExpanded}));
    }

    private timer: any;
    private handleMouseEnter = () => {
        clearTimeout(this.timer);
        if (!this.state.isExpanded) {
            this.setState((prevState) => ({
                ...prevState,
                isExpanded: true,
            }));
        }
    }
    private handleMouseLeave = () => {
        if (this.state.isExpanded) {
            this.timer = setTimeout(
                () => {
                    this.setState((preState) => ({
                        ...preState,
                        isExpanded: false,
                    }));
                },
                1500,
            );
        }
    }

    public render() {
        return (
            <>
                <Wrapper
                    translate={(!this.props.canToggle || this.state.isExpanded) ? 0 : this.props.translateAmount}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >
                    {this.props.children}
                </Wrapper>
                {this.props.canToggle && <this.props.toggleButton
                    onClick={this.handleToggleClicked}
                    isExpanded={this.state.isExpanded}
                    isMobile={this.props.isMobile}
                />}
            </>
        );
    }
}
