import {DeletedFileResponse} from '../../API/services/AppService';
import {createActionCreator, createVoidActionCreator} from '../common/actions';

export const TrashLoadingStarted = createVoidActionCreator('TRASH_LOADING_STARTED');
export const TrashLoadingSucceeded = createActionCreator<DeletedFileResponse>('TRASH_LOADING_SUCCEEDED');
export const TrashLoadingFailed = createVoidActionCreator('TRASH_LOADING_FAILED');
export const TrashContentOutdated = createVoidActionCreator('TRASH_CONTENT_OUTDATED');

export const TrashFileSelected = createActionCreator<FileID>('TRASH_FILE_SELECTED');
export const TrashFileDeSelected = createActionCreator<FileID>('TRASH_FILE_DE_SELECTED');
export const TrashAllFilesSelected = createActionCreator<{jobID: JobID}>('TRASH_ALL_FILES_SELECTED');
export const TrashAllFilesDeSelected = createVoidActionCreator('TRASH_ALL_FILES_DESELECTED');

export const TrashFileDeleted = createActionCreator<FileID>('TRASH_FILE_DELETED'); // use file removed already in use?
export const TrashFileDeleteFailed = createActionCreator<FileID>('TRASH_FILE_DELETE_FAILED');
export const TrashFilesDeletionStarted = createActionCreator<FileID[]>('TRASH_FILES_DELETION_STARTED');
export const TrashFilesDeletionSucceeded = createActionCreator<FileID[]>('TRASH_FILES_DELETION_SUCCEEDED');
export const TrashFilesDeletionFailed = createActionCreator<FileID[]>('TRASH_FILES_DELETION_FAILED');

export const FetchMoreTrashTriggered = createVoidActionCreator('FETCH_MORE_TRASH_TRIGGERED');
