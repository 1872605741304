import {createSelector} from 'reselect';
import {isCaptureTV} from '../../../../config/constants';
import {FileTarget} from '../../utilities/fileTarget';
import {
    calcImagesPerRow, calcNumberOfVisibleThumbs, computeGridContainerWidth, GridStyle,
} from '../../utilities/gridElementSizeCalculator';
import {AvailableActions, getAvailableActions} from '../albumList/selectors';
import {FileComment, UserActionStatus} from '../comments/reducer';
import {getCommentsGroupedByImage} from '../comments/selectors';
import {getCurrentUserUUID} from '../currentUser/selectors';
import {FileMetadata} from '../fileMetadata/actions';
import {CaptureFile, CommentStatus, JobFile} from '../files/reducer';
import {getFileByID, getFileMetadata, getFileType, getJobFiles, makeThumbForJobFilesSelector} from '../files/selectors';
import {JobInfoElement} from '../jobInfo/reducer';
import {getJobInfoElement} from '../jobInfo/selectors';
import {Reactions} from '../reaction/actions';
import {getCurrentUserLovedFiles, getTotalLoveCountPerFile} from '../reaction/selectors';
import {State} from '../store';
import {FileInformation} from '../uploader/reducer';
import {getAllCurrentFiles} from '../uploader/selectors';
import {UserActionsStatus} from '../userActions/reducer';
import {getUsersInfo, UserInfo} from '../users/selectors';
import {getViewportHeight, getViewportWidth, isMobileMode} from '../viewMode/selectors';
import {getLastSeenElementForJob} from '../lastSeenElement/selectors';

export type AlbumFileComment = {
    commentID: CommentID,
    fileID: FileID,
    albumID: JobID,
    text: string,
    author: UserInfo,
    timeCreated: Date,
    currentUserCan: {
        editComment: boolean,
        deleteComment: boolean,
    },
    awaitsDecisionToBeDeleted: boolean,
    isEditing: boolean,
    isPendingServerEdit: boolean,
    isPendingServerDelete: boolean,
    hasBeenEdited: boolean,
    editFailed: boolean,
    deleteFailed: boolean,
};

export type AlbumFile = CaptureFile & {
    addedBy_obj: UserInfo,
    thumbURL?: string,
    comments: AlbumFileComment[],
    ctime: number,
    currentUserCan: {
        deleteFile: boolean,
        setFileAsAlbumCoverPhoto: boolean,
        addComment: boolean,
    }
    isPendingNewComment: boolean,
    lovedByCurrentUser: boolean,
    totalLoveCount: number,
};

export type Album = {
    id: JobID,
    title: string,
    owner: UserInfo,
    files: AlbumFile[],
    dateCreated: Date,
    filesDateRange?: DateRange,
    subscribersCount: number,
    currentUserCan: AvailableActions,
    isHealthy: boolean,
    awaitsDecisionToBeDeleted: boolean,
    awaitsServerResponseDelete: boolean,
    deleteFailed: boolean,
    isShared: boolean,
};

const unknownUser: UserInfo = {userID: 'unknown', name: ''};
const makeGetUserFunc = (
    users: DictionaryOf<UserInfo>,
) => (userID: UserID): UserInfo => users[userID] || unknownUser;

const transformComment = (
    albumID: JobID,
    getUser: (u: UserID) => UserInfo,
    currentUser?: UserID,
    albumCreator?: UserID,
) => (c: FileComment): AlbumFileComment => ({
    commentID: c.commentUUID,
    fileID : c.fileID,
    albumID,
    text: c.comment,
    author: getUser(c.userUUID),
    timeCreated: new Date(c.timestamp * 1000),
    currentUserCan: {
        editComment: c.userUUID === currentUser,
        deleteComment: c.userUUID === currentUser || currentUser === albumCreator,
    },
    awaitsDecisionToBeDeleted: c.userActionStatus === UserActionStatus.PENDING_USER__DELETE,
    isPendingServerDelete: c.userActionStatus === UserActionStatus.PENDING_SERVER__DELETE,
    deleteFailed: c.userActionStatus === UserActionStatus.SERVER_ERROR__DELETE,
    isEditing: c.userActionStatus === UserActionStatus.PENDING_USER__EDIT,
    isPendingServerEdit: c.userActionStatus === UserActionStatus.PENDING_SERVER__EDIT,
    hasBeenEdited: c.lastEditTimestamp !== undefined,
    editFailed: c.userActionStatus === UserActionStatus.SERVER_ERROR__EDIT,
});

const albumFileComments = (
    file: JobFile,
    getUser: (userID: UserID) => UserInfo,
    allComments: DictionaryOf<FileComment[]>,
    currentUser: UserID|undefined,
    jobOwnerID: UserID,
): AlbumFileComment[] => {
    return (allComments[file.fileID] || []).map(
        transformComment(file.jobID, getUser, currentUser, jobOwnerID),
    );
};

const albumFileLoveInfo = (
    file: JobFile,
    currentUserLovedFiles: DictionaryOf<boolean>,
    totalLoveCountPerFile: DictionaryOf<number>,
) => {
    let lovedByCurrentUser = currentUserLovedFiles[file.fileID] || false;
    let totalLoveCount = totalLoveCountPerFile[file.fileID] || 0;

    if (file.pendingReaction === Reactions.None && lovedByCurrentUser) {
        lovedByCurrentUser = false;
        totalLoveCount--;
    }

    if (file.pendingReaction === Reactions.Love && !lovedByCurrentUser) {
        lovedByCurrentUser = true;
        totalLoveCount++;
    }

    return {
        lovedByCurrentUser,
        totalLoveCount,
    };
};

export const makeAlbumFiles = (
    jobFiles: JobFile[],
    users: DictionaryOf<UserInfo>,
    allComments: DictionaryOf<FileComment[]>,
    thumbURLs: DictionaryOf<string>,
    currentUserLovedFiles: DictionaryOf<boolean>,
    totalLoveCountPerFile: DictionaryOf<number>,
    currentUser: UserID|undefined,
    albumOwnerID: UserID,
    fileMetadata: DictionaryOf<FileMetadata>,
    canAddComment: boolean,
) => {
    const getUser = makeGetUserFunc(users);

    return jobFiles.map((file: JobFile): AlbumFile => {
        return {
            ...file,
            type: getFileType(file),
            addedBy_obj: getUser(file.addedBy),
            thumbURL: thumbURLs[file.fileID],
            thumbURLSmall: thumbURLs[file.fileID],
            thumbURLMedium: thumbURLs[file.fileID],
            thumbURLLarge: thumbURLs[file.fileID],
            metadata: fileMetadata[file.fileID],
            comments: albumFileComments(file, getUser, allComments, currentUser, albumOwnerID),
            ctime: file.ctime || file.mtime,
            currentUserCan: {
                deleteFile: file.addedBy === currentUser || albumOwnerID === currentUser,
                setFileAsAlbumCoverPhoto: albumOwnerID === currentUser,
                addComment: canAddComment,
            },
            isPendingNewComment: file.pendingCommentStatus === CommentStatus.PENDING,
            ...albumFileLoveInfo(file, currentUserLovedFiles, totalLoveCountPerFile),
        };
    }).filter((file) => file.type === FileTarget.Pictures || file.type === FileTarget.Movies);
};

const getAvailableActionsForJob = (state: State, jobId: JobID) => (
    getAvailableActions(state)[jobId]
);

export type AlbumSortMode = 'added_time_desc'|'create_time_desc'|'create_time_asc';
const albumSortMethods: Record<AlbumSortMode, (a: JobFile, b: JobFile) => number> = {
    added_time_desc: (a, b) => b.timestamp - a.timestamp,
    create_time_asc: (a, b) => (a.ctime || a.mtime) - (b.ctime || b.mtime),
    create_time_desc: (a, b) => (b.ctime || b.mtime) - (a.ctime || a.mtime),
};
export const getAlbumSortMode = createSelector(
    getJobInfoElement,
    (jobInfoElement): AlbumSortMode => {
        return jobInfoElement.sortMode || 'create_time_desc';
    },
);

const getSortedAlbumFiles = createSelector(
    getJobFiles,
    getAlbumSortMode,
    (jobFiles: JobFile[], sortMode: AlbumSortMode) => {
        return [...jobFiles].sort(albumSortMethods[sortMode]);
    },
);

export const getAlbumFiles = createSelector(
    getJobInfoElement,
    getSortedAlbumFiles,
    getCommentsGroupedByImage,
    getUsersInfo,
    getCurrentUserUUID,
    makeThumbForJobFilesSelector(1280),
    getCurrentUserLovedFiles,
    getTotalLoveCountPerFile,
    getFileMetadata,
    getAvailableActionsForJob,
    (
        jobInfoElement: JobInfoElement|undefined,
        jobFiles: JobFile[],
        allComments: DictionaryOf<FileComment[]>,
        users: DictionaryOf<UserInfo>,
        currentUserUUID: UserID|undefined,
        thumbURLs: DictionaryOf<string>,
        currentUserLovedFiles: DictionaryOf<boolean>,
        totalLoveCountPerFile: DictionaryOf<number>,
        metadata: DictionaryOf<FileMetadata>,
        availableActionsForJob: AvailableActions,
    ): AlbumFile[] => {
        // If job info is not fetched; the album is not ready to be composed
        if (jobInfoElement === undefined || jobInfoElement.jobInfo === undefined) {
            return [];
        }

        const jobInfo = jobInfoElement.jobInfo;
        const showComments = jobInfo.allow_comments || jobInfo.owner === currentUserUUID;

        return makeAlbumFiles(
            jobFiles,
            users,
            showComments ? allComments : {},
            thumbURLs,
            currentUserLovedFiles,
            totalLoveCountPerFile,
            currentUserUUID,
            jobInfo.owner,
            metadata,
            availableActionsForJob.addComment,
        );

    },
);
export const getSubscribersForJob = (state: State, jobID: JobID): UserInfo[]|undefined => state.subscribers[jobID];

export type DateRange = {start: Date, end: Date};

export const getAlbumFilesDateRange = createSelector(
    getAlbumFiles,
    getAlbumSortMode,
    (files, currentSortMode): DateRange|undefined => {
        if (files.length === 0) {
            return undefined;
        }

        if (currentSortMode !== 'create_time_desc') {
            const {min, max} = files.reduce<{min: number, max: number}>(
                (c, {ctime}) => ({min: Math.min(c.min, ctime), max: Math.max(c.max, ctime)}),
                {max: -Infinity, min: Infinity},
            );
            return {start: new Date(min * 1000), end: new Date(max * 1000)};
        }

        return {
            start: new Date(files[files.length - 1].ctime * 1000),
            end: new Date(files[0].ctime * 1000),
        };
    },
);

export const makeAlbum = (
    id: JobID,
    title: string,
    ownerID: UserID,
    files: AlbumFile[],
    filesDateRange: DateRange|undefined,
    ctime: number, // seconds
    currentUserCan: AvailableActions,
    users: DictionaryOf<UserInfo>,
    subscribers: UserInfo[]|undefined,
    deleteStatus: UserActionsStatus|undefined,
    isShared: boolean,
): Album => {
    const getUser = makeGetUserFunc(users);

    let isHealthy = subscribers ? subscribers.length > 1 : false;
    let i = files.length;
    while (!isHealthy && i > 0) {
        i--; // before accessing to offset 0-indexing
        isHealthy = files[i].comments.some((c) => c.author.userID !== ownerID);
    }

    return {
        id,
        title,
        owner: getUser(ownerID),
        files,
        filesDateRange,
        dateCreated: new Date(ctime * 1000),
        subscribersCount: isShared && subscribers ? subscribers.length : 0,
        currentUserCan,
        isHealthy,
        awaitsDecisionToBeDeleted: deleteStatus === UserActionsStatus.PENDING_USER,
        awaitsServerResponseDelete: deleteStatus === UserActionsStatus.PENDING_SERVER,
        deleteFailed: deleteStatus === UserActionsStatus.SERVER_ERROR,
        isShared,
    };
};

export const getAlbum: (state: State, jobID: JobID) => Album|undefined = createSelector(
    getJobInfoElement,
    getAlbumFiles,
    getAlbumFilesDateRange,
    getUsersInfo,
    getSubscribersForJob,
    getAvailableActionsForJob,
    (
        jobInfoElement: JobInfoElement|undefined,
        files: AlbumFile[],
        filesDateRange: DateRange|undefined,
        users: DictionaryOf<UserInfo>,
        subscribers: UserInfo[]|undefined,
        currentUserCan: AvailableActions,
    ): Album|undefined => {
        // If job info is not fetched; the album is not ready to be composed
        if (jobInfoElement === undefined || jobInfoElement.jobInfo === undefined) {
            return;
        }

        const jobInfo = jobInfoElement.jobInfo;
        return makeAlbum(
            jobInfoElement.jobID,
            jobInfo.title,
            jobInfo.owner,
            files,
            filesDateRange,
            jobInfo.ctime || jobInfo.mtime,
            currentUserCan,
            users,
            subscribers,
            undefined,
            jobInfo.isShared,
        );
    },
);

export type AlbumViewMode = 'flow'|'grid';
export const getAlbumViewMode = (state: State, albumID: JobID): AlbumViewMode => {
    const jobInfoElement = getJobInfoElement(state, albumID);
    return jobInfoElement.viewMode || 'flow';
};

export const getTitleForAlbum: (state: State, albumID: JobID) => string|undefined = createSelector(
    getJobInfoElement,
    (jobInfoElement: JobInfoElement|undefined) => {
        if (jobInfoElement && jobInfoElement.jobInfo) {
            return jobInfoElement.jobInfo.title;
        }
    },
);

export type PendingAlbum = {
    title: string,
    pendingFiles: FileInformation[],
    uploadedFiles: AlbumFile[],
    isShared: boolean,
};

export const getPendingAlbum: (state: State, jobID: JobID) => PendingAlbum|undefined = createSelector(
    getAlbum,
    getAllCurrentFiles,
    (album: Album|undefined, allPendingFiles: FileInformation[]) => {
        if (album) {
            const albumFileList: DictionaryOf<boolean> = {};
            album.files.forEach((file) => {
                albumFileList[file.fileID] = true;
            });
            const pendingFiles = allPendingFiles.filter((file) => {
                return file.targetJob === album.id
                    && (file.fileUUID === undefined || !albumFileList[file.fileUUID]);
            });
            return {
                title: album.title === '__new_album__' ? '' : album.title,
                pendingFiles,
                uploadedFiles: album.files,
                isShared: album.isShared,
            };
        }
    },
);

export const getComments = (state: State, fileID: FileID): AlbumFileComment[] => {
    const users = getUsersInfo(state);
    const file = getFileByID(state, fileID);
    if (file === undefined) {
        return [];
    }
    const {jobInfo} = getJobInfoElement(state, file.jobID);
    return (getCommentsGroupedByImage(state)[fileID] || []).map(
        transformComment(
            file.jobID,
            (user: UserID) => (users[user] ? users[user] : unknownUser),
            getCurrentUserUUID(state),
            jobInfo ? jobInfo.owner : undefined),
    );
};

export const canCurrentUserCommentOnAlbum = (state: State, albumID: JobID): boolean => {
    const album = getAlbum(state, albumID);
    if (album) {
        return album.currentUserCan.addComment;
    }
    return false;
};

const albumGridStyleMobile = {
    elementWidth: 176,
    elementHeight: 176,
    elementSpaceAround: 2,
};
const albumGridStyleDesktop = {
    elementWidth: 228,
    elementHeight: 228,
    elementSpaceAround: 2,
};
const albumGridStyleTV = {
    elementWidth: 180,
    elementHeight: 180,
    elementSpaceAround: 2,
};

const albumHeaderElementHeight = 180;
export const maxAlbumContainerWidth = isCaptureTV ? 820 : 928;

const computeAlbumGridStyle = (screenWidth: number, isMobile: boolean): GridStyle => {
    const style = isCaptureTV ? albumGridStyleTV : isMobile ? albumGridStyleMobile : albumGridStyleDesktop;
    return {
        ...style,
        width: computeGridContainerWidth(screenWidth, maxAlbumContainerWidth, style),
    };
};
export const getAlbumGridStyle = createSelector(
    getViewportWidth,
    isMobileMode,
    computeAlbumGridStyle,
);
export const getImagesPerRow = createSelector(
    getAlbumGridStyle,
    calcImagesPerRow,
);

export const getNumberOfVisibleThumbs = createSelector(
    getAlbumGridStyle,
    getViewportHeight,
    calcNumberOfVisibleThumbs,
);

export const getImageHeightProvider = createSelector(
    getViewportWidth,
    isMobileMode,
    (w: number, isMobile: boolean) => {
        return (file: AlbumFile): number => {
            if (file.width && file.height) {
                const containerWidth = isMobile ? w : Math.min(w, maxAlbumContainerWidth) * 0.67;
                const ratio = file.width / file.height;

                const scaledHeight = containerWidth / ratio;
                return Math.min(scaledHeight, file.height);
            }
            return 250;
        };
    },
);

export const getLastViewedGridOffset: (state: State, jobID: JobID) =>
    {offset: number, fileIndex: number}|undefined = createSelector(
        getJobInfoElement,
        getAlbumFiles,
        getLastSeenElementForJob,
        getAlbumGridStyle,
        getViewportHeight,
        (jobInfo, files, lastSeenFileID, gridStyle, viewportHeight) => {
            if (lastSeenFileID) {
                const fileIndex = files.map((f) => f.fileID).indexOf(lastSeenFileID);
                const rowHeight = gridStyle.elementHeight + (gridStyle.elementSpaceAround * 2);
                const offset = albumHeaderElementHeight
                    + (Math.floor(fileIndex / calcImagesPerRow(gridStyle)) * rowHeight)
                    + (rowHeight / 2)
                    - (viewportHeight / 2);

                return {offset, fileIndex};
            }
            return undefined;
        },
    );
