import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {isJobCreatedByCurrentUser} from '../../state/currentUser/selectors';
import {getJobTitle} from '../../state/jobInfo/selectors';
import {StatusNotification} from '../../state/statusNotifications/reducer';
import {getStatusNotifications, isPendingStatus} from '../../state/statusNotifications/selectors';
import {State} from '../../state/store';
import {isMobileMode} from '../../state/viewMode/selectors';
import {addOnBeforeUnloadBlock, removeOnBeforeUnloadBlock} from '../../utilities/onBeforeUnload';
import {Toast} from './ToastComponent';

const Wrapper = styled.div`
    width: ${(props: {isMobile: boolean}) => props.isMobile ? '100%' : '304px'};
    display: flex;
    flex-direction: column;

    z-index: 1000;
`;

type WrapperProps = {
    isVisible: boolean,
    n: number,
    i: number,
};

const ToastWrapperDesktop = styled.div`
    position: relative;
    transition: opacity  0.3s ease-out, height  0.3s ease-out, visibility 0.3s 0.1s;

    height: ${(props: WrapperProps) => props.isVisible ? '56px' : '0px'};
    opacity: ${(props) => props.isVisible ? '1' : '0'};
    visibility: ${(props) => props.isVisible ? 'visible' : 'hidden'};
`;
const ToastWrapperMobile = styled.div`
    position: absolute;
    bottom: 0;
    left: 0; right: 0;
    transition: transform ease-out 0.3s;
    transform: translateY(${(props: WrapperProps) => ((props.isVisible || props.n === props.i ? 0 : -1) + (props.n - 1 - props.i)) * -48}px);
`;
const PlaceholderWrapper = styled.div`
    height: 48px;
`;

type Props = {
    toasts: StatusNotification[],
    isMobileMode: boolean,
    isOwnerOfJob: (job: JobID) => boolean,
    getJobName: (job: JobID) => string,
};

type ComponentState = {allToasts: StatusNotification[]};

class _ToastsComponentPlacement extends React.PureComponent<Props, ComponentState> {
    public state: ComponentState = {allToasts: this.props.toasts};

    private havePendingToast = () => this.props.toasts.some(isPendingStatus);
    public componentDidMount() {
        addOnBeforeUnloadBlock(this.havePendingToast);
    }
    public componentWillUnmount() {
        removeOnBeforeUnloadBlock(this.havePendingToast);
    }

    public componentWillReceiveProps(newProps: Props) {
        if (newProps.toasts !== this.props.toasts) {
            const seen: DictionaryOf<boolean> = {};
            this.state.allToasts.forEach((t) => {seen[t.id] = true; });
            const newToasts = newProps.toasts.filter((t) => seen[t.id] === undefined);
            if (newToasts.length > 0) {
                this.setState({...this.state, allToasts: this.state.allToasts.concat(newToasts)});
            }

            if (newProps.toasts.length === 0) {
                setTimeout(() => {
                    if (this.props.toasts.length === 0) {
                        this.setState({...this.state, allToasts: []});
                    }
                }, 310);
            }
        }
    }
    public render() {
        const ToastWrapper = this.props.isMobileMode ? ToastWrapperMobile : ToastWrapperDesktop;

        const visible: DictionaryOf<boolean> = {};
        this.props.toasts.forEach((t) => {visible[t.id] = true; });
        const visibleCount  = Object.keys(visible).length;

        let stackNumber = -1;
        const toasts = this.state.allToasts.map((toast, i) => {
            stackNumber += (visible[toast.id] ? 1 : 0);
            return (
                <ToastWrapper key={i} n={visibleCount} i={stackNumber} isVisible={visible[toast.id]}>
                    <Toast
                        toast={toast}
                        isOwnerOfJob={this.props.isOwnerOfJob}
                        getJobName={this.props.getJobName}
                    />
                </ToastWrapper>
            );
        });

        return (
            <Wrapper isMobile={this.props.isMobileMode}>
                {toasts}
                {/* placeholder element to enable animation of new elements */}
                <ToastWrapper i={visibleCount} n={visibleCount} isVisible={false}>
                    <PlaceholderWrapper />
                </ToastWrapper>
            </Wrapper>
        );
    }
}

const mapStateToProps = (state: State): Props => ({
    toasts: getStatusNotifications(state),
    isMobileMode: isMobileMode(state),
    isOwnerOfJob: (job: JobID) => isJobCreatedByCurrentUser(state, job) === true,
    getJobName: (job: JobID) => getJobTitle(state, job,  _('unknown')),
});

export const ToastsComponentPlacement = connect(mapStateToProps)(_ToastsComponentPlacement);
