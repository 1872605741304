import * as React from 'react';
import styled from 'styled-components';
import {
    ConfirmPromptComponent,
    LoggedOutPromptComponent, OkPromptComponent,
} from './DialoguePromptComponent';
import {FullscreenOverlay} from './FullscreenOverlay';

const Wrapper = styled.div`
    padding: 8px;
    box-sizing: border-box;
    white-space: pre-wrap;
`;
function wrapInFullscreenOverlay<T>(Inner: React.SFC<T>) {
    return (props: T) => (
        <FullscreenOverlay>
            <Wrapper>
                <Inner {...props}/>
            </Wrapper>
        </FullscreenOverlay>
    );
}

export const ConfirmPromptOverlay = wrapInFullscreenOverlay(ConfirmPromptComponent);
export const OkPromptOverlay = wrapInFullscreenOverlay(OkPromptComponent);
export const LoggedOutPromptOverlay = wrapInFullscreenOverlay(LoggedOutPromptComponent);
