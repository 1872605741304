import {Action, isType} from '../common/actions';
import {
    CarouselViewSideModuleClosed, CarouselViewSideModuleOpened,
    ChangedCurrentCarouselFile, EnteredCarouselView, FullscreenModeEntered,
    FullscreenModeExited, SideModuleContentType,
} from './actions';

export type CarouselViewInfo = {jobID: JobID, fileID: FileID};
export type CarouselViewerState = {
    currentView?: CarouselViewInfo,
    prevView?: CarouselViewInfo,
    currentSideModule: SideModuleContentType | undefined,
    isInFullscreenMode: boolean,
};

const initialState = {
    currentSideModule: undefined,
    isInFullscreenMode: false,
};

export function carouselViewerReducer(
    state: CarouselViewerState = initialState,
    action: Action<any>,
): CarouselViewerState {
    if (isType(action, EnteredCarouselView)) {
        return {
            ...state,
            currentView: action.payload,
            prevView: undefined,
        };
    }

    if (isType(action, ChangedCurrentCarouselFile)) {
        return {
            ...state,
            currentView: action.payload,
            prevView: state.currentView,
        };
    }

    if (isType(action, CarouselViewSideModuleOpened)) {
        return {
            ...state,
            currentSideModule: action.payload,
        };
    }
    if (isType(action, CarouselViewSideModuleClosed)) {
        return {
            ...state,
            currentSideModule: undefined,
        };
    }

    if (isType(action, FullscreenModeEntered)) {
        return {
            ...state,
            isInFullscreenMode: true,
        };
    }

    if (isType(action, FullscreenModeExited)) {
        return {
            ...state,
            isInFullscreenMode: false,
        };
    }

    return state;

}
