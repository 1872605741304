import {State} from '../store';
import {SideModuleContentType} from './actions';
import {CarouselViewInfo} from './reducer';

// Selectors that only access the relevant substate (CarouselViewerState)

export const getCurrentViewInfo = (state: State): CarouselViewInfo|undefined => state.carouselViewer.currentView;
export const getPreviousViewInfo = (state: State): CarouselViewInfo|undefined => state.carouselViewer.prevView;

export const isSideModuleExpanded = (state: State): boolean => state.carouselViewer.currentSideModule !== undefined;
export const getCurrentSideModuleContent = (state: State): SideModuleContentType|undefined => (
    state.carouselViewer.currentSideModule
);
export const isCarouselViewOpen = (state: State): boolean => state.carouselViewer.currentView !== undefined;
export const isFullscreenMode = (state: State): boolean => state.carouselViewer.isInFullscreenMode;
