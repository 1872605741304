import {createSelector, Selector} from 'reselect';
import {FileTarget, getFileTargetFromName} from '../../utilities/fileTarget';
import { State as GlobalState, StateWithUploaderState as State} from '../store';
import {getViewportHeight} from '../viewMode/selectors';
import {FileInformation, FileUploadStatus, UploaderOnlineStatus} from './reducer';

// Selectors relative to FileInformation (not global state)
const isPicture = (file: FileInformation) => getFileTargetFromName(file.name) === FileTarget.Pictures;
/* const isDocument = (file: FileInformation) => getFileTargetFromName(file.name) === FileTarget.Documents; */
/* const isMovie = (file: FileInformation) => getFileTargetFromName(file.name) === FileTarget.Movies; */

export const isUploadStatusPending = (fileInfo: FileInformation) => fileInfo.status === FileUploadStatus.Pending;
export const isUploadStatusEnqueued = (fileInfo: FileInformation) => fileInfo.status === FileUploadStatus.Enqueued;
export const isUploadStatusSucceeded = (fileInfo: FileInformation) => fileInfo.status === FileUploadStatus.Succeeded;
export const isUploadStatusRejected = (fileInfo: FileInformation) => fileInfo.status === FileUploadStatus.Rejected;
export const isUploadStatusCancelled = (fileInfo: FileInformation) => fileInfo.status === FileUploadStatus.Cancelled;
export const isUploadStatusUploading = (fileInfo: FileInformation) => fileInfo.status === FileUploadStatus.Uploading;

// Global state-selectors
export const isStatusBoxExpanded = (state: State) => state.uploader.isStatusBoxExpanded;
export const isStatusBoxVisible = (state: State) => state.uploader.isStatusBoxVisible;

const selectFilesById = (state: State) => state.uploader.filesByID;
const selectCurrentlyUploadingFiles = (state: State) => state.uploader.currentlyUploadingFiles;

export const getFileInformationByUploaderId = (state: State, id: number): FileInformation|undefined => (
    state.uploader.filesByID[id]
);

export const getAllFiles: Selector<State, FileInformation[]> = createSelector(
    selectFilesById,
    (filesByID) => Object.keys(filesByID).map((id) => filesByID[Number.parseInt(id)]),
);
export const getAllCurrentFiles: Selector<State, FileInformation[]> = createSelector(
    selectFilesById, selectCurrentlyUploadingFiles,
    (allFiles: {[index: number]: FileInformation}, currentFileIDs: number[]) => currentFileIDs.map((id) => allFiles[id]),
);

// Factory-method for building filter-selectors
const allCurrentFilesFiltered = (filters: ((file: FileInformation) => boolean)): Selector<State, FileInformation[]> => {
    return createSelector(
        getAllCurrentFiles,
        (files) => files.filter(filters),
    );
};

export const getPendingFiles = allCurrentFilesFiltered(isUploadStatusPending);
export const getEnquedFiles = allCurrentFilesFiltered(isUploadStatusEnqueued);
export const getCancelledFiles = allCurrentFilesFiltered(isUploadStatusCancelled);
export const getSucceededFiles = allCurrentFilesFiltered(isUploadStatusSucceeded);
export const getRejectedFiles = allCurrentFilesFiltered(isUploadStatusRejected);
const getCurrentlyUploadingFiles = allCurrentFilesFiltered(isUploadStatusUploading);
export const getNotUploadedFiles = (state: State) => getCancelledFiles(state).concat(getRejectedFiles(state));

export const getCurrentlyUploadingFile = (state: State) => getCurrentlyUploadingFiles(state)[0];

export const getCurrentOrNextUploadingFile: (state: State) => FileInformation|undefined = createSelector(
    getCurrentlyUploadingFile,
    getEnquedFiles,
    (uploadingFile, enqFiles) => {
        return uploadingFile || enqFiles[0];
    },
);

export const getFinishedThumbs = createSelector(
    getSucceededFiles,
    (succFiles) => {
        const photos = succFiles.filter(isPicture);
        return photos.length > 0 ? photos.slice(0, 4) : succFiles.slice(0, 1);
    },
);

const isOnlineStatus = (state: State, status: UploaderOnlineStatus) => state.uploader.onlineStatus === status;
export const isOnline = (state: State) => isOnlineStatus(state, UploaderOnlineStatus.Online);
export const isOffline = (state: State) => isOnlineStatus(state, UploaderOnlineStatus.Offline);
export const isRetrying = (state: State) => isOnlineStatus(state, UploaderOnlineStatus.Retrying);

export const isFiltered = (state: State) => state.uploader.isFiltered;
export const isPaused = (state: State) => state.uploader.isPaused;
export const isStopPrompted = (state: State) => state.uploader.isPendingStop;

export const getCurrentlyDoneRatio = (state: State) => {
    const current = getCurrentlyUploadingFile(state);
    const all = getAllCurrentFiles(state).length;
    const waiting = getPendingFiles(state).length + getEnquedFiles(state).length + (current ? 1 - current.uploadedPercent : 0);
    return all === 0 ? 1 : 1 - waiting / all;
};
export const isUploaderDone = (state: State) => getCurrentlyDoneRatio(state) === 1;

export const isCurrentlyUploading = (state: State) => getCurrentlyUploadingFiles(state).length > 0;

export const isFileCancelable = (file: FileInformation) => (file.status === FileUploadStatus.Enqueued) || (file.status === FileUploadStatus.Uploading);

export const getVisibleFiles = createSelector(
    isFiltered,
    getAllCurrentFiles,
    getNotUploadedFiles,
    (isFiltered, allFiles, filesNotUploaded) => {
        if (isFiltered) {
            return filesNotUploaded;
        }
        return allFiles;
    },
);
export const getUploadStatusHeight = createSelector(
    isStatusBoxExpanded,
    getViewportHeight,
    getVisibleFiles,
    (isExpanded, viewHeight, visibleFiles) => {
        const cardHeight = 136;

        if (isExpanded) {
            const entryHeight = 48;
            const bottomListElm = 64;
            const topListElm = 54;
            const listPadding = 8;

            return Math.min(
                viewHeight * 0.75,
                (visibleFiles.length * entryHeight) + listPadding + topListElm + bottomListElm,
            );
        }
        return cardHeight;
    },
);

const spaceBelowUploader = 8;
export const getUploadStatusTopPosition = (state: GlobalState) => getUploadStatusHeight(state) + spaceBelowUploader;
