import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, layout} from '../../assets/styleConstants';
import {ButtonProps} from '../Common/Button';
import {IconButton, IconTextButton} from '../Common/IconTextButton';
import {OverflowMenu} from '../Common/OverflowMenu';
import {CloseIcon} from '../Icons/CloseIcon';
import {EmptyIcon} from '../Icons/EmptyIcon';
import {TopNavBar} from '../Navigation/TopNavBar';

const SelectionToolBarWrapper = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    animation: fadeIn 0.3s ease-in both;

    background-color: ${colors.captureBlue};
    border-radius: 3px;
    padding: 8px 16px;
    box-sizing: border-box;

    color: white;
    font-size: 14px;
`;

const ToolBarWrapperDesktop = styled(SelectionToolBarWrapper)`
    width: 100%;
    height: ${layout.selectionToolbarHeight}px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.2);
    border-radius: 0px;

    position: fixed;
    top: 56px;
    left: 50%;
    transform: translateX(-50%);
`;

const IconWrapper = styled.div`
    cursor: pointer;
`;

const ButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LeftSection = styled(ButtonSection)`
    justify-content: flex-start;

    & > div {
        height: 24px;
        margin-right: 16px;
    }
`;

const RightSection = styled(ButtonSection)`
    justify-content: flex-end;

    & > div {
        margin-left: 24px;
        height: 24px;
    }
`;

const Info = styled.span`
    cursor: default;
`;

type Props = {
    selectedFiles: FileID[],
    cancelSelectMode: () => any,
    buttons: ButtonProps[],
};

const SelectedInfo: React.SFC<{count: number}> = (props) => {
    const text = props.count === 0 ?
        _('select_files') :
        _('selected__format').replace('%d', props.count.toString());
    return <Info>{text}</Info>;
};

export const SelectionToolbarMobile: React.SFC<Props & {overflowOptions?: ButtonProps[]}> = (props) => {
    const visibleButtons = props.buttons.map((b, i) => (
        <IconButton
            key={i}
            onClick={b.onClick}
            icon={b.icon || EmptyIcon}
            color={'white'}
            hoverColor={colors.captureBlue900}
        />
        ),
    );

    const rightSection = props.selectedFiles.length > 0 && (
        <RightSection>
            {visibleButtons}
            {props.overflowOptions && <OverflowMenu menuOptions={props.overflowOptions} iconColor={'white'}/>}
        </RightSection>
    );

    return (
        <TopNavBar>
            <SelectionToolBarWrapper>
                <LeftSection>
                    <IconWrapper onClick={props.cancelSelectMode}>
                        <CloseIcon size={24} color={'white'}/>
                    </IconWrapper>
                    <SelectedInfo count={props.selectedFiles.length} />
                </LeftSection>
                {rightSection}
            </SelectionToolBarWrapper>
        </TopNavBar>
    );
};

export const SelectionToolbarDesktop: React.SFC<Props> = (props) => {
    const content = props.buttons.map((btn, i) => (
            <IconTextButton
                key={i}
                onClick={btn.onClick}
                text={btn.text}
                icon={btn.icon || EmptyIcon}
                color={'white'}
                hoverColor={colors.captureBlue900}
            />
        ));

    return (
        <ToolBarWrapperDesktop>
            <LeftSection>
                <IconButton
                    onClick={props.cancelSelectMode}
                    icon={CloseIcon}
                    color={'white'}
                    hoverColor={colors.captureBlue900}
                />
                <SelectedInfo count={props.selectedFiles.length} />
            </LeftSection>
            <RightSection>
                {content}
            </RightSection>
        </ToolBarWrapperDesktop>
    );
};
