import * as React from 'react';
import {colors} from '../../assets/styleConstants';

/* tslint:disable:max-line-length jsx-self-close */
type CancelIconProps = {
    size: number,
    color?: string,
    opacity?: number,
    stroke?: string,
};

export const CancelIcon: React.SFC<CancelIconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle fillOpacity={props.opacity || 1} fill={props.color || colors.captureGrey800} cx="15" cy="15" r="15"></circle>
            <g transform="translate(3.000000, 3.000000)" strokeWidth="1.2">
                <polygon fill={props.stroke || '#FFFFFF'} points="19 6.4 17.6 5 12 10.6 6.4 5 5 6.4 10.6 12 5 17.6 6.4 19 12 13.4 17.6 19 19 17.6 13.4 12"></polygon>
                <rect x="0" y="0" width={props.size} height={props.size}></rect>
            </g>
        </g>
    </svg>
);
