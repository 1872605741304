import * as React from 'react';
import {colors} from '../../assets';

export class Tooltip extends React.Component<{text: string, position?: string, color?: string}, {isActive?: boolean}> {
    state = {isActive: false};

    render() {

        const tooltipStyle: React.CSSProperties = {
            position: 'absolute',
            color: 'white',
            padding: 6,
            backgroundColor: colors.captureGrey800,
            borderRadius: 2,
            fontSize: 12,
            whiteSpace: 'nowrap',
            transition: 'transform ' + this.state.isActive ? '0.1s' : '0s',
            transitionDelay:  this.state.isActive ? '0.5s' : '',
        };

        switch (this.props.position || 'bottom'){
            case 'bottom':
                Object.assign(tooltipStyle, {
                    left: '50%',
                    transform: 'translateX(-50%) ' + (this.state.isActive ? 'scale(1)' : 'scale(0)'),
                    marginTop: 4,
                });
                break;
            case 'top':
                Object.assign(tooltipStyle, {
                    top: -8,
                    left: '50%',
                    transform: 'translate(-50%, -100%) ' + (this.state.isActive ? 'scale(1)' : 'scale(0)'),
                });
                break;
            case 'right':
                Object.assign(tooltipStyle, {
                    top: '50%',
                    left: '100%',
                    marginLeft: 8,
                    transform: 'translateY(-50%) ' + (this.state.isActive ? 'scale(1)' : 'scale(0)'),
                });
                break;
            case 'left':
                Object.assign(tooltipStyle, {
                    top: '50%',
                    right: '100%',
                    marginRight: 8,
                    transform: 'translateY(-50%) ' + (this.state.isActive ? 'scale(1)' : 'scale(0)'),
                });
                break;
        }

        return <div>
            <div onMouseEnter={() => this.setState({isActive: true})} onMouseLeave={() => this.setState({isActive: false})} onClick={() => this.setState({isActive: false})}>
                {this.props.children}
            </div>
            <div style={tooltipStyle} >{this.props.text}</div>
        </div>;
    }
}
