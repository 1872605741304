import {Store} from 'redux';
import {whenNetworkGoesOffline} from '../../API/toolbox';
import {FileTarget, getFileTargetFromName} from '../../utilities/fileTarget';
import {consoleLog} from '../../utilities/logging';
import {Dispatch} from '../common/actions';
import {getAvailableStorage, isLoggedIn, isOutOfStorage} from '../currentUser/selectors';
import {isStoryJob} from '../jobInfo/selectors';
import {State, StateWithCurrentUserState, StateWithUploaderState} from '../store';
import {
    AddedMoreFilesThanAvailableStorage, FileUploadFailed, FileUploadProgress, FileUploadRetry,
    FileUploadStarted, FileUploadSucceeded,
    FileWasAcceptedToUploadQueue, FileWasAddedPayload, FileWasAddedToUploadQueue, FileWasRejected,
    FileWasRemovedFromUploadQueue, UploaderFinished, UploaderPaused,
    UploaderResumed, UploaderStatusBoxShown, UploaderStopped,
} from './actions';
import {FileInformation, RejectReason} from './reducer';
import {
    getEnquedFiles, isCurrentlyUploading,
    isOffline, isPaused,
    isStopPrompted, isUploaderDone,
} from './selectors';
import {getFileURLWithCorrectOrientation} from './uploadFileURL';

export type UploadResponse = {
    uuid: string,
    timestamp: string,
    used_space: number,
    height: number,
    width: number,
};

type UploadFunction = (f: File, i: FileInformation) => AbortablePromise<UploadResponse>;
type UploadCheck = (file: File, targetJob: JobID) => Promise<any>;
type MultiUploadCheck = (files: File[], targetJob: JobID, targetFolder?: string) => Promise<any>;

// Combining the state required for the uploader to work
export type UploadQueueCompatibleState = StateWithUploaderState & StateWithCurrentUserState;

export const checkFolder: UploadCheck = (file: File) => {
    /* Folder-check:
    * FileReader will not be able to read a folder (while it will be ok with any file).
    * Therefore; try to read the first 64 bytes of data from the file - if that fails the user most likely
    * added a folder (or something else that will fail to upload) so reject it from the upload queue.
    * If FileReader does not exist (legacy browsers) - or for some other reason causes a real Exception - accept
    * the file as we failed to assert if it was a folder (and most likely is a legitimate file anyway)
    */
    return new Promise((resolve, reject) => {
        try {
            const fr = new FileReader();
            fr.onload = () => {resolve(); };
            fr.onerror = () => {reject(RejectReason.FileIsFolder); };
            fr.readAsBinaryString(file.slice(0, 64));
        }
        catch (e) {
            // No file-reader? We accept the upload by default [to actually function in these cases]
            resolve();
        }
    });
};
export const makeFileTypeCheck =
    (allowedFileTypes: FileTarget[] = [FileTarget.Pictures, FileTarget.Movies]): UploadCheck  =>
 (file: File) => {
    return new Promise((resolve, reject) => {
        const fileType = getFileTargetFromName(file.name);
        if (allowedFileTypes.some((type) => fileType === type)) {
            resolve();
        } else {
            reject(RejectReason.UnSupported);
        }
    });
};

export const makeEnforceAvailableStorageCheck = (store: Store<State>): MultiUploadCheck => (
    async (files: File[], jobID: JobID) => {
        if (isStoryJob(store.getState(), jobID) && !isLoggedIn(store.getState())) {
            return; // Allow uploads from non-logged-in-users even if they do not have space
        }
        const availableStorage = getAvailableStorage(store.getState());
        const totalSize = files.reduce((sum, file) => sum + file.size, 0);
        if (totalSize > availableStorage) {
            store.dispatch(AddedMoreFilesThanAvailableStorage());
            throw new Error('Rejecting file additions: There is not enough space');
        }
    }
);

export enum UploadFailedReason {NetworkError, FileError, OutOfStorage}
export class UploadError extends Error {
    constructor(public reason: UploadFailedReason, public message: string) {
        super(message);
    }
}

class UploadQueue {
    private files: File[] = [];
    private previewThumbs: DictionaryOf<string> = {};
    private currentlyUploading?: FileInformation;
    private abortCurrent?: () => any;

    constructor(private store: Store<UploadQueueCompatibleState>,
                private uploadFunction: UploadFunction,
                private uploadChecks: UploadCheck[],
                private multiUploadChecks: MultiUploadCheck[],
    ) {
        this.addFile = this.addFile.bind(this);
        this.stop = this.stop.bind(this);
    }

    private handleEnquedUploading = (fileToUpload: FileInformation) => {
        this.currentlyUploading = fileToUpload;
        this.store.dispatch(FileUploadStarted({fileID: fileToUpload.id}));
        this.store.dispatch(UploaderStatusBoxShown());
        const call = this.uploadFunction(this.files[fileToUpload.id], fileToUpload);
        this.abortCurrent = call.abort;

        call.then(
            (resolved: UploadResponse) => {
                this.abortCurrent = undefined;
                this.currentlyUploading = undefined;
                this.store.dispatch(FileUploadSucceeded({
                    fileID: fileToUpload.id,
                    fileUUID: resolved.uuid,
                    usedStorage: resolved.used_space,
                }));
            },
            (error: UploadError) => {
                this.abortCurrent = undefined;
                this.currentlyUploading = undefined;
                switch (error.reason) {
                    case UploadFailedReason.FileError:
                        this.store.dispatch(FileWasRejected({
                            fileID: fileToUpload.id,
                            reason: RejectReason.UnSupported,
                        }));
                        break;
                    case UploadFailedReason.OutOfStorage:
                        this.store.dispatch(FileWasRejected({
                            fileID: fileToUpload.id,
                            reason: RejectReason.NoStorage,
                        }));
                        break;
                    default:
                    case UploadFailedReason.NetworkError:
                        this.store.dispatch(FileUploadFailed({fileID: fileToUpload.id, message: error.message}));
                }
            },
        ).then(() => {
            if (isUploaderDone(this.store.getState())) {
                this.store.dispatch(UploaderFinished());
            }
        });
    }

    public digestNewState(newState: UploadQueueCompatibleState) {
        if (!isPaused(newState)
            && !isStopPrompted(newState)
            && !isOutOfStorage(newState)
            && !isOffline(newState)
            && !isCurrentlyUploading(newState)
            && getEnquedFiles(newState).length > 0
        ) {
            this.handleEnquedUploading(getEnquedFiles(newState)[0]);
        }
    }

    public addFiles(files: File[], targetJob: JobID, targetFolder?: string) {
        Promise.all(this.multiUploadChecks.map((check) => check(files, targetJob, targetFolder))).then(
            () => files.forEach((file) => this.addFile(file, targetJob, targetFolder)),
            () => {/* If the checks failed, the user should get notified by the UI-changes, swallow error here */},
        );
    }

    public addFile(file: File, targetJob: JobID, targetFolder?: string): Promise<any> {
        const id = this.files.length;
        this.files[id] = file;

        const checksInSerial = (checks: UploadCheck[]): Promise<any> => checks.reduce(
            (prev: Promise<any>, c) => prev.then(() => c(file, targetJob)), Promise.resolve(''),
        );

        const newFile: FileWasAddedPayload = {
            id,
            targetJob,
            targetFolder,
            name: file.name,
            size: file.size,
        };

        this.store.dispatch(FileWasAddedToUploadQueue(newFile));
        return checksInSerial(this.uploadChecks)
            .then(() => {
                this.store.dispatch(FileWasAcceptedToUploadQueue({fileID: id}));
            }).catch((reason: RejectReason) => {
                this.store.dispatch(FileWasRejected({fileID: id, reason}));
            });
    }

    public async getUploadFilePreviewThumb(id: number): Promise<string> {
        const file = this.files[id];
        if (file) {
            if (this.previewThumbs[id] === undefined) {
                this.previewThumbs[id] = await getFileURLWithCorrectOrientation(file);
            }

            return this.previewThumbs[id];
        }

        return Promise.reject('file does not exist');
    }

    public clearUploadFiles() {
        Object.keys(this.previewThumbs).forEach((id) => {
            const thumb = this.previewThumbs[id];
            window.URL.revokeObjectURL(thumb);
        });

        this.previewThumbs = {};
        this.files = [];
    }

    public retryUpload() {
        this.store.dispatch(FileUploadRetry());
    }

    public pause() {
        this.store.dispatch(UploaderPaused());
    }
    public resume() {
        this.store.dispatch(UploaderResumed());
    }

    public stop() {
        if (this.abortCurrent) {
            this.abortCurrent();
            this.abortCurrent = undefined;
        }
        this.store.dispatch(UploaderStopped());
    }

    public removeFile(fileID: number) {
        this.store.dispatch(FileWasRemovedFromUploadQueue({fileID}));
        if (this.currentlyUploading && this.currentlyUploading.id === fileID && this.abortCurrent) {
            this.abortCurrent();
        }
    }

}

export const getConnectedInstance = (): UploadQueue => {
    if (!window.uploadQueueInstance) {
        throw new Error('Must connectUploadQueue before fetching the connectedInstance');
    }
    return window.uploadQueueInstance;
};

export function connectUploadQueue(
    store: Store<UploadQueueCompatibleState>,
    uploadFunction: UploadFunction,
    checks: UploadCheck[] = [],
    multiChecks: MultiUploadCheck[] = [],
): UploadQueue {
    const q = new UploadQueue(store, uploadFunction, checks, multiChecks);
    store.subscribe(() => q.digestNewState(store.getState()));
    if (typeof(window) !== 'undefined') {
        window.uploadQueueInstance = q;
    }
    return q;
}

/**
 * For an UploadFunction that accepts the request as an argument and uses that for the upload:
 * Make the Promise of an UploadFunctionResolveValue into an abortable and progress-tracking Promise
 */
export type UploadMethod = (f: File, i: FileInformation, r?: XMLHttpRequest) => Promise<UploadResponse>;
export type UploadDecorator = (u: UploadMethod) => UploadMethod;
export const makeUploadFunction = (dispatch: Dispatch, doUpload: UploadMethod): UploadFunction => {
    let request: XMLHttpRequest | undefined;

    // If other requests detects that the network is missing, abort the uploader one.
    // The request may still be hanging for some time before it is aborted (as the connection have been made and the efforts to keep it may leave it hanging for several minutes)
    whenNetworkGoesOffline(() => {
        if (request) {consoleLog('aborting request as internet is gone'); request.abort(); request = undefined; }
    });

    return (f: File, i: FileInformation) => {
        request = new XMLHttpRequest();
        let isTimeBlocked = false; // Avoid triggering too many actions within the same timeframe
        let lastDispatchedDoneRatio = 0; // ... or when the change doesn't matter
        request.upload.addEventListener('progress', (event: ProgressEvent) => {
            if (event.loaded && event.total) {
                const doneRatio = event.loaded / event.total;
                if (!isTimeBlocked && doneRatio - lastDispatchedDoneRatio > 0.01) {
                    lastDispatchedDoneRatio = doneRatio;
                    isTimeBlocked = true;
                    setTimeout(() => {isTimeBlocked = false; }, 3);
                    dispatch(FileUploadProgress({fileID: i.id, percentComplete: doneRatio}));
                }
            }
        });

        return Object.assign(
            doUpload(f, i, request).then(
                (resp: any) => {
                    request = undefined;
                    return resp;
                },
                (err: any) => {
                    request = undefined;
                    throw err;
                },
            ),
            {
                abort: () => {
                    if (request && lastDispatchedDoneRatio !== 1) {
                        request.abort();
                    }
                },
            },
        );
    };
};
