import * as React from 'react';
import styled from 'styled-components';
import {CaptureFile} from '../../state/files/reducer';
import {GridElementProps, GridStyle} from '../../utilities/gridElementSizeCalculator';
import {GridElement} from '../GridView/GridElement';

type ThumbWrapperProps = {width: number, alignCentered: boolean};
const ThumbWrapper = styled.div`
    margin: 0 auto;
    width: ${(props: ThumbWrapperProps) => props.width}px;
    margin: 16px auto 24px auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props) => props.alignCentered ? 'center' : 'flex-start'};
`;
const Thumb = styled(GridElement)`
    cursor: pointer;
`;

type Props = {
    files: CaptureFile[],
    gridStyle: GridStyle,
    enterCarouselView: (fileID: FileID) => any,
    isMobileDisplay: boolean,
};

export class ThumbSection extends React.Component<Props, {}> {

    public render() {

        const photos = this.props.files.map((photo) => (
            <Thumb
                key={photo.fileID}
                style={{backgroundImage: `url('${photo.thumbURLSmall}')`}}
                elementWidth={this.props.gridStyle.elementWidth}
                elementHeight={this.props.gridStyle.elementHeight}
                elementSpaceAround={this.props.gridStyle.elementSpaceAround}
                onClick={() => this.props.enterCarouselView(photo.fileID)}
            />
        ));

        return (
            <ThumbWrapper width={this.props.gridStyle.width} alignCentered={this.props.files.length <= 4}>
                {photos}
            </ThumbWrapper>
        );
    }
}
