import {createSelector} from 'reselect';
import {State} from '../store';

export const getHostDirectoryForJob = (state: State, job: JobID) => state.hosts[job] || null;

export const getAppHostForJob   = (state: State, job: JobID) => job in state.hosts ? state.hosts[job].appHost : null;
export const getPollHostForJob  = (state: State, job: JobID) => job in state.hosts ? state.hosts[job].pollHost : null;
export const getThumbHostForJob = (state: State, job: JobID) => job in state.hosts ? state.hosts[job].thumbHost : null;
export const getVideoHostForJob = (state: State, job: JobID) => job in state.hosts ? state.hosts[job].videoHost : null;

export const getThumbHosts = createSelector(
    (state: State) => state.hosts,
    (hosts) => {
        const result: DictionaryOf<string> = {};
        Object.keys(hosts).forEach((jobID) => {
            result[jobID] = hosts[jobID].thumbHost;
        });
        return result;
    },
);
