import * as React from 'react';
import styled from 'styled-components';
import {_, colors} from '../../assets';
import {fontSize, mediaQueries} from '../../assets/styleConstants';
import {UserInfo} from '../../state/users/selectors';
import {Avatar} from '../Common/Avatar';
import {EditableInput} from '../Common/EditableInput';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: 8px;
    box-sizing: border-box;
    width: 100%;

    ${mediaQueries.mobile} {
        flex-direction: column;
        width: 100%;
        padding-left: 0;
    }
`;

const ProfilePictureElement = styled.div`
    margin-right: 32px;

    ${mediaQueries.mobile} {
        margin-right: 0px;
    }
`;
const NameElement = styled.div`
    margin-top: 8px;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    border-bottom: 1px solid;
    border-color: ${(props: {isInFocus: boolean, isHovering: boolean}) => props.isInFocus ? colors.captureBlue : props.isHovering ? colors.captureGrey300 : 'transparent'};
    box-shadow: ${(props) => props.isHovering && !props.isInFocus ? '0px 3px 1px -2px ' + colors.captureGrey300 : 'none'};
    max-width: 80%;
    min-width: 230px;
    font-size: ${fontSize.large_24};
    
    ${mediaQueries.mobile} {
        font-size: ${fontSize.medium_18};
        max-width: 100%;
    }
`;

type Props = {
    userInfo: UserInfo,
    onChangeProfileName: (name: string) => any,
    isMobile: boolean,
};
type ComponentState = {
    showEditableName: boolean,
    inputIsInFocus: boolean,
};
export class ProfileSettings extends React.Component<Props, ComponentState> {
    public state: ComponentState = {showEditableName: false, inputIsInFocus: false};

    private inputField = React.createRef<HTMLDivElement>();
    private onChangeName = (name: string) => {
        this.props.onChangeProfileName(name);
        this.setState({showEditableName: false, inputIsInFocus: false});
    }
    private handleMouseEnter = () => {
        this.setState({...this.state, showEditableName: true});
    }
    private handleMouseLeave = () => {
        if (!this.state.inputIsInFocus) {
            this.setState({...this.state, showEditableName: false});
        }
    }
    private handleFocus = () => {
        this.setState({...this.state, inputIsInFocus: true});
    }

    public render() {

        const profilePicture = this.props.userInfo.profilePicture && (
            <ProfilePictureElement>
                <Avatar
                    size={96}
                    name={this.props.userInfo.name}
                    img={this.props.userInfo.profilePicture}
                />
            </ProfilePictureElement>
        );

        return (
            <Wrapper>
                {profilePicture}
                <NameElement
                    innerRef={this.inputField}
                    isInFocus={this.state.inputIsInFocus}
                    isHovering={this.state.showEditableName}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >
                    <EditableInput
                        value={this.props.userInfo.name}
                        placeholder={_('enter_your_name')}
                        onBlur={this.onChangeName}
                        onFocus={this.handleFocus}
                        iconColor={this.state.inputIsInFocus ? colors.captureBlue : colors.captureGrey500}
                        alignCentered={this.props.isMobile}
                        iconSize={24}
                    />
                </NameElement>
            </Wrapper>
        );
    }
}
