import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

export const DeleteIcon: React.SFC<IconProps> = (props) => {
    const color = props.color || colors.defaultIconColor;
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path fill={color} d="M6,18 C6,19.1 6.9,20 8,20 L16,20 C17.1,20 18,19.1 18,18 L18,6 L6,6 L6,18 Z M19,3 L15.5,3 L14.5,2 L9.5,2 L8.5,3 L5,3 L5,5 L19,5 L19,3 Z"/>
            </g>
        </svg>
    );
};
