import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {fontSize} from '../../assets/styleConstants';
import {Pages} from '../../routing';
import {AlbumSharingInitiated} from '../../state/album/actions';
import {AlbumListFilterSet} from '../../state/albumList/actions';
import {AlbumListFilter} from '../../state/albumList/reducer';
import {getAlbumListFilter} from '../../state/albumList/selectors';
import {AlbumLinkWasCopied, AlbumOptionsOverlayClosed} from '../../state/albumOptions/actions';
import {AlbumOptions} from '../../state/albumOptions/reducer';
import {getPendingAlbumOptionsOverlay} from '../../state/albumOptions/selectors';
import {NavigateTo} from '../../state/routing/actions';
import {State} from '../../state/store';
import {isMobileMode} from '../../state/viewMode/selectors';
import {AlbumParticipantsList} from '../Album/AlbumParticipantsList';
import {UsersLoveFileList} from '../Album/UsersLoveFileList';
import {albumListFilterOptions} from '../AlbumList/AlbumListFilterButton';
import {Button} from '../Common/Button';
import {SlideUpChoiceMenu} from '../Common/ChoiceMenu';
import {OptionsOverlay} from '../Common/Overlay';
import {RadioButtonGroup} from '../Common/RadioButtonGroup';
import {AlbumSortModePrompt} from './AlbumSortModePrompt';
import {EditAlbumSettings} from './EditAlbumSettings';
import {ShareAlbumPrompt} from './ShareAlbumPrompt';
import {ShareURLToOptions} from './ShareToOptions';

const OptionEntry = styled.div`
    font-size: ${fontSize.small_14};
    padding: 12px 0;
`;
type StateProps = {
    optionsOverlay?: AlbumOptions,
    isMobile: boolean,
    currentFilter: AlbumListFilter,
};
type DispatchProps = {
    dismissOptionsOverlay: () => any,
    selectFilter: (filter: AlbumListFilter) => any,
    privateWasSelected: () => any,
    sharedWasSelected: () => any,
    doShareInitiate: (albumID: JobID) => any,
    onLinkCopied: () => any,
};
type Props = StateProps & DispatchProps;

class _AlbumOptionsPlacement extends React.Component<Props, {}> {
    public componentWillUnmount() {
        if (this.props.optionsOverlay) {
            this.props.dismissOptionsOverlay();
        }
    }

    public render() {
        if (!this.props.optionsOverlay) {
            return null;
        }

        switch (this.props.optionsOverlay.type) {
            case 'share':
                return (
                    <OptionsOverlay
                        onClose={this.props.dismissOptionsOverlay}
                        cancelButton={Button(_('close'), this.props.dismissOptionsOverlay)}
                        isMobile={this.props.isMobile}
                    >
                        <ShareURLToOptions
                            sharePage={Pages.AlbumNotLoggedIn(this.props.optionsOverlay.albumID)}
                            doClose={this.props.dismissOptionsOverlay}
                            onLinkCopied={this.props.onLinkCopied}
                        />
                    </OptionsOverlay>
                );
            case 'share_prompt':
                return (
                    <ShareAlbumPrompt
                        albumID={this.props.optionsOverlay.albumID}
                        onShareNow={this.props.doShareInitiate}
                        onShareLater={this.props.dismissOptionsOverlay}
                    />
                );
            case 'settings':
                return (
                    <EditAlbumSettings
                        onClose={this.props.dismissOptionsOverlay}
                        albumID={this.props.optionsOverlay.albumID}
                    />
                );
            case 'albums_filter':
                return this.props.isMobile && (
                    <SlideUpChoiceMenu title={_('filter_by')} onClose={this.props.dismissOptionsOverlay}>
                        <RadioButtonGroup
                            value={this.props.currentFilter}
                            options={albumListFilterOptions}
                            onSelectOption={this.props.selectFilter}
                        />
                    </SlideUpChoiceMenu>
                );
            case 'create_album':
                return this.props.isMobile && (
                    <SlideUpChoiceMenu title={_('create_new')} onClose={this.props.dismissOptionsOverlay}>
                        <OptionEntry onClick={this.props.privateWasSelected}>{_('private_album')}</OptionEntry>
                        <OptionEntry onClick={this.props.sharedWasSelected}>{_('shared_album')}</OptionEntry>
                    </SlideUpChoiceMenu>
                );
            case 'loveList':
                return (
                    <UsersLoveFileList
                        fileID={this.props.optionsOverlay.fileID}
                        isMobile={this.props.isMobile}
                        onClose={this.props.dismissOptionsOverlay}
                    />
                );
            case 'participantsList':
                return (
                    <AlbumParticipantsList
                        albumID={this.props.optionsOverlay.albumID}
                        isMobile={this.props.isMobile}
                        onClose={this.props.dismissOptionsOverlay}
                    />
                );
            case 'album_files_sort':
                return (
                    <AlbumSortModePrompt
                        albumID={this.props.optionsOverlay.albumID}
                        onClose={this.props.dismissOptionsOverlay}
                    />
                );
            default:
                return null;
        }
    }
}

const stateToProps = (state: State): StateProps => {
    const optionsOverlay = getPendingAlbumOptionsOverlay(state);

    return ({
        optionsOverlay,
        isMobile: isMobileMode(state),
        currentFilter: getAlbumListFilter(state),
    });
};
const dispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    dismissOptionsOverlay: () => dispatch(AlbumOptionsOverlayClosed()),
    selectFilter: (filter: AlbumListFilter) => {
        dispatch(AlbumListFilterSet(filter));
        dispatch(AlbumOptionsOverlayClosed());
    },
    privateWasSelected: () => {
        dispatch(NavigateTo(Pages.CreatePrivateAlbum));
        dispatch(AlbumOptionsOverlayClosed());
    },
    sharedWasSelected: () => {
        dispatch(NavigateTo(Pages.CreateSharedAlbum));
        dispatch(AlbumOptionsOverlayClosed());
    },
    doShareInitiate: (albumID: JobID) => dispatch(AlbumSharingInitiated(albumID)),
    onLinkCopied: () => dispatch(AlbumLinkWasCopied()),
});

export const AlbumOptionsPlacement = connect(stateToProps, dispatchToProps)(_AlbumOptionsPlacement);
