import * as React from 'react';
import styled from 'styled-components';
import {InvisibleFileInput} from './InvisibleFileInput';

const Wrapper = styled.span`
    cursor: pointer;
    display: inherit;
`;

export class FileAcceptElement extends React.Component<{fileHandler: (files: File[]) => any}, {}> {
    private fileInput: InvisibleFileInput|null = null;

    private bindFileInput = (me: InvisibleFileInput|null) => {
        this.fileInput = me;
    }

    private handleClick = () => {
        if (this.fileInput) {
            this.fileInput.triggerFileDialog();
        }
    }

    public render() {
        return (
            <Wrapper onClick={this.handleClick}>
                <InvisibleFileInput
                    ref={this.bindFileInput}
                    fileHandler={this.props.fileHandler}
                />
                {this.props.children}
            </Wrapper>
        );
    }
}
