import * as React from 'react';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const LinkIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="link-linearGradient">
                <stop stopColor="#FAD961" offset="0%" />
                <stop stopColor="#F76B1C" offset="100%" />
            </linearGradient>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="url(#link-linearGradient)" x="0" y="0" width={props.size} height={props.size} rx="3" />
            <g transform="translate(7.000000, 7.000000)" fill="#FFFFFF" fillRule="nonzero">
                <path d="M31.4519028,2.47782416 C28.0783527,-0.825941385 22.5514727,-0.825941385 19.1779226,2.47782416 L12.5503736,8.96820043 C14.8233329,8.33556448 17.2877775,8.4995812 19.4411073,9.48368158 L22.8863569,6.10962315 C24.2262066,4.79748932 26.4034624,4.79748932 27.743312,6.10962315 C29.0831617,7.42175699 29.0831617,9.55397446 27.743312,10.8661083 L23.4127264,15.1071123 L20.5655459,17.8953967 C19.2256962,19.2075305 17.0484404,19.2075305 15.7085125,17.8953967 L12,21.5271957 C12.8613319,22.3707869 13.8662192,23.0033462 14.9189583,23.4016726 C17.766217,24.5029278 21.0679895,24.0811705 23.5562818,22.1597551 C23.7955406,21.9723839 24.0587254,21.7615053 24.2740584,21.5271191 L28.7481993,17.1455293 L31.4518246,14.4978307 C34.8493788,11.1941418 34.8493788,5.80502066 31.4519028,2.47782416 Z" />
                <path d="M14.4091734,24.6589258 L11.1046837,27.8916723 C9.76373127,29.203588 7.58468365,29.203588 6.24373127,27.8916723 C4.90277889,26.5798331 4.90277889,24.448171 6.24373127,23.1362553 L13.4274047,16.108622 C14.7683571,14.7967829 16.9474047,14.7967829 18.2884354,16.108622 L22,12.4776386 C21.1379592,11.6343134 20.1322449,11.0018195 19.0786395,10.6035827 C16.0854422,9.43229768 12.5654422,9.97108877 10.0511565,12.1731045 C9.93142857,12.2668073 9.81170068,12.3839358 9.71591837,12.4776386 L2.5322449,19.5052719 C-0.844081633,22.8082955 -0.844081633,28.2196321 2.5322449,31.5227323 C5.90857143,34.8257559 11.44,34.8257559 14.8163265,31.5227323 L21.3056565,25.1040141 C18.0010102,25.8535599 17.2347517,25.7599337 14.4091734,24.6589258 Z" />
            </g>
        </g>
    </svg>
);
