import {getElementIndex} from '../../utilities/arrayUtils';
import {AutoGeneratedAlbumFinished, DownloadAlbumFailed} from '../album/actions';
import {AlbumLinkWasCopied} from '../albumOptions/actions';
import {Action, isType} from '../common/actions';
import {
    FilesCopiedToTimeline, FilesCopiedToTimelineFailed, FilesWereShared,
} from '../files/actions';
import {
    FilesCopiedToAlbum, FilesCopiedToAlbumFailed,
    FilesDeletionSucceeded, FilesDownloadFailed,
    FilesRestorationFailed, FilesRestorationSucceeded,
    FileWasSetAsCoverPhoto, JobCopiedToTimeline,
    JobCopiedToTimelineFailed, ShareCreationFailed,
} from '../job/actions';
import {ShareCopiedToTimeline, ShareCopiedToTimelineFailed} from '../share/actions';
import {
    CreditCardUpdated, CreditCardUpdateFailed,
    PlanCanceled, PlanCancelFailed,
    PlanChangeFailed, PlanChangeSucceeded,
    PlanReactivated, PlanReactivationFailed,
    PurchaseFailed, PurchaseSucceeded,
} from '../storagePlan/actions';
import {TrashFilesDeletionFailed, TrashFilesDeletionSucceeded} from '../trash/actions';
import {LongRunningTaskFinished, LongRunningTaskStarted, StatusNotificationDismissed} from './actions';

type StatusNotificationBase<T extends string> = {id: number, type: T};
export type PendingStatusType = 'filesAreBeingDeleted' | 'filesAreBeingRestored' | 'filesAreBeingCopied';
type PendingNotification = StatusNotificationBase<PendingStatusType>;

export type StatusNotification
    = StatusNotificationBase<'albumCoverPhotoSet'> & {relatedFile: FileID}
    | StatusNotificationBase<'filesDeleted'> & {relatedJob: JobID, relatedFiles: FileID[]}
    | StatusNotificationBase<'trashFilesDeleted'> & {numFiles: number}
    | StatusNotificationBase<'trashFilesDeletionFailed'> & {relatedFiles: FileID[]}
    | StatusNotificationBase<'filesWereRestored'> & {numFiles: number}
    | StatusNotificationBase<'filesRestorationFailed'> & {relatedJob: JobID, relatedFiles: FileID[]}
    | StatusNotificationBase<'autoCreatedAlbum'> & {relatedJobID: JobID, relatedJobName: string}
    | StatusNotificationBase<'jobCopiedToTimeline'>
    | StatusNotificationBase<'jobCopiedToTimelineFailed'>
    | StatusNotificationBase<'filesCopiedToTimeline'>
    | StatusNotificationBase<'filesCopiedToTimelineFailed'>
    | StatusNotificationBase<'filesCopiedToAlbum'> & {relatedJob: JobID, relatedFiles: FileID[]}
    | StatusNotificationBase<'filesCopiedToAlbumFailed'> & {relatedJob: JobID, relatedFiles: FileID[]}
    | StatusNotificationBase<'shareCopiedToTimeline'>
    | StatusNotificationBase<'shareCopiedToTimelineFailed'>
    | StatusNotificationBase<'shareCreationFailed'>
    | StatusNotificationBase<'filesWereShared'>
    | StatusNotificationBase<'filesDownloadFailed'>
    | StatusNotificationBase<'linkWasCopied'>
    | StatusNotificationBase<'purchaseSuccessful'>
    | StatusNotificationBase<'purchaseFailed'>
    | StatusNotificationBase<'creditCardUpdated'>
    | StatusNotificationBase<'creditCardUpdateFailed'>
    | StatusNotificationBase<'planCanceled'>
    | StatusNotificationBase<'planCancelFailed'>
    | StatusNotificationBase<'planChangeSucceeded'>
    | StatusNotificationBase<'planChangeFailed'>
    | StatusNotificationBase<'planReactivated'>
    | StatusNotificationBase<'planReactivationFailed'>
    | PendingNotification
    ;

export type StatusNotificationState = {
    _nextID: number,
    messages: StatusNotification[],
};

const initialState: StatusNotificationState = {
    _nextID: 0,
    messages: [],
};

const appendStatusNotification = (state: StatusNotificationState, msg: StatusNotification) => {
    return {
        ...state,
        _nextID: state._nextID + 1,
        messages: state.messages.concat(msg),
    };
};

const removePendingStatusNotificaton = (state: StatusNotificationState, pendingType: PendingStatusType) => {
    const pendingMsgIndex = getElementIndex(state.messages, (m) => m.type === pendingType);
    return {
        ...state,
        messages: state.messages.filter((_m, i) => i !== pendingMsgIndex),
    };
};

export const StatusNotificationsReducer = (
    state: StatusNotificationState = initialState,
    action: Action<any>,
): StatusNotificationState => {

    if (isType(action, FileWasSetAsCoverPhoto)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'albumCoverPhotoSet',
            relatedFile: action.payload.fileID,
        });
    }

    if (isType(action, LongRunningTaskStarted)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: action.payload,
        });
    }

    if (isType(action, LongRunningTaskFinished)) {
        return removePendingStatusNotificaton(state, action.payload);
    }

    if (isType(action, FilesDeletionSucceeded)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesDeleted',
            relatedJob: action.payload.jobID,
            relatedFiles: action.payload.files,
        });
    }

    if (isType(action, TrashFilesDeletionSucceeded)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'trashFilesDeleted',
            numFiles: action.payload.length,
        });
    }
    if (isType(action, TrashFilesDeletionFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'trashFilesDeletionFailed',
            relatedFiles: action.payload,
        });
    }

    if (isType(action, StatusNotificationDismissed)) {
        return {
            ...state,
            messages: state.messages.filter((m) => (m.id !== action.payload)),
        };
    }

    if (isType(action, JobCopiedToTimeline)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'jobCopiedToTimeline',
        });
    }

    if (isType(action, JobCopiedToTimelineFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'jobCopiedToTimelineFailed',
        });
    }

    if (isType(action, FilesCopiedToTimeline)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesCopiedToTimeline',
        });
    }

    if (isType(action, FilesCopiedToTimelineFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesCopiedToTimelineFailed',
        });
    }

    if (isType(action, FilesCopiedToAlbum)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesCopiedToAlbum',
            relatedJob: action.payload.jobID,
            relatedFiles: action.payload.files,
        });
    }

    if (isType(action, FilesCopiedToAlbumFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesCopiedToAlbumFailed',
            relatedJob: action.payload.jobID,
            relatedFiles: action.payload.files,
        });
    }

    if (isType(action, ShareCopiedToTimeline)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'shareCopiedToTimeline',
        });
    }
    if (isType(action, ShareCopiedToTimelineFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'shareCopiedToTimelineFailed',
        });
    }

    if (isType(action, ShareCreationFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'shareCreationFailed',
        });
    }

    if (isType(action, FilesWereShared)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesWereShared',
        });
    }

    if (isType(action, FilesDownloadFailed) || isType(action, DownloadAlbumFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesDownloadFailed',
        });
    }

    if (isType(action, FilesRestorationSucceeded)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesWereRestored',
            numFiles: action.payload.files.length,
        });
    }

    if (isType(action, FilesRestorationFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'filesRestorationFailed',
            relatedJob: action.payload.jobID,
            relatedFiles: action.payload.files,
        });
    }
    if (isType(action, AlbumLinkWasCopied)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'linkWasCopied',
        });
    }
    if (isType(action, AutoGeneratedAlbumFinished)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'autoCreatedAlbum',
            relatedJobID: action.payload.jobID,
            relatedJobName: action.payload.name,
        });
    }
    if (isType(action, PurchaseSucceeded)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'purchaseSuccessful',
        });
    }
    if (isType(action, PurchaseFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'purchaseFailed',
        });
    }
    if (isType(action, CreditCardUpdated)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'creditCardUpdated',
        });
    }
    if (isType(action, CreditCardUpdateFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'creditCardUpdateFailed',
        });
    }
    if (isType(action, PlanCanceled)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'planCanceled',
        });
    }
    if (isType(action, PlanCancelFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'planCancelFailed',
        });
    }
    if (isType(action, PlanChangeSucceeded)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'planChangeSucceeded',
        });
    }
    if (isType(action, PlanChangeFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'planChangeFailed',
        });
    }
    if (isType(action, PlanReactivated)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'planReactivated',
        });
    }
    if (isType(action, PlanReactivationFailed)) {
        return appendStatusNotification(state, {
            id: state._nextID,
            type: 'planReactivationFailed',
        });
    }

    return state;
};
