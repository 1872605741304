import * as React from 'react';

type CircleBtnProps = {
    size: number,
};

export const PrevCircleButton: React.SFC<CircleBtnProps> = (props) =>
    <svg width={props.size} height={props.size} viewBox="0 0 68 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle fill="#000000" cx="34" cy="34" r="34"></circle>
            <g transform="translate(26.000000, 19.000000)" strokeWidth="3" stroke="#FFFFFF">
                <polyline transform="translate(7.703429, 14.533627) scale(-1, 1) translate(-7.703429, -14.533627) " points="0.667194006 0 15.0471024 14.3799084 0.359755834 29.0672549"></polyline>
            </g>
        </g>
    </svg>;

type ArrowBtnProps = {
    width: number,
    height: number,
};

export const PrevArrowButton: React.SFC<ArrowBtnProps> = (props) => (
    <svg width={props.width} height={props.height} style={{filter: 'drop-shadow( 0 1px 1px #000000)'}} viewBox="0 0 20 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-15.000000, -618.000000)" strokeWidth="3" stroke="#FFFFFF">
                <g transform="translate(24.000000, 634.000000) scale(-1, 1) translate(-24.000000, -634.000000) translate(16.000000, 619.000000)">
                    <polyline points="0.667194006 0 15.0471024 14.3799084 0.359755834 29.0672549"></polyline>
                </g>
            </g>
        </g>
    </svg>
);
