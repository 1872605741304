import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets/styleConstants';
import {isMobileDevice} from '../../utilities/device';
import {runInAnimationFrame} from '../CarouselView/scrollAnimation';
import {SelectionIndicator} from '../Common/SelectionIndicator';
import {VideoIndicator} from '../Common/VideoIndicator';
import {GridElement} from '../GridView/GridElement';

export const NormalItemImage = styled(GridElement)`
    position: relative;
    background-color: rgba(${colors.captureGrey500rgb}, 0.1);

    &:focus {
        outline: 4px solid white;
    }
`;

const SelectModeItemImage = styled(NormalItemImage)`
    ${(props: {selected: boolean}) =>
        props.selected
            ? 'transform: translateZ(0px) scale3d(0.9, 0.9, 1);'
            : ''};
`;

const ItemTools = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;

    ${() =>
        !isMobileDevice.any()
            ? `
        &:hover .item-select-indicator {
            visibility: visible;
        }
    `
            : ''};
`;

const NormalItemTools = styled(ItemTools)`
    ${() =>
        !isMobileDevice.any()
            ? `
        &:hover {
            background: linear-gradient(rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 25%);
        }
    `
            : ''};
`;

const SelectModeItemTools = styled(ItemTools)`
    ${(props: {selected: boolean}) =>
        !props.selected
            ? `
        background: linear-gradient(rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 25%);
    `
            : ''};
`;

const LeftTopIndicator = styled.div`
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: pointer;
`;

const LeftBottomIndicator = styled.div`
    position: absolute;
    left: 16px;
    bottom: 16px;
`;

type ItemProps = {
    fileID: FileID;
    thumbURL: string;
    videoFileDuration?: number;
    width: number;
    height: number;
    spaceAround: number;
    selection?: {
        onToggleSelect: (fileID: FileID, selected: boolean) => any;
    }
    shouldFocus?: boolean,
};

type NormalItemProps = ItemProps & {
    jobID: JobID;
    onClick: (jobID: JobID, fileID: FileID) => any;
};

export class GroupListItem extends React.Component<NormalItemProps, {loaded: boolean}> {
    public state = {loaded: false};
    private element = React.createRef<HTMLDivElement>();

    public componentWillMount() {
        runInAnimationFrame(() => this.setState({loaded: true}));
    }
    public componentDidUpdate() {
        if (this.props.shouldFocus && this.element.current) {
            this.element.current.focus();
        }
    }
    private handleClickSelectButton = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        this.props.selection && this.props.selection.onToggleSelect(this.props.fileID, true);
    }

    private handleClickItem = () => {
        this.props.onClick(this.props.jobID, this.props.fileID);
    }

    private longTouchTimer?: number;

    private startTouch = () => {
        this.longTouchTimer = window.setTimeout(() => {
            this.props.selection && this.props.selection.onToggleSelect(this.props.fileID, true);
        }, 500);
    }

    private stopTouch = () => {
        clearTimeout(this.longTouchTimer);
    }

    public render() {
        if (! this.state.loaded) {
            return (
                <NormalItemImage elementWidth={this.props.width} elementHeight={this.props.height} elementSpaceAround={this.props.spaceAround}/>
            );
        }

        const touchEvents = isMobileDevice.any() ? {
            onTouchStart: this.startTouch,
            onTouchEnd: this.stopTouch,
            onTouchMove: this.stopTouch,
        } : undefined;

        const leftBottomIndicator = this.props.videoFileDuration ? (
            <LeftBottomIndicator>
                <VideoIndicator duration={this.props.videoFileDuration} />
            </LeftBottomIndicator>
        ) : undefined;
        const leftTopIndicator = this.props.selection !== undefined && (
            <LeftTopIndicator>
                <SelectionIndicator
                    onClick={this.handleClickSelectButton}
                    isSelected={false}
                    showPlaceholder={false}
                    fillColor={'white'}
                />
            </LeftTopIndicator>
        );

        return (
            <NormalItemImage
                innerRef={this.element}
                elementWidth={this.props.width}
                elementHeight={this.props.height}
                elementSpaceAround={this.props.spaceAround}
                style={{backgroundImage: `url('${this.props.thumbURL}')`}}
                onClick={this.handleClickItem}
                {...touchEvents}
            >
                <NormalItemTools>
                    {leftTopIndicator}
                    {leftBottomIndicator}
                </NormalItemTools>
            </NormalItemImage>
        );
    }
}

type SelectModeItemProps = ItemProps & {
    selection: {
        isSelected: boolean,
        onToggleSelect: (fileID: FileID, selected: boolean) => any;
    };
};

export class SelectModeGroupListItem extends React.Component<SelectModeItemProps> {
    private toggleSelect = () => {
        this.props.selection.onToggleSelect(this.props.fileID, !this.props.selection.isSelected);
    }

    private handleClickSelectButton = (
        event: React.MouseEvent<HTMLDivElement>,
    ) => {
        event.stopPropagation();
        this.toggleSelect();
    }

    private handleClickItem = () => {
        this.toggleSelect();
    }

    public render() {
        const leftBottomIndicator = this.props.videoFileDuration ? (
            <LeftBottomIndicator>
                <VideoIndicator duration={this.props.videoFileDuration} />
            </LeftBottomIndicator>
        ) : undefined;

        return (
            <SelectModeItemImage
                data-cy="selectModeItem"
                style={{backgroundImage: `url('${this.props.thumbURL}')`}}
                selected={this.props.selection.isSelected}
                elementWidth={this.props.width}
                elementHeight={this.props.height}
                elementSpaceAround={this.props.spaceAround}
            >
                <SelectModeItemTools
                    onClick={this.handleClickItem}
                    selected={this.props.selection.isSelected}
                >
                    <LeftTopIndicator>
                        <SelectionIndicator
                            isSelected={this.props.selection.isSelected}
                            showPlaceholder={true}
                            onClick={this.handleClickSelectButton}
                            fillColor={'white'}
                            strokeColor={colors.captureGreen}
                        />
                    </LeftTopIndicator>
                    {leftBottomIndicator}
                </SelectModeItemTools>
            </SelectModeItemImage>
        );
    }
}
