import * as React from 'react';
import styled from 'styled-components';
import {isMobileDevice} from '../../utilities/device';

type Props = {
    blockScrolling?: boolean, // If set tot true, all touch-events will block, disabling scrolling etc.
    threshold?: number, // Pixel-length of the drag before recognizing it as a swipe. Defaults to 20
    onSwipeLeft?: () => any,
    onSwipeRight?: () => any,
    onClick?: () => any,
    // TODO: Expand as deemed needed
};

type Direction = 'up'|'down'|'left'|'right';

// SwipeRecognizer area will fulfill the parent element
const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    -ms-touch-action: none;
`;

/**
 * The SwipeRecognizer binds to touch-events and allows to react to swipes.
 * For fullscreen-components blocking scrolling is probably a good idea (setting blockScrolling={true})
 *
 * Example:
 *   <SwipeRecognizer onSwipeLeft={()=>console.log('didSwipeLeft')} onSwipeRight={()=>console.log('theOtherLeft')}>
 *       <MyContent ... />
 *   </SwipeRecognizer>
 */

export class SwipeRecognizer extends React.Component<Props> {
    private wrapper = React.createRef<HTMLDivElement>();

    private startX: number = 0;
    private startY: number = 0;
    private isMoving: boolean = false;

    private getTouchMoveDirection = (newPosition: {pageX: number, pageY: number}): Direction|undefined => {
        const threshold = this.props.threshold || 20;
        const dx = this.startX - newPosition.pageX;
        const dy = this.startY - newPosition.pageY;

        let dir: Direction|undefined;
        if (Math.abs(dx) >= threshold) {
            dir = dx > 0 ? 'left' : 'right';
        }
        else if (Math.abs(dy) >= threshold) {
            dir = dy > 0 ? 'up' : 'down';
        }

        return dir;
    }

    private onPointerDown = (e: MSPointerEvent) => {
        this.startX = e.pageX;
        this.startY = e.pageY;
    }

    private onTouchStart = (e: TouchEvent) => {
        if (e.touches.length === 1) {
            this.startX = e.touches[0].pageX;
            this.startY = e.touches[0].pageY;
            this.isMoving = true;
        }
    }

    private onTouchMove = (e: TouchEvent) => {
        if (this.props.blockScrolling) {
            e.preventDefault();
        }

        const dir = this.getTouchMoveDirection(e.touches[0]);
        if (this.isMoving && dir) {
            this.isMoving = false;
            this.dealWithSwipe(dir);
        }
    }

    private handleClick = () => {
        if (this.props.onClick && !isMobileDevice.Windows()) {
            this.props.onClick();
        }
    }

    private onPointerUp = (e: MSPointerEvent) => {
        const dir = this.getTouchMoveDirection(e);
        if (dir) {
            this.dealWithSwipe(dir);
        } else if (this.props.onClick) {
            this.props.onClick();
        }
    }

    private dealWithSwipe(dir: Direction) {
        switch (dir) {
            case 'left':
                if (this.props.onSwipeLeft) {this.props.onSwipeLeft(); }
                break;
            case 'right':
                if (this.props.onSwipeRight) {this.props.onSwipeRight(); }
                break;

        }
    }

    public componentDidMount() {
        if (this.wrapper.current) {
            if (isMobileDevice.Windows()) {
                this.wrapper.current.addEventListener(
                    'MSPointerDown',
                    this.onPointerDown as EventListener,
                    false,
                );
                this.wrapper.current.addEventListener(
                    'MSPointerUp',
                    this.onPointerUp as EventListener,
                    false,
                );
            } else {
                this.wrapper.current.addEventListener('touchstart', this.onTouchStart, false);
                this.wrapper.current.addEventListener('touchmove', this.onTouchMove, false);
            }
        }
    }

    public componentWillUnmount() {
        if (this.wrapper.current) {
            if (isMobileDevice.Windows()) {
                this.wrapper.current.removeEventListener(
                    'MSPointerDown',
                    this.onPointerDown as EventListener,
                );
                this.wrapper.current.removeEventListener(
                    'MSPointerUp',
                    this.onPointerUp as EventListener,
                );
            } else {
                this.wrapper.current.removeEventListener('touchstart', this.onTouchStart);
                this.wrapper.current.removeEventListener('touchmove', this.onTouchMove);
            }
        }
    }

    public render() {
        return (
            <Wrapper
                innerRef={this.wrapper}
                onClick={this.handleClick}
            >
                {this.props.children}
            </Wrapper>
        );
    }
}
