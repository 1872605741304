import * as React from 'react';
import styled from 'styled-components';
import {OverflowMenu} from '../Common/OverflowMenu';
import {SwipeRecognizer} from '../Gesture/SwipeRecognizer';
import {BackArrowIcon} from '../Icons/BackArrowIcon';
import {InnerComponentProps} from './CarouselView';
import {CarouselViewSlideBar} from './CarouselViewSlideBar';
import {MediaElementComponent} from './MediaElement';

const Wrapper = styled.div`
    position: relative;

    height: 100%;
    width: 100%;
`;

const AlbumContentWrapper = styled.div`
    position: relative;

    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: ${(props: {centerImage: boolean}) => props.centerImage ? 'center' : 'flex-start'};
`;

const MediaElementWrapper = styled.div`
    margin-bottom: ${(props: {bottomDiff: number}) => props.bottomDiff}px;
`;

const UserActionsWrapper = styled.div`
    width: 100%;
    position: absolute;
    box-sizing: border-box;
`;

const IconsWrapper = styled.div`
    display: flex;
    & > div {
        margin-right: 16px;
        height: 24px;
    }
`;

const TopUserActionsWrapper = styled(UserActionsWrapper)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 0;
    height: 80px;
    padding: 15px 10px 40px 10px;
    background: linear-gradient(rgba(0,0,0,0.5) 5%, rgba(0,0,0,0) 65%);
`;

const TopToolButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const BottomUserActionsWrapper = styled(UserActionsWrapper)`
    bottom: ${(props: {bottomDiff: number}) => props.bottomDiff}px;
    height: 90px;
    padding: 30px 10px 10px;
    background: linear-gradient(rgba(0,0,0,0) 35%, rgba(0,0,0,0.8) 100%);
`;

export class CarouselViewMobile extends React.Component<InnerComponentProps> {

    private onCloseClick = () => {
        history.back();
    }
    public render() {
        const isSlideShowMode = this.props.slideShowControls !== null;
        let topActions = null;
        let bottomActions = null;
        if (this.props.displayToolButtons && !isSlideShowMode) {
            const overflowMenu = this.props.overflowMenuItems.length > 0 && (
                <OverflowMenu
                    menuOptions={this.props.overflowMenuItems}
                    iconColor={'white'}
                    shadowColor={'black'}
                />
            );
            let shareElement: React.ReactNode = null;
            if (this.props.currentViewerNode.type === 'timeline') {
                shareElement = this.props.shareElement;
            }

            topActions = (
                <TopUserActionsWrapper>
                    <div onClick={this.onCloseClick}>
                        <BackArrowIcon size={24} color={'white'}/>
                    </div>
                    <TopToolButtonsWrapper>
                        <IconsWrapper>
                            {this.props.loveInfoElement}
                            {this.props.commentsElement}
                            {shareElement}
                            {this.props.infoElement}
                        </IconsWrapper>
                        {overflowMenu}
                    </TopToolButtonsWrapper>
                </TopUserActionsWrapper>
            );

            // Bottom tool buttons
            bottomActions = (
                <BottomUserActionsWrapper bottomDiff={this.props.viewportDiff}>
                    <CarouselViewSlideBar
                        thumbFiles={this.props.thumbFiles}
                        currentThumbIndex={this.props.currentThumbIndex}
                        thumbStyle={{size: 30, spaceBetween: 4}}
                        navBtnStyle={{width: 16, height: 30}}
                        onClickThumbImage={this.props.onClickThumbImage}
                        showNavArrows={true}
                    />
                </BottomUserActionsWrapper>
            );
        }

        const shouldCenterImage = this.props.viewerNodeSize.height / window.innerHeight < 0.8;

        return (
            <Wrapper>
                <SwipeRecognizer
                    onSwipeRight={this.props.navToPrevFile}
                    onSwipeLeft={this.props.navToNextFile}
                    onClick={this.props.toggleToolButtons}
                >
                    <AlbumContentWrapper centerImage={shouldCenterImage}>
                        <MediaElementWrapper bottomDiff={this.props.viewportDiff}>
                            <MediaElementComponent
                                currentFile={this.props.currentViewerNode.file}
                                viewerNodeSize={this.props.viewerNodeSize}
                                onVideoPlayStatusChanged={this.props.onVideoPlayStatusChanged}
                                onImageDimensionsDetected={this.props.onImageDimensionsDetected}
                            />
                        </MediaElementWrapper>
                    </AlbumContentWrapper>
                    {this.props.displayToolButtons && this.props.slideShowControls}
                </SwipeRecognizer>
                {topActions}
                {bottomActions}
            </Wrapper>
        );
    }

}
