import {Dispatch} from '../state/common/actions';
import {ProfileNameChanged, ProfileNameChangeFailed} from '../state/users/actions';
import {getServiceProvider} from './HostProvider';

export const setProfileUserName = async (dispatch: Dispatch, name: string) => {
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        await service.setProfileName(name);
        dispatch(ProfileNameChanged({name}));
    } catch (e) {
        dispatch(ProfileNameChangeFailed());
    }
};
