import * as React from 'react';
import {Album, AlbumViewMode} from '../../state/album/selectors';
import {GridStyle} from '../../utilities/gridElementSizeCalculator';
import {AlbumFileList} from './AlbumFileList';
import {AlbumGridView} from './AlbumGridView';
import {AlbumHeaderComponent} from './AlbumHeaderComponent';

type Props = {
    album: Album,
    numberOfVisibleFiles: number,
    isMobileMode: boolean,
    viewMode: AlbumViewMode,
    gridStyle: GridStyle,
    doShowParticipantsList: () => any,
};

export const AlbumPageContent: React.SFC<Props> = (props) => {
    let content = null;
    if (props.viewMode === 'flow') {
        content = (
            <AlbumFileList
                albumID={props.album.id}
                files={props.album.files.slice(0, props.numberOfVisibleFiles)}
                mobileMode={props.isMobileMode}
                userCanAddComment={props.album.currentUserCan.addComment}
                isShared={props.album.isShared}
            />
        );
    }
    else {
        content = (
            <AlbumGridView
                albumID={props.album.id}
                files={props.album.files.slice(0, props.numberOfVisibleFiles)}
                styling={props.gridStyle}
            />
        );
    }
    return (
        <div>
            <AlbumHeaderComponent
                mobileMode={props.isMobileMode}
                albumTitle={props.album.title}
                dateCreated={props.album.dateCreated}
                filesDateRange={props.album.filesDateRange}
                itemCount={props.album.files.length}
                subscribersCount={props.album.subscribersCount}
                doShowParticipantsList={props.doShowParticipantsList}
                owner={props.album.owner}
            />
            {content}
        </div>
    );
};
