import * as React from 'react';
import {IconProps} from './index';

export const AddIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(0.000000, 1.000000)">
            <path fill={props.color} fillRule="nonzero" d="M3.83333333,5.75 L1.91666667,5.75 L1.91666667,19.1666667 C1.91666667,20.2208333 2.77916667,21.0833333 3.83333333,21.0833333 L17.25,21.0833333 L17.25,19.1666667 L3.83333333,19.1666667 L3.83333333,5.75 Z M19.1666667,1.91666667 L7.66666667,1.91666667 C6.6125,1.91666667 5.75,2.77916667 5.75,3.83333333 L5.75,15.3333333 C5.75,16.3875 6.6125,17.25 7.66666667,17.25 L19.1666667,17.25 C20.2208333,17.25 21.0833333,16.3875 21.0833333,15.3333333 L21.0833333,3.83333333 C21.0833333,2.77916667 20.2208333,1.91666667 19.1666667,1.91666667 Z M18.2083333,10.5416667 L14.375,10.5416667 L14.375,14.375 L12.4583333,14.375 L12.4583333,10.5416667 L8.625,10.5416667 L8.625,8.625 L12.4583333,8.625 L12.4583333,4.79166667 L14.375,4.79166667 L14.375,8.625 L18.2083333,8.625 L18.2083333,10.5416667 Z" />
        </g>
    </svg>
);
