import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets/styleConstants';
import {
    ImageGroup, ScrollerYearGroup, TimelineGroupStyle,
} from '../../state/timeline/selectors';
import {isBodyScrollable} from '../../utilities/preventBodyScroll';
import {NextIcon} from '../Icons/NextIcon';
import {PreviousIcon} from '../Icons/PreviousIcon';
import {FastScroller} from './FastScroller';
import {SlideInHoverWrapper} from './SlideInHoverWrapper';

export const ScrollerContainerBase = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    overflow: hidden;
`;
const ScollerContainerWeb = ScrollerContainerBase.extend`
    top: 56px;
    width: 40px;
    padding: ${(props: {isMobile: boolean}) => props.isMobile ? '0px 16px 0 8px' : '0px 24px 0 12px'};
    background-color: rgba(${colors.captureGrey50_rgb}, 0.85);
`;

export const HiddenScrollWrapper = styled.div`
    width:100%;
    height:100%;
    overflow-y:scroll;
    padding-right:40px; //to cover multple scrollbar widths
`;
const ContentWrapper = styled.div`
    height:100%;
    padding-top: 56px;
    box-sizing: border-box;
`;

type ToggleBtnProps = {isExpanded: boolean, isMobile: boolean};
const ToggleScrollerBtn = styled.div`
    width: 24px;
    height: 32px;
    background-color: rgba(${colors.captureGrey50_rgb}, 0.85);
    cursor: pointer;
    border-radius: 50% 0 0 50%;
    padding-left: 4px;

    position: fixed;
    right: ${(props: ToggleBtnProps) => props.isMobile ? 64 : 76}px;
    top: ${(props) => props.isMobile ? '136px' : '50%'};
    display: flex;
    justify-content: center;
    align-items: center;

    transform: translateX(${(props) => props.isExpanded ? 0 : (props.isMobile ? 60 : 64)}px);
    transition: transform 0.3s;
    transition-timing-function: ease-out;
`;

const ToggleExpandButton: React.SFC<{isExpanded: boolean, onClick: () => any, isMobile: boolean}> = (props) => {
    const Icon = props.isExpanded ? NextIcon : PreviousIcon;
    return (
        <ToggleScrollerBtn {...props}>
            <Icon size={24} color={colors.captureGrey800} />
        </ToggleScrollerBtn>
    );
};

export type TimelineFastScrollerProps = {
    imageGroups: ImageGroup[],
    imageGroupStyle: TimelineGroupStyle,
    scrollerGroups: ScrollerYearGroup[],
};

type AddedProps = {
    selectedItemKey: string,
    scrollerDiv: React.RefObject<HTMLDivElement>,
    scrollerItemSelect: (scrollerEntryKey: string) => any,
};
type ComponentProps<T = {}> = T & AddedProps;
export const timelineFastScroller = <T extends TimelineFastScrollerProps>(Inner: React.ComponentType<ComponentProps<T>>) => {
    type TimelineScrollerState = {
        focusedGroupKey: string,
        isExpanded: boolean,
    };
    return class TimelineScrollerComponent extends React.Component<TimelineFastScrollerProps, TimelineScrollerState> {
        public state: TimelineScrollerState = {
            isExpanded: false,
            focusedGroupKey: '',
        };
        private scrollerDiv = React.createRef<HTMLDivElement>();

        public componentWillReceiveProps(nextProps: TimelineFastScrollerProps) {
            if (this.props.imageGroups !== nextProps.imageGroups) {
                this.updateFocusedGroup(nextProps);
            }
        }

        private handleScroll = () => {
            this.updateFocusedGroup(this.props);
        }

        private findMatchImageGroup = (groups: ImageGroup[], condition: (g: ImageGroup) => boolean): ImageGroup => {
            for (const g of groups) {
                if (condition(g)) {
                    return g;
                }
            }

            return groups[0];
        }

        private updateFocusedGroup = (props: TimelineFastScrollerProps) => {
            if (isBodyScrollable() && props.imageGroups.length > 0) {
                const newGroup = this.findMatchImageGroup(props.imageGroups, (g: ImageGroup) => {
                    return g.position + g.height - props.imageGroupStyle.elementHeight / 2 >= window.pageYOffset;
                });
                const newGroupKey = newGroup.header.slice(0, 7);

                // check if scrolled to bottom, then respect current focusedGroupKey,
                // since focused group is below first visible group when scroll to bottom
                const scrolledToBottom = window.innerHeight + window.pageYOffset >= document.body.scrollHeight;

                if (newGroupKey !== this.state.focusedGroupKey && !scrolledToBottom) {
                    this.setState({
                        ...this.state,
                        focusedGroupKey: newGroupKey,
                    });
                }

                if (this.scrollerDiv.current && window.pageYOffset === 0 && this.scrollerDiv.current.scrollTop !== 0) {
                    this.scrollerDiv.current.scrollTop = 0;
                }
            }
        }

        private scrollToImageGroup = (groupKey: string, imageGroups: ImageGroup[]) => {
            const selectedGroup = this.findMatchImageGroup(imageGroups, (g: ImageGroup) => {
                return g.header.slice(0, 7) === groupKey;
            });
            window.scrollTo(0, selectedGroup.position);
        }

        private handleScrollerItemSelected = (newGroupKey: string) => {
            this.scrollToImageGroup(newGroupKey, this.props.imageGroups);

            this.setState({
                ...this.state,
                focusedGroupKey: newGroupKey,
            });
        }

        public componentDidMount() {
            window.addEventListener('scroll', this.handleScroll);
        }

        public componentWillUnmount() {
            window.removeEventListener('scroll', this.handleScroll);
        }
        public render() {
            const addedProps: AddedProps = {
                selectedItemKey: this.state.focusedGroupKey,
                scrollerDiv: this.scrollerDiv,
                scrollerItemSelect: this.handleScrollerItemSelected,
            };
            return <Inner {...addedProps} {...this.props} />;
        }
    };
};

const TimelineFastScrollerWeb = timelineFastScroller<TimelineFastScrollerProps>(
    ({scrollerDiv, selectedItemKey, scrollerItemSelect, scrollerGroups}) => (
        <HiddenScrollWrapper innerRef={scrollerDiv}>
            <ContentWrapper>
                <FastScroller
                    scrollerGroups={scrollerGroups}
                    selectedItemKey={selectedItemKey}
                    scrollerItemOnSelected={scrollerItemSelect}
                    scrollerWrapper={scrollerDiv.current}
                />
            </ContentWrapper>
        </HiddenScrollWrapper>
    ),
);

type Props = TimelineFastScrollerProps & {
    viewportWidth: number,
    isMobileMode: boolean,
};
export const TimelineScrollerPlacement: React.SFC<Props> = ({viewportWidth, isMobileMode, ...fastScrollerProps}) => {
    const canToggle = (viewportWidth - fastScrollerProps.imageGroupStyle.width) / 2 <= 70;
    return (
        <SlideInHoverWrapper
            toggleButton={ToggleExpandButton}
            canToggle={canToggle}
            isMobile={isMobileMode}
            translateAmount={isMobileMode ? 60 : 64}
        >
            <ScollerContainerWeb isMobile={isMobileMode}>
                <TimelineFastScrollerWeb {...fastScrollerProps}/>
            </ScollerContainerWeb>
        </SlideInHoverWrapper>
    );
};
