import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {checkVideoTranscode} from '../../API/album';
import {EncodingStatus} from '../../state/files/reducer';
import {getEncodingStatus, getVideoURL} from '../../state/files/selectors';
import {State} from '../../state/store';
import {
    ClientViewportSizeChangeDisabled,
    ClientViewportSizeChangeEnabled,
} from '../../state/viewMode/actions';
import {isMobileMode} from '../../state/viewMode/selectors';
import {isMobileDevice} from '../../utilities/device';
import {
    enterFullscreen,
    exitFullscreen,
    isInFullscreenMode,
    subscribeFullscreenChange,
    unsubscribeFullscreenChange,
} from '../../utilities/fullscreen';
import {RippleLoader} from '../Common/RippleLoader';
import {canPlayVideo} from './';
import {PlayButton} from './PlayButton';

const PlayButtonWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    ${(props: {isMobileMode?: boolean}) =>
        !props.isMobileMode
            ? `
        #playButton{
            opacity: 0.5;
        }
        &:hover #playButton{
            opacity: 0.8;
        }
    `
            : ``};
`;

const LoaderWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
`;

const PlaceHolderImg = styled.img`
    max-width: 100%;
    max-height: 100%;
    display: block;
    flex: 0 0 auto;
`;

const VideoWrapper = styled.video`
    width: 100%;
    max-height: 100%;
    display: block;
    flex: 0 0 auto;
`;

const LoadingIndicator: React.SFC = () => (
    <LoaderWrapper>
        <RippleLoader color={'white'} size={64} />
    </LoaderWrapper>
);

type StateProps = {
    isVideoPlayable: boolean;
    isVideoEncoding: boolean;
    videoURL: string | undefined;
};

type DispatchProps = {
    checkVideoTranscode: () => Promise<void>;
    changeFullscreenStatus: (isActive: boolean) => any;
};

export type VideoPlayStatus = 'playing' | 'pause' | 'end';

type OwnProps = {
    jobID: JobID;
    fileID: FileID;
    thumbURL: string | undefined;
    shouldAutoPlay?: boolean;
    onPlayStatusChanged?: (newStatus: VideoPlayStatus) => any;
};

type AlbumVideoProps = StateProps & DispatchProps & OwnProps;

type AlbumVideoState = {
    showPlayButton: boolean;
    isInPlayMode: boolean;
};

const checkVideoTranscodeByProps = (props: AlbumVideoProps) => {
    if (!props.isVideoPlayable && !props.isVideoEncoding) {
        props.checkVideoTranscode();
    }
};

class AlbumVideoComponent extends React.Component<
    AlbumVideoProps,
    AlbumVideoState
> {
    public videoElement = React.createRef<HTMLVideoElement>();

    public state: AlbumVideoState = {
        showPlayButton: true,
        isInPlayMode: false,
    };

    public componentDidMount(): void {
        if (this.props.shouldAutoPlay) {
            this.handlePlayButtonClick();
        }
    }

    public componentDidUpdate(prevProps: AlbumVideoProps) {
        if (prevProps.fileID !== this.props.fileID) {
            if (this.props.shouldAutoPlay) {
                this.handlePlayButtonClick();
            } else {
                this.setState({
                    ...this.state,
                    showPlayButton: true,
                    isInPlayMode: false,
                });
            }
        }
    }

    public render() {
        let indicator: React.ReactNode = null;
        if (this.props.isVideoEncoding) {
            indicator = <LoadingIndicator />;
        } else if (this.state.showPlayButton) {
            indicator = (
                <PlayButtonWrapper onClick={this.handlePlayButtonClick}>
                    <PlayButton size={61} />
                </PlayButtonWrapper>
            );
        }

        let content: React.ReactNode = (
            <PlaceHolderImg
                src={this.props.thumbURL}
                onLoad={this.handleImageLoaded}
            />
        );

        if (this.props.isVideoPlayable && this.state.isInPlayMode) {
            content = (
                <VideoWrapper
                    innerRef={this.videoElement}
                    poster={this.props.thumbURL}
                    src={this.props.videoURL}
                    onPlay={this.handleVideoPlay}
                    onPause={this.handleVideoPause}
                    onEnded={this.handleVideoEnded}
                    controls={true}
                    autoPlay={true}
                />
            );
        }

        return (
            <Container>
                {content}
                {indicator}
            </Container>
        );
    }

    private handleVideoPlay = () => {
        this.notifyVideoPlayStatus('playing');
    }

    private handleVideoPause = () => {
        this.notifyVideoPlayStatus('pause');
    }

    private notifyVideoPlayStatus = (newStatus: VideoPlayStatus) => {
        if (this.props.onPlayStatusChanged) {
            this.props.onPlayStatusChanged(newStatus);
        }
    }

    private handlePlayButtonClick = () => {
        checkVideoTranscodeByProps(this.props);

        this.setState({
            ...this.state,
            showPlayButton: false,
            isInPlayMode: true,
        });

        this.notifyVideoPlayStatus('playing');
    }

    private handleImageLoaded = () => {
        this.setState({
            ...this.state,
            showPlayButton: !this.state.isInPlayMode && canPlayVideo(),
        });
    }

    private handleVideoEnded = () => {
        if (this.videoElement.current) { exitFullscreen(this.videoElement.current); }
        this.setState({
            ...this.state,
            showPlayButton: true,
            isInPlayMode: false,
        });

        this.notifyVideoPlayStatus('end');
    }
}

const MobileImagePlaceholder = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

class AlbumVideoMobileComponent extends React.Component<AlbumVideoProps> {
    private videoElement = React.createRef<HTMLVideoElement>();

    private notifyVideoPlayStatus = (newStatus: VideoPlayStatus) => {
        if (this.props.onPlayStatusChanged) {
            this.props.onPlayStatusChanged(newStatus);
        }
    }
    public handleVideoPlay = () => {
        this.notifyVideoPlayStatus('playing');
    }
    private handleVideoEnd = () => {
        if (this.videoElement.current) {
            exitFullscreen(this.videoElement.current);
        }

        this.notifyVideoPlayStatus('end');
    }

    private playVideo = () => {
        if (this.videoElement.current) {
            enterFullscreen(this.videoElement.current);
            this.videoElement.current.play();
            this.notifyVideoPlayStatus('playing');
        }
    }

    private pauseVideo = () => {
        if (
            this.videoElement.current &&
            this.videoElement.current.currentTime > 0 &&
            !this.videoElement.current.paused &&
            !this.videoElement.current.ended
        ) {
            this.videoElement.current.pause();
            this.notifyVideoPlayStatus('pause');
        }
    }

    private handlePlayButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
        // overlay button to trigger play
        // do not propagate to prevent container onclick behave
        e.stopPropagation();
        this.playVideo();
    }

    private handleVideoBeginFullscreen = () => {
        this.props.changeFullscreenStatus(true);
    }

    private handleVideoEndFullscreen = () => {
        this.props.changeFullscreenStatus(false);
        if (isMobileDevice.Android()) {
            // paused video to show placeholder instead
            // when exit fullscreen to avoid black video content
            // on android
            this.pauseVideo();
        }
    }

    private handleFullscreenChange = () => {
        if (isInFullscreenMode()) {
            this.handleVideoBeginFullscreen();
        } else {
            this.handleVideoEndFullscreen();
        }
    }

    private subscribeVideoEvent = () => {
        if (this.videoElement.current) {
            if (isMobileDevice.iOS()) {
                // iOS fullscreen events
                this.videoElement.current.addEventListener(
                    'webkitbeginfullscreen',
                    this.handleVideoBeginFullscreen,
                );
                this.videoElement.current.addEventListener(
                    'webkitendfullscreen',
                    this.handleVideoEndFullscreen,
                );
            }

            if (isMobileDevice.Android()) {
                // Android fullscreen events
                subscribeFullscreenChange(this.handleFullscreenChange);
            }
        }
    }

    private unsubscribeVideoEvent = () => {
        if (this.videoElement.current) {
            if (isMobileDevice.iOS()) {
                // iOS fullscreen events
                this.videoElement.current.removeEventListener(
                    'webkitbeginfullscreen',
                    this.handleVideoBeginFullscreen,
                );
                this.videoElement.current.removeEventListener(
                    'webkitendfullscreen',
                    this.handleVideoEndFullscreen,
                );
            }

            if (isMobileDevice.Android()) {
                // Android fullscreen events
                unsubscribeFullscreenChange(this.handleFullscreenChange);
            }
        }
    }

    public componentWillMount() {
        checkVideoTranscodeByProps(this.props);
    }

    public componentWillUnmount() {
        this.unsubscribeVideoEvent();
    }

    public componentDidMount() {
        if (this.props.isVideoPlayable) {
            this.subscribeVideoEvent();
        }
    }

    public componentWillReceiveProps(nextProps: AlbumVideoProps) {
        if (this.props.fileID !== nextProps.fileID) {
            checkVideoTranscodeByProps(nextProps);
        }
    }

    public componentDidUpdate(prevProps: AlbumVideoProps) {
        if (this.props.isVideoPlayable !== prevProps.isVideoPlayable) {
            this.subscribeVideoEvent();
        }
    }

    public render() {
        let indicator: React.ReactNode = null;
        let placeHolder: React.ReactNode = null;
        let content: React.ReactNode = null;

        if (this.props.isVideoEncoding) {
            indicator = <LoadingIndicator />;
            content = <PlaceHolderImg src={this.props.thumbURL} />;
        } else if (this.props.isVideoPlayable && canPlayVideo()) {
            if (isMobileDevice.Android()) {
                // Android Mobile Video touch event will not be propagated,
                // need overlay hack
                indicator = (
                    <PlayButtonWrapper
                        isMobileMode={true}
                        onClick={this.handlePlayButtonClick}
                    >
                        <PlayButton size={50} />
                    </PlayButtonWrapper>
                );
                placeHolder = (
                    <MobileImagePlaceholder src={this.props.thumbURL} />
                );
            }

            content = (
                <VideoWrapper
                    innerRef={this.videoElement}
                    poster={this.props.thumbURL}
                    src={this.props.videoURL}
                    onEnded={this.handleVideoEnd}
                    onPlay={this.handleVideoPlay}
                    controls={true}
                />
            );
        }

        return (
            <Container>
                {content}
                {placeHolder}
                {indicator}
            </Container>
        );
    }
}

const stateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    isVideoPlayable:
        getEncodingStatus(state, ownProps.fileID) === EncodingStatus.ENCODED,
    isVideoEncoding:
        getEncodingStatus(state, ownProps.fileID) === EncodingStatus.PENDING,
    videoURL: getVideoURL(
        state,
        ownProps.jobID,
        ownProps.fileID,
        isMobileMode(state) ? 'v-low' : 'v-high',
    ),
});

const dispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
): DispatchProps => ({
    checkVideoTranscode: () =>
        checkVideoTranscode(dispatch, ownProps.jobID, ownProps.fileID),
    changeFullscreenStatus: (isActive: boolean) => {
        if (isActive) {
            dispatch(ClientViewportSizeChangeDisabled());
        } else {
            dispatch(
                ClientViewportSizeChangeEnabled({
                    width: window.innerWidth,
                    height: window.innerHeight,
                }),
            );
        }
    },
});

export const AlbumVideo = connect(stateToProps, dispatchToProps)(
    isMobileDevice.any() ? AlbumVideoMobileComponent : AlbumVideoComponent,
);
