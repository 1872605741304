import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from '../Icons';

/* tslint:disable:max-line-length jsx-self-close */
export const PlayButton: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="1 1 116 116" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(1.000000, 1.000000)">
            <g fill="#000000">
                <path d="M58,116 C90.0325155,116 116,90.0325155 116,58 C116,25.9674845 90.0325155,0 58,0 C25.9674845,0 0,25.9674845 0,58 C0,90.0325155 25.9674845,116 58,116 Z"></path>
            </g>
            <g transform="translate(36.131148, 36.131148)">
                <polygon fill={props.color || colors.defaultIconColor} points="15.2131148 9.50819672 15.2131148 36.1311475 36.1311475 22.8196721"></polygon>
                <polygon points="0 0 45.6393443 0 45.6393443 45.6393443 0 45.6393443"></polygon>
            </g>
        </g>
    </svg>
);
