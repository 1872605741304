import * as React from 'react';
import {Dispatch} from 'react-redux';
import {PRODUCT_NAME} from '../../../../config/constants';
import {subscribeToAlbum} from '../../API/album';
import {_} from '../../assets';
import {Pages} from '../../routing';
import {b64ToUuid} from '../../utilities/uuid';
import {RequireLoginActionPage} from './RequireLoginActionPage';

type Props = {
    params: {albumIDB64: string},
};

export class RequireLoginCreateNewAlbum extends React.Component<Props> {

    private subscribeToCurrentAlbum = (d: Dispatch) => {
        const albumID = b64ToUuid(this.props.params.albumIDB64);
        subscribeToAlbum(d, albumID);
    }

    public render() {
        return (
            <RequireLoginActionPage
                text={_('needs_connect_to_create_album').replace('%product_name%', PRODUCT_NAME)}
                targetAfterLogin={Pages.Albums}
                afterLoginAction={this.subscribeToCurrentAlbum}
            />
        );
    }
}
