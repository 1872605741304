import * as React from 'react';
import {StatusNotificationPlacement} from '../../common/components/Common/StatusNotificationPlacement';
import {UserActionDialogPlacement} from '../../common/components/Common/UserActionDialogPlacement';
import {UploadStatusPlacement} from '../../common/components/Uploader/UploadStatusPlacement';

export class AlbumReceiverApp extends React.Component<{}, {}> {

    public render() {
        return (
            <div>
                {this.props.children}
                <UploadStatusPlacement/>
                <StatusNotificationPlacement />
                <UserActionDialogPlacement/>
            </div>
        );
    }
}
