// xBrowser requestAnimationFrame()

const defineRequestAnimationFrame = () => {
    let lastTime = 0;
    const vendors = ['ms', 'moz', 'webkit', 'o'];
    for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = (window as any)[vendors[x] + 'RequestAnimationFrame'];
        window.cancelAnimationFrame = (window as any)[vendors[x] + 'CancelAnimationFrame']
            || (window as any)[vendors[x] + 'CancelRequestAnimationFrame'];
    }
    if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = (callback) => {
            const currTime = new Date().getTime();
            const timeToCall = Math.max(0, 16 - (currTime - lastTime));
            const id = window.setTimeout(() => { callback(currTime + timeToCall); }, timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };
    }
    if (!window.cancelAnimationFrame) {
        window.cancelAnimationFrame = (id) => {
            clearTimeout(id);
        };
    }
};

const requestAnimationFrame = (callback: () => any) => {
    if (!window.requestAnimationFrame) {
        defineRequestAnimationFrame();
    }

    return window.requestAnimationFrame(callback);
};

const easings = {
    // t = current time or position
    // b = begin value
    // c = change or delta of value
    // d = duration / total time or position
    easeInOutQuad: (t: number, b: number, c: number, d: number) => {
        t = t / (d / 2);
        if (t < 1) {
            return c / 2 * t * t + b;
        }
        t = t - 1;
        return -c / 2 * (t * (t - 2) - 1) + b;
    },
};

const getNow = () => {
    if (window.performance && window.performance.now) {
        return window.performance.now();
    }
    else if (Date.now) {
        return Date.now();
    }
    else {
        return new Date().getTime();
    }
};

export type ScrollAnimationFunc = (
    start: number,
    end: number,
    duration: number,
    callback?: () => any,
) => void;
export const makeScrollAnimation = (w: HTMLElement): ScrollAnimationFunc => (
    start: number,
    end: number,
    duration: number,
    callback?: () => any,
) => {
    const delta = end - start;
    const startTime: number = getNow();

    const animateLoop = (time?: number) => {
        const t = (!time ? 0 : time - startTime);
        const factor = easings.easeInOutQuad(t, 0, 1, duration);
        w.scrollLeft = start + delta * factor;
        if (t < duration && w.scrollLeft !== end) {
            requestAnimationFrame(animateLoop);
        } else if (callback) {
            callback();
        }
    };

    animateLoop();
};

export const runInAnimationFrame = requestAnimationFrame;
