import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './';

/* tslint:disable:max-line-length jsx-self-close */
export const PersonIcon: React.SFC<IconProps> = (props) => {
    const iconColor = props.color || colors.defaultIconColor;
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g>
                <circle fill="none" stroke={iconColor} strokeWidth="1.5" strokeMiterlimit="10" cx="15" cy="15" r="14.1"/>
                <path fill={iconColor} stroke={iconColor} strokeMiterlimit="10" d="M25.5,24.3c-1.4-0.9-6.9-2.3-8.1-2.7c3.9-5.6,5.1-16.7-2.1-16.7c-8.1,0-6,11.3-1.9,16.7c-1.4,0.3-7.2,1.9-8.7,2.7c0.7,0.7,4,4.8,10.3,4.8C21,29.1,25.5,24.3,25.5,24.3z"/>
            </g>
        </svg>
    );
};
