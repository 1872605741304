import * as React from 'react';
import styled from 'styled-components';
import {CarouselViewerNode} from '.';
import {_} from '../../assets/localizedStrings';
import {fontSize} from '../../assets/styleConstants';
import {SideModuleContentType} from '../../state/carouselViewer/actions';
import {ThumbFile} from '../../state/carouselViewer/selectors';
import {isMobileDevice} from '../../utilities/device';
import {FileTarget} from '../../utilities/fileTarget';
import {
    isFullscreenEnabled, isFullscreenVideo, isInFullscreenMode,
    subscribeFullscreenChange, unsubscribeFullscreenChange,
} from '../../utilities/fullscreen';
import {Button, ButtonProps} from '../Common/Button';
import {AddIcon} from '../Icons/AddIcon';
import {CommentIcon} from '../Icons/CommentIcon';
import {DeleteIcon} from '../Icons/DeleteIcon';
import {DownloadIcon} from '../Icons/DownloadIcon';
import {FullscreenIcon} from '../Icons/FulscreenIcon';
import {ImageIcon} from '../Icons/ImageIcon';
import {InfoIcon} from '../Icons/InfoIcon';
import {PlusIcon} from '../Icons/PlusIcon';
import {ShareIcon} from '../Icons/ShareIcon';
import {AlbumFileLoveInfo} from '../Reaction/AlbumFileLoveInfo';
import {VideoPlayStatus} from '../Video/AlbumVideo';
import {CarouselViewDesktop} from './CarouselViewDesktop';
import {CarouselViewMobile} from './CarouselViewMobile';
import {SlideShowControls} from './SlideShowControls';

type CarouselViewProps = {
    currentViewerNode: CarouselViewerNode,
    thumbFiles: ThumbFile[],
    currentThumbIndex: number,
    viewerNodeSize: {width: number, height: number},
    onNavigateToFile: (jobID: JobID, fileID: FileID) => any,
    onDeleteInitiate: () => any,
    viewportDiff: number,
    onImageDimensionsDetected?: (width: number, height: number) => any,
    onSetAsAlbumCoverPhoto: (albumID: JobID, fileID: FileID) => any,
    currentCoverPhoto: FileID,
    onLoveChanged: (isLove: boolean) => any,
    doSideModuleToggle: (type: SideModuleContentType) => any,
    isSideModuleExpanded: boolean,
    isInFullscreenMode: boolean,
    onFullscreenModeEnter: () => any,
    onFullscreenModeExit: () => any,
    onAddImageToAlbum: (files: FileID[]) => any,
    onDownloadFile: (jobID: JobID, fileID: FileID) => any,
    onShareFile: () => any,
    doCopyToTimeline: () => any,
};

export type InnerComponentProps = Pick<CarouselViewProps,
    'currentViewerNode'
    | 'thumbFiles'
    | 'currentThumbIndex'
    | 'viewerNodeSize'
    | 'isSideModuleExpanded'
    | 'onImageDimensionsDetected'
> & {
    viewportDiff: number,
    displayToolButtons: boolean,
    toggleToolButtons: () => any,
    onClickThumbImage: (file: FileID) => any,
    navToPrevFile: () => any,
    navToNextFile: () => any,
    loveInfoElement: React.ReactNode,
    commentsElement: React.ReactNode,
    infoElement: React.ReactNode,
    shareElement: React.ReactNode,
    slideShowControls: React.ReactNode,
    overflowMenuItems: ButtonProps[],
    onVideoPlayStatusChanged: (newStatus: VideoPlayStatus) => any,
};

const CommentInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    color: white;
    font-size: ${fontSize.small_12};

    & > svg {
        margin-right: 3px;
    }
`;

type CarouselViewState = {
    displayToolButtons: boolean,
    shouldAutoPlay: boolean,
    isVideoPlaying: boolean,
};

export class CarouselView extends React.Component<CarouselViewProps, CarouselViewState> {
    public state: CarouselViewState = {
        displayToolButtons: true,
        shouldAutoPlay: true,
        isVideoPlaying: false,
    };
    private isMobileDevice: boolean = isMobileDevice.any();

    private autoPlayTimer: number = 0;
    private startAutoPlay = () => {
        if (this.autoPlayTimer === 0) {
            this.autoPlayTimer = window.setTimeout(this.navToNextFileWithLooping, 5000);
        }

        this.setState((prevState) => ({...prevState, shouldAutoPlay: true}));
    }

    private stopAutoPlay = (disallowSystemAutoPlay?: boolean) => {
        this.resetAutoPlay();

        if (disallowSystemAutoPlay) {
            this.setState((prevState) => ({...prevState, shouldAutoPlay: false}));
        }
    }

    private resetAutoPlay = () => {
        window.clearTimeout(this.autoPlayTimer);
        this.autoPlayTimer = 0;
    }

    private navigateToFile = (fileID: FileID|null) => {
        if (fileID !== null) {
            this.props.onNavigateToFile(this.props.currentViewerNode.file.jobID, fileID);
            this.setState((prevState) => ({...prevState, isVideoPlaying: false}));

            // restart autoPlay for new view node in slideshow auto play
            if (this.props.isInFullscreenMode && this.state.shouldAutoPlay) {
                this.resetAutoPlay();
                this.startAutoPlay();
            }
        }

    }

    private navToPrevFile = () => {
        this.navigateToFile(this.props.currentViewerNode.prev);
    }
    private navToNextFile = () => {
        this.navigateToFile(this.props.currentViewerNode.next);
    }

    private navToPrevFileWithLooping = () => {
        this.navigateToFile(this.props.currentViewerNode.prev || this.props.currentViewerNode.last);
    }
    private navToNextFileWithLooping = () => {
        this.navigateToFile(this.props.currentViewerNode.next || this.props.currentViewerNode.first);
    }
    private handleClickThumbImage = (fileID: FileID) => {
        this.navigateToFile(fileID);
    }

    private setCoverPhoto = () => {
        this.props.onSetAsAlbumCoverPhoto(
            this.props.currentViewerNode.file.jobID,
            this.props.currentViewerNode.file.fileID,
        );
    }

    private launchAlbumSelect = () => {this.props.onAddImageToAlbum([this.props.currentViewerNode.file.fileID]); };

    private handleVideoPlayStatusChanged = (newStatus: VideoPlayStatus) => {
        this.setState((prevState) => ({...prevState, isVideoPlaying: newStatus === 'playing'}));

        // Change autoPlay only for slideshow when shouldAutoPlay
        if (this.props.isInFullscreenMode && this.state.shouldAutoPlay) {
            switch (newStatus) {
                case 'playing':
                    this.stopAutoPlay();
                    break;
                case 'pause':
                    this.startAutoPlay();
                    break;
                case 'end':
                    this.navToNextFileWithLooping();
                    break;
            }
        }
    }

    private toggleToolButtons = () => {
        this.setState((prevState) => ({...prevState, displayToolButtons: !prevState.displayToolButtons}));
    }

    private downloadFile = () => {
        this.props.onDownloadFile(this.props.currentViewerNode.file.jobID, this.props.currentViewerNode.file.fileID);
    }

    private getMenuItems = (): ButtonProps[] => {
        const node = this.props.currentViewerNode;
        let menuItems: ButtonProps[] = [];
        const deleteButton = Button(
            _('delete_file'),
            this.props.onDeleteInitiate,
            {icon: DeleteIcon},
        );

        const addToAlbumButton = Button(
            _('add_to_album'),
            this.launchAlbumSelect,
            {icon: PlusIcon},
        );
        const downloadButton = Button(
            _('download'),
            this.downloadFile,
            {icon: DownloadIcon},
        );

        if (node.type === 'album') {
            menuItems = [
                Button(_('share'), this.props.onShareFile, {icon: ShareIcon}),
                Button(_('add_file_to_timeline'), this.props.doCopyToTimeline, {icon: AddIcon}),
                (node.file.currentUserCan.setFileAsAlbumCoverPhoto && node.file.type === FileTarget.Pictures) && (
                    Button(
                        _('set_as_cover_photo'),
                        this.setCoverPhoto,
                        {icon: ImageIcon, isActive: this.props.currentCoverPhoto !== node.file.fileID},
                    )
                ),
                downloadButton,
                node.file.currentUserCan.deleteFile && (
                    deleteButton
                ),

            ].filter((e): e is ButtonProps => typeof e !== 'boolean');
        }
        else if (node.type === 'timeline') {
            menuItems = [
                addToAlbumButton,
                downloadButton,
                deleteButton,
            ];
        }

        if (this.isMobileDevice || isFullscreenEnabled()) {
            menuItems.push(Button(_('slideshow'), this.props.onFullscreenModeEnter, {icon: FullscreenIcon}));
        }

        return menuItems;
    }

    private getLoveElement = (): React.ReactNode => {
        const node = this.props.currentViewerNode;
        if (node.type === 'album') {
            return (
                <AlbumFileLoveInfo
                    loved={node.file.lovedByCurrentUser}
                    totalLoveCounts={node.file.totalLoveCount}
                    onLoveChanged={this.props.onLoveChanged}
                    showOnlyNumber={true}
                    iconColor={'white'}
                />
            );
        }
        return null;
    }

    private getCommentElement = () => {
        const node = this.props.currentViewerNode;
        if (node.type === 'album' && node.file.currentUserCan.addComment) {
            return (
                <CommentInfoWrapper onClick={this.onCommentIconClick}>
                    <CommentIcon size={24} color={'white'}/>
                    {node.file.comments.length}
                </CommentInfoWrapper>
            );
        }
    }
    private onCommentIconClick = () => {
        this.props.doSideModuleToggle('comments');
    }
    private onImageInfoClick = () => {
        this.props.doSideModuleToggle('info');
    }

    private getSlideShowControls = (): React.ReactNode => {
        if (this.props.isInFullscreenMode) {
            return (
                <SlideShowControls
                    onExitClick={this.props.onFullscreenModeExit}
                    onPrevClick={this.navToPrevFileWithLooping}
                    onNextClick={this.navToNextFileWithLooping}
                    hasPrev={this.props.currentViewerNode.prev !== null || this.props.currentViewerNode.next !== null}
                    hasNext={this.props.currentViewerNode.prev !== null || this.props.currentViewerNode.next !== null}
                    startAutoPlay={this.startAutoPlay}
                    stopAutoPlay={this.stopAutoPlay}
                    isPlaying={this.state.shouldAutoPlay}
                />
            );
        }

        return null;
    }

    private handleFullscreenChangeEvent = () => {
        if (isInFullscreenMode() === this.props.isInFullscreenMode) {return; }

        // User clicked escape to leave fullscreen-mode in the browser
        if (!isFullscreenVideo() && !this.props.isInFullscreenMode) {
            this.props.onFullscreenModeEnter();
        } else {
            this.props.onFullscreenModeExit();
        }
    }
    public componentWillMount() {
        if (this.props.isInFullscreenMode && !this.state.isVideoPlaying) {
            this.startAutoPlay();
        }
    }

    public componentDidMount() {
        if (!this.isMobileDevice) {
            subscribeFullscreenChange(this.handleFullscreenChangeEvent);
        }
    }

    public componentWillUnmount() {
        if (!this.isMobileDevice) {
            unsubscribeFullscreenChange(this.handleFullscreenChangeEvent);
        }

        this.resetAutoPlay();
    }

    public componentWillReceiveProps(newProps: CarouselViewProps) {
        if (newProps.isInFullscreenMode
            && !this.props.isInFullscreenMode
            && !this.state.isVideoPlaying
        ) { // entered slideshow
            this.startAutoPlay();
        }
        else if (!newProps.isInFullscreenMode && this.props.isInFullscreenMode) { // exited slideshow
            this.stopAutoPlay();
        }
    }

    public render() {
        const InnerComponent: React.ComponentClass<InnerComponentProps> = (
            this.isMobileDevice ? CarouselViewMobile : CarouselViewDesktop
        );

        const infoElement = (
            <div onClick={this.onImageInfoClick}>
                <InfoIcon size={24}/>
            </div>
        );
        const shareElement = (
            <div onClick={this.props.onShareFile}>
                <ShareIcon size={24} color={'white'} />
            </div>
        );

        return (
            <InnerComponent
                currentViewerNode={this.props.currentViewerNode}
                thumbFiles={this.props.thumbFiles}
                currentThumbIndex={this.props.currentThumbIndex}
                viewerNodeSize={this.props.viewerNodeSize}
                viewportDiff={this.props.viewportDiff}
                displayToolButtons={this.state.displayToolButtons}
                toggleToolButtons={this.toggleToolButtons}
                navToPrevFile={this.navToPrevFile}
                navToNextFile={this.navToNextFile}
                onClickThumbImage={this.handleClickThumbImage}
                overflowMenuItems={this.getMenuItems()}
                loveInfoElement={this.getLoveElement()}
                commentsElement={this.getCommentElement()}
                shareElement={shareElement}
                infoElement={infoElement}
                isSideModuleExpanded={this.props.isSideModuleExpanded}
                slideShowControls={this.getSlideShowControls()}
                onVideoPlayStatusChanged={this.handleVideoPlayStatusChanged}
                onImageDimensionsDetected={this.props.onImageDimensionsDetected}
            />
        );
    }

}
