import {Store} from 'redux';
import {
    ErrorWhenFetchingJobInfo, FetchedJobInfo, JobNotFound, JobRequiresPassword,
    StartedFetchingJobInfo,
} from '../../state/jobInfo/actions';
import {JobInfo, JobInfoStatus} from '../../state/jobInfo/reducer';
import {getTheJobCurrentlyDisplayed} from '../../state/jobSyncing/selectors';
import {State} from '../../state/store';
import {UserInfoWasFetched} from '../../state/users/actions';
import {consoleLog} from '../../utilities/logging';
import {getServiceProvider} from '../HostProvider';
import {fetchContributingUsers} from '../job';
import {JobInfoResponse} from '../services/AppService';
import {ResponseNotOKError} from '../toolbox';

/**
 * The job of the JobInfoSyncer:
 *  - Detect which (if any) job the app is currently interested in
 *  - If the JobInfo is not present for current job, fetch it (including host-directory if needed)
 */

class JobInfoSyncer {
    private blocked: DictionaryOf<boolean> = {};

    constructor(private store: Store<State>) {
        store.subscribe(() => this.digestState(store.getState()));
    }

    private digestState = (newState: State) => {
        const currentlyInFocus = getTheJobCurrentlyDisplayed(newState);

        if (currentlyInFocus === undefined || this.blocked[currentlyInFocus] === true) {
            return;
        }

        const currentJobInfo = newState.jobInfo[currentlyInFocus];

        // Only fetch jobInfo for JobInfoStatuses that requires fetching
        if (!(currentJobInfo === undefined
            || currentJobInfo.status === JobInfoStatus.EXPIRED
            || currentJobInfo.status === JobInfoStatus.NOT_STARTED
            || currentJobInfo.status === JobInfoStatus.PASSWORD_PROVIDED)) {
            return;
        }

        // Do not start fetching if there is a login-process ongoing
        if (newState.currentUser.isLoggingIn) {
            return;
        }

        // Only keep one thread for each job at the same time (no need to to work already started by another state change)
        this.blocked[currentlyInFocus] = true;
        consoleLog('started fetching jobInfo for job ', currentlyInFocus);

        // Do not send StartedFetchingJobInfo if the reason for re-fetching is expired info (silent update in background)
        if (! (currentJobInfo && currentJobInfo.status === JobInfoStatus.EXPIRED)) {
            this.store.dispatch(StartedFetchingJobInfo(currentlyInFocus));
        }

        // Get hosts for job, then fetch job info as required;
        getServiceProvider().getAppServiceForJob(currentlyInFocus)
            .then((service) => service.getJobInfo(currentlyInFocus))
            .then(
            (jobInfo: JobInfoResponse) => {
                const info: JobInfo = {
                    type: jobInfo.type === 'story' ? 'story' : 'timeline',
                    ctime: jobInfo.ctime,
                    mtime: jobInfo.mtime,
                    owner: jobInfo.owner.uuid,
                    title: jobInfo.name || '',
                    allow_comments: jobInfo.permissions ? jobInfo.permissions.allow_comments !== 0 : true,
                    allow_uploads: jobInfo.permissions ? jobInfo.permissions.allow_uploads !== 0 : true,
                    isShared: jobInfo.privacy_mode ? jobInfo.privacy_mode === 'shared' : false,
                    last_update: jobInfo.last_update || -1 /* May be unknown for timeline, only used with albums */,
                    pendingProperties: {},
                };
                const {uuid, name, email} = jobInfo.owner;
                this.store.dispatch(UserInfoWasFetched([{userID: uuid, name, email}]));
                this.store.dispatch(FetchedJobInfo({job: currentlyInFocus, info}));
                this.blocked[currentlyInFocus] = false;

                // Whenever a story-job is fetched, also fetch the list of contributing users.
                if (jobInfo.type === 'story') {
                    fetchContributingUsers(this.store.dispatch, currentlyInFocus);
                }
            },
        ).catch(
            (err: ResponseNotOKError) => {
                // Checking for err.response below as the promise may have been rejected for other reasons than ResponseNotOK
                // and then the response will not be on the Error-object.
                const status = err.response ? err.response.status : undefined;
                // Backend returns HTTP400 for password-protected stories with auth="", and sometimes 403
                if (status === 400 || status === 401 || status === 403) {
                    // NB: Backend also returns 400-errors when fetching hosts for jobs that does not match valid formats.
                    // We want to treat invalid IDs as if they do not exist (since the user most likely have copied the URL incorrectly or in other ways tampered with the URL)
                    // Read the returned string and do magic here:
                    err.response.text().then((s: string) => {
                        if (s.indexOf('public job auth failed') !== -1) {
                            this.store.dispatch(JobRequiresPassword(currentlyInFocus));
                        } else {
                            this.store.dispatch(JobNotFound(currentlyInFocus));
                        }
                    });
                } else if (status === 404) {
                    this.store.dispatch(JobNotFound(currentlyInFocus));
                } else {
                    this.store.dispatch(ErrorWhenFetchingJobInfo(currentlyInFocus));
                }
                this.blocked[currentlyInFocus] = false;
            },
        );
    }
}

export const connectJobInfoSyncer = (store: Store<State>) => {new JobInfoSyncer(store); };
