import * as React from 'react';
import styled from 'styled-components';
import {colors, layout} from '../../assets/styleConstants';
import {CaptureIcon} from '../Icons/CaptureLogo';

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    max-width: ${layout.maxContainerWidth}px;
    margin: 5% auto;
    padding: 0 10px;
`;

export const InfoHighlight = styled.div`
    font-style: italic;
    color: ${colors.captureGrey500};
    margin-top: 15px;
`;

export const ConfirmArea = styled.div`
    width: 80%;
    margin-top: 1em;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin: 10px auto;
    padding: 0 16px;
    max-width: 480px;
`;

const ContentArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 35vh;
    text-align: center;
    max-width: ${(p: {maxWidth: number}) => p.maxWidth}px;
    line-height: 22px;
`;

type Props = {
    title: string,
    contentMaxWidth?: number;
};

export class TakeoutContent extends React.Component<Props> {
    public render() {
        return (
            <ContentWrapper>
                <CaptureIcon size={78} />
                <Title>{this.props.title}</Title>
                <ContentArea maxWidth={this.props.contentMaxWidth || 480}>
                    {this.props.children}
                </ContentArea>
            </ContentWrapper>
        );
    }
}
