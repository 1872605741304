import {Action, isType} from '../common/actions';
import {CollapseMegaMenu, ExpandMegaMenu, ToggleMegaMenu} from './actions';

export interface MegaMenuState {
    isExpanded: boolean;
}

const initialState: MegaMenuState = {
    isExpanded: false,
};

export function megaMenuReducer(state: MegaMenuState = initialState, action: Action<any>): MegaMenuState {
    if (isType(action, ExpandMegaMenu)){
        return Object.assign({}, state, {isExpanded: true});
    }
    if (isType(action, CollapseMegaMenu)){
        return Object.assign({}, state, {isExpanded: false});
    }
    if (isType(action, ToggleMegaMenu)){
        return Object.assign({}, state, {isExpanded: !state.isExpanded});
    }
    return state;
}
