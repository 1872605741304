import {createActionCreator, createVoidActionCreator} from '../common/actions';
import {PrintOrderStatus} from './reducer';

export const OrderCreated = createActionCreator<{orderID: string}>('ORDER_CREATED');
export const OrderInfoRetrieved = createActionCreator<{orderID: string, checksum?: string}>('ORDER_INFO_RETRIEVED');

export const ActiveOrderSetFromCache = createVoidActionCreator('ACTIVE_ORDER_SET_FROM_CACHE');

export const OrderManifestSaved = createActionCreator<{returnedChecksum: string}>('ORDER_MANIFEST_SAVED');

export const ActiveOrderStatusChanged = createActionCreator<{status: PrintOrderStatus}>('ACTIVE_ORDER_STATUS_CHANGED');
