import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {TextArea, TextInput} from './Input';

const FormRowHeader = styled.div`
    font-size: ${fontSize.small_12};
    text-align: left;
    color: ${colors.captureGrey500};
    margin-bottom: 8px;
`;

const FormRowError = styled.span`
    color: ${colors.captureMagenta};
    margin-left: 10px;
`;

const FormRowContainer = styled.div`
    margin-bottom: 16px;
`;

const InputIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
`;

const InputRow = styled.div`
    display: flex;
    justify-content: flex-start;

    padding: 6px;
    border: 1px solid ${colors.captureGrey500};
`;

const FormRowTextArea = styled(TextArea)`
    width: 100%;
    height: 100px;
`;

type FormInputProps = React.InputHTMLAttributes<HTMLInputElement> &{
    icon?: React.ReactNode,
    multiLine?: boolean,
};

export const TextFormInput: React.SFC<FormInputProps> = (props) => {
    const {icon, multiLine, ...inputProps} = props;
    const Input = multiLine ? FormRowTextArea : TextInput;

    return (
        <InputRow>
            {icon && <InputIcon>{icon}</InputIcon>}
            <Input {...inputProps} />
        </InputRow>
    );
};

type FormRowProps = {
    title: string,
    isInvalid?: boolean,
    value: string,
    placeholder: string,
    icon?: React.ReactNode,
    multiLine?: boolean,
    onChange: (newValue: string) => any,
};

export class TextInputFormRow extends React.Component<FormRowProps> {
    private handleInputChanged: React.FormEventHandler<any> = (evt) => {
        this.props.onChange(evt.currentTarget.value);
    }

    public render() {
        return (
            <FormRowContainer>
                <FormRowHeader>
                    {this.props.title}
                    {this.props.isInvalid && <FormRowError>{_('invalid_input')}</FormRowError>}
                </FormRowHeader>
                <TextFormInput
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    onChange={this.handleInputChanged}
                    icon={this.props.icon}
                    multiLine={this.props.multiLine}
                />
            </FormRowContainer>
        );
    }
}
