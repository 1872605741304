import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {LoveButton} from './LoveButton';

type LoveInfoProps = {
    loved: boolean,
    totalLoveCounts: number,
    onLoveChanged: (ifLove: boolean) => any,
    doShowLoveList?: () => any,
    showOnlyNumber?: boolean,
    iconColor?: string,
};

type LoveInfoState = {
    syncInfoWithAnimation: boolean,
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Info = styled.div`
    font-size: ${fontSize.small_14};
    color: ${(props: {color: string}) => props.color};
    cursor: pointer;
`;

export const loveInfoText = (lovedByCurrentUser: boolean, totalLoveCount: number) => {
    if (lovedByCurrentUser) {
        switch (totalLoveCount) {
            case 1:
                return _('you_love_message');
            case 2:
                return _('you_and_one_love_message');
            default:
                return _('you_and_others_love_message_format').replace('%d', (totalLoveCount - 1).toString());
        }
    } else {
        switch (totalLoveCount) {
            case 0:
                return '';
            case 1:
                return _('one_person_love_message');
            default:
                return _('people_love_message_format').replace('%d', totalLoveCount.toString());

        }
    }
};

export class AlbumFileLoveInfo extends React.Component<LoveInfoProps, LoveInfoState> {
    public state: LoveInfoState = {
        // sync love info text with love animation before http request
        // to prevent delayed css animation from http request
        syncInfoWithAnimation: false,
    };

    public shouldComponentUpdate(nextProps: LoveInfoProps, nextState: LoveInfoState): boolean {
        // prevent unnecessary re-render since love css animation could be expensive
        return this.props.loved !== nextProps.loved
            || this.props.totalLoveCounts !== nextProps.totalLoveCounts
            || this.state.syncInfoWithAnimation !== nextState.syncInfoWithAnimation;
    }

    public render() {
        const userLoved: boolean = this.props.loved || this.state.syncInfoWithAnimation;
        const infoText = this.props.showOnlyNumber
            ? this.props.totalLoveCounts.toString()
            : loveInfoText(userLoved, this.props.totalLoveCounts + (userLoved && !this.props.loved ? 1 : 0));

        return (
            <Container>
                <LoveButton
                    size={24}
                    loved={this.props.loved}
                    onAnimationEnd={this.handleLoveAnimationEnd}
                    onLoveChanged={this.handleLoveChanged}
                    color={this.props.iconColor}
                />
                <Info onClick={this.props.doShowLoveList} color={this.props.iconColor || colors.captureGrey600}>
                    {infoText}
                </Info>
            </Container>
        );
    }

    private handleLoveAnimationEnd = () => {
        this.props.onLoveChanged(true);

        this.setState({
            syncInfoWithAnimation: false,
        });
    }

    private handleLoveChanged = (ifLove: boolean) => {
        if (ifLove) {
            this.setState({
                syncInfoWithAnimation: true,
            });
        } else {
            this.props.onLoveChanged(false);
        }
    }
}
