import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {DocumentFileGroup} from '../../state/timeline/selectors';
import {getMonthFromNumberString} from '../../utilities/dateOperations';
import {TableEntry} from '../Common/ActionableTableContent';
import {Button} from '../Common/Button';
import {FileListEntry} from '../Common/FileListEntry';
import {FilesTable} from '../Common/FilesTable';
import {ThreeColumns} from '../Common/ThreeColumnsRow';
import {DeleteIcon} from '../Icons/DeleteIcon';
import {DownloadIcon} from '../Icons/DownloadIcon';

const TitleArea = styled.div`
    margin: 0 8px 16px 8px;
    color: ${colors.captureGrey800};
    font-size: ${fontSize.large_24};
    text-transform: capitalize;
    user-select: none;

    display: flex;
    align-items: center;
`;

const GroupHeader: React.SFC<{header: string}> = ({header}) => {
    const [year, monthNum] = header.split('-');
    const groupTitle = `${getMonthFromNumberString(monthNum)} ${year}`;
    return (
        <TitleArea>
            <span>{groupTitle}</span>
        </TitleArea>
    );
};

type Props = {
    isMobile: boolean,
    isInSelectMode: boolean,
    selectedFiles: DictionaryOf<boolean>,
    documentGroups: DocumentFileGroup[],
    onFileSelected: (fileID: FileID) => any,
    onFileDeSelected: (fileID: FileID) => any,
    deleteFile: (file: FileID) => any,
    downloadFile: (file: FileID) => any,
};

export class DocumentsList extends React.Component<Props> {

    private getEntry: TableEntry<DocumentFileGroup> = ({row, setActionableId, currentActionableId}) => {
        const groupFiles = row.files.map((f) => {
            const optionButtons = [
                Button(_('download'), () => this.props.downloadFile(f.fileID), {icon: DownloadIcon}),
                Button(_('delete_'), () => this.props.deleteFile(f.fileID), {icon: DeleteIcon}),
            ];
            return (
                <FileListEntry
                    key={f.fileID}
                    doFileSelected={this.props.onFileSelected}
                    doFileDeSelected={this.props.onFileDeSelected}
                    doSetActionableFile={setActionableId}
                    isMobile={this.props.isMobile}
                    fileName={f.fileName}
                    fileID={f.fileID}
                    dateInfo={f.uploadedTime}
                    isSelected={this.props.selectedFiles[f.fileID] === true}
                    isInSelectMode={this.props.isInSelectMode}
                    isActionable={currentActionableId === f.fileID}
                    optionButtons={optionButtons}
                />
            );
        });

        return (
            <div key={row.header}>
                <GroupHeader header={row.header} />
                {groupFiles}
            </div>
        );
    }

    public render() {
        const columns: ThreeColumns = [
            <div key="header-1">{_('filename')}</div>,
            <div key="header-2">{_('file_type')}</div>,
            (
            <div key="header-3">
                {!this.props.isMobile && <div>{_('uploaded')}</div>}
            </div>
            ),
        ];

        return (
            <FilesTable
                isMobile={this.props.isMobile}
                isInSelectMode={this.props.isInSelectMode}
                headerColumns={columns}
                rows={this.props.documentGroups}
                entry={this.getEntry}
            />
        );
    }
}
