import {createSelector} from 'reselect';
import {CarouselViewerNode} from '../../components/CarouselView/index';
import {SideModuleContentType} from '../carouselViewer/actions';
import {getCurrentSideModuleContent} from '../carouselViewer/pureSelectors';
import {getCurrentViewerNode} from '../carouselViewer/selectors';
import {getFileMetadata} from '../files/selectors';
import {FileMetadata} from './actions';

export const getFileInNeedOfMetadata = createSelector(
    getCurrentViewerNode,
    getFileMetadata,
    getCurrentSideModuleContent,
    (
        currentNode: CarouselViewerNode|undefined,
        metadata: DictionaryOf<FileMetadata>,
        sideModuleContent: SideModuleContentType|undefined,
    ) => {
        if (currentNode && metadata[currentNode.file.fileID] === undefined && sideModuleContent === 'info') {
            return currentNode.file;
        }
    },
);
