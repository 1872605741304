import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {HOME_SITE_ADDRESS, PRODUCT_NAME} from '../../../../config/constants';
import {trackEvent} from '../../analytics/eventTracking';
import {downloadSingleFile} from '../../API/job';
import {loginAndGoTo, requireAccountInfo} from '../../API/login';
import {copyShareFilesToTimeline, downloadShare} from '../../API/share';
import {_, colors} from '../../assets';
import {getStringByAmount} from '../../assets/localizedStrings';
import {fontSize} from '../../assets/styleConstants';
import * as Pages from '../../routing/pages';
import {NavigateTo, State} from '../../state';
import {isLoggedIn} from '../../state/currentUser/selectors';
import {CaptureFile, JobFile} from '../../state/files/reducer';
import {LastSeenElementCleared} from '../../state/lastSeenElement/actions';
import {getLastViewOffset, getShare, getShareGridStyle, Share} from '../../state/share/selectors';
import {getTimelineJobID} from '../../state/timeline/selectors';
import {isMobileMode} from '../../state/viewMode/selectors';
import {isMobileDevice} from '../../utilities/device';
import {GridStyle} from '../../utilities/gridElementSizeCalculator';
import {localStorageGet, localStorageRemove} from '../../utilities/webStorage';
import {PageEventTrigger} from '../Analytics/PageEventTrigger';
import {BorderedTextButton, FilledTextButton, TextButton} from '../Common/IconTextButton';
import {PageWrapper} from '../Common/PageWrapper';
import {RippleLoader} from '../Common/RippleLoader';
import {StatusNotificationPlacement} from '../Common/StatusNotificationPlacement';
import {FadeInContainer} from '../Common/StyledComponents';
import {CaptureLogoHorizontal} from '../Icons';
import {TwoAreasTopNavBar} from '../Navigation/TwoAreasTopNavBar';
import {DocSection} from './DocSection';
import {ThumbSection} from './ThumbSection';

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
`;

const LogoWrapper = styled.span`
    cursor: pointer;
`;
const HeaderText = styled.div`
    font-size: ${fontSize.small_14};
    text-align: center;
    margin: 0 16px;
`;
const LoginBtn = styled.div`
    text-transform: uppercase;
    color: ${colors.captureGrey500};
    font-weight: bold;
`;

const ButtonWrapper = styled.div`
    width: 256px;
    margin: 4px auto;
    text-transform: uppercase;
    font-size: ${fontSize.small_14};
`;

const InfoText = styled.div`
    font-size: ${fontSize.small_12};
    max-width: 584px;
    text-align: left;
    margin: 32px;
`;
const SubText = styled.div`
    color: ${colors.captureGrey500};
    font-size: ${fontSize.xSmall_10};
`;

type StateProps = {
    share: Share|undefined,
    gridStyle: GridStyle,
    isMobileMode: boolean,
    timelineJobID: JobID|undefined,
    isLoggedIn: boolean,
    lastViewOffset?: number,
};
type DispatchProps = {
    doEnterCarouselView: (fileID: FileID) => any,
    doLogin: () => any,
    loginAndGoToTimeline: () => any,
    doDownloadFiles: () => any,
    doDownloadSingleFile: (fileID: FileID) => any,
    copyFilesToTimeline: (files: CaptureFile[], timelineJobID: JobID) => any,
    goToTimeline: () => any,
    goToRequireLoginCopyShare: () => any,
    doClearLastKnownPos: (jobID: JobID) => void,
};

type OwnProps = {
    shareID: JobID,
};

type Props = StateProps & DispatchProps & OwnProps;

class SharePage extends React.Component<Props, {}> {

    private addFilesToCapture = () => {
        if (!this.props.share) {
            return;
        }

        if (this.props.isLoggedIn && this.props.timelineJobID) {
            this.props.copyFilesToTimeline(this.props.share.thumbFiles, this.props.timelineJobID);
            this.props.goToTimeline();
        }
        else {
            this.props.goToRequireLoginCopyShare();
        }
    }

    public componentWillReceiveProps(newProps: Props) {
        if (newProps.share
            && newProps.share.thumbFiles.length
            && localStorageGet('addShareFilesToCapture') === newProps.share.id
            && this.props.timelineJobID
        ) {
            localStorageRemove('addShareFilesToCapture');
            this.props.copyFilesToTimeline(newProps.share.thumbFiles, this.props.timelineJobID);
        }
    }
    private onLogoClick = () => {
        if (this.props.isLoggedIn) {
            trackEvent('SharePage', 'CaptureLogo_clicked', 'goToTimeline');
            this.props.goToTimeline();
        }
        else {
            trackEvent('SharePage', 'CaptureLogo_clicked', 'goToOpenWeb');
            window.location.href = HOME_SITE_ADDRESS;
        }
    }

    private getThumbSection = (thumbFiles: CaptureFile[]) => {
       if (thumbFiles.length > 0) {
           const addFilesBtnText = getStringByAmount(
               thumbFiles.length,
               _('share__add_singleFile_to_capture__format'),
               _('share__add_multipleFiles_to_capture__format'),
           ).replace('%s', PRODUCT_NAME);

           return (
               <div>
                   <ThumbSection
                       files={thumbFiles}
                       gridStyle={this.props.gridStyle}
                       enterCarouselView={this.props.doEnterCarouselView}
                       isMobileDisplay={this.props.isMobileMode}
                   />
                   <ButtonWrapper>
                       <FilledTextButton
                           onClick={this.addFilesToCapture}
                           text={addFilesBtnText}
                           fillColor={colors.captureBlue}
                       />
                   </ButtonWrapper>
               </div>
           );
       }
    }
    private getDocSection = (docFiles: JobFile[]) => {
        if (docFiles.length > 0) {
            return (
                <DocSection
                    files={docFiles}
                    onDownloadSingleFile={this.props.doDownloadSingleFile}
                />
            );
        }
    }

    public componentWillMount() {
        if (this.props.lastViewOffset) {
            setTimeout(() => {
                window.scrollTo(0, this.props.lastViewOffset);
                this.props.doClearLastKnownPos(this.props.shareID);
            }, 0);
        }
    }
    public render() {
        let content = null;

        if (!this.props.share) {
            content = <RippleLoader />;
        }
        else {
            const totalFileCount = this.props.share.thumbFiles.length + this.props.share.docFiles.length;

            const headerText = getStringByAmount(
                totalFileCount,
                _('share__header_SingleFile__format'),
                _('share__header_MultipleFiles__format'),
            );
            const formattedHeaderText = headerText
                .replace('%user_name%', this.props.share.owner.name || this.props.share.owner.email || '')
                .replace('%product_name%', PRODUCT_NAME);

            const downloadBtnText = getStringByAmount(
                totalFileCount,
                _('download_file'),
                _('download_all'),
            );
            const captureTextPart1 = _('share__service_info_part1__format')
                .replace('%product_name%', PRODUCT_NAME);
            const captureTextPart2 = _('share__service_info_part2__format')
                .replace('%product_name%', PRODUCT_NAME)
                .replace('%storage_amount%', '2 GB');

            content = (
                <ContentWrapper>
                    <FadeInContainer isVisible={!this.props.lastViewOffset} >
                        <HeaderText>{formattedHeaderText}</HeaderText>
                        {this.getThumbSection(this.props.share.thumbFiles)}
                        {this.getDocSection(this.props.share.docFiles)}
                    </FadeInContainer>
                    {!isMobileDevice.iOS() && (
                        <ButtonWrapper>
                            <BorderedTextButton
                                onClick={this.props.doDownloadFiles}
                                text={downloadBtnText}
                                color={colors.captureBlue}
                            />
                        </ButtonWrapper>
                    )}
                    <InfoText >
                        <p>{captureTextPart1}</p>
                        <p>{captureTextPart2}*</p>
                        <SubText>*{_('telenor_unlimited_hook_subtext')}</SubText>
                    </InfoText>
                </ContentWrapper>
            );

        }
        const loginBtn = !this.props.isLoggedIn && (
            <LoginBtn>
                <TextButton
                    onClick={this.props.loginAndGoToTimeline}
                    text={_('login')}
                />
            </LoginBtn>
        );

        const captureLogo = (
            <LogoWrapper onClick={this.onLogoClick}>
                <CaptureLogoHorizontal />
            </LogoWrapper>
        );
        return (
            <PageEventTrigger context="SharePage" whenScrolledDown="didScrollDown">
                <PageWrapper navBar={<TwoAreasTopNavBar left={captureLogo} right={loginBtn}/>}>
                    {content}
                    <StatusNotificationPlacement />
                </PageWrapper>
            </PageEventTrigger>
        );
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    share: getShare(state, ownProps.shareID),
    gridStyle: getShareGridStyle(state, ownProps.shareID),
    isMobileMode: isMobileMode(state),
    timelineJobID: getTimelineJobID(state),
    isLoggedIn: isLoggedIn(state),
    lastViewOffset: getLastViewOffset(state, ownProps.shareID),
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    doLogin: () => requireAccountInfo(dispatch),
    loginAndGoToTimeline: () => loginAndGoTo(Pages.Timeline),
    doEnterCarouselView: (fileID: FileID) => dispatch(NavigateTo(Pages.AlbumCarousel(ownProps.shareID, fileID))),
    doDownloadFiles: () => downloadShare(dispatch, ownProps.shareID),
    doDownloadSingleFile: (fileID: FileID) => downloadSingleFile(dispatch, ownProps.shareID, fileID),
    copyFilesToTimeline: (files: CaptureFile[], timelineJobID: JobID) => {
        copyShareFilesToTimeline(dispatch, timelineJobID, files);
    },
    goToTimeline: () => dispatch(NavigateTo(Pages.Timeline)),
    goToRequireLoginCopyShare: () => dispatch(NavigateTo(Pages.CopyShareRequireLogin(ownProps.shareID))),
    doClearLastKnownPos: (jobID: JobID) => dispatch(LastSeenElementCleared(jobID)),
});
export const ShareReceiverPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SharePage);
