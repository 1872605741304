import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {createAlbumWithFiles} from '../../API/album';
import {copyFilesToAlbum, fetchListOfJobs} from '../../API/job';
import {_} from '../../assets/localizedStrings';
import {fontSize} from '../../assets/styleConstants';
import {AlbumListFilter} from '../../state/albumList/reducer';
import {
    AlbumJobInfo,
    getAlbumsCurrentUserCanAdd, privacyModeFilter,
} from '../../state/albumList/selectors';
import {getCurrentUserUUID} from '../../state/currentUser/selectors';
import {CaptureFile} from '../../state/files/reducer';
import {
    AlbumListDependentComponentMounted,
    AlbumListDependentComponentUnmounted,
} from '../../state/jobSyncing/actions';
import {State} from '../../state/store';
import {isMobileMode} from '../../state/viewMode/selectors';
import {AlbumSelectList} from '../Common/AlbumSelectList';
import {Button} from '../Common/Button';
import {OptionsOverlay} from '../Common/Overlay';
import {CheckIcon} from '../Icons/CheckIcon';

type StateProps = {
    albums: AlbumJobInfo[],
    currentUser?: UserID,
    isMobile: boolean,
};

type DispatchProps = {
    onFetchAlbums: (currentUser: UserID) => any,
    mountAlbumListDependency: () => void,
    unmountAlbumListDependency: () => void,
    doCopyFiles: (albumID: JobID) => Promise<void>,
    doCreateAlbum: () => any,
};

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${(props: {fontSize: string}) => props.fontSize};
`;

type OwnProps = {
    files: CaptureFile[];
    onConfirmNew: (albumID: JobID) => void;
    onConfirmExisting: () => void;
    cancel: () => void;
    filter?: AlbumListFilter
};

type Props = StateProps & DispatchProps & OwnProps;
type LocalState = {selectedAlbumID?: JobID};
class _AddToAlbumComponent extends React.Component<Props, LocalState> {
    public state: LocalState = {};

    public componentDidMount() {
        if (this.props.currentUser) {
            this.props.onFetchAlbums(this.props.currentUser);
            this.props.mountAlbumListDependency();
        }
    }

    public componentWillUnmount() {
        this.props.unmountAlbumListDependency();
        this.setState({selectedAlbumID: undefined});
    }

    private onConfirm = () => {
        if (this.state.selectedAlbumID) {
            this.props.doCopyFiles(this.state.selectedAlbumID);
            this.props.onConfirmExisting();
        }
    }

    private setSelectedAlbum = (id: JobID) => {
        this.setState({
            ...this.state,
            selectedAlbumID: id,
        });
    }

    public render() {
        if (!this.props.albums) {
            return null;
        }

        return (
            <OptionsOverlay
                isMobile={this.props.isMobile}
                onClose={this.props.cancel}
                cancelButton={Button(_('cancel'), this.props.cancel)}
                confirmButton={Button(_('done'), this.onConfirm, {
                    icon: CheckIcon,
                    isActive: !!this.state.selectedAlbumID,
                })}
                title={_('album_select')}
            >
                <ContentContainer
                    fontSize={
                        this.props.isMobile
                            ? fontSize.medium_18
                            : fontSize.large_22
                    }
                >
                    <AlbumSelectList
                        albums={this.props.albums}
                        onSelect={this.setSelectedAlbum}
                        selectedID={this.state.selectedAlbumID}
                        isMobile={this.props.isMobile}
                        onCreateNew={this.props.doCreateAlbum}
                    />
                </ContentContainer>
            </OptionsOverlay>
        );
    }
}

const stateToProps = (state: State, ownProps: OwnProps): StateProps => {
    const availableAlbums = getAlbumsCurrentUserCanAdd(state);
    const albums = availableAlbums.filter(privacyModeFilter[ownProps.filter || 'all']);

    return {
        albums,
        currentUser: getCurrentUserUUID(state),
        isMobile: isMobileMode(state),
    };
};

const dispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    onFetchAlbums: (currentUser: UserID) => fetchListOfJobs(dispatch, currentUser),
    mountAlbumListDependency: () => dispatch(AlbumListDependentComponentMounted()),
    unmountAlbumListDependency: () => dispatch(AlbumListDependentComponentUnmounted()),
    doCopyFiles: (albumID: JobID) => copyFilesToAlbum(dispatch, albumID, ownProps.files),
    doCreateAlbum: async () => {
        const albumID = await createAlbumWithFiles(dispatch, '__new_album__', ownProps.files);
        ownProps.onConfirmNew(albumID);
    },
});

export const AddToAlbum = connect(stateToProps, dispatchToProps)(
    _AddToAlbumComponent,
);
