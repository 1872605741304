import {createSelector} from 'reselect';
import {ThumbService} from '../../API/services/ThumbService';
import {VideoQuality, VideoService} from '../../API/services/VideoService';
import {cachedInArray} from '../../utilities/arrayUtils';
import {FileTarget, getFileTargetFromName} from '../../utilities/fileTarget';
import {getAuthTokenForJob} from '../currentUser/selectors';
import {FileMetadata} from '../fileMetadata/actions';
import {getThumbHostForJob, getVideoHostForJob} from '../hosts/selectors';
import {isStoryJob} from '../jobInfo/selectors';
import {State, StateWithFilesState} from '../store';
import {CaptureFile, CommentStatus, EncodingStatus, JobFile} from './reducer';

const isFolder = (file: JobFile) => {
    return file.path && file.path.charAt(file.path.length - 1) === '/';
};

export const getFiles = createSelector(
    (state: StateWithFilesState) => state.files,
    (files): DictionaryOf<JobFile> => (
        Object.keys(files).reduce((list: DictionaryOf<JobFile>, id: FileID) => {
            const file = files[id];
            if (!file.isDeleted && !isFolder(file)) { list[id] = file; }
            return list;
        }, {})
    ),
);

export const getFileByID = (state: StateWithFilesState, fileID: FileID): JobFile|undefined => state.files[fileID];

export const getJobFiles = createSelector(
    getFiles,
    (_state: StateWithFilesState, jobID: JobID) => jobID,
    (files, jobID): JobFile[] => (
        Object.keys(files).map((id) => files[id]).filter((file) => file.jobID === jobID)
    ),
);

export type ThumbSelector = (state: State, jobID: JobID) => DictionaryOf<string>;

export const makeThumbForJobFilesSelector = (size: number): ThumbSelector => createSelector(
    getThumbHostForJob,
    getAuthTokenForJob,
    getJobFiles,
    (host: string | null, auth: string, files: JobFile[]) => {
        const thumbURLDictionary: DictionaryOf<string> = {};
        if (host) {
            const service = new ThumbService(host, auth);

            files.forEach((file: JobFile) => {
                const { fileID, jobID } = file;
                thumbURLDictionary[fileID] = service.getThumbUrl(jobID, fileID, size);
            });
        }

        return thumbURLDictionary;
    },
);

export const getFileMetadata = (state: State) => state.fileMetadata || {};

const compareFileTime = (a: JobFile, b: JobFile) => {
    let sort = (b.ctime || b.mtime) - (a.ctime || a.mtime);
    if (sort === 0) {
        // Time is equal - sort by path (filename)
        sort = a.path.localeCompare(b.path);
    }
    return sort;
};

export const getCaptureFilesForJob = createSelector(// only includes Pictures and Movies
    getJobFiles,
    makeThumbForJobFilesSelector(256),
    makeThumbForJobFilesSelector(512),
    makeThumbForJobFilesSelector(1280),
    getFileMetadata,
    (
        files: JobFile[],
        thumbSmall: DictionaryOf<string>,
        thumbMedium: DictionaryOf<string>,
        thumbLarge: DictionaryOf<string>,
        metadata: DictionaryOf<FileMetadata>,
    ): CaptureFile[] => {
        return files.map((file) => {
            return {
                ...file,
                type: getFileType(file),
                thumbURLSmall: thumbSmall[file.fileID],
                thumbURLMedium: thumbMedium[file.fileID],
                thumbURLLarge: thumbLarge[file.fileID],
                metadata: metadata[file.fileID],
            };
        })
        .filter((capFile) =>
            (capFile.type === FileTarget.Pictures || capFile.type === FileTarget.Movies)
            // TODO: handle file groups by CAPWEB-1441
            && (capFile.group === undefined || capFile.group.isMaster),
        )
        .sort(compareFileTime);
    },
);

export const getCaptureFilesByID = (state: State, jobID: JobID, fileIDs: FileID[]): CaptureFile[] => {
    const captureFiles = getCaptureFilesForJob(state, jobID);
    const files = cachedInArray(fileIDs);
    return captureFiles.filter((f) => files(f.fileID));
};

export const getDocumentFilesForJob = createSelector(
    getJobFiles,
    (files: JobFile[]): JobFile[] => {
        return files.filter((f) => getFileType(f) === FileTarget.Documents)
                    .sort(compareFileTime);
    },
);

type FilesGrouped = DictionaryOf<JobFile[]>;
export const getFilesByJob: (state: StateWithFilesState) => DictionaryOf<JobFile[]> = createSelector(
    getFiles,
    (files) => (
        Object.keys(files).reduce((all: FilesGrouped, key: JobID) => {
            const file = files[key];
            all[file.jobID] = (all[file.jobID] || []).concat([file]);
            return all;
        }, {})
    ),
);

// Extra memoization to avoid reference-checking causing re-calculating in child selectors
// (as files-state changes a lot while id of first file in job changes almost never)
let prev_getFirstFileIDByJob: DictionaryOf<FileID> = {};
export const getFirstFileIDByJob = createSelector(
    getFilesByJob,
    (files) => {
        const changes: DictionaryOf<FileID> = {};
        Object.keys(files).forEach((jobID) => {
            const firstFile = files[jobID][0] || {};
            if (prev_getFirstFileIDByJob[jobID] !== firstFile.fileID) {
                changes[jobID] = firstFile.fileID;
            }
        });
        Object.keys(prev_getFirstFileIDByJob).forEach((jobID) => {
            const jobFiles = files[jobID] || [];
            if (jobFiles.length === 0) {
                const {[jobID]: omit, ...rest} = prev_getFirstFileIDByJob;
                prev_getFirstFileIDByJob = rest;
            }
        });
        if (Object.keys(changes).length > 0) {
            prev_getFirstFileIDByJob = {
                ... prev_getFirstFileIDByJob,
                ... changes,
            };
        }
        return prev_getFirstFileIDByJob;
    },
);

export const getCurrentCommentText = (state: StateWithFilesState, fileID: FileID) => {
    return state.files[fileID] ? state.files[fileID].pendingComment : '';
};

export const getCommentStatus = (state: StateWithFilesState, fileID: FileID) => {
    return state.files[fileID] ? state.files[fileID].pendingCommentStatus : CommentStatus.NOT_YET_SUBMITTED;
};

export const getEncodingStatus = (state: StateWithFilesState, fileID: FileID): EncodingStatus|undefined => {
    return state.files[fileID] ? state.files[fileID].encodingStatus : undefined;
};

export const getVideoURL = (state: State, jobID: JobID, videoID: FileID, quality: VideoQuality): string|undefined => {
    const host = getVideoHostForJob(state, jobID);
    if (host) {
        const auth = getAuthTokenForJob(state, jobID);
        const service = new VideoService(host, auth);
        return service.getVideoUrl(jobID, videoID, quality);
    }
};

export const getFileType = (file: JobFile): FileTarget => {
    if (file.duration !== undefined) {// newly uploaded file
        return file.duration !== 0 ? FileTarget.Movies : FileTarget.Pictures;
    } else {// fallback to check file name
        return getFileTargetFromName(file.path);
    }
};

export const isLastFileOfStoryJob = (state: State, fileID: FileID): boolean => {
    const file = getFileByID(state, fileID);
    if (file && isStoryJob(state, file.jobID)) {
        const allfiles = getJobFiles(state, file.jobID);
        return allfiles.length === 1 && allfiles[0].fileID === fileID;
    }

    return false;
};
