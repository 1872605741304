import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets';
import {_} from '../../assets/localizedStrings';

const Wrapper = styled.div`
    width: ${(props: {isMobile: boolean}) => props.isMobile ? 'auto' : '400px'};
    padding: 0 12%;
    box-sizing: border-box;
`;

const Header = styled.div`
    text-align: center;
    text-transform: uppercase;
    margin: 20px 0;
`;

const ShareIconsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 16px 0 8px 0;
`;
const InternalShareOptions = ShareIconsWrapper.extend`
    border-bottom: 1px solid ${colors.captureGrey300};
    padding-top: 0;
`;
const IconWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;
    width: 64px;
    text-align: center;
    margin-bottom: 8px;
`;

const BottomElementWrapper = styled.div`
    height: 32px;
    max-width: 240px;
    margin: 0 auto 8px auto;
`;

type Props = {
    shareOptions: React.ReactNode[],
    internalOptions?: React.ReactNode[],
    bottomElement?: React.ReactNode,
    isMobile: boolean,
};

export class ShareToComponent extends React.PureComponent<Props> {

    public render() {
        const internalShareIcons = this.props.internalOptions && (
            <InternalShareOptions>
                {this.props.internalOptions.map((option, i) => (
                    <IconWrapper key={i}>{option}</IconWrapper>
                ))}
            </InternalShareOptions>
        );
        const shareIcons = this.props.shareOptions.map((option, i) => (
            <IconWrapper key={i}>{option}</IconWrapper>
        ));

        return (
            <Wrapper isMobile={this.props.isMobile}>
                <Header>{_('share_to')}:</Header>
                {internalShareIcons}
                <ShareIconsWrapper>
                    {shareIcons}
                </ShareIconsWrapper>
                <BottomElementWrapper>
                    {this.props.bottomElement}
                </BottomElementWrapper>
            </Wrapper>
        );
    }
}
