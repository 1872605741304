import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets';
import {UserInfo} from '../../state/users/selectors';
import {Avatar} from '../Common/Avatar';
import {IconButton} from '../Common/IconTextButton';
import {OverlayModal} from '../Common/OverlayModal';
import {CloseIcon} from '../Icons';

const ListEntry = styled.div`
    display: flex;
    align-items: center;
    padding: 6px 0;
`;
const Name = styled.div`
    margin-left: 12px;
    flex: 1;
    overflow: hidden;
    overflow-wrap: break-word;
`;

const HeaderElement = styled.div`
    border-bottom: 1px solid ${colors.captureGrey400};
    padding-bottom: 8px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
`;

const List = styled.div`
    overflow-y: auto;
    width: ${(props: {isMobile: boolean}) => props.isMobile ? 'auto' : '350px'};
`;

const UserListEntry: React.SFC<UserInfo> = ({name, profilePicture}) => {
    const displayedUserName = name === '' ? 'Unnamed user' : name;
    return (
        <ListEntry>
            <Avatar size={40} name={displayedUserName} img={profilePicture}/><Name>{displayedUserName}</Name>
        </ListEntry>
    );
};

type Props = {
    usersList?: UserInfo[],
    headerText: string,
    onClose: () => any,
    isMobile: boolean,
};

export class UsersListModal extends React.Component<Props> {
    public render() {

        const userList = this.props.usersList && this.props.usersList.map((user) => (
            <UserListEntry key={user.userID} {...user}/>
        ));
        const headerElement = (
            <HeaderElement>
                {this.props.headerText}
                <IconButton
                    onClick={this.props.onClose}
                    icon={CloseIcon}
                />
            </HeaderElement>
        );
        return (
            <OverlayModal onClose={this.props.onClose} isMobile={this.props.isMobile} headerElements={headerElement}>
                <List isMobile={this.props.isMobile}>{userList}</List>
            </OverlayModal>

        );
    }
}
