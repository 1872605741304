import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {initTimeline} from '../../API/job';
import {colors, fontSize} from '../../assets/styleConstants';
import {JobFile} from '../../state/files/reducer';
import {getFiles} from '../../state/files/selectors';
import {State} from '../../state/store';
import {TimelineFileSelectingCanceled} from '../../state/timeline/actions';
import {TimelineFilterMode} from '../../state/timeline/reducers';
import {
    getSelectedTimelineFileIDs, getTimelineGroupStyle,
    getTimelineImageGroups, getTimelineScrollerGroups, ImageGroup, ScrollerYearGroup, TimelineGroupStyle,
} from '../../state/timeline/selectors';
import {getViewportWidth, isMobileMode} from '../../state/viewMode/selectors';
import {TopNavPlaceholder} from '../Navigation/TopNavBar';
import {PhotoSelectionNavbar} from '../PhotoSelection/PhotoSelectionNavbar';
import {TimelineScrollerPlacement} from '../TimelineScroller/TimelineScrollerPlacement';
import {TimelinePhotoGroups} from './TimelinePhotoGroups';

const PhotosWrapper = styled.div`
    margin-top: 24px;
`;

type OwnProps = {
    nextButtonLabel?: string,
    headerTitle?: string,
    doHandleFiles: (files: JobFile[]) => any;
    filter?: TimelineFilterMode;
    selectionMax?: number;
};
type StateProps = {
    selectedFiles: FileID[];
    files: DictionaryOf<JobFile>;
    isMobileMode: boolean;
    imagesGrouped: ImageGroup[],
    scrollerGroups: ScrollerYearGroup[],
    imageGroupStyle: TimelineGroupStyle,
    viewportWidth: number,
};
type DispatchProps = {
    onCancelSelectMode: () => any;
    fetchTimeline: () => any;
};
type Props = OwnProps & StateProps & DispatchProps;

class PhotoSelectionPageInner extends React.Component<Props> {
    private handleClickNext = async () => {
        if (this.props.selectedFiles.length > 0) {
            const files = this.props.selectedFiles.map(
                (fileID) => this.props.files[fileID],
            );
            await this.props.doHandleFiles(files);
        }
    }

    public componentWillMount() {
        this.props.fetchTimeline();
    }
    public componentWillUnmount() {
        if (this.props.selectedFiles.length > 0) {
            this.props.onCancelSelectMode();
        }
    }

    public render() {
        return (
            <div>
                <TopNavPlaceholder />
                <PhotosWrapper>
                    <TimelinePhotoGroups
                        isInSelectMode={true}
                        imagesGrouped={this.props.imagesGrouped}
                        groupStyle={this.props.imageGroupStyle}
                    />
                    <TimelineScrollerPlacement
                        imageGroups={this.props.imagesGrouped}
                        scrollerGroups={this.props.scrollerGroups}
                        imageGroupStyle={this.props.imageGroupStyle}
                        isMobileMode={this.props.isMobileMode}
                        viewportWidth={this.props.viewportWidth}
                    />
                </PhotosWrapper>
                <PhotoSelectionNavbar
                    nextButtonLabel={this.props.nextButtonLabel}
                    headerTitle={this.props.headerTitle}
                    onNextBtnClick={this.handleClickNext}
                    selectedCount={this.props.selectedFiles.length}
                    selectionMax={this.props.selectionMax}
                    isMobileMode={this.props.isMobileMode}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    selectedFiles: getSelectedTimelineFileIDs(state),
    files: getFiles(state),
    isMobileMode: isMobileMode(state),
    imagesGrouped: getTimelineImageGroups(state, ownProps.filter),
    scrollerGroups: getTimelineScrollerGroups(state),
    imageGroupStyle: getTimelineGroupStyle(state),
    viewportWidth: getViewportWidth(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onCancelSelectMode: () => dispatch(TimelineFileSelectingCanceled()),
    fetchTimeline: () => initTimeline(dispatch),
});

export const PhotoSelectionPage = connect<StateProps, DispatchProps, OwnProps, State>(
    mapStateToProps, mapDispatchToProps,
)(PhotoSelectionPageInner);
