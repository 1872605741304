import * as React from 'react';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const ChevronIconFlipped: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g strokeWidth="3" stroke={props.color} fill="none" fillRule="evenodd">
            <polyline transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) " points="8.16745743 4 16 11.9153857 8 20" />
        </g>
    </svg>
);

export const ChevronIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g strokeWidth="3" stroke={props.color} fill="none" fillRule="evenodd">
            <polyline transform="translate(12.000000, 12.000000) rotate(90.000000) translate(-12.000000, -12.000000) " points="8.16745743 4 16 11.9153857 8 20" />
        </g>
    </svg>
);
