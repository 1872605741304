import * as React from 'react';
import {trackEvent} from '../../analytics';
import {_} from '../../assets';
import {guaranteeAUser} from '../../state/currentUser/UserExistenceGuarantee';
import {getConnectedInstance} from '../../state/uploader/uploadQueue';
import {AlbumIllustration} from '../Illustrations/AlbumIllustration';
import {flattenFolder, Folder} from './FileAcceptZone';
import {UploadOverlay} from './UploadOverlay';
import {VisibleOnDrag} from './VisibleOnDrag';

type SingleAlbumProps = {
    albumID: JobID,
    albumName: string,
};

export class UploadOverlaySingleAlbumPlacement extends React.Component<SingleAlbumProps> {
    private fileHandler = async (folder: Folder) => {
        const files = flattenFolder(folder);
        trackEvent('Uploader', 'FilesAddedToAlbumByDrop', '', files.length);
        await guaranteeAUser();
        getConnectedInstance().addFiles(files, this.props.albumID, '');
    }

    public render() {
        const text = _('dropZone_text__singleAlbum').replace('%album_name%', this.props.albumName);

        return (
            <VisibleOnDrag>
                <UploadOverlay
                    fileHandler={this.fileHandler}
                    text={text}
                    illustration={<AlbumIllustration />}
                />
            </VisibleOnDrag>
        );
    }
}
