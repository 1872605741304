import {copy} from 'clipboard-js';
import * as React from 'react';
import styled from 'styled-components';
import {trackEvent} from '../../analytics/eventTracking';
import {triggerShareDialog} from '../../API/FBConnect/index';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {PageDescription} from '../../state/routing/actions';
import {withoutTheBools} from '../../utilities/arrayUtils';
import {isMobileDevice} from '../../utilities/device';
import {preventDefault} from '../../utilities/eventHandling';
import {shareToEmail, shareToSMS} from '../../utilities/share';
import {ShareToComponent} from '../Share/ShareToComponent';
import {GetLinkButton, ShareByMailButton, ShareBySMSButton, ShareToFBButton} from '../Share/ShareToOptionButtons';

export const CopyLinkOutlinedBtn = styled.a`
    height: 28px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid ${colors.captureBlue};
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    padding: 3px;

    font-size: ${fontSize.small_14};
    font-weight: bold;
    color: ${colors.captureBlue};
    text-transform: uppercase;
    text-align: center;

    &:active {
        border: 2px solid ${colors.captureBlue};
        padding: 2px;
        background-color: ${colors.captureBlue50};
    }
`;

type Props = {
    sharePage: PageDescription,
    doClose: () => any,
    onLinkCopied: () => any,
};
type LocalState = {
    showLink: boolean,
};
export class ShareURLToOptions extends React.PureComponent<Props, LocalState> {
    public state: LocalState = {showLink: false};

    private getSharedURL = (): URLstring => (
        location.origin + this.props.sharePage.url
    )
    private onShareByFB = () => {
        trackEvent('Share', 'clickedShareToFacebook');
        triggerShareDialog(this.getSharedURL() + '#sf');
        this.props.doClose();
    }
    private onShareByEmail = () => {
        trackEvent('Share', 'clickedShareToEmail');
        shareToEmail(this.getSharedURL() + '#se');
        this.props.doClose();
    }
    private onShareBySMS = () => {
        trackEvent('Share', 'clickedShareToSMS');
        shareToSMS(this.getSharedURL() + '#sms');
        this.props.doClose();
    }
    private onGetLink = () => {
        trackEvent('Share', 'clickedShareByCopying');

        if (isMobileDevice.any()) {
            this.setState({showLink: true});
        }
        else {
            copy(this.getSharedURL() + '#sc');
        }
    }
    private onCopyLink = () => {
        this.props.onLinkCopied();
        this.props.doClose();
    }
    public componentDidMount() {
        document.addEventListener('copy', this.onCopyLink);
    }
    public componentWillUnmount() {
        document.removeEventListener('copy', this.onCopyLink);
    }

    public render() {
        const isMobile = isMobileDevice.any();

        const shareOptions = withoutTheBools([
            <ShareToFBButton key="shareToFB" onClick={this.onShareByFB} />,
            isMobile && <ShareBySMSButton key="shareSMS" onClick={this.onShareBySMS} />,
            <ShareByMailButton key="shareMail" onClick={this.onShareByEmail} />,
            <GetLinkButton key="getLink" onClick={this.onGetLink} />,
        ]);

        const copyLinkButton = this.state.showLink && (
            <CopyLinkOutlinedBtn
                href={this.getSharedURL() + '#sc'}
                onClick={preventDefault}
            >
                {_('hold_to_copy_link')}
            </CopyLinkOutlinedBtn>
        );
        return (
            <ShareToComponent
                shareOptions={shareOptions}
                bottomElement={copyLinkButton}
                isMobile={isMobile}
            />
        );
    }
}
