import {createSelector} from 'reselect';
import {calcImagesPerRow, computeGridContainerWidth, GridStyle} from '../../utilities/gridElementSizeCalculator';
import {CaptureFile, JobFile} from '../files/reducer';
import {getCaptureFilesForJob, getDocumentFilesForJob} from '../files/selectors';
import {JobInfoElement} from '../jobInfo/reducer';
import {getJobInfoElement} from '../jobInfo/selectors';
import {State} from '../store';
import {getUsersInfo, UserInfo} from '../users/selectors';
import {getViewportHeight, getViewportWidth, isMobileMode} from '../viewMode/selectors';
import {getLastSeenElementForJob} from '../lastSeenElement/selectors';

export type Share = {
    id: JobID,
    owner: UserInfo,
    thumbFiles: CaptureFile[],
    docFiles: JobFile[],
};

const unknownUser: UserInfo = {userID: 'unknown', name: '', email: ''};

export const getShare: (state: State, jobID: JobID) => Share|undefined = createSelector(
    getJobInfoElement,
    getCaptureFilesForJob,
    getDocumentFilesForJob,
    getUsersInfo,
    (
        jobInfoElement: JobInfoElement|undefined,
        thumbFiles: CaptureFile[],
        docFiles: JobFile[],
        users: DictionaryOf<UserInfo>,
    ): Share|undefined => {
        // If job info is not fetched; the album is not ready to be composed
        if (jobInfoElement === undefined || jobInfoElement.jobInfo === undefined) {
            return;
        }

        const jobInfo = jobInfoElement.jobInfo;
        const owner = users[jobInfo.owner] || unknownUser;
        const thumbs = [...thumbFiles].reverse();

        return {
            id: jobInfoElement.jobID,
            owner,
            thumbFiles: thumbs,
            docFiles,
        };
    },
);

const bigThumbSize = {
    elementWidth: 296,
    elementHeight: 296,
    elementSpaceAround: 2,
};
const mediumThumbSize = {
    elementWidth: 128,
    elementHeight: 128,
    elementSpaceAround: 2,
};
const smallThumbSize = {
    elementWidth: 88,
    elementHeight: 88,
    elementSpaceAround: 2,
};
const getShareFileNumber = createSelector(getShare, (share: Share|undefined) => {
    if (share) {
        return share.thumbFiles.length;
    }

    return 0;
});
export const getShareGridStyle = createSelector(
    getShareFileNumber,
    isMobileMode,
    getViewportWidth,
    (shareFileNumber: number, isMobile: boolean, viewportWidth: number): GridStyle => {
        let style = null;
        switch (shareFileNumber) {
            case 1:
                style = bigThumbSize;
                break;
            case 2:
                style = mediumThumbSize;
                break;
            default:
                style = isMobile ? smallThumbSize : mediumThumbSize;
        }
        const totalElementWidth = style.elementWidth + (style.elementSpaceAround * 2);
        const maxWidth = totalElementWidth * Math.min(shareFileNumber, 5);

        return {
            ...style,
            width: computeGridContainerWidth(viewportWidth, maxWidth, style),
        };
    },
);

export const getLastViewOffset: (state: State, jobID: JobID) => number|undefined = createSelector(
    getJobInfoElement,
    getCaptureFilesForJob,
    getShareGridStyle,
    getLastSeenElementForJob,
    getViewportHeight,
    (jobInfo, files, shareGridStyle, lastSeenElement, vpHeight) => {
        if (lastSeenElement) {
            const fileIndex = [...files].reverse().map((f) => f.fileID).indexOf(lastSeenElement);
            const offset =  Math.floor(fileIndex / calcImagesPerRow(shareGridStyle)) * shareGridStyle.elementWidth;

            return offset > vpHeight ? offset : undefined;
        }
    },
);
