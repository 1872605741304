import * as React from 'react';
import {connect} from 'react-redux';
import {fontSize} from '../../assets/styleConstants';
import {Takeout} from '../../routing/pages';
import {NavigateTo} from '../../state/routing/actions';
import {FilledTextButton} from '../Common/IconTextButton';
import {trackTakeoutPageview} from './analytics';
import {ConfirmArea, TakeoutContent} from './TakeoutContent';
import {TakeoutLanguageSelector} from './TakeoutLanguageSelector';
import {getTakeoutString} from './takeoutLocalizedStrings';

type Props = {
    gotoTakeout: () => any,
};

class ShutdownInfoPageInner extends React.Component<Props> {
    public componentDidMount() {
        trackTakeoutPageview('/shutdown_info');
    }

    public render() {
        return (
            <TakeoutContent title={getTakeoutString('takeout_info')}>
                <TakeoutLanguageSelector />
                <div>
                    <p>{getTakeoutString('shutdown_ultimatum')}</p>
                    <p><b>{getTakeoutString('shutdown_date')}</b></p>
                    <p>{getTakeoutString('alert_copy')}</p>
                </div>
                <ConfirmArea>
                    <FilledTextButton
                        onClick={this.props.gotoTakeout}
                        text={getTakeoutString('alert_confirm')}
                        fontSize={fontSize.small_14}
                    />
                </ConfirmArea>
            </TakeoutContent>
        );
    }
}

export const ShutdownInfoPage = connect(
    null,
    (dispatch) => ({gotoTakeout: () => dispatch(NavigateTo(Takeout))}),
)(ShutdownInfoPageInner);
