import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {loadAccountInfoIfAvailable, requireAccountInfo} from '../../API/login';
import {whenUnauthenticated} from '../../API/toolbox';
import {State} from '../../state';
import {isLoggedIn} from '../../state/currentUser/selectors';
import {LoggedOutPromptOverlay} from './DialoguePromptOverlay';
import {LoadingPage} from './LoadingPage';

type Config<P> = {
    gotoIfLoginFails?: string, // Allow override of endpoint if login is canceled (defaults to /)
};

type StateProps = {
    isLoggedIn: boolean,
};

const stateToProps = (state: State): StateProps => ({
    isLoggedIn: isLoggedIn(state),
});

/**
 * Higher-Order-Component for decorating components so that they are only available if the user is logged in.
 * The decorated component will require the user to log in if not already logged in.
 */
export const requiresLogin = <P extends {}>(conf: Config<P> = {}) => (Inner: React.ComponentType<P>) => {
    type DispatchProps = {
        doLogIn: (gotoIfLoginFails: string) => void,
    };
    type Props = StateProps & DispatchProps & P;

    type ComponentState = {
        showLoggedOutDialog: boolean,
    };
    class RequireLoggedIn extends React.Component<Props, ComponentState> {
        public state: ComponentState = {showLoggedOutDialog: false};

        private tryLogIn = () => {
            this.props.doLogIn(conf.gotoIfLoginFails || '/');
        }

        private handleClickSignIn = () => {
            this.setState({showLoggedOutDialog: false});
            this.tryLogIn();
        }

        public componentWillMount() {
            if (!this.props.isLoggedIn) {
                this.tryLogIn();
            }

            whenUnauthenticated(() => {
                if (!this.state.showLoggedOutDialog) {
                    this.setState({showLoggedOutDialog: true});
                }
            });
        }

        public render() {
            if (!this.props.isLoggedIn) {
                return <LoadingPage />;
            }

            const loggedOutPrompt = this.state.showLoggedOutDialog && (
                <LoggedOutPromptOverlay doSignIn={this.handleClickSignIn} />
            );

            return (
                <div style={{height: '100%'}}>
                    <Inner {...this.props} />
                    {loggedOutPrompt}
                </div>
            );
        }
    }

    const dispatchToProps = (dispatch: Dispatch): DispatchProps => ({
        doLogIn: (gotoIfLoginFails: string) => requireAccountInfo(dispatch, gotoIfLoginFails),
    });

    return connect<StateProps, DispatchProps, P, State>(stateToProps, dispatchToProps)(RequireLoggedIn);
};

/**
 * Higher-Order-Component for decorating components so that they try fetch account info.
 */
export const fetchAccount = <P extends {}>(Inner: React.ComponentType<P>) => {
    type DispatchProps = {
        fetchAccountInfo: () => any,
    };

    type Props = StateProps & DispatchProps & P;

    class FetchAccountComp extends React.Component<Props> {
        public componentWillMount() {
            if (!this.props.isLoggedIn) {
                this.props.fetchAccountInfo();
            }
        }

        public render() {
            return <Inner {...this.props} />;
        }
    }

    const dispatchToProps = (dispatch: Dispatch): DispatchProps => ({
        fetchAccountInfo: () => loadAccountInfoIfAvailable(dispatch),
    });

    return connect<StateProps, DispatchProps, P, State>(stateToProps, dispatchToProps)(FetchAccountComp);
};
