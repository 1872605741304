import * as React from 'react';
import {colors} from '../../assets';
import {IconProps} from './index';

export const CloseIcon: React.SFC<IconProps> = (props) => {
    const color = props.color || colors.captureGrey800;
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon fill={color} points="19 6.4 17.6 5 12 10.6 6.4 5 5 6.4 10.6 12 5 17.6 6.4 19 12 13.4 17.6 19 19 17.6 13.4 12"/>
            </g>
        </svg>
    );
};
