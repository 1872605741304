import * as React from 'react';
import styled from 'styled-components';
import {trackEvent} from '../../analytics/eventTracking';
import {_} from '../../assets/localizedStrings';
import {preventDefault} from '../../utilities/eventHandling';
import {SadFaceIcon} from '../Icons/SadFaceIcon';

const AlbumImg = styled.img`
    max-width: 100%;
    display: block;
    flex: 0 0 auto;
    user-drag: none;
    user-select: none;
`;
const ErrorMessage = styled.div`
    color: white;
    font-size: 14px;
    text-align: center;
    padding: 40px;
`;
const ErrorIcon = styled.div`
    margin: 8px;
`;
const MetaDataArea = styled.div`
    position: absolute;
    bottom: 16px;
    left: 10px;
`;

type AlbumImageProps = {
    fileID: FileID,
    thumbURL: string|undefined,
    onImageDimensionsDetected?: (width: number, height: number) => any,
    metaDataContent?: React.ReactNode;
};

type AlbumImageState = {
    failedToLoadImage: boolean,
};

export class AlbumImage extends React.Component<AlbumImageProps, AlbumImageState> {

    public state = {failedToLoadImage: false};

    private imageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (this.props.onImageDimensionsDetected) {
            this.props.onImageDimensionsDetected(e.currentTarget.naturalWidth, e.currentTarget.naturalHeight);
        }
    }

    public componentWillReceiveProps(nextProps: AlbumImageProps) {
        if (nextProps.fileID !== this.props.fileID && this.state.failedToLoadImage === true) {
            this.setState({failedToLoadImage: false});
        }
    }

    private errorHandler = () => {
        this.setState({failedToLoadImage: true});
        trackEvent('Album', 'Failed_to_load_unsupported_media_type', this.props.fileID);
    }

    public render() {
        if (this.state.failedToLoadImage) {
            return (
                <ErrorMessage>
                    <ErrorIcon><SadFaceIcon/></ErrorIcon>
                    <div>{_('error_msg_unsupported_media_type')}</div>
                </ErrorMessage>
            );
        }

        return (
            <>
                <AlbumImg
                    src={this.props.thumbURL}
                    onLoad={this.imageLoaded}
                    onError={this.errorHandler}
                    onDragStart={preventDefault} // Needed to prevent item from being draggable on Firefox..
                />
                <MetaDataArea>
                    {this.props.metaDataContent}
                </MetaDataArea>
            </>
        );
    }
}
