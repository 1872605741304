import * as React from 'react';
import styled from 'styled-components';
import {colors, fontSize} from '../../assets/styleConstants';
import {CarouselViewInfo} from '../../state/carouselViewer/reducer';
import {ImageGroup, TimelineGroupStyle} from '../../state/timeline/selectors';
import {getRange} from '../../utilities/arrayUtils';
import {getMonthFromNumberString} from '../../utilities/dateOperations';
import {isMobileDevice} from '../../utilities/device';
import {calcImagesPerRow, getElementSize} from '../../utilities/gridElementSizeCalculator';
import {SelectionIndicator} from '../Common/SelectionIndicator';
import {GroupListItem, NormalItemImage, SelectModeGroupListItem} from './GroupListItem';
import {VisibleRange} from './TimelinePhotoGroups';

type ContainerProps = {bottomGap: number};
const GroupContainer = styled.div`
    padding-bottom: ${(props: ContainerProps) => props.bottomGap}px;
`;

type TitleAreaProps = {height: number, bottomGap: number, selectMode: boolean};
const TitleArea = styled.div`
    margin-bottom: ${(props: TitleAreaProps) => props.bottomGap}px;
    height: ${(props) => props.height}px;
    font-size: ${fontSize.large_24};
    text-transform: capitalize;
    user-select: none;

    display: flex;
    align-items: center;

    ${(props) => !isMobileDevice.any() && !props.selectMode ? `
        &:hover .item-select-indicator {
            visibility: visible;
        }
    ` : ''}
`;

const GroupCheckMark = styled.div`
    height: 24px;
    margin-left: 16px;
`;

const GridArea = styled.div`
    position: relative;
    display:flex;
    flex-wrap:wrap;
`;

type Props = ImageGroup & {
    onEnterCarouselView: (sourceGroup: string, fileID: FileID) => any,
    groupStyle: TimelineGroupStyle,
    visibleRange: VisibleRange,
    selection?: {
        isInSelectMode: boolean,
        onSelectFile: (fileID: FileID) => any,
        onSelectGroup: (fileIDs: FileID[]) => any,
        onDeSelectFile: (fileID: FileID) => any,
        onDeSelectGroup: (fileIDs: FileID[]) => any,
    },
    lastSeenElement?: FileID,
};

export class GroupListEntry extends React.Component<Props> {

    private getLabelsFromHeader = (header: string, groupStyle: TimelineGroupStyle) => {
        const [year, monthNum] = header.split('-');
        const groupTitle = `${getMonthFromNumberString(monthNum)} ${year}`;
        const groupSelection = this.props.selection && (
            <GroupCheckMark >
                <SelectionIndicator
                    onClick={this.handleToggleSelectGroup}
                    showPlaceholder={this.props.selection.isInSelectMode}
                    isSelected={this.isGroupSelected()}
                    fillColor={colors.captureBlue}
                />
            </GroupCheckMark>
        );
        return (
            <TitleArea
                height={groupStyle.headerHeight}
                bottomGap={groupStyle.headerBottomGap}
                selectMode={this.props.selection !== undefined && this.props.selection.isInSelectMode}
            >
                <span>{groupTitle}</span>
                {groupSelection}
            </TitleArea>
        );
    }

    private handleToggleSelectItem = (fileID: FileID, selected: boolean) => {
        if (this.props.selection) {
            if (selected) {
                this.props.selection.onSelectFile(fileID);
            } else {
                this.props.selection.onDeSelectFile(fileID);
            }
        }
    }
    private isGroupSelected = () => {
        return this.props.images.length > 0 &&
            this.props.images.every((img) => this.props.imagesSelectedStatus[img.fileID]);
    }
    private handleToggleSelectGroup = () => {
        if (this.props.selection) {
            const fileIDs: FileID[] = this.props.images.map((img) => img.fileID);
            if (this.isGroupSelected()) {
                this.props.selection.onDeSelectGroup(fileIDs);
            }
            else {
                const nonSelectedFiles = fileIDs.filter((id) => !this.props.imagesSelectedStatus[id]);
                this.props.selection.onSelectGroup(nonSelectedFiles);
            }
        }
    }

    public render() {
        const {header, groupStyle, visibleRange, position, height} = this.props;

        const farOutCutoff = 400; // How far outside the visibleRange the row must be to be hidden
        const startOfImageArea = position + groupStyle.headerHeight + groupStyle.headerBottomGap;
        const imageSize = getElementSize(groupStyle);
        const imagesPerRow = calcImagesPerRow(groupStyle);
        const totalRowNumber = Math.ceil(this.props.imageCount / imagesPerRow);

        const topPixelsToIgnore = Math.max(visibleRange.top - startOfImageArea - farOutCutoff, 0);
        const bottomPixelsToIgnore = Math.max(position + height - visibleRange.bottom - farOutCutoff, 0);

        const topRowsToIgnore = Math.floor(topPixelsToIgnore / imageSize.height);
        const bottomRowsToIgnore = Math.floor(bottomPixelsToIgnore / imageSize.height);

        // if image count is higher than number of images, a gray square will fill the gap
        const imagesTotal = this.props.images.map((image) => {
            const itemProps = {
                fileID: image.fileID,
                thumbURL: image.thumbURLSmall,
                videoFileDuration: image.duration,
                width: groupStyle.elementWidth,
                height: groupStyle.elementHeight,
                spaceAround: groupStyle.elementSpaceAround,
                selection: this.props.selection && {onToggleSelect: this.handleToggleSelectItem},
            };
            return this.props.selection && this.props.selection.isInSelectMode ? (
                <SelectModeGroupListItem
                    {...itemProps}
                    key={image.fileID}
                    selection={{
                        isSelected: this.props.imagesSelectedStatus[image.fileID],
                        onToggleSelect: this.handleToggleSelectItem,
                    }}
                />
            ) : (
                <GroupListItem
                    {...itemProps}
                    key={image.fileID}
                    jobID={image.jobID}
                    onClick={() => this.props.onEnterCarouselView(header, image.fileID)}
                    shouldFocus={this.props.lastSeenElement === image.fileID}
                />
            );
        }).concat(getRange(this.props.imageCount - this.props.images.length).map((i) => {
            return (
                <NormalItemImage
                    key={this.props.header + i}
                    elementWidth={groupStyle.elementWidth}
                    elementHeight={groupStyle.elementHeight}
                    elementSpaceAround={groupStyle.elementSpaceAround}
                />
            );
        }));

        return (
            <GroupContainer bottomGap={groupStyle.groupBottomGap}>
                {this.getLabelsFromHeader(header, groupStyle)}
                <div style={{height: topRowsToIgnore * imageSize.height}} />
                <GridArea>
                    {imagesTotal.slice(topRowsToIgnore * imagesPerRow, (totalRowNumber - bottomRowsToIgnore) * imagesPerRow)}
                </GridArea>
                <div style={{height: bottomRowsToIgnore * imageSize.height}} />
            </GroupContainer>
        );
    }
}
