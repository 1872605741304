import * as React from 'react';
import styled from 'styled-components';
import {AlbumFileComment} from '../../state/album/selectors';
import {RippleLoader} from '../Common/RippleLoader';
import {SingleCommentContainer} from './SingleCommentContainer';

type Props = {
    showNumberOfComments: number,
    comments: AlbumFileComment[],
    isPendingNewComment: boolean,
};

const LoaderWrapper = styled.div`
    height: 36px;
    margin-bottom: 12px;

    & > div {
        margin: 0 auto;
    }
`;

type ComponentState = {now: Date};
export class CommentsListComponent extends React.Component<Props, ComponentState> {
    private doRedrawInterval?: number;
    public state: ComponentState = {now: new Date()};

    public componentDidMount() {
        this.doRedrawInterval = window.setInterval(() => this.setState({now: new Date()}), 10000);

    }
    public componentWillUnmount() {
        window.clearInterval(this.doRedrawInterval);
    }

    public render() {

        let pendingCommentElement = null;
        let commentLimit = this.props.showNumberOfComments;

        if (this.props.isPendingNewComment) {
            pendingCommentElement = <LoaderWrapper><RippleLoader size={24}/></LoaderWrapper>;
            if (this.props.showNumberOfComments < this.props.comments.length) {
                commentLimit = commentLimit - 1;
            }
        }
        const commentList = this.props.comments.slice(-commentLimit).map((comment) =>
            <SingleCommentContainer key={comment.commentID} now={this.state.now} {...comment} />);

        return (
            <div>
                {commentList}
                {pendingCommentElement}
            </div>
        );
    }
}
