import * as React from 'react';
import {IconProps} from '../../../common/components/Icons';

/* tslint:disable:max-line-length jsx-self-close */
export const NextIconTV: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g strokeWidth="2" fill="#FFFFFF" fillRule="nonzero">
            <path d="M40,2A38,38,0,1,0,78,40,38,38,0,0,0,40,2ZM51.69,54.62H48.77V40L28.31,54.62V25.38L48.77,40V25.38h2.92V54.62Z" />
        </g>
    </svg>
);

export const PrevIconTV: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g strokeWidth="2" fill="#FFFFFF" fillRule="nonzero">
            <path d="M40,78A38,38,0,1,0,2,40,38,38,0,0,0,40,78ZM28.31,25.38h2.92V40L51.69,25.38V54.62L31.23,40V54.62H28.31V25.38Z" />
        </g>
    </svg>
);

export const PlayIconTV: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g strokeWidth="2" fill="#FFFFFF" fillRule="nonzero">
            <path d="M40,2A38,38,0,1,0,78,40,38,38,0,0,0,40,2ZM31.23,57.54V22.46L60.46,40Z" />
        </g>
    </svg>
);

export const PauseIconTV: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g strokeWidth="2" fill="#FFFFFF" fillRule="nonzero">
            <path d="M40,2A38,38,0,1,0,78,40,38,38,0,0,0,40,2ZM34.15,54.62H31.23V25.38h2.92V54.62Zm14.62,0H45.85V25.38h2.92V54.62Z" />
        </g>
    </svg>
);
