import {getCurrentLocale} from '../../../config/constants';
import {translatedStrings} from './translatedStrings';

/* tslint:disable:max-line-length */

export const defaultStrings: DictionaryOf<string> = {
    // General
    to: 'to',
    of: 'of',
    ok: 'OK',
    yes: 'Yes',
    got_it: 'Got it',
    see_more: 'See more',
    skip: 'Skip',
    no: 'No',
    done: 'Done',
    reactivate: 'Reactivate',
    unsubscribe: 'Unsubscribe',
    delete_: 'Delete',
    edit: 'Edit',
    update: 'Update',
    cancel: 'Cancel',
    share: 'Share',
    undo: 'Undo',
    back: 'Back',
    select_all: 'Select all',
    restore: 'Restore',
    filename: 'filename',
    file_type: 'file type',
    deleted: 'deleted',
    unknown: 'unknown',
    retry: 'Retry',
    create_account: 'Create an account',
    sign_in: 'Sign in',
    logged_out_prompt: 'Oops, you were logged out. To continue, please sign in',
    capture_save_see_share: 'Save, see and share all your photos and videos.',
    feature_only_available_in_app: 'This feature is currently only available in the Capture app.',
    open_capture: 'Open Capture',
    open_in_app: 'Open in app',
    storage: 'Storage',
    active: 'Active',
    save: 'Save', // for buttons

    // Time
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    day: 'Day',
    month: 'Month',
    year: 'Year',
    monthly: 'monthly',
    yearly: 'yearly',

    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',

    // User Action Prompts & Alerts
    delete_files_prompt__format: `%d files will be moved to Deleted Items. The files will be permanently deleted after 30 days.`,
    delete_single_file_prompt: `File will be moved to Deleted Items. The file will be permanently deleted after 30 days.`,
    delete_file_failed_message: 'The file could not be deleted',
    delete_file_failed_message__format: 'An error occurred when trying to delete "%s". Please try again.',
    // --- album prompts ---
    delete_album_file_prompt_text: 'File will be removed from album. Any comments and loves related to this file will also be deleted.',
    delete_last_file_prompt_text: 'File will be removed from album. Removing the last file in an album will also delete the album.',
    unsubscribe_album_prompt_text: 'You will no longer see this album on your album list or receive updates on this album.',
    delete_album_prompt_text: 'Album will be deleted and removed from your album list.',
    delete_comment_prompt_text: 'Comment will be deleted.',
    // --- settings page prompts ---
    remove_device_prompt__format: 'You will be logged out of %s on this device.',
    cancel_storage_plan_prompt__format: 'Plan will expire on %expired_date%. Total storage will be reduced to %storage_amount% once plan expires.',
    reactivate_storage_plan_prompt__format: 'Plan will continue current %period% billing cycle.',
    change_storage_plan_period_prompt: 'Confirm change in current plan\'s billing period. The new billing cycle will begin immediately', // replace with plan_upgrade when charge amount is available
    upgrade_storage_plan_size_prompt__format: 'Confirm change in your current plan if you’d like to increase your storage plan to %new_size% from %current_size%. Changes will take place immediately.', // remove once getting charge amount is possible
    upgrade_storage_plan_prompt__format: 'Plan will be upgraded to %plan_price%/%period% (%size%). You will be charged %amount% and the billing cycle will begin immediately.',
    downgrade_not_available: 'Downgrading your current plan is not available at this time.',
    downgrade_active_plan_alert: 'You must cancel your current plan and wait for it to expire before you may select this plan.',
    downgrade_canceled_plan_alert: 'You must wait for your current plan to expire before you may select this plan.',

    // --- trash and document prompts ---
    delete_single_document_file: 'File will be permanently deleted.',
    delete_document_files__format: '%d files will be permanently deleted.',
    restore_single_file_prompt: 'File will be restored to your photos.',
    restore_files_prompt__format: '%d files will be restored to your photos.',
    permanently_delete_single_file_prompt: 'File will be permanently deleted. This action cannot be undone.',
    permanently_delete_files_prompt__format: '%d files will be permanently deleted. This action cannot be undone.',

    // Navigation
    wall: 'Wall',
    timeline: 'Photos',
    albums: 'Albums',
    your_albums: 'Your Albums',
    menu: 'Menu',
    add_files: 'Add files',
    documents: 'Files',
    settings: 'Settings',
    account: 'Account',
    help: 'Help',
    deleted_items: 'Deleted items',
    login: 'Login',
    log_out: 'Log out',
    close: 'Close',
    wall_desc: 'A personal wall with all your photos that you can edit yourself.',
    timeline_desc: 'All your photos and videos, organized by date.',
    albums_desc: 'Add photos to an Album and share it with friends!',

    go_back_to_legacy_ribbon: 'Thank you for using this new feature. Click here to return to the regular web and access all features.',

    // Drag and drop
    drag_drop: 'Drag & Drop',
    dropZone_text__timeline: 'files to upload them, or a folder to create a new album',
    dropZone_text__albums: 'files or folders to create new albums',
    dropZone_text__singleAlbum: 'files anywhere to upload them to %album_name%',
    // click_here_to_select: 'or click here to select from you computer',

    // UploaderBox
    files: 'file(s)',
    uploaded: 'uploaded',
    did_not_upload: "didn't upload",
    upload_folder_reject: 'Cannot upload folders',
    upload_folder_safari_reject: 'Safari doesn\'t support uploading folders',
    unsupported_file_reject: 'Unsupported file',
    was_not_uploaded: 'Was not uploaded',
    see_which: 'See which',
    // show_files: 'Show files',
    // show_photos: 'Show photos',
    offline: 'No Internet connection',
    upload: 'Upload',
    uploading: 'Uploading',
    // pause: 'Pause',
    // resume: 'Resume',
    retrying_in: 'Retrying in',
    sec: 'sec',
    retrying_now: 'Retrying now...',
    try_again: 'Try again',
    stop: 'Stop',
    complete: 'Complete',
    cancelled: 'Cancelled',
    // unknown_format: 'Unknown format',
    preparing: 'Preparing...',
    waiting_for_internet_connection: 'Waiting for Internet connection...',
    out_of_storage: 'Out of storage',
    free_up_space: 'Delete files to resume the upload',
    cancelling_upload_are_you_sure: 'Are you sure you want to stop uploading?',

    // Popup
    reached_given_storage_ratio: 'Running out of storage',
    reached_given_storage_ratio_subText: 'You can free up space by deleting photos or videos, or buy more storage in Settings.',

    not_enough_storage_for_add: 'Insufficient storage',
    not_enough_storage_for_add_subText: 'You do not have enough storage for all the files you are trying to add. Free up space by deleting photos or videos, or buy more storage in Settings.',

    not_enough_storage_for_restore: 'Insufficient storage',
    not_enough_storage_for_restore_subText: 'You do not have enough storage for all the files you are trying to restore. Free up space by deleting photos or videos, or buy more storage in Settings.',

    // T I M E L I N E
    // emtpy state
    timeline_is_empty: 'Your timeline is empty.',
    timeline_filtered_photos_empty: 'You don\'t have any photos.',
    start_uploading_photos_now: 'Why don’t you start uploading photos now?',
    start_uploading_now: 'Why don’t you start uploading some now?',
    drag_drop_photos_to_upload: 'You can drag and drop your photos here or press the button below.',
    download_app_to_upload_directly_from_device: 'Install the Capture app on your mobile device to upload photos and videos directly from your device\'s camera and photo gallery',
    timeline_empty_screenshot_filter: 'You don\'t have any screenshots. Good for you!', // TODO: verify text
    timeline_filtered_videos_empty: 'You don\'t have any videos.',
    drag_drop_files_to_upload: 'You can drag and drop your files here or press the button below.',
    add_videos: 'Add videos',

    // timeline page
    selected__format: '%d selected',
    select_files: 'Select files',
    download_limit_exceeded: 'You cannot download more than 500 items at one time. Please select fewer items.',
    filter_by_screenshots: 'Screenshots',
    filter_by_videos: 'Videos',
    filter_by_images: 'Images',
    show_all: 'Show all',

    // A L B U M S
    album: 'album',
    item__format: '%d item',
    items__format: '%d items',
    participant__format: '%d participant',
    participants__format: '%d participants',
    participants: 'Participants',
    by: 'by',
    create_album: 'Create album',
    auto_created_album_title: 'Untitled album',
    new_album: 'New album',
    filter_by: 'Filter by',
    filter_albums: 'Filter albums',
    filtered_by_private: 'Filtered by private',
    filtered_by_shared: 'Filtered by shared',
    all_albums: 'All albums',
    shared_albums: 'Shared albums',
    private_albums: 'Private albums',
    download_album: 'Download album',
    add_photos: 'Add photos',
    add_to_album: 'Add to album',
    error_msg_unsupported_media_type: 'Oops! Something went wrong.',
    you_and_others_love_message_format: 'You and %d others love this',
    you_and_one_love_message: 'You and 1 other love this',
    you_love_message: 'You love this',
    one_person_love_message: '1 loves this',
    people_love_message_format: '%d love this',
    leave_album: 'Leave album',
    unsubscribe_album_failed_message__format: 'An error occurred when trying to leave the album %s. Please try again.',
    delete_album: 'Delete album',
    delete_file: 'Delete file',
    download_file: 'Download file',
    download_files_failed_message: 'An error occurred when trying to download. Please try again later.',
    delete_album_failed_message__format: 'An error occurred when trying to delete the album %s. Please try again.',
    set_as_cover_photo: 'Set as cover photo',
    set_cover_photo_failed_message: 'The cover photo could not be changed. Please try again.',
    add_file_to_timeline: `Add to "Photos"`,
    add_album_to_timeline: `Add album to "Photos"`,
    change_album_viewMode: 'Change view mode',
    sort_album_files: 'Sort album',
    sort_album_files_title: 'Sort album by',
    sort_album_files_by_added: 'Recently added',
    sort_album_files_by_newest_first: 'Newest first',
    sort_album_files_by_oldest_first: 'Oldest first',

    // empty album list state
    album_list_is_empty: 'You don\'t have any albums yet.',
    empty_albumList_secondTitle: 'Why don’t you create one now?',
    empty_filtered_private_albumList_title: 'You don’t have any private albums.',
    empty_filtered_shared_albumList_title: 'You don’t have any shared albums.',
    empty_filtered_albumList_subtext: 'It’s easy to make an album! Press the button below to start.',
    // add to album
    album_select: 'Select an album',

    // create album
    create_new: 'Create new',
    private_album: 'Private album',
    shared_album: 'Shared album',
    share_created_album_prompt_header: 'You have created an album!',
    share_created_album_prompt_text: 'Share it with friends and family to allow them to add photos and comment on your album.',
    share_later: 'Share later',
    share_now: 'Share now',

    // Comments
    comment: 'comment',
    comments: 'comments',
    write_your_comment: 'Write your comment',
    comment_error_message: 'Error posting comment. Please try again.',
    more: 'More',
    edited: 'Edited',
    edit_comment_error: 'Could not edit comment',
    delete_comment_error: 'Could not delete comment',

    view_all_comments: 'View all comments',

    what_is_your_name: 'What\'s your name?',
    tap_here_to_enter_name: 'Tap here to enter name',
    click_here_to_enter_name: 'Enter name here',
    enter: 'enter',
    for_best_experience: 'For the best experience',
    register_or_sign_in: 'register or sign in now!',

    year_ago__format: '%d year ago',
    years_ago__format: '%d years ago',
    month_ago__format: '%d month ago',
    months_ago__format: '%d months ago',
    day_ago__format: '%d day ago',
    days_ago__format: '%d days ago',
    hour_ago__format: '%d hour ago',
    hours_ago__format: '%d hours ago',
    minute_ago__format: '%d minute ago',
    minutes_ago__format: '%d minutes ago',
    just_now: 'just now',
    post: 'Post',

    // C A R O U S E L   V I E W
    slideshow: 'Slideshow',
    play: 'Play',
    pause: 'Pause',
    previous: 'Previous',
    next: 'Next',
    exit_fullscreen: 'Exit',
    info: 'Info',

    // N E W    F E A T U R E S
    releaseNotes_v0_header: 'New and improved Capture!',
    releaseNotes_v0_p1: 'Find photos more quickly with the new fast scroll and filtering features',
    releaseNotes_v0_p2: 'Upload photos by dragging and dropping anywhere on the screen',
    releaseNotes_v0_p3: 'Create, edit, and share albums',
    releaseNotes_v0_p4: 'Collaborate on albums with friends and family',
    releaseNotes_v0_p5: 'And lots more...',

    // S E T T I N G S
    // profile
    profile_info: 'Profile info',
    enter_your_name: 'Enter your name',

    // storage
    storage_management: 'Storage Management',
    total_storage: 'Total storage',
    remaining_storage: 'Remaining',
    used_storage: 'Used',
    buy_more_storage: 'Buy more storage',
    connected_devices: 'Logged in devices',
    unlimited: 'Unlimited',
    your_storage_plan: 'Your Storage Plan',
    storage_used_out_of__format: '%storage_used% used out of %storage_total%',
    cancel_storage_plan: 'Cancel Storage Plan',
    renew_status__format: 'Plan will be renewed on %s',
    expired_status__format: 'Plan will expire on %s',
    reactivate_storage_plan: 'Reactivate Storage Plan',

    // payment settings
    payment_settings: 'Payment Settings',
    payment_settings_info_text: 'Your registered card information:',
    update_card: 'Change card info',
    card_number: 'Card number',
    card_number_placeholder: 'Enter in your card number',
    card_exp_date: 'Expiration date',
    card_cvc: 'CVC/CVV',
    confirm_payment: 'Confirm payment',

    // print payment
    payment: 'Payment',
    choose_payment_method: 'Choose your payment method',
    pay_with_vipps: 'Pay with Vipps',

    // Buy more storage
    save__format: 'Save %d',
    get_started: 'Get Started!',
    telenor_unlimited_hook__format: 'As a Telenor mobile customer, you have access to unlimited storage for %product_name%',
    telenor_unlimited_hook_subtext: 'Eligible subscriptions: Fleksi/Medium/Trygg/Yng/U18/Frihet/Bedrift Fri+',
    monthly_sub: 'Monthly Plan',
    yearly_sub: 'Yearly Plan',

    // select language
    language: 'Language',
    select_language: 'Select language',

    // privacy
    privacy_info: 'Review your data and take control of your settings all in one place.',
    privacy: 'Privacy',
    open_privacy_pages: 'Open Privacy Pages',

    // I N F O
    // Download app page
    thanks_for_signing_in: 'Thanks for signing in!',
    get_capture_to_create_album: 'Get Capture on your mobile device to create your own Albums.',
    return_to_album: 'Return to the album',

    // Footer
    telenor_capture_text: 'Capture is developed by Telenor Digital, a Telenor company that provides tomorrow\'s digital solutions. The Connect account is used to log in to services provided by Telenor Digital.',
    terms_of_service: 'Terms of Service',
    customer_service: 'Customer Service',
    capture_footer_text_mobile: 'For the full experience, get the app',
    open: 'open',
    download: 'Download',

    // Album Not Found
    album_not_found: 'Album not found',
    album_not_found_description: 'Sorry! We were unable to find the Album. You may have clicked an expired, deleted, or mistyped link.',
    capture_create_user_description: 'Capture is a safe and easy-to-use storage service for pictures and videos. If you are not a Capture user, create an account today.',
    capture_more_link: 'Learn more about Capture',

    // Share Not Found
    share_not_found: 'Share not found',
    share_not_found_description: 'You may have clicked an expired, deleted, or mistyped link.',

    // Page Not Found
    page_not_found: 'We can\'t seem to find the page you\'re looking for!',
    helpful_links_description: 'Here are some helpful links instead',
    helpful_links_about_capture: 'About Capture',

    // Provide Password
    password_required: 'Password required',
    password_input_placeholder: 'Click here to enter password',
    password_required_submit: 'enter',
    password_incorrect: 'Incorrect password',
    album_password_required_description: 'The sender has protected this Album with a password. Please enter the password in the box above to unlock the Album.',

    // Require login to perform action
    needs_connect_to_create_album: 'You need a %product_name% account to create albums',
    needs_login_to_copy_files_to_timeline: 'You need a %product_name% account to save these files',
    needs_login_to_copy_album_to_timeline: 'You need a %product_name% account to save files from this album',

    // Edit/Create album page
    no_title_yet: 'No title yet',
    add_album_title: 'Enter album title',

    // Suggest Modern Browser
    upgrade_your_browser: 'Please upgrade your browser',
    upgrade_your_browser_des: 'Sorry! This content can’t be viewed in your current browser. Upgrade or use a different browser like Chrome, Firefox or Microsoft Edge.',

    // Album settings
    share_album: 'Share album',
    edit_album_settings: 'Edit album settings',
    enabled: 'Enabled',
    disabled: 'Disabled',
    privacy_private: 'Private',
    privacy_shared: 'Shared',
    edit_album_title_text: 'Album title (Tap to change):',
    edit_allow_comments_text: 'Anyone can view and add comments',
    edit_allow_upload_text: 'Anyone can add photos',
    edit_privacy_mode_text: 'Privacy level',

    // Toasts
    toast__coverPhoto_was_set: 'Cover photo changed',
    toast__multiple_files_deleted__format: '%d files deleted',
    toast__single_file_deleted: 'File deleted',

    toast__multiple_files_perm_deleted__format: '%d files permanently deleted',
    toast__single_file_perm_deleted: 'File permanently deleted',
    toast__multiple_files_perm_deleted_failed__format: 'Error - %d files not permanently deleted',
    toast__single_file_perm_deleted_failed: 'Error - file not permanently deleted',

    toast__single_file_restored: 'File restored',
    toast__multiple_files_restored__format: '%d files restored',
    toast__single_file_restored_failed: 'Error - file not restored',
    toast__multiple_files_restored_failed__format: 'Error - %d files not restored',

    toast__single_file_added_to_album__format: 'File added to Album "%album_name%"',
    toast__multiple_files_added_to_album__format: '%d files added to Album "%album_name%"',
    toast__single_file_added_to_album_failed__format: 'Error - file not added',
    toast__multiple_file_added_to_album_failed__format: 'Error - %d files not added',

    toast__album_auto_crated__format: 'Album "%album_name%" was created for you',
    toast__see_album: 'See album',
    toast__album_added_to_timeline: 'Album added to Photos',
    toast__album_added_to_timeline_failed: 'Failed to add album - please try again',
    toast__file_added_to_timeline: 'File added to Photos',
    toast__file_added_to_timeline_failed: 'Failed to add file - please try again',
    toast__files_were_shared: 'Your files were shared!',

    toast__files_are_deleting: 'Deleting files...',
    toast__files_are_restoring: 'Restoring files...',
    toast__files_are_copying: 'Copying files...',

    toast__credit_card_updated: 'Credit card info updated',
    toast__credit_card_update_failed: 'Failed to update info',

    toast__purchase_successful: 'Storage plan purchased',
    toast__purchase_failed: 'Failed to purchase storage plan',

    toast__plan_canceled: 'Storage plan canceled',
    toast__plan_cancel_failed: 'Failed to cancel storage plan',

    toast__plan_change_succeeded: 'Plan successfully updated',
    toast__plan_change_failed: 'Failed to update plan',

    toast__plan_reactivated: 'Storage plan reactivated',
    toast__plan_reactivation_failed: 'Failed to reactivate storage plan',

    // Trash
    trash_delete_info: 'Deleted files will be permanently deleted after 1 month.',
    trash_no_files: 'You don\'t have any deleted files.',
    trash_restore_info: 'You can restore them any time before that!',

    // Documents
    documents_no_files: 'You don\'t have any files.',

    // Sharing
    share_to: 'Share by',
    email: 'Email',
    SMS: 'SMS',
    get_link: 'Get link',
    hold_to_copy_link: 'Press and hold to copy link',
    copy_link: 'Copy link',
    link_copied: 'Link was copied!',
    share_files_success_header: 'You’ve shared an album!',
    can_not_share_video_to_fb_album: 'Ops! Videos can not be shared to Facebook Album',

    // email sharing form
    send_email_to: 'Send email to:',
    enter_receivers_address: 'Enter receiver\'s address here',
    email_subject: 'Subject:',
    enter_subject: 'Enter subject here',
    email_message: 'Message (Optional):',
    enter_message: 'Enter your message here',
    invalid_input: 'Invalid',

    // FB share form
    fb_album_share_desc: 'Write a name for the Facebook album',
    enter_fb_album_name: 'Facebook album name',
    fb_permissions_error: 'Ops! In order to share your photos to Facebook, we need permission to publish to your account.',
    fb_permissions_cta: ' You will be prompted for these permissions after clicking finished.',

    // S H A R E   R E C E I V E R
    share__header_SingleFile__format: '%user_name% has shared a file with you using %product_name%',
    share__header_MultipleFiles__format: '%user_name% has shared some files with you using %product_name%',
    share__add_singleFile_to_capture__format: 'Add file to my %s',
    share__add_multipleFiles_to_capture__format: 'Add files to my %s',
    download_all: 'Download all',
    share_added_to_timeline: 'Files were added',
    share_added_to_timeline_failed: 'Failed to add files - please try again',
    share_failed: 'Failed to share files - please try again',
    share__not_capture_user: 'Not a Capture user?',
    share__try_capture: 'Click here to give it a try!',
    share__service_info_part1__format: '%product_name% is made to keep your photos and videos safe! It is the easiest way to automatically backup photos and videos from your phone, so you can access them anytime anywhere, and share them with your friends and family.',
    share__service_info_part2__format: 'Download %product_name% and get %storage_amount% free storage. If you are a Telenor customer, you may have unlimited storage included in your subscription.',

    // Password required
    share_password_required_description: 'The sender has protected this Share with a password. Please enter the password in the box above to view the Share.',
};

export const getStringByAmount = (amount: number, singularStr: string, pluralStr: string) => amount > 1 ? pluralStr : singularStr;
export const getStringWithAmount = (amount: number, singularStr: string, pluralStrFormat: string, placeholder: string = '%d') => {
    return getStringByAmount(amount, singularStr, pluralStrFormat).replace(placeholder, amount.toString());
};

let localizedSet = translatedStrings[getCurrentLocale()] || {};

// Semi-hackish way of letting legacy-web inform of current language.
export function setCurrentLanguage(langCode: string) {
    if (translatedStrings[langCode] === undefined) {
        throw new Error('Unknown language code: ' + langCode);
    }
    localizedSet = translatedStrings[langCode];
}

export function _(stringKey: string): string {
    return localizedSet[stringKey] || defaultStrings[stringKey] || stringKey;
}
