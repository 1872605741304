import * as React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {requireAccountInfo} from '../../API/login';
import {_} from '../../assets/localizedStrings';
import {fontSize} from '../../assets/styleConstants';
import {FilledTextButton} from '../Common/IconTextButton';
import {trackTakeoutEvent} from './analytics';
import {TakeoutContent} from './TakeoutContent';
import {getTakeoutString} from './takeoutLocalizedStrings';

type Props = {
    doLogin: () => any;
};

const ButtonWrapper = styled.div`
    width: 200px;
`;

const TakeoutBeforeLoggedInPageInner: React.SFC<Props> = (props) => (
    <TakeoutContent title={getTakeoutString('takeout_service')}>
        <p>{getTakeoutString('before_login_info')}</p>
        <ButtonWrapper>
            <FilledTextButton
                onClick={props.doLogin}
                text={_('login')}
                fontSize={fontSize.small_12}
            />
        </ButtonWrapper>
    </TakeoutContent>
);

export const TakeoutBeforeLoggedInPage = connect(
    null,
    (dispatch) => ({
        doLogin: () => {
            trackTakeoutEvent('loginPage_loginButtonClicked');
            return requireAccountInfo(dispatch);
        },
    }),
)(TakeoutBeforeLoggedInPageInner);
