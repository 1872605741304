import {getCurrentLocale} from '../../../../config/constants';

const loadSDK = (d: Document, s: string, id: string): void => {
    let js: HTMLScriptElement;
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
        return;
    }

    js = d.createElement(s) as HTMLScriptElement;
    js.id = id;
    js.src = `//connect.facebook.net/${getCurrentLocale()}/sdk.js`;
    if (fjs && fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs);
    }
};

let FBAppID: string | undefined;
export const enableFBSDK = (appId: string) => FBAppID = appId;

let SDKInit: Promise<any> | undefined;
export const loadFBSDKIfNeeded = (): Promise<void> => {
    if (SDKInit === undefined) {
        const appId = FBAppID;
        if (appId === undefined) {
            throw new Error ('Must enableFBSDK before using');
        }
        SDKInit = new Promise((resolve, _reject) => {
            window.fbAsyncInit = () => {
                FB.init({
                    appId,
                    xfbml: true,
                    version: 'v2.11',
                });
                resolve();
            };
        });

        loadSDK(document, 'script', 'facebook-jssdk');
    }
    return SDKInit;
};

export const getFBLoginStatus = (): Promise<fb.AuthResponse> => {
    return new Promise((resolve) => {
        loadFBSDKIfNeeded().then(() => {
            FB.getLoginStatus((response) => {
                resolve(response);
            });
        });
    });
};

export const isAppConnectedToFacebook = async (): Promise<boolean> => {
    try {
        const FBStatus = await getFBLoginStatus();
        return FBStatus.status === 'connected';
    } catch (e) {
        return false;
    }
};

export const makeloginFB = (fbAppID: string) => (redirectURL?: string): void => {
    // Do redirect to avoid popup-blockers etc. The token returned is handled by bootstrap in index.tsx
    window.location.href = 'https://www.facebook.com/dialog/oauth?client_id=' + fbAppID +
        '&redirect_uri=' + encodeURIComponent(redirectURL || location.href) + '&response_type=token';
};

const _loginFB = (scope: string, authType?: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        loadFBSDKIfNeeded().then(() => {
            FB.login((response) => {
                // Possible FB response status
                // connected: The person is logged into Facebook, and has logged into your app.
                // not_authorized: The person is logged into Facebook, but has not logged into your app.
                // unknown: The person is not logged into Facebook, so you don't know if they've logged into your app.
                if (response.status === 'connected') {
                    resolve(response.authResponse);
                } else {
                    reject(response.status);
                }
            }, {scope, auth_type: authType});
        });
    });
};
export const getFBAccessTokenForceLogin = async (permissions: string[] = []) => {
    const auth = await _loginFB(permissions.join(','), 'rerequest');
    return auth.accessToken;
};

export const getFBAccessToken = async (permissions: string[] = []): Promise<string> => {
    const response = await getFBLoginStatus();

    if (response.status === 'connected') {
        return response.authResponse.accessToken;
    } else {
        const auth = await _loginFB(permissions.join(','));
        return auth.accessToken;
    }
};

export const getFBProfilePicture = (fbID: string) => `https://graph.facebook.com/${fbID}/picture?width=200&height=200`;

type FBError = {
    message: string,
    type: string,
    code: number,
    fbtrace_id: string,
};
type FBErrorResponse = {error: FBError};
const isFBError = (resp: FBErrorResponse|any): resp is FBErrorResponse => resp.error !== undefined;
type FBRequestMethod = 'GET' | 'POST' | 'DELETE';

const callFBAPI = <T extends {}>(path: string, method: FBRequestMethod, params: any = {}): Promise<T> => {
    return new Promise((resolve, reject) => {
        const requestCallback = (response: T | FBErrorResponse) => {
            if (!isFBError(response)) {
                resolve(response);
            } else {
                reject(response.error);
            }
        };

        loadFBSDKIfNeeded().then(() => {
            if (method === 'GET') {
                FB.api(path, method, requestCallback);
            } else {
                FB.api(path, method, params, requestCallback);
            }
        });
    });
};

export const triggerShareDialog = (href: URLstring) => {
    loadFBSDKIfNeeded().then(() => new Promise((ok) => {
        FB.ui({method: 'share', href}, ok);
    }));
};

type FBAlbum = {
    created_time: string,
    name: string,
    id: string,
};
type FBAlbumResponse = {data: FBAlbum[]};
const getFBAlbums = () => callFBAPI<FBAlbumResponse>('/me/albums', 'GET');

type FBCreateAlbumResponse = {id: string};
const createFBAlbum = (name: string) => callFBAPI<FBCreateAlbumResponse>('/me/albums', 'POST', {name});

export const getAlbumIDByName = async (albumName: string): Promise<string> => {
    const {data} = await getFBAlbums();

    for (const album of data) {
        if (album.name.toLowerCase() === albumName.toLowerCase()) {
            return album.id;
        }
    }

    const {id} = await createFBAlbum(albumName);
    return id;
};

type FBAddPhotoToAlbumResponse = {
    id: string,
    post_id: string,
};
export const addPhotoToFBAlbum = (albumID: string, photoURL: string) => {
    return callFBAPI<FBAddPhotoToAlbumResponse>(`/${albumID}/photos`, 'POST', {url: photoURL});
};
