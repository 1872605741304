import * as React from 'react';
import styled from 'styled-components';
import {colors, fontSize} from '../../assets/styleConstants';
import {CloseButton} from './CloseButton';

type Props = {
    text: string,
    action: () => any,
};

type State = {
    dismissed: boolean,
};

const ButtonDiv = styled.div`
    height: 40px;
    min-width: 128px;
    border-radius: 100px;

    font-size: ${fontSize.small_14};

    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px;
    z-index: 100;

    background: linear-gradient(to right, ${colors.captureBlue}, ${colors.gradientGreen})
`;

const Wrapper = styled.div`
    height: inherit;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0 10px;
    color: #fff;
`;

const AppLink = styled.div`
    min-width: 90px;
    padding-right: 5px;
    margin-right: 5px;
    text-align: center;

    border-right: 1px solid white;
    line-height: 24px;
    white-space: nowrap;
`;

const CloseWrapper = styled.div`
    height: 24px;
`;

export class FloatingPill extends React.Component<Props, State> {
    public state: State = {
        dismissed: false,
    };

    public dismissButton = () => {
        this.setState({
            ...this.state,
            dismissed: true,
        });
    }

    public render() {
        if (this.state.dismissed) {
            return null;
        }

        return (
            <ButtonDiv>
                <Wrapper>
                    <AppLink onClick={this.props.action}>
                        {this.props.text}
                    </AppLink>
                    <CloseWrapper onClick={this.dismissButton}>
                        <CloseButton size={24} />
                    </CloseWrapper>
                </Wrapper>
            </ButtonDiv>
        );
    }
}
