import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {TimelineFilterMode} from '../../state/timeline/reducers';
import {getConnectedInstance} from '../../state/uploader/uploadQueue';
import {isMobileDevice} from '../../utilities/device';
import {EmptyStatePage} from '../Common/EmptyStatePage';
import {FilledTextButton} from '../Common/IconTextButton';
import {DownloadAppInfo} from '../Info/DownloadAppInfo';
import {FileAcceptElement} from '../Uploader/FileAcceptElement';

const AddPhotosButton = styled.div`
    text-transform: uppercase;
    font-size: ${fontSize.xSmall_10};
    letter-spacing: 1.25px;
    display: inline-block;
`;

const InfoWrapper = styled.div`
    margin-top: 24px;
`;

type Props = {
    jobID: JobID;
    appliedFilter?: TimelineFilterMode,
};

type TextStrings = {
    headerTextFirst: string,
    headerTextSecond?: string,
    addFileButtonText?: string,
};
export class TimelineEmptyState extends React.Component<Props, {}> {
    private fileHandler = (files: File[]) => {
        getConnectedInstance().addFiles(files, this.props.jobID, '');
    }
    private getText = (): TextStrings => {
        switch (this.props.appliedFilter) {
            case 'only_images':
                return {
                    headerTextFirst: _('timeline_filtered_photos_empty'),
                    headerTextSecond: _('start_uploading_now'),
                    addFileButtonText: _('add_photos'),
                };
            case 'only_videos':
                return {
                    headerTextFirst: _('timeline_filtered_videos_empty'),
                    headerTextSecond: _('start_uploading_now'),
                    addFileButtonText: _('add_videos'),
                };
            case 'only_screenshots':
                return {
                    headerTextFirst: _('timeline_empty_screenshot_filter'),
                };
            case 'all':
            default:
                return {
                    headerTextFirst: _('timeline_is_empty'),
                    headerTextSecond: _('start_uploading_photos_now'),
                    addFileButtonText: _('add_photos'),
                };
        }
    }

    public render() {
        const isMobile = isMobileDevice.any();
        const text = this.getText();
        const addPhotosBtn = text.addFileButtonText && (
            <FileAcceptElement fileHandler={this.fileHandler}>
                <AddPhotosButton>
                    <FilledTextButton
                        text={text.addFileButtonText}
                        fillColor={colors.captureBlue}
                        color={'white'}
                    />
                </AddPhotosButton>
            </FileAcceptElement>
        );

        const header = (
            <div>
                <div>{text.headerTextFirst}</div>
                <div>{text.headerTextSecond}</div>
            </div>
        );
        const subHeader = text.addFileButtonText && !isMobile && _('drag_drop_files_to_upload');

        return (
            <EmptyStatePage
                header={header}
                subHeader={subHeader}
            >
                <div>
                    {addPhotosBtn}
                    <InfoWrapper><DownloadAppInfo /></InfoWrapper>
                </div>
            </EmptyStatePage>
        );
    }
}
