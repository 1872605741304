import * as React from 'react';
import styled from 'styled-components';
import {_, colors} from '../../assets';
import {WarningIcon} from '../Icons/WarningIcon';

const GrayOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(${colors.captureGrey800rgb},0.6);
`;

const BoxWrapper = styled.div`
    width: 100%;
    height: 136px;
    padding: 24px;
    box-sizing: border-box;
    background-color: white;
    position: absolute;
    bottom: 0;

    display: flex;
`;
const Thumbnail = styled.div`
    width: 56px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
`;
const Text = styled.div`
    margin-top: 8px;
`;
const Buttons = styled.div`
    color: ${colors.captureBlue};
    display: flex;
    justify-content: space-between;

    div {
        cursor: pointer;
    }
`;

type Props = {
    doStopAborted: () => void;
    doStop: () => void;
}

export class StopPrompt extends React.Component<Props, {}> {

    public render() {

        return (
            <GrayOverlay>
                <BoxWrapper>
                    <Thumbnail>
                        <WarningIcon size={56}/>
                    </Thumbnail>
                    <Content>
                        <Text>{_('cancelling_upload_are_you_sure')}</Text>
                        <Buttons>
                            <div onClick={this.props.doStop}>{_('yes')}</div>
                            <div onClick={this.props.doStopAborted}>{_('no')}</div>
                        </Buttons>
                    </Content>
                </BoxWrapper>
            </GrayOverlay>
        );
    }
}
