/**
 * Tool for updating the document title in sync with the components that are rendered.
 * Cancellation function returned when setting the title to allow resetting the title
 * (The reset only occurs when no other components have overwritten the title since.)
 *
 * Sample usage: A page-component sets and resets the page title when it is mounted:
 *    private resetTitle = () => {}; // Overwritten with actual reset-function when component mounts
 *    componentDidMount() {
 *        this.resetTitle = setDocumentTitle(this.props.title);
 *    }
 *    componentWillUnmount() {
 *        this.resetTitle();
 *    }
 *
 */
import {PRODUCT_NAME} from '../../../config/constants';

let counter: number = 0;

const resetTitle = () => {document.title = PRODUCT_NAME; };
const getResetCallback = (currentCounter: number) => () => {if (counter === currentCounter) {resetTitle(); }};

/**
 * The function setTitle sets the document title to the title provided
 */
export const setDocumentTitle = (title?: string): () => void => {
    document.title = PRODUCT_NAME + (title ? ': ' + title : '');
    counter++;
    return getResetCallback(counter);
};
