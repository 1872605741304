import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {trackEvent} from '../../analytics/eventTracking';
import {State} from '../../state';
import {DisplayListOfParticipantsInitiated} from '../../state/album/actions';
import {Album, AlbumViewMode, getAlbum, getAlbumGridStyle,
        getAlbumViewMode, getImagesPerRow, getLastViewedGridOffset,
        getNumberOfVisibleThumbs } from '../../state/album/selectors';
import {getLastViewAlbumFileIndex} from '../../state/carouselViewer/selectors';
import {LastSeenElementCleared} from '../../state/lastSeenElement/actions';
import {isMobileMode} from '../../state/viewMode/selectors';
import {isMobileDevice} from '../../utilities/device';
import {setDocumentTitle} from '../../utilities/documentTitle';
import {GridStyle} from '../../utilities/gridElementSizeCalculator';
import {AlbumOptionsPlacement} from '../AlbumOptions/AlbumOptionsPlacement';
import {PageEventTrigger} from '../Analytics/PageEventTrigger';
import {LoadingPage} from '../Common/LoadingPage';
import {FadeInContainer} from '../Common/StyledComponents';
import {AlbumReceiverFooter} from '../Info/AlbumReceiverFooter';
import {TopNavPlaceholder} from '../Navigation/TopNavBar';
import {RequireUserInfoPlacement} from '../ProvideName/RequireUserInfoPlacement';
import {UploadOverlaySingleAlbumPlacement} from '../Uploader/UploadOverlaySingleAlbumPlacement';
import {UploadStatusPlacementMinified} from '../Uploader/UploadStatusPlacementMinified';
import {AlbumPageContent} from './AlbumPageContent';
import {AlbumReceiverTopNav} from './AlbumReceiverTopNav';
import {autoSubscribeToAlbum} from './AutoSubscribeToAlbum';
import {OpenAlbumInAppButton} from './OpenAlbumInAppButton';

type StateProps = {
    album: Album|undefined,
    isMobile: boolean,
    lastViewIndex?: number,
    lastViewGridOffset?: {offset: number, fileIndex: number},
    albumViewMode: AlbumViewMode,
    numberOfVisibleThumbs: number,
    imagesPerRow: number,
    albumGridStyle: GridStyle,
};
type DispatchProps = {
    doShowParticipantsList: () => any,
    doClearLastKnownPos: () => void,
};
type OwnProps = {
    albumID: JobID,
};

type Props = StateProps & DispatchProps & OwnProps;

type LocalState = {
    visibleElements: number,
    displayOpenInAppButton: boolean,
};

const ContentWrapper = styled.div`
    display: block;
    margin: 0 auto;
`;
// Use for E2E test and DOM element size tracking after render
export const albumFileDOMClass = 'album-file-comp';

class _AlbumReceiverPage extends React.Component<Props, LocalState> {
    public state: LocalState = {
        visibleElements:
            this.props.albumViewMode === 'grid' ?
                this.props.numberOfVisibleThumbs : Math.max(10, (this.props.lastViewIndex || 0) + 5),
        displayOpenInAppButton: true,
    };
    private wrapperElement = React.createRef<HTMLDivElement>();
    private haveTrackedHealth: boolean = false;
    private resetTitle = () => {}; // Overwritten with actual reset-function when component mounts

    private handleScroll = () => {
        if (! this.wrapperElement.current) {
            return; // Avoid undefined-error if handling scroll before wrapperElement is bound [CAPWEB-699]
        }
        const fileCount = this.props.album ? this.props.album.files.length : 0;
        const distanceToBottom = this.wrapperElement.current.scrollHeight - window.innerHeight - window.pageYOffset;
        const incrementalValue = this.props.albumViewMode === 'grid' ? (this.props.imagesPerRow * 2) : 5;
        if (distanceToBottom < window.innerHeight && fileCount > this.state.visibleElements) {
            this.setState({...this.state, visibleElements: this.state.visibleElements + incrementalValue});
        }

        if (this.props.isMobile) {
            if (distanceToBottom > 34 !== this.state.displayOpenInAppButton) {
                this.setState({...this.state, displayOpenInAppButton: distanceToBottom > 34});
            }
        }
    }

    private checkHealthTracking = (props: Props) => {
        if (!this.haveTrackedHealth && props.album && props.album.files.length > 0) {
            const status = props.album.isHealthy ? 'healthy' : 'unhealthy';
            trackEvent('AlbumPage', 'albumLoaded', props.albumID);
            trackEvent('AlbumPage', 'albumLoaded__' + status, props.albumID, props.album.subscribersCount);
            this.haveTrackedHealth = true;
        }
    }

    public componentDidMount() {
        if (this.props.album) {
            this.resetTitle = setDocumentTitle(this.props.album.title);
            this.checkHealthTracking(this.props);

            if (this.wrapperElement.current && this.props.lastViewIndex) {
                const currentImgNode = this.wrapperElement.current.getElementsByClassName(albumFileDOMClass)[this.props.lastViewIndex];
                if (currentImgNode) {
                    const r = currentImgNode.getBoundingClientRect();
                    const newTop = window.pageYOffset + r.top - (window.innerHeight - r.height) / 2;
                    window.setTimeout(() => {
                        this.props.doClearLastKnownPos();
                        window.scrollTo(0, newTop);
                    });
                }
            }

            if (this.props.lastViewGridOffset && this.props.albumViewMode === 'grid') {
                    const pageOffset = this.props.lastViewGridOffset.offset;
                    if (pageOffset > (this.props.numberOfVisibleThumbs / this.props.imagesPerRow) * this.props.albumGridStyle.elementHeight) {
                        this.setState({...this.state, visibleElements: this.props.lastViewGridOffset.fileIndex + this.props.imagesPerRow});
                    }
                    setTimeout(() => {
                        this.props.doClearLastKnownPos();
                        window.scrollTo(0, pageOffset);
                    }, 0);
            }
        }
        window.addEventListener('scroll', this.handleScroll);
    }

    public componentWillReceiveProps(newProps: Props) {
        this.checkHealthTracking(newProps);
        if (newProps.albumViewMode === 'grid' && this.props.albumViewMode !== 'grid') {
            this.setState({...this.state, visibleElements: newProps.numberOfVisibleThumbs});
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.resetTitle();
    }

    public render() {
        const {album, isMobile} = this.props;
        if (!album || album.files.length === 0) {
            return <LoadingPage />;
        }
        const showOpenInAppButton = isMobileDevice.any() && this.state.displayOpenInAppButton;
        return (
            <PageEventTrigger context="AlbumPage" whenScrolledDown="didScrollDown">
                <div>
                    <ContentWrapper id="receiver-wrapper" innerRef={this.wrapperElement}>
                        <TopNavPlaceholder/>
                        <FadeInContainer isVisible={!this.props.lastViewIndex || !this.props.lastViewGridOffset}>
                            <AlbumPageContent
                                album={album}
                                numberOfVisibleFiles={this.state.visibleElements}
                                isMobileMode={this.props.isMobile}
                                viewMode={this.props.albumViewMode}
                                doShowParticipantsList={this.props.doShowParticipantsList}
                                gridStyle={this.props.albumGridStyle}
                            />
                        </FadeInContainer>
                    </ContentWrapper>
                    <AlbumReceiverFooter albumID={album.id}/>
                    {showOpenInAppButton && <OpenAlbumInAppButton albumID={album.id} />}
                    <AlbumReceiverTopNav
                        albumID={album.id}
                        firstAlbumFileID={album.files[0].fileID}
                        isSharedAlbum={album.isShared}
                        currentUserCan={album.currentUserCan}
                    />
                    {isMobile && <UploadStatusPlacementMinified />}
                    <RequireUserInfoPlacement jobID={album.id} />
                    <AlbumOptionsPlacement />
                    {album.currentUserCan.addPhotos &&
                        <UploadOverlaySingleAlbumPlacement albumID={this.props.albumID} albumName={album.title}/>
                    }
                </div>
            </PageEventTrigger>
        );
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    isMobile: isMobileMode(state),
    album: getAlbum(state, ownProps.albumID),
    lastViewIndex: getLastViewAlbumFileIndex(state, ownProps.albumID),
    lastViewGridOffset: getLastViewedGridOffset(state, ownProps.albumID),
    albumViewMode: getAlbumViewMode(state, ownProps.albumID),
    numberOfVisibleThumbs: getNumberOfVisibleThumbs(state),
    imagesPerRow: getImagesPerRow(state),
    albumGridStyle: getAlbumGridStyle(state),
});
const dispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    doShowParticipantsList: () => dispatch(DisplayListOfParticipantsInitiated(ownProps.albumID)),
    doClearLastKnownPos: () => dispatch(LastSeenElementCleared(ownProps.albumID)),
});
const albumIDProvider = (props: OwnProps) => props.albumID;

export const AlbumReceiverPage = autoSubscribeToAlbum(albumIDProvider)(
    connect(mapStateToProps, dispatchToProps)(
        _AlbumReceiverPage,
    ),
);
