import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {UserActionsStatus} from '../../state/userActions/reducer';
import {Button, ButtonProps} from './Button';

export type Props = {
    buttons: ButtonProps[],
};

const Wrapper = styled.div`
    max-width: 400px;
    background-color: white;
    box-shadow: 0px 2px 13px 0px rgba(0,0,0,0.2);
    padding: 24px 24px 12px;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 14px;

    div:first-child{
        padding-bottom: 8px;
        border-bottom: 1px solid ${colors.captureGrey300};
        margin-bottom: 4px;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    color: ${colors.captureBlue};
    transform: translateX(16px);

    span {
        padding: 12px 16px 4px;
        text-align: center;
        cursor: pointer;
        margin-left: 16px;
    }
`;

export class DialogBox extends React.Component<Props, {}> {

    private stopPropagation = (e: any) => {
        e.stopPropagation();
    }

    public render() {
        const makeButton = (props: ButtonProps, i: number) => <span key={i} onClick={props.onClick}>{props.text}</span>;

        return (
            <Wrapper onClick={this.stopPropagation}>
                <div>{this.props.children}</div>
                <ButtonsWrapper>
                    {this.props.buttons.map(makeButton)}
                </ButtonsWrapper>
            </Wrapper>
        );
    }
}

type ConfirmProps = {
    onConfirm: () => any;
    onCancel: () => any;
    confirmText?: string;
    cancelText?: string;
};

export const ConfirmPromptComponent: React.SFC<ConfirmProps> = (props) => (
    <DialogBox
        buttons={[Button(props.cancelText || _('cancel'), props.onCancel), Button(props.confirmText || _('ok'), props.onConfirm)]}
    >
        {props.children}
    </DialogBox>
);

export const OkPromptComponent = (props: {onOK: () => any, children?: any}) => (
    <DialogBox buttons={[Button(_('ok'), props.onOK)]}>
        {props.children}
    </DialogBox>
);

export type DialogPromptProps = {
    userActionStatus: UserActionsStatus,
    onConfirm: () => any,
    onDeny: () => any,
    confirmText: any,
    onOK: () => any,
    okText: any,
};

type LoggedOutPromptProps = {
    doSignIn: () => any,
};
export const LoggedOutPromptComponent: React.SFC<LoggedOutPromptProps> = ({doSignIn}) => (
    <DialogBox buttons={[Button(_('sign_in'), doSignIn)]}>
        {_('logged_out_prompt')}
    </DialogBox>
);
