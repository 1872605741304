import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {Pages} from '../../routing';
import {CaptureFile, JobFile} from '../../state/files/reducer';
import { getCaptureFilesByID } from '../../state/files/selectors';
import {FilesOptionsClosed} from '../../state/FilesOptions/actions';
import {FilesOptions} from '../../state/FilesOptions/reducer';
import {getFilesOptionsStatus} from '../../state/FilesOptions/selector';
import {NavigateTo} from '../../state/routing/actions';
import {State} from '../../state/store';
import {
    TimelineFileSelectingCanceled,
    TimelineFilterSet,
} from '../../state/timeline/actions';
import {TimelineFilterMode} from '../../state/timeline/reducers';
import {isMobileMode} from '../../state/viewMode/selectors';
import {AddToAlbum} from './AddToAlbum';
import {ShareFilesOptions} from './ShareFilesOptions';

type OwnProps = {
    files: FileID[];
    jobID: JobID;
};

type StateProps = {
    options?: FilesOptions;
    isMobile: boolean;
    selectedFiles: CaptureFile[];
};
type DispatchProps = {
    dismissOverlay: () => any;
    confirmOption: () => any;
    confirmCreateAlbum: (albumID: JobID) => void;
    selectFilter: (filter: TimelineFilterMode) => any;
};

type Props = StateProps & DispatchProps & OwnProps;

class FilesOptionsPlacementComponent extends React.Component<Props> {
    public componentWillUnmount() {
        if (this.props.options) { // close prompt if user navigates away
            this.props.dismissOverlay();
        }
    }
    public render() {
        if (!this.props.options) {
            return null;
        }
        switch (this.props.options.type) {
            case 'add_to_album':
                return (
                    <AddToAlbum
                        files={this.props.selectedFiles}
                        onConfirmNew={this.props.confirmCreateAlbum}
                        onConfirmExisting={this.props.confirmOption}
                        cancel={this.props.dismissOverlay}
                    />
                );
            case 'share_to_album':
                return (
                    <AddToAlbum
                        files={this.props.selectedFiles}
                        onConfirmNew={this.props.confirmCreateAlbum}
                        onConfirmExisting={this.props.confirmOption}
                        cancel={this.props.dismissOverlay}
                        filter={'shared'}
                    />
                );
            case 'share':
                return (
                    <ShareFilesOptions
                        isMobile={this.props.isMobile}
                        files={this.props.selectedFiles}
                        onCancel={this.props.dismissOverlay}
                        onConfirm={this.props.confirmOption}
                    />
                );
            default:
                return null;
        }
    }
}

const stateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    options: getFilesOptionsStatus(state),
    isMobile: isMobileMode(state),
    selectedFiles: getCaptureFilesByID(state, ownProps.jobID, ownProps.files),
});

const dispatchToProps = (dispatch: Dispatch): DispatchProps => {
    const dismissOverlay = () => dispatch(FilesOptionsClosed());
    const confirmOption = () => {
        dismissOverlay();
        dispatch(TimelineFileSelectingCanceled());
    };

    return {
        dismissOverlay,
        confirmOption,
        confirmCreateAlbum: (albumID: JobID) => {
            confirmOption();
            dispatch(NavigateTo(Pages.EditAlbum(albumID, 't')));
        },
        selectFilter: (filter: TimelineFilterMode) => {
            dispatch(TimelineFilterSet(filter));
            dismissOverlay();
        },
    };
};

export const FilesOptionsPlacement = connect(stateToProps, dispatchToProps)(
    FilesOptionsPlacementComponent,
);
