import * as React from 'react';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {TrashFile} from '../../state/trash/selectors';
import {withoutTheBools} from '../../utilities/arrayUtils';
import {TableEntry} from '../Common/ActionableTableContent';
import {Button} from '../Common/Button';
import {FileListEntry} from '../Common/FileListEntry';
import {FilesTable} from '../Common/FilesTable';
import {SelectionIndicator, VisibleSelectionIndicator} from '../Common/SelectionIndicator';
import {ThreeColumns} from '../Common/ThreeColumnsRow';
import {DeleteIcon} from '../Icons/DeleteIcon';
import {RefreshIcon} from '../Icons/RefreshIcon';

type Props = {
    isMobile: boolean,
    isInSelectMode: boolean,
    files: TrashFile[],
    allFilesSelected: boolean,
    onFileSelected: (fileID: FileID) => any,
    onFileDeSelected: (fileID: FileID) => any,
    onToggleSelectAll: () => any,
    onTriggerConfirmPrompt: (prompt: 'restore'|'delete', targetFile: FileID) => any,
};

export class TrashList extends React.Component<Props> {

    private getEntry: TableEntry<TrashFile> = ({row, setActionableId, currentActionableId}) => {
        const optionButtons = [
            Button(_('restore'), () => this.props.onTriggerConfirmPrompt('restore', row.id), {icon: RefreshIcon}),
            Button(_('delete_'), () => this.props.onTriggerConfirmPrompt('delete', row.id), {icon: DeleteIcon}),
        ];
        return (
            <FileListEntry
                key={row.id}
                doFileSelected={this.props.onFileSelected}
                doFileDeSelected={this.props.onFileDeSelected}
                doSetActionableFile={setActionableId}
                isMobile={this.props.isMobile}
                fileName={row.name}
                fileID={row.id}
                dateInfo={row.deletedTime}
                fileThumb={row.thumbnail}
                isSelected={row.isSelected}
                isInSelectMode={this.props.isInSelectMode}
                isActionable={currentActionableId === row.id}
                optionButtons={optionButtons}
            />
        );
    }

    public render() {
        const showDeletedHeader = !this.props.isMobile;
        const showSelectAll = !this.props.isMobile || this.props.isInSelectMode;
        const SelectAllButton = this.props.isMobile ? SelectionIndicator : VisibleSelectionIndicator;

        const lastHeaderElements = withoutTheBools([
            showDeletedHeader && (<div key="deleted">{_('deleted')}</div>),
            showSelectAll && (
                <SelectAllButton
                    key="Indicator"
                    onClick={this.props.onToggleSelectAll}
                    isSelected={this.props.allFilesSelected}
                    showPlaceholder={this.props.isMobile}
                    fillColor={colors.captureBlue}
                    strokeColor={'white'}
                />
            ),
        ]);
        const columns: ThreeColumns = [
            <div key="header-1">{_('filename')}</div>,
            <div key="header-2">{_('file_type')}</div>,
            <div key="header-3">{lastHeaderElements}</div>,
        ];

        return (
            <FilesTable
                isMobile={this.props.isMobile}
                isInSelectMode={this.props.isInSelectMode}
                headerColumns={columns}
                rows={this.props.files}
                entry={this.getEntry}
            />
        );
    }
}
