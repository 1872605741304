import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {_} from '../../assets/localizedStrings';
import {State} from '../../state/store';
import {TimelineFilterSet} from '../../state/timeline/actions';
import {TimelineFilterMode} from '../../state/timeline/reducers';
import {getTimelineFilterMode} from '../../state/timeline/selectors';
import {FilterIconButton, FilterTextButton} from '../Common/FilterButton';
import {RadioButtonOption} from '../Common/RadioButtonGroup';

type DispatchProps = {
    onSelect: (filter: TimelineFilterMode) => any;
};
type StateProps = {
    buttonText: string;
    current: TimelineFilterMode;
    options: Array<RadioButtonOption<TimelineFilterMode>>;
    isFilterApplied: boolean;
};
type OwnProps = {
    showText?: boolean;
};

export const availableFilterOptions: Array<{value: TimelineFilterMode, text: string}> = [
    {value: 'all', text: _('show_all')},
    {value: 'only_images', text: _('filter_by_images')},
    {value: 'only_videos', text: _('filter_by_videos')},
    {value: 'only_screenshots', text: _('filter_by_screenshots')},
];
// TODO: Add proper values for the different filters
const buttonText: Record<TimelineFilterMode, string> = {
    all: _('filter_by'),
    only_images: _('filter_by'),
    only_videos: _('filter_by'),
    only_screenshots: _('filter_by'),
};

const stateToProps = (state: State): StateProps => ({
    buttonText: buttonText[getTimelineFilterMode(state)],
    options: availableFilterOptions,
    current: getTimelineFilterMode(state),
    isFilterApplied: getTimelineFilterMode(state) !== 'all',
});

const dispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onSelect: (filter: TimelineFilterMode) =>
        dispatch(TimelineFilterSet(filter)),
});

const TimelineFilterTextButton = FilterTextButton<TimelineFilterMode>();
const TimelineFilterIconButton = FilterIconButton<TimelineFilterMode>();

export const TimelineFilterButton = connect(stateToProps, dispatchToProps)(
    (props: StateProps & DispatchProps & OwnProps) => {
        const FilterButton = props.showText ? TimelineFilterTextButton : TimelineFilterIconButton;
        return <FilterButton {...props} />;
    },
);
