import React, {Component} from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {checkVideoTranscode} from '../../../common/API/album';
import {colors} from '../../../common/assets/styleConstants';
import {RippleLoader} from '../../../common/components/Common/RippleLoader';
import {AlbumImage} from '../../../common/components/Image/AlbumImage';
import {VideoPlayStatus} from '../../../common/components/Video/AlbumVideo';
import {PlayButton} from '../../../common/components/Video/PlayButton';
import {AlbumFile} from '../../../common/state/album/selectors';
import {getTVFileSizeProvider} from '../../../common/state/carouselViewer/selectors';
import {FileDimensionsDiscovered} from '../../../common/state/files/actions';
import {CaptureFile, EncodingStatus} from '../../../common/state/files/reducer';
import {getEncodingStatus, getVideoURL} from '../../../common/state/files/selectors';
import {State} from '../../../common/state/store';
import {FileTarget} from '../../../common/utilities/fileTarget';

const FileElementWrapper = styled.div`
    width: ${(props: {width: number, height: number}) => props.width}px;
    height: ${(props) => props.height}px;
    img{
        animation: fadeIn 0.3s ease-in both;
        width: ${(props) => props.width}px;
        height: ${(props) => props.height}px;
    }
`;

const PlayButtonWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:focus, &:hover {
        box-shadow: 0 0 24px 0 #fff;
        border-radius: 30px;
        background-color: rgba(${colors.white_rgb}, 0.5);
    }
`;

const LoaderWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

type StateProps = {
    nodeSize: {width: number, height: number},
    isVideoPlayable: boolean;
    isVideoEncoding: boolean;
    videoURL: string|undefined;
};

type DispatchProps = {
    checkVideoTranscode: () => Promise<any>,
    onImageDimensionsDetected: (width: number, height: number) => any,
};

type OwnProps = {
    file: AlbumFile|CaptureFile,
    autoPlay: boolean,
    onVideoPlayStatusChanged?: (newStatus: VideoPlayStatus) => any,
};

type Props = StateProps & DispatchProps & OwnProps;

const tryCheckVideoTranscode = (props: Props) => {
    if (props.file.type === FileTarget.Movies &&
        !props.isVideoPlayable &&
        !props.isVideoEncoding) {
        props.checkVideoTranscode();
    }
};

class MediaElementTVComp extends Component<Props> {
    private isVideoPlaying: boolean = false; // avoid unnecessary auto play

    private notifyVideoPlayStatus = (newStatus: VideoPlayStatus) => {
        if (this.props.onVideoPlayStatusChanged) {
            this.props.onVideoPlayStatusChanged(newStatus);
        }
    }

    private playVideo = () => {
        if (window.AndroidTV && this.props.videoURL) {
            window.AndroidTV.playVideoInApp(this.props.videoURL);
            this.notifyVideoPlayStatus('playing');
        }
    }

    private handlePlayButtonClicked = () => {
        this.isVideoPlaying = true;
        this.playVideo();
    }

    private handleVideoPlayEnd = () => {
        this.notifyVideoPlayStatus('end');
        this.isVideoPlaying = false;
    }

    private getPlayButton = () => {
        if (this.props.isVideoEncoding) {
            return (
                <LoaderWrapper>
                    <RippleLoader color={'white'} size={64} />
                </LoaderWrapper>
            );
        } else if (this.props.isVideoPlayable) {
            return (
                <PlayButtonWrapper onClick={this.handlePlayButtonClicked}>
                    <PlayButton size={68}/>
                </PlayButtonWrapper>
            );
        }

        return null;
    }

    public componentDidMount() {
        tryCheckVideoTranscode(this.props);
        window.addEventListener('AndroidTV.videoEnd', this.handleVideoPlayEnd);
    }

    public componentWillUnmount() {
        window.removeEventListener('AndroidTV.videoEnd', this.handleVideoPlayEnd);
    }

    public componentWillReceiveProps(nextProps: Props) {
        tryCheckVideoTranscode(nextProps);

        if (this.props.videoURL !== nextProps.videoURL) {
            this.isVideoPlaying = false;
        }

    }

    public componentDidUpdate() {
        if (this.props.autoPlay && this.props.isVideoPlayable && !this.isVideoPlaying) {
            this.isVideoPlaying = true;
            window.setTimeout(this.playVideo, 500); // delay auto play to show video poster
        }
    }

    public render() {
        return (
            <FileElementWrapper
                width={this.props.nodeSize.width}
                height={this.props.nodeSize.height}
            >
                <AlbumImage
                    key={this.props.file.fileID}
                    fileID={this.props.file.fileID}
                    thumbURL={this.props.file.thumbURLLarge}
                    onImageDimensionsDetected={this.props.onImageDimensionsDetected}
                />
                {this.getPlayButton()}
            </FileElementWrapper>
        );
    }
}

const stateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    nodeSize: getTVFileSizeProvider(state)(ownProps.file),
    isVideoPlayable:
        getEncodingStatus(state, ownProps.file.fileID) === EncodingStatus.ENCODED,
    isVideoEncoding:
        getEncodingStatus(state, ownProps.file.fileID) === EncodingStatus.PENDING,
    videoURL: getVideoURL(
        state,
        ownProps.file.jobID,
        ownProps.file.fileID,
        'v-low', // TODO: solve bandwidth issue from android TV box
    ),
});

const dispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    checkVideoTranscode: () =>
        checkVideoTranscode(dispatch, ownProps.file.jobID, ownProps.file.fileID),
    onImageDimensionsDetected: (width: number, height: number) => {
        dispatch(FileDimensionsDiscovered([{fileID: ownProps.file.fileID, width, height}]));
    },
});

export const MediaElementTV = connect(stateToProps, dispatchToProps)(MediaElementTVComp);
