import {Action, isType} from '../common/actions';
import {AlbumListDependentComponentMounted, AlbumListDependentComponentUnmounted} from './actions';

export type JobSyncingState = {
    albumListDependentComponentsMounted: number,
};

const initialState: JobSyncingState = {
    albumListDependentComponentsMounted: 0,
};

export const jobSyncingReducer = (state: JobSyncingState = initialState, action: Action<any>): JobSyncingState => {
    if (isType(action, AlbumListDependentComponentMounted)) {
        return {...state, albumListDependentComponentsMounted: state.albumListDependentComponentsMounted + 1};
    }
    if (isType(action, AlbumListDependentComponentUnmounted)) {
        return {...state, albumListDependentComponentsMounted: state.albumListDependentComponentsMounted - 1};
    }

    return state;
} ;
