import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets/styleConstants';
import {EditIcon} from '../Icons/EditIcon';
import {TextInput} from './Input';

const EditableTextInput = styled(TextInput)`
    margin-right: 8px;
    flex-grow: 1;
    background-color: inherit;
    text-overflow: ellipsis;
    text-align: ${(props: {alignCentered: boolean|undefined}) => props.alignCentered ? 'center' : 'inherit'};
    font-size: inherit;
    max-width: 100%;
    width: ${(props) => props.width}px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
`;

const Placeholder = styled.div`
    width: ${(props: {size: number}) => props.size}px;
    height: ${(props: {size: number}) => props.size}px;
    flex-shrink: 0;
    margin-right: 8px;
`;
const Button = styled.div`
    cursor: pointer;
`;

type Props = {
    value: string,
    onBlur: (value: string) => any, //blur 'submits' value
    iconSize?: number,
    placeholder?: string,
    iconColor?: string,
    onChange?: (value: string) => any,
    onFocus?: () => any,
    maxLength?: number,
    alignCentered?: boolean,
};

type State = {
    editedText: string,
};

export class EditableInput extends React.Component<Props, State> {
    public state: State = {
        editedText: this.props.value,
    };
    private input = React.createRef<HTMLTextAreaElement>();

    private handleTextChanged = (evt: any) => {
        if (this.props.onChange) {
            this.props.onChange(evt.target.value);
        }
        this.setState({
            ...this.state,
            editedText: evt.target.value,
        });
    }

    private handleInputBlur = () => {
        if (this.props.onBlur) {
            this.props.onBlur(this.state.editedText);
        }
    }

    private handleEditIconClick = () => {
        if (this.input.current) {
            this.input.current.focus();
            // focus to end of textarea content
            this.input.current.selectionStart = this.input.current.selectionEnd = this.input.current.value.length;
        }
    }
    private handleKeyPress = (e: any) => {
        if (this.input.current && e.keyCode === 13) {
            this.input.current.blur();
        }
    }

    public componentWillReceiveProps(nextProps: Props) {
        if (this.props.value !== nextProps.value
            && nextProps.value !== this.state.editedText) {
            this.setState({
                ...this.state,
                editedText: nextProps.value,
            });
        }
    }

    public render() {
        const iconSize = this.props.iconSize || 16;
        return (
            <Wrapper>
                {this.props.alignCentered && <Placeholder size={iconSize} />}
                <EditableTextInput
                    innerRef={this.input}
                    value={this.state.editedText}
                    placeholder={this.props.placeholder}
                    onChange={this.handleTextChanged}
                    onBlur={this.handleInputBlur}
                    maxLength={this.props.maxLength}
                    onFocus={this.props.onFocus}
                    onKeyDown={this.handleKeyPress}
                    alignCentered={this.props.alignCentered}
                    width={(this.state.editedText.length + 1) * 11}
                />
                <Button onClick={this.handleEditIconClick}>
                    <EditIcon
                        size={iconSize}
                        color={this.props.iconColor || colors.captureBlue}
                    />
                </Button>
            </Wrapper>
        );
    }
}
