import {JobFile as APIJobFile} from '../../API/services/AppService';
import {AlbumViewMode} from '../album/selectors';
import {createActionCreator, createVoidActionCreator} from '../common/actions';

export const StartFetchingDefaultJob = createVoidActionCreator('START_FETCHING_DEFAULT_JOB');

export const FetchedDefaultJob = createActionCreator<JobID>('FETCHED_DEFAULT_JOB');
export const FetchedLastSerialOfDefaultJob = createActionCreator<number>('FETCHED_DEFAULT_JOB_SERIAL');

export const UnableToFetchDefaultJob = createActionCreator<any>('UNABLE_TO_FETCH_DEFAULT_JOB');

export const UnableToFetchJobChanges = createActionCreator<JobID>('UNABLE_TO_FETCH_JOB_CHANGES');

type FileReference = {
    jobID: JobID,
    fileID: FileID,
};

type MultiFileReference = {
    jobID: JobID,
    files: FileID[],
};

export type FileDescription = FileReference
& Omit<APIJobFile, 'id' | 'aspect_ratio'>
& {
    user_uuid: UserID,
    timestamp: number,
};

export const FileWasAddedToJob = createActionCreator<FileDescription>('FILE_WAS_ADDED_TO_JOB');
export type FileCopiedInfo = {from: FileID, to: FileReference};
export const FileWasCopiedToJob = createActionCreator<FileCopiedInfo>('FILE_WAS_COPIED_TO_JOB');
export const FilesCopiedToAlbum = createActionCreator<MultiFileReference>('FILES_COPIED_TO_ALBUM');
export const FilesCopiedToAlbumFailed = createActionCreator<MultiFileReference>('FILES_COPIED_TO_ALBUM_FAILED');

type FileRangePayload = {
    jobID: JobID,
    start: Month,
    end: Month,
};
export type FilesFetchedPayload = {
    jobID: JobID,
    lastEvent: number,
    files: FileDescription[],
};
type FileRangeWasFetchedPayload = FilesFetchedPayload & FileRangePayload;
export const FileRangeFetchingStarted = createActionCreator<FileRangePayload>('FILE_RANGE_FETCHING_STARTED');
export const FileRangeWasFetched = createActionCreator<FileRangeWasFetchedPayload>('FILE_RANGE_WAS_FETHCED');
export const FileRangeFetchFailed = createActionCreator<FileRangePayload>('FILE_RANGE_FETCH_FAILED');

export const JobChangesWasFetched = createActionCreator<{jobID: JobID, lastSerial: number}>('JOB_CHANGES_WAS_FETCHED');

export const AllJobFilesWasFetched = createActionCreator<FilesFetchedPayload>('ALL_JOB_FILES_WAS_FETCHED');

export const FileWasRemovedFromJob = createActionCreator<FileReference>('FILE_WAS_REMOVED_FROM_JOB');
export const FilesDeletionStarted = createActionCreator<MultiFileReference>('FILES_DELETION_STARTED');
export const FilesDeletionSucceeded = createActionCreator<MultiFileReference>('FILES_DELETION_SUCCEEDED');
export const FilesDeletionFailed = createActionCreator<MultiFileReference>('FILES_DELETION_FAILED');

export const FileWasRestored = createActionCreator<FileReference> ('FILE_WAS_RESTORED');
export const FileRestoreFailed = createActionCreator<FileReference>('FILE_RESTORE_FAILED');
export const FilesRestorationStarted = createActionCreator<MultiFileReference>('FILES_RESTORATION_STARTED');
export const FilesRestorationSucceeded = createActionCreator<MultiFileReference>('FILES_RESTORATION_SUCCEEDED');
export const FilesRestorationFailed = createActionCreator<MultiFileReference>('FILES_RESTORATION_FAILED');

export const FileWasSetAsCoverPhoto = createActionCreator<FileReference>('FILE_WAS_SET_AS_COVER_PHOTO');
export const SetFileAsCoverPhotoFailed = createActionCreator<JobID>('SET_FILE_AS_COVER_PHOTO_FAILED');
export const AlbumViewModeChanged = createActionCreator<{jobID: JobID, viewMode: AlbumViewMode}>('ALBUM_VIEWMODE_SET_TO_GRID');

export const JobInfoChangeDetected = createActionCreator<{jobID: JobID, eventID: number}>('JOB_INFO_CHANGE_DETECTED');

export const JobCopiedToTimeline = createVoidActionCreator('JOB_COPIED_TO_TIMELINE');
type CopyJobFailedReason = 'out_of_storage' | 'unknown';
export const JobCopiedToTimelineFailed = createActionCreator<{reason: CopyJobFailedReason}>('JOB_COPIED_TO_TIMELINE_FAILED');

type DownloadFilesPayload = {
    jobID: JobID,
    files: FileID[],
};

export const FilesDownloadSuccess = createActionCreator<DownloadFilesPayload>('FILES_DOWNLOAD_SUCCESS');
export const FilesDownloadFailed = createActionCreator<DownloadFilesPayload>('FILES_DOWNLOAD_FAILED');

export const ShareWasCreated = createActionCreator<JobID>('SHARE_WAS_CREATED');
export const ShareCreationFailed = createVoidActionCreator('SHARE_CREATION_FAILED');

export const JobWasPublished = createActionCreator<JobID>('JOB_WAS_PUBLISHED');
export const JobPublishingFailed = createActionCreator<JobID>('JOB_PUBLISHING_FAILED');
