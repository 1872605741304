import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {_} from '../../../common/assets';
import {fontSize} from '../../../common/assets/styleConstants';
import {CarouselViewerNode} from '../../../common/components/CarouselView';
import {BackArrowIcon} from '../../../common/components/Icons/BackArrowIcon';
import {VideoPlayStatus} from '../../../common/components/Video/AlbumVideo';
import {State} from '../../../common/state';
import {
    ChangedCurrentCarouselFile,
} from '../../../common/state/carouselViewer/actions';
import {getCurrentViewerNode} from '../../../common/state/carouselViewer/selectors';
import {BackBtnTV} from '../common/BackBtnTV';
import {CaptureTVButton} from '../common/CaptureTVButton';
import {CarouselViewControls} from './CarouselViewControls';
import {MediaElementTV} from './MediaElementTV';

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;

    position: relative;
    display:flex;
    justify-content: center;
    align-items: center;
`;

type StateProps = {
    currentViewerNode: CarouselViewerNode|undefined,
};

type DispatchProps = {
    onNavigateToFile: (jobID: JobID, fileID: FileID) => any,
};

type Props = StateProps & DispatchProps;
type ComponentState = {
    shouldSlideShowAutoPlay: boolean,
    showSlideShowControls: boolean,
};

class _CarouselViewTV extends React.Component<Props, ComponentState> {
    public state: ComponentState = {
        shouldSlideShowAutoPlay: false,
        showSlideShowControls: true,
    };
    private playSlideshowTimer: number = 0;
    private setSlideShowInterval = () => {
        if (this.playSlideshowTimer === 0) {
            this.playSlideshowTimer = window.setTimeout(this.navToNextFile, 5000);
        }
    }
    private displayControlsTimer: number = 0;
    private resetControlsTimer = () => {
        window.clearTimeout(this.displayControlsTimer);
        this.displayControlsTimer = window.setTimeout(
            () => this.setState((prevState) => ({...prevState, showSlideShowControls: false})),
            4000,
        );
    }
    private displaySlideShowControls = () => {
        if (!this.state.showSlideShowControls) {
            this.setState((prevState) => ({...prevState, showSlideShowControls: true}));
            this.resetControlsTimer();
        }
    }

    private startAutoPlaySlideShow = () => {
        this.setSlideShowInterval();
        this.resetControlsTimer();
        this.setState((prevState) => ({...prevState, shouldSlideShowAutoPlay: true}));
    }

    private handlePauseButtonClicked = () => {
        this.stopAutoPlaySlideShow();
        this.setState((prevState) => ({...prevState, shouldSlideShowAutoPlay: false, showSlideShowControls: true}));
    }

    private stopAutoPlaySlideShow = () => {
        this.resetAutoPlayTimer();
        clearTimeout(this.displayControlsTimer);
    }

    private resetAutoPlayTimer = () => {
        window.clearTimeout(this.playSlideshowTimer);
        this.playSlideshowTimer = 0;
    }
    private keypressListener = (event: KeyboardEvent) => {
        if (this.state.shouldSlideShowAutoPlay) {
            // keys to display slideshow controls when hidden
            switch (event.keyCode) {
                case 13: // Enter
                case 37: // ArrowLeft
                case 38: // ArrowUp
                case 39: // ArrowRight
                case 40: // ArrowDown
                    this.displaySlideShowControls();
                    return false;
                default:
                    return;
            }
        }
    }

    private handleVideoPlayStatusChanged = (newStatus: VideoPlayStatus) => {
        if (this.state.shouldSlideShowAutoPlay) {
            switch (newStatus) {
                case 'playing':
                    this.resetAutoPlayTimer();
                    break;
                case 'end':
                    this.navToNextFile();
                    break;
            }
        }
    }
    private navigateToFile = (fileID: FileID|null) => {
        if (fileID !== null && this.props.currentViewerNode) {
            this.props.onNavigateToFile(this.props.currentViewerNode.file.jobID, fileID);

            if (this.state.shouldSlideShowAutoPlay) {
                this.resetAutoPlayTimer();
                this.setSlideShowInterval();
            }
        }
    }
    private navToPrevFile = () => {
        if (this.props.currentViewerNode && this.props.currentViewerNode.prev) {
            this.navigateToFile(this.props.currentViewerNode.prev);
        }
    }
    private navToNextFile = () => {
        if (this.props.currentViewerNode && this.props.currentViewerNode.next) {
            this.navigateToFile(this.props.currentViewerNode.next);
        }
    }
    private onBackClick = () => {
        history.back();
    }

    public componentDidMount() {
        document.addEventListener('keyup', this.keypressListener);
    }
    public componentWillUnmount() {
        this.stopAutoPlaySlideShow();
        document.removeEventListener('keyup', this.keypressListener);
    }

    public render() {
        const node = this.props.currentViewerNode;
        if (node !== undefined) {
            return (
                <Wrapper>
                    <MediaElementTV
                        file={node.file}
                        autoPlay={this.state.shouldSlideShowAutoPlay}
                        onVideoPlayStatusChanged={this.handleVideoPlayStatusChanged}
                    />
                    <BackBtnTV onClick={this.onBackClick}>
                        <BackArrowIcon size={24} color={'white'}/>
                    </BackBtnTV>
                    <CarouselViewControls
                        displayButtons={this.state.showSlideShowControls}
                        autoPlay={this.state.shouldSlideShowAutoPlay}
                        onPlay={this.startAutoPlaySlideShow}
                        onPause={this.handlePauseButtonClicked}
                        onPrev={node.prev ? this.navToPrevFile : undefined}
                        onNext={node.next ? this.navToNextFile : undefined}
                    />
                </Wrapper>
            );
        }
        return null;
    }
}

const mapStateToProps = (state: State): StateProps => ({
    currentViewerNode: getCurrentViewerNode(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onNavigateToFile: (jobID: JobID, fileID: FileID) => dispatch(ChangedCurrentCarouselFile({jobID, fileID})),
});

export const CarouselViewTV = connect(mapStateToProps, mapDispatchToProps)(_CarouselViewTV);
