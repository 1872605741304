import {Action, isType} from '../common/actions';
import {AlbumOptionsOverlayClosed, AlbumOptionsOverlayTriggered} from './actions';

type GeneralOption<T> = {type: T};
type AlbumOption<T> = GeneralOption<T> & {albumID: JobID};
type AlbumFileOption<T> = AlbumOption<T> & {fileID: FileID};

export type AlbumOptions =
    GeneralOption<'albums_filter'>
    | GeneralOption<'create_album'>
    | AlbumOption<'share'>
    | AlbumOption<'share_prompt'>
    | AlbumOption<'settings'>
    | AlbumOption<'participantsList'>
    | AlbumFileOption<'loveList'>
    | AlbumOption<'album_files_sort'>
    ;

export type AlbumOptionsState = {
    options?: AlbumOptions,
};

const initialState: AlbumOptionsState = {
    options: undefined,
};

export const albumOptionsReducer = (
    state: AlbumOptionsState = initialState,
    action: Action<any>,
): AlbumOptionsState => {

    if (isType(action, AlbumOptionsOverlayTriggered)) {
        return {
            ...state,
            options: action.payload,
        };
    }
    if (isType(action, AlbumOptionsOverlayClosed)) {
        return {
            ...state,
            options: undefined,
        };
    }

    return state;
};
