
import {getJSON, HostUrl, postJSON, putJSON} from '../../../common/API/toolbox';
import {PrintOrderStatus} from '../../../common/state/printOrder/reducer';
import {PrintArea} from '../../../common/state/printOrderItem/reducer';
import {PrintProduct} from '../../constants/productInfo';

type PrintJobInfo = {
    ctime: number,
    mtime: 0,
    name: string,
    type: string,
    uuid: string,
};

type OrderConfirmationOptions = {
    amount?: number,
    host: string,
};

type ValidateAuthTokenResponse = {
    current_time?: number,
    message: string,
    valid_to_epoch?: number,
};

type PostManifestResponse = {
    manifest_status: 'VALID' | 'NOT VALID',
    checksum: string,
};
export type ContactInfoValidityErrors = {
    email: string,
    zip_code: string,
    city: string,
    contact_information: string; // structure is incorrect (not likely)
};
type ContactInfoResponse<T> = {contact_information_status: T};
type ContactInfoSuccess = ContactInfoResponse<'VALID'>;
type ContactInfoFailure = ContactInfoResponse<'NOT VALID'> & {contact_info_validity_errors: ContactInfoValidityErrors};

export type PostContactInfoResponse = ContactInfoSuccess | ContactInfoFailure;

export type OrderManifest = {
    version: number,
    order_items: OrderItem[],
};

type ConfirmOrderResponse = {
    message: string,
    url: string,
    order_uuid: string,
};

export type ContactInfo = {
    address: {
        forename: string,
        surname: string,
        street: string,
        zip_code: string,
        city: string,
        email: string,
    },
    payment_details: {
        vipps_msisdn: string,
    },
};
export type OrderItem = {
    quantity: number,
    product_type: PrintProduct,
    order_files: OrderFile[],
};

export type OrderFile = {
    file_uuid: FileID,
    quantity: number,
    print_area: PrintArea,
    original_image_size: {width: number, height: number},
};

export type TempPrintUser = {
    auth_token: string,
    valid_to_epoch: number,  // in seconds
    job_info: PrintJobInfo,
    service: DictionaryOf<string>,
    user_uuid: string,
    order_uuid: string,
};

type FeatureFlagResponse = {flags: DictionaryOf<{version: number}>};

export class PrintAppService {
    private hostUrl: HostUrl;

    constructor(hostname: string, authToken: string) {
        const commonQueryParams: DictionaryOf<string> = {
            key: __API_KEY__,
            auth: authToken,
        };
        this.hostUrl = new HostUrl(hostname, commonQueryParams);
    }
    public getTempPrintUserData = () => {
        return postJSON<TempPrintUser>(this.hostUrl.getPath('/st/4/prints/create_user'));
    }

    public postOrderManifest = (orderID: string, orderManifest: OrderManifest, checksum?: string) => {
        return postJSON<PostManifestResponse>(
            this.hostUrl.getPath(`/st/4/prints/order/${orderID}/manifest`, {checksum}),
            JSON.stringify(orderManifest),
        );
    }

    public postContactInformation = (orderID: string, contactInfo: ContactInfo) => {
        return postJSON<PostContactInfoResponse>(this.hostUrl.getPath(`/st/4/prints/order/${orderID}/contact_information`),
            JSON.stringify({contact_info: contactInfo}),
        );
    }

    public postOrderConfirmation = (orderID: string, opts: OrderConfirmationOptions) => {
        return postJSON<ConfirmOrderResponse>(this.hostUrl.getPath(`/st/4/prints/order/${orderID}`, opts));
    }

    public createOrder = () => {
        return putJSON<{order_uuid: string}>(this.hostUrl.getPath(`/st/4/prints/order`));
    }
    public getOrderStatus = (orderID: string) => {
        return getJSON<PrintOrderStatus>(this.hostUrl.getPath(`/st/4/prints/order/${orderID}/status`));
    }
    public getOrderInfo = (orderID: string) => {
        return getJSON<OrderManifest>(this.hostUrl.getPath(`/st/4/prints/order/${orderID}/manifest`));
    }

    public getFeatureFlags = () => {
        return getJSON<FeatureFlagResponse>(this.hostUrl.getPath(`/st/4/feature_flags`, {client_v: __VERSION__}));
    }
    public validateAuthToken = () => {
        return getJSON<ValidateAuthTokenResponse>(this.hostUrl.getPath('/st/4/verify_auth'));
    }
}
