import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {isEmail} from '../../utilities/inputValidation';
import {Button} from '../Common/Button';
import {OptionsOverlay} from '../Common/Overlay';
import {RippleLoaderOverlay} from '../Common/RippleLoaderOverlay';
import {TextInputFormRow} from '../Common/TextInputFormRow';
import {CheckIcon} from '../Icons/CheckIcon';
import {PersonIcon} from '../Icons/PersonIcon';

const Wrapper = styled.div`
    margin-top: 28px;
    width: ${(props: {isMobile: boolean}) => props.isMobile ? '100%' : '400px'};
    position: relative;
`;

export type EmailSharingOptions = {
    recipients: string,
    subject: string,
    message: string,
};

type Props = {
    isMobile: boolean;
    isPending: boolean;
    onCancel: () => any;
    onConfirm: (options: EmailSharingOptions) => any;
};

type FormField = {
    value: string,
    isValid?: boolean,
};
type State = Record<keyof EmailSharingOptions, FormField>;

export class ShareToEmailForm extends React.Component<Props, State> {
    public state: State = {
        recipients: {value: ''},
        subject: {value: ''},
        message: {value: '', isValid: true}, // optional field
    };

    private validateAllFormFields = (): boolean => {
        const isValidRecipients = this.isRecipientValid(this.state.recipients.value);
        const isValidSubject = this.isSubjectValid(this.state.subject.value);

        this.setState({
            ...this.state,
            recipients: {
                ...this.state.recipients,
                isValid: isValidRecipients,
            },
            subject: {
                ...this.state.subject,
                isValid: isValidSubject,
            },
        });

        return isValidRecipients && isValidSubject;

    }

    private isRecipientValid = (value: string): boolean => {
        return value.split(',').every(isEmail);
    }

    private isSubjectValid = (value: string): boolean => {
        return value !== '';
    }

    private isAllFieldsValid = (): boolean => {
        return Object.keys(this.state).every(
            (k) => this.state[k as keyof EmailSharingOptions].isValid === true,
        );
    }

    private handleRecipientsChanged = (newValue: string) => {
        this.setState({
            ...this.state,
            recipients: {
                value: newValue,
                isValid: this.isRecipientValid(newValue),
            },
        });
    }

    private handleSubjectChanged = (newValue: string) => {
        this.setState({
            ...this.state,
            subject: {
                value: newValue,
                isValid: this.isSubjectValid(newValue),
            },
        });
    }

    private handleMessageChanged = (newValue: string) => {
        this.setState({
            ...this.state,
            message: {
                value: newValue,
                isValid: true,
            },
        });
    }

    private handleEmailOptionsConfirmed = () => {
        if (!this.props.isPending && this.validateAllFormFields()) {
            this.props.onConfirm({
                recipients: this.state.recipients.value,
                subject: this.state.subject.value,
                message: this.state.message.value,
            });
        }
    }

    public render() {
        const confirmButton = Button(
            _('done'),
            this.handleEmailOptionsConfirmed,
            {
                icon: CheckIcon,
                isActive: !this.props.isPending && this.isAllFieldsValid(),
            },
        );
        return (
            <OptionsOverlay
                onClose={this.props.onCancel}
                cancelButton={Button(_('cancel'), this.props.onCancel)}
                confirmButton={confirmButton}
                isMobile={this.props.isMobile}
            >
                <Wrapper isMobile={this.props.isMobile}>
                    {this.props.isPending && <RippleLoaderOverlay />}
                    <TextInputFormRow
                        title={_('send_email_to')}
                        isInvalid={this.state.recipients.isValid === false}
                        value={this.state.recipients.value}
                        placeholder={_('enter_receivers_address')}
                        icon={<PersonIcon size={16} color={colors.captureGrey800}/>}
                        onChange={this.handleRecipientsChanged}
                    />
                    <TextInputFormRow
                        title={_('email_subject')}
                        isInvalid={this.state.subject.isValid === false}
                        value={this.state.subject.value}
                        placeholder={_('enter_subject')}
                        onChange={this.handleSubjectChanged}
                    />
                    <TextInputFormRow
                        title={_('email_message')}
                        value={this.state.message.value}
                        placeholder={_('enter_message')}
                        multiLine={true}
                        onChange={this.handleMessageChanged}
                    />
                </Wrapper>
            </OptionsOverlay>
        );
    }
}
