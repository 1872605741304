import styled from 'styled-components';
import {GridElementProps} from '../../utilities/gridElementSizeCalculator';

export const GridElement = styled.div.attrs({
    tabIndex: 0,
})`
    width:  ${(props: GridElementProps) => props.elementWidth}px;
    height: ${(props) => props.elementHeight}px;
    margin: ${(props) => props.elementSpaceAround}px;
    background-size: cover;
    background-position: center;
`;

const IconContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 25%;
    padding: 8px;
    box-sizing: border-box;
    color: #FFF;
`;
export const GridElementIconContainerTop = styled(IconContainer)`
    top: 0;
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0));
    text-align: right;
`;

export const GridElementIconContainerBottom = styled(IconContainer)`
    bottom: 0;
    background: linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0));

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
