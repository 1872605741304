import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets/styleConstants';
import {ThumbFile} from '../../state/carouselViewer/selectors';

type ThumbListWrapperProps = {
    width: number;
    spaceAround: number,
};

const ThumbListWrapper = styled.div`
    width: ${(props: ThumbListWrapperProps) => props.width}px;
    height: 100%;
    display: flex;

    padding: 0 ${(props) => props.spaceAround}px;

`;

type ThumbElementProps = {
    size: number,
    spaceBetween: number,
    selected: boolean,
};

const ThumbElement = styled.div`
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    overflow: hidden;

    width: ${(props: ThumbElementProps) => props.size}px;
    height: ${(props) => props.size}px;
    margin: 8px ${(props) => props.spaceBetween / 2}px;

    /* hide scrollbar */
    margin-bottom: 24px;

    cursor:pointer;
    background-color: ${colors.captureGrey100};
    background-size: cover;
    ${(props) => props.selected ? `border: 2px solid white` : ``};
    ${(props) => !props.selected ? `opacity: 0.7` : ``};
`;

type ThumbListProps = {
    width: number,
    spaceAround: number,
    loadThumbStart: number,
    loadThumbEnd: number,
    thumbStyle: {
        size: number,
        spaceBetween: number,
    },
    thumbFiles: ThumbFile[],
    selectedIndex: number,
    onClickThumb: (fileID: FileID) => any,
};

export class SlideBarThumbList extends React.Component<ThumbListProps, {}> {
    private clickedThumbHandlers: DictionaryOf<() => any> = {};

    private buildThumbClickHandlers = (
        files: ThumbFile[],
        onClickThumb: ThumbListProps['onClickThumb'],
    ) => {
        this.clickedThumbHandlers = {};
        files.forEach((file) => {
            this.clickedThumbHandlers[file.fileID] = () => {
                onClickThumb(file.fileID);
            };
        });
    }

    constructor(props: ThumbListProps) {
        super(props);
        this.buildThumbClickHandlers(props.thumbFiles, props.onClickThumb);
    }

    public componentWillReceiveProps(nextProps: ThumbListProps) {
        if (this.props.thumbFiles !== nextProps.thumbFiles
            || this.props.onClickThumb !== nextProps.onClickThumb) {
            this.buildThumbClickHandlers(nextProps.thumbFiles, nextProps.onClickThumb);
        }
    }

    public render() {
        const thumbElements = this.props.thumbFiles.map((file, index) => {
            const showImage = index >= this.props.loadThumbStart && index <= this.props.loadThumbEnd;

            return (
                <ThumbElement
                    style={{backgroundImage: showImage ? `url('${file.url}')` : ''}}
                    {...this.props.thumbStyle}
                    key={file.fileID}
                    selected={index === this.props.selectedIndex}
                    onClick={this.clickedThumbHandlers[file.fileID]}
                />
            );
        });

        return (
            <ThumbListWrapper
                width={this.props.width}
                spaceAround={this.props.spaceAround}
            >
                {thumbElements}
            </ThumbListWrapper>
        );
    }
}
