import * as React from 'react';
import styled from 'styled-components';
import {FileTarget, getFileTargetFromName} from '../../utilities/fileTarget';
import {DocIcon} from '../Icons/DocIcon';

const ThumbWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const IconWrapper =  styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const Image = styled.div`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
`;
const Video = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

type Props = {
    fileName: string,
    thumbURL?: string,
};

export class Thumb extends React.PureComponent<Props> {

    public render() {
        let content: React.ReactNode;
        const fileTarget = getFileTargetFromName(this.props.fileName);
        if (fileTarget === FileTarget.Pictures) {
            content = <Image style={{backgroundImage: `url(${this.props.thumbURL})`}} />;
        }
        else if (fileTarget === FileTarget.Movies) {
            content = <Video src={this.props.thumbURL} />;
        }
        else {
            content = <IconWrapper><DocIcon size={40}/></IconWrapper>;
        }

        return <ThumbWrapper>{content}</ThumbWrapper>;
    }

}
