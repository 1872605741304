import {Reactions} from '../../state/reaction/actions';
import {UploadError, UploadFailedReason, UploadResponse} from '../../state/uploader/uploadQueue';
import {isMobileDevice} from '../../utilities/device';
import {CommentService} from '../capabilities/index';
import {
    Delete, deleteJSON,
    downloadIframeGETRequest, downloadIframePOSTRequest,
    get, getJSON, HostUrl, post, postJSON, put, putJSON,
} from '../toolbox';

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/default/get_st_4_account_info
export type AccountInfo = {
    files_disabled: boolean
    kissmetrics_id: string
    logged_in_as: string
    max_space: string
    name: string
    used_space: string
    username: string
    uuid: UserID,
    shutdown_info?: {}, // Present for users who will be shut down.
};

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/default/get_st_4_devices
type DeviceInfo = {
    id: string,
    name: string,
    expires: number,
};
export type DeviceResponse = {
    current: string,
    devices: DeviceInfo[],
};

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/default/post_st_4_jobs
type CreateJobProperties = {
    name: string,
    type?: 'story',
    password?: string,
    message?: string,
    public?: number,
    mtime?: number,
    allow_comments?: number,
    allow_uploads?: number,
    allow_reactions?: number,
    allow_sharing?: number,
    allow_anonymous_access?: number,
    allow_discovery?: number,
};

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/default/get_st_4_jobs_default
type DefaultJobInfo = {
    id: string,
    name: string,
    mtime: number,
};

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/default/get_st_4_jobs_job_uuid_timeline_months
export type TimelineMonth = {
    month: number,
    year: number,
    count: number,
} & Partial<{  // New props from apr2018 - expected included on all servers soon, but make Partial to force handling
    document_count: number,
    other_count: number,
    picture_count: number,
    screenshot_count: number,
    video_count: number,
}>;

type UserInfo = {
    uuid: UserID,
    name: string,
    email: string,
    profile_picture_url?: string,
};
// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/default/get_st_4_jobs_job_uuid_info
export type JobInfoResponse = {
    id: JobID,
    ctime?: number, // Timeline and some old jobs comes without ctime
    mtime: number, // mtime is required for useful jobs
    name: string
    owner: UserInfo,
    // These are not present for timeline job
    type?: string,
    last_update?: number,
    password_protected?: boolean,
    message?: string,
    permissions?: {
        allow_comments?: number,
        allow_uploads?: number,
    },
    privacy_mode?: PrivacyMode,
};

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/default/get_st_4_jobs and observed
// API-behaviour
type BaseJobsResponse = {
    id: string,
    name: string,
    ctime: number,
    mtime: number,
    last_update: number,
};
export type TimelineJobsResponse = BaseJobsResponse & {
    type: undefined,
};

export type AlbumJobsResponse = BaseJobsResponse & {
    cover_id: string,
    last_user_update: number,
    participant_count: number,
    password_protected: boolean,
    permissions: number,
    privacy_mode: PrivacyMode,
    service: {
        'app-host': string,
        'thumb-host': string,
        'video-host': string,
    },
    type: 'story',

    owner: UserInfo,

    // include_detail fields
    comment_count: number,
    media_count: number,
    reaction_count: number,
};
export type JobsResponse = TimelineJobsResponse | AlbumJobsResponse;

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/default/get_st_4_jobs_job_uuid_changes
// and observed API-behaviour (as some of the elements are only present for some change-types)
export type JobChange = {
    id: string,
    path: string,
    size: number,
    mtime: number,
    ctime: number,
    timestamp: number,
    checksum: string,
    serial: number,
    type: number,
    duration?: number, // included for newly uploaded video
    comment_uuid?: string,
    user_uuid?: string, // Not included when the change is caused by the logged in user.
    comment?: string,

    // newly included job changes response properties
    reaction?: Reaction,
    width?: number,
    height?: number,
    aspect_ratio?: string,
};

export type FileGroupType = 'live' | 'burst';
export type JobFile = {
    id: string,
    path: string,
    size: number,
    mtime: number,
    ctime: number,
    checksum: string,
    duration?: number, // included for newly uploaded video
    width?: number,
    height?: number,
    aspect_ratio?: string,

    // file groups related properties
    group_type?: FileGroupType,
    group_id?: string,
    master?: '1',
};
export type JobFileResponse = {
    lastEventSerial: number,
    files: JobFile[],
};

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/default/get_st_4_jobs_job_uuid_files
export type JobFilesRequestOptions = {
    path?: string,
    start?: string,
    end?: string,
    dirs_only?: 1|0,
    recursive?: 1|0,
};

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/default/get_st_4_trash_can
export type DeletedFile = {
    dtime: number,
    id: string,
    job: string,
    mtime: number,
    path: string,
    size: number,
};
export type DeletedFileResponse = {
    deletedFiles: DeletedFile[],
    limit: number,
    resultCount: number,
};
export type DeletedFileRequestOptions = {
    offset?: number,
    limit?: number,
};

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/metadata/get_st_4_jobs_job_uuid_metadata_file_uuid
// and observed API behaviour
export type FileMetadataResponse = {
    Make?: string,
    Model?: string,
    ApertureValue?: string,
    ExposureTime?: string,
    FocalLength?: string,
    ISOSpeedRatings?: string,
    'Capture.OrigHeight'?: number,
    'Capture.OrigWidth'?: number,
    'Capture.Parser'?: string,
    'Capture.GpsPosition'?: number[],
};

export type ContributingUsersResponse = {
    last_user_update: number,
    users: {
        [key: string]: {
            name: string,
            self?: boolean,
            profile_picture?: number, // boolean?
            profile_picture_url?: string,
            subscribed?: boolean,
        },
    },
};

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/default/post_st_4_stories_users
export type CreatedStoryUser = {
    result: 'OK'
    name: string
    auth: string
    uuid: UserID,
};

type CommentResponse = {
    comment_uuid: string,
    result: string,
};

export type Reaction = '' | 'love';

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/story/post_st_4_jobs_job_uuid_files_by_id_file_uuid_reaction
type ReactionResponse = {
    serial: string,
    message: string,
    result: string,
};

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/story/get_st_4_jobs_job_uuid_permissions
type Permissions = {
    allow_comments: number,
    allow_uploads: number,
    allow_reactions: number,
    allow_sharing: number,
    allow_anonymous_access: number,
    allow_discovery: number,
};

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/story/post_st_4_jobs_job_uuid_permissions
type PermissionsResponse = {
    result: string,
    permissions: Permissions,
};

type PrivacyMode = 'private'
    | 'protected'
    | 'shared' // default
    | 'public'
    ;

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/story/post_st_4_jobs_job_uuid_privacy_mode
type PrivacyModeResponse = PermissionsResponse;

// Based on https://see-jenkinsslave.univex.no/swagger-ui/dist/index.html#!/default/post_st_4_jobs_job_uuid_publish
type PublishJobOptions = {
    to_email: string,
    to_self?: '1' | '0',
    message?: string,
};

type PublishJobResponse = {
    url: string,
};

type OtherPaymentSource
    = 'capture' // free storage
    | 'apple store'
    | 'android store'
    | 'connect store'
    | 'b2b'
    | 'other'
    | 'customer service';

type UserGrantBase<T extends string> = {
    source: T;
    size: number;
    used: number;
};

type StripeGrantLinks = {
    cancellation?: string,
    reactivation?: string,
};

type StripeGrantExtras = {
    stripe_product_id: string;
    stripe_order_id: string;
    period: 'yearly' | 'monthly';
    renews_at?: number,
    cancelled_at?: number,
    expires_at?: number,
    _links: StripeGrantLinks,
};

export type StripeUserGrant = UserGrantBase<'capture stripe'> & StripeGrantExtras;

export type UserGrant = StripeUserGrant | UserGrantBase<OtherPaymentSource> ;

type UserGrantsResponse = {
    result: {grants: UserGrant[]};
};

export type StripeProduct = {
    id: string,
    currency: string,
    period: 'monthly' | 'yearly',
    price: number,
    size: number, // in GB
};

export type StripePaymentInfoResponse = {
    card: string, // card id
    email?: string,
    exp_month: number,
    exp_year: number,
};

export type TakeoutFile = {
    checksum: string,
    url: string,
    name: string,
    size: number,
};
type TakeoutStatusResponseCommon<TStatus extends 'processing'|'done'|'failed'> = {
    status: TStatus,
    contact_info: string, // The email-address provided when takeout was ordered
};
type TakeoutStatusDone = {
    file_links: TakeoutFile[],
    expiration: {
        request: number, // timestamp for when another request can be made
        archive: number, // number timestamp for when the fileLinks expires
    },
};
export type TakeoutStatusResponse
    = TakeoutStatusResponseCommon<'processing'>
    | TakeoutStatusResponseCommon<'done'> & TakeoutStatusDone
    | TakeoutStatusResponseCommon<'failed'> & {details: string}
    ;

type DedupPolicy = 'allow_duplicates' | 'no_duplicates' | 'once_only';

type DedupResponse = {
    result: string,
    status: string,
    uuid: string,
};

export class AppService implements CommentService {
    private hostUrl: HostUrl;

    constructor(
        hostname: string,
        authToken: string,
        foreignAuth?: string,
    ) {
        const commonQueryParams: DictionaryOf<string> = {
            key: __API_KEY__,
            auth: authToken,
            client_v: __VERSION__,
        };
        if (foreignAuth) {
            commonQueryParams['foreign-auth'] = foreignAuth;
        }

        this.hostUrl = new HostUrl(hostname, commonQueryParams);
    }

    public getAccountInfo(): Promise<AccountInfo> {
        const params = {
            wantName: '0',
        };
        return getJSON<AccountInfo>(this.hostUrl.getPath('/st/4/account_info', params));
    }

    public setProfileName(name: string): Promise<Response> {
        return post(this.hostUrl.getPath(`/st/4/name`, {name}));
    }

    public updatePushToken(push_token: string): Promise<Response> {
        return post(this.hostUrl.getPath(`/st/4/update_push_token`, {push_token}));
    }

    public getUserOption(optionKey: string) {
        return get(this.hostUrl.getPath(`/st/4/options/${optionKey}`)).then((r) => r.text());
    }

    public setUserOption(optionKey: string, value: string) {
        return put(this.hostUrl.getPath(`/st/4/options/${optionKey}`), value);
    }

    public getConnectedDevices(): Promise<DeviceResponse> {
        return getJSON<DeviceResponse>(this.hostUrl.getPath('/st/4/devices'));
    }
    public deleteConnectedDevice(deviceID: string): Promise<Response> {
        return post(this.hostUrl.getPath(`/st/4/devices/${deviceID}/delete`));
    }

    public logout(): Promise<Response> {
        return post(this.hostUrl.getPath('/st/4/logout'));
    }
    public getJobList(): Promise<JobsResponse[]> {
        const params = {
            stories: '1',
            include_details: '1',
        };
        return getJSON<JobsResponse[]>(this.hostUrl.getPath('/st/4/jobs', params));
    }

    public async getDefaultJob(): Promise<DefaultJobInfo & {lastEventSerial: number}> {
        const resp = await get(this.hostUrl.getPath('/st/4/jobs/default'));
        const info: DefaultJobInfo = await resp.json();
        const lastEventSerial = parseInt(resp.headers.get('x-last-event-serial') || '-1', 10);
        return {...info, lastEventSerial};
    }

    public getTimelineMonths(jobID: JobID): Promise<{months: TimelineMonth[]}> {
        return getJSON<{months: TimelineMonth[]}>(this.hostUrl.getPath(`/st/4/jobs/${jobID}/timeline/months`));
    }

    public getJobInfo(jobID: JobID): Promise<JobInfoResponse> {
        const params = {
            include_details: '1',
        };
        return getJSON<JobInfoResponse>(this.hostUrl.getPath(`/st/4/jobs/${jobID}/info`, params));
    }

    public getJobChanges(jobID: JobID, since= 0): Promise<JobChange[]> {
        return getJSON<JobChange[]>(this.hostUrl.getPath(`/st/4/jobs/${jobID}/changes`, {
            serial: 1,
            since,
        }));
    }
    public async getLastJobChangeID(jobID: JobID): Promise<number> {
        const resp = await get(this.hostUrl.getPath(`/st/4/jobs/${jobID}/changes`, {
            since: Number.MAX_SAFE_INTEGER,
        }));
        return parseInt(resp.headers.get('x-last-event-serial') || '-1', 10);
    }

    public getJobContributors(jobID: JobID, includeProfilePicture: boolean= false): Promise<ContributingUsersResponse> {
        return getJSON<ContributingUsersResponse>(this.hostUrl.getPath('/st/4/jobs/' + jobID + '/users', {
            include_profile_url: includeProfilePicture ? 1 : 0,
        }));
    }

    public async createJob(opts: CreateJobProperties): Promise<DefaultJobInfo> {
        return postJSON<DefaultJobInfo>(this.hostUrl.getPath('/st/4/jobs', opts));
    }

    public publishJob(jobID: JobID, opts: PublishJobOptions): Promise<PublishJobResponse> {
        return postJSON<PublishJobResponse>(this.hostUrl.getPath(`/st/4/jobs/${jobID}/publish`, opts));
    }

    public async deleteJob(jobID: JobID): Promise<void> {
        await Delete(this.hostUrl.getPath('/st/4/jobs/' + jobID));
    }

    public createStoryUser(name: string): Promise<CreatedStoryUser> {
        return postJSON<CreatedStoryUser>(this.hostUrl.getPath('/st/4/stories/users', {name}));
    }

    public async getFiles(jobID: string, options: JobFilesRequestOptions): Promise<JobFileResponse> {
        const resp = await get(this.hostUrl.getPath(`/st/4/jobs/${jobID}/files`, options));
        const lastEventSerial = parseInt(resp.headers.get('x-last-event-serial') || '-1', 10);
        const files = await resp.json() as JobFile[];
        return {lastEventSerial, files};
    }

    public async getDeletedFiles(options: DeletedFileRequestOptions): Promise<DeletedFileResponse> {
        const resp = await get(this.hostUrl.getPath('/st/4/trash_can', options));
        const deletedFiles = await resp.json() as DeletedFile[];
        const limit = parseInt(resp.headers.get('x-limit') || '-1', 10);
        const resultCount = parseInt(resp.headers.get('x-result-count') || '-1', 10);
        return {deletedFiles, limit, resultCount};
    }

    public getFileMetadata(jobID: JobID, fileID: FileID): Promise<FileMetadataResponse> {
        return getJSON<FileMetadataResponse>(this.hostUrl.getPath(`/st/4/jobs/${jobID}/metadata/${fileID}`));
    }

    private static getUUIDFromTextResponse(str: string): string {
        // Response-format of a successful upload/dedup: "OK [FileID] [UsedSpace] [timestamp]"
        const parts = str.split(' ');
        if (parts[0] !== 'OK') {
            throw new UploadError(UploadFailedReason.FileError, 'File rejected by server');
        }
        return parts[1];
    }

    public async dedupFile(targetJob: JobID, opts: Partial<JobFile & {policy: DedupPolicy}>): Promise<string> {
        const resp = await post(this.hostUrl.getPath('/st/4/jobs/' + targetJob + '/files_dedup', opts));
        const contentType = resp.headers.get('content-type');

        if (contentType && resp.status === 201) {
            if (contentType.indexOf('application/json') !== -1) {
                const value: DedupResponse = await resp.json();
                return value.uuid;
            }
            else if (contentType.indexOf('text/html') !== -1) {
                return AppService.getUUIDFromTextResponse(await resp.text());
            }
        }

        throw new UploadError(UploadFailedReason.FileError, 'File rejected by server');
    }

    public async copyFilesToDefaultJob(sourceJobID: JobID, fileIDs: FileID[]): Promise<any> {
        return postJSON(this.hostUrl.getPath(`/st/4/jobs/${sourceJobID}/keep-files`), fileIDs.join('\n'));
    }

    public async copyJobToDefaultJob(sourceJobID: JobID): Promise<any> {
        return post(this.hostUrl.getPath(`/st/4/jobs/${sourceJobID}/keep-all-files`));
    }

    public uploadFile(
        jobID: string,
        path: string,
        mtime: number,
        file: File,
        opt_request?: XMLHttpRequest,
    ): Promise<UploadResponse> {
        const params = {path, mtime, policy: 'no_duplicates', want_dimensions: '1'};
        const url = this.hostUrl.getPath(`/st/4/jobs/${jobID}/files`, params);

        const payload = new FormData();
        payload.append('file', file);

        const reqPromise = new Promise<XMLHttpRequest>((success, error) => {
            const request = opt_request || new XMLHttpRequest();
            request.addEventListener('load', () => success(request));
            request.addEventListener('error', error);
            request.addEventListener('abort', error);

            request.open('POST', url);
            request.send(payload);
        });

        return reqPromise
            .then((request: XMLHttpRequest): UploadResponse => {
                    if (request.status === 413 /* Request Entity Too Large => Out of storage */) {
                        throw new UploadError(UploadFailedReason.OutOfStorage, 'Out of storage');
                    }

                    return JSON.parse(request.responseText);
                },
                () => {throw new UploadError(UploadFailedReason.NetworkError, 'Network error'); },
            );
    }

    public deleteFile(jobID: JobID, fileID: FileID): Promise<any> {
        return post(this.hostUrl.getPath(`/st/4/jobs/${jobID}/files_by_id/${fileID}/delete`));
    }

    public emptyTrashCan(fileid: FileID): Promise<any> {
        return postJSON(this.hostUrl.getPath(`/st/4/empty_trash_can`, {fileid}));
    }

    public restoreFile(jobID: JobID, fileID: FileID): Promise<any> {
        return post(this.hostUrl.getPath(`/st/4/jobs/${jobID}/rollback`, {id: fileID}));
    }

    public addComment(jobID: JobID, fileID: string, comment: string): Promise<CommentResponse> {
        return postJSON<CommentResponse>(this.hostUrl.getPath(`/st/4/jobs/${jobID}/files_by_id/${fileID}/comments`), comment);
    }

    public deleteComment(jobID: JobID, fileID: string, commentID: CommentID): Promise<CommentResponse> {
        return deleteJSON<CommentResponse>(this.hostUrl.getPath(`/st/4/jobs/${jobID}/files_by_id/${fileID}/comments/${commentID}`));
    }

    public editComment(jobID: JobID, fileID: string, commentID: CommentID, commentText: string): Promise<CommentResponse> {
        return putJSON<CommentResponse>(this.hostUrl.getPath(`/st/4/jobs/${jobID}/files_by_id/${fileID}/comments/${commentID}`), commentText);
    }

    public subscribeToJob(jobID: JobID): Promise<Response> {
        return post(this.hostUrl.getPath(`/st/4/jobs/${jobID}/subscribe`));
    }

    public unsubscribeFromJob(jobID: JobID): Promise<Response> {
        return post(this.hostUrl.getPath(`/st/4/jobs/${jobID}/unsubscribe`));
    }

    public downloadAllFilesInJob(jobID: JobID): Promise<any> {
        // When no files are provided, the backend returns all files in job
        return this.downloadFilesAsArchiveFromJob(jobID, []);
    }

    public async downloadSingleFileFromJob(jobID: JobID, fileID: FileID): Promise<any> {
        if (isMobileDevice.iOS()) {
            window.location.href = this.hostUrl.getPath(`/st/4/jobs/${jobID}/files_by_id/${fileID}`);
            return;
        }
        await downloadIframeGETRequest(
            this.hostUrl.getPath(`/st/4/jobs/${jobID}/files_by_id/${fileID}`),
        );
    }

    public downloadFilesAsArchiveFromJob(jobID: JobID, files: FileID[]): Promise<any> {
        return downloadIframePOSTRequest(
            this.hostUrl.getPath(`/st/4/jobs/${jobID}/files_as_archive`),
            files,
        );
    }

    public setJobName(jobID: JobID, name: string): Promise<Response> {
        return post(this.hostUrl.getPath(`/st/4/jobs/${jobID}/name`, {name}));
    }

    public setCoverPhoto(jobID: JobID, fileID: FileID): Promise<Response> {
        return post(this.hostUrl.getPath(`/st/4/jobs/${jobID}/cover`, {id: fileID}));
    }

    public setPermissionsforJob(jobID: JobID, permissions: Partial<Permissions>): Promise<PermissionsResponse> {
        return postJSON<PermissionsResponse>(this.hostUrl.getPath(`/st/4/jobs/${jobID}/permissions`, permissions));
    }

    public setPrivacyModeForJob(jobID: JobID, mode: PrivacyMode): Promise<PrivacyModeResponse> {
        return postJSON<PrivacyModeResponse>(this.hostUrl.getPath(`/st/4/jobs/${jobID}/privacy_mode`, {mode}));
    }

    public loveFile(jobID: JobID, fileID: FileID): Promise<ReactionResponse> {
        return postJSON<ReactionResponse>(
            this.hostUrl.getPath(`/st/4/jobs/${jobID}/files_by_id/${fileID}/reaction`),
            Reactions.Love,
        );
    }

    public unLoveFile(jobID: JobID, fileID: FileID): Promise<ReactionResponse> {
        return deleteJSON<ReactionResponse>(this.hostUrl.getPath(`/st/4/jobs/${jobID}/files_by_id/${fileID}/reaction`));
    }

    public createTakeout(email: string): Promise<Response> {
        return post(this.hostUrl.getPath('/st/4/takeout', {contact_info: email}));
    }
    public getTakeoutStatus(): Promise<TakeoutStatusResponse> {
        // Returns 404 if never requested
        return getJSON(this.hostUrl.getPath('/st/4/takeout'));
    }
    public resetTakeout(): Promise<Response> {
        return post(this.hostUrl.getPath('/st/4/reset_takeout'));
    }

    public getStripeProducts(mode: string /* 'test'|'production'*/): Promise<{result: StripeProduct[]}> {
        return getJSON(this.hostUrl.getPath('/st/4/stripe_products', {[mode]: 1}));
    }
    public stripePurchase(plan: string, token: string, card: string) {
        return post(this.hostUrl.getPath('/st/4/stripe_purchase', {plan, token, card}));
    }

    public postStripePaymentMethod(token: string, card: string) {
        return post(this.hostUrl.getPath('/st/4/stripe_payment_method', {token, card}));
    }

    public getStripePaymentMethodInfo() {
        return getJSON<StripePaymentInfoResponse>(this.hostUrl.getPath('/st/4/stripe_payment_method'));
    }

    public getUserGrants() {
        return getJSON<UserGrantsResponse>(this.hostUrl.getPath('/st/4/user_grants'));
    }

    public executeGrantLink(link: string) {
        return post(link);
    }

    public updateCurrentPlan(plan: string) {
        return post(this.hostUrl.getPath('/st/4/update_stripe_purchase', {plan}));
    }
 }
