import * as React from 'react';
import styled from 'styled-components';
import {CUSTOMER_SERVICE_LINK, HOME_SITE_ADDRESS, TERMS_OF_SERVICE_LINK} from '../../../../config/constants';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {CaptureIconInactive} from '../Icons/CaptureLogo';
import {TelenorCaptureLogo} from '../Icons/TelenorCaptureLogo';

const Container = styled.div`
    min-height: 100vh;
    height: 100vh;
    width:100%;
    padding: 48px 16px 16px;
    box-sizing: border-box;

    display: block;
`;

const Content = styled.div`
    display: table;
    height: 65%;
    width:100%;
`;

const Wrapper = styled.div`
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 0 30px;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 31px auto 0;
    width:100%;
    display: inline-block;
`;

const Description = styled.div`
    font-size: 14px;
    margin: 44px auto 0;
    width:100%;
    display: inline-block;
`;

const ExtLink = styled.a`
    width:100%;
    display: inline-block;
    font-size: 14px;
    color: ${colors.captureBlue};
    text-align: center;
    text-decoration: underline;
    margin-top: 45px;
`;

const FooterWrapper = styled.div`
    width: 100%;
    border-top: 1px solid rgba(0,0,0,0.14);
    padding: 20px 8px 16px;
    font-size: 10px;
    text-align: center;
    margin-top: 40px;

    display: inline-block;
`;

const LogoWrapper = styled.div`
    width:100%;
    display: inline-block;
`;

const FooterText = styled.p`
    color: ${colors.captureGrey600};
    max-width: 550px;
    margin: 16px auto;
`;

const ButtonDiv = styled.div`
    width: 100%;
    display: table;
`;

const ButtonWrapper = styled.div`
    max-width: 176px;
    min-height: 25px;
    background: white;
    border: 1px solid ${colors.captureGrey300};
    font-size: 12px;
    line-height: 22px;
    color: ${colors.captureGrey600};
    display: inline-block;

    padding: 5px;
    margin: 6px;

    a {
        text-align: center;
        vertical-align: middle;
    }
`;

export const SuggestModernBrowser: React.SFC = () => (
    <Container>
        <Content>
            <Wrapper>
                <LogoWrapper><CaptureIconInactive size={78}/></LogoWrapper>
                <Title>{_('upgrade_your_browser')}</Title>
                <Description>{_('upgrade_your_browser_des')}</Description>
                <ExtLink href={HOME_SITE_ADDRESS}>{_('capture_more_link')}</ExtLink>
            </Wrapper>
        </Content>
        <FooterWrapper>
            <LogoWrapper><TelenorCaptureLogo width={153} height={43}/></LogoWrapper>
            <FooterText>{_('telenor_capture_text')}</FooterText>
            <ButtonDiv>
                <ButtonWrapper>
                    <a href={TERMS_OF_SERVICE_LINK} target="_blank">{_('terms_of_service')}</a>
                </ButtonWrapper>
                <ButtonWrapper>
                    <a href={CUSTOMER_SERVICE_LINK} target="_blank">{_('customer_service')}</a>
                </ButtonWrapper>
            </ButtonDiv>
        </FooterWrapper>
    </Container>
);
