import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {_, Button, ButtonProps, colors} from '../../assets';
import {State} from '../../state';
import {isOutOfStorage} from '../../state/currentUser/selectors';
import {isStoryJob} from '../../state/jobInfo/selectors';
import {
    FileUploadRetry, UploaderPaused, UploaderResumed, UploaderStatusBoxCollapsed, UploaderStatusBoxDismissed,
    UploaderStatusBoxExpanded, UploaderStatusBoxShown, UploaderStopAborted, UploaderStopPrompted,
    UploadStatusListFiltered,
} from '../../state/uploader/actions';
import {FileInformation} from '../../state/uploader/reducer';
import {
    getAllCurrentFiles, getCurrentlyDoneRatio, getCurrentlyUploadingFile, getEnquedFiles, getFinishedThumbs,
    getNotUploadedFiles, getPendingFiles, getRejectedFiles, getUploadStatusHeight, getVisibleFiles,
    isOffline, isPaused, isRetrying, isStatusBoxExpanded, isStatusBoxVisible, isStopPrompted,
    isUploaderDone,
} from '../../state/uploader/selectors';
import {getConnectedInstance} from '../../state/uploader/uploadQueue';
import {isMobileMode} from '../../state/viewMode/selectors';
import {addOnBeforeUnloadBlock, removeOnBeforeUnloadBlock} from '../../utilities/onBeforeUnload';
import {NoInternetIcon} from '../Icons/NoInternetIcon';
import {WarningIcon} from '../Icons/WarningIcon';
import {AsyncPreviewThumb} from './AsyncPreviewThumb';
import {MultiThumb} from './MultiThumb';
import {StopPrompt} from './StopUploadPrompt';
import {UploadStatusBoxContent} from './UploadStatusBoxContent';
import {UploadStatusBoxFooter} from './UploadStatusBoxFooter';
import {UploadStatusBoxList} from './UploadStatusBoxList';

const Wrapper = styled.div`
    position: fixed;
    bottom: 8px;
    left: 40px;
    width: 304px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 2px 5px;
`;

const StatusBoxWrapper = styled.div`
    height: ${(props: {height: number}) => props.height}px;
    background-color: white;
    box-shadow: 3px -3px 20px 2px rgba(0, 0, 0, 0.21);
    transition: height 0.3s ease-in-out;
`;

type StateProps = {
    isMobileMode: boolean,
    isExpanded: boolean,
    isVisible: boolean,
    isOffline: boolean,
    isRetrying: boolean,
    isPaused: boolean,
    isUploading: boolean,
    isPendingStop: boolean,
    isOutOfStorage: boolean,

    uploadProgress: number,
    waitingFiles: number,
    totalFilesInQueue: number,
    completedFiles: number,
    unSuccessfulFiles: number,
    visibleFiles: FileInformation[],
    numberOfRejectedFiles: number,

    randomFinishedImages: FileInformation[],
    uploaderHeight: number,
    currentFile: FileInformation,
    fileIsStoryJob: boolean,
};

type DispatchProps = {
    doExpand: () => void,
    doCollapse: () => void,
    doClose: () => void,
    doOpen: () => void,
    onPause: () => void,
    onResume: () => void,
    doRetry: () => void,
    doStopPrompt: () => void,
    doStopAborted: () => void,
    onFilterList: () => void,
    clearUploadQueue: () => void,
};

type Props = StateProps & DispatchProps;

type UploaderBoxState = {
    retryingIn?: number,
    retryTimer?: number,
};

class _UploadStatusPlacement extends React.Component<Props, UploaderBoxState> {
    public state: UploaderBoxState = {};

    public componentWillReceiveProps(newProps: Props) {
        if (
            (newProps.isUploading || newProps.numberOfRejectedFiles > this.props.numberOfRejectedFiles) &&
            !this.props.isVisible
        ) {
            this.props.doOpen();
        } else if (
            !newProps.isVisible &&
            !newProps.isUploading &&
            newProps.isUploading !== this.props.isUploading
        ) {
            this.props.clearUploadQueue();
        }

        if (newProps.isOffline && this.state.retryingIn === undefined) {
            this.retryIn(4);
        }
    }

    private isUploading = () => this.props.isUploading;
    public componentDidMount() {
        addOnBeforeUnloadBlock(this.isUploading);
    }
    public componentWillUnmount() {
        removeOnBeforeUnloadBlock(this.isUploading);
    }

    private updateState(changes: Partial<UploaderBoxState>) {
        this.setState({
            ...this.state,
            ...changes,
        });
    }

    private retryIn(sec: number) {
        const timer = window.setInterval(() => {
            if (
                this.state.retryingIn !== undefined &&
                this.state.retryingIn > 1
            ) {
                this.updateState({retryingIn: this.state.retryingIn - 1});
            } else {
                this.retryNow();
            }
        }, 1000);
        this.updateState({retryingIn: sec, retryTimer: timer});
    }

    private retryNow() {
        if (this.state.retryTimer) {
            clearInterval(this.state.retryTimer);
        }
        this.updateState({retryingIn: undefined, retryTimer: undefined});
        this.props.doRetry();
    }

    private close() {
        this.props.doClose();
        if (this.props.isExpanded) {
            this.props.doCollapse();
        }
    }

    private filter() {
        this.props.onFilterList();
        this.props.doExpand();
    }

    private getCurrentElementConfig() {
        let mainText = '',
            subText = '',
            buttons: ButtonProps[] = [],
            thumb: React.ReactElement<HTMLDivElement>,
            isExpandable = true,
            leftButtonPosition = 120;

        if (this.props.isOffline) {
            mainText = _('offline');
            subText = `
                ${this.props.completedFiles} ${_('of')} ${
                this.props.totalFilesInQueue
            } \n
                ${_('retrying_in')} ${this.state.retryingIn} ${_('sec')}
            `;
            buttons = [
                Button(_('stop'), this.props.doStopPrompt),
                Button(_('try_again'), () => this.retryNow()),
            ];
            thumb = <NoInternetIcon size={56} color={colors.captureGrey800} />;
            leftButtonPosition = 38;
        } else if (this.props.isRetrying) {
            mainText = _('offline');
            subText = _('retrying_now');
            buttons = [Button(_('try_again'), () => {}, {isActive: false})];
            thumb = <NoInternetIcon size={56} color={colors.captureGrey800} />;
        } else if (this.props.isOutOfStorage) {
            mainText = _('out_of_storage');
            subText = _('free_up_space');
            buttons = [Button(_('stop'), this.props.doStopPrompt)];
            thumb = <WarningIcon size={56} />;
        } else {
            if (this.props.uploadProgress < 1 || this.props.currentFile) {
                const thisFile = this.props.currentFile || this.props.visibleFiles[0];
                mainText = _('uploading');
                subText = `${this.props.completedFiles} ${_('of')} ${this.props.totalFilesInQueue}`;
                buttons = [Button(_('stop'), this.props.doStopPrompt)];
                thumb = (
                    <AsyncPreviewThumb
                        fileName={thisFile.name}
                        uploadId={thisFile.id}
                    />
                );
            } else if (this.props.unSuccessfulFiles > 0) {
                isExpandable = false;
                mainText = `${this.props.unSuccessfulFiles} ${_('of')} ${this.props.totalFilesInQueue} ${_('files')} \n
                            ${_('did_not_upload')}`;
                subText = '';
                buttons = [Button(_('ok'), () => this.close())];
                if (!this.props.isExpanded) {
                    buttons.unshift(
                        Button(_('see_which'), () => this.filter()),
                    );
                }
                thumb =
                    this.props.numberOfRejectedFiles > 0 ? (
                        <WarningIcon size={56} />
                    ) : (
                        <MultiThumb files={this.props.randomFinishedImages} />
                    );
            } else {
                isExpandable = false;
                mainText = `${this.props.completedFiles} ${_('files')} \n${_('uploaded')}`;
                subText = `${this.props.completedFiles} ${_('of')} ${this.props.totalFilesInQueue}`;
                buttons = [Button(_('ok'), () => this.close())];
                thumb = <MultiThumb files={this.props.randomFinishedImages} />;
            }
        }

        return {
            mainText,
            subText,
            buttons,
            thumb,
            isExpandable,
            leftButtonPosition,
        };
    }

    public render() {
        if (this.props.isMobileMode || !this.props.isVisible) {
            return null;
        }

        const config = this.getCurrentElementConfig();

        let content: React.ReactNode = null;
        if (this.props.isExpanded) {
            const filesInList = this.props.visibleFiles;
            const statusText =
                this.props.isUploading && !this.props.isOffline
                    ? config.subText
                    : config.mainText;
            content = (
                <UploadStatusBoxList
                    filesInList={filesInList}
                    availableHeight={this.props.uploaderHeight - 64}
                    isOffline={this.props.isOffline}
                    statusText={statusText}
                    displayWarningIcon={
                        !this.props.isUploading &&
                        this.props.numberOfRejectedFiles > 0
                    }
                    doCollapse={this.props.doCollapse}
                />
            );
        } else {
            content = (
                <UploadStatusBoxContent
                    mainText={config.mainText}
                    subText={config.subText}
                    thumb={config.thumb}
                    doExpand={this.props.doExpand}
                />
            );
        }

        const stopPrompt = (
            <StopPrompt
                doStopAborted={this.props.doStopAborted}
                doStop={getConnectedInstance().stop}
            />
        );

        return (
            <Wrapper>
                <StatusBoxWrapper height={this.props.uploaderHeight}>
                    {content}
                </StatusBoxWrapper>
                <UploadStatusBoxFooter
                    hasBorder={this.props.isExpanded}
                    buttons={config.buttons}
                    disableStatusBar={
                        this.props.isOffline || this.props.isOutOfStorage
                    }
                    uploadProgress={this.props.uploadProgress}
                    leftButtonMargin={config.leftButtonPosition}
                />
                {this.props.isPendingStop && stopPrompt}
            </Wrapper>
        );
    }
}

const mapStateToProps = (state: State): StateProps => {
    const currFile = getCurrentlyUploadingFile(state);
    const allFiles = getAllCurrentFiles(state);
    const fileToCheck = currFile || allFiles[0];

    return {
        isMobileMode: isMobileMode(state),
        isExpanded: isStatusBoxExpanded(state),
        isVisible: isStatusBoxVisible(state),
        isOffline: isOffline(state),
        isRetrying: isRetrying(state),
        isPaused: isPaused(state),
        isUploading: !isUploaderDone(state),
        isPendingStop: isStopPrompted(state),
        isOutOfStorage: isOutOfStorage(state),

        uploadProgress: getCurrentlyDoneRatio(state),
        waitingFiles: getEnquedFiles(state).length,
        visibleFiles: getVisibleFiles(state),
        totalFilesInQueue: getAllCurrentFiles(state).length,
        completedFiles:
            getAllCurrentFiles(state).length - getEnquedFiles(state).length - getPendingFiles(state).length,
        unSuccessfulFiles: getNotUploadedFiles(state).length,
        numberOfRejectedFiles: getRejectedFiles(state).length,

        currentFile: currFile,
        uploaderHeight: getUploadStatusHeight(state),
        fileIsStoryJob:
            fileToCheck !== undefined &&
            isStoryJob(state, fileToCheck.targetJob) === true,
        randomFinishedImages: getFinishedThumbs(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doExpand: () => dispatch(UploaderStatusBoxExpanded()),
    doCollapse: () => dispatch(UploaderStatusBoxCollapsed()),
    doClose: () => dispatch(UploaderStatusBoxDismissed()),
    doOpen: () => dispatch(UploaderStatusBoxShown()),
    onPause: () => dispatch(UploaderPaused()),
    onResume: () => dispatch(UploaderResumed()),
    doRetry: () => dispatch(FileUploadRetry()),
    doStopPrompt: () => dispatch(UploaderStopPrompted()),
    doStopAborted: () => dispatch(UploaderStopAborted()),
    onFilterList: () => dispatch(UploadStatusListFiltered()),
    clearUploadQueue: () => getConnectedInstance().clearUploadFiles(),
});

export const UploadStatusPlacement = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_UploadStatusPlacement);
