import * as React from 'react';
import styled from 'styled-components';
import {colors, fontSize} from '../../assets/styleConstants';
import {FullscreenOverlay} from './FullscreenOverlay';
import {withClickOutsideHandling} from './HandleClickOutsideHOC';
import {OverlayModal} from './OverlayModal';

const Wrapper = styled.div`
    background: white;
    min-width: 250px;
    text-align: left;
    text-transform: none;
    font-weight: normal;
    padding: 8px;
`;

const MenuWrapper = withClickOutsideHandling(Wrapper.extend`
    box-shadow: rgba(0,0,0,0.2) 0px 3px 4px;
    padding: 24px;
    box-sizing: border-box;
`);

const SlideUpOverlay = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 80%;
    overflow: auto;
`;

const MenuTitle = styled.div`
    font-size: ${fontSize.small_14};
    color: ${colors.captureGrey400};

    margin-bottom: 12px;
    cursor: default;
`;

type Props = {
    title: string,
    onClickOutside?: () => any,
};

export const ChoiceMenu: React.SFC<Props> = (props) => (
    <MenuWrapper onClickOutside={props.onClickOutside}>
        <MenuTitle>{props.title.toUpperCase()}</MenuTitle>
        {props.children}
    </MenuWrapper>
);

type OverlayMenuProps = {
    title: string,
    onClose: () => any,
};
export const SlideUpChoiceMenu: React.SFC<OverlayMenuProps> = (props) => (
    <FullscreenOverlay onOverlayClick={props.onClose}>
        <SlideUpOverlay><ChoiceMenu {...props} /></SlideUpOverlay>
    </FullscreenOverlay>
);

export const CenteredChoiceMenu: React.SFC<OverlayMenuProps> = (props) => (
    <OverlayModal onClose={props.onClose} isMobile={false}>
        <Wrapper>
            <MenuTitle>{props.title.toUpperCase()}</MenuTitle>
            {props.children}
        </Wrapper>
    </OverlayModal>
);
