import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {slideBarPaginationThumbs} from '.';
import {CarouselViewTV} from '../../../captureTV/components/CarouselView/CarouselViewTV';
import {initTimeline} from '../../API/job';
import {setCurrentVisibleRanges} from '../../API/syncers/TimelineChunkSyncer';
import {EnteredCarouselView} from '../../state/carouselViewer/actions';
import {State} from '../../state/store';
import {TimelineMonth} from '../../state/timeline/reducers';
import {getTimelineJobID, getTimelineMonths} from '../../state/timeline/selectors';
import {getElementIndex} from '../../utilities/arrayUtils';
import {b64ToUuid} from '../../utilities/uuid';
import {RippleLoaderPage} from '../Common/RippleLoader';
import {FilesOptionsPlacement} from '../FilesOptions/FilesOptionsPlacement';
import {CarouselViewPlacement} from './CarouselViewPlacement';

type AlbumRouterProps = {
    params: {albumIDB64: JobID, fileID: FileID},
};

type AlbumDispatchProps = {
    startCarouselView: () => any,
};

type AlbumCarouselViewPageProps = AlbumRouterProps & AlbumDispatchProps;

class AlbumCarouselViewPageComp extends React.Component<AlbumCarouselViewPageProps> {
    public componentDidMount() {
        this.props.startCarouselView();
    }

    public render() {
        return (
            <>
                <CarouselViewPlacement />
                <FilesOptionsPlacement
                    jobID={b64ToUuid(this.props.params.albumIDB64)}
                    files={[this.props.params.fileID]}
                />
            </>
        );
    }
}
const albumMapDispatchToProps = (dispatch: Dispatch, ownProps: AlbumRouterProps): AlbumDispatchProps => ({
    startCarouselView: () => dispatch(EnteredCarouselView({
        jobID: b64ToUuid(ownProps.params.albumIDB64), fileID: ownProps.params.fileID,
    })),
});
export const AlbumCarouselViewPage = connect(
    null,
    albumMapDispatchToProps,
)(AlbumCarouselViewPageComp);

class AlbumCarouselViewPageTVComp extends React.Component<AlbumCarouselViewPageProps> {
    public componentDidMount() {
        this.props.startCarouselView();
    }
    public render() {
        return <CarouselViewTV />;
    }
}
export const AlbumCarouselViewTVPage = connect(
    null,
    albumMapDispatchToProps,
)(AlbumCarouselViewPageTVComp);

const getPaginationMonthIndex = (months: TimelineMonth[]) => {
    const paginationThreshold = 2 * slideBarPaginationThumbs;
    let targetIndex = 0;
    let monthFileCount = 0;
    months.forEach((m) => {
        if (monthFileCount < paginationThreshold) {
            monthFileCount += m.count;
            targetIndex += 1;
        }
    });

    return targetIndex;
};

type TimelineRouterProps = {
    params: {sourceGroup: string, fileID: FileID},
};

type TimelineStateProps = {
    timelineJobID: JobID|undefined,
    timelineMonths: TimelineMonth[],
};

type TimelineDispatchProps = {
    initTimeline: () => any,
    fetchFilesForCurrentVisibleRanges: (visibleMonths: Month[]) => any,
    startCarouselView: (timelineJob: JobID) => any,
};

type TimelineCarouselViewPageProps = TimelineRouterProps & TimelineStateProps & TimelineDispatchProps;

const makeTimelineCarouselViewPageComp = (Inner: React.ComponentType<any>) => {
    return class Component extends React.Component<TimelineCarouselViewPageProps> {
        public componentDidMount() {
            if (this.props.timelineJobID === undefined || this.props.timelineMonths.length === 0) {
                this.props.initTimeline();
            } else {
                this.props.startCarouselView(this.props.timelineJobID);
            }
        }

        public componentDidUpdate(prevProps: TimelineCarouselViewPageProps) {
            if (this.props.timelineJobID
                && prevProps.timelineMonths.length !== this.props.timelineMonths.length) {
                this.setVisibleRange();
                this.props.startCarouselView(this.props.timelineJobID);
            }
        }

        private setVisibleRange = () => {
            const [year, month] = this.props.params.sourceGroup.split('-');
            const currentYear = parseInt(year, 10);
            const currentMonth = parseInt(month, 10);
            const monthsTotal = this.props.timelineMonths.length;
            const currentMonthIndex = getElementIndex(this.props.timelineMonths, (m) => {
                return m.year === currentYear && m.month === currentMonth;
            });

            const fetchEnd = currentMonthIndex < monthsTotal - 1 ?
                currentMonthIndex + getPaginationMonthIndex(this.props.timelineMonths.slice(currentMonthIndex + 1, monthsTotal))
                : monthsTotal;

            this.props.fetchFilesForCurrentVisibleRanges(this.props.timelineMonths.slice(0, fetchEnd + 1));
        }

        public render() {
            if (this.props.timelineMonths.length === 0 || this.props.timelineJobID === undefined || !this.props.params) {
                return <RippleLoaderPage/>;
            } else {
                return (
                    <Inner timelineID={this.props.timelineJobID} fileID={this.props.params.fileID}/>
                );
            }
        }
    };
};

const TimelineMapStateToProps = (state: State): TimelineStateProps => ({
    timelineJobID: getTimelineJobID(state),
    timelineMonths: getTimelineMonths(state),
});

const TimelineMapDispatchToProps = (dispatch: Dispatch, ownProps: TimelineRouterProps): TimelineDispatchProps => ({
    initTimeline: () => initTimeline(dispatch),
    fetchFilesForCurrentVisibleRanges: (visibleMonths: Month[]) => {
        setCurrentVisibleRanges(visibleMonths);
    },
    startCarouselView: (timelineJob: JobID) => dispatch(EnteredCarouselView({
        jobID: timelineJob, fileID: ownProps.params.fileID,
    })),
});

const TimelineCarouselViewContent: React.SFC<{timelineID: string, fileID: string}> =
    (props: {timelineID: string, fileID: string}) => (
        <>
            <CarouselViewPlacement/>
            <FilesOptionsPlacement jobID={props.timelineID || ''} files={[props.fileID]}/>
        </>
    );
const TimelineCarouselViewPageComp = makeTimelineCarouselViewPageComp(TimelineCarouselViewContent);
export const TimelineCarouselViewPage = connect(
    TimelineMapStateToProps,
    TimelineMapDispatchToProps,
)(TimelineCarouselViewPageComp);

const TimelineCarouselViewPageTVComp = makeTimelineCarouselViewPageComp(CarouselViewTV);
export const TimelineCarouselViewTVPage = connect(
    TimelineMapStateToProps,
    TimelineMapDispatchToProps,
)(TimelineCarouselViewPageTVComp);
