
export const getRange = (num: number): number[] => Array.from(Array(num).keys());

export const inArray = <T>(haystack: T[], needle: T): boolean => {
    return haystack.some((e) => e === needle);
};

type CachedInArray<T> = (haystack: T[]) => (key: T) => boolean;
export const cachedInArray: CachedInArray<string>&CachedInArray<number> = (haystack: Array<string|number>) => {
    const list = haystack.reduce<DictionaryOf<string>>((dict, e) => {
        dict[e] = typeof e;
        return dict;
    }, {});

    return (key: string|number) => {
        return list[key] !== undefined && list[key] === typeof key;
    };
};

export const withoutTheBools = <T>(arr: Array<T|boolean>): T[] => arr.filter((e): e is T => typeof e !== 'boolean');

export const getElementIndex = <T>(list: T[], match: (e: T) => boolean): number => {
    for (let i = 0; i < list.length; i++) {
        if (match(list[i])) {
            return i;
        }
    }

    return -1;
};
