import {GoogleAnalyticsTracker} from '../../common/analytics/trackers/GoogleAnalyticsTracker';

export class GoogleAlbumTracker extends GoogleAnalyticsTracker {

    public trackPageChange(newPath: string) {
        // Track the first part of the url as a custom dimension (allow analysing top-levels)
        super.setOnTracker('dimension1', '/' + newPath.substr(1).split('/')[0]);

        super.trackPageChange(newPath);
    }
}