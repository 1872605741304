import * as React from 'react';
import {fonts} from '../../assets/styleConstants';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const SMSIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="sms-linearGradient-1">
                <stop stopColor="#3ADFCB" offset="0%" />
                <stop stopColor="#1BBC9B" offset="100%" />
            </linearGradient>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="url(#sms-linearGradient-1)" x="0" y="0" width={props.size} height={props.size} rx="3" />
            <path fill="#FFFFFF" d="M24.0249996,39.0438363 C21.6807888,39.0438363 19.4341845,38.6980776 17.3596324,38.0661168 L6.4260478,43.1532363 L10.7074999,34.6068756 C7.18539343,31.7895213 5,27.8654083 5,23.5227453 C5,14.9499151 13.5165824,8 24.0249996,8 C34.5334168,8 43.0499992,14.9499151 43.0499992,23.5227453 C43.0499992,32.0955756 34.5334168,39.0438363 24.0249996,39.0438363 Z" />
            <text fontFamily={fonts.Lato} fontSize={props.size / 4} fill="#1BBC9B">
                <tspan x="12" y="28">SMS</tspan>
            </text>
        </g>
    </svg>
);
