import {StripePaymentInfoResponse, StripeProduct, UserGrant} from '../../API/services/AppService';
import {Action, isType} from '../common/actions';
import {PaymentInfoFetched, StorageProductsFetched, UserStoragePlanFetched} from './actions';

export type StorageState = {
    availableProducts: StripeProduct[],
    userGrants: UserGrant[],
    paymentInfo: StripePaymentInfoResponse|undefined,
};

const initialState = {
    availableProducts: [],
    userGrants: [],
    paymentInfo: undefined,
};

export const storagePlanReducer = (state: StorageState = initialState, action: Action<any>): StorageState => {
    if (isType(action, StorageProductsFetched)) {
        return {
            ...state,
            availableProducts: action.payload,
        };
    }

    if (isType(action, UserStoragePlanFetched)) {
        return {
            ...state,
            userGrants: action.payload,
        };
    }
    if (isType(action, PaymentInfoFetched)) {
        return {
            ...state,
            paymentInfo: action.payload,
        };
    }

    return state;
};
