import * as React from 'react';
import styled from 'styled-components';
import {colors, layout} from '../../assets/styleConstants';
import {ActionableTableContent, TableEntry} from '../Common/ActionableTableContent';
import {ContainerWithStickyHeader} from './ContainerWithStickyHeader';
import {ThreeColumns, ThreeColumnsRow} from './ThreeColumnsRow';

const HeaderWrapper = styled.div`
    margin: auto;
    color: ${colors.captureGrey400};
    text-transform: capitalize;
`;

const ContentWrapper = styled.div`
    margin: 10px auto;
`;

type Props<T> = {
    isMobile: boolean,
    isInSelectMode: boolean,
    headerColumns: ThreeColumns,
    rows: T[],
    entry: TableEntry<T>,
};

export class FilesTable<T> extends React.Component<Props<T>> {
    public render() {
        const headerPosition = this.props.isMobile || !this.props.isInSelectMode ?
            layout.topNavBarHeight : (layout.topNavBarHeight + layout.selectionToolbarHeight);

        const header = (
            <HeaderWrapper>
                <ThreeColumnsRow
                    isMobile={this.props.isMobile}
                    columns={this.props.headerColumns}
                />
            </HeaderWrapper>
        );

        return (
            <ContainerWithStickyHeader
                header={header}
                headerPosition={headerPosition}
            >
                <ContentWrapper>
                    <ActionableTableContent
                        rows={this.props.rows}
                        entry={this.props.entry}
                        disableAction={this.props.isInSelectMode}
                    />
                </ContentWrapper>
            </ContainerWithStickyHeader>
        );
    }
}
