import * as React from 'react';
import {createShareWithFiles} from '../../API/job';
import {JobFile} from '../../state/files/reducer';
import {asTimelineFilterValue} from '../../state/timeline/reducers';
import {parseSearchString} from '../../utilities/urlParsing';
import {LoadingPage} from '../Common/LoadingPage';
import {PhotoSelectionPage} from '../Timeline/PhotoSelectionPage';
import {SelectPhotoExternalParams} from './NavigationUtil';

type Props = {
    params: {},
};

type ComponentState = {
    isCopying: boolean,
    externalParams?: SelectPhotoExternalParams,
};

export class SelectFromTimelineToExternalPage extends React.Component<Props, ComponentState> {
    public state: ComponentState = {isCopying: false};
    private doHandleFiles = async (files: JobFile[]) => {
        if (this.state.externalParams === undefined) {
            return;
        }
        this.setState((prevState) => ({...prevState, isCopying: true}));
        const id = await createShareWithFiles((() => {/* Ignore dispatch */}) as any, '_for_external_', files);

        window.location.replace(this.state.externalParams.callbackTemplate.replace('<uuid>', id || ''));
    }

    public componentDidMount() {
        const {countLimit, callbackTemplate, filter} = parseSearchString(window.location.search);

        this.setState((s: ComponentState) => ({
                ...s,
                externalParams: {
                    countLimit: parseInt(countLimit,  10),
                    callbackTemplate,
                    filter: asTimelineFilterValue(filter) || 'all',
                },
            }));
    }

    public render() {
        if   (this.state.externalParams === undefined || this.state.isCopying) {
            return <LoadingPage />;
        }

        return (
            <PhotoSelectionPage
                doHandleFiles={this.doHandleFiles}
                headerTitle={`Velg opp til ${this.state.externalParams.countLimit} bilder`}
                filter={this.state.externalParams.filter}
                selectionMax={this.state.externalParams.countLimit}
            />
        );
    }
}
