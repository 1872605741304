import {GATracker} from '../../analytics/trackers/GATracker';
import {isOpenInsideAppWebView} from '../../API/externals';

let tracker: GATracker | null = null;
const getTakeoutGATracker = () => {
    if (tracker === null) {
        tracker = new GATracker('GOOGLE_ANALYTICS_ID__TAKEOUT', 'takeout');
        tracker.set('dimension1', isOpenInsideAppWebView() ? 'true' : 'false');
    }
    return tracker;
};

export const trackTakeoutEvent = (event: string) => {
    // getTakeoutGATracker().sendEvent('takeout', event);
};

export const trackTakeoutPageview = (path: string) => {
    // getTakeoutGATracker().set('page', path);
    // getTakeoutGATracker().sendPageview();
}