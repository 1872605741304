import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const PhotoIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g strokeWidth="1" stroke={props.color || colors.defaultIconColor} fill="none" fillRule="evenodd">
            <path strokeWidth="2" d="M21,19.9V4.1C21,3.5,20.5,3,19.9,3H4.1C3.5,3,3,3.5,3,4.1v15.8C3,20.5,3.5,21,4.1,21h15.8C20.5,21,21,20.5,21,19.9z M3,19.9l4.9-7.1l2.9,3l4-4.4l6.1,8.5" />
            <circle cx="10.3" cy="8.1" r="1.7" />
        </g>
    </svg>
);

export const PhotoIconFilled: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill={props.color || colors.defaultIconColor} fillRule="nonzero" transform="translate(3.000000, 3.000000)">
            <path d="M13.5910112,16.694382 L6.49213483,9.59550562 L0.182022472,15.8853933 L0.182022472,16.6741573 C0.182022472,17.9078652 1.17303371,18.8988764 2.40674157,18.8988764 L15.7146067,18.8988764 C16.9483146,18.8988764 17.9393258,17.9078652 17.9393258,16.6741573 L17.9393258,15.9865169 L16.1191011,14.1662921 L13.5910112,16.694382 Z" />
            <path d="M15.7146067,0.141573034 L2.40674157,0.141573034 C1.17303371,0.141573034 0.182022472,1.13258427 0.182022472,2.36629213 L0.182022472,12.9438202 L6.49213483,6.63370787 L13.611236,13.752809 L16.1393258,11.2247191 L17.9595506,13.0449438 L17.9595506,2.36629213 C17.9393258,1.13258427 16.9483146,0.141573034 15.7146067,0.141573034 Z M13.4587911,6.19595509 C12.6437187,6.19595509 11.9787911,5.52617406 11.9787911,4.70515216 C11.9787911,3.88413025 12.6437187,3.23595506 13.4587911,3.23595506 C14.2738636,3.23595506 14.9387911,3.90573609 14.9387911,4.726758 C14.9387911,5.5477799 14.2738636,6.19595509 13.4587911,6.19595509 Z" />
        </g>
    </svg>
);
