import {PrintProduct} from '../../../print/constants/productInfo';
import {Action, isType} from '../common/actions';
import {FileWasAddedToOrderItem, FileWasRemovedFromOrderItem,
        OrderItemAdded, OrderItemInitiated,
        OrderItemQuantityUpdated, PrintFileCroppingUpdated, PrintFileQuantityUpdated} from './actions';

export type PrintArea = {
    top: number,
    left: number,
    width: number,
    height: number,
};

export type PrintFile = {
    fileID: FileID,
    quantity: number,
    originalResolution: {width: number, height: number},
    selectedPrintArea: PrintArea,
};
export type OrderItem = {
    orderID: string,
    quantity: number,
    productType: PrintProduct,
    orderFiles: PrintFile[],
};

export type OrderItemsState = DictionaryOf<OrderItem>;

const initialState = {};

export const orderItemsReducer = (
    state: OrderItemsState = initialState,
    action: Action<any>,
): OrderItemsState => {

    if (isType(action, OrderItemInitiated)) {
        const nextID = Object.keys(state).length;
        const newOrderItem = {
            orderID: action.payload.orderID,
            quantity: 1,
            productType: action.payload.productType,
            orderFiles: [],
        };
        return {...state, [nextID]: newOrderItem};
    }
    if (isType(action, OrderItemAdded)) {
        return {...state, [Object.keys(state).length]: action.payload};
    }
    if (isType(action, OrderItemQuantityUpdated) && state[action.payload.orderItemID]) {
        const orderOfInterest = state[action.payload.orderItemID];
        return {
            ...state,
            [action.payload.orderItemID]: {
                ...orderOfInterest,
                quantity: action.payload.updatedQuantity,
            },
        };
    }

    if (isType(action, FileWasAddedToOrderItem) && state[action.payload.orderItemID]) {
        // default selected print area: square (for mvp), and centered
        const {width, height} = action.payload.fileData.originalResolution;
        const size = Math.min(width, height);

        const newFile = {
            ...action.payload.fileData,
            quantity: 1,
            selectedPrintArea: {
                top: Math.floor((height - size) / 2),
                left: Math.floor((width - size) / 2),
                width: size,
                height: size,
            },
        };

        const orderOfInterest = state[action.payload.orderItemID];
        return {
            ...state,
            [action.payload.orderItemID]: {
                ...orderOfInterest,
                orderFiles: [...orderOfInterest.orderFiles, newFile],
            },
         };
    }

    if (isType(action, FileWasRemovedFromOrderItem) && state[action.payload.orderItemID]) {
        const orderOfInterest = state[action.payload.orderItemID];
        const newFiles = orderOfInterest.orderFiles
            .filter((f) => f.fileID !== action.payload.fileID);

        return {
            ...state,
            [action.payload.orderItemID]: {
                ...orderOfInterest,
                orderFiles: newFiles,
            },
        };
    }

    if (isType(action, PrintFileQuantityUpdated) && state[action.payload.orderItemID]) {
        const orderOfInterest = state[action.payload.orderItemID];
        orderOfInterest.orderFiles[action.payload.fileIndex].quantity = action.payload.updatedQuantity;
        return {
            ...state,
            [action.payload.orderItemID]: {
                ...orderOfInterest,
            },
        };
    }

    if (isType(action, PrintFileCroppingUpdated) && state[action.payload.orderItemID]) {
        const orderOfInterest = state[action.payload.orderItemID];
        orderOfInterest.orderFiles[action.payload.fileIndex].selectedPrintArea = action.payload.updatedCropping;

        return {
            ...state,
            [action.payload.orderItemID]: {
                ...orderOfInterest,
            },
        };
    }

    return state;
};
