import {Store} from 'redux';
import {getCurrentUserUUID} from '../../state/currentUser/selectors';
import {State} from '../../state/store';
import {getTimelineJobID, getUnfetchedTimelineMonths} from '../../state/timeline/selectors';
import {MonthAsNumber} from '../../utilities/dateOperations';
import {fetchFileRange, FetchFileRangeMethod} from '../job';

export class TimelineChunkSyncer {
    constructor(
        private store: Store<State>,
        private fetchRange: FetchFileRangeMethod,
    ) {}

    public async setCurrentVisibleRanges(ranges: Month[]) {
        const state = this.store.getState();
        const jobID = getTimelineJobID(state);
        const currentUser = getCurrentUserUUID(state);

        const unfetchedRanges: DictionaryOf<boolean> = getUnfetchedTimelineMonths(state).reduce(
            (o, v) => ({...o, [MonthAsNumber(v)]: true}), {},
        );

        ranges = ranges.filter((m) => unfetchedRanges[MonthAsNumber(m)] === true);

        if (jobID && currentUser && ranges.length > 0) {
            const rFrom = ranges.reduce((p, c) => MonthAsNumber(p) < MonthAsNumber(c) ? p : c);
            const rTo   = ranges.reduce((p, c) => MonthAsNumber(p) > MonthAsNumber(c) ? p : c);

            await this.fetchRange(this.store.dispatch, jobID, currentUser, rFrom, rTo);
        }
    }
}

let instance: TimelineChunkSyncer;
export const connectTimelineChunkSyncer = (store: Store<State>) => {
    instance = new TimelineChunkSyncer(store, fetchFileRange);
};
// To be called from Timeline when visible ranges changes. Maybe better to dispatch these and get the "pending visible"
// -ranges from selector.
export const setCurrentVisibleRanges = (ranges: Month[]) => {
    if (instance === undefined) {
        console.warn('setCurrentVisibleRanges ignored: Must be connected first');
        return;
    }
    instance.setCurrentVisibleRanges(ranges);
};
