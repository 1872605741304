import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

export const PreviousIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polyline strokeWidth="2" stroke={props.color || colors.defaultIconColor} transform="translate(12.042307, 12.000000) scale(-1, 1) translate(-12.042307, -12.000000) " points="8.1692286 4 16.0846143 11.9153857 8 20"/>
        </g>
    </svg>
);