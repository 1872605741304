import * as React from 'react';
import {trackEvent} from '../../analytics';

type LocalState = {
    isDragTarget: boolean,
};
export class VisibleOnDrag extends React.Component<{}, LocalState> {
    private lastDragEventTarget: EventTarget|null = null;

    public state: LocalState = {isDragTarget: false};

    private dragStart = (event: DragEvent) => {
        this.lastDragEventTarget = event.target;
        if (! this.state.isDragTarget) {
            trackEvent('Uploader', 'UploadOverlay_dragStart');
            this.setState({isDragTarget: true});
        }
    }

    private dragStop = (event: DragEvent) => {
        if (this.lastDragEventTarget === event.target) {
            trackEvent('Uploader', 'UploadOverlay_dragStop');
            this.setState({isDragTarget: false});
        }
    }

    private handleDropOccur = () => {
        this.setState({isDragTarget: false});
    }

    public componentDidMount() {
        window.addEventListener('dragenter', this.dragStart);
        window.addEventListener('dragleave', this.dragStop);
        window.addEventListener('drop', this.handleDropOccur);
    }

    public componentWillUnmount() {
        window.removeEventListener('dragenter', this.dragStart);
        window.removeEventListener('dragleave', this.dragStop);
        window.removeEventListener('drop', this.handleDropOccur);
    }

    public render() {

        if (this.state.isDragTarget) {
            return <div>{this.props.children}</div>;
        }
        return null;
    }
}
