import * as React from 'react';
import {connect} from 'react-redux';
import {State} from '../../state';
import {JobInfoStatus} from '../../state/jobInfo/reducer';
import {getStatusOfJob} from '../../state/jobInfo/selectors';
import {b64ToUuid} from '../../utilities/uuid';
import {LoadingPage} from '../Common/LoadingPage';
import {ShareNotFoundPage} from '../Info/AlbumNotFound';
import {ProvidePasswordSharePage} from '../Info/ProvidePassword';
import {ShareReceiverPage} from './ShareReceiverPage';

type OwnProps = {
    shareID: JobID,
};
type StateProps = {
    fetchStatus: JobInfoStatus;
};
type Props = OwnProps & StateProps;

class _ShareContainer extends React.Component<Props, {}> {

    public render() {
        switch (this.props.fetchStatus) {
            case JobInfoStatus.FETCHED:
            case JobInfoStatus.EXPIRED:
                return <ShareReceiverPage shareID={this.props.shareID} />;
            case JobInfoStatus.NOT_FOUND:
            case JobInfoStatus.FETCH_FAILED:
                return <ShareNotFoundPage />;
            case JobInfoStatus.PASSWORD_REQUIRED:
                return <ProvidePasswordSharePage shareID={this.props.shareID} />;
            case JobInfoStatus.NOT_STARTED:
            case JobInfoStatus.PENDING:
            default:
                return <LoadingPage />;
        }
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    fetchStatus: getStatusOfJob(state, ownProps.shareID),
});

const ShareContainer = connect(mapStateToProps)(_ShareContainer);

export const ShareMountUnpackedUUID:React.SFC<{params: {shareID: string}}> = ({params}) => (
    <ShareContainer shareID={params.shareID} />
);
export const ShareMount:React.SFC<{params: {shareIDB64: string}}> = ({params}) => (
    <ShareContainer shareID={b64ToUuid(params.shareIDB64)} />
);
