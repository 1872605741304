import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {logout} from '../../API/login';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {Pages} from '../../routing';
import {NavigateTo, PageDescription} from '../../state/routing/actions';
import {State} from '../../state/store';
import {isLargerThanTablet} from '../../state/viewMode/selectors';
import {goToOpenWeb} from '../../utilities/appLink';
import {inArray} from '../../utilities/arrayUtils';
import {CaptureLogoHorizontal} from '../Icons/CaptureLogo';
import {SlideInBurgerMenu} from './SlideInBurgerMenu';
import {ThreeAreasTopNavBar} from './ThreeAreasTopNavBar';

const NavElement = styled.div`
    font-size: ${fontSize.medium_18};
    font-weight: ${(props: {isHighlighted: boolean}) => props.isHighlighted ? 'bold' : 'normal'};
    text-transform: uppercase;
    letter-spacing: 2px;
    color: ${(props) => props.isHighlighted ? colors.captureBlue : colors.captureGrey800};
    padding-bottom: 14px;
    border-bottom: 4px solid ${(props) => props.isHighlighted ? colors.captureBlue : 'transparent'};
    margin: 0 30px;
    display: inline-block;
    cursor: pointer;
`;
const NavElementsWrapper = styled.div`
    align-self: flex-start;
`;

const PageSpecificElement = styled.div`
    margin-left: 32px;
    display: inline-flex;

    & > div, & > span {
        text-transform: uppercase;
        font-weight: bold;
        color: ${colors.captureGrey600};

        &:hover{
            color: ${colors.captureBlue};
        }
    }
`;
const BurgerWrapper = styled.div`
    margin-right: 32px;
`;

type StateProps = {
    showButtonText: boolean,
};
type DispatchProps = {
    goTo: (page: PageDescription) => any,
    doLogout: () => void,
};
type OwnProps = {
    currentPage?: PageDescription,
    mainActionElements?: (showText: boolean) => React.ReactNode[],
};
type Props = OwnProps & DispatchProps & StateProps;

class MainTopNavBar extends React.Component<Props, {}> {

    public render() {
        const makeNavButton = (label: string, page: PageDescription) => (
            {label, page, onClick: () => this.props.goTo(page)}
        );
        const navButtons = [
            makeNavButton(_('timeline'), Pages.Timeline),
            makeNavButton(_('albums'), Pages. Albums),
        ];

        const navElements = navButtons.map((link) => {
            return (
                <NavElement
                    key={link.label}
                    onClick={link.onClick}
                    isHighlighted={link.page === this.props.currentPage}
                >
                    <div>{link.label}</div>
                </NavElement>
            );
        });

        let pageSpecificElements = null;
        if (this.props.mainActionElements) {
            pageSpecificElements = this.props.mainActionElements(this.props.showButtonText).map((el, i) => (
                <PageSpecificElement key={i}>{el}</PageSpecificElement>
            ));
        }

        const highlightBurgerIcon = !inArray([Pages.Timeline, Pages.Albums], this.props.currentPage);
        const leftElements: React.ReactNode[] = [
            (
                <BurgerWrapper key={'burger_menu'} >
                    <SlideInBurgerMenu currentPage={this.props.currentPage} highlightIcon={highlightBurgerIcon}/>
                </BurgerWrapper>
            ),
            <CaptureLogoHorizontal key={'logo'} />,
        ];

        return (
            <ThreeAreasTopNavBar
                left={leftElements}
                middle={<NavElementsWrapper>{navElements}</NavElementsWrapper>}
                right={pageSpecificElements}
            />
        );
    }
}

const mapStateToProps = (state: State): StateProps => ({
    showButtonText: isLargerThanTablet(state),
});
const dispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    goTo: (page: PageDescription) => dispatch(NavigateTo(page)),
    doLogout: () => {logout(dispatch).then(goToOpenWeb); },
});

export const MainNavBarDesktop = connect<StateProps, DispatchProps, OwnProps, State>(
    mapStateToProps,
    dispatchToProps,
)(MainTopNavBar);
