import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

export const FullscreenIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path fill={props.color || colors.defaultIconColor} fillRule="nonzero" d="M6.28571429,14.2857143 L4,14.2857143 L4,20 L9.71428571,20 L9.71428571,17.7142857 L6.28571429,17.7142857 L6.28571429,14.2857143 Z M4,9.71428571 L6.28571429,9.71428571 L6.28571429,6.28571429 L9.71428571,6.28571429 L9.71428571,4 L4,4 L4,9.71428571 Z M17.7142857,17.7142857 L14.2857143,17.7142857 L14.2857143,20 L20,20 L20,14.2857143 L17.7142857,14.2857143 L17.7142857,17.7142857 Z M14.2857143,4 L14.2857143,6.28571429 L17.7142857,6.28571429 L17.7142857,9.71428571 L20,9.71428571 L20,4 L14.2857143,4 Z"/>
        </g>
    </svg>
);
