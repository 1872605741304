import {getAuthToken} from '../../API/externals';
import {Action, isType} from '../common/actions';
import {FileUploadSucceeded, FileWasAddedToUploadQueue, FileWasRejected} from '../uploader/actions';
import {RejectReason} from '../uploader/reducer';
import {ProfileNameChanged} from '../users/actions';
import {
    ConnectedDevicesWasFetched, ConnectedDeviceWasDeleted, CurrentWebVersionWasSeen, DeviceInfo,
    FetchedAccountInfo, FetchedAnonymousUserInfo, FetchedLastWebVersionSeen,
    FetchedTempPrintUserInfo, InvalidLoginStateDetected, JobSubscriptionsDetected, StartFetchingAccountInfo,
    UnableToFetchAccountInfo, UserLoggedOut, UserSubscribedToAlbum, UserUnsubscribedFromAlbum,
    ValidUserInfoRequestDeniedByUser, ValidUserInfoRequired,
} from './actions';

export type CurrentUserState = {
    isLoggingIn: boolean,
    isLoggedIn: boolean,
    haveFetchedAlbumList: boolean,
    lastWebVersionSeen?: number,
    userInfoRequired: {isRequired: boolean, reason?: string},
    accountInfo?: AccountInfo,
    fileSizesPending: {[index: number]: number|undefined},
    subscribedJobs: JobID[],
    connectedDevices: DeviceInfo[],
};

type CommonAccountInfo<T extends string> = {
    type: T
    name: string
    uuid: UserID
    auth_token: string,
};
type CaptureSpecificAccountInfo = {
    username: string,
    logged_in_as: string,
    kissmetrics_id: string,
    files_disabled: boolean,
    used_space: number,
    max_space: number,
    isBeingShutDown?: boolean,
};

export type AccountInfo
    = CommonAccountInfo<'anonymous'>
    | CommonAccountInfo<'print'> & {targetJob: JobID}
    | CommonAccountInfo<'capture'> & CaptureSpecificAccountInfo
    ;

// Gotcha: When loading the app, there is some time before the login-actions are dispatched.
// Anytime there is a stored authToken a login-process will be triggered. Respect and reflect that.
const isLoggingIn = getAuthToken() !== '';

const initialState: CurrentUserState = {
    isLoggingIn,
    isLoggedIn: false,
    haveFetchedAlbumList: false,
    lastWebVersionSeen: undefined,
    userInfoRequired: {isRequired: false},
    fileSizesPending: {},
    subscribedJobs: [],
    connectedDevices: [],
};

export function currentUserReducer(state: CurrentUserState = initialState, action: Action<any>): CurrentUserState {
    if (isType(action, StartFetchingAccountInfo)) {
        return {...state, isLoggingIn: true, isLoggedIn: false, accountInfo: undefined};
    }
    if (isType(action, UnableToFetchAccountInfo)) {
        return {...state, isLoggingIn: false};
    }
    if (isType(action, FetchedAccountInfo)) {
        return {...state, isLoggingIn: false, isLoggedIn: true, accountInfo: {...action.payload, type: 'capture'}};
    }
    if (isType(action, ProfileNameChanged)) {
        if (state.accountInfo) {
            return {
                ...state,
                accountInfo: {...state.accountInfo, name: action.payload.name},
            };
        }
    }
    if (isType(action, FetchedLastWebVersionSeen)) {
        return { ...state, lastWebVersionSeen: action.payload};
    }
    if (isType(action, CurrentWebVersionWasSeen)) {
        return { ...state, lastWebVersionSeen: action.payload };
    }
    if (isType(action, InvalidLoginStateDetected) || isType(action, UserLoggedOut)) {
        return {...state, isLoggedIn: false, accountInfo: undefined};
    }
    if (isType(action, ValidUserInfoRequired)) {
        return {...state, userInfoRequired: {isRequired: true, reason: action.payload}};
    }
    if (isType(action, ValidUserInfoRequestDeniedByUser)) {
        return {...state, userInfoRequired: {isRequired: false}};
    }

    if (isType(action, FetchedAnonymousUserInfo)) {
        return {...state, accountInfo: {...action.payload, type: 'anonymous'}};
    }

    if (isType(action, FetchedTempPrintUserInfo)) {
        return {...state, accountInfo: {
                    uuid: action.payload.uuid,
                    auth_token: action.payload.auth_token,
                    name: 'tempPrintUser',
                    type: 'print',
                    targetJob: action.payload.targetJob,
                },
        };
    }

    if (isType(action, JobSubscriptionsDetected)) {
        return {...state, subscribedJobs: action.payload, haveFetchedAlbumList: true};
    }
    if (isType(action, UserSubscribedToAlbum)) {
        return {...state, subscribedJobs: state.subscribedJobs.concat([action.payload])};
    }
    if (isType(action, UserUnsubscribedFromAlbum)) {
        return {...state, subscribedJobs: state.subscribedJobs.filter((id) => id !== action.payload)};
    }

    // Keep track of used space when uploading, deleting etc
    if (isType(action, FileWasAddedToUploadQueue)) {
        return {...state, fileSizesPending: {...state.fileSizesPending, [action.payload.id]: action.payload.size}};
    }
    if (isType(action, FileUploadSucceeded)) {
        let accInfo = state.accountInfo;
        if (accInfo && accInfo.type === 'capture') {
            accInfo = {...accInfo, used_space: action.payload.usedStorage};
        }
        return {
            ...state,
            accountInfo: accInfo,
            fileSizesPending: {...state.fileSizesPending, [action.payload.fileID]: undefined},
        };
    }
    if (isType(action, FileWasRejected) && action.payload.reason === RejectReason.NoStorage) {
        const accInfo = state.accountInfo;
        if (accInfo && accInfo.type === 'capture' && accInfo.used_space <= accInfo.max_space) {
            return {
                ...state,
                accountInfo: {...accInfo, used_space: accInfo.max_space + 1},
            };
        }
    }

    if (isType(action, ConnectedDevicesWasFetched)) {
        const devices = action.payload.devices.filter((d) => d.id !== action.payload.currentDeviceID);
        return {
            ...state,
            connectedDevices: devices,
        };
    }
    if (isType(action, ConnectedDeviceWasDeleted)) {
        const newState = {...state};
        const devices = newState.connectedDevices.filter((d) => d.id !== action.payload.deviceID);
        return {
            ...newState,
            connectedDevices: devices,
        };
    }

    return state;
}
