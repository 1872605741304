import {Action, isType} from '../common/actions';
import {
    FileUploadFailed, FileUploadProgress, FileUploadRetry, FileUploadStarted, FileUploadSucceeded,
    FileWasAcceptedToUploadQueue, FileWasAddedToUploadQueue, FileWasRejected, FileWasRemovedFromUploadQueue,
    UploaderPaused, UploaderResumed, UploaderStatusBoxCollapsed, UploaderStatusBoxDismissed, UploaderStatusBoxExpanded,
    UploaderStatusBoxShown, UploaderStopAborted, UploaderStopped, UploaderStopPrompted, UploadStatusListFiltered,
} from './actions';

export const enum UploaderOnlineStatus {
    Online, Retrying, Offline,
}

export const enum FileUploadStatus {
    Pending,
    Enqueued,
    Uploading,
    Succeeded,
    Rejected,
    Cancelled,
}

export enum RejectReason {
    FileIsFolder,
    NoStorage,
    UnSupported,
}

export type FileInformation = {
    id: number;
    targetJob: JobID;
    targetFolder: string;
    status: FileUploadStatus;
    statusReason?: RejectReason;
    uploadedPercent: number;
    name: string;
    size: number;
    fileUUID?: FileID; // ID of file from server when file is uploaded
    contentHash?: string;
};

export type UploaderState = {
    isStatusBoxExpanded: boolean;
    isStatusBoxVisible: boolean;
    isPaused: boolean;
    isPendingStop: boolean;
    onlineStatus: UploaderOnlineStatus;
    currentlyUploadingFiles: number[];
    filesByID: {[index: number]: FileInformation};
    isFiltered: boolean;
};

const initialState: UploaderState = {
    isStatusBoxExpanded: false,
    isStatusBoxVisible: false,
    isPaused: false,
    isPendingStop: false,
    onlineStatus: UploaderOnlineStatus.Online,
    currentlyUploadingFiles: [],
    filesByID: {},
    isFiltered: false,
};

const stoppableStates: FileUploadStatus[] = [FileUploadStatus.Enqueued, FileUploadStatus.Uploading];

export function uploaderReducer(state: UploaderState = initialState, action: Action<any>): UploaderState {

    if (isType(action, UploaderStatusBoxExpanded)) {
        return {...state, isStatusBoxExpanded: true};
    }
    if (isType(action, UploaderStatusBoxCollapsed)) {
        return {...state, isStatusBoxExpanded: false};
    }
    if (isType(action, UploaderStatusBoxShown)) {
        return {...state, isStatusBoxVisible: true};
    }
    if (isType(action, UploaderStatusBoxDismissed)) {
        return { ...state, isStatusBoxVisible: false };
    }
    if (isType(action, UploaderPaused)) {
        return {...state, isPaused: true};
    }
    if (isType(action, UploaderResumed)) {
        return {...state, isPaused: false};
    }

    if (isType(action, UploaderStopPrompted)) {
        return {...state, isPendingStop: true};
    }
    if (isType(action, UploaderStopAborted)) {
        return {...state, isPendingStop: false};
    }

    if (isType(action, UploaderStopped)) {
        const files: {[index: number]: FileInformation} = {};
        Object.keys(state.filesByID).map((id) => state.filesByID[parseInt(id, 10)]).forEach((file: FileInformation) => {
            if (stoppableStates.indexOf(file.status) >= 0 && file.uploadedPercent < 1) {
                file = {...file, status: FileUploadStatus.Cancelled};
            }
            files[file.id] = file;
        });
        return {...state, filesByID: files, isPendingStop: false, onlineStatus: UploaderOnlineStatus.Online};
    }

    if (isType(action, FileWasAddedToUploadQueue)) {
        const doneStatuses = [FileUploadStatus.Succeeded, FileUploadStatus.Cancelled, FileUploadStatus.Rejected];
        const allIsDone = state.currentlyUploadingFiles
            .reduce(
                (soFar: boolean, id: number) => soFar && doneStatuses.indexOf(state.filesByID[id].status) !== -1,
                true,
            );
        const currentlyUploading: number[] = allIsDone ? [] : state.currentlyUploadingFiles;

        const newFile: FileInformation = {
            id: action.payload.id,
            targetJob: action.payload.targetJob,
            targetFolder: action.payload.targetFolder || '',
            status: FileUploadStatus.Pending,
            uploadedPercent: 0,
            name: action.payload.name,
            size: action.payload.size,
        };

        return {
            ...state,
            filesByID: {...state.filesByID, [newFile.id]: newFile},
            currentlyUploadingFiles: currentlyUploading.concat([newFile.id]),
            isFiltered: false,
        };
    }

    if (isType(action, FileWasAcceptedToUploadQueue)) {
        return _updatedFile(state, action.payload.fileID, {status: FileUploadStatus.Enqueued});
    }

    if (isType(action, FileWasRemovedFromUploadQueue)) {
        if (stoppableStates.indexOf(state.filesByID[action.payload.fileID].status) >= 0) {
            return _updatedFile(state, action.payload.fileID, {status: FileUploadStatus.Cancelled});
        }
        return state;
    }

    if (isType(action, FileUploadProgress)) {
        const {percentComplete, fileID} = action.payload;
        const newState = _updatedFile(state, fileID, {uploadedPercent: percentComplete});
        // When the uploader retries and the file makes progress, the uploader is once again online
        if (action.payload.percentComplete > 0) {
            newState.onlineStatus = UploaderOnlineStatus.Online;
        }
        return newState;
    }

    if (isType(action, FileUploadStarted)) {
        return _updatedFile(state, action.payload.fileID, {status: FileUploadStatus.Uploading});
    }
    if (isType(action, FileUploadSucceeded)) {
        return _updatedFile(
            state,
            action.payload.fileID,
            {status: FileUploadStatus.Succeeded, fileUUID: action.payload.fileUUID},
        );
    }
    if (isType(action, FileWasRejected)) {
        if (action.payload.reason === RejectReason.NoStorage) {
            return _updatedFile(state, action.payload.fileID, {status: FileUploadStatus.Enqueued, uploadedPercent: 0});
        }
        return _updatedFile(
            state,
            action.payload.fileID,
            {status: FileUploadStatus.Rejected, statusReason: action.payload.reason},
        );
    }
    if (isType(action, FileUploadFailed)) {
        // If the file-upload fails due to the upload being canceled, no action should be taken (as this is expected)
        if (state.filesByID[action.payload.fileID].status === FileUploadStatus.Cancelled) {return state; }

        // When file upload fails it is due to network-issues:
        // Set the uploader to be in offline-mode and re-enqueue the file to have it retried when network returns
        return Object.assign(
            _updatedFile(state, action.payload.fileID, {status: FileUploadStatus.Enqueued, uploadedPercent: 0}),
            {onlineStatus: UploaderOnlineStatus.Offline},
        );
    }

    if (isType(action, UploadStatusListFiltered)) {
        return {...state, isFiltered: true};
    }

    if (isType(action, FileUploadRetry)) {
        if (state.onlineStatus === UploaderOnlineStatus.Offline) {
            return {...state, onlineStatus: UploaderOnlineStatus.Retrying};
        }
        return state;
    }

    return state;
}

function _updatedFile(state: UploaderState, fileID: number, updatedValues: DictionaryOf<any>): UploaderState {
    const allEqual = Object.keys(updatedValues)
        .map((key: string) => {
            const fileInfo: DictionaryOf<any> = state.filesByID[fileID];
            return fileInfo[key] === updatedValues[key];
        })
        .reduce((a: boolean, b: boolean) => a && b, true);
    if (allEqual) {return state; }

    const file = {...state.filesByID[fileID], ...updatedValues};
    return {...state, filesByID: {...state.filesByID, [fileID]: file}};
}
