import {sessionStorageGet, sessionStorageRemove} from '../utilities/webStorage';
 /**
 * The API Externals are elements that are considered app-constants, but needs to be loaded dynamically.
 * The values are stored in localStorage and may be updated by the fetchAccountInfo-method in ./login.ts
 * (as it is the method validating login-state and fetching these otherwise unchangeable values)
 */

export const getAuthToken = (): string => sessionStorageGet('authToken') || '';

interface ServiceDict {
    appHost: string;
    thumbHost: string;
    videoHost: string;
    pollHost: string;
}
export const getStoredServiceDict = (): ServiceDict|undefined => {
    const dictStr = sessionStorageGet('serviceDict');
    const dict = dictStr && JSON.parse(dictStr);
    if (dict && dict.appHost && dict.thumbHost && dict.videoHost && dict.pollHost) {
        return dict;
    }
};

// If the session was initiated as an extension of a legacy-session, the sourceHost is stored in localStorage.
// This allows to detect if the user came from a legacy-session and provide a link to send them back to.
export const getLegacyReturnUrl = (): string|undefined => {
    const legacyHost = sessionStorageGet('legacySourceHost');
    return legacyHost ? 'https://' + legacyHost : undefined;
};

// When the app opens the web from inside a web-view, it includes a return-url with captureapp__-prefix
export const isOpenInsideAppWebView = (): boolean => (
    /^captureapp__/.test(sessionStorageGet('legacySourceHost') || '')
);
export const getAppReturnLink = (): string|undefined => {
    if (isOpenInsideAppWebView()) {
        return (sessionStorageGet('legacySourceHost') as string).replace('captureapp__', 'capture-app://');
    }
};

export const clearLoginInfo = () => {
    sessionStorageRemove('authToken');
    sessionStorageRemove('serviceDict');
    sessionStorageRemove('legacySourceHost');
};
