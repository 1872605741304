import {keyframes} from 'styled-components';

export const colors = {
    captureBlue: '#0091D2',
    captureBlueRGB: '0, 145, 210',
    captureBlue50: '#E5F4FA',
    captureBlue100: '#B2DEF1',
    captureBlue200: '#66BDE4',
    captureBlue900: '#00496B',
    captureGreen: '#1BBC9B',
    captureGrey50: '#FAFAFA',
    captureGrey50_rgb: '250, 250, 250',
    captureGrey100: '#F5F5F5',
    captureGrey200: '#EEEEEE',
    captureGrey300: '#E0E0E0',
    captureGrey400: '#BDBDBD',
    captureGrey500: '#9E9E9E',
    captureGrey500rgb: '158, 158, 158',
    captureGrey600: '#757575',
    captureGrey800: '#424242',
    captureGrey800rgb: '66, 66, 66',
    captureMagenta: '#FF3F95',
    captureYellow: '#FFCD14',

    turquoise: '#0E93AB',
    lightTurquoise: '#3DC4DC',
    turquoise_rgb: '14, 147, 171',

    white_rgb: '255, 255, 255',
    greyBorder: 'rgba(151,151,151,0.3)',
    gradientGreen: '#86D6C6',

    darkBackground: '#191919',
    darkOverlay_rgb: 'rgba(0,0,0,0.5)',

    defaultIconColor: '#FFFFFF',
};

// Font stacks for use with fontFamily-attribute for styling
export const fonts = {
    Sriracha: '"Sriracha", cursive',
    Lato: 'Lato, Arial, sans-serif',
    Playfair: 'Playfair, serif',
};

export const fontSize = {
    xSmall_10: '10px',
    small_12: '12px',
    small_14: '14px',
    medium_16: '16px',
    medium_18: '18px',
    large_22: '22px',
    large_24: '24px',
    xLarge_32: '32px',
    xLarge_38: '38px',
    xLarge_48: '48px',
};

export const layout = {
    maxContainerWidth: 1200,
    topNavBarHeight: 56,
    selectionToolbarHeight: 40,
};

export const mediaBreakpoint__mobile = 768;
export const mediaBreakpoint__tabletLandscape = 1024;

export const mediaQueries = {
    mobile: `@media (max-width: ${mediaBreakpoint__mobile}px)`,
    tabletLandscape: `@media (max-width: ${mediaBreakpoint__tabletLandscape}px)`,
};

// animations
const makeFadeTo = (startOpacity: number, endOpacity: number) => keyframes`
    0% {
        opacity: ${startOpacity};
    }
    100% {
        opacity: ${endOpacity};
    }
`;
export const animations = {
    swingInTopFwd: keyframes`
        0% {
            transform: rotateX(-100deg);
            transform-origin: top;
            opacity: 0;
        }
        100% {
            transform: rotateX(0deg);
            transform-origin: top;
            opacity: 1;
        }
    `,
    fadeIn: makeFadeTo(0, 1),
    fadeOut: makeFadeTo(1, 0),
};
