import * as React from 'react';
import styled from 'styled-components';
import {fontSize} from '../../assets/styleConstants';
import {msToHMS} from '../../utilities/dateOperations';
import {PlayIconFilled} from '../Icons/PlayIcon';

const VideoIndicatorWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const DurationText = styled.span`
    color: #FFF;
    margin-left: 8px;
    font-size: ${fontSize.small_14};
    user-select: none;
`;

export const VideoIndicator: React.SFC<{duration: number, color?: string}> = (props) => (
    <VideoIndicatorWrapper>
        <PlayIconFilled size={24} color={props.color || '#FFF'} />
        <DurationText>{msToHMS(props.duration)}</DurationText>
    </VideoIndicatorWrapper>
);
