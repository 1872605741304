import * as React from 'react';
import styled from 'styled-components';
import {preventDefault} from '../../utilities/eventHandling';
import {Directory, getDirectoryStructFromDataTransfer} from './webkitFileSystem';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;
type Props = {
    fileHandler: (files: Folder) => any;
    whenDropOccur?: () => any;
};
export type Folder = Directory;
export const flattenFolder = (d: Folder): File[] => d.files.concat(...d.children.map(flattenFolder));

export class FileAcceptZone extends React.Component<Props, {}> {
    private handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        if (event.dataTransfer.items) { // if browser support directory
            getDirectoryStructFromDataTransfer(event.dataTransfer.items).then((dir) => {
                this.props.fileHandler(dir);
            });
        } else {
            const fileArr: File[] = [];
            for (let i = 0, l = event.dataTransfer.files.length; i < l; ++i) {
                fileArr.push(event.dataTransfer.files[i]);
            }
            this.props.fileHandler({name: '', files: fileArr, children: []});
        }

        if (this.props.whenDropOccur) {
            this.props.whenDropOccur();
        }
    }

    public render() {
        return (
            <Wrapper
                onDragOver={preventDefault}
                onDrop={this.handleDrop}
            >
                {this.props.children}
            </Wrapper>
        );
    }
}
