import * as React from 'react';
import {HOME_SITE_ADDRESS, LOGGED_IN_SITE_ADDRESS} from '../../../config/constants';
import {uuidToB64} from '../../common/utilities/uuid';

type Props = {
    params: {albumID?: JobID, albumIDB64?: string},
};

/**
 * Mount-point for AlbumReceiver-router-endpoints for redirecting,
 * accepting either a Base64-encoded or a PainText-encoded album-ID for album-pages or no param for index redirect
 * *
 * This should also be handled in the deployment-service for a direct HTTP-redirect,
 * but let's have a javascript-implemented fallback regardless.
 */

export class RedirectToWebComponent extends React.Component<Props> {
    private getRedirectTarget = () => {
        if (this.props.params.albumIDB64) {
            return `${LOGGED_IN_SITE_ADDRESS}/a/${this.props.params.albumIDB64}`;
        } else if (this.props.params.albumID) {
            return `${LOGGED_IN_SITE_ADDRESS}/a/${uuidToB64(this.props.params.albumID)}`;
        }
        return HOME_SITE_ADDRESS;
    }

    public componentDidMount() {
        window.location.replace(this.getRedirectTarget());
    }

    public render() {
        return null;
    }
};
