
export const enterFullscreen = (element: HTMLElement & FullscreenElement) => {
    // Standard
    if (!document.fullscreenElement && element.requestFullscreen) {
        element.requestFullscreen();
    }

    // Chrome & Opera & Edge & Safari
    if (!document.webkitFullscreenElement && element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    }

    // Firefox
    if (!document.mozFullScreenElement && element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    }

    // Internet Explorer 11
    if (!document.msFullscreenElement && element.msRequestFullscreen) {
        element.msRequestFullscreen();
    }
};

export const exitFullscreen = (element: HTMLElement) => {
    // Standard
    if (document.fullscreenElement && document.fullscreenElement === element) {
        document.exitFullscreen();
    }
    // Chrome & Opera & Edge & Safari
    if (document.webkitFullscreenElement && document.webkitFullscreenElement === element) {
        document.webkitExitFullscreen();
    }

    // Firefox
    if (document.mozFullScreenElement && document.mozCancelFullScreen && document.mozFullScreenElement === element) {
        document.mozCancelFullScreen();
    }

    // Internet Explorer 11
    if (document.msFullscreenElement && document.msExitFullscreen && document.msFullscreenElement === element) {
        document.msExitFullscreen();
    }
};

export const isFullscreenEnabled = (): boolean => {
    return !!(
        document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled
    );
};

export const getFullscreenElement = (): Element|undefined => {
    return (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement ||
        undefined // to replace null-value in IE11
    );
};

// DOM element in fullscreen
export const isInFullscreenMode = (): boolean => {
    return getFullscreenElement() !== undefined;
};

type FullscreenElement = {
    // Chrome & Opera & Edge & Safari
    webkitRequestFullscreen?: () => any;
    // Firefox
    mozRequestFullScreen?: () => any;
    // Internet Explorer 11
    msRequestFullscreen?: () => any;
};

export const subscribeFullscreenChange = (handler: () => any) => {
    document.addEventListener('webkitfullscreenchange', handler);
    document.addEventListener('mozfullscreenchange', handler);
    document.addEventListener('fullscreenchange', handler);
    document.addEventListener('MSFullscreenChange', handler);
};

export const unsubscribeFullscreenChange = (handler: () => any) => {
    document.removeEventListener('webkitfullscreenchange', handler);
    document.removeEventListener('mozfullscreenchange', handler);
    document.removeEventListener('fullscreenchange', handler);
    document.removeEventListener('MSFullscreenChange', handler);
};

export const isFullscreenVideo = (): boolean => {
    const element = getFullscreenElement();

    return element !== undefined && element.tagName.toLowerCase() === 'video';
};
