import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const OverflowIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <path id="overflow-icon-path-1" d="M12,2 C12,3.1 12.9,4 14,4 C15.1,4 16,3.1 16,2 C16,0.9 15.1,0 14,0 C12.9,0 12,0.9 12,2 M6,2 C6,3.1 6.9,4 8,4 C9.1,4 10,3.1 10,2 C10,0.9 9.1,0 8,0 C6.9,0 6,0.9 6,2 M4,2 C4,0.9 3.1,0 2,0 C0.9,0 -7.10542736e-15,0.9 -7.10542736e-15,2 C-7.10542736e-15,3.1 0.9,4 2,4 C3.1,4 4,3.1 4,2"/>
            <filter id="overflow-icon-filter-2" x="-112.5%" y="-28.1%" width="325.0%" height="156.2%" filterUnits="objectBoundingBox">
                <feOffset dx="0" dy="0" in="SourceAlpha" result="overflow-icon-shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="1.5" in="overflow-icon-shadowOffsetOuter1" result="overflow-icon-shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0" type="matrix" in="overflow-icon-shadowBlurOuter1"></feColorMatrix>
            </filter>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(4.000000, 10.000000)">
                <use fill={props.shadowColor || 'none'} fillOpacity="1" filter="url(#overflow-icon-filter-2)" xlinkHref="#overflow-icon-path-1"></use>
                <use fill={props.color || 'black'} fillRule="evenodd" xlinkHref="#overflow-icon-path-1"></use>
            </g>
        </g>
    </svg>
);

export const OverflowIconOutlined: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g strokeWidth="1" fill="none"  stroke={props.color || colors.captureGrey800} fillRule="nonzero" transform="translate(4.000000, 10.000000)">
            <path d="M12,2 C12,3.1 12.9,4 14,4 C15.1,4 16,3.1 16,2 C16,0.9 15.1,0 14,0 C12.9,0 12,0.9 12,2 Z M6,2 C6,3.1 6.9,4 8,4 C9.1,4 10,3.1 10,2 C10,0.9 9.1,0 8,0 C6.9,0 6,0.9 6,2 Z M4,2 C4,0.9 3.1,0 2,0 C0.9,0 0,0.9 0,2 C0,3.1 0.9,4 2,4 C3.1,4 4,3.1 4,2 Z"/>
        </g>
    </svg>
);
