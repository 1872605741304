import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets/styleConstants';

type BurgerIconProps = {
    isExpanded: boolean,
    iconColor?: string,
};
const Wrapper = styled.div`
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

const IconWrapper = styled.span`
    width: 18px;
    height: 10px;
    text-decoration: none;
    display: inline-block;
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
`;

const LineBase = styled.div`
    position: absolute;
    outline: 1px solid ${(props: {color: string}) => props.color};
`;

const TopLine = styled(LineBase)`
    top: 0;
    width: 100%;
    transform: ${(props: {isExpanded: boolean}) => props.isExpanded ?
        'rotateZ(45deg) translate(4px, 4px)' : 'rotateZ(0deg) translate(0px, 0px)'};
    transition: transform 0.2s;
`;

const MidLine = styled(LineBase)`
    top: 5px;
    width: ${(props: {isExpanded: boolean}) => props.isExpanded ?  0 : 18}px;
    left: ${(props) => props.isExpanded ?  '12px' : 0};
    opacity: ${(props) => props.isExpanded ?  0 : 1};
    transition: width 0.2s, left 0.2s, opacity 0.1s;
`;

const BottomLine = styled(LineBase)`
    top: 10px;
    width: 100%;
    transform: ${(props: {isExpanded: boolean}) => props.isExpanded ?
        'rotateZ(-45deg) translate(3px, -3px)' : 'rotateZ(0deg) translate(0px, 0px)'};
    transition: transform 0.2s;
`;

export class BurgerIconAnimated extends React.Component<BurgerIconProps> {

    public render() {
        const lineColor = this.props.iconColor || colors.captureGrey800;

        return (
            <Wrapper>
                <IconWrapper>
                    <TopLine isExpanded={this.props.isExpanded} color={lineColor}/>
                    <BottomLine isExpanded={this.props.isExpanded} color={lineColor}/>
                    <MidLine isExpanded={this.props.isExpanded} color={lineColor}/>
                </IconWrapper>
            </Wrapper>
        );

    }
}
