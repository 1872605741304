import * as React from 'react';
import styled from 'styled-components';
import {HeaderText, SubHeaderText} from './StandardPageElements';

const PageWrapper = styled.div`
    height: 100%;
    padding: 0 16px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const ContentWrapper = styled.div`
    padding: 24px 0;
`;

type PageProps = {
    header?: React.ReactNode,
    subHeader?: React.ReactNode,
    illustration?: React.ReactNode,
};

export const EmptyStatePage: React.SFC<PageProps> = ({header, subHeader, illustration, children}) => (
    <PageWrapper>
        <div>{illustration}</div>
        {header && <HeaderText>{header}</HeaderText>}
        {subHeader && <SubHeaderText>{subHeader}</SubHeaderText>}
        <ContentWrapper>{children}</ContentWrapper>
    </PageWrapper>
);
