import {createHistory, History} from 'history';
import {useRouterHistory} from 'react-router';
import * as Pages from './pages';
import {RoutePath} from './routePath';

let browserHistory: History;
const getBrowserHistory = (): History => {
    if (!browserHistory) {
        browserHistory = useRouterHistory(createHistory)({basename: __BASEURL__});
        // Replace location in router to allow navigating back to this page when using Chrome iOS (CAPWEB-361)
        browserHistory.replace(location.pathname.replace(__BASEURL__, '') + location.search + location.hash);
    }
    return browserHistory;
};

export {
    getBrowserHistory,
    Pages,
    RoutePath,
};
