import {Middleware} from 'redux';
import {loadTrashContent} from '../../API/job';
import {BulkOfActions, isType} from '../common/actions';
import {FilesDeletionSucceeded} from '../job/actions';
import {State} from '../store';
import {getTimelineJobID} from '../timeline/selectors';
import {FetchMoreTrashTriggered, TrashContentOutdated} from './actions';
import {getTrashFilesNextResultOffset} from './selectors';

export const trashMiddleware: Middleware = (store) => (next) => (action) => {
        const state: State = store.getState();
        const timelineID = getTimelineJobID(state);

        if (isType(action, BulkOfActions)) {
            const filesDeletedAction = action.payload.filter((a) => isType(a, FilesDeletionSucceeded))[0];
            if (filesDeletedAction && filesDeletedAction.payload.jobID === timelineID) {
                store.dispatch(TrashContentOutdated());
            }
        }

        // if deletion comes separate from BulkOfActions
        if (isType(action, FilesDeletionSucceeded) && action.payload.jobID === timelineID) {
            store.dispatch(TrashContentOutdated());
        }

        if (isType(action, FetchMoreTrashTriggered)) {
            const offset = getTrashFilesNextResultOffset(state);
            loadTrashContent(store.dispatch, offset);
        }

        next(action);
};
