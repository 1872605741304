import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {trackEvent} from '../../analytics';
import {
    deleteMultipleTrashFiles,
    loadTrashContent,
    restoreMultipleFiles,
} from '../../API/job';
import {_, getStringWithAmount} from '../../assets/localizedStrings';
import {colors, layout} from '../../assets/styleConstants';
import {Pages} from '../../routing/index';
import {State} from '../../state/store';
import {getTimelineJobID} from '../../state/timeline/selectors';
import {
    FetchMoreTrashTriggered,
    TrashAllFilesDeSelected,
    TrashAllFilesSelected,
    TrashFileDeSelected,
    TrashFileSelected,
} from '../../state/trash/actions';
import {
    getSelectedTrashFiles,
    getTrashFilesForJob,
    hasMoreTrashFilesToFetch,
    isLoadingTrash,
    shouldTrashBeFetched,
    TrashFile,
} from '../../state/trash/selectors';
import {isMobileMode} from '../../state/viewMode/selectors';
import {Button} from '../Common/Button';
import {ConfirmPromptOverlay} from '../Common/DialoguePromptOverlay';
import {EmptyStatePage} from '../Common/EmptyStatePage';
import {OverflowMenu} from '../Common/OverflowMenu';
import {PageWrapper} from '../Common/PageWrapper';
import {RippleLoader, RippleLoaderPage} from '../Common/RippleLoader';
import {HeaderText} from '../Common/StandardPageElements';
import {CheckFilledIcon} from '../Icons/CheckIcon';
import {DeleteIcon} from '../Icons/DeleteIcon';
import {RefreshIcon} from '../Icons/RefreshIcon';
import {TrashIllustration} from '../Icons/TrashIllustration';
import {MainNavBarDesktop} from '../Navigation/MainNavBarDesktop';
import {TopNavBarMobile} from '../Navigation/TopNavBarMobile';
import {
    SelectionToolbarDesktop,
    SelectionToolbarMobile,
} from '../Timeline/SelectionToolBar';
import {TrashList} from './TrashList';

const ContentWrapper = styled.div`
    height: 100%;
    max-width: ${layout.maxContainerWidth}px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
`;

const Header = HeaderText.extend`
    margin-top: ${layout.selectionToolbarHeight}px;
    text-transform: capitalize;
`;
const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

type PromptType = 'restore' | 'delete';
type PagePrompt = {
    type: PromptType;
    text: string;
    targets: FileID[];
};

const pagePrompts: Record<PromptType, (targets: FileID[]) => PagePrompt> = {
    restore: (targets) => ({
        type: 'restore',
        text: getStringWithAmount(
            targets.length,
            _('restore_single_file_prompt'),
            _('restore_files_prompt__format'),
        ),
        targets,
    }),
    delete: (targets) => ({
        type: 'delete',
        text: getStringWithAmount(
            targets.length,
            _('permanently_delete_single_file_prompt'),
            _('permanently_delete_files_prompt__format'),
        ),
        targets,
    }),
};

type StateProps = {
    isMobileMode: boolean;
    files: TrashFile[];
    hasMoreToFetch: boolean;
    selectedFiles: FileID[];
    isTrashLoading: boolean;
    shouldTrashBeFetched: boolean;
    timelineID?: JobID;
};

type DispatchProps = {
    fetchTrash: () => void;
    deleteFiles: (files: FileID[]) => void;
    restoreFiles: (jobID: JobID, files: FileID[]) => void;
    onFileSelected: (fileID: FileID) => any;
    onFileDeSelected: (fileID: FileID) => any;
    onSelectAll: (jobID: JobID) => any;
    onDeSelectAll: () => any;
    cancelSelectMode: () => void;
    doFetchMoreTrashItems: () => any;
};

type Props = StateProps & DispatchProps;

type ComponentState = {
    prompt?: PagePrompt;
    isInSelectMode: boolean;
};

class TrashPageComponent extends React.Component<Props, ComponentState> {
    public state: ComponentState = {
        prompt: undefined,
        isInSelectMode: false,
    };
    private contentElement = React.createRef<HTMLDivElement>();

    private selectionButtons = [
        Button(
            _('restore'),
            () => this.openPrompt('restore', this.props.selectedFiles),
            {icon: RefreshIcon},
        ),
        Button(
            _('delete_'),
            () => this.openPrompt('delete', this.props.selectedFiles),
            {icon: DeleteIcon},
        ),
    ];

    private startSelectingFiles = () => {
        this.setState((s) => ({...s, isInSelectMode: true}));
    }

    private cancelSelecting = () => {
        this.setState((s) => ({...s, isInSelectMode: false}));
        this.props.cancelSelectMode();
    }

    private checkIfAllFilesSelected = () => {
        return (
            this.props.files.length > 0 &&
            this.props.files.length === this.props.selectedFiles.length
        );
    }

    private toggleSelectAll = () => {
        if (this.checkIfAllFilesSelected()) {
            this.props.onDeSelectAll();
        } else if (this.props.timelineID) {
            this.props.onSelectAll(this.props.timelineID);
        }
    }

    private openPrompt = (type: PromptType, target: FileID[]) => {
        this.setState((s) => ({
            ...s,
            prompt: pagePrompts[type](target),
        }));
    }

    private openPromptForSingleFile = (
        prompt: PromptType,
        targetFile: FileID,
    ) => {
        this.openPrompt(prompt, [targetFile]);
    }

    private confirmPrompt = () => {
        if (this.state.prompt && this.props.timelineID) {
            const {type, targets} = this.state.prompt;
            switch (type) {
                case 'restore':
                    this.props.restoreFiles(this.props.timelineID, targets);
                    break;
                case 'delete':
                    this.props.deleteFiles(targets);
                    break;
            }
            if (this.props.hasMoreToFetch) {
                this.props.doFetchMoreTrashItems();
            }
            this.props.cancelSelectMode();
            this.closePrompt();
        }
    }

    private closePrompt = () => {
        this.setState((s) => ({...s, prompt: undefined}));
    }

    private getMobileMenuItems = () => {
        const options = [
            Button(_('select_files'), this.startSelectingFiles, {
                icon: CheckFilledIcon,
            }),
        ];
        return [<OverflowMenu key={'overflow'} menuOptions={options} />];
    }

    public componentWillMount() {
        this.props.shouldTrashBeFetched && this.props.fetchTrash();
        window.addEventListener('scroll', this.isReachingBottomOfList);
    }
    public componentWillUnmount() {
        this.props.cancelSelectMode();
        window.removeEventListener('scroll', this.isReachingBottomOfList);
    }

    public componentWillReceiveProps(newProps: Props) {
        const isInSelectMode = newProps.selectedFiles.length > 0;
        if (
            this.props.selectedFiles.length !== newProps.selectedFiles.length &&
            isInSelectMode !== this.state.isInSelectMode
        ) {
            this.setState((s) => ({...s, isInSelectMode}));
        }
    }

    private isReachingBottomOfList = () => {
        if (
            !this.props.isTrashLoading &&
            this.contentElement.current &&
            this.contentElement.current.scrollHeight -
                window.innerHeight -
                window.pageYOffset <
                1000 &&
            this.props.hasMoreToFetch
        ) {
            trackEvent('Trash', 'fetchedMoreTrashItems');
            this.props.doFetchMoreTrashItems();
        }
    }

    public render() {
        const Toolbar = this.props.isMobileMode
            ? SelectionToolbarMobile
            : SelectionToolbarDesktop;
        const toolbarComp = this.state.isInSelectMode && (
            <Toolbar
                buttons={this.selectionButtons}
                selectedFiles={this.props.selectedFiles}
                cancelSelectMode={this.cancelSelecting}
            />
        );

        const navBarDesktop = <MainNavBarDesktop currentPage={Pages.Trash} />;
        const navBarMobile = !this.state.isInSelectMode && (
            <TopNavBarMobile
                currentPage={Pages.Trash}
                rightElements={this.getMobileMenuItems}
            />
        );

        let content;
        if (
            (this.props.files.length === 0 && this.props.isTrashLoading) ||
            this.props.timelineID === undefined
        ) {
            content = <RippleLoaderPage />;
        } else if (this.props.files.length === 0 && this.props.timelineID) {
            const header = _('trash_no_files');
            const subHeader = (
                <div>
                    <div>{_('trash_delete_info')}</div>
                    <div>{_('trash_restore_info')}</div>
                </div>
            );
            content = (
                <EmptyStatePage
                    illustration={
                        <TrashIllustration
                            color={colors.captureBlue}
                            size={100}
                        />
                    }
                    header={header}
                    subHeader={subHeader}
                />
            );
        } else {
            content = (
                <TrashList
                    isMobile={this.props.isMobileMode}
                    isInSelectMode={this.state.isInSelectMode}
                    files={this.props.files}
                    allFilesSelected={this.checkIfAllFilesSelected()}
                    onFileSelected={this.props.onFileSelected}
                    onFileDeSelected={this.props.onFileDeSelected}
                    onToggleSelectAll={this.toggleSelectAll}
                    onTriggerConfirmPrompt={this.openPromptForSingleFile}
                />
            );
        }

        const prompt = this.state.prompt && (
            <ConfirmPromptOverlay
                onConfirm={this.confirmPrompt}
                onCancel={this.closePrompt}
                confirmText={this.state.prompt.type === 'restore' ? _('restore') : _('delete_')}
            >
                {this.state.prompt.text}
            </ConfirmPromptOverlay>
        );

        return (
            <PageWrapper
                navBar={this.props.isMobileMode ? navBarMobile : navBarDesktop}
            >
                <ContentWrapper innerRef={this.contentElement}>
                    {!this.props.isMobileMode && (
                        <Header>{_('deleted_items')}</Header>
                    )}
                    {content}
                    {this.props.hasMoreToFetch && (
                        <LoaderWrapper>
                            <RippleLoader />
                        </LoaderWrapper>
                    )}
                </ContentWrapper>
                {toolbarComp}
                {prompt}
            </PageWrapper>
        );
    }
}

const mapStateToProps = (state: State): StateProps => {
    const timelineID = getTimelineJobID(state);
    return {
        isMobileMode: isMobileMode(state),
        files: timelineID ? getTrashFilesForJob(state, timelineID) : [],
        hasMoreToFetch: hasMoreTrashFilesToFetch(state),
        selectedFiles: getSelectedTrashFiles(state),
        isTrashLoading: isLoadingTrash(state),
        shouldTrashBeFetched: shouldTrashBeFetched(state),
        timelineID,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    fetchTrash: () => loadTrashContent(dispatch),
    deleteFiles: (files: FileID[]) => deleteMultipleTrashFiles(dispatch, files),
    restoreFiles: (jobID: JobID, files: FileID[]) =>
        restoreMultipleFiles(dispatch, jobID, files),
    onFileSelected: (fileID: FileID) => dispatch(TrashFileSelected(fileID)),
    onFileDeSelected: (fileID: FileID) => dispatch(TrashFileDeSelected(fileID)),
    onSelectAll: (jobID: JobID) => dispatch(TrashAllFilesSelected({jobID})),
    onDeSelectAll: () => dispatch(TrashAllFilesDeSelected()),
    cancelSelectMode: () => dispatch(TrashAllFilesDeSelected()),
    doFetchMoreTrashItems: () => dispatch(FetchMoreTrashTriggered()),
});

export const TrashPage = connect(mapStateToProps, mapDispatchToProps)(
    TrashPageComponent,
);
