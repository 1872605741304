import * as React from 'react';
import styled from 'styled-components';
import {colors, fontSize} from '../../assets/styleConstants';
import {OverflowIcon} from '../Icons/OverflowIcon';
import {ButtonProps} from './Button';
import {withClickOutsideHandling} from './HandleClickOutsideHOC';
import {IconButton} from './IconTextButton';
import {ButtonWithExpandingContent, Position} from './Positioning';

const MenuWrapper = withClickOutsideHandling(styled.div`
    position: absolute;
    ${(props: {position: Position}) => props.position.v === 'bottom' ? 'top: -24px' : 'bottom: -24px'};
    ${(props) => props.position.h === 'left' ? 'left: 0' : 'right: 0'};
    background: white;
    width: 200px;
    box-shadow: rgba(0,0,0,0.3) 1px 2px 5px;
    padding: 8px 0;
`);

const activeColor = colors.captureGrey800;
const inactiveColor = colors.captureGrey400;

const OptionDiv = styled.div`
    width: 100%;
    padding: 8px 16px;
    box-sizing: border-box;
    cursor: ${(props: {isActive: boolean}) => props.isActive ? 'pointer' : 'default'};
    color: ${(props) => props.isActive ? activeColor : inactiveColor};
    text-align: left;

    display: flex;
    align-items: center;

    &:hover {
        background-color: ${(props) => props.isActive ? colors.captureGrey100 : 'inherit'};
    }
`;

const IconDiv = styled.div`
    width: 16px;
    height: 16px;
    margin-right: 8px;
    display: inherit;
`;

const TextDiv = styled.div`
    font-size: ${fontSize.small_14};
    line-height: 16px;
`;

type Props = {
    menuOptions: ButtonProps[],
    showIcons?: boolean,
    iconColor?: string,
    shadowColor?: string,
    hoverColor?: string,
};

type MenuProps = Props & {
    doCollapse: () => any,
    isExpanded: boolean,
    position: Position,
};
class Menu extends React.Component<MenuProps> {
    private handleOptionClick = (opt: ButtonProps) => {
        opt.onClick();
        this.props.doCollapse();
    }

    public render() {
        const optionsList = this.props.menuOptions.map((opt, i) => {
            const icon = opt.icon ? <opt.icon size={16} color={opt.isActive ? activeColor : inactiveColor} /> : null;
            const clickEvent = opt.isActive ? () => this.handleOptionClick(opt) : undefined;

            return (
                <OptionDiv
                    key={i}
                    onClick={clickEvent}
                    isActive={opt.isActive}
                >
                    {this.props.showIcons !== false ? <IconDiv>{icon}</IconDiv> : null}
                    <TextDiv>{opt.text}</TextDiv>
                </OptionDiv>
            );
        });
        if (!this.props.isExpanded) {
            return null;
        }
        return (
            <MenuWrapper onClickOutside={this.props.doCollapse} position={this.props.position}>
                {optionsList}
            </MenuWrapper>

        );
    }
}

const ExpandableOverflowMenu = (position: Position) => ButtonWithExpandingContent(position)
<Props, Pick<Props, 'menuOptions'>>(
    ({toggleExpand, iconColor, shadowColor, hoverColor}) => (
        <IconButton
            onClick={toggleExpand}
            icon={OverflowIcon}
            color={iconColor}
            shadowColor={shadowColor}
            hoverColor={hoverColor}
        />
    ),
    ({doCollapse, isExpanded, menuOptions}) => (
        <Menu
            position={position}
            menuOptions={menuOptions}
            doCollapse={doCollapse}
            isExpanded={isExpanded}
        />
    ),
);

export const OverflowMenu = ExpandableOverflowMenu({v: 'bottom', h: 'right'});
export const OverflowMenuExpandingUpwards = ExpandableOverflowMenu({v: 'top', h: 'right'});
