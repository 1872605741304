import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets';
import {getStringWithAmount} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {DateRange, maxAlbumContainerWidth} from '../../state/album/selectors';
import {UserInfo} from '../../state/users/selectors';
import {Avatar} from '../Common/Avatar';
import {Bullet} from '../Common/Bullet';
import {
    getFilesDateRangeString,
    localizedDateStringShort,
} from '../Common/TimeStrings';

const Wrapper = styled.div`
    max-width: ${maxAlbumContainerWidth}px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
`;

const Owner = styled.div`
    text-transform: uppercase;
    font-size: ${fontSize.small_12};
    letter-spacing: 2.25px;
`;

const AvatarWrapper = styled.div`
    height: 26px;
    margin-bottom: 6px;
    color: ${colors.captureGrey800};
`;

type ViewModeProps = {
    mobileMode: boolean;
};

const Title = styled.h1`
    font-weight: normal;
    font-size: ${(props: ViewModeProps) =>
        props.mobileMode ? fontSize.large_24 : fontSize.xLarge_32};
    margin: 16px 0 12px;
`;

const AlbumMetaData = styled.div`
    font-size: ${(props: ViewModeProps) =>
        props.mobileMode ? fontSize.small_14 : fontSize.medium_16};
    & > span {
        white-space: nowrap;
    }
`;

const ParticipantsText = styled.span`
    cursor: pointer;
`;

type Props = {
    albumTitle: string;
    dateCreated: Date;
    filesDateRange?: DateRange;
    itemCount: number;
    subscribersCount: number;
    owner: UserInfo;
    mobileMode: boolean;
    doShowParticipantsList?: () => any;
};

export class AlbumHeaderComponent extends React.Component<Props> {
    public render() {
        const itemString = getStringWithAmount(
            this.props.itemCount,
            _('item__format'),
            _('items__format'),
        );
        const participantText = getStringWithAmount(
            this.props.subscribersCount,
            _('participant__format'),
            _('participants__format'),
        );
        const participantsElement = this.props.subscribersCount > 0 && [
            <Bullet key="bullet" color={colors.captureGrey400} />,
            (
            <ParticipantsText key="text" onClick={this.props.doShowParticipantsList}>
                {participantText}
            </ParticipantsText>
            ),
        ];

        let dateString;
        if (this.props.filesDateRange) {
            const {start, end} = this.props.filesDateRange;
            dateString = getFilesDateRangeString(start, end);
        } else {
            dateString = localizedDateStringShort(this.props.dateCreated);
        }

        return (
            <Wrapper id="album-header">
                <Owner>
                    <AvatarWrapper>
                        <Avatar
                            size={26}
                            name={this.props.owner.name}
                            img={this.props.owner.profilePicture}
                        />
                    </AvatarWrapper>
                    <div>
                        {_('by')} {this.props.owner.name}
                    </div>
                </Owner>
                <Title mobileMode={this.props.mobileMode}>
                    {this.props.albumTitle}
                </Title>
                <AlbumMetaData mobileMode={this.props.mobileMode}>
                    <span>{dateString}</span>
                    <Bullet color={colors.captureGrey400} />
                    <span data-cy="photoCount">{itemString}</span>
                    {participantsElement}
                </AlbumMetaData>
            </Wrapper>
        );
    }
}
