import * as React from 'react';
import styled, {keyframes} from 'styled-components';
import {animations, colors} from '../../assets/styleConstants';
import {HeartIcon, HeartIconFilled} from '../Icons/HeartIcon';

type LoveButtonProps = {
    loved: boolean,
    size: number,
    onAnimationEnd: () => void,
    onLoveChanged: (ifLove: boolean) => void,
    color?: string,
};

type LoveButtonState = {
    isAnimating: boolean,
};

const Container = styled.div`
    position: relative;
    width: ${(props: {size: number}) => props.size}px;
    height: ${(props) => props.size}px;
    cursor: pointer;
    transform: translate(-3px, 0);
`;

const rippleEffect = keyframes`
    0% {
        opacity: 1;
        width: 10%;
        height: 10%;
    }
    100% {
        opacity: 0;
        width: 160%;
        height: 160%;
    }
`;

const heartPumpEffect = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1.5);
    }
`;

type RippleDivProps = {size: number, color?: string};
const RippleAnimation = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    width: ${(props: RippleDivProps) => props.size}px;
    height: ${(props) => props.size}px;

    border: 2px solid ${(props) => props.color || colors.captureMagenta};
    border-radius: 50%;

    opacity: 0;
    transform: translate(-50%, -50%);

    animation-name: ${rippleEffect};
    animation-delay: 0.1s;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
`;

const HeartWrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
`;

const HeartFilledAnimationWrapper = styled(HeartWrapper)`
    svg {
        opacity: 1;
        animation-name: ${animations.fadeIn};
        animation-duration: 1.5s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
    }
`;

const HeartAnimationWrapper = styled(HeartWrapper)`
    svg {
        opacity: 0;
        animation-name: ${heartPumpEffect};
        animation-duration: 0.8s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
    }
`;

const LoveAnimation: React.SFC<{size: number, onAnimationEnd: () => any, color?: string}> = (props) => (
    <div>
        <RippleAnimation onAnimationEnd={props.onAnimationEnd} size={props.size} color={props.color}/>
        <HeartAnimationWrapper>
            <AnimatedHeartIcon size={props.size} isFilled={false} color={props.color}/>
        </HeartAnimationWrapper>
        <HeartFilledAnimationWrapper>
            <AnimatedHeartIcon size={props.size} isFilled={true} color={props.color}/>
        </HeartFilledAnimationWrapper>
    </div>
);

const AnimatedHeartIcon: React.SFC<{size: number, isFilled: boolean, color?: string}> = (props) => {
    if (props.isFilled) {
        return <HeartIconFilled size={props.size} color={props.color || colors.captureMagenta} />;
    } else {
        return <HeartIcon size={props.size} color={props.color || colors.captureGrey800} />;
    }
};

export class LoveButton extends React.Component<LoveButtonProps, LoveButtonState> {
    public state: LoveButtonState = {
        isAnimating: false,
    };

    public shouldComponentUpdate(): boolean {
        // do not re-render during animation
        return this.state.isAnimating !== true;
    }

    public render() {
        const {loved, size} = this.props;

        let content: React.ReactNode = null;

        if (this.state.isAnimating) {
            content = (
                <LoveAnimation
                    size={size}
                    onAnimationEnd={this.handleLoveAnimationEnd}
                    color={this.props.color}
                />
            );
        } else {
            content = <AnimatedHeartIcon size={size} isFilled={loved} color={this.props.color}/>;
        }

        return (
            <Container
                size={size}
                onClick={this.handleClick}
            >
                {content}
            </Container>
        );
    }

    private handleLoveAnimationEnd = () => {
        this.props.onAnimationEnd();

        this.setState({
            isAnimating: false,
        });
    }

    private handleClick = () => {
        if (!this.state.isAnimating) {
            this.props.onLoveChanged(!this.props.loved);

            if (!this.props.loved) {
                this.setState({
                    isAnimating: true,
                });
            }
        }
    }
}
