import * as React from 'react';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {FilterIcon} from '../Icons/FilterIcon';
import {ChoiceMenu} from './ChoiceMenu';
import {IconButton, TextButton} from './IconTextButton';
import {ButtonWithBottomRightExpandingContent} from './Positioning';
import {RadioButtonGroup, RadioButtonOption} from './RadioButtonGroup';

type Props<T> = {
    buttonText: string,
    current: T,
    options: Array<RadioButtonOption<T>>
    onSelect: (filter: T) => any,
    isFilterApplied: boolean,
    showText?: boolean,
};

type FilterContentProps = {isExpanded: boolean, doCollapse: () => void};
type FilterContentType<T> = React.SFC<FilterContentProps & Props<T>>;
const makeFilterContent = <T extends {}>(): FilterContentType<T> => (props) => {
    if (!props.isExpanded) {
        /* If ChoiceMenu are mounted while not expanded, the clickOutside will auto-collapse it on expand */
        return null;
    }
    return (
        <ChoiceMenu title={_('filter_by')} onClickOutside={props.doCollapse}>
            <RadioButtonGroup
                value={props.current}
                options={props.options}
                onSelectOption={(val: T) => {props.onSelect(val); setTimeout(props.doCollapse, 200); }}
            />
        </ChoiceMenu>
    );
};

// Must be a method to allow the generic type T. Maybe add options as a static variable?
export const FilterTextButton = <T extends {}> () => ButtonWithBottomRightExpandingContent<Props<T>, Props<T>>(
    ({toggleExpand, isExpanded, buttonText, isFilterApplied}) => (
        <TextButton
            onClick={toggleExpand}
            text={buttonText}
            color={isExpanded || isFilterApplied ? colors.captureBlue : ''}
        />
    ),
    makeFilterContent<T>(),
);

export const FilterIconButton = <T extends {}> () => ButtonWithBottomRightExpandingContent<Props<T>, Props<T>>(
    ({toggleExpand, isExpanded, isFilterApplied}) => (
        <IconButton
            onClick={toggleExpand}
            icon={FilterIcon}
            color={isExpanded || isFilterApplied ? colors.captureBlue : ''}
            hoverColor={colors.captureBlue}
        />
    ),
    makeFilterContent<T>(),
);
