import {Action, isType} from '../common/actions';
import {UserInfoWasFetched} from './actions';

export type User = {
    userID: UserID,
    name: string,
    email?: string,
    profilePicture?: string,
};

export type UsersState = DictionaryOf<User>;

export const usersReducer = (state: UsersState = {}, action: Action<any>): UsersState => {

    if (isType(action, UserInfoWasFetched)) {
        return {
            ...state,
            ...action.payload.reduce((o, user) => {o[user.userID] = user; return o; }, {} as any),
        };
    }

    return state;
};
