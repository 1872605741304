import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

export const CommentIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path stroke={props.color || colors.defaultIconColor} strokeWidth="2" strokeLinejoin="round" d="M21.6083272,11.42805 C21.6083272,15.5304667 17.5328272,18.8554667 12.5041606,18.8554667 C11.3823689,18.8554667 10.3072856,18.6900083 9.31453556,18.3875917 L4.08241056,20.8219667 L6.1312439,16.7322167 C4.44578556,15.3840083 3.3999939,13.506175 3.3999939,11.42805 C3.3999939,7.32563333 7.4754939,3.99984167 12.5041606,3.99984167 C17.5328272,3.99984167 21.6083272,7.32563333 21.6083272,11.42805 L21.6083272,11.42805 Z"/>
        </g>
    </svg>
);
