import * as React from 'react';
import styled from 'styled-components';
import {slideBarPaginationThumbs} from '.';
import {ThumbFile} from '../../state/carouselViewer/selectors';
import {isMobileDevice} from '../../utilities/device';
import {NextArrowButton} from '../Common/NextButton';
import {PrevArrowButton} from '../Common/PreviousButton';
import {makeScrollAnimation, ScrollAnimationFunc} from './scrollAnimation';
import {SlideBarThumbList} from './SlideBarThumbList';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

const SliderWrapper = styled.div`
    position: relative;
    overflow: hidden;
`;

const SliderContainer = styled.div`
    width: ${(props: {width: number}) => props.width}px;

    overflow-x: auto;
    overflow-y: hidden;

    /* hide scrollbar */
    margin-bottom: -18px;
    padding-top: 4px;

`;

const NavBtn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    cursor: pointer;
`;

type SlideBarProps = {
    thumbFiles: ThumbFile[]
    currentThumbIndex: number,
    thumbStyle: {
        size: number,
        spaceBetween: number,
    },
    navBtnStyle: {
        width: number,
        height: number,
    },
    onClickThumbImage: (fileID: FileID) => any,
    showNavArrows: boolean,
};

type SlideBarState = {
    loadThumbStart: number,
    loadThumbEnd: number,
};

const inRange = (i: number, min: number, max: number): number => Math.max(Math.min(i, max), min);

export class CarouselViewSlideBar extends React.Component<SlideBarProps, SlideBarState> {
    private visibleThumbs: number = 2 * slideBarPaginationThumbs + 1;
    private slider = React.createRef<HTMLDivElement>();
    private scrollAnimation?: ScrollAnimationFunc;
    // set shouldAnimateScroll as true
    // to prevent handleScroll event handler
    // when animate scroll
    private shouldAnimateScroll: boolean = false;

    private handleClickThumb = (fileID: FileID) => {
        this.shouldAnimateScroll = true;
        this.props.onClickThumbImage(fileID);
    }

    private getScrollPositionByThumbIndex = (thumbIndex: number) => {
        const elementWidth = this.props.thumbStyle.size + this.props.thumbStyle.spaceBetween;
        return thumbIndex * elementWidth;
    }

    private updateViewFileByThumbIndex = (thumbIndex: number) => {
        if (this.props.currentThumbIndex !== thumbIndex) {
            const newViewFile = this.props.thumbFiles[thumbIndex].fileID;
            this.props.onClickThumbImage(newViewFile);
        }
    }

    private updateViewFileBySliderPosition = (newScrollPosition: number) => {
        const elementWidth = this.props.thumbStyle.size + this.props.thumbStyle.spaceBetween;
        const newThumbIndex = Math.min(
            Math.round(newScrollPosition / elementWidth),
            this.props.thumbFiles.length - 1,
        );

        this.updateViewFileByThumbIndex(newThumbIndex);
    }

    private handleScroll = (e: Event) => {
        e.stopPropagation();

        if (this.slider.current && !this.shouldAnimateScroll) {
            this.updateViewFileBySliderPosition(this.slider.current.scrollLeft);
        }
    }

    private setScrollPositionSlider = (newThumbIndex: number) => {
        if (this.slider.current) {
            const scrollPosition = this.getScrollPositionByThumbIndex(newThumbIndex);
            this.slider.current.scrollLeft = scrollPosition;
        }
    }

    private animateScroll = (scrollToThumbIndex: number, duration: number) => {
        if (this.slider.current && this.scrollAnimation) {
            const start = this.slider.current.scrollLeft;
            const end = this.getScrollPositionByThumbIndex(scrollToThumbIndex);

            this.scrollAnimation(start, end, duration, () => {
                this.updateViewFileBySliderPosition(end);
                this.shouldAnimateScroll = false;
            });
        }
    }

    private goToThumbRelativeToCurrent = (delta: number) => {
        const newThumbIndex = inRange(
            this.props.currentThumbIndex + delta,
            0,
            this.props.thumbFiles.length - 1,
        );

        this.shouldAnimateScroll = true;
        this.updateViewFileByThumbIndex(newThumbIndex);
    }

    private navBackSlider = () => {
        this.goToThumbRelativeToCurrent(-slideBarPaginationThumbs);
    }

    private navForwardSlider = () => {
        this.goToThumbRelativeToCurrent(slideBarPaginationThumbs);
    }

    private subscribeScrollEvent = () => {
        if (this.slider.current) {
            if (isMobileDevice.iOS()) { // iOS device works better with touchmove event
                this.slider.current.addEventListener('touchmove', this.handleScroll);
            } else if (isMobileDevice.any()) { // other mobile device
                this.slider.current.addEventListener('scroll', this.handleScroll);
            } else { // desktop
                // Webkit and IE support at least "mousewheel"
                this.slider.current.addEventListener('mousewheel', this.handleScroll);
                // Modern browsers support "wheel"
                this.slider.current.addEventListener('wheel', this.handleScroll);
            }
        }
    }

    private unsubscribeScrollEvent = () => {
        if (this.slider.current) {
            if (isMobileDevice.iOS()) {
                this.slider.current.removeEventListener('touchmove', this.handleScroll);
            } else if (isMobileDevice.any()) {
                this.slider.current.removeEventListener('scroll', this.handleScroll);
            } else {
                this.slider.current.removeEventListener('mousewheel', this.handleScroll);
                this.slider.current.removeEventListener('wheel', this.handleScroll);
            }
        }
    }

    constructor(props: SlideBarProps) {
        super(props);
        this.state = {
            loadThumbStart: Math.max(0, props.currentThumbIndex - this.visibleThumbs),
            loadThumbEnd: Math.min(props.currentThumbIndex + this.visibleThumbs, props.thumbFiles.length - 1),
        };
    }

    public componentDidMount() {
        if (this.slider.current) {
            this.subscribeScrollEvent();
            this.scrollAnimation = makeScrollAnimation(this.slider.current);
            this.setScrollPositionSlider(this.props.currentThumbIndex);
        }
    }

    public componentWillUnmount() {
        if (this.slider.current) {
            this.unsubscribeScrollEvent();
        }
    }

    public componentDidUpdate(prevProps: SlideBarProps) {
        if (this.props.currentThumbIndex !== prevProps.currentThumbIndex) {
            if (this.shouldAnimateScroll) {
                this.animateScroll(this.props.currentThumbIndex, 500);
            } else {
                this.setScrollPositionSlider(this.props.currentThumbIndex);
            }
        }

        if (prevProps.currentThumbIndex !== this.props.currentThumbIndex
            || prevProps.thumbFiles.length !== this.props.thumbFiles.length) {
                const newStart = inRange(
                    this.props.currentThumbIndex - this.visibleThumbs,
                    0,
                    this.state.loadThumbStart,
                );
                const newEnd = inRange(
                    this.props.currentThumbIndex + this.visibleThumbs,
                    this.state.loadThumbEnd,
                    this.props.thumbFiles.length - 1,
                );

                if (newStart !== this.state.loadThumbStart || newEnd !== this.state.loadThumbEnd) {
                    this.setState({
                        ...this.state,
                        loadThumbStart: newStart,
                        loadThumbEnd: newEnd,
                    });
                }
        }
    }

    public render() {
        const {
            thumbFiles,
            currentThumbIndex,
            thumbStyle,
            navBtnStyle,
        } = this.props;

        const thumbTotalWidth = thumbStyle.size + thumbStyle.spaceBetween;
        const prevButton = this.props.showNavArrows && (
            <NavBtn onClick={this.navBackSlider}>
                <PrevArrowButton {...navBtnStyle} />
            </NavBtn>
        );
        const nextButton = this.props.showNavArrows && (
            <NavBtn onClick={this.navForwardSlider}>
                <NextArrowButton {...navBtnStyle} />
            </NavBtn>
        );

        return (
            <Wrapper>
                {prevButton}
                <SliderWrapper>
                    <SliderContainer
                        width={thumbTotalWidth * this.visibleThumbs}
                        innerRef={this.slider}
                    >
                        <SlideBarThumbList
                            width={thumbTotalWidth * thumbFiles.length}
                            spaceAround={thumbTotalWidth * slideBarPaginationThumbs}
                            thumbStyle={thumbStyle}
                            thumbFiles={thumbFiles}
                            loadThumbStart={this.state.loadThumbStart}
                            loadThumbEnd={this.state.loadThumbEnd}
                            selectedIndex={currentThumbIndex}
                            onClickThumb={this.handleClickThumb}
                        />
                    </SliderContainer>
                </SliderWrapper>
                {nextButton}
            </Wrapper>
        );
    }
}
