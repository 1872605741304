export const CAPTURE_VIDEO_TYPE: string = 'video/mp4';

let ifCanPlayVideo: boolean;
export const canPlayVideo = (): boolean => {
    if (ifCanPlayVideo === undefined) {
        const v = document.createElement('video');
        ifCanPlayVideo = !!(
            v.canPlayType && v.canPlayType(CAPTURE_VIDEO_TYPE).replace(/no/, '')
        );
    }

    return ifCanPlayVideo;
};
