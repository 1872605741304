import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import FormEvent = React.FormEvent;
import {RippleLoaderOverlay} from '../Common/RippleLoaderOverlay';

type Props = {
    text: string,
    doCancel: () => any,
    doSubmit: (text: string) => any,
    isSubmitting: boolean,
};

const Wrapper = styled.div`
    position: relative;
`;

const EditCommentForm = styled.form`
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    background-color: ${colors.captureBlue50};
    margin-bottom: 12px;

    textarea{
        width: 100%;
        height: auto;
        box-sizing: border-box;
        box-shadow: inset 1px 1px 5px rgba(0,0,0,0.3);
        padding: 8px;
        border: none;
        resize: none;
        outline: none;
        font-size: 16px;
        color: ${colors.captureGrey500};
        -webkit-appearance: none;
        border-radius: 0px;
    }
`;

const EditButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    align-items: stretch;
`;
const commonButtonStyle = `
    min-width: 70px;
    font-size: 14px;
    padding: 4px 12px;
    border: 1px solid ${colors.captureBlue};
    border-radius: 4px;
    cursor: pointer;
`;
const UpdateButton = styled.input`
    ${commonButtonStyle}
    background: ${colors.captureBlue};
    color:  white;
    margin: 0px;
    margin-right: 22px;
    -webkit-appearance: none;
`;
const CancelButton = styled.span`
    ${commonButtonStyle}
    background: white;
    color:  ${colors.captureBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`;

type ComponentState = {editedText?: string};
export class EditCommentComponent extends React.Component<Props, ComponentState> {

    private textAreaElement: HTMLTextAreaElement|null = null;

    public state: ComponentState = {editedText: this.props.text};

    public componentDidMount() {
        if (this.textAreaElement) {
            this.textAreaElement.style.height = '1px';
            this.textAreaElement.style.height = (this.textAreaElement.scrollHeight) + 'px';
        }

    }

    private handleTextChange = (evt: React.FormEvent<HTMLTextAreaElement>) => {
        this.setState({editedText: evt.currentTarget.value});
        if (this.textAreaElement) {
            this.textAreaElement.style.height = (this.textAreaElement.scrollHeight) + 'px';
        }
    }

    private handleSubmit = (e: FormEvent<any>) => {
        if (this.state.editedText) {
            this.props.doSubmit(this.state.editedText);
        }

        e.preventDefault();
        return false;
    }

    private bindTextElement = (me: HTMLTextAreaElement|null) => this.textAreaElement = me;

    public render() {

        return (
            <Wrapper>
                <EditCommentForm onSubmit={this.handleSubmit}>
                    <textarea
                        ref={this.bindTextElement}
                        value={this.state.editedText}
                        onChange={this.handleTextChange}
                    />
                    <EditButtonsWrapper>
                        <UpdateButton type="submit" value={_('update')}/>
                        <CancelButton onClick={this.props.doCancel}>{_('cancel')}</CancelButton>
                    </EditButtonsWrapper>
                </EditCommentForm>
                {this.props.isSubmitting && <RippleLoaderOverlay loaderSize={48}/>}
            </Wrapper>
        );
    }
}
