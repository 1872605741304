import {State} from '../store';
import {PendingUserAction, UserActionsState} from './reducer';

export const getUserActionStatus = (state: State): UserActionsState => state.userActions || {};

export const getUserActionPendingConfirm = (state: State): PendingUserAction|undefined => {
    return state.userActions.pendingConfirm;
};

export const getUserActionAlert = (state: State): PendingUserAction|undefined => {
    return state.userActions.alerts[0];
};
