import {routerReducer, RouterState} from 'react-router-redux';
import {combineReducers, Reducer} from 'redux';
import {albumListReducer, AlbumListState} from './albumList/reducer';
import {albumOptionsReducer, AlbumOptionsState} from './albumOptions/reducer';
import {carouselViewerReducer, CarouselViewerState} from './carouselViewer/reducer';
import {commentsReducer, CommentsState} from './comments/reducer';
import {BulkOfActions, isType} from './common/actions';
import {currentUserReducer, CurrentUserState} from './currentUser/reducer';
import {fileMetadataReducer, FileMetadataState} from './fileMetadata/reducer';
import {filesReducer, FilesState} from './files/reducer';
import {FilesOptionsState, timelineOptionsReducer} from './FilesOptions/reducer';
import {hostsReducer, HostState} from './hosts/reducer';
import {jobReducer, JobState} from './job/reducer';
import {jobInfoReducer, JobInfoState} from './jobInfo/reducer';
import {jobSyncingReducer, JobSyncingState} from './jobSyncing/reducer';
import {lastSeenElementReducer, LastSeenElementState} from './lastSeenElement/reducer';
import {megaMenuReducer, MegaMenuState} from './megaMenu/reducer';
import {popupReducer, PopupState} from './popup/reducer';
import {orderReducer, PrintOrderState} from './printOrder/reducer';
import {orderItemsReducer, OrderItemsState} from './printOrderItem/reducer';
import {reactionReducer, ReactionState} from './reaction/reducer';
import {StatusNotificationsReducer, StatusNotificationState} from './statusNotifications/reducer';
import {storagePlanReducer, StorageState} from './storagePlan/reducer';
import {subscribersReducer, SubscribersState} from './subscribers/reducer';
import {takeoutReducer, TakeoutState} from './takeout/reducer';
import {timelineReducer, TimelineState} from './timeline/reducers';
import {trashReducer, TrashState} from './trash/reducer';
import {uploaderReducer, UploaderState} from './uploader/reducer';
import {userActionsReducer, UserActionsState} from './userActions/reducer';
import {usersReducer, UsersState} from './users/reducer';
import {viewModeReducer, ViewModeState} from './viewMode/reducer';

export type SubState<K extends keyof State> = {
    [P in K]: State[K];
};

export type State = {
    comments: CommentsState,
    currentUser: CurrentUserState
    hosts: HostState,
    megaMenu: MegaMenuState,
    files: FilesState,
    job: JobState,
    jobInfo: JobInfoState,
    jobSyncing: JobSyncingState,
    takeout: TakeoutState,
    timeline: TimelineState,
    filesOptions: FilesOptionsState,
    trash: TrashState,
    printOrder: PrintOrderState,
    printOrderItems: OrderItemsState,
    uploader: UploaderState,
    users: UsersState,
    popup: PopupState,
    viewMode: ViewModeState,
    carouselViewer: CarouselViewerState,
    reaction: ReactionState,
    userActions: UserActionsState,
    albumOptions: AlbumOptionsState,
    statusNotifications: StatusNotificationState,
    albumList: AlbumListState,
    fileMetadata: FileMetadataState,
    subscribers: SubscribersState,
    storagePlan: StorageState,
    lastSeenElement: LastSeenElementState,
    routing: RouterState,
};

export type StateWithCommentsState = SubState<'comments'>;
export type StateWithCurrentUserState = SubState<'currentUser'>;
export type StateWithFilesState = SubState<'files'>;
export type StateWithUploaderState = SubState<'uploader'>;
export type StateWithUsersState = SubState<'users'>;
export type StateWithReactionState = SubState<'reaction'>;
export type StateWithViewModeState = SubState<'viewMode'>;

const _mainReducer: Reducer<State> = combineReducers<State>(
    {
        comments: commentsReducer,
        currentUser: currentUserReducer,
        hosts: hostsReducer,
        megaMenu: megaMenuReducer,
        files: filesReducer,
        job: jobReducer,
        jobInfo: jobInfoReducer,
        jobSyncing: jobSyncingReducer,
        takeout: takeoutReducer,
        timeline: timelineReducer,
        filesOptions: timelineOptionsReducer,
        trash: trashReducer,
        printOrder: orderReducer,
        printOrderItems: orderItemsReducer,
        uploader: uploaderReducer,
        users: usersReducer,
        popup: popupReducer,
        viewMode: viewModeReducer,
        carouselViewer: carouselViewerReducer,
        reaction: reactionReducer,
        userActions: userActionsReducer,
        routing: routerReducer,
        albumOptions: albumOptionsReducer,
        albumList: albumListReducer,
        fileMetadata: fileMetadataReducer,
        statusNotifications: StatusNotificationsReducer,
        subscribers: subscribersReducer,
        storagePlan: storagePlanReducer,
        lastSeenElement: lastSeenElementReducer,
    },
);

export const mainReducer: Reducer<State> = (state: State = {} as State, action) => {
    if (isType(action, BulkOfActions)) {
        // Bulk of actions: Apply all actions to state in one go.
        return action.payload.reduce(mainReducer, state);
    }
    return _mainReducer(state, action);
};
