import * as React from 'react';
import styled from 'styled-components';
import {TopNavPlaceholder} from '../Navigation/TopNavBar';
import {TelenorCaptureFooter} from './TelenorCaptureFooter';

type Props = {
    navBar: React.ReactNode,
    isContentReady?: boolean,
};
const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const Content = styled.div`
    flex:  1 0 auto;
`;
export const PageWrapper: React.SFC<Props> = (props) => {
    return (
        <Wrapper>
            {props.isContentReady !== false && <TopNavPlaceholder />}
            <Content>{props.children}</Content>
            <TelenorCaptureFooter />
            {props.navBar}
        </Wrapper>
    );
};
