import {createSelector} from 'reselect';
import {StateWithCommentsState} from '../store';
import {
    CommentsState, FileComment,
} from './reducer';

const rootSelector = (state: StateWithCommentsState): CommentsState => state.comments;

export const getCommentsGroupedByImage: (state: StateWithCommentsState) => DictionaryOf<FileComment[]> = createSelector(
    rootSelector,
    (comments: CommentsState) => {
        const result: DictionaryOf<FileComment[]> = {};
        Object.keys(comments).forEach((commentID: CommentID) => {
            const comment = comments[commentID];
            result[comment.fileID] = [
                ...(result[comment.fileID] || []),
                comment,
            ].sort((commentA, commentB) => commentA.timestamp - commentB.timestamp);
        });
        return result;
    },
);
