import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {subscribeToAlbum} from '../../API/album';
import {isCurrentUserSubscribedToJob, isLoggedIn} from '../../state/currentUser/selectors';
import {State} from '../../state/store';

export function autoSubscribeToAlbum<P>(albumIDProvider: (props: Readonly<P>) => JobID) {
    return (Inner: React.ComponentClass<P>) => {
        type StateProps = {
            isLoggedIn: boolean,
            isSubscribedToAlbum: (albumID: JobID) => boolean,
        };

        type DispatchProps = {
            doSubscribeToAlbum: (albumID: JobID) => any,
        };

        type Props = StateProps & DispatchProps & P;

        class AutoSubscribeToAlbum extends React.Component<Props, {}> {
            private trySubscribeToAlbum() {
                const targetAlbum = albumIDProvider(this.props);
                if (!this.props.isSubscribedToAlbum(targetAlbum)) {
                    this.props.doSubscribeToAlbum(targetAlbum);
                }
            }

            public componentWillMount() {
                if (this.props.isLoggedIn) {
                    this.trySubscribeToAlbum();
                }
            }

            public componentWillReceiveProps(nextProps: Readonly<Props>) {
                if (nextProps.isLoggedIn && !this.props.isLoggedIn) {
                    this.trySubscribeToAlbum();
                }
            }

            public render() {
                return <Inner {...this.props} />;
            }
        }

        const stateToProps = (state: State): StateProps => ({
            isLoggedIn: isLoggedIn(state),
            isSubscribedToAlbum: (albumID) => isCurrentUserSubscribedToJob(state, albumID),
        });

        const dispatchToProps = (dispatch: Dispatch): DispatchProps => ({
            doSubscribeToAlbum: (albumID: JobID) => subscribeToAlbum(dispatch, albumID),
        });

        return connect<StateProps, DispatchProps, P, State>(stateToProps, dispatchToProps)(AutoSubscribeToAlbum);

    };
}
