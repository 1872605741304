import * as React from 'react';
import {
    ScrollerMonthEntry, ScrollerYearGroup,
} from '../../state/timeline/selectors';
import {getShortMonthFromNumberString} from '../../utilities/dateOperations';

type ScrollerItemProps = {
    item: ScrollerMonthEntry,
    onSelect: (scrollerEntryKey: string) => any,
    isSelected: boolean,
    scrollerWrapper: HTMLDivElement|null,
};
class ScrollerItem extends React.Component<ScrollerItemProps> {
    private itemDiv = React.createRef<HTMLDivElement>();

    private handleClick = () => {
        this.props.onSelect(this.props.item.scrollerEntryKey);
    }

    private isCompletelyScrolledIntoView = (): boolean => {
        if (this.itemDiv.current && this.props.scrollerWrapper) {
            const elemTop = this.itemDiv.current.offsetTop - this.props.scrollerWrapper.scrollTop;
            const elemBottom: number
                = this.itemDiv.current.offsetTop
                - this.props.scrollerWrapper.scrollTop
                + this.itemDiv.current.clientHeight;

            return (elemTop >= 0) && (elemBottom <= this.itemDiv.current.offsetParent.clientHeight);
        }
        return true;
    }

    public componentWillReceiveProps(nextProps: ScrollerItemProps) {
        if (this.itemDiv.current
            && this.props.scrollerWrapper
            && this.props.isSelected !== nextProps.isSelected
            && nextProps.isSelected
            && !this.isCompletelyScrolledIntoView()
        ) {
            this.props.scrollerWrapper.scrollTop = this.itemDiv.current.offsetTop - this.itemDiv.current.offsetParent.clientHeight / 2;
        }
    }

    public render() {
        return (
            <div ref={this.itemDiv} onClick={this.handleClick} tabIndex={0} className="scroller-item">
                {this.props.children}
            </div>
        );
    }
}

type FastScrollerProps = {
    scrollerGroups: ScrollerYearGroup[],
    selectedItemKey: string,
    scrollerItemOnSelected: (scrollerEntryKey: string) => any,
    scrollerWrapper: HTMLDivElement|null,
};
export type FastScrollerItemProps = {isSelected: boolean};

export const makeFastScroller = (
    Wrapper: React.ComponentType,
    Header: React.ComponentType,
    Item: React.ComponentType<FastScrollerItemProps>,
) => class FastScroller extends React.Component<FastScrollerProps> {

    private getItem = (month: ScrollerMonthEntry) => {
        return (
            <ScrollerItem
                key={month.monthStr}
                item={month}
                onSelect={this.props.scrollerItemOnSelected}
                scrollerWrapper={this.props.scrollerWrapper}
                isSelected={month.scrollerEntryKey === this.props.selectedItemKey}
            >
                <Item isSelected={month.scrollerEntryKey === this.props.selectedItemKey}>
                    {getShortMonthFromNumberString(month.monthStr)}
                </Item>
            </ScrollerItem>
        );
    }
    public render() {
        return this.props.scrollerGroups.map((group) => {
            return (
                <Wrapper key={group.yearStr}>
                    <Header>{group.yearStr}</Header>
                    <div>{group.months.map(this.getItem)}</div>
                </Wrapper>
            );
        });
    }
};
