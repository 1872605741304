import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {isMobileDevice} from '../../utilities/device';
import {getFileExtension} from '../../utilities/fileTarget';
import {DocIcon} from '../Icons/DocIcon';
import {ButtonProps} from './Button';
import {IconButton} from './IconTextButton';
import {SelectionIndicator} from './SelectionIndicator';
import {ThreeColumns, ThreeColumnsRow} from './ThreeColumnsRow';

type ListRowProps = {
    isSelected: boolean,
    isClickable: boolean,
};
const ListRowWrapper = styled.div`
    border: 1px solid transparent;
    align-items: center;

    font-size: ${fontSize.small_14};
    background-color: #FFF;
    ${(props: ListRowProps) => props.isSelected ? `border: 1px solid ${colors.captureBlue}` : ''};
    margin-bottom: 8px;
    transition: border 0.3s ease-out;

    ${!isMobileDevice.any() ? `
        &:hover {
            background-color: ${colors.captureBlue50};
        }

        &:hover .item-select-indicator {
            visibility: visible;
        }
    ` : ''}

    cursor: ${(props) => props.isClickable ? 'pointer' : 'default'};
`;

const FileInfoSection = styled.div`
    display: inline-block;
    max-width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ThumbWrapper = styled.div`
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: ${(props: {isMobile: boolean}) => props.isMobile ? '16' : '50'}px;
`;

const Image = styled.div`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
`;

const ActionButtonWrapper = styled.div`
    margin-left: 8px;
`;

type FileRowProps = {
    fileID: FileID,
    fileName: string,
    dateInfo: Date,
    fileThumb?: string,
    isMobile: boolean,
    isSelected: boolean,
    isInSelectMode: boolean,
    isActionable: boolean,
    optionButtons: ButtonProps[],
    doFileSelected: (fileID: FileID) => any,
    doFileDeSelected: (fileID: FileID) => any,
    doSetActionableFile: (fileID: FileID) => any,
};

export class FileListEntry extends React.PureComponent<FileRowProps> {
    private handleSelect = (e: React.SyntheticEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!this.props.isSelected) {
            this.props.doFileSelected(this.props.fileID);
        }
        else {
            this.props.doFileDeSelected(this.props.fileID);
        }
    }

    private handleClickRow = (e: React.SyntheticEvent<HTMLDivElement>) => {
        if (this.props.isInSelectMode) {
            this.handleSelect(e);
        } else if (this.props.isMobile) {
            this.props.doSetActionableFile(this.props.fileID);
        }
    }

    public render() {
        const thumb = this.props.fileThumb ? (
            <Image style={{backgroundImage: `url(${this.props.fileThumb})`}} />
        ) : (
            <DocIcon size={32} />
        );

        const fileExtension = getFileExtension(this.props.fileName);

        const actionButtons = this.props.isActionable ? (
            this.props.optionButtons.map((b, i) => b.icon && (
                <ActionButtonWrapper key={i}>
                    <IconButton
                        icon={b.icon}
                        onClick={b.onClick}
                    />
                </ActionButtonWrapper>
            ))
        ) : (
            <SelectionIndicator
                onClick={this.handleSelect}
                isSelected={this.props.isSelected}
                showPlaceholder={this.props.isMobile && this.props.isInSelectMode}
                fillColor={colors.captureBlue}
                strokeColor={'white'}
            />
        );

        const columns: ThreeColumns = [
            (
            <div key="entry-column-1">
                <ThumbWrapper isMobile={this.props.isMobile}>
                    {thumb}
                </ThumbWrapper>
                <FileInfoSection>{this.props.fileName}</FileInfoSection>
            </div>
            ),
            (
            <div key="entry-column-2">
                {fileExtension ? fileExtension.toUpperCase() : _('unknown')}
            </div>
            ),
            (
            <div key="entry-column-3">
                {!this.props.isMobile && <div>{this.props.dateInfo.toLocaleDateString()}</div>}
                {actionButtons}
            </div>
            ),
        ];

        return (
            <ListRowWrapper
                onClick={this.handleClickRow}
                isSelected={this.props.isSelected}
                isClickable={this.props.isInSelectMode || this.props.isMobile}
            >
                <ThreeColumnsRow
                    isMobile={this.props.isMobile}
                    columns={columns}
                />
            </ListRowWrapper>
        );
    }
}
