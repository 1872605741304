import * as React from 'react';

type CloseButtonProps = {
    size: number,
};

export const CloseButton: React.SFC<CloseButtonProps> = (props) =>
    <svg width={props.size} height={props.size} viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="close_icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon id="Shape" fill="#FFFFFF" points="19 6.4 17.6 5 12 10.6 6.4 5 5 6.4 10.6 12 5 17.6 6.4 19 12 13.4 17.6 19 19 17.6 13.4 12"></polygon>
            <rect id="bounds" x="0" y="0" width="24" height="24"></rect>
        </g>
    </svg>;
