import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {isMobileDevice} from '../../utilities/device';
import {AppStoreBadgeLink, GooglePlayBadgeLink} from '../Common/AppBadgeLink';
import {CaptureLogoVertical} from '../Icons/CaptureLogo';

const DownloadAppWrapper = styled.div`
    width: 100%;
    background-color: rgba(${colors.captureBlueRGB}, 0.05);
    padding: ${(props: {isMobileMode: boolean}) => props.isMobileMode ? '8px 16px' : '16px 24px'};
    box-sizing: border-box;

    display: block;
    text-align: center;
`;

const Logo = styled.div`
    margin: 16px;
`;

const DownloadText = styled.div`
    max-width: 288px;
    color: ${colors.captureBlue};
    text-align: center;
    line-height: 24px;
    margin: 0 auto 8px;
`;

const AppBadges = styled.div`
    margin: 16px;
    a {
        margin: 0 12px;
        display: inline-block;
    }
`;

export const DownloadAppInfo: React.SFC = () => {
    const isMobile = isMobileDevice.any();

    let appBadges = null;
    if (isMobileDevice.Android()) {
        appBadges = <AppBadges><GooglePlayBadgeLink isMobile={isMobile} /></AppBadges>;
    }
    else if (isMobileDevice.iOS()) {
        appBadges = <AppBadges><AppStoreBadgeLink isMobile={isMobile} /></AppBadges>;
    }
    else {
        appBadges = (
            <AppBadges>
                <GooglePlayBadgeLink isMobile={isMobile} />
                <AppStoreBadgeLink isMobile={isMobile} />
            </AppBadges>
        );
    }
    return (
        <DownloadAppWrapper isMobileMode={isMobile}>
            <Logo><CaptureLogoVertical size={74}/></Logo>
            <DownloadText>{_('download_app_to_upload_directly_from_device')}</DownloadText>
            {appBadges}
        </DownloadAppWrapper>
    );
};
