import {RoutePath} from '../../routing';
import {b64ToUuid} from '../../utilities/uuid';
import {ChangedCurrentCarouselFile} from '../carouselViewer/actions';
import {Action, isType} from '../common/actions';
import {NavigateTo} from '../index';
import {NavigateByReplacingCurrent} from '../routing/actions';
import {LastSeenElementCleared, LastSeenElementUpdated} from './actions';

export type LastSeenElementState = {
    activeFile: DictionaryOf<FileID|undefined>,
    currentAlbum: JobID|undefined,
};

const initialState: LastSeenElementState = {
    activeFile: {},
    currentAlbum: undefined,
};

export const lastSeenElementReducer = (state: LastSeenElementState = initialState, action: Action<any>): LastSeenElementState => {

    if (isType(action, LastSeenElementUpdated) || isType(action, ChangedCurrentCarouselFile)) {
        return {
            ...state,
            activeFile: {
                ...state.activeFile,
                [action.payload.jobID]: action.payload.fileID,
            },
        };
    }

    if (isType(action, LastSeenElementCleared)) {
        return {
            ...state,
            activeFile: {
                ...state.activeFile,
                [action.payload]: undefined,
            },
        };
    }

    if (isType(action, NavigateTo || isType(action, NavigateByReplacingCurrent))) {
        const albumPath = action.payload.url.match(RoutePath.getLoggedInAlbumPath('([^/]*)'));
        if (albumPath) {
            return {
                ...state,
                currentAlbum: b64ToUuid(albumPath[1]),
            };
        }
    }

    return state;
};
