import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {logout} from '../../API/login';
import {_} from '../../assets/localizedStrings';
import {fontSize} from '../../assets/styleConstants';
import {FilledTextButton} from '../Common/IconTextButton';
import {trackTakeoutEvent} from './analytics';

type Props = {
    doLogout: () => any;
};

const W = styled.div`
    position: fixed;
    top: 16px;
    left: 16px;
`;

const LogoutButtonInner: React.SFC<Props> = (props) => (
    <FilledTextButton
        onClick={props.doLogout}
        text={_('log_out')}
        fontSize={fontSize.small_12}
    />
);

export const LogoutButton = connect(null, (d: Dispatch) => ({
    doLogout: () => {
        trackTakeoutEvent('logoutButtonClicked');
        return logout(d);
    },
}))(LogoutButtonInner);

export const FixedTopLeftLogoutButton: React.SFC = () => (
    <W>
        <LogoutButton />
    </W>
);
