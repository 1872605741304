
import {PrintProduct} from '../../../print/constants/productInfo';
import {createActionCreator} from '../common/actions';
import {OrderItem, PrintArea} from './reducer';

type InitialFile = {
    fileID: FileID,
    originalResolution: {width: number, height: number},
};

export const OrderItemInitiated =
    createActionCreator<{orderID: string, productType: PrintProduct}>('ORDER_ITEM_INITIATED');

export const OrderItemAdded = createActionCreator<OrderItem>('ORDER_ITEM_CREATED');

export const FileWasAddedToOrderItem =
    createActionCreator<{orderItemID: number, fileData: InitialFile}>('FILE_WAS_ADDED_TO_ORDER_ITEM');

export const FileWasRemovedFromOrderItem =
    createActionCreator<{orderItemID: number, fileID: FileID}>('FILE_WAS_REMOVED_FROM_ORDER_ITEM');

export const OrderItemQuantityUpdated =
    createActionCreator<{orderItemID: number, updatedQuantity: number}>('ORDER_ITEM_QUANTITY_UPDATED');

export const PrintFileQuantityUpdated =
    createActionCreator<{orderItemID: number, fileIndex: number, updatedQuantity: number}>('PRINT_FILE_QUANTITY_UPDATED');

export const PrintFileCroppingUpdated =
    createActionCreator<{orderItemID: number, fileIndex: number, updatedCropping: PrintArea}>('PRINT_FILE_CROPPING_UPDATED');
