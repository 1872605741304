import * as React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {colors} from '../../assets';
import {Album, AlbumFileComment, canCurrentUserCommentOnAlbum, getComments} from '../../state/album/selectors';
import {CommentStatus} from '../../state/files/reducer';
import {getCommentStatus} from '../../state/files/selectors';
import {State} from '../../state/store';
import {b64ToUuid} from '../../utilities/uuid';
import {CloseIcon} from '../Icons/CloseIcon';
import {RequireUserInfoPlacement} from '../ProvideName/RequireUserInfoPlacement';
import {CommentsComponent} from './CommentsComponent';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 3px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    & > div:last-child {
        flex: 1;
    }
`;
const TopElement = styled.div`
    font-size: 11px;
    padding: 16px 4px 8px;
    margin: 0 8px;
    box-sizing: border-box;
    border-bottom: 1px solid ${colors.captureGrey400};
    text-align: right;

    div{
        display: inline-block;
        height: 24px;
    }
`;

type InnerProps = {
    fileID: FileID,
    albumID: JobID,
    comments: AlbumFileComment[],
    commentsCanBeAdded: boolean,
    isPendingNewComment: boolean,
};

class CommentsListOverlayInner extends React.Component<InnerProps> {

    private goBack = () => {
        history.back();
    }

    public render() {
        return (
            <Wrapper>
                <TopElement>
                    <div onClick={this.goBack}><CloseIcon size={24}/></div>
                </TopElement>
                <CommentsComponent
                    fileID={this.props.fileID}
                    albumID={this.props.albumID}
                    comments={this.props.comments}
                    commentsCanBeAdded={this.props.commentsCanBeAdded}
                    isPendingNewComment={this.props.isPendingNewComment}
                />
                <RequireUserInfoPlacement jobID={this.props.albumID}/>
            </Wrapper>
        );
    }
}

export const CommentListByAlbum = (props: {album: Album, fileID: FileID}) => {
    const file = props.album.files.find((f) => f.fileID === props.fileID);

    return (
        <CommentsListOverlayInner
            albumID={props.album.id}
            fileID={props.fileID}
            comments={file ? file.comments : []}
            commentsCanBeAdded={props.album.currentUserCan.addComment}
            isPendingNewComment={file ? file.isPendingNewComment : false}
        />
    );
};

type OwnProps = {
    params: {albumIDB64: string, fileID: FileID},
};
const mapStateToProps = (state: State, ownProps: OwnProps): InnerProps => {
    const albumID = b64ToUuid(ownProps.params.albumIDB64);
    const fileID = ownProps.params.fileID;
    return {
        albumID,
        fileID,
        comments: getComments(state, fileID),
        commentsCanBeAdded: canCurrentUserCommentOnAlbum(state, albumID),
        isPendingNewComment: getCommentStatus(state, ownProps.params.fileID) === CommentStatus.PENDING,
    };
};

// Only works for Albums with Job-type backend (ie Capture)
export const CommentsListOverlay = connect(mapStateToProps)(CommentsListOverlayInner);
