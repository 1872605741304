import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {PRODUCT_NAME} from '../../../../config/constants';
import {deleteAlbum, unsubscribeFromAlbum} from '../../API/album';
import {deleteFile, removeConnectedDevice} from '../../API/job';
import {StripeProduct} from '../../API/services/AppService';
import {cancelStoragePlan, changeCurrentPlan, reactivateStoragePlan} from '../../API/storagePlan';
import {_} from '../../assets/localizedStrings';
import {getTitleForAlbum} from '../../state/album/selectors';
import {getMaxStorage} from '../../state/currentUser/selectors';
import {JobFile} from '../../state/files/reducer';
import {getFileByID, isLastFileOfStoryJob} from '../../state/files/selectors';
import {getAvailableProducts, getCurrentStoragePlan, StoragePlan} from '../../state/storagePlan/selectors';
import {State} from '../../state/store';
import {getTimelineJobID} from '../../state/timeline/selectors';
import {UserActionAlertHandled, UserActionConfirmHandled} from '../../state/userActions/actions';
import {PendingUserAction, UserActionTypes} from '../../state/userActions/reducer';
import {getUserActionAlert, getUserActionPendingConfirm} from '../../state/userActions/selectors';
import {bytesToSize} from '../../utilities/fileSizeFormatting';
import {ConfirmPromptOverlay, OkPromptOverlay} from './DialoguePromptOverlay';
import {localizedDateStringShort} from './TimeStrings';

type StateProps = {
    albumTitle: (albumID: JobID) => any,
    getFile: (fileID: FileID) => JobFile|undefined,
    isLastFileOfAlbum: (fileID: FileID) => boolean,
    currentMaxStorage: number,
    currentStoragePlan?: StoragePlan,
    userActionConfirm: PendingUserAction|undefined,
    userActionAlert: PendingUserAction|undefined,
    availablePlans: StripeProduct[],
    timelineID: JobID | undefined,
};

type DispatchProps =  {
    dispatch: Dispatch,
};

type Props = StateProps & DispatchProps;

type DialogContent = {
    text: (target: string) => string,
    action?: (dispatch: Dispatch, target: string) => any,
};

const planPeriodStrings: Record<'monthly' | 'yearly', string> = {
    monthly: _('monthly'),
    yearly: _('yearly'),
};

class DialogComponent extends React.PureComponent<Props> {

    private alerts: DictionaryOf<DialogContent> = {
        [UserActionTypes.DELETE_ALBUM]: {
            text: (target: string) => (
                _('delete_album_failed_message__format').replace('%s', this.props.albumTitle(target))
            ),
        },
        [UserActionTypes.UNSUBSCRIBE_ALBUM]: {
            text: (target: string) => (
                _('unsubscribe_album_failed_message__format').replace('%s', this.props.albumTitle(target))
            ),
        },
        [UserActionTypes.SET_COVER_PHOTO]: {
            text: (_target: string) => _('set_cover_photo_failed_message'),
        },
        [UserActionTypes.DELETE_FILE]: {
            text: (target: string) => {
                const file = this.props.getFile(target);
                return _('delete_file_failed_message__format').replace('%s', file ? file.path : '');
            },
        },
        [UserActionTypes.DOWNLOAD_MORE_THAN_LIMIT]: {
            text: (_target: string) => _('download_limit_exceeded'),
        },
        [UserActionTypes.UNAVAILABLE_PLAN_CLICKED]: {
            text: (_target: string) => {
                const contextualPart = this.props.currentStoragePlan && this.props.currentStoragePlan.isCanceled
                    ? _('downgrade_canceled_plan_alert') : _('downgrade_active_plan_alert');
                return `${_('downgrade_not_available')} ${contextualPart}`;
            },
        },
    };

    private confirms: DictionaryOf<DialogContent> = {
        [UserActionTypes.DELETE_ALBUM]: {
            text: () => _('delete_album_prompt_text'),
            action: deleteAlbum,
        },
        [UserActionTypes.UNSUBSCRIBE_ALBUM]: {
            text: () => _('unsubscribe_album_prompt_text'),
            action: unsubscribeFromAlbum,
        },
        [UserActionTypes.DELETE_FILE]: {
            text: (target: string) => {
                if (this.props.isLastFileOfAlbum(target)) {
                    return _('delete_last_file_prompt_text');
                }
                const file = this.props.getFile(target);
                if (file && file.jobID === this.props.timelineID) {
                    return _('delete_single_file_prompt');
                } else {
                    return _('delete_album_file_prompt_text');
                }
            },
            action: (dispatch, target) => {
                const file = this.props.getFile(target);
                if (file) {
                    if (this.props.isLastFileOfAlbum(target)) {
                        deleteAlbum(dispatch, file.jobID);
                    } else {
                        deleteFile(dispatch, file.jobID, target);
                    }
                }
            },
        },
        [UserActionTypes.DELETE_DEVICE]: {
            text: () => _('remove_device_prompt__format').replace('%s', PRODUCT_NAME),
            action: removeConnectedDevice,
        },
        [UserActionTypes.CANCEL_STORAGE_PLAN]: {
            text: () => {
                if (this.props.currentStoragePlan) {
                    const {validToDate, size} = this.props.currentStoragePlan;

                    const expiredDate = validToDate || localizedDateStringShort(new Date());
                    const storageAmount
                        = bytesToSize(this.props.currentMaxStorage - (size * Math.pow(2, 30)));

                    return _('cancel_storage_plan_prompt__format')
                        .replace('%storage_amount%', storageAmount)
                        .replace('%expired_date%', expiredDate);
                }
                return '';
            },
            action: cancelStoragePlan,
        },
        [UserActionTypes.REACTIVATE_STORAGE_PLAN]: {
            text: () => {
                if (this.props.currentStoragePlan && this.props.currentStoragePlan.validToDate) {
                    return _('reactivate_storage_plan_prompt__format')
                        .replace('%period%', planPeriodStrings[this.props.currentStoragePlan.period]);
                }
                return '';
            },
            action: reactivateStoragePlan,
        },
        [UserActionTypes.CHANGE_STORAGE_PLAN]: {
            text: (target: string) => {
                const targetSize = this.props.availablePlans.filter((f) => f.id === target)[0].size;
                if (this.props.currentStoragePlan && targetSize) {
                    if (targetSize === this.props.currentStoragePlan.size) {
                        return _('change_storage_plan_period_prompt');
                    }
                    // TODO: Get lowest denomination (bytes) from backend as we do with price?
                    return _('upgrade_storage_plan_size_prompt__format')
                        .replace('%new_size%', `${targetSize} GB`)
                        .replace('%current_size%', `${this.props.currentStoragePlan.size} GB`);
                }

                return '';
            },
            action: changeCurrentPlan,
        },
    };

    private onUserActionConfirm = () => {
        if (this.props.userActionConfirm) {
            const {type, target} = this.props.userActionConfirm;
            const action = this.confirms[type].action;
            if (action) {
                action(this.props.dispatch, target);
            }
        }

        this.onConfirmPromptHandled();
    }

    private onConfirmPromptHandled = () => {
        this.props.dispatch(UserActionConfirmHandled());
    }

    private onErrorPromptHandled = () => {
        this.props.dispatch(UserActionAlertHandled());
    }

    public render() {
        if (this.props.userActionConfirm) {
            const {type, target} = this.props.userActionConfirm;
            let confirmText;

            switch (type) {
                case UserActionTypes.DELETE_ALBUM:
                case UserActionTypes.DELETE_FILE:
                    confirmText = _('delete_');
                    break;
                case UserActionTypes.REACTIVATE_STORAGE_PLAN:
                    confirmText = _('reactivate');
                    break;
                case UserActionTypes.UNSUBSCRIBE_ALBUM:
                    confirmText = _('unsubscribe');
                    break;
                case UserActionTypes.DELETE_DEVICE:
                    confirmText = _('log_out');
                    break;
            }
            return (
                <ConfirmPromptOverlay
                    onConfirm={this.onUserActionConfirm}
                    onCancel={this.onConfirmPromptHandled}
                    confirmText={confirmText}
                >
                    {this.confirms[type].text(target)}
                </ConfirmPromptOverlay>
            );
        }

        if (this.props.userActionAlert) {
            const {type, target} = this.props.userActionAlert;
            return (
                <OkPromptOverlay onOK={this.onErrorPromptHandled}>
                    {this.alerts[type].text(target)}
                </OkPromptOverlay>
            );
        }

        return null;
    }
}

const mapStateToProps = (state: State): StateProps => ({
    userActionConfirm: getUserActionPendingConfirm(state),
    userActionAlert: getUserActionAlert(state),
    albumTitle: (albumID: JobID) => getTitleForAlbum(state, albumID),
    getFile: (fileID: FileID) => getFileByID(state, fileID),
    isLastFileOfAlbum: (fileID: FileID) => isLastFileOfStoryJob(state, fileID),
    currentMaxStorage: getMaxStorage(state),
    currentStoragePlan: getCurrentStoragePlan(state),
    availablePlans: getAvailableProducts(state),
    timelineID: getTimelineJobID(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({dispatch});

export const UserActionDialogPlacement = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DialogComponent);
