import {
    AlbumDetailsFetched, AutoGeneratedAlbumCanceled,
    AutoGeneratedAlbumCreated, AutoGeneratedAlbumFinished,
} from '../album/actions';
import {Action, isType} from '../common/actions';
import {UserInfo} from '../users/selectors';
import {AlbumListFilterSet} from './actions';

export type AlbumListFilter = 'shared' | 'private' | 'all';

type PendingAlbum = {
    jobID: JobID,
    tempID: string,
    name: string,
};

export type AlbumNumberOf = {
    contributors: number,
    files: number,
    comments: number,
    loves: number,
};

export type AlbumDetails = {
    albumID: JobID,
    title: string,
    owner: UserInfo,
    coverPhotoID: string,
    ctime: number,
    mtime: number,
    permissions: number,
    numberOf: AlbumNumberOf,
    isShared: boolean,
};

export type AlbumListState = {
    filter: AlbumListFilter,
    pendingAlbums: PendingAlbum[],
    albumDetailsList: DictionaryOf<AlbumDetails>,
};

const initialState: AlbumListState = {
    filter: 'all',
    pendingAlbums: [],
    albumDetailsList: {},
};

export const albumListReducer = (state: AlbumListState = initialState, action: Action<any>): AlbumListState => {
    if (isType(action, AlbumListFilterSet)) {
        return {
            ...state,
            filter: action.payload,
        };
    }

    if (isType(action, AutoGeneratedAlbumCreated)) {
        return {
            ...state,
            pendingAlbums: state.pendingAlbums.concat(action.payload),
        };
    }

    if (isType(action, AutoGeneratedAlbumFinished) || isType(action, AutoGeneratedAlbumCanceled)) {
        return {
            ...state,
            pendingAlbums: state.pendingAlbums.filter((a) => a.tempID !== action.payload.tempID),
        };
    }

    if (isType(action, AlbumDetailsFetched)) {
        return {
            ...state,
            albumDetailsList: {
                ...state.albumDetailsList,
                [action.payload.albumID]: action.payload,
            },
        };
    }

    return state;
};
