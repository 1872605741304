import {createActionCreator} from '../common/actions';

export interface HostDirectory {
    appHost: string;
    thumbHost: string;
    videoHost: string;
    pollHost: string;
}

export const FetchingHostDirectoryStarted = createActionCreator<JobID>('FETCHING_HOST_DIRECTORY_STARTED');
export const FetchingHostDirectoryFailed = createActionCreator<JobID>('FETCHING_HOST_DIRECTORY_FAILED');
export const FetchedHostDirectory = createActionCreator<{job: JobID, hosts: HostDirectory}>('FETCHED_HOST_DIRECTORY');
