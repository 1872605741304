import * as React from 'react';
import styled from 'styled-components';
import {RippleLoader} from './RippleLoader';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RippleLoaderOverlay: React.SFC<{loaderSize?: number}> = (props) => {
    return (
        <Wrapper>
            <RippleLoader size={props.loaderSize}/>
        </Wrapper>
    );
};
