import {createActionCreator, createVoidActionCreator} from '../common/actions';

type ViewportSizePayload = {
    height: number,
    width: number,
};
export const ClientViewportSizeChanged = createActionCreator<ViewportSizePayload>('CLIENT_VIEWPORT_SIZE_CHANGED');

export const ClientViewportSizeChangeDisabled = createVoidActionCreator('CLIENT_VIEWPORT_SIZE_CHANGE_DISABLED');
export const ClientViewportSizeChangeEnabled =
    createActionCreator<ViewportSizePayload>('CLIENT_VIEWPORT_SIZE_CHANGE_ENABLED');
