import {createSelector, Selector} from 'reselect';
import {Reaction} from '../../API/services/AppService';
import {getCurrentUserUUID} from '../currentUser/selectors';
import {
    StateWithCurrentUserState,
    StateWithReactionState,
    StateWithUsersState,
} from '../store';
import {getUsersInfo, UserInfo} from '../users/selectors';
import {Reactions} from './actions';
import {FileReaction} from './reducer';

const getReactionList = (state: StateWithReactionState): DictionaryOf<FileReaction> => state.reaction;

export type ReactionItem = {userID: UserID, fileID: FileID, reaction: Reaction};
const getReactionItems: Selector<StateWithReactionState, ReactionItem[]> = createSelector(
    getReactionList,
    (reactionList: DictionaryOf<FileReaction>) => {
        const list: ReactionItem[] = [];
        Object.keys(reactionList).forEach((fileID: FileID) => {
            const fileReaction = reactionList[fileID];
            Object.keys(fileReaction).forEach((userID: UserID) => {
                list.push({fileID, userID, reaction: fileReaction[userID]});
            });
        });
        return list;
    },
);

const getReactionItemsByType = (reactionType: Reaction) =>
    createSelector(getReactionItems, (reactions: ReactionItem[]) => {
        return reactions.filter(({reaction}) => reaction === reactionType);
    });

const reactionTotalCountPerFile = (reactionType: Reaction) =>
    createSelector(
        getReactionItemsByType(reactionType),
        (reactions: ReactionItem[]) => {
            return reactions.reduce<DictionaryOf<number>>((o, {fileID}) => {
                o[fileID] = (o[fileID] || 0) + 1;
                return o;
            }, {});
    },
);

export const getTotalLoveCountPerFile = reactionTotalCountPerFile(Reactions.Love);

const currentUserReactedFiles = (reactionType: Reaction) =>
    createSelector(
        getCurrentUserUUID,
        getReactionItemsByType(reactionType),
        (
            currentUserUUID: UserID | undefined,
            reactions: ReactionItem[],
        ) => {
            if (currentUserUUID !== undefined) {
                return reactions.reduce<DictionaryOf<boolean>>(
                    (o, {fileID, userID}) => {
                        o[fileID] = userID === currentUserUUID;
                        return o;
                    },
                    {},
                );

            }
            return {};
        },
    );

export const getCurrentUserLovedFiles = currentUserReactedFiles(Reactions.Love);

export const isCurrentUserLovedFile = (
    state: StateWithReactionState & StateWithCurrentUserState,
    fileID: FileID,
) => getCurrentUserLovedFiles(state)[fileID];

const usersWhoReactedToFile = (reactionType: Reaction) =>
    createSelector(
        getReactionItemsByType(reactionType),
        getUsersInfo,
        (
            reactions: ReactionItem[],
            users: DictionaryOf<UserInfo>,
        ): DictionaryOf<UserInfo[]> => {
            return reactions.reduce<DictionaryOf<UserInfo[]>>(
                (o, {userID, fileID}) => {
                    o[fileID] = (o[fileID] || []).concat(users[userID]);
                    return o;
                },
                {},
            );
        },
    );

export const getUsersLovedFilesList = usersWhoReactedToFile(Reactions.Love);

export const getUsersLovedListByFile = (
    state: StateWithReactionState & StateWithUsersState,
    fileID: FileID,
) =>  getUsersLovedFilesList(state)[fileID];
