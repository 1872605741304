import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {AlbumListFilter} from '../../state/albumList/reducer';
import {ChoiceMenu} from '../Common/ChoiceMenu';
import {EmptyStatePage} from '../Common/EmptyStatePage';
import {FilledTextButton} from '../Common/IconTextButton';
import {ButtonWithExpandingContent} from '../Common/Positioning';
import {AlbumPrivacyModeSelector} from '../EditAlbum/AlbumPrivacyModeSelector';

const ButtonWrapper = styled.div`
    text-transform: uppercase;
    font-size: ${fontSize.xSmall_10};
    letter-spacing: 1.25px;
`;

const AlbumCreationButton: React.SFC<{onClick: () => any}> = (props) => (
    <ButtonWrapper>
        <FilledTextButton
            onClick={props.onClick}
            text={_('create_album')}
            fillColor={colors.captureBlue}
            color={'white'}
        />
    </ButtonWrapper>
);

const CreateAlbumButtonFilled = ButtonWithExpandingContent({v: 'bottom', h: 'center'})(
    ({toggleExpand}) => (
        <AlbumCreationButton onClick={toggleExpand} />
    ),
    () => (
        <ChoiceMenu title={_('create_new')}>
            <AlbumPrivacyModeSelector />
        </ChoiceMenu>
    ),
);

const firstLineTitles: Record<AlbumListFilter, string> = {
    all: _('album_list_is_empty'),
    private: _('empty_filtered_private_albumList_title'),
    shared: _('empty_filtered_shared_albumList_title'),
};

type InnerProps = {
    currentFilter: AlbumListFilter,
    initiateAlbumCreation: () => any,
};
const AlbumListEmptyStateMobile: React.SFC<InnerProps> = ({currentFilter, initiateAlbumCreation}) => (
    <EmptyStatePage
        header={<span>{firstLineTitles[currentFilter]} {_('empty_albumList_secondTitle')}</span>}
        subHeader={_('empty_filtered_albumList_subtext')}
    >
        <AlbumCreationButton onClick={initiateAlbumCreation} />
    </EmptyStatePage>
);

const AlbumListEmptyStateDesktop: React.SFC<InnerProps> = ({currentFilter}) => {
    const header = (
        <div>
            <div>{firstLineTitles[currentFilter]}</div>
            <div>{_('empty_albumList_secondTitle')}</div>
        </div>
    );

    return (
        <EmptyStatePage
            header={header}
            subHeader={_('empty_filtered_albumList_subtext')}
        >
            <CreateAlbumButtonFilled />
        </EmptyStatePage>
    );
};

type Props = InnerProps & {
    isMobileMode: boolean,
};

export const AlbumListEmptyState: React.SFC<Props> = ({isMobileMode, currentFilter, initiateAlbumCreation}) => {
    const Comp = isMobileMode ? AlbumListEmptyStateMobile : AlbumListEmptyStateDesktop;
    return (
        <Comp currentFilter={currentFilter} initiateAlbumCreation={initiateAlbumCreation} />
    );
};
