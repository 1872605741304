import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const CircleIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g strokeWidth="1" stroke={props.color || colors.defaultIconColor} fill="none" fillRule="evenodd">
            <circle cx="12" cy="12" r="11" />
        </g>
    </svg>
);
