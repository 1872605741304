import * as React from 'react';
import {colors} from '../../assets';
import {RippleAnimationElement} from './RippleAnimationElement';

const CloudsAndSunIllustration: React.SFC = () => (
    <svg width="164px" height="63px" viewBox="0 0 164 63" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle className="capture_illustration_sun" cx="24" cy="24" r="10" transform="translate(115.000000, 0.000000)" fill={colors.captureYellow}/>
            <g opacity="0.5" stroke={colors.captureBlue} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                <path d="M76.89,52.5a7.77,7.77,0,0,1,15.54,0" fill="#FFFFFF" />
                <path d="M63,53.2a3.16,3.16,0,0,1,6.32,0" fill="#FFFFFF" />
                <path d="M43.32,53.2A9.83,9.83,0,0,1,63,53.2" fill="#FFFFFF" />
                <path d="M133.64,49.7a10.32,10.32,0,0,1,20.63,0" fill="#FFFFFF" />
                <path d="M138.82,62A25.42,25.42,0,0,0,91,44.65" fill="#FFFFFF" strokeDasharray="9,5,2,5" />
                <path d="M140.4,53.2a25.42,25.42,0,0,0-50.85,0" fill="#FFFFFF" />
                <path d="M76.89,53.2H69.3" />
                <path d="M43.18,53.2H0.48" />
                <path d="M161.86,49.7h-7" />
            </g>
        </g>
    </svg>
);

export const CloudsAndSunIllustrationAnimated: React.SFC = () => (
    <RippleAnimationElement
        illustration={<CloudsAndSunIllustration />}
        rippleSelector={'circle.capture_illustration_sun'}
        rippleColor={colors.captureYellow}
        baseOpacity={0.5}
        scale={3}
        animationDuration={2}
    />
);
