import * as React from 'react';
import styled from 'styled-components';
import {PRODUCT_NAME} from '../../../../config/constants';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {maxAlbumContainerWidth} from '../../state/album/selectors';
import {goToAppStore, openAlbumInApp} from '../../utilities/appLink';
import {isMobileDevice} from '../../utilities/device';
import {TelenorCaptureFooter} from '../Common/TelenorCaptureFooter';
import {CaptureIconWhite} from '../Icons/CaptureLogo';

type Props = {
    albumID: JobID,
};

const Wrapper = styled.div`
    width: 100vw;
    min-height: 64px;
    background: linear-gradient(to right, ${colors.captureBlue}, ${colors.gradientGreen});
    color: white;
    padding: 0 8px;
    box-sizing: border-box;
`;

const ContentWrapper = styled.div`
    max-width: ${maxAlbumContainerWidth}px;
    display: flex;
    align-items: center;
    padding: 8px 0;
    box-sizing: border-box;
    margin: 0 auto;
`;

const Info = styled.div`
    flex: 1;
    padding: 0px 12px;
    box-sizing: border-box;
`;
const Header = styled.div`
    font-size: ${fontSize.large_22};
`;
const Text = styled.div`
    font-size: ${fontSize.small_14};
`;

const OpenButton = styled.div`
    min-width: 64px;
    height: 36px;
    border: 2px solid white;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    box-sizing: border-box;
    font-size: 14px;
    text-transform: uppercase;
`;
export class AlbumReceiverFooter extends React.Component<Props, {}> {

    private goToApp = () => {
        openAlbumInApp(this.props.albumID);
    }

    public render() {
        let openButton = null;
        if (isMobileDevice.Android()) {
            openButton = <OpenButton onClick={this.goToApp}>{_('open')}</OpenButton>;
        } else if (isMobileDevice.iOS()) {
            openButton = <OpenButton onClick={goToAppStore}>{_('download')}</OpenButton>;
        }

        if (openButton) {
            return (
                <Wrapper>
                    <ContentWrapper>
                        <CaptureIconWhite size={42}/>
                        <Info>
                            <Header>{PRODUCT_NAME}</Header>
                            <Text>{_('capture_footer_text_mobile')}</Text>
                        </Info>
                        {openButton}
                    </ContentWrapper>
                </Wrapper>
            );
        }

        return <TelenorCaptureFooter />;
    }
}
