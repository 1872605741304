import {HostUrl} from '../toolbox';

export class ThumbService {
    private hostUrl: HostUrl;

    constructor(hostname: string, authToken: string) {
        const commonQueryParams: DictionaryOf<any> = {
            auth: authToken,
            key: __API_KEY__,
            client_v: __VERSION__,
        };

        this.hostUrl = new HostUrl(hostname, commonQueryParams);
    }

    // area[small, medium, large] = [256, 512, 1280]
    public getThumbUrl(jobID: JobID, fileID: FileID, area: number = 256): string {
        return this.hostUrl.getPath(`/th/3/${fileID}`, {
            area,
            share: jobID,
        });
    }
}
