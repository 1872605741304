import {Action, isType} from '../common/actions';
import {ActiveOrderSetFromCache, ActiveOrderStatusChanged, OrderCreated, OrderInfoRetrieved, OrderManifestSaved} from './actions';

export type PrintOrderStatus = 'INVALID_DRAFT' | 'VALID_DRAFT' | 'PENDING_PAYMENT' | 'PAID';

export type PaymentDetails = {phoneNumber: string};

export type PrintOrder = {
    orderID: string,
    lastSavedChecksum?: string,
    status: PrintOrderStatus,
};

// after mvp: PrintHistoryState

export type PrintOrderState = {
    activeOrder?: PrintOrder,
};

const initialState: PrintOrderState = {};

export const orderReducer = (state: PrintOrderState = initialState, action: Action<any>): PrintOrderState => {

    if (isType(action, OrderCreated) || isType(action, OrderInfoRetrieved)) {
        // workaround for possible ts bug?
        const thePayload: {orderID: string, checksum?: string} = action.payload;
        const activeOrder: PrintOrder = {
            orderID: action.payload.orderID,
            status: 'INVALID_DRAFT',
            lastSavedChecksum: thePayload.checksum,
        };
        return {...state, activeOrder};
    }

    if (isType(action, ActiveOrderStatusChanged) && state.activeOrder) {
        return {activeOrder: {...state.activeOrder, status: action.payload.status}};
    }

    if (isType(action, OrderManifestSaved) && state.activeOrder) {
        return {activeOrder: {...state.activeOrder, lastSavedChecksum: action.payload.returnedChecksum}};
    }

    return state;
};
