import * as React from 'react';
import styled from 'styled-components';
import {isOpenInsideAppWebView} from '../../API/externals';
import {colors, fontSize} from '../../assets/styleConstants';
import {trackTakeoutEvent} from './analytics';

const Button = styled.div`
    width: 38px;
    height: 38px;
    border-radius: 19px;
    background-color: ${colors.captureGrey300};
    font-size: ${fontSize.medium_18};
    font-weight: bold;
    cursor: pointer;
`;

const ButtonContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const W = styled.div`
    position: fixed;
    top: 16px;
    left: 16px;
`;

const buttonClick = () => {
    trackTakeoutEvent('FAQButtonClicked');
    const faqURL = location.href + '/faq'; /* Abusing the fact that this is only used on takeout-page */
    if (isOpenInsideAppWebView()) {
        window.location.href = faqURL;
    } else {
        window.open(faqURL, '_blank');
    }
};

export const FixedTopLeftFAQButton: React.SFC = () => (
    <W>
        <Button onClick={buttonClick}><ButtonContent>?</ButtonContent></Button>
    </W>
);
