import {Store} from 'redux';
import {State} from '../../state/store';
import {connectFileMetadataSyncer} from './FileMetadataSyncer';
import {connectJobChangesSyncer} from './JobChangesSyncer';
import {connectJobInfoSyncer} from './JobInfoSyncer';
import {connectTimelineChunkSyncer} from './TimelineChunkSyncer';

export const connectSyncers = (store: Store<State>) => {
    connectJobInfoSyncer(store);
    connectJobChangesSyncer(store);
    connectTimelineChunkSyncer(store);
    connectFileMetadataSyncer(store);
};
