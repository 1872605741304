import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {colors, layout} from '../../assets/styleConstants';
import {State} from '../../state';
import {FileUploadRetry} from '../../state/uploader/actions';
import {getCurrentlyDoneRatio, isOffline, isUploaderDone} from '../../state/uploader/selectors';
import {UploadStatusBar} from './UploadStatusBar';

type ProgressBarProps = {isProgressDone: boolean, topOffset: number};
const ProgressBarWrapper = styled.div`
    width: 100%;
    height: 12px;
    background-color: ${colors.captureGrey100};
    opacity: ${(props: ProgressBarProps) => props.isProgressDone ? 0 : 1};
    transition: opacity 0.5s ease-out;
    box-shadow: inset 0px 1px 3px rgba(0,0,0,0.2);

    position: fixed;
    top: ${(props) => props.topOffset}px;
`;

type OwnProps = {
    topOffset?: number;
};

type StateProps = {
    isOffline: boolean,
    isUploading: boolean,
    uploadProgress: number,
};

type DispatchProps = {
    doRetry: () => void,
};

type Props = StateProps & DispatchProps & OwnProps;

type UploaderBoxState = {
    isClosed: boolean,
    isProgressDone: boolean,
};

class _UploadStatusPlacement extends React.Component<Props, UploaderBoxState> {
    public state: UploaderBoxState = {isClosed: true, isProgressDone: false};

    public componentWillReceiveProps(newProps: Props) {
        if (newProps.isUploading && this.state.isClosed) {
            this.setState({isClosed: false, isProgressDone: false});
        }
        if (this.props.isUploading && !newProps.isUploading) {
            setTimeout(() => {
                this.setState({...this.state, isProgressDone: true});
                setTimeout(() => {
                    this.setState({...this.state, isClosed: true});
                }, 1000);
            }, 2000);
        }
        if (newProps.isOffline && !this.props.isOffline) {
            setTimeout(this.props.doRetry, 4000);
        }
    }

    public render() {
        if (!this.props.isUploading && this.state.isClosed) {
            return null;
        }

        return (
        <ProgressBarWrapper
            isProgressDone={this.state.isProgressDone}
            topOffset={this.props.topOffset || layout.topNavBarHeight}
        >
            <UploadStatusBar
                shouldBeGray={this.props.isOffline}
                progress={this.props.uploadProgress}
                runAnimation={this.props.uploadProgress < 1}
            />
        </ProgressBarWrapper>
        );
    }
}

const mapStateToProps = (state: State): StateProps => ({
    isOffline: isOffline(state),
    isUploading: !isUploaderDone(state),
    uploadProgress: getCurrentlyDoneRatio(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doRetry: () => dispatch(FileUploadRetry()),
});

export const UploadStatusPlacementMinified = connect<StateProps, DispatchProps, OwnProps, State>(
    mapStateToProps,
    mapDispatchToProps,
)(_UploadStatusPlacement);
