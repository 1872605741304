import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {downloadFiles, initTimeline} from '../../API/job';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {Pages} from '../../routing';
import {State} from '../../state';
import {FilesOptionsTriggered} from '../../state/FilesOptions/actions';
import {
    DeleteSelectedFilesInitiated,
    TimelineFileSelectingCanceled,
    TimelineFileSelectingStarted,
    TimelineFilterSet,
} from '../../state/timeline/actions';
import {TimelineFilterMode} from '../../state/timeline/reducers';
import {
    getLastViewedRowPos,
    getSelectedTimelineFileIDs, getTimelineFilterMode,
    getTimelineGroupStyle,
    getTimelineImageGroups, getTimelineJobID,
    getTimelineScrollerGroups,
    haveTimelineMonths,
    ImageGroup, isTimelineInSelectMode, ScrollerYearGroup, TimelineGroupStyle,
} from '../../state/timeline/selectors';
import {getViewportWidth, isMobileMode} from '../../state/viewMode/selectors';
import {isMobileDevice} from '../../utilities/device';
import {Button} from '../Common/Button';
import {SlideUpChoiceMenu} from '../Common/ChoiceMenu';
import {OverflowMenu} from '../Common/OverflowMenu';
import {PageWrapper} from '../Common/PageWrapper';
import {RadioButtonGroup} from '../Common/RadioButtonGroup';
import {RippleLoaderPage} from '../Common/RippleLoader';
import {FadeInContainer} from '../Common/StyledComponents';
import {FilesOptionsPlacement} from '../FilesOptions/FilesOptionsPlacement';
import {CheckFilledIcon} from '../Icons/CheckIcon';
import {DeleteIcon} from '../Icons/DeleteIcon';
import {DownloadIcon} from '../Icons/DownloadIcon';
import {FilterIcon} from '../Icons/FilterIcon';
import {PlusIcon} from '../Icons/PlusIcon';
import {ShareIcon} from '../Icons/ShareIcon';
import {MainNavBarDesktop} from '../Navigation/MainNavBarDesktop';
import {TopNavBarMobile} from '../Navigation/TopNavBarMobile';
import {TimelineScrollerPlacement} from '../TimelineScroller/TimelineScrollerPlacement';
import {UploadButton} from '../Uploader/UploadButton';
import {UploadOverlayTimelinePlacement} from '../Uploader/UploadOverlayTimelinePlacement';
import {UploadStatusPlacementMinified} from '../Uploader/UploadStatusPlacementMinified';
import {SelectionActionDialogPlacement} from './SelectionActionDialogPlacement';
import {
    SelectionToolbarDesktop,
    SelectionToolbarMobile,
} from './SelectionToolBar';
import {TimelineEmptyState} from './TimelineEmptyState';
import {availableFilterOptions, TimelineFilterButton} from './TimelineFilterButton';
import {TimelinePhotoGroups} from './TimelinePhotoGroups';
import {LastSeenElementCleared} from '../../state/lastSeenElement/actions';

type StateProps = {
    isMobileMode: boolean,
    timelineJobID: JobID|undefined,
    imagesGrouped: ImageGroup[],
    scrollerGroups: ScrollerYearGroup[],
    selectedFiles: FileID[],
    filterBy: TimelineFilterMode,
    isInSelectMode: boolean,
    isTimelineReady: boolean,
    imageGroupStyle: TimelineGroupStyle,
    viewportWidth: number,
    previouslyViewedOffset?: number,
};

type DispatchProps = {
    fetchTimeline: (job?: JobID) => any,
    startSelectingFiles: () => any,
    initiateTimelineFiltering: () => any,
    cancelSelectMode: () => any,
    deleteFiles: () => any,
    downloadFiles: (jobID: JobID, files: FileID[]) => any,
    addFileToAlbum: () => any,
    shareFiles: () => any,
    selectFilter: (filter: TimelineFilterMode) => void,
    doClearLastKnownPos: (jobID: JobID) => void,
};

type Props = StateProps & DispatchProps;

class _TimelinePage extends React.Component<Props, {showFilterOption: boolean}> {
    public state = {showFilterOption: false};
    private toggleFilterSlideMenu = () => this.setState({showFilterOption: !this.state.showFilterOption});
    private handleTimelineFilterSet = (filter: TimelineFilterMode) => {
        this.props.selectFilter(filter);
        this.toggleFilterSlideMenu();
    }
    private getMenuItems = (showButtonText?: boolean) => {
        if (this.props.isMobileMode) {
            const selectButton = Button(_('select_files'), this.props.startSelectingFiles, {icon: CheckFilledIcon});
            const filterButton = Button(_('filter_by'), this.toggleFilterSlideMenu, {icon: FilterIcon});
            const options = [selectButton, filterButton];
            return [
                <UploadButton key={'upload'} iconColor={colors.captureGrey800} />,
                <OverflowMenu key={'overflow'} menuOptions={options} />,
            ];
        } else {
            return [
                <TimelineFilterButton key={'filter'} showText={showButtonText} />,
                <UploadButton key={'upload'} iconColor={colors.captureGrey800} showText={showButtonText} />,
            ];
        }
    }

    private getNavBar = () => {
        if (!this.props.isMobileMode) {
            return (
                <MainNavBarDesktop
                    currentPage={Pages.Timeline}
                    mainActionElements={this.getMenuItems}
                />);
        }
        if (!this.props.isInSelectMode) {  // No navbar in mobile select mode.
            return (
                <TopNavBarMobile
                    currentPage={Pages.Timeline}
                    rightElements={this.getMenuItems}
                />);
        }
    }

    private handleDownloadSelectedFiles = () => {
        if (this.props.timelineJobID && this.props.selectedFiles.length > 0) {
            this.props.downloadFiles(this.props.timelineJobID, this.props.selectedFiles);
        }
    }

    private getTimelineSelectionToolbar = () => {
        const shareButton = Button(_('share'), this.props.shareFiles, {icon: ShareIcon});
        const addToAlbumButton = Button(_('add_to_album'), this.props.addFileToAlbum, {icon: PlusIcon});
        const deleteButton = Button(_('delete_'), this.props.deleteFiles, {icon: DeleteIcon});
        const downloadButton = Button(_('download'), this.handleDownloadSelectedFiles, {icon: DownloadIcon});
        if (this.props.isMobileMode) {
            const buttons = [
                shareButton,
                addToAlbumButton,
                ... isMobileDevice.iOS() ? [] : [downloadButton],
            ];
            return (
                <SelectionToolbarMobile
                    buttons={buttons}
                    overflowOptions={[deleteButton]}
                    selectedFiles={this.props.selectedFiles}
                    cancelSelectMode={this.props.cancelSelectMode}
                />
            );
        } else {
            const buttons = [
                shareButton,
                addToAlbumButton,
                ... isMobileDevice.iOS() ? [] : [downloadButton],
                deleteButton,
            ];
            return (
                <SelectionToolbarDesktop
                    buttons={buttons}
                    selectedFiles={this.props.selectedFiles}
                    cancelSelectMode={this.props.cancelSelectMode}
                />
            );
        }
    }

    private getContent = () => {
        if (this.props.imagesGrouped.length === 0 && this.props.timelineJobID) {
            return <TimelineEmptyState jobID={this.props.timelineJobID} appliedFilter={this.props.filterBy}/>;
        }

        const notEmptyTimeline = this.props.isTimelineReady && this.props.imagesGrouped.length !== 0;
        const timelineStyle = {
            marginTop: notEmptyTimeline ? 48 : 0,
            height: '100%',
        };

        const filterSlideInMenu = this.state.showFilterOption && (
            <SlideUpChoiceMenu
                title={_('filter_by')}
                onClose={this.toggleFilterSlideMenu}
            >
                <RadioButtonGroup
                    value={this.props.filterBy}
                    options={availableFilterOptions}
                    onSelectOption={this.handleTimelineFilterSet}
                />
            </SlideUpChoiceMenu>
        );

        return (
            <>
                <div style={timelineStyle}>
                    <FadeInContainer isVisible={this.props.previouslyViewedOffset === undefined}>
                        <TimelinePhotoGroups
                            imagesGrouped={this.props.imagesGrouped}
                            isInSelectMode={this.props.isInSelectMode}
                            filterBy={this.props.filterBy}
                            groupStyle={this.props.imageGroupStyle}
                        />
                    </FadeInContainer>
                    <TimelineScrollerPlacement
                        imageGroups={this.props.imagesGrouped}
                        scrollerGroups={this.props.scrollerGroups}
                        imageGroupStyle={this.props.imageGroupStyle}
                        viewportWidth={this.props.viewportWidth}
                        isMobileMode={this.props.isMobileMode}
                    />
                </div>
                {this.props.isInSelectMode && this.getTimelineSelectionToolbar()}
                {this.props.isMobileMode && <UploadStatusPlacementMinified />}
                <FilesOptionsPlacement jobID={this.props.timelineJobID || ''} files={this.props.selectedFiles} />
                <SelectionActionDialogPlacement />
                {filterSlideInMenu}
                {this.props.timelineJobID && <UploadOverlayTimelinePlacement targetJob={this.props.timelineJobID} />}
            </>
        );
    }

    public componentWillMount() {
        this.props.fetchTimeline(this.props.timelineJobID);
    }
    public componentDidMount() {
        if (this.props.previouslyViewedOffset) {
            setTimeout(() => {
                window.scrollTo(0, this.props.previouslyViewedOffset);
                this.props.timelineJobID && this.props.doClearLastKnownPos(this.props.timelineJobID);
            }, 0);
        }
    }

    public componentWillUnmount() {
        this.props.cancelSelectMode();
    }

    public render() {
        if (!this.props.isTimelineReady) {
            return <RippleLoaderPage/>;
        }
        return (
            <PageWrapper
                navBar={this.getNavBar()}
                isContentReady={this.props.isTimelineReady}
            >
                {this.getContent()}
            </PageWrapper>
        );
    }
}

const mapStateToProps = (state: State): StateProps => {
    const filterBy = getTimelineFilterMode(state);
    const imageGroupStyle = getTimelineGroupStyle(state);
    return {
        isMobileMode: isMobileMode(state),
        timelineJobID: getTimelineJobID(state),
        imagesGrouped: getTimelineImageGroups(state, filterBy),
        scrollerGroups: getTimelineScrollerGroups(state, filterBy),
        selectedFiles: getSelectedTimelineFileIDs(state),
        filterBy,
        isInSelectMode: isTimelineInSelectMode(state),
        isTimelineReady: haveTimelineMonths(state),
        imageGroupStyle,
        viewportWidth: getViewportWidth(state),
        previouslyViewedOffset: getLastViewedRowPos(imageGroupStyle)(state, filterBy),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    fetchTimeline: async (job?: string) => initTimeline(dispatch, job),
    startSelectingFiles: () => dispatch(TimelineFileSelectingStarted()),
    initiateTimelineFiltering: () => dispatch(FilesOptionsTriggered({type: 'filter'})),
    cancelSelectMode: () => dispatch(TimelineFileSelectingCanceled()),
    deleteFiles: () => dispatch(DeleteSelectedFilesInitiated()),
    downloadFiles: (jobID: JobID, files: FileID[]) => {
        downloadFiles(dispatch, jobID, files);
        dispatch(TimelineFileSelectingCanceled());
    },
    addFileToAlbum: () => dispatch(FilesOptionsTriggered({type: 'add_to_album'})),
    shareFiles: () => dispatch(FilesOptionsTriggered({type: 'share'})),
    selectFilter: (filter: TimelineFilterMode) => dispatch(TimelineFilterSet(filter)),
    doClearLastKnownPos: (jobID: JobID) => dispatch(LastSeenElementCleared(jobID)),
});

export const TimelinePage = connect(mapStateToProps, mapDispatchToProps)(
    _TimelinePage,
);
