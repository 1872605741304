import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {isMinSky} from '../../../../config/constants';
import {logout} from '../../API/login';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize, layout} from '../../assets/styleConstants';
import {Pages} from '../../routing';
import {NavigateTo, PageDescription} from '../../state/routing/actions';
import {State} from '../../state/store';
import {isMobileMode} from '../../state/viewMode/selectors';
import {goToOpenWeb} from '../../utilities/appLink';
import {withoutTheBools} from '../../utilities/arrayUtils';
import {Button, ButtonProps} from '../Common/Button';
import {IconTextListButton} from '../Common/IconTextButton';
import {CaptureLogoHorizontal} from '../Icons';
import {AlbumIconHeart} from '../Icons/AlbumIcon';
import {BurgerIconAnimated} from '../Icons/BurgerIconAnimated';
import {DeleteIcon} from '../Icons/DeleteIcon';
import {DocIcon} from '../Icons/DocIcon';
import {EmptyIcon} from '../Icons/EmptyIcon';
import {ExitIcon} from '../Icons/ExitIcon';
import {GearIconFilled} from '../Icons/GearIcon';
import {PhotoIconFilled} from '../Icons/PhotoIcon';

const Logo = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 16px 16px;
`;

const InvisibleClickTarget = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: ${(props: {isExpanded: boolean}) => props.isExpanded ? 'block' : 'none'};
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

type MenuWrapperProps = {isExpanded: boolean, isMobile: boolean};
const MenuWrapper = styled.div`
    position: fixed;
    top: ${(props: MenuWrapperProps) => props.isMobile ? 0 : layout.topNavBarHeight}px;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.95);
    width: 240px;
    box-shadow: rgba(0,0,0,0.2) 0px 3px 4px;
    padding: 16px 0;
    box-sizing: border-box;
    transform: ${(props) => props.isExpanded ? 'translateX(0)' : 'translateX(-242px)'};
    transition: transform 0.2s;
    transform-origin: left top;
    cursor: initial;
    font-size: ${fontSize.medium_18};
`;
const BurgerWrapper = styled.div`
    height: 24px;
`;

const MenuGroupWrapper = styled.div`
    padding: 12px 0;
    width: 100%;

    &:first-of-type{
        padding-top: 24px;
    }
    &:last-of-type{
        font-weight: lighter;
    }
`;
const Separator = styled.div`
    width: 56px;
    margin-left: 16px;
    border-top: 1px solid ${colors.captureGrey300};
`;
const ItemWrapper = styled.div`
    width: 100%;
    padding: 4px 0;
    border-left: 8px solid ${(props: {highlightItem: boolean}) => props.highlightItem ? colors.captureBlue : 'transparent'};
    box-sizing: border-box;
`;

type DispatchProps = {
    goTo: (page: PageDescription) => any,
    doLogout: () => any,
};
type StateProps = {
    isMobile: boolean,
};
type OwnProps = {
    highlightIcon?: boolean,
    currentPage?: PageDescription,
};
type Props = OwnProps & DispatchProps & StateProps;

type LocalState = {isMenuExpanded: boolean};
class _SlideInBurgerMenu extends React.Component<Props, LocalState> {
    public state: LocalState = {isMenuExpanded: false};

    private expandMenu = (e: any) => {
        e.stopPropagation();
        this.setState({isMenuExpanded: true});
    }
    private collapseMenu = () => {
        this.setState({isMenuExpanded: false});
    }
    private toggleMenu = (e: any) => {
        this.state.isMenuExpanded ? this.collapseMenu() : this.expandMenu(e);
    }
    private handleOptionClick = (opt: ButtonProps) => {
        opt.onClick();
        this.collapseMenu();
    }
    private onClickOutside = (e: any) => {
        e.stopPropagation();
        this.collapseMenu();
    }
    private transitionToHandlerFactory = (page: PageDescription) => {
        return () => this.props.goTo(page);
    }

    private createMenuItem = (opt: ButtonProps) => (
        <ItemWrapper key={opt.text} highlightItem={opt.isActive}>
            <IconTextListButton
                onClick={() => this.handleOptionClick(opt)}
                icon={opt.icon || EmptyIcon}
                text={opt.text}
                color={opt.isActive ? colors.captureBlue : colors.captureGrey800}
                hoverColor={colors.captureBlue}
            />
        </ItemWrapper>
    )

    public render() {
        const menuLinksGroup1: ButtonProps[] = [
            Button(_('timeline'), this.transitionToHandlerFactory(Pages.Timeline),
                {icon: PhotoIconFilled, isActive: Pages.Timeline === this.props.currentPage}),
            Button(_('albums'), this.transitionToHandlerFactory(Pages.Albums),
                {icon: AlbumIconHeart, isActive: Pages.Albums === this.props.currentPage}),
        ];
        const menuLinksGroup2: ButtonProps[] = withoutTheBools([
            Button(_('settings'), this.transitionToHandlerFactory(Pages.Settings),
                {icon: GearIconFilled, isActive: Pages.Settings === this.props.currentPage}),
            Button(_('deleted_items'), this.transitionToHandlerFactory(Pages.Trash),
                {icon: DeleteIcon, isActive: Pages.Trash === this.props.currentPage}),
            isMinSky && Button(_('documents'), this.transitionToHandlerFactory(Pages.Documents),
                {icon: DocIcon, isActive: Pages.Documents === this.props.currentPage}),
        ]);
        const menuLinksGroup3: ButtonProps[] = [
            Button(_('log_out'), this.props.doLogout, {icon: ExitIcon, isActive: false}),
        ];

        return (
            <div>
                <InvisibleClickTarget isExpanded={this.state.isMenuExpanded} onClick={this.onClickOutside}/>
                <BurgerWrapper onClick={this.toggleMenu}>
                    <BurgerIconAnimated
                        isExpanded={this.state.isMenuExpanded}
                        iconColor={this.props.highlightIcon ? colors.captureBlue : ''}
                    />
                </BurgerWrapper>
                <MenuWrapper isExpanded={this.state.isMenuExpanded} isMobile={this.props.isMobile}>
                    {this.props.isMobile && <Logo><CaptureLogoHorizontal /></Logo>}
                    <MenuGroupWrapper>{menuLinksGroup1.map(this.createMenuItem)}</MenuGroupWrapper>
                    <Separator />
                    <MenuGroupWrapper>{menuLinksGroup2.map(this.createMenuItem)}</MenuGroupWrapper>
                    <Separator />
                    <MenuGroupWrapper>{menuLinksGroup3.map(this.createMenuItem)}</MenuGroupWrapper>
                </MenuWrapper>
            </div>
        );
    }
}

const stateToProps = (state: State): StateProps => ({
    isMobile: isMobileMode(state),
});
const dispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    goTo: (page: PageDescription) => dispatch(NavigateTo(page)),
    doLogout: () => {logout(dispatch).then(goToOpenWeb); },
});

export const SlideInBurgerMenu = connect(
    stateToProps,
    dispatchToProps,
)(_SlideInBurgerMenu);
