import {RoutePath} from '../routing';
import {getObfuscated} from './hashing';

/**
 * Tool-method to parse `window.location.search`
 * use: `const searchParams ? parseSearchString(window.location.search)`;
 *
 * @param {string} search
 * @returns {DictionaryOf<string>}
 */
export const parseSearchString = (search: string): DictionaryOf<string> => {
    return search.substring(1).split('&').reduce(
        (dict: DictionaryOf<string>, kv: string) => {
            const [key, value] = kv.split('=');
            if (value !== undefined) {
                dict[key] = decodeURIComponent(value);
            }
            return dict;
        },
        {},
    );
};

export const getAnalyticsSafeURL = (url: string) => {
    const secretPart = url.match(RoutePath.getLoggedInAlbumPath('([^/]*)'))
    || url.match(RoutePath.getNotLoggedInAlbumPath('([^/]*)'))
    || url.match(RoutePath.getShareReceiverPath('([^/]*)'));

    return secretPart ? url.replace(secretPart[1], 'hash_' + getObfuscated(secretPart[1])) : url;
};
