import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {colors} from '../../assets';
import {AlbumFileComments} from '../../routing/pages';
import {NavigateTo} from '../../state';

type OwnProps = {
    albumID: JobID,
    fileID: FileID,
};

type DispatchProps = {
    onClick: () => any,
};

type Props = DispatchProps & OwnProps;

const ViewMoreComments = styled.span`
    color: ${colors.captureBlue};
    padding: 8px;
    padding-left: 0;
    cursor: pointer;
`;

const _DisplayCommentOverlayTrigger: React.SFC<Props>
    = (props) => <ViewMoreComments onClick={props.onClick}>{props.children}</ViewMoreComments>;

const dispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => ({
    onClick: () => dispatch(NavigateTo(AlbumFileComments(ownProps.albumID, ownProps.fileID))),
});

export const DisplayCommentOverlayTrigger = connect(
    null,
    dispatchToProps,
)(_DisplayCommentOverlayTrigger);
