import {_} from '../assets/localizedStrings';

const months = [
    _('january'),
    _('february'),
    _('march'),
    _('april'),
    _('may'),
    _('june'),
    _('july'),
    _('august'),
    _('september'),
    _('october'),
    _('november'),
    _('december'),
];

const monthsAbbreviated = [
    _('jan'),
    _('feb'),
    _('mar'),
    _('apr'),
    _('may'),
    _('jun'),
    _('jul'),
    _('aug'),
    _('sep'),
    _('oct'),
    _('nov'),
    _('dec'),
];

export const getMonthFromNumberString = (month: string) => getMonthFromNumber(parseInt(month, 10));
export const getShortMonthFromNumberString = (month: string) => getShortMonthFromNumber(parseInt(month, 10));

export const getMonthFromNumber = (month: number) => months[month - 1];
export const getShortMonthFromNumber = (month: number) => monthsAbbreviated[month - 1];

export const MonthAsNumber = (m: Month) => m.year * 12 + m.month;
export const compareMonth = (a: Month, b: Month) => MonthAsNumber(a) - MonthAsNumber(b);

const digitToString = (digit: number): string => {
    return ('00' + digit).substr(-2);
};

export const msToHMS = (ms: number): string => {
    const seconds = Math.floor(ms / 1000);
    const secDiff = Math.floor(seconds % 60);
    const minDiff = Math.floor((seconds % 3600) / 60);
    const hDiff = Math.floor(seconds / 3600);

    const timestamp = (hDiff > 0 ? [hDiff] : []).concat([minDiff, secDiff]);
    return timestamp.map((d) => digitToString(d)).join(':');
};
