import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {postComment} from '../../API/album';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {State} from '../../state';
import {CommentStatus} from '../../state/files/reducer';
import {getCommentStatus, getCurrentCommentText} from '../../state/files/selectors';
import {isMobileDevice} from '../../utilities/device';

type FormProps = {isEmpty: boolean, hasFocus: boolean, maxHeight: number};
const CommentForm = styled.form`
    margin: 0 8px 8px;
    display: flex;
    border: 1px solid ${(props: FormProps) => props.hasFocus ? colors.captureBlue : colors.captureGrey400};
    background-color: white;
    position: relative;
    flex: 1;
    font-size: ${fontSize.medium_16};

    &:hover {
        border: 1px solid ${(props) => props.hasFocus ? colors.captureBlue : colors.captureGrey500};
    }

    &>textarea {
        height: ${(props) => props.isEmpty ? '38px!important' : 'auto'};
        max-height: ${(props) => props.maxHeight}px;
        flex: 1;
        overflow-y: auto;
        border: none;
        resize: none;
        outline: none;
        box-sizing: border-box;
        padding: 8px;
        padding-right: 64px;
        font-size: ${fontSize.medium_16};
    }
`;

const PublishBtn = styled.input`
    background: ${(props: {color: string}) => props.color};
    border-radius: 4px;
    border: 1px solid ${(props) => props.color};
    color: white;
    min-width: 48px;
    height: 30px;
    margin: 4px;
    font-size: ${fontSize.small_12};
    text-transform: uppercase;
    -webkit-appearance: none;
    position: absolute;
    right: 0;
    bottom: 0;
`;
const StatusElement = styled.div`
    margin-bottom: 12px;
`;
const ErrorMessage = styled.span`
    color: ${colors.captureMagenta};
    font-size: 11px;
    padding: 4px 16px;
`;

type OwnProps = {
    albumID: JobID,
    fileID: FileID,
    maxHeight: number,
};

type StateProps = {
    commentStatus: CommentStatus,
    comment: string,
};

type DispatchProps = {
    handleCommentSubmit: (commentText: string) => any,
};

type Props = OwnProps & StateProps & DispatchProps;

type ComponentState = {comment: string, hasFocus: boolean};

class _CommentInputComponent extends React.Component<Props, ComponentState> {
    public state: ComponentState = {comment: '', hasFocus: false};
    private textAreaElement: HTMLTextAreaElement|null = null;

    public componentWillReceiveProps(newProps: Props) {
        // If the comment-text was changed in state (ie as the comment was successfully posted) - this change should
        // be reflected here
        if (this.props.comment !== newProps.comment && this.state.comment !== newProps.comment) {
            this.setState({...this.state, comment: newProps.comment});
        }
    }

    private handleTextChange = (evt: React.SyntheticEvent<HTMLTextAreaElement>) => {
        if (this.state.comment !== evt.currentTarget.value) {
            this.setState({...this.state, comment: evt.currentTarget.value});
        }

        if (this.textAreaElement) {
            this.textAreaElement.style.height = '38px';
            this.textAreaElement.style.height = (this.textAreaElement.scrollHeight) + 'px';
        }
    }

    private handleSubmit = (e: React.FormEvent<any>) => {
        if (this.state.comment) {
            this.props.handleCommentSubmit(this.state.comment);
        }
        e.preventDefault();
        return false;
    }
    private handleFocus = () => {
        this.setState({...this.state, hasFocus: true});
    }
    private handleBlur = () => {
        this.setState({...this.state, hasFocus: false});
    }
    private bindTextAreaElement = (me: HTMLTextAreaElement|null) => {
        this.textAreaElement = me;
    }
    private handleInputKeyDown = (evt: any) => {
        if (evt.keyCode === 13 && !evt.shiftKey && this.state.comment && !isMobileDevice.any()) {
            this.props.handleCommentSubmit(this.state.comment);
        }
    }

    public render() {
        let errorMessage = null;
        if (this.props.commentStatus === CommentStatus.ERROR) {
            errorMessage = <StatusElement><ErrorMessage>{_('comment_error_message')}</ErrorMessage></StatusElement>;
        }

        let textColor = colors.captureGrey800;
        let btnColor = colors.captureBlue;
        if (this.props.commentStatus === CommentStatus.PENDING) {
            textColor = colors.captureGrey400;
            btnColor = colors.captureGrey400;
        }

        let publishBtn = null;
        if (this.state.comment.length !== 0) {
            publishBtn = (
                <PublishBtn
                    className="publish-btn"
                    type="submit"
                    value={_('post')}
                    disabled={this.props.commentStatus === CommentStatus.PENDING}
                    color={btnColor}
                />
            );
        }

        return (
            <div className="comment-input">
                {errorMessage}
                <CommentForm
                    className="comment-form"
                    onSubmit={this.handleSubmit}
                    isEmpty={this.state.comment.length === 0}
                    hasFocus={this.state.hasFocus}
                    maxHeight={this.props.maxHeight}
                >
                    <textarea
                        ref={this.bindTextAreaElement}
                        className="comment-area"
                        value={this.state.comment}
                        placeholder={_('write_your_comment')}
                        onChange={this.handleTextChange}
                        readOnly={this.props.commentStatus === CommentStatus.PENDING}
                        style={{color: textColor}}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onKeyDown={this.handleInputKeyDown}
                    />
                    {publishBtn}
                </CommentForm>
            </div>
        );
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    comment: getCurrentCommentText(state, ownProps.fileID),
    commentStatus: getCommentStatus(state, ownProps.fileID),
});
const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    handleCommentSubmit: (commentText: string) => (
        postComment(dispatch, ownProps.albumID, ownProps.fileID, commentText)
    ),
});

export const CommentInputComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_CommentInputComponent);
