import {TakeoutFile} from '../../API/services/AppService';
import {createActionCreator, createVoidActionCreator} from '../common/actions';

export const TakeoutInitiated = createActionCreator<{contactEmail: string}>('TAKEOUT_INITIATED');

export const TakeoutStatusFetchStarted = createVoidActionCreator('TAKEOUT_STATUS_FETCH_STARTED');
export const TakeoutIsNotStarted = createVoidActionCreator('TAKEOUT_STATUS_FETCHED_NOT_STARTED');
export const TakeoutIsBaking = createActionCreator<{contactEmail: string}>('TAKEOUT_STATUS_FETCHED_IS_BAKING');
type StatusFetchedReady = {
    contactEmail: string,
    files: TakeoutFile[],
    filesExpires: number,
    rebakeAllowedAt: number,
};
export const TakeoutIsReady = createActionCreator<StatusFetchedReady>('TAKEOUT_STATUS_FETCHED_IS_READY');
export const TakeoutStatusFetchFailed = createVoidActionCreator('TAKEOUT_STATUS_FETCH_FAILED');
