import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets';
import {fontSize} from '../../assets/styleConstants';
import {FullscreenOverlay} from '../Common/FullscreenOverlay';

type Props = {
    mainText: string;
    subText: React.ReactElement<any>;
    illustration: React.ReactElement<any>;
    buttons: React.ReactNode[];
};

const Wrapper = styled.div`
    width: 70%;
    max-width: 569px;
    background: white;
    padding: 54px 44px 24px;
    box-sizing: border-box;
    border-radius: 3px;
`;

const InfoContent = styled.div`
    display: flex;
    margin-bottom: 16px;
`;
const LeftSection = styled.div`
    margin-right: 32px;
`;
const RightSection = styled.div`
    flex: 1;
`;
const Header = styled.div`
    font-size: ${fontSize.xLarge_32};
    color: ${colors.captureBlue};
    margin-bottom: 8px;
`;
const TextContent = styled.div`
    font-size: ${fontSize.small_14};
`;
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;
const ButtonDiv = styled.div`
    margin: 8px;
    width: 240px;
`;

export class PopupHorizontalLayout extends React.Component<Props, {}> {

    public render() {
        const buttons = this.props.buttons.map((button: React.ReactNode, i) => <ButtonDiv key={i}>{button}</ButtonDiv>);
        return (
            <FullscreenOverlay>
                <Wrapper>
                    <InfoContent>
                        <LeftSection>
                            {this.props.illustration}
                        </LeftSection>
                        <RightSection>
                            <Header>{this.props.mainText}</Header>
                            <TextContent>{this.props.subText}</TextContent>
                        </RightSection>
                    </InfoContent>
                    <ButtonWrapper>{buttons}</ButtonWrapper>
                </Wrapper>
            </FullscreenOverlay>
        );
    }
}
