import {createSelector} from 'reselect';
import {
    CarouselViewerNode, ViewerFiles,
} from '../../components/CarouselView';
import {getElementIndex} from '../../utilities/arrayUtils';
import {elementSizeCalculator} from '../../utilities/elementSizeCalculator';
import {AlbumFile, getAlbumFiles} from '../album/selectors';
import {CaptureFile, JobFile} from '../files/reducer';
import {getCaptureFilesForJob, makeThumbForJobFilesSelector} from '../files/selectors';
import {isShareJob, isStoryJob} from '../jobInfo/selectors';
import {getLastSeenElementForJob} from '../lastSeenElement/selectors';
import {State} from '../store';
import {getFilteredTimelineFiles} from '../timeline/selectors';
import {getViewportHeight, getViewportWidth, isMobileMode} from '../viewMode/selectors';
import {getCurrentViewInfo, isFullscreenMode, isSideModuleExpanded} from './pureSelectors';

type JobType = 'timeline' | 'album' | 'share';
export const getCurrentViewJobType = (state: State): JobType|undefined => {
    const current = getCurrentViewInfo(state);

    if (current !== undefined) {
        const {jobID} = current;
        if (isStoryJob(state, jobID)) {
            return 'album';
        } else if (isShareJob(state, jobID)) {
            return 'share';
        } else {
            return 'timeline';
        }
    }
};

const getCurrentViewerFiles = (state: State): ViewerFiles|undefined => {
    const type = getCurrentViewJobType(state);
    const current = getCurrentViewInfo(state);

    if (type && current) {
        switch (type) {
            case 'album':
                return {type, files: getAlbumFiles(state, current.jobID)};
            case 'share':
                return {type, files: [...getCaptureFilesForJob(state, current.jobID)].reverse()};
            case 'timeline':
                return {type, files: getFilteredTimelineFiles(state)};
        }
    }
};

export const getCurrentViewerNode = createSelector(
    getCurrentViewInfo,
    getCurrentViewerFiles,
    (currentViewInfo, viewerFiles): CarouselViewerNode|undefined => {
        if (currentViewInfo !== undefined && viewerFiles !== undefined) {
            const currentOffset = getElementIndex(viewerFiles.files, (f) => f.fileID === currentViewInfo.fileID);

            if (currentOffset !== -1) {
                const prevFile = viewerFiles.files[currentOffset - 1];
                const nextFile = viewerFiles.files[currentOffset + 1];

                // AlbumFile is superset of CaptureFile,
                // which makes ts infer type as 'album' instead of 'timeline' | 'share' | 'album'
                return {
                    type: viewerFiles.type,
                    file: viewerFiles.files[currentOffset],
                    prev: prevFile ? prevFile.fileID : null,
                    next: nextFile ? nextFile.fileID : null,
                    first: viewerFiles.files[0].fileID,
                    last: viewerFiles.files[viewerFiles.files.length - 1].fileID,
                } as CarouselViewerNode;
            }
        }
    },
);

type Size = {width: number, height: number};

const getCarouselContainerSize = createSelector(
    isMobileMode,
    getViewportWidth,
    getViewportHeight,
    isSideModuleExpanded,
    isFullscreenMode,
    (
        isMobile: boolean,
        viewWidth: number,
        viewHeight: number,
        isExpanded: boolean,
        isFullscreen: boolean,
    ): Size => {
        const bottomPadding = isMobile || isFullscreen ? 0 : 80;
        return {
            width: viewWidth - (isExpanded && !(isMobile || isFullscreen) ? 360 : 0),
            height: viewHeight - bottomPadding,
        };
    },
);

export const getFullscreenAlbumFileSizeProvider = createSelector(
    isMobileMode,
    getCarouselContainerSize,
    (isMobile, containerSize) => {
        const cal = elementSizeCalculator(containerSize, isMobile ? 0.2 : 0);

        return (file: JobFile): {width: number, height: number} => {
            if (file.width === undefined || file.height === undefined) {
                return containerSize;
            }

            return cal({
                width: file.width,
                height: file.height,
            });
        };
    },
);

export const getTVFileSizeProvider = createSelector(
    getViewportHeight,
    getViewportWidth,
    (height, width) => {
        const containerSize = {width, height};
        const cal = elementSizeCalculator(containerSize);

        return (file: JobFile): {width: number, height: number} => {
            if (file.width === undefined || file.height === undefined) {
                return containerSize;
            }

            return cal({
                width: file.width,
                height: file.height,
            });
        };
    },
);

export type ThumbFile = {
    fileID: FileID,
    url: string,
};

const getCarouselThumbURLs = (state: State): DictionaryOf<string> => {
    const current = getCurrentViewInfo(state);
    if (current !== undefined) {
        const urlSelector = makeThumbForJobFilesSelector(256);
        return urlSelector(state, current.jobID);
    }

    return {};
};

export const getCurrentThumbFiles = createSelector(
    getCurrentViewerFiles,
    getCarouselThumbURLs,
    (viewerFiles, thumbURLs): ThumbFile[] => {
        if (viewerFiles !== undefined) {
            const files: Array<AlbumFile|CaptureFile> = viewerFiles.files;
            return files.map((f) => ({
                fileID: f.fileID,
                url: thumbURLs[f.fileID],
            }));
        }

        return [];
    },
);

export const getCurrentThumbIndex = createSelector(
    getCurrentThumbFiles,
    getCurrentViewInfo,
    (thumbFiles, currentViewInfo): number => {
        let index =  -1;
        if (currentViewInfo) {
            thumbFiles.forEach((file, i) => {if (file.fileID === currentViewInfo.fileID) {index = i; }});
        }
        return index;
    },
);

export const getLastViewAlbumFileIndex = (state: State, albumID: JobID) => {
    const lastSeenFile = getLastSeenElementForJob(state, albumID);
    if (lastSeenFile && isStoryJob(state, albumID)) {
        return getElementIndex(getAlbumFiles(state, albumID), (f) => {
            return f.fileID === lastSeenFile;
        });
    }
};
