import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

type ButonProps = IconProps & {
    filled: boolean,
};

/* tslint:disable:max-line-length jsx-self-close */
const RadioButton: React.SFC<ButonProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g stroke={props.color || colors.captureBlue}>
                <circle cx="8" cy="8" r="7.5"></circle>
                {props.filled && <circle strokeWidth="3" fill={props.color || colors.captureBlue} cx="8" cy="8" r="4"></circle>}
            </g>
        </g>
    </svg>
);

export const RadioButtonIcon: React.SFC<IconProps> = (props) => <RadioButton {...props} filled={false} />;
export const RadioButtonFilledIcon: React.SFC<IconProps> = (props) => <RadioButton {...props} filled={true} />;
