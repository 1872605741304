import {createSelector} from 'reselect';
import {PrintRoutePath} from '../../../print/routing/printRoutePath';
import {RoutePath} from '../../routing/routePath';
import {getNextUnfetchedAlbumID} from '../albumList/selectors';
import {JobInfoStatus} from '../jobInfo/reducer';
import {getJobUUIDFromURL, getStatusOfJob} from '../jobInfo/selectors';
import {getCurrentLocation} from '../routing/selectors';
import {State} from '../store';
import {getTimelineInitialLastChangeEventID, getTimelineJobID} from '../timeline/selectors';

const isPrioritisingAlbums = (state: State): boolean => state.jobSyncing.albumListDependentComponentsMounted > 0;

export const getTheJobCurrentlyDisplayed: (state: State) => JobID|undefined = createSelector(
    getCurrentLocation,
    getTimelineJobID,
    getNextUnfetchedAlbumID,
    isPrioritisingAlbums,
    (currentURL: string|undefined, timelineJobID: JobID|undefined, albumJob?: JobID|undefined, forceAlb?: boolean) => {
        // Print have it's own rules - this is fun:
        if (__ARTIFACT__ === 'print') {
            const match = new RegExp(PrintRoutePath.getCaptureSelectPicturesPath('([^/]*)', '.*')).exec(currentURL || '');
            return match ? match[1] : undefined;
        }

        if (forceAlb && albumJob) {
            return albumJob;
        }
        if (!currentURL) {
            return timelineJobID;
        }
        if (currentURL === '/' + RoutePath.Albums) {
            return albumJob;
        }
        return getJobUUIDFromURL(currentURL) || timelineJobID;
    },
);

const getJobInfoStatusForCurrentJob = (state: State): JobInfoStatus => (
    getStatusOfJob(state, getTheJobCurrentlyDisplayed(state) || '')
);

export const getTheJobCurrentlyFetchingChangesFor: (state: State) => JobID|undefined = createSelector(
    getTheJobCurrentlyDisplayed,
    getJobInfoStatusForCurrentJob,
    getTimelineJobID,
    getTimelineInitialLastChangeEventID,
    (currentlyInFocus: JobID|undefined, status, timelineJobID, timelineEventID) => {
        // Only fetch jobChanges when JobInfo is fetched
        if (status !== JobInfoStatus.FETCHED) {
            return;
        }

        if (currentlyInFocus === timelineJobID && timelineEventID === -1) {
            return;
        }

        return currentlyInFocus;
    },
);

export const getInitialChangeEventID = (state: State, jobID: JobID): number => {
    if (getTimelineJobID(state) === jobID) {
        return getTimelineInitialLastChangeEventID(state);
    }
    return 0;
};
