import * as React from 'react';
import {connect} from 'react-redux';
import {trackEvent} from '../../analytics';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {State} from '../../state/store';
import {getTimelineJobID} from '../../state/timeline/selectors';
import {getConnectedInstance} from '../../state/uploader/uploadQueue';
import {IconButton, TextButton} from '../Common/IconTextButton';
import {UploadIcon} from '../Icons/UploadCloud';
import {FileAcceptElement} from './FileAcceptElement';

type OwnProps = {
    iconColor?: string,
    showText?: boolean,
    targetJob?: JobID,
};
type StateProps = {
    targetJob: JobID,
};
type Props = OwnProps & StateProps;

class _UploadButton extends React.Component<Props, {}> {

    private fileHandler = (files: File[]) => {
        trackEvent('Uploader', 'FilesAddedBySelection', '', files.length);
        getConnectedInstance().addFiles(files, this.props.targetJob, '');
    }
    private trackUploadButtonClick = () => {
        trackEvent('Uploader', 'UploadButton_click');
    }

    public render() {
        const textButton = <TextButton onClick={this.trackUploadButtonClick} text={_('upload')} />;
        const iconButton = (
            <IconButton
                onClick={this.trackUploadButtonClick}
                icon={UploadIcon}
                hoverColor={colors.captureBlue}/>
        );

        return (
            <FileAcceptElement fileHandler={this.fileHandler}>
                {this.props.showText ? textButton : iconButton}
            </FileAcceptElement>
        );
    }
}

const stateToProps = (state: State, ownProps: OwnProps) => ({
    targetJob: ownProps.targetJob || getTimelineJobID(state) || '',
});

export const UploadButton = connect(stateToProps)(_UploadButton);
