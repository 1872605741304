import * as React from 'react';
import styled from 'styled-components';
import {appLanguages, getCurrentLocale, setCurrentLocale} from '../../../../config/constants';
import {RadioButtonGroup, RadioButtonOption} from '../Common/RadioButtonGroup';

const Wrapper = styled.div`
    padding-left: 8px;
`;

const ColumnWrapper = styled.div`
    display: inline-block;
    margin-right: 40px;
    vertical-align: top;
`;

export class LanguageSelect extends React.Component {
    private sortedLangOptions = () => {
        const langOptions: Array<RadioButtonOption<string>> =
            appLanguages.map((lang) => ({text: lang.name, value: lang.key}));
        return langOptions.sort((a, b) => a.text.localeCompare(b.text));
    }
    private createLangColumn = (languages: Array<RadioButtonOption<string>>) => (
        <ColumnWrapper>
            <RadioButtonGroup
                value={getCurrentLocale()}
                options={languages}
                onSelectOption={setCurrentLocale}
            />
        </ColumnWrapper>
    )

    public render() {
        const splitIndex = Math.ceil(this.sortedLangOptions().length / 2);

        const langGroup1 = this.sortedLangOptions().slice(0, splitIndex);
        const langGroup2 = this.sortedLangOptions().slice(splitIndex);

        return (
            <Wrapper>
                {this.createLangColumn(langGroup1)}
                {this.createLangColumn(langGroup2)}
            </Wrapper>
        );
    }
}
