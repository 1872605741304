import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */

export const CalendarIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={props.color || colors.defaultIconColor} transform="translate(4.000000, 3.000000)">
                <path d="M12.3209877,9.04078365 L8,9.04078365 L8,13.0546904 L12.3209877,13.0546904 L12.3209877,9.04078365 L12.3209877,9.04078365 Z M11.4567901,0.210188761 L11.4567901,1.81575147 L4.54320988,1.81575147 L4.54320988,0.210188761 L2.81481481,0.210188761 L2.81481481,1.81575147 L1.95061728,1.81575147 C0.991358025,1.81575147 0.230864198,2.53825469 0.230864198,3.42131418 L0.222222222,14.6602531 C0.222222222,15.5433126 0.991358025,16.2658158 1.95061728,16.2658158 L14.0493827,16.2658158 C15,16.2658158 15.7777778,15.5433126 15.7777778,14.6602531 L15.7777778,3.42131418 C15.7777778,2.53825469 15,1.81575147 14.0493827,1.81575147 L13.1851852,1.81575147 L13.1851852,0.210188761 L11.4567901,0.210188761 L11.4567901,0.210188761 Z M14.0493827,14.6602531 L1.95061728,14.6602531 L1.95061728,5.82965824 L14.0493827,5.82965824 L14.0493827,14.6602531 L14.0493827,14.6602531 Z"/>
            </g>
        </g>
    </svg>
);