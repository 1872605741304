import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from '../../components/Icons/index';

/* tslint:disable:max-line-length jsx-self-close */
export const FilesIllustration: React.SFC<IconProps> = (props: IconProps) => (
    <svg width={props.size} height={props.size} viewBox="0 0 100 122" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
        <g fill={props.color || colors.defaultIconColor} fillRule="nonzero">
            <ellipse cx="31.6032182" cy="69.7746154" rx="3.67935636" ry="5.49782051"></ellipse>
            <ellipse cx="68.3967818" cy="69.7746154" rx="3.67935636" ry="5.49782051"></ellipse>
            <path d="M68.3967818,78.9383013 L31.6032182,78.9383013 C30.4532967,78.9383013 29.3720565,79.4740064 28.6754317,80.3850962 C27.9807692,81.298141 27.7511774,82.4829487 28.0612245,83.585641 C28.2142857,84.1428526 32.0820251,97.2676282 50,97.2676282 C67.9218995,97.2676282 71.7857143,84.1428526 71.9427002,83.585641 C72.2488226,82.4829487 72.0231554,81.298141 71.3245683,80.3850962 C70.6318681,79.4740064 69.5467033,78.9383013 68.3967818,78.9383013 Z"></path>
            <path d="M99.1287284,26.3199359 L73.3810832,0.658878205 C73.2083987,0.490737179 73.0043171,0.35974359 72.7806122,0.265897436 C72.5549451,0.172051282 72.3175039,0.123173077 72.0761381,0.123173077 L9.52708006,0.123173077 C4.4544741,0.123173077 0.327708006,4.23285256 0.327708006,9.2888141 L0.327708006,111.933045 C0.327708006,116.987051 4.45251177,121.098686 9.52708006,121.098686 L90.4729199,121.098686 C95.5455259,121.098686 99.672292,116.989006 99.672292,111.933045 L99.672292,27.6161859 C99.672292,27.3796154 99.6232339,27.1391346 99.532967,26.9142949 C99.4368132,26.6914103 99.3014129,26.4880769 99.1287284,26.3199359 Z M79.4348509,25.7842308 C76.3912873,25.7842308 73.9167975,23.316859 73.9167975,20.2864103 L73.9167975,6.38153846 L93.3908948,25.7842308 L79.4348509,25.7842308 Z M90.4729199,117.42891 L9.52708006,117.42891 C6.48351648,117.42891 4.00902669,114.961538 4.00902669,111.93109 L4.00902669,9.28685897 C4.00902669,6.25445513 6.48547881,3.78903846 9.52708006,3.78903846 L70.2374411,3.78903846 L70.2374411,20.2864103 C70.2374411,25.3404167 74.3622449,29.4520513 79.4368132,29.4520513 L95.994898,29.4520513 L95.994898,111.93109 C95.9929356,114.963494 93.5164835,117.42891 90.4729199,117.42891 Z"></path>
        </g>
    </svg>
);
