import styled from 'styled-components';
import {colors} from '../../../common/assets';

export const CaptureTVButton = styled.button.attrs({
    tabIndex: 0,
})`
    text-transform: uppercase;
    border: 1px solid #fff;
    background-color: rgba(${colors.white_rgb}, 0.25);
    border-radius: 40px;
    padding: 12px 32px;
    display: inline-block;
    cursor: pointer;

    &:focus, &:hover {
        box-shadow: 0 0 18px 2px white;
    }
`;