import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {HOME_SITE_ADDRESS} from '../../../../config/constants';
import {_, colors} from '../../assets';
import {State} from '../../state';
import {JobPasswordProvided} from '../../state/jobInfo/actions';
import {getProvidedPassword} from '../../state/jobInfo/selectors';
import {isMobileMode} from '../../state/viewMode/selectors';
import {getAlbumPasswordHash} from '../../utilities/hashing';
import {PageEventTrigger} from '../Analytics/PageEventTrigger';
import {CaptureIcon} from '../Icons/CaptureLogo';
import {InfoContainer, InfoProps} from './InfoContainer';

const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin-top: 28px;
`;

const PasswordInput = styled.input`
    width: 238px;
    height: 48px;
    box-shadow: inset 0 1px 4px 0 rgba(0,0,0,0.40);
    border-radius: 2px;
    border: ${(props: {error: boolean}) => props.error ? '1px solid ' + colors.captureMagenta : 'none'};
    -webkit-appearance: none;

    text-align: center;
    align-self: center;
    box-sizing: border-box;

    font-size: 16px;
`;

const PasswordSubmit = styled.button`
    width: 238px;
    height: 48px;
    background: ${colors.captureBlue};
    border-radius: 2px;
    border: none;
    font-size: 14px;
    color: white;
    text-transform: uppercase;

    align-self: center;
    margin-top: 16px;
`;

const ErrorMessage = styled.div`
    margin-top: 18px;
    font-size: 14px;
    color: ${colors.captureMagenta};
    text-align: center;

    align-self: center;
`;

const Description = styled.div`
    font-size: 14px;
    margin-top: 50px;
    padding: 0 16px;
    max-width: ${ (props: {mobile: boolean}) => props.mobile ? '100%' : '480px'};
    line-height: 21px;
    letter-spacing: -0.24px;
`;

type ProvidePasswordState = {
    password: string,
    error: boolean,
};

type StateProps = {
    isMobileMode: boolean
    incorrectPassword: boolean,
};

type DispatchProps = {
    submitPassword: (pwd: string) => any,
};

type OwnProps = {
    jobID: JobID,
    description: string,
    passwordTransformer?: (password: string, jobID: JobID) => string,
};

type ProvidePasswordProps = StateProps & DispatchProps & OwnProps;

class _ProvidePassword extends React.Component<ProvidePasswordProps, ProvidePasswordState> {
    private infoProps: InfoProps = {
        infoIcon: (<CaptureIcon size={78}/>),
        infoTitle: _('password_required'),
        extLink: {
            link: HOME_SITE_ADDRESS,
            text: _('capture_more_link'),
        },
    };

    public state: ProvidePasswordState = {
        password: '',
        error: this.props.incorrectPassword,
    };

    private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({...this.state, password: event.currentTarget.value});
    }

    private handleSubmit = (event: React.FormEvent<any>) => {
        this.props.submitPassword(this.state.password);
        event.preventDefault();
    }

    public render() {
        return (
            <InfoContainer {... this.infoProps}>
                <FormWrapper onSubmit={this.handleSubmit}>
                    <PasswordInput
                        type="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        placeholder={_('password_input_placeholder')}
                        error={this.state.error}
                    />
                    <PasswordSubmit type="submit">{_('password_required_submit')}</PasswordSubmit>
                    {this.state.error ? <ErrorMessage>{_('password_incorrect')}</ErrorMessage> : null}
                </FormWrapper>
                <Description mobile={this.props.isMobileMode}>
                    {this.props.description}
                    <br /> <br />
                    {_('capture_create_user_description')}
                </Description>
            </InfoContainer>
        );
    }
}

const mapStateToProps = (state: State, props: OwnProps): StateProps => {
    return {
        isMobileMode: isMobileMode(state),
        incorrectPassword: getProvidedPassword(state, props.jobID) !== undefined,
    };
};

const dispatchToProps = (dispatch: Dispatch, props: OwnProps): DispatchProps => ({
    submitPassword: (pwd: string) => dispatch(JobPasswordProvided({
        job: props.jobID,
        password: props.passwordTransformer ? props.passwordTransformer(pwd, props.jobID) : pwd,
    })),
});

const ProvidePassword = connect(mapStateToProps, dispatchToProps)(_ProvidePassword);

export const ProvidePasswordAlbumPage: React.SFC<{albumID: JobID}> = (props) => (
    <PageEventTrigger context="AlbumPage" whenPageLoads={'PasswordRequired'}>
        <ProvidePassword
            jobID={props.albumID}
            description={_('album_password_required_description')}
            passwordTransformer={getAlbumPasswordHash}
        />
    </PageEventTrigger>
);

export const ProvidePasswordSharePage: React.SFC<{shareID: JobID}> = (props) => (
    <PageEventTrigger context="SharePage" whenPageLoads={'PasswordRequired'}>
        <ProvidePassword
            jobID={props.shareID}
            description={_('share_password_required_description')}
        />
    </PageEventTrigger>

);
