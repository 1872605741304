import * as React from 'react';
import styled from 'styled-components';
import {colors, fontSize} from '../../assets/styleConstants';
import {ButtonProps} from '../Common/Button';

type OptionProps = {
    isSelected: boolean,
};

const BaseOption = styled.div`
    font-size: ${fontSize.medium_16};
    text-align: center;
    user-select: none;

    border: 2px solid transparent;
    border-radius: 25px;

    padding: 4px 10px;
    margin: 10px;
`;

const EnabledOption = styled(BaseOption)`
    color: ${(props: OptionProps) => props.isSelected ? colors.captureBlue : colors.captureGrey800};
    border-color: ${(props) => props.isSelected ? colors.captureBlue : 'transparent'};
    font-weight: ${(props) => props.isSelected ? 'bold' : 'normal'};
    cursor: pointer;
`;
const DisabledOption = styled(BaseOption)`
    color: ${colors.captureGrey500};
`;

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

type Props = {
    options: ButtonProps[],
    isEnabled?: boolean, // default: true
};

const makeEnabledOption = (opt: ButtonProps) => (
    <EnabledOption key={opt.text} isSelected={opt.isActive} onClick={opt.onClick}>
        {opt.text}
    </EnabledOption>
);
const makeDisabledOption = (opt: ButtonProps) => (
    <DisabledOption key={opt.text}>
        {opt.text}
    </DisabledOption>
);

export class SettingsOptionList extends React.PureComponent<Props, {}> {
    public render() {
        const makeOption = this.props.isEnabled === false ? makeDisabledOption : makeEnabledOption;
        const optionsList = this.props.options.map(makeOption);

        return (
            <OptionsContainer>
                {optionsList}
            </OptionsContainer>
        );
    }
}
