import * as React from 'react';
import {ButtonProps, colors} from '../../assets';
import {UploadStatusBar} from './UploadStatusBar';

interface Props {
    buttons: ButtonProps[];
    disableStatusBar: boolean;
    uploadProgress: number;
    hasBorder?: boolean;
    leftButtonMargin?: number;
}

export class UploadStatusBoxFooter extends React.Component<Props, {}> {
    public render() {
        const statusInfoStyle: React.CSSProperties = {
            width: '100%',
            height: 64,
            padding:
                '16px 24px 16px ' + (this.props.leftButtonMargin || 24) + 'px',
            boxSizing: 'border-box',
            backgroundColor: this.props.hasBorder ? 'white' : undefined,
            borderTop:
                '1px solid ' +
                (this.props.hasBorder ? colors.greyBorder : 'transparent'),
            position: 'absolute',
            bottom: 0,
            textAlign: 'right',
            fontSize: 14,
        };

        const statusBarStyle: React.CSSProperties = {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 16,
            backgroundColor: colors.captureGrey300,
        };

        const Button = (props: ButtonProps, i: number, all: ButtonProps[]) => {
            const style: React.CSSProperties = {
                color: props.isActive
                    ? colors.captureBlue
                    : colors.captureGrey500,
                fontWeight: 'bold',
                flex: 1,
                msFlex: 1,
                cursor: props.isActive ? 'pointer' : 'default',
                float: i === 0 && all.length > 1 ? 'left' : 'right',
            };

            const attributes: React.HTMLProps<HTMLAnchorElement> = {
                key: props.text,
                style,
                onClick: props.isActive ? props.onClick : undefined,
            };

            return <a {...attributes}>{props.text}</a>;
        };

        return (
            <div style={statusInfoStyle}>
                {this.props.buttons.map(Button)}
                <div data-cy="uploadStatusBar" style={statusBarStyle}>
                    <UploadStatusBar
                        shouldBeGray={this.props.disableStatusBar}
                        progress={this.props.uploadProgress}
                        runAnimation={this.props.uploadProgress < 1}
                    />
                </div>
            </div>
        );
    }
}
