import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets';
import {AnimatedGradient} from '../Common/AnimatedGradient';

const ProgressDiv = styled.div`
    will-change: transform;
    width: 100%;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    transform-origin: 0 0;
    height: 100%;
    overflow-x:hidden;
    transition: transform 0.3s linear;
    animation-fill-mode: forwards;
    transform: scaleX(${(props: {progress: number}) => props.progress});
`;

const Gray = styled.div`
    background: ${colors.captureGrey600};
    width: 100%;
    height: 100%;
`;

type Props = {
    progress: number;
    shouldBeGray?: boolean;
    runAnimation: boolean;
};

type UploadStatusBarState = {
    shownProgress: number,
    inTransition: boolean, // progress updates from props are suspended while component is animating.
};

export class UploadStatusBar extends React.Component<Props, UploadStatusBarState> {
    private progressDiv = React.createRef<HTMLDivElement>();

    public state: UploadStatusBarState = {
        shownProgress: 0,
        inTransition: false,
    };

    private onTransitionEnd = () => {
        this.setState({
            shownProgress: this.props.progress,
            // New transition will start if shownProgress changes.
            inTransition: this.state.shownProgress !== this.props.progress,
        });
    }

    public componentDidMount() {
        if (this.progressDiv.current) {
            this.progressDiv.current.addEventListener('transitionend', this.onTransitionEnd);
        }
    }

    public componentWillReceiveProps(newProps: Props) {
        if (!this.state.inTransition && this.state.shownProgress !== newProps.progress) {
            this.setState({
                shownProgress: newProps.progress,
                inTransition: true,
            });
        }
        else if (this.state.inTransition && this.state.shownProgress === newProps.progress) {
            this.setState({
                ...this.state,
                inTransition: false,
            });
        }
    }

    public render() {

        const animatedGradient = (
            <AnimatedGradient
                color1={colors.gradientGreen}
                color2={colors.captureBlue}
                iterationCount={this.props.runAnimation ? 'infinite' : '1'}
            />
        );
        // uses shownProgress from state to have a stable component during animation (using props.progress directly
        // yields animation issues).
        return (
            <ProgressDiv progress={this.state.shownProgress} innerRef={this.progressDiv}>
                {this.props.shouldBeGray ? <Gray/> : animatedGradient}
            </ProgressDiv>
        );
    }
}
