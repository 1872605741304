import {BasePath} from '../../common/routing/routePath';
import {PaymentMethod} from '../components/PaymentSelection/PaymentSelection';

const EditCartPath = new BasePath('redigerElement');
const CheckoutPath = new BasePath('kasse');
const ProcessPath = new BasePath('betalingsprosessering');

export const PrintRoutePath = {
    boxProductPage: 'boks',
    albumProductPage: 'album',
    canvasProductPage: 'lerret',
    magnetProductPage: 'magnet',
    getPrintCartPath: (orderIDParam: string, oIDParam: string) => EditCartPath.subPath(orderIDParam, oIDParam),
    getImportSharePath: (shareIDParam: string) => `import/${shareIDParam}`,
    getCaptureChoseProductPath: (shareIDParam: string) => `capture/${shareIDParam}/select`,
    getCaptureSelectPicturesPath: (shareIDParam: string, productParam: string) => `capture/${shareIDParam}/${productParam}`,
    getCheckoutPath: (orderIDParam: string) => CheckoutPath.subPath(orderIDParam),
    getProcessingPagePath: (paymentType: PaymentMethod) => ProcessPath.subPath(paymentType),
    paymentSuccessPage: 'betalingssuksess',
    paymentErrorPage: 'betalingsfeil',
};
