import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets';
import {layout} from '../../assets/styleConstants';

export const TopNavPlaceholder = styled.div`
    height: ${layout.topNavBarHeight}px;
    width: 100%;
    flex: 0 0 auto;
`;

const FixedTopNavWrapper = styled.div`
    width: 100%;
    height: ${layout.topNavBarHeight}px;
    padding: 8px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.95);
    border-bottom: 1px solid ${colors.captureGrey300};
    user-select: none;
`;

export const TopNavBar: React.SFC<{}> = (props) => (
    <FixedTopNavWrapper>
        {props.children}
    </FixedTopNavWrapper>
);
