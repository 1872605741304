import * as React from 'react';
import {b64ToUuid} from '../../utilities/uuid';
import {AlbumContainer, FetchedPageType} from './AlbumContainer';

type Props = {
    params: {albumID?: JobID, albumIDB64?: string},
};

/**
 * Mount-point for Album-page in router, accepting either a Base64-encoded or a PainText-encoded album-ID
 */

export const makeAlbumMount = (fPage: FetchedPageType) => class AlbumMount extends React.Component<Props, {}> {
    public render() {
        if (this.props.params.albumID) {
            return <AlbumContainer fetchedPage={fPage} albumID={this.props.params.albumID}/>;
        } else if (this.props.params.albumIDB64) {
            return <AlbumContainer fetchedPage={fPage} albumID={b64ToUuid(this.props.params.albumIDB64)}/>;
        }
        throw Error('Must have params for either albumID or albumIDB64');
    }
};
