import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {
    setAlbumCommentsPermission, setAlbumSharingProperty,
    setAlbumTitle, setAlbumUploadsPermission,
} from '../../API/album';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {JobInfo} from '../../state/jobInfo/reducer';
import {getJobInformation} from '../../state/jobInfo/selectors';
import {State} from '../../state/store';
import {isMobileMode} from '../../state/viewMode/selectors';
import {inArray} from '../../utilities/arrayUtils';
import {Button} from '../Common/Button';
import {EditableInput} from '../Common/EditableInput';
import {OptionsOverlay} from '../Common/Overlay';
import {CheckIcon} from '../Icons/CheckIcon';
import {SettingsOptionList} from './SettingsOptionList';

const Wrapper = styled.div`
    margin-top: 8px;
`;
const EditItemHeader = styled.div`
    font-size: ${fontSize.small_12};
    text-align: left;
    border-bottom: 1px solid ${colors.captureGrey100};
    color: ${colors.captureGrey500};
    padding-bottom: 5px;
    margin-bottom: 8px;
`;
const AlbumTitleHeader = styled(EditItemHeader)`
    border: none;
    margin-bottom: 0;
`;

const EditItemError = styled.span`
    color: ${colors.captureMagenta};
    margin-left: 10px;
`;

const EditItemContainer = styled.div`
    margin-bottom: 20px;
`;
const InputWrapper = styled.div`
    width: ${(props: {isMobile: boolean}) => props.isMobile ? '100%' : '278px'};
    padding: 2px 4px;
    box-sizing: border-box;
    border: 1px solid ${colors.captureGrey500};
    margin-top: 0 !important;
`;

const ErrorMsg = () => <EditItemError>{_('try_again')}</EditItemError>;

type StateProps = {
    albumInfo: JobInfo|undefined,
    isMobile: boolean,
};

type DispatchProps = {
    onAlbumTitleChanged: (newTitle: string) => any,
    onAllowCommentsChanged: (enable: boolean) => any,
    onAllowUploadChanged: (enable: boolean) => any,
    onAllowShareChanged: (enable: boolean) => any,
};

type OwnProps = {
    albumID: JobID,
    onClose: () => any,
};

type SettingProps = StateProps & DispatchProps & OwnProps;

type SettingState = JobInfo['pendingProperties'] & {failedFields: Array<keyof JobInfo>};

class EditAlbumSettingsComponent extends React.Component<SettingProps, SettingState> {
    public state: SettingState = {failedFields: []};

    private updateState = <T extends keyof SettingState>(key: T, value: SettingState[T]) => {
        this.setState((s) => ({...s, [key as string]: value}));
    }
    private updateTitle = (newTitle: string) => this.updateState('title', newTitle);

    private allowComments = () => this.updateState('allow_comments', true);
    private disallowComments = () => this.updateState('allow_comments', false);

    private allowUploads = () => this.updateState('allow_uploads', true);
    private disallowUploads = () => this.updateState('allow_uploads', false);

    private allowShare = () => this.updateState('isShared', true);
    private disallowShare = () => this.updateState('isShared', false);

    private isSettingChangeFailed = (key: keyof SettingState) => {
        return inArray(this.state.failedFields, key);
    }
    private hasNewValue = (props: SettingProps, field: keyof JobInfo) => (
        this.state[field] !== undefined && props.albumInfo && props.albumInfo[field] !== this.state[field]
    )

    private hasPendingProps = () => {
        if (this.props.albumInfo) {
            return Object.keys(this.props.albumInfo.pendingProperties).length > 0;
        }
        return false;
    }

    private saveChanges = () => {
        const fields: Array<{key: keyof JobInfo, method: (value: any) => any}> = [
            {key: 'title', method: this.props.onAlbumTitleChanged},
            {key: 'allow_comments', method: this.props.onAllowCommentsChanged},
            {key: 'allow_uploads', method: this.props.onAllowUploadChanged},
            {key: 'isShared', method: this.props.onAllowShareChanged},
        ];
        const changed = fields.filter((f) => this.hasNewValue(this.props, f.key));
        if (changed.length > 0) {
            this.setState((s) => ({...s, failedFields: []}));
            changed.forEach((f) => f.method(this.state[f.key]));
            this.checkFailedFields = true;
        } else if (!this.hasPendingProps()) {
            this.props.onClose();
        }
    }

    private checkFailedFields = false;
    public componentWillReceiveProps(newProps: SettingProps) {
        if (
            this.checkFailedFields
            && newProps.albumInfo
            && Object.keys(newProps.albumInfo.pendingProperties).length === 0
        ) {
            const fields: Array<keyof JobInfo> = ['title', 'allow_comments', 'allow_uploads', 'isShared'];
            const failedFields = fields.filter((f) => this.hasNewValue(newProps, f));
            this.setState((s) => ({...s, failedFields}));
            if (failedFields.length === 0) {
                newProps.onClose();
            }
            this.checkFailedFields = false;
        }
    }

    public render() {
        if (this.props.albumInfo === undefined) {
            return null;
        }
        const albumInfo = {...this.props.albumInfo, ...this.state};

        const allowCommentsOptions = [
            Button(
                _('enabled'),
                this.allowComments,
                {isActive: albumInfo.allow_comments},
            ),
            Button(
                _('disabled'),
                this.disallowComments,
                {isActive: !albumInfo.allow_comments},
            ),
        ];

        const allowUploadOptions = [
            Button(
                _('enabled'),
                this.allowUploads,
                {isActive: albumInfo.allow_uploads},
            ),
            Button(
                _('disabled'),
                this.disallowUploads,
                {isActive: !albumInfo.allow_uploads},
            ),
        ];

        const allowShareOptions = [
            Button(
                _('privacy_private'),
                this.disallowShare,
                {isActive: !albumInfo.isShared},
            ),
            Button(
                _('privacy_shared'),
                this.allowShare,
                {isActive: albumInfo.isShared},
            ),
        ];

        return (
            <OptionsOverlay
                onClose={this.props.onClose}
                confirmButton={Button(_('done'), this.saveChanges, {icon: CheckIcon, isActive: !this.hasPendingProps()})}
                isMobile={this.props.isMobile}
            >
                <Wrapper>
                    <EditItemContainer>
                        <AlbumTitleHeader>
                            {_('edit_album_title_text')}
                            {this.isSettingChangeFailed('title') && <ErrorMsg />}
                        </AlbumTitleHeader>
                        <InputWrapper isMobile={this.props.isMobile}>
                            <EditableInput
                                value={albumInfo.title}
                                onBlur={this.updateTitle}
                            />
                        </InputWrapper>
                    </EditItemContainer>
                    <EditItemContainer>
                        <EditItemHeader>
                            {_('edit_privacy_mode_text')}
                            {this.isSettingChangeFailed('isShared') && <ErrorMsg />}
                        </EditItemHeader>
                        <SettingsOptionList options={allowShareOptions} />
                    </EditItemContainer>
                    <EditItemContainer>
                        <EditItemHeader>
                            {_('edit_allow_comments_text')}
                            {this.isSettingChangeFailed('allow_comments') && <ErrorMsg />}
                        </EditItemHeader>
                        <SettingsOptionList options={allowCommentsOptions} isEnabled={albumInfo.isShared}/>
                    </EditItemContainer>
                    <EditItemContainer>
                        <EditItemHeader>
                            {_('edit_allow_upload_text')}
                            {this.isSettingChangeFailed('allow_uploads') && <ErrorMsg />}
                        </EditItemHeader>
                        <SettingsOptionList options={allowUploadOptions} isEnabled={albumInfo.isShared}/>
                    </EditItemContainer>
                </Wrapper>
            </OptionsOverlay>
        );
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    albumInfo: getJobInformation(state, ownProps.albumID),
    isMobile: isMobileMode(state),
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    onAlbumTitleChanged: (newTitle: string) => {
        setAlbumTitle(dispatch, ownProps.albumID, newTitle);
    },
    onAllowCommentsChanged: (enable: boolean) => {
        setAlbumCommentsPermission(dispatch, ownProps.albumID, enable);
    },
    onAllowUploadChanged: (enable: boolean) => {
        setAlbumUploadsPermission(dispatch, ownProps.albumID, enable);
    },
    onAllowShareChanged: (enable: boolean) => {
        setAlbumSharingProperty(dispatch, ownProps.albumID, enable);
    },
});

export const EditAlbumSettings = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditAlbumSettingsComponent);
