import {Action, isType} from '../common/actions';
import {FilesOptionsClosed, FilesOptionsTriggered} from './actions';
type GeneralOption<T> = {type: T};

export type FilesOptions
    = GeneralOption<'add_to_album'>
    | GeneralOption<'share_to_album'>
    | GeneralOption<'share'>
    | GeneralOption<'filter'>
    ;
export type FilesOptionsState = {
    options?: FilesOptions,
};

const initialState: FilesOptionsState = {
    options: undefined,
};

export const timelineOptionsReducer = (
    state: FilesOptionsState = initialState,
    action: Action<any>,
): FilesOptionsState => {

    if (isType(action, FilesOptionsTriggered)) {
        return {
            ...state,
            options: action.payload,
        };
    }
    if (isType(action, FilesOptionsClosed)) {
        return {
            ...state,
            options: undefined,
        };
    }

    return state;
};
