import * as React from 'react';
export const UploadIllustration: React.SFC = () => (
    <svg width="311px" height="198px" viewBox="0 0 311 198" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M197.875,116.566667 C193.908333,96.4416667 176.233333,81.3333333 155,81.3333333 C138.141667,81.3333333 123.5,90.9 116.208333,104.9 C98.65,106.766667 85,121.641667 85,139.666667 C85,158.975 100.691667,174.666667 120,174.666667 L195.833333,174.666667 C211.933333,174.666667 225,161.6 225,145.5 C225,130.1 213.041667,117.616667 197.875,116.566667 L197.875,116.566667 Z M166.666667,133.833333 L166.666667,157.166667 L143.333333,157.166667 L143.333333,133.833333 L125.833333,133.833333 L155,104.666667 L184.166667,133.833333 L166.666667,133.833333 L166.666667,133.833333 Z" fill="#FFFFFF" />
            <ellipse stroke="#FFFFFF" strokeWidth="0.66" fill="#FFFFFF" fillRule="nonzero" cx="180.67474" cy="3.39100346" rx="2.90657439" ry="3.39100346" />
            <ellipse stroke="#FFFFFF" strokeWidth="0.66" fill="#FFFFFF" fillRule="nonzero" cx="291.906574" cy="50.3910035" rx="2.90657439" ry="3.39100346" />
            <ellipse stroke="#FFFFFF" strokeWidth="0.66" fill="#FFFFFF" fillRule="nonzero" cx="69.9065744" cy="44.3910035" rx="2.90657439" ry="3.39100346" />
            <ellipse stroke="#FFFFFF" strokeWidth="0.66" fill="#FFFFFF" fillRule="nonzero" cx="307.906574" cy="143.391003" rx="2.90657439" ry="3.39100346" />
            <path d="M20.8131488,86.2283737 L20.8131488,84.7750865 C20.8131488,83.9724581 20.1624899,83.3217993 19.3598616,83.3217993 C18.5572332,83.3217993 17.9065744,83.9724581 17.9065744,84.7750865 L17.9065744,86.2283737 L16.4532872,86.2283737 C15.6506588,86.2283737 15,86.8790325 15,87.6816609 C15,88.4842893 15.6506588,89.1349481 16.4532872,89.1349481 L17.9065744,89.1349481 L17.9065744,90.5882353 C17.9065744,91.3908637 18.5572332,92.0415225 19.3598616,92.0415225 C20.1624899,92.0415225 20.8131488,91.3908637 20.8131488,90.5882353 L20.8131488,89.1058824 L22.266436,89.1058824 C23.0690643,89.1058824 23.7197232,88.4552235 23.7197232,87.6525952 C23.7197232,86.8499668 23.0690643,86.199308 22.266436,86.199308 L20.8131488,86.199308 L20.8131488,86.2283737 Z" stroke="#FFFFFF" fill="#FFFFFF" fillRule="nonzero" />
            <path d="M104.134948,2.90657439 L104.134948,1.4532872 C104.134948,0.650658841 103.484289,0 102.681661,0 C101.879033,0 101.228374,0.650658841 101.228374,1.4532872 L101.228374,2.90657439 L99.7750865,2.90657439 C98.9724581,2.90657439 98.3217993,3.55723324 98.3217993,4.35986159 C98.3217993,5.16248995 98.9724581,5.81314879 99.7750865,5.81314879 L101.228374,5.81314879 L101.228374,7.26643599 C101.228374,8.06906434 101.879033,8.71972318 102.681661,8.71972318 C103.484289,8.71972318 104.134948,8.06906434 104.134948,7.26643599 L104.134948,5.78408304 L105.588235,5.78408304 C106.390864,5.78408304 107.041522,5.1334242 107.041522,4.33079585 C107.041522,3.52816749 106.390864,2.87750865 105.588235,2.87750865 L104.134948,2.87750865 L104.134948,2.90657439 Z" stroke="#FFFFFF" fill="#FFFFFF" fillRule="nonzero" />
            <path d="M260.813149,101.906574 L260.813149,100.453287 C260.813149,99.6506588 260.16249,99 259.359862,99 C258.557233,99 257.906574,99.6506588 257.906574,100.453287 L257.906574,101.906574 L256.453287,101.906574 C255.650659,101.906574 255,102.557233 255,103.359862 C255,104.16249 255.650659,104.813149 256.453287,104.813149 L257.906574,104.813149 L257.906574,106.266436 C257.906574,107.069064 258.557233,107.719723 259.359862,107.719723 C260.16249,107.719723 260.813149,107.069064 260.813149,106.266436 L260.813149,104.784083 L262.266436,104.784083 C263.069064,104.784083 263.719723,104.133424 263.719723,103.330796 C263.719723,102.528167 263.069064,101.877509 262.266436,101.877509 L260.813149,101.877509 L260.813149,101.906574 Z" stroke="#FFFFFF" fill="#FFFFFF" fillRule="nonzero" />
            <path d="M55.8131488,145.906574 L55.8131488,144.453287 C55.8131488,143.650659 55.1624899,143 54.3598616,143 C53.5572332,143 52.9065744,143.650659 52.9065744,144.453287 L52.9065744,145.906574 L51.4532872,145.906574 C50.6506588,145.906574 50,146.557233 50,147.359862 C50,148.16249 50.6506588,148.813149 51.4532872,148.813149 L52.9065744,148.813149 L52.9065744,150.266436 C52.9065744,151.069064 53.5572332,151.719723 54.3598616,151.719723 C55.1624899,151.719723 55.8131488,151.069064 55.8131488,150.266436 L55.8131488,148.784083 L57.266436,148.784083 C58.0690643,148.784083 58.7197232,148.133424 58.7197232,147.330796 C58.7197232,146.528167 58.0690643,145.877509 57.266436,145.877509 L55.8131488,145.877509 L55.8131488,145.906574 Z" stroke="#FFFFFF" fill="#FFFFFF" fillRule="nonzero" />
            <path d="M227.044019,37.2228024 L227.732647,36.5030346 C227.897314,36.102925 227.795665,35.637448 227.48121,35.3516441 C227.166755,35.0658402 226.711616,35.0252593 226.355391,35.2512644 L225.666763,35.9710322 L225.008075,35.2512644 C224.777756,34.9530973 224.404834,34.8184606 224.047552,34.9044852 C223.69027,34.9905097 223.411286,35.2821087 223.328983,35.6555473 C223.246681,36.0289858 223.375492,36.4187716 223.66076,36.6595058 L224.409268,37.2228024 L223.72064,37.9425703 C223.41293,38.3409268 223.442253,38.9177288 223.78862,39.2797582 C224.134987,39.6417876 224.686834,39.6724369 225.067956,39.3508118 L225.756584,38.6310439 L226.445212,39.3508118 C226.675531,39.6489788 227.048453,39.7836155 227.405735,39.697591 C227.763018,39.6115664 228.042001,39.3199674 228.124304,38.9465288 C228.206607,38.5730903 228.077795,38.1833045 227.792528,37.9425703 L227.044019,37.2228024 Z" stroke="#FFFFFF" fill="#FFFFFF" fillRule="nonzero" />
        </g>
    </svg>
);
