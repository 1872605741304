import {Action, isType} from '../common/actions';
import {FetchedHostDirectory, HostDirectory} from './actions';

export type HostState = DictionaryOf<HostDirectory>;

const initialState: HostState = {};

export const hostsReducer = (state: HostState = initialState, action: Action<any>): HostState => {
    if (isType(action, FetchedHostDirectory)) {
        return Object.assign({}, state, {[action.payload.job]: action.payload.hosts});
    }

    return state;
};
