import {DeletedFile} from '../../API/services/AppService';
import {cachedInArray} from '../../utilities/arrayUtils';
import {Action, isType} from '../common/actions';
import {
    FilesRestorationFailed, FilesRestorationStarted, FilesRestorationSucceeded,
} from '../job/actions';
import {
    TrashAllFilesDeSelected, TrashAllFilesSelected, TrashContentOutdated,
    TrashFileDeSelected, TrashFilesDeletionFailed,
    TrashFilesDeletionStarted, TrashFilesDeletionSucceeded,
    TrashFileSelected, TrashLoadingFailed,
    TrashLoadingStarted, TrashLoadingSucceeded,
} from './actions';

export type TrashState = {
    fetchState: 'not_started'|'pending'|'fetched'|'failed',
    files: DeletedFile[],
    selectedFiles: FileID[],
    processingFiles: FileID[],
    limit: number,
    lastResultCount: number,
    nextResultOffset: number,
};

const initialState: TrashState = {
    fetchState: 'not_started',
    files: [],
    selectedFiles: [],
    processingFiles: [],
    limit: 0,
    lastResultCount: 0,
    nextResultOffset: 0,
};

const addProcessingFilesFromState = (state: TrashState, targetFiles: FileID[]): TrashState => {
    const isInProcessing = cachedInArray(state.processingFiles);
    const processingFiles = [...state.processingFiles, ...targetFiles.filter((f) => !isInProcessing(f))];
    return {...state, processingFiles};
};

const removeProcessingFilesFromState = (state: TrashState, targetFiles: FileID[]): TrashState => {
    const isRemoveTarget = cachedInArray(targetFiles);
    const processingFiles = state.processingFiles.filter((f) => !isRemoveTarget(f));
    return {...state, processingFiles};
};

const removeDeletedFilesFromState = (state: TrashState, targetFiles: FileID[]): TrashState => {
    const isRemoveTarget = cachedInArray(targetFiles);
    return {
        ...state,
        processingFiles: state.processingFiles.filter((f) => !isRemoveTarget(f)),
        files: state.files.filter((f) => !isRemoveTarget(f.id)),
    };
};

export const trashReducer = (state: TrashState = initialState, action: Action<any>): TrashState => {
    if (isType(action, TrashContentOutdated)) {
        return initialState;
    }
    if (isType(action, TrashLoadingStarted)) {
        return {...state, fetchState: 'pending'};
    }
    if (isType(action, TrashLoadingSucceeded)) {
        return {
            ...state,
            fetchState: 'fetched',
            files: state.files.concat(action.payload.deletedFiles),
            limit: action.payload.limit,
            lastResultCount: action.payload.resultCount,
            nextResultOffset: state.nextResultOffset + action.payload.limit,
        };
    }
    if (isType(action, TrashLoadingFailed)) {
        return {...state, fetchState: 'failed'};
    }
    if (isType(action, TrashFileSelected)) {
        const selectedFiles = [...state.selectedFiles, action.payload];
        return {...state, selectedFiles};
    }
    if (isType(action, TrashFileDeSelected)) {
        return {
            ...state,
            selectedFiles: state.selectedFiles.filter((id) => id !== action.payload),
        };
    }
    if (isType(action, TrashAllFilesSelected)) {
        const isInProcessing = cachedInArray(state.processingFiles);
        return {
            ...state,
            selectedFiles: state.files
                .filter((f) => f.job === action.payload.jobID && !isInProcessing(f.id))
                .map((file) => file.id),
        };
    }
    if (isType(action, TrashAllFilesDeSelected)) {
        return {...state, selectedFiles: []};
    }

    if (isType(action, TrashFilesDeletionStarted)) {
        return addProcessingFilesFromState(state, action.payload);
    }
    if (isType(action, TrashFilesDeletionSucceeded)) {
        return removeDeletedFilesFromState(state, action.payload);
    }
    if (isType(action, TrashFilesDeletionFailed)) {
        return removeProcessingFilesFromState(state, action.payload);
    }

    if (isType(action, FilesRestorationStarted)) {
        return addProcessingFilesFromState(state, action.payload.files);
    }
    if (isType(action, FilesRestorationSucceeded)) {
        return removeDeletedFilesFromState(state, action.payload.files);
    }
    if (isType(action, FilesRestorationFailed)) {
        return removeProcessingFilesFromState(state, action.payload.files);
    }

    return state;
};
