import * as React from 'react';
import {IconProps} from '.';
import {colors} from '../../assets';

/* tslint:disable:max-line-length jsx-self-close */
export const DownloadIcon: React.SFC<IconProps> = (props) => {
    const color = props.color || colors.defaultIconColor;
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(0, 2)">
                <path fill={color} fillRule="nonzero" d="M19,9 L15,9 L15,3 L9,3 L9,9 L5,9 L12,16 L19,9 Z M5,18 L5,20 L19,20 L19,18 L5,18 Z"/>
            </g>
        </svg>
    );
};
