import {Dispatch} from 'react-redux';
import {stripeMode} from '../../../config/constants';
import {
    CreditCardUpdated, CreditCardUpdateFailed,
    PaymentInfoFetched, PlanCanceled,
    PlanCancelFailed, PlanChangeFailed,
    PlanChangeSucceeded, PlanReactivated,
    PlanReactivationFailed, PurchaseFailed,
    PurchaseSucceeded, StorageProductsFetched,
    UserStoragePlanFetched,
} from '../state/storagePlan/actions';
import {getServiceProvider} from './HostProvider';

export const fetchCreditCardInfo = async (dispatch: Dispatch) => {
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        const paymentInfoResp = await service.getStripePaymentMethodInfo();
        dispatch(PaymentInfoFetched(paymentInfoResp));
    }
    catch (e) {}
};
export const updateCreditCardInfo = async (dispatch: Dispatch, paymentTokenID: string, cardID: string) => {
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        await service.postStripePaymentMethod(paymentTokenID, cardID);
        dispatch(CreditCardUpdated());
    } catch (error) {
        dispatch(CreditCardUpdateFailed());
    }
};

export const sendSubscriptionPayment = async (
    dispatch: Dispatch,
    planID: string,
    paymentTokenID: string,
    cardID: string,
) => {
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        await service.stripePurchase(planID, paymentTokenID, cardID);
        dispatch(PurchaseSucceeded());
    } catch (error) {
        dispatch(PurchaseFailed());
    }
};

const getStorageProducts = async (dispatch: Dispatch) => {
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        const resp = await service.getStripeProducts(stripeMode);
        dispatch(StorageProductsFetched(resp.result));
    }
    catch (e) { /* No harm done */ }
};

export const getUserStoragePlan = async (dispatch: Dispatch) => {
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        const resp = await service.getUserGrants();
        dispatch(UserStoragePlanFetched(resp.result.grants));
    }
    catch (e) { /* No harm done */ }
};

export const fetchStoragePlanInfo = (dispatch: Dispatch) => {
    return Promise.all([
        getStorageProducts(dispatch),
        getUserStoragePlan(dispatch),
    ]);
};

export const cancelStoragePlan = async (dispatch: Dispatch, url: string) => {
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        await service.executeGrantLink(url);
        await getUserStoragePlan(dispatch); // reload user plan
        dispatch(PlanCanceled());
    }
    catch (e) {
        dispatch(PlanCancelFailed());
    }
};

export const reactivateStoragePlan = async (dispatch: Dispatch, url: string) => {
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        await service.executeGrantLink(url);
        await getUserStoragePlan(dispatch); // reload user plan
        dispatch(PlanReactivated());
    }
    catch (e) {
        dispatch(PlanReactivationFailed());
    }
};

export const changeCurrentPlan = async (dispatch: Dispatch, targetPlanID: string) => {
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        await service.updateCurrentPlan(targetPlanID);
        await getUserStoragePlan(dispatch); // reload user plan
        dispatch(PlanChangeSucceeded());
    } catch (e) {
        dispatch(PlanChangeFailed());
    }
};
