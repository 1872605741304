import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {StoragePlan} from '../../state/storagePlan/selectors';
import {bytesToSize} from '../../utilities/fileSizeFormatting';
import {InfiniteIconBig} from '../Icons/InfiniteIcon';

type WrapperProps = {hasUnlimited: boolean};
const Wrapper = styled.div`
    ${(props: WrapperProps) => props.hasUnlimited ? `
        background: url(${require('../../assets/img/telenor_propeller_big.png')}) center bottom no-repeat;
        background-size: contain;
    ` : ''}
`;
const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: ${fontSize.medium_16};

    width: 100%;
    max-width: 640px;
    margin-bottom: 24px;
`;

const PlanStorageNumber = styled.div`
    font-size: ${fontSize.large_24};
    color: ${colors.captureBlue};
    font-weight: bold;
    margin: 8px 0;
`;

const PlanDetails = styled.div`
    color: ${colors.captureGrey500};
`;

const UsageInfo = styled.div`
    text-align: center;
`;

const StorageBarWrapper = styled.div`
    position: relative;
    margin-bottom: 16px;
`;
const InfiniteStorageBarWrapper = StorageBarWrapper.extend`
    padding: 12px 0;
`;
const StorageBar = styled.div`
    height: 32px;
    max-width: 640px;
    border-radius: 2px;
    background-color: ${colors.captureGrey200};
    overflow: hidden;
    margin: 0 auto;
`;
const UsedStorage = styled.div`
    width: 100%;
    height: 100%;
    transform: scaleX(${(props: {ratio: number}) => props.ratio});
    transform-origin: 0 0;
    background-color: ${colors.captureBlue};
`;
const InfinityIconWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
        display: block;
    }
`;

const ActionButton = styled.div`
    cursor: pointer;
    color: ${colors.captureBlue};
    display: flex;
    flex-direction: column;
`;
const ActionButtonDetail = styled.div`
    color: ${(props: {warning: boolean}) => props.warning ? colors.captureMagenta : colors.captureGrey800};
    font-size: ${fontSize.small_12};
`;

type Props = {
    usedStorage: number,
    totalStorage: number,
    currentPlan?: StoragePlan,
    triggerPlanAction: () => any,
};

const PlanInfo: React.SFC<{storageText: string, storageDetail?: string}> = (props) => (
    <div>
        <div>{_('your_storage_plan')}</div>
        <PlanStorageNumber>{props.storageText}</PlanStorageNumber>
        <PlanDetails>{props.storageDetail}</PlanDetails>
    </div>
);

export class StorageComponent extends React.Component<Props> {
    public render() {
        let infoElement = null;
        if (this.props.totalStorage === Infinity) {
            infoElement = (
                <>
                    <PlanInfo storageText={_('unlimited')} />
                    <InfiniteStorageBarWrapper>
                        <StorageBar />
                        <InfinityIconWrapper><InfiniteIconBig/></InfinityIconWrapper>
                    </InfiniteStorageBarWrapper>
                    <UsageInfo>{bytesToSize(this.props.usedStorage)} {_('used_storage')}</UsageInfo>
                </>
            );
        }
        else {
            const usedStorageRatio = this.props.usedStorage / this.props.totalStorage;
            const storageInfoText = _('storage_used_out_of__format').split(/(\%\w+\%)/).map(
                (t) => {
                    if (t === '%storage_used%') {
                        return <b key="storage_used">{bytesToSize(this.props.usedStorage)}</b>;
                    }
                    else if (t === '%storage_total%') {
                        return <b key="storage_total">{bytesToSize(this.props.totalStorage)}</b>;
                    }
                    return t;
            });

            let storageDetailString = '';
            let storageActionBtn = null;
            if (this.props.currentPlan) {
                const {size, price, period, isCanceled, validToDate} = this.props.currentPlan;
                const currentPlanInfo = `${size} GB (NOK ${price / 100},- /
                    ${period === 'monthly' ? _('month') : _('year')})`;
                storageDetailString =
                    `${bytesToSize(this.props.totalStorage - (size * Math.pow(2, 30)), 0)}
                    + ${currentPlanInfo}`;

                if (validToDate) {
                    const planStatus = (isCanceled ?
                        _('expired_status__format') : _('renew_status__format')
                    ).replace('%s', validToDate);
                    storageActionBtn = (
                        <ActionButton onClick={this.props.triggerPlanAction}>
                            <ActionButtonDetail warning={isCanceled}>{planStatus}</ActionButtonDetail>
                            <div>{isCanceled ? _('reactivate_storage_plan') : _('cancel_storage_plan')}</div>
                        </ActionButton>
                    );
                }
            }

            infoElement = (
                <>
                    <InfoContainer>
                        <PlanInfo
                            storageText={bytesToSize(this.props.totalStorage)}
                            storageDetail={storageDetailString}
                        />
                        {storageActionBtn}
                    </InfoContainer>
                    <StorageBarWrapper>
                        <StorageBar>
                            <UsedStorage ratio={usedStorageRatio}/>
                        </StorageBar>
                    </StorageBarWrapper>
                    <UsageInfo>{storageInfoText}</UsageInfo>
                </>
            );
        }

        return (
            <Wrapper hasUnlimited={this.props.totalStorage === Infinity}>
                {infoElement}
            </Wrapper>
        );
    }
}
