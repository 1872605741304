import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets';

const AvatarWrapper = styled.div`
    border-radius: 50%;
    width: ${(props: {size: number}) => props.size}px;
    height: ${(props) => props.size}px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    overflow: hidden;
    user-select: none;
    cursor: default;
`;

const ContainerFillingImage = styled.img`
    min-width: 100%;
    min-height: 100%;
`;

const LetterAvatarWrapper = styled(AvatarWrapper)`
    padding: 3px;
    border: 1px solid ${(props: {color: string}) => props.color};
    font-size: 14px;
    color: ${(props) => props.color};
`;

const Letter = styled.span`
    align-self: center;
    letter-spacing: 0;
    text-transform: uppercase;
`;

type Props = {size: number, name: string, img?: string, color?: string};
type ComponentState = {hasImageError: boolean};
export class Avatar extends React.Component<Props, ComponentState> {
    public state: ComponentState = {hasImageError: false};

    private onImageLoadError = () => this.setState({hasImageError: true});

    public render() {
        const {size, name, img, color} = this.props;
        if (img && !this.state.hasImageError) {
            return (
                <AvatarWrapper size={size} >
                    <ContainerFillingImage src={img} alt={name} onError={this.onImageLoadError} />
                </AvatarWrapper>
            );
        }
        // If the name starts with a surrogate pair (ie an emoticon), use the entire pair for the avatar-letter
        const letter = name.substr(0, /^[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(name) ? 2 : 1);
        return (
            <LetterAvatarWrapper size={size} color={color || colors.captureGrey800}>
                <Letter>{letter}</Letter>
            </LetterAvatarWrapper>
        );
    }
};

const AvatarWithNameWrapper = styled.div`
    display: flex;
    align-items: center;

    & > span {
        margin-left: 4px;
        font-weight: bold;
    }
`;
export const AvatarWithName: React.SFC<{name: string, img?: string, color?: string}> = (props) => (
    <AvatarWithNameWrapper>
        <Avatar
            size={24}
            name={props.name}
            img={props.img}
            color={props.color || 'white'}
        />
        <span>{props.name}</span>
    </AvatarWithNameWrapper>
);
