import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const GearIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path stroke={props.color || colors.defaultIconColor} fillRule="nonzero" d="M12.9533333,8.65333333 C12.98,8.44 13,8.22666667 13,8 C13,7.77333333 12.98,7.56 12.9533333,7.34666667 L14.36,6.24666667 C14.4866667,6.14666667 14.52,5.96666667 14.44,5.82 L13.1066667,3.51333333 C13.0266667,3.36666667 12.8466667,3.31333333 12.7,3.36666667 L11.04,4.03333333 C10.6933333,3.76666667 10.32,3.54666667 9.91333333,3.38 L9.66,1.61333333 C9.64,1.45333333 9.5,1.33333333 9.33333333,1.33333333 L6.66666667,1.33333333 C6.5,1.33333333 6.36,1.45333333 6.34,1.61333333 L6.08666667,3.38 C5.68,3.54666667 5.30666667,3.77333333 4.96,4.03333333 L3.3,3.36666667 C3.14666667,3.30666667 2.97333333,3.36666667 2.89333333,3.51333333 L1.56,5.82 C1.47333333,5.96666667 1.51333333,6.14666667 1.64,6.24666667 L3.04666667,7.34666667 C3.02,7.56 3,7.78 3,8 C3,8.22 3.02,8.44 3.04666667,8.65333333 L1.64,9.75333333 C1.51333333,9.85333333 1.48,10.0333333 1.56,10.18 L2.89333333,12.4866667 C2.97333333,12.6333333 3.15333333,12.6866667 3.3,12.6333333 L4.96,11.9666667 C5.30666667,12.2333333 5.68,12.4533333 6.08666667,12.62 L6.34,14.3866667 C6.36,14.5466667 6.5,14.6666667 6.66666667,14.6666667 L9.33333333,14.6666667 C9.5,14.6666667 9.64,14.5466667 9.66,14.3866667 L9.91333333,12.62 C10.32,12.4533333 10.6933333,12.2266667 11.04,11.9666667 L12.7,12.6333333 C12.8533333,12.6933333 13.0266667,12.6333333 13.1066667,12.4866667 L14.44,10.18 C14.52,10.0333333 14.4866667,9.85333333 14.36,9.75333333 L12.9533333,8.65333333 Z M8,10.3333333 C6.71333333,10.3333333 5.66666667,9.28666667 5.66666667,8 C5.66666667,6.71333333 6.71333333,5.66666667 8,5.66666667 C9.28666667,5.66666667 10.3333333,6.71333333 10.3333333,8 C10.3333333,9.28666667 9.28666667,10.3333333 8,10.3333333 Z" />
        </g>
    </svg>
);

export const GearIconFilled: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill={props.color || colors.defaultIconColor} fillRule="nonzero" transform="translate(3.000000, 3.000000)">
            <path d="M9,7 C7.9,7 7,7.9 7,9 C7,10.1 7.9,11 9,11 C10.1,11 11,10.1 11,9 C11,7.9 10.1,7 9,7 Z M16,0 L2,0 C0.89,0 0,0.9 0,2 L0,16 C0,17.1 0.89,18 2,18 L16,18 C17.11,18 18,17.1 18,16 L18,2 C18,0.9 17.11,0 16,0 Z M14.25,9 C14.25,9.23 14.23,9.46 14.2,9.68 L15.68,10.84 C15.81,10.95 15.85,11.14 15.76,11.29 L14.36,13.71 C14.27,13.86 14.09,13.92 13.93,13.86 L12.19,13.16 C11.83,13.44 11.43,13.67 11.01,13.85 L10.75,15.7 C10.72,15.87 10.57,16 10.4,16 L7.6,16 C7.43,16 7.28,15.87 7.25,15.71 L6.99,13.86 C6.56,13.68 6.17,13.45 5.81,13.17 L4.07,13.87 C3.91,13.93 3.73,13.87 3.64,13.72 L2.24,11.3 C2.15,11.15 2.19,10.96 2.32,10.85 L3.8,9.69 C3.77,9.46 3.75,9.23 3.75,9 C3.75,8.77 3.77,8.54 3.8,8.32 L2.32,7.16 C2.19,7.05 2.15,6.86 2.24,6.71 L3.64,4.29 C3.73,4.14 3.91,4.08 4.07,4.14 L5.81,4.84 C6.17,4.56 6.57,4.33 6.99,4.15 L7.25,2.3 C7.28,2.13 7.43,2 7.6,2 L10.4,2 C10.57,2 10.72,2.13 10.75,2.29 L11.01,4.14 C11.44,4.32 11.83,4.55 12.19,4.83 L13.93,4.13 C14.09,4.07 14.27,4.13 14.36,4.28 L15.76,6.7 C15.85,6.85 15.81,7.04 15.68,7.15 L14.2,8.31 C14.23,8.54 14.25,8.77 14.25,9 Z" />
        </g>
    </svg>
);
