import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {
    initializeStripePaymentModal,
    loadStripeCheckoutSDK,
    TokenResponse,
} from '../../API/3rdParty/stripe';
import {clearStripeCheckoutIFrames} from '../../API/3rdParty/stripe';
import {setProfileUserName} from '../../API/currentUser';
import {fetchConnectedDevices} from '../../API/job';
import {loadAccountInfoIfAvailable} from '../../API/login';
import {StripePaymentInfoResponse} from '../../API/services/AppService';
import {
    fetchCreditCardInfo,
    fetchStoragePlanInfo,
    sendSubscriptionPayment,
    updateCreditCardInfo,
} from '../../API/storagePlan';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {Pages} from '../../routing';
import {
    DeleteConnectedDeviceInitiated,
    DeviceInfo,
} from '../../state/currentUser/actions';
import {
    getConnectedDevices,
    getCurrentUserInfo,
    getMaxStorage,
    getUsedStorage,
} from '../../state/currentUser/selectors';
import {
    PaymentInfoFetched, PlanCancelInitiated, PlanChangeInitiated,
    PlanReactivationInitiated,
    UnavailablePlanClicked,
} from '../../state/storagePlan/actions';
import {
    CaptureStripeProduct,
    getCreditCardInfo,
    getCurrentStoragePlan,
    getStoragePlanGroups,
    PlanGroup,
    StoragePlan,
} from '../../state/storagePlan/selectors';
import {State} from '../../state/store';
import {UserInfo} from '../../state/users/selectors';
import {isMobileMode} from '../../state/viewMode/selectors';
import {ExpandableSection} from '../Common/ExpandableSection';
import {PageWrapper} from '../Common/PageWrapper';
import {RippleLoaderPage} from '../Common/RippleLoader';
import {ChevronIcon, ChevronIconFlipped} from '../Icons/ChevronIcons';
import {DownloadAppInfo} from '../Info/DownloadAppInfo';
import {MainNavBarDesktop} from '../Navigation/MainNavBarDesktop';
import {TopNavBarMobile} from '../Navigation/TopNavBarMobile';
import {BuyMoreStorage} from './BuyMoreStorage';
import {ConnectedDevicesComponent} from './ConnectedDevices';
import {LanguageSelect} from './LanguageSelect';
import {PaymentSetting} from './PaymentSetting';
import {PrivacySetting} from './PrivacySetting';
import {ProfileSettings} from './ProfileSettings';
import {StorageComponent} from './Storage';

const SectionHeader = styled.div`
    width: 100%;
    max-width: 640px;
    border-bottom: 1px solid ${colors.captureGrey300};
    padding: 16px 0;
    margin: 32px auto 16px auto;
    font-size: ${fontSize.large_24};
    font-weight: bold;
    color: ${colors.captureGrey800};
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    & > svg {
        flex-shrink: 0;
    }
`;

const SectionContent = styled.div`
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
`;
const DownloadAppWrapper = SectionContent.extend`
    margin-top: 64px;
`;

const makeSettingsHeader: (title: string) => React.SFC<{isExpanded: boolean}> = (title) => ({isExpanded}) => {
    const Icon = isExpanded ? ChevronIconFlipped : ChevronIcon;
    return (
        <SectionHeader>
            {title}
            <Icon color={colors.captureGrey800} size={24} />
        </SectionHeader>
    );
};

type SectionProps = {
    title: string,
    expandedByDefault?: boolean,
};
const SettingsSection: React.SFC<SectionProps> = (props) => {
    return (
        <ExpandableSection
            header={makeSettingsHeader(props.title)}
            expandedByDefault={props.expandedByDefault}
        >
            <SectionContent>{props.children}</SectionContent>
        </ExpandableSection>
    );
};

type StateProps = {
    isMobileMode: boolean;
    userInfo?: UserInfo;
    usedStorage: number;
    totalStorage: number;
    connectedDevices: DeviceInfo[];
    currentPlan?: StoragePlan,
    storagePlanGroups: PlanGroup[],
    paymentInfo: StripePaymentInfoResponse|undefined,
};
type DispatchProps = {
    fetchConnectedDevices: () => any;
    doDeleteDeviceInitiate: (deviceID: string) => any;
    fetchCurrentStoragePlan: () => any,
    doCancelPlan: (cancelURL: string) => any,
    doReactivatePlan: (cancelURL: string) => any,
    doUpdateCreditCardInfo: (token: TokenResponse) => any,
    doPurchasePlan: (planId: string, token: TokenResponse) => any,
    doChangePlan: (plan: CaptureStripeProduct) => any,
    fetchPaymentInfo: () => any,
    doUpdateProfileName: (name: string) => any,
};
type Props = StateProps & DispatchProps;
type ComponentState = {isLoading: boolean};
class SettingsContainer extends React.Component<Props, ComponentState> {
    public state = {isLoading: true};

    public componentDidMount() {
        loadStripeCheckoutSDK();
    }
    public componentWillUnmount() {
        clearStripeCheckoutIFrames();
    }
    public componentWillMount() {
        Promise.all([
            this.props.fetchConnectedDevices(),
            this.props.fetchCurrentStoragePlan(),
            this.props.fetchPaymentInfo(),
        ]).then(
            () => this.setState({...this.state, isLoading: false}),
        );
    }
    private handleCreditCardUpdate = async () => {
        const token = await initializeStripePaymentModal({
            description: _('update_card'),
            email: this.props.paymentInfo && this.props.paymentInfo.email || '',
            panelLabel: _('update'),
        });
        this.props.doUpdateCreditCardInfo(token);
    }
    private triggerPurchasePlanModal = async ({id, size, period, currency, price}: CaptureStripeProduct) => {
        const token = await initializeStripePaymentModal({
                description: `${size} GB, ${period === 'monthly' ? _('monthly_sub') : _('yearly_sub')}`,
                currency,
                amount: price,
        });
        this.props.doPurchasePlan(id, token);
    }
    private handlePlanSelection = (plan: CaptureStripeProduct) => {
        if (this.props.currentPlan) {
            this.props.doChangePlan(plan);
        } else {
            this.triggerPurchasePlanModal(plan);
        }
    }
    private handlePlanActionTriggered = () => {
        if (this.props.currentPlan) {
            const {isCanceled, planActionLink} = this.props.currentPlan;
            if (planActionLink) {
                const triggerMethod = isCanceled ? this.props.doReactivatePlan : this.props.doCancelPlan;
                triggerMethod(planActionLink);
            }
        }
    }

    public render() {
        const navBar = this.props.isMobileMode ? (
            <TopNavBarMobile currentPage={Pages.Settings}/>
        ) : (
            <MainNavBarDesktop currentPage={Pages.Settings}/>
        );
        let content = null;
        if (this.state.isLoading) {
            content = <RippleLoaderPage/>;
        }
        else {
            const profileInfo = this.props.userInfo && (
                <SettingsSection
                    title={_('profile_info')}
                    expandedByDefault={this.props.userInfo.name === ''}
                >
                    <ProfileSettings
                        userInfo={this.props.userInfo}
                        onChangeProfileName={this.props.doUpdateProfileName}
                        isMobile={this.props.isMobileMode}
                    />
                </SettingsSection>
            );

            const hasUnlimited = this.props.totalStorage === Infinity;

            // Only show "Buy more storage"-section to user who have access to purchasable products
            // Hide from users with unlimited storage until more options are available (CAPWEB-1495)
            const buyMoreTitle = this.props.currentPlan ? _('your_storage_plan') : _('buy_more_storage');
            const buyMoreSection = this.props.storagePlanGroups.length > 0 && !hasUnlimited && (
                <SettingsSection title={buyMoreTitle} expandedByDefault={!this.props.currentPlan}>
                    <BuyMoreStorage
                        planGroups={this.props.storagePlanGroups}
                        currentPlan={this.props.currentPlan}
                        isMobile={this.props.isMobileMode}
                        onPlanSelection={this.handlePlanSelection}
                    />
                </SettingsSection>
            );

            const downloadAppBanner = this.props.connectedDevices.length === 0 && (
                <DownloadAppWrapper>
                    <DownloadAppInfo/>
                </DownloadAppWrapper>
            );

            const paymentSettingsSection = this.props.currentPlan && this.props.paymentInfo && (
                <SettingsSection title={_('payment_settings')}>
                    <PaymentSetting
                        doUpdateCreditCardInfo={this.handleCreditCardUpdate}
                        currentCardInfo={this.props.paymentInfo}
                    />
                </SettingsSection>
            );

            const connectedDevices = this.props.connectedDevices.length > 0 && (
                <SettingsSection title={_('connected_devices')}>
                    <ConnectedDevicesComponent
                        connectedDevices={this.props.connectedDevices}
                        onDeleteDeviceInitiate={this.props.doDeleteDeviceInitiate}
                    />
                </SettingsSection>
            );

            content = (
                <div style={this.props.isMobileMode ? {padding: '16px'} : {}}>
                    {profileInfo}
                    <SettingsSection
                        title={_('storage_management')}
                        expandedByDefault={hasUnlimited || this.props.currentPlan !== undefined}
                    >
                        <StorageComponent
                            usedStorage={this.props.usedStorage}
                            totalStorage={this.props.totalStorage}
                            currentPlan={this.props.currentPlan}
                            triggerPlanAction={this.handlePlanActionTriggered}
                        />
                    </SettingsSection>
                    {buyMoreSection}
                    {paymentSettingsSection}
                    {connectedDevices}
                    <SettingsSection title={_('language')}>
                        <LanguageSelect/>
                    </SettingsSection>
                    <SettingsSection title={_('privacy')}>
                        <PrivacySetting/>
                    </SettingsSection>
                    {downloadAppBanner}
                </div>
            );
        }

        return (
            <PageWrapper navBar={navBar}>
                {content}
            </PageWrapper>
        );

    }
}

const mapStateToProps = (state: State): StateProps => ({
    isMobileMode: isMobileMode(state),
    userInfo: getCurrentUserInfo(state),
    usedStorage: getUsedStorage(state),
    totalStorage: getMaxStorage(state),
    connectedDevices: getConnectedDevices(state),
    currentPlan: getCurrentStoragePlan(state),
    storagePlanGroups: getStoragePlanGroups(state),
    paymentInfo: getCreditCardInfo(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    fetchConnectedDevices: () => fetchConnectedDevices(dispatch),
    doDeleteDeviceInitiate: (deviceID: string) =>
        dispatch(DeleteConnectedDeviceInitiated(deviceID)),
    fetchCurrentStoragePlan: () => fetchStoragePlanInfo(dispatch),
    doCancelPlan: (cancelURL: string) => dispatch(PlanCancelInitiated(cancelURL)),
    doReactivatePlan: (reactivateURL: string) => dispatch(PlanReactivationInitiated(reactivateURL)),
    fetchPaymentInfo: () => fetchCreditCardInfo(dispatch),
    doUpdateCreditCardInfo: async (token: TokenResponse) => {
        dispatch(PaymentInfoFetched({
            card: token.card.last4,
            email: token.email,
            exp_month: token.card.exp_month,
            exp_year: token.card.exp_year,
        }));
        await updateCreditCardInfo(dispatch, token.id, token.card.id);
        await fetchCreditCardInfo(dispatch);
    },
    doPurchasePlan: async (planId: string, token: TokenResponse) => {
        await sendSubscriptionPayment(dispatch, planId, token.id, token.card.id);
        loadAccountInfoIfAvailable(dispatch);
    },
    doChangePlan: ({id, isPurchasable}) => dispatch(isPurchasable ? PlanChangeInitiated(id) : UnavailablePlanClicked()),
    doUpdateProfileName: (name: string) => setProfileUserName(dispatch, name),
});

export const SettingsPage = connect(mapStateToProps, mapDispatchToProps)(
    SettingsContainer,
);
