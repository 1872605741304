import * as React from 'react';
import styled from 'styled-components';
import {CUSTOMER_SERVICE_LINK, TERMS_OF_SERVICE_LINK} from '../../../../config/constants';
import {_, colors} from '../../assets';
import {fontSize} from '../../assets/styleConstants';
import {CaptureLogoVertical} from '../Icons/CaptureLogo';
import {TelenorCaptureLogo, TelenorCaptureLogoWhite} from '../Icons/TelenorCaptureLogo';

const Wrapper = styled.div`
    width: 100%;
    margin-top: 40px;
    padding: 0 24px;
    box-sizing: border-box;
`;
const FooterWrapper = styled.div`
    border-top: 1px solid rgba(0,0,0,0.14);
    padding: 24px 0;
    font-size: ${fontSize.xSmall_10};
    text-align: center;
`;

const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 16px 0;
`;

const ButtonLink = styled.a`
    max-width: 152px;
    min-height: 25px;
    flex: 1 1 100%;
    background: transparent;
    border: 1px solid ${(props: {color?: string}) => props.color || colors.captureBlue};
    border-radius: 3px;
    margin: 0 6px;
    font-size: ${fontSize.small_12};
    color: ${(props) => props.color || colors.captureBlue};
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
        align-self: center;
    }
    &:focus {
        box-shadow: 0 0 18px 4px white;
    }
`;

class Footer extends React.Component<{logo: React.ReactNode, linkColor?: string}> {
    public render() {

        return (
            <Wrapper>
                <FooterWrapper id="telenor-capture-footer">
                    <LogoWrapper>{this.props.logo}</LogoWrapper>
                    <ButtonDiv>
                        <ButtonLink color={this.props.linkColor} href={TERMS_OF_SERVICE_LINK} target="_blank">
                            {_('terms_of_service')}
                        </ButtonLink>
                        <ButtonLink color={this.props.linkColor} href={CUSTOMER_SERVICE_LINK} target="_blank">
                            {_('customer_service')}
                        </ButtonLink>
                    </ButtonDiv>
                    <SimpleTelenorCaptureFooter />
                </FooterWrapper>
            </Wrapper>
        );
    }
}

const captureLogo = <CaptureLogoVertical size={60} />;
export const CaptureFooterWithCaptureLogo = () => <Footer logo={captureLogo} />;

const telenorCaptureLogo =  <TelenorCaptureLogo width={153} height={43} />;
export const TelenorCaptureFooter = () => <Footer logo={telenorCaptureLogo} />;

const telenorCaptureLogowhite = <TelenorCaptureLogoWhite width={153} height={43} />;
const Line = styled.div`
    width: 50%;
    margin: 0 auto -40px auto;
    border-top: 1px solid #fff;
`;
export const TelenorCaptureFooterTV = () => (
    <>
        <Line />
        <Footer linkColor="white" logo={telenorCaptureLogowhite} />
    </>
);

const SimpleFooterWrapper = styled.div`
    font-size: ${fontSize.small_14};
    font-weight: lighter;
    text-align: center;
`;
export const SimpleTelenorCaptureFooter: React.SFC = () => {
    const d = new Date();
    const currentYear = d.getFullYear();
    return <SimpleFooterWrapper>{currentYear} © Telenor Norway</SimpleFooterWrapper>;
};
