var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Sriracha&subset=thai);", ""]);

// module
exports.push([module.id, "@font-face {\n  font-family: 'Playfair';\n  src: url(" + escape(require("./fonts/PlayfairDisplay-Regular.ttf")) + ") format(\"truetype\");\n  font-weight: normal;\n  font-style: normal; }\n\nhtml, body {\n  height: 100%; }\n\nbody {\n  margin: 0px;\n  color: #424242;\n  background-color: #FAFAFA;\n  -webkit-font-smoothing: antialiased; }\n\ninput, textarea, select, button {\n  font-family: inherit; }\n\na {\n  text-decoration: none;\n  color: inherit; }\n\nsvg {\n  pointer-events: none; }\n\n.fadeOutElements:hover > div {\n  opacity: 0.4;\n  -webkit-transition: opacity 0.2s ease-out;\n  transition: opacity 0.2s ease-out; }\n\n.fadeOutElements > div:hover {\n  opacity: 1.0; }\n\n@-webkit-keyframes fadeIn {\n  0% {\n    opacity: 0; }\n  100% {\n    opacity: 1; } }\n\n@keyframes fadeIn {\n  0% {\n    opacity: 0; }\n  100% {\n    opacity: 1; } }\n", ""]);

// exports
