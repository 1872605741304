import * as React from 'react';

export type TableEntry<T> = React.SFC<{
    row: T,
    setActionableId: (id: string) => any,
    currentActionableId: string|undefined,
}>;

type Props<T> = {
    rows: T[],
    entry: TableEntry<T>,
    disableAction?: boolean,
};

type State = {
    actionableId: string|undefined,
};

export class ActionableTableContent<T> extends React.Component<Props<T>, State> {
    public state: State = {
        actionableId: undefined,
    };

    private setActionableId = (id: string) => {
        const actionableId = this.state.actionableId !== id ? id : undefined;
        this.setState({...this.state, actionableId});
    }

    public componentWillReceiveProps(newProps: Props<T>) {
        if (this.props.disableAction !== true
            && newProps.disableAction === true) {
            this.setState({...this.state, actionableId: undefined});
        }
    }

    public render() {
        const rows = this.props.rows.map((r, i) => (
            <this.props.entry
                key={i}
                row={r}
                setActionableId={this.setActionableId}
                currentActionableId={this.state.actionableId}
            />
        ));

        return (
            <div>{rows}</div>
        );
    }
}
