import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './';

export const DocIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path fill={props.color || colors.captureGrey800} d="M7.5,4 C6.675,4 6.0075,4.72 6.0075,5.6 L6,18.4 C6,19.28 6.6675,20 7.4925,20 L16.5,20 C17.325,20 18,19.28 18,18.4 L18,8.8 L13.5,4 L7.5,4 L7.5,4 Z M12.75,9.6 L12.75,5.2 L16.875,9.6 L12.75,9.6 L12.75,9.6 Z" />
        </g>
    </svg>
);
