import * as React from 'react';
import {getConnectedInstance} from '../../state/uploader/uploadQueue';
import {Thumb} from './Thumb';

type Props = {
    fileName: string,
    uploadId: number,
};

type State = {
    thumbURL?: string,
};

export class AsyncPreviewThumb extends React.PureComponent<Props, State> {
    public state: State = {};
    private shouldUpdateThumb: boolean = true;

    private updateThumb = (uploadId: number) => {
        getConnectedInstance()
            .getUploadFilePreviewThumb(uploadId)
            .then((thumbURL) => {
                if (this.shouldUpdateThumb) { this.setState({...this.state, thumbURL}); }
            });
    }

    public componentDidMount() {
        this.shouldUpdateThumb = true;
        this.updateThumb(this.props.uploadId);
    }

    public componentWillUnmount() {
        this.shouldUpdateThumb = false;
    }

    public componentWillReceiveProps(newProps: Props) {
        if (newProps.uploadId !== this.props.uploadId) {
            this.updateThumb(newProps.uploadId);
        }
    }

    public render() {
        return (
            <Thumb
                fileName={this.props.fileName}
                thumbURL={this.state.thumbURL}
            />
        );
    }
}
