import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {createAnonymousUser} from '../../API/job';
import {ValidUserInfoRequestDeniedByUser} from '../../state/currentUser/actions';
import {mustProvideUserInfo} from '../../state/currentUser/selectors';
import {State} from '../../state/store';
import {ProvideNameOverlay} from './ProvideNameOverlay';

type OwnProps = {
    jobID: JobID,
};
type StateProps = {
    mustProvideUserInfo: boolean,
};
type DispatchProps = {
    createUser: (name: string) => Promise<any>,
    denyUserInfoRequest: () => any,
};

type Props = OwnProps & StateProps & DispatchProps;

class _RequireUserInfo extends React.Component<Props, {}> {
    public render() {
        if (this.props.mustProvideUserInfo) {
            return (
                <ProvideNameOverlay
                    handleNameProvision={this.props.createUser}
                    cancelNamePrompt={this.props.denyUserInfoRequest}
                />
            );
        }
        return null;
    }
}

const stateToProps = (state: State): StateProps => ({
    mustProvideUserInfo: mustProvideUserInfo(state),
});
const dispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    createUser: (name: string) => createAnonymousUser(dispatch, ownProps.jobID, name),
    denyUserInfoRequest: () => dispatch(ValidUserInfoRequestDeniedByUser()),
});

export const RequireUserInfoPlacement = connect(
    stateToProps, dispatchToProps,
)(_RequireUserInfo);
