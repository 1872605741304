import {AlbumDetails} from '../albumList/reducer';
import {AlbumOptionsOverlayTriggered} from '../albumOptions/actions';
import {createActionCreator} from '../common/actions';
import {UserActionInitiated} from '../userActions/actions';
import {UserActionTypes} from '../userActions/reducer';
import {AlbumSortMode} from './selectors';

/* tslint:disable:max-line-length */

/**
 * Albums do not have their own state (the state is derived from state of other parts), but do dispatch som actions
 * that are made available for analytical purposes (by the analytics-package)
 */

export const DownloadAlbumClicked = createActionCreator<JobID>('DOWNLOAD_ALBUM_CLICKED');
export const DownloadAlbumSuccess = createActionCreator<JobID>('DOWNLOAD_ALBUM_SUCCESS');
export const DownloadAlbumFailed = createActionCreator<JobID>('DOWNLOAD_ALBUM_FAILED');

// Event to track if users are interested in/tries to trigger full-screen-image
export const ClickedAlbumImage = createActionCreator<{albumID: JobID, imageID: ImageID}>('CLICKED_ALBUM_IMAGE');

export const AlbumDeletionInitiated = (target: JobID) => UserActionInitiated({target, type: UserActionTypes.DELETE_ALBUM});
export const AlbumUnsubscriptionInitiated = (target: JobID) => UserActionInitiated({target, type: UserActionTypes.UNSUBSCRIBE_ALBUM});
export const AlbumSharingInitiated = (id: JobID) => AlbumOptionsOverlayTriggered({albumID: id, type: 'share'});
export const EditAlbumSettingsInitiated = (id: JobID) => AlbumOptionsOverlayTriggered({albumID: id, type: 'settings'});
export const DisplayListOfLoversInitiated = (albumID: JobID, fileID: FileID) => AlbumOptionsOverlayTriggered({albumID, fileID, type: 'loveList'});
export const DisplayListOfParticipantsInitiated = (albumID: JobID) => AlbumOptionsOverlayTriggered({albumID, type: 'participantsList'});
export const AlbumListFilterInitiated = () => AlbumOptionsOverlayTriggered({type: 'albums_filter'});
export const CreateAlbumInitiated = () => AlbumOptionsOverlayTriggered({type: 'create_album'});
export const ShowShareAlbumPrompt = (id: JobID) => AlbumOptionsOverlayTriggered({albumID: id, type: 'share_prompt'});
export const ShowAlbumSortModePrompt = (id: JobID) => AlbumOptionsOverlayTriggered({albumID: id, type: 'album_files_sort'});

export type AutoGeneratedAlbumInfo = {jobID: JobID, tempID: string, name: string};
export const AutoGeneratedAlbumCreated = createActionCreator<AutoGeneratedAlbumInfo>('AUTO_CREATED_ALBUM_CREATED');
export const AutoGeneratedAlbumFinished = createActionCreator<AutoGeneratedAlbumInfo>('AUTO_CREATED_ALBUM_FINISHED');
export const AutoGeneratedAlbumCanceled = createActionCreator<AutoGeneratedAlbumInfo>('AUTO_CREATED_ALBUM_CANCELED');

type SortModePayload = {
    jobID: JobID,
    sortMode: AlbumSortMode,
};
export const AlbumSortModeChanged = createActionCreator<SortModePayload>('ALBUM_SORT_MODE_CHANGED');

export const AlbumDetailsFetched = createActionCreator<AlbumDetails>('ALBUM_DETAILS_FETCHED');
