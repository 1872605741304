import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {HOME_SITE_ADDRESS, SUPPORT_SITE_ADDRESS} from '../../../../config/constants';
import {requireAccountInfo} from '../../API/login';
import {_} from '../../assets';
import {colors, fontSize} from '../../assets/styleConstants';
import {Pages} from '../../routing';
import {isLoggedIn} from '../../state/currentUser/selectors';
import {NavigateTo, PageDescription} from '../../state/routing/actions';
import {State} from '../../state/store';
import {isMobileMode} from '../../state/viewMode/selectors';
import {Button, ButtonProps} from '../Common/Button';
import {CreateAlbumElement} from '../EditAlbum/CreateAlbumLink';
import {CaptureIcon} from '../Icons/CaptureLogo';
import {MainNavBarDesktop} from '../Navigation/MainNavBarDesktop';
import {NotLoggedInTopNavBar} from '../Navigation/NotLoggedInTopNavBar';
import {TopNavPlaceholder} from '../Navigation/TopNavBar';
import {TopNavBarMobile} from '../Navigation/TopNavBarMobile';
import {InfoContainer, InfoProps} from './InfoContainer';

const Description = styled.div`
    margin: ${(props: {isMobile: boolean}) => props.isMobile ? 20 : 38}px auto;
    font-size: ${fontSize.medium_16};
`;

const HelpLinksWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
`;

const Link = styled.div`
    font-size: ${fontSize.medium_16};
    color: ${colors.captureBlue};
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 8px;
`;

const HelpLink: React.SFC<ButtonProps> = (props) => (
    <Link onClick={props.onClick}>{props.text}</Link>
);

const PageFrame: React.SFC<{isMobile: boolean, isLoggedIn: boolean}> = (props) => {
    let navBar: React.ReactNode;
    if (props.isLoggedIn) {
        navBar = props.isMobile ? <TopNavBarMobile /> : <MainNavBarDesktop />;
    } else {
        navBar = <NotLoggedInTopNavBar />;
    }

    return (
        <div>
            {props.isMobile && <TopNavPlaceholder />}
            {props.children}
            {navBar}
        </div>
    );
};

type StateProps = {
    isMobile: boolean,
    isLoggedIn: boolean,
};

type DispatchProps = {
    doGoToPage: (page: PageDescription) => any,
    doLogin: () => any,
};

type Props = StateProps & DispatchProps;

class PageNotFoundComponent extends React.Component<Props> {

    private getHelpLinks = () => {
        if (this.props.isLoggedIn) {
            const createNewAlbumLink = (
                <CreateAlbumElement key="create_new_album">
                    <Link>{_('create_album')}</Link>
                </CreateAlbumElement>
            );

            const otherLinks = [
                Button(_('timeline'), () => this.props.doGoToPage(Pages.Timeline)),
                Button(_('albums'), () => this.props.doGoToPage(Pages.Albums)),
                Button(_('settings'), () => this.props.doGoToPage(Pages.Settings)),
                Button(_('help'), () => window.location.href = SUPPORT_SITE_ADDRESS),
            ].map((l, i) => <HelpLink key={i} {...l}/>);

            return [createNewAlbumLink].concat(otherLinks);
        } else {
            return [
                Button(_('login'), this.props.doLogin),
                Button(_('helpful_links_about_capture'), () => window.location.replace(HOME_SITE_ADDRESS)),
                Button(_('help'), () => window.location.href = SUPPORT_SITE_ADDRESS),
            ].map((l, i) => <HelpLink key={i} {...l}/>);
        }
    }

    public render() {
        const infoProps: InfoProps = {
            infoIcon: (<CaptureIcon size={78}/>),
            infoTitle: _('page_not_found'),
        };

        return (
            <PageFrame isMobile={this.props.isMobile} isLoggedIn={this.props.isLoggedIn}>
                <InfoContainer {...infoProps}>
                    <Description isMobile={this.props.isMobile}>{_('helpful_links_description')}</Description>
                    <HelpLinksWrapper>{this.getHelpLinks()}</HelpLinksWrapper>
                </InfoContainer>
            </PageFrame>
        );
    }
}

const mapStateToProps = (state: State): StateProps => ({
    isMobile: isMobileMode(state),
    isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doGoToPage: (page: PageDescription) => dispatch(NavigateTo(page)),
    doLogin: () => requireAccountInfo(dispatch),
});

export const PageNotFound = connect(mapStateToProps, mapDispatchToProps)(PageNotFoundComponent);
