import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {downloadAlbum} from '../../API/album';
import {copyAlbumToTimeline} from '../../API/job';
import {_} from '../../assets/localizedStrings';
import {Pages} from '../../routing';
import {
    AlbumDeletionInitiated,
    AlbumSharingInitiated,
    AlbumUnsubscriptionInitiated,
    EditAlbumSettingsInitiated,
} from '../../state/album/actions';
import {
    AlbumJobInfo, getLastViewedAlbumOffset,
    havePendingAutoCreatedAlbums,
} from '../../state/albumList/selectors';
import {getCurrentUserUUID} from '../../state/currentUser/selectors';
import {NavigateTo} from '../../state/routing/actions';
import {State} from '../../state/store';
import {isMobileMode} from '../../state/viewMode/selectors';
import {withoutTheBools} from '../../utilities/arrayUtils';
import {isMobileDevice} from '../../utilities/device';
import {GridStyle} from '../../utilities/gridElementSizeCalculator';
import {Button, ButtonProps} from '../Common/Button';
import {FadeInContainer} from '../Common/StyledComponents';
import {AddIcon} from '../Icons/AddIcon';
import {CancelIcon} from '../Icons/CancelIcon';
import {DeleteIcon} from '../Icons/DeleteIcon';
import {DownloadIcon} from '../Icons/DownloadIcon';
import {GearIcon} from '../Icons/GearIcon';
import {ShareIcon} from '../Icons/ShareIcon';
import {AlbumListEntry, AlbumListEntryPlaceholder} from './AlbumListEntry';

const ListWrapper = styled.div`
    margin-top: 10px;
`;

const AlbumElementWrapper = styled.div`
    width: ${(props: {width: number, height: number}) => props.width}px;
    height: ${(props) => props.height}px;
    padding: 2px;
    display: inline-block;
    vertical-align: top;
`;

type OwnProps = {
    albums: AlbumJobInfo[],
    onAlbumClick: (albumID: string) => any,
    style: GridStyle,
};

type StateProps = {
    currentUser?: UserID,
    havePendingAlbum: boolean,
    isMobile: boolean,
    lastSeenOffset: number|undefined,
};

type DispatchProps = {
    doGoToAlbum: (albumID: JobID) => any,
    doDeleteInitiate: (albumID: JobID) => any,
    doUnsubscribeInitiate: (albumID: JobID) => any,
    onShareInitiate: (albumID: JobID) => any,
    onEditSettingsInitiate: (albumID: JobID) => any,
    doCopyToTimeline: (targetAlbumID: JobID) => any,
    doDownloadAlbum: (albumID: JobID) => any,
};
type Props = OwnProps & StateProps & DispatchProps;
type ComponentState = {
    displayContainer: boolean,
}
class _AlbumList extends React.Component<Props, ComponentState> {
    public state: ComponentState = {displayContainer: false}
    private getMenuItems(album: AlbumJobInfo): ButtonProps[] {
        return withoutTheBools([
            album.isShared && (
                Button(_('share_album'), () => this.props.onShareInitiate(album.id), {icon: ShareIcon})
            ),
            album.currentUserCan.editAlbum && (
                Button(_('settings'), () => this.props.onEditSettingsInitiate(album.id), {icon: GearIcon})
            ),
            album.currentUserCan.leaveAlbum && (
                Button(_('leave_album'), () => this.props.doUnsubscribeInitiate(album.id), {icon: CancelIcon})
            ),
            !isMobileDevice.any() && (
                Button(_('download_album'), () => this.props.doDownloadAlbum(album.id), {icon: DownloadIcon})
            ),
            Button(_('add_album_to_timeline'), () => this.props.doCopyToTimeline(album.id), {icon: AddIcon}),
            album.currentUserCan.deleteAlbum && (
                Button(_('delete_album'), () => this.props.doDeleteInitiate(album.id), {icon: DeleteIcon})
            ),
        ]);
    }
    public componentDidMount() {
        if (this.props.lastSeenOffset) {
            setTimeout(() => {
                window.scrollTo(0, this.props.lastSeenOffset);
                this.setState({...this.state, displayContainer: true});
            }, 0);
        } else {
            this.setState({...this.state, displayContainer: true});
        }
    }
    public render() {

        const albumList = this.props.albums.map((album) => {
            if (album) {
                return (
                    <AlbumElementWrapper
                        key={album.id}
                        onClick={() => this.props.onAlbumClick(album.id)}
                        width={this.props.style.elementWidth}
                        height={this.props.style.elementHeight}
                    >
                        <AlbumListEntry
                            {...album}
                            overflowMenuItems={this.getMenuItems(album)}
                        />
                    </AlbumElementWrapper>
                );
            }
        });

        if (this.props.havePendingAlbum) {
            albumList.unshift((
                <AlbumElementWrapper
                    key={'pending'}
                    width={this.props.style.elementWidth}
                    height={this.props.style.elementHeight}
                >
                    <AlbumListEntryPlaceholder />
                </AlbumElementWrapper>
            ));
        }

        return (
            <FadeInContainer isVisible={this.state.displayContainer}>
                <ListWrapper>{albumList}</ListWrapper>
            </FadeInContainer>
        );
    }
}

const mapStateToProps = (state: State): StateProps => ({
    currentUser: getCurrentUserUUID(state),
    havePendingAlbum: havePendingAutoCreatedAlbums(state),
    isMobile: isMobileMode(state),
    lastSeenOffset: getLastViewedAlbumOffset(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doGoToAlbum: (albumID: JobID) => dispatch(NavigateTo(Pages.Album(albumID))),
    doDeleteInitiate: (id: JobID) => dispatch(AlbumDeletionInitiated(id)),
    doCopyToTimeline: (targetAlbumID: JobID) => copyAlbumToTimeline(dispatch, targetAlbumID),
    doDownloadAlbum: (albumID: JobID) => downloadAlbum(dispatch, albumID),
    doUnsubscribeInitiate: (id: JobID) => dispatch(AlbumUnsubscriptionInitiated(id)),
    onShareInitiate: (id: JobID) => dispatch(AlbumSharingInitiated(id)),
    onEditSettingsInitiate: (id: JobID) => dispatch(EditAlbumSettingsInitiated(id)),
});

export const AlbumList = connect<StateProps, DispatchProps, OwnProps, State>(
    mapStateToProps, mapDispatchToProps,
)(_AlbumList);
