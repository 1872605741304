import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

export const FullscreenExitIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path fill={props.color || colors.defaultIconColor} fillRule="nonzero" d="M4,16.5714286 L7.42857143,16.5714286 L7.42857143,20 L9.71428571,20 L9.71428571,14.2857143 L4,14.2857143 L4,16.5714286 Z M7.42857143,7.42857143 L4,7.42857143 L4,9.71428571 L9.71428571,9.71428571 L9.71428571,4 L7.42857143,4 L7.42857143,7.42857143 Z M14.2857143,20 L16.5714286,20 L16.5714286,16.5714286 L20,16.5714286 L20,14.2857143 L14.2857143,14.2857143 L14.2857143,20 Z M16.5714286,7.42857143 L16.5714286,4 L14.2857143,4 L14.2857143,9.71428571 L20,9.71428571 L20,7.42857143 L16.5714286,7.42857143 Z"/>
        </g>
    </svg>
);
