import * as React from 'react';
import styled from 'styled-components';
import {colors, fontSize} from '../../assets/styleConstants';
import {DeviceInfo} from '../../state/currentUser/actions';
import {CloseIcon} from '../Icons/CloseIcon';

type Props = {
    connectedDevices: DeviceInfo[],
    onDeleteDeviceInitiate: (deviceID: string) => any,
};

const DeviceEntry = styled.div`
    background-color: ${colors.captureGrey100};
    padding: 4px 12px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    margin: 8px 0;
    font-size: ${fontSize.small_14};
    user-select: none;

    &:hover {
       background-color: ${colors.captureGrey200};
    }
`;
const IconWrapper = styled.span`
    margin-right: 24px;
    height: 24px;
    cursor: pointer;
`;

export class ConnectedDevicesComponent extends React.Component<Props, {}> {

    public render() {
        const devices = this.props.connectedDevices.map((d) => (
                <DeviceEntry key={d.id}>
                    <IconWrapper onClick={() => this.props.onDeleteDeviceInitiate(d.id)}>
                        <CloseIcon size={24} color={colors.captureGrey500}/>
                    </IconWrapper>
                    {d.name}
                </DeviceEntry>
            ),
        );
        return (
            <>{devices}</>
        );

    }
}
