export const isEmail = (value: string): boolean => {
    // Basic W3C email input validation
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Validation
    // tslint:disable-next-line:max-line-length
    return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(value);
};

export const hasTextValue = (value: string) => value.trim() !== '';

export const hasOnlyNumbers = (value: string, length?: number) => {
    const s = value.trim();
    return /^\d+$/.test(s) && (length === undefined || s.length === length);
};

export const makeWhitelist = <T>(validValues: T[]) => (valToCheck: any): T|undefined => {
    return validValues.filter((e) => e === valToCheck)[0];
};
