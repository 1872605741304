import React, {Component} from 'react';
import styled, {keyframes} from 'styled-components';

const slideDown = keyframes`
    from {
        transform-origin: top;
        transform: scaleY(0);
    }
    to   {
        transform-origin: top;
        transform: scaleY(1);
    }
`;

const ExpandedContent = styled.div`
    animation: ${slideDown} 0.2s ease-in-out both;
`;

type Props = {
    header: React.ComponentType<{isExpanded: boolean}>,
    expandedByDefault?: boolean,
};
type State = {isExpanded: boolean};
export class ExpandableSection extends Component<Props, State> {
    public state: State = {isExpanded: this.props.expandedByDefault || false};

    private toggleExpand = () => {
        this.setState((prvState) => ({
            ...prvState,
            isExpanded: !prvState.isExpanded,
        }));
    }

    public render() {
        return (
            <>
                <div onClick={this.toggleExpand}>
                    <this.props.header isExpanded={this.state.isExpanded} />
                </div>
                {this.state.isExpanded && <ExpandedContent>{this.props.children}</ExpandedContent>}
            </>
        );
    }
}
