import * as React from 'react';
import styled from 'styled-components';
import {colors, fontSize} from '../../assets/styleConstants';
import {FileMetadata} from '../../state/fileMetadata/actions';
import {CaptureFile} from '../../state/files/reducer';
import {bytesToSize} from '../../utilities/fileSizeFormatting';
import {RippleLoader} from '../Common/RippleLoader';
import {localizedDateStringLong, localizedTimeString} from '../Common/TimeStrings';
import {CalendarIcon} from '../Icons/CalendarIcon';
import {CameraIcon} from '../Icons/CameraIcon';
import {ImageIcon} from '../Icons/ImageIcon';

type Props = {
    file: CaptureFile,
    metadata: FileMetadata | undefined,
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 16px 8px;
    box-sizing: border-box;
`;

const InfoElement = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 8px;
    box-sizing: border-box;
`;

const IconElement = styled.div`
    height: 24px;
    margin: 8px;
`;
const TextElement = styled.div`
    flex: 1;
    align-self: center;
    padding: 0 8px;
    font-size: ${fontSize.small_14};
`;

const SubText = styled.div`
    font-size: ${fontSize.small_12};
    color: ${colors.captureGrey400};

    span {
        margin-right: 16px;
    }
`;

const MainText = styled.div`
    margin-bottom: 4px;
    span {
        margin-right: 8px;
    }
`;

const RippleWrapper = styled.div`
    align-self: center;
`;

type InfoElementProps = {
    icon: React.ReactNode,
    subElements?: React.ReactNode,
    mainElements: React.ReactNode,
};
const FileInfoElement: React.SFC<InfoElementProps> = (props) => {
    return (
        <InfoElement>
            <IconElement>{props.icon}</IconElement>
            <TextElement>
                <MainText>{props.mainElements}</MainText>
                <SubText>{props.subElements}</SubText>
            </TextElement>
        </InfoElement>
    );
};

export class FileInfoComponent extends React.PureComponent<Props, {}> {

    public render() {
        const {metadata, file} = this.props;

        const date: Date = new Date((file.ctime || file.mtime) * 1000);

        let metadataContent = null;
        if (!metadata) {
            metadataContent = (
                <InfoElement>
                    <RippleWrapper><RippleLoader/></RippleWrapper>
                </InfoElement>
            );
        } else {
            const {aperture, exposure, focalLength, iso, deviceManufacturer, deviceModel} = metadata;

            const showIcon = aperture || exposure || focalLength || iso || deviceManufacturer || deviceModel;
            metadataContent = (
                <FileInfoElement
                    icon={showIcon && <CameraIcon size={24} color={colors.captureGrey800}/>}
                    mainElements={[
                        aperture && <span key="aperture">{aperture}</span>,
                        exposure && <span key="exposure">{exposure}</span>,
                        focalLength && <span key="focalLength">{focalLength}</span>,
                        iso && <span key="iso">{iso} ISO</span>,
                    ]}
                    subElements={(deviceManufacturer || deviceModel) && <span>{deviceManufacturer} {deviceModel}</span>}
                />
            );
        }
        return (
            <Wrapper>
                <FileInfoElement
                    icon={<CalendarIcon size={24} color={colors.captureGrey800}/>}
                    mainElements={localizedDateStringLong(date)}
                    subElements={localizedTimeString(date)}
                />
                <FileInfoElement
                    icon={<ImageIcon size={24} color={colors.captureGrey800}/>}
                    mainElements={file.path}
                    subElements={[
                        file.size && <span key="file_size">{bytesToSize(file.size)}</span>,
                        file.width && file.height && <span key="file_height">{file.width}x{file.height}</span>,
                    ]}
                />
                {metadataContent}
            </Wrapper>
        );
    }
}
