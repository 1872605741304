import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets/styleConstants';
import {disableBodyScroll, enableBodyScroll} from '../../utilities/preventBodyScroll';

const Overlay = styled.div`
    position: fixed;
    background-color: ${(props: {color: string, zIndex: boolean}) => props.color};
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    ${(props) => props.zIndex ? 'z-index: 1000' : ''};

    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;

    position: relative;

    display:flex;

    justify-content: center;
    align-items: center;
`;

type FullscreenOverlayProps = {
    onOverlayClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
    color?: string,
    shouldStackOnTop?: boolean,
};

export class FullscreenOverlay extends React.Component<FullscreenOverlayProps> {

    private clickOverlayHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (this.props.onOverlayClick && event.target === event.currentTarget) {
            this.props.onOverlayClick(event);
        }
    }

    public componentDidMount() {
        disableBodyScroll();
    }

    public componentWillUnmount() {
        enableBodyScroll();
    }

    public render() {
        const color = this.props.color || colors.darkOverlay_rgb;
        const shouldStackOnTop = this.props.shouldStackOnTop !== undefined ? this.props.shouldStackOnTop : true;
        return (
            <Overlay color={color} zIndex={shouldStackOnTop}>
                <Wrapper onClick={this.clickOverlayHandler}>
                    {this.props.children}
                </Wrapper>
            </Overlay>
        );
    }
}
