import {Middleware} from 'redux';
import {triggerManualChangesPolling} from '../../API/syncers/JobChangesSyncer';
import {ActionCreator} from '../common/actions';
import {FileCommentSubmitSuccessful} from '../files/actions';
import {FileUploadSucceeded} from '../uploader/actions';

/**
 *  The changesSyncerMiddleware observe actions that should yield server-side changes that usually are detected by the longpoller.
 *  However there are quite a few cases where the longpoller have been failing us, so in addition to having the longpoller
 *  going we are telling the JobChangesSyncer when we expect changes to be ready
 */
export const changesSyncerMiddleware: Middleware = (_store) => {

    const actionsWeCareAbout: Array<ActionCreator<any>> = [
        FileUploadSucceeded,
        FileCommentSubmitSuccessful,
    ];
    const typesWeCareAbout = actionsWeCareAbout.map((action: ActionCreator<any>) => action.type);

    return (next) => (action) => {
        if (typesWeCareAbout.indexOf(action.type) !== -1) {
            triggerManualChangesPolling();
        }

        next(action);
    };
};
