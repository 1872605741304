import {AlbumSortModeChanged} from '../album/actions';
import {AlbumSortMode, AlbumViewMode} from '../album/selectors';
import {Action, isType} from '../common/actions';
import {UserUnsubscribedFromAlbum} from '../currentUser/actions';
import {
    AlbumViewModeChanged, FileWasSetAsCoverPhoto, JobChangesWasFetched, JobInfoChangeDetected, UnableToFetchJobChanges,
} from '../job/actions';
import {
    ChangeJobPropertyFailed, ChangeJobPropertySubmitted,
    ChangeJobPropertySucceeded, ErrorWhenFetchingJobInfo,
    FetchedJobInfo, JobNotFound,
    JobPasswordProvided, JobRequiresPassword, JobWasCreated, JobWasConfirmed, JobWasDeleted,
    StartedFetchingJobInfo,
} from './actions';

export type JobInfoState = DictionaryOf<JobInfoElement>;

// Enumeration of different states a job can have in the system
export enum JobInfoStatus {
    NOT_STARTED,        // Never tried to fetch any jobInfo
    PENDING,            // Currently in progress of fetching jobInfo
    NOT_FOUND,          // Job does not exist (or is unavailable for other reasons)
    PASSWORD_REQUIRED,  // Job requires password (and the one provided is not correct)
    PASSWORD_PROVIDED,  // User has provided password and the new password should be retried
    FETCHED,            // Ideal end-state: jobInfo is fully fetched
    EXPIRED,            // The stored jobInfo is stale and should be refetched
    FETCH_FAILED,       // Some other thing went wrong when fetching the job (network error, unknown responses etc) - given up.
}

export type JobInfo = {
    type: 'timeline'|'story',
    ctime?: number,
    mtime: number,
    owner: UserID,
    title: string,
    allow_comments: boolean,
    allow_uploads: boolean,
    isShared: boolean,
    last_update: number,
    pendingProperties: Partial<JobInfo>,
    coverPhoto?: FileID,
};

export type JobInfoElement = {
    jobID: JobID;
    status: JobInfoStatus;
    providedPassword?: string;
    lastSeenSerial?: number, // -1 for broken job
    jobInfo?: JobInfo;
    viewMode?: AlbumViewMode,
    sortMode?: AlbumSortMode,
    pending?: boolean,
};

const getDefaultElement = (jobID: JobID): JobInfoElement => {
    return {
        jobID,
        status: JobInfoStatus.NOT_STARTED,
    };
};

function newStateWithElementChanges(
    state: JobInfoState,
    elementId: JobID,
    changes: Partial<JobInfoElement>,
): JobInfoState {
    return {
        ...state,
        [elementId]: {
            ...state[elementId] || getDefaultElement(elementId),
            ...changes,
        },
    };
}

const newStateWithJobInfoChanges = (
    state: JobInfoState,
    elementId: JobID,
    changes: Partial<JobInfo>,
): JobInfoState => {
    const {jobInfo} = state[elementId];
    if (jobInfo) {
        return newStateWithElementChanges(state, elementId, {
            jobInfo: {
                ...jobInfo,
                ...changes,
            },
        });
    }

    return state;
};

const initialState: JobInfoState = {};

export const jobInfoReducer = (state: JobInfoState = initialState, action: Action<any>): JobInfoState => {
    if (isType(action, StartedFetchingJobInfo)) {
        return newStateWithElementChanges(state, action.payload, {status: JobInfoStatus.PENDING});
    }

    if (isType(action, JobNotFound)) {
        return newStateWithElementChanges(state, action.payload, {status: JobInfoStatus.NOT_FOUND});
    }
    if (isType(action, JobRequiresPassword)) {
        return newStateWithElementChanges(state, action.payload, {status: JobInfoStatus.PASSWORD_REQUIRED});
    }
    if (isType(action, JobPasswordProvided)) {
        return newStateWithElementChanges(
            state,
            action.payload.job,
            {
                status: JobInfoStatus.PASSWORD_PROVIDED,
                providedPassword: action.payload.password,
            },
        );
    }

    if (isType(action, FetchedJobInfo)) {
        return newStateWithElementChanges(
            state,
            action.payload.job,
            {
                status: JobInfoStatus.FETCHED,
                jobInfo: action.payload.info,
                viewMode: state[action.payload.job] && state[action.payload.job].viewMode || 'flow',
            },
        );
    }

    if (isType(action, ErrorWhenFetchingJobInfo)) {
        return newStateWithElementChanges(state, action.payload, {status: JobInfoStatus.FETCH_FAILED});
    }

    if (isType(action, JobInfoChangeDetected)) {
        const stateJob = state[action.payload.jobID];
        if (stateJob && stateJob.jobInfo && stateJob.jobInfo.last_update < action.payload.eventID) {
            return newStateWithElementChanges(state, action.payload.jobID, {status: JobInfoStatus.EXPIRED});
        }
    }

    if (isType(action, JobWasDeleted)) {
        const {[action.payload]: omit, ...s} = state;
        return s;
    }

    if (isType(action, UserUnsubscribedFromAlbum)) {
        const {[action.payload]: omit, ...s} = state;
        return s;
    }

    if (isType(action, JobWasCreated)) {
        return newStateWithElementChanges(
            state,
            action.payload,
            {
                pending: true,
            },
        );
    }

    if (isType(action, JobWasConfirmed)) {
        return newStateWithElementChanges(
            state,
            action.payload,
            {
                pending: undefined,
            },
        );
    }

    if (isType(action, FileWasSetAsCoverPhoto)) {
        return newStateWithJobInfoChanges(
            state,
            action.payload.jobID,
            {
                coverPhoto: action.payload.fileID,
            },
        );
    }

    if (isType(action, AlbumViewModeChanged)) {
        return newStateWithElementChanges(
            state,
            action.payload.jobID,
            {
                viewMode: action.payload.viewMode,
            },
        );
    }

    if (isType(action, AlbumSortModeChanged)) {
        return newStateWithElementChanges(
            state,
            action.payload.jobID,
            {
                sortMode: action.payload.sortMode,
            },
        );
    }

    if (isType(action, JobChangesWasFetched)) {
        return newStateWithElementChanges(
            state,
            action.payload.jobID,
            {lastSeenSerial: action.payload.lastSerial},
        );
    }

    if (isType(action, UnableToFetchJobChanges)) {
        return newStateWithElementChanges(
            state,
            action.payload,
            {lastSeenSerial: -1},
        );
    }

    if (isType(action, ChangeJobPropertySubmitted)) {
        const {jobInfo} = state[action.payload.job];
        if (jobInfo) {
            return newStateWithJobInfoChanges(
                state,
                action.payload.job,
                {
                    pendingProperties: {
                        ...jobInfo.pendingProperties,
                        [action.payload.property]: action.payload.value,
                    },
                },
            );
        }
    }

    if (isType(action, ChangeJobPropertySucceeded)) {
        const {jobInfo} = state[action.payload.job];
        const {property} = action.payload;
        if (jobInfo) {
            const newPendings = {...jobInfo.pendingProperties};
            delete newPendings[property];
            return newStateWithJobInfoChanges(
                state,
                action.payload.job,
                {
                    pendingProperties: newPendings,
                    [property]: action.payload.value,
                },
            );
        }
    }

    if (isType(action, ChangeJobPropertyFailed)) {
        const {jobInfo} = state[action.payload.job];
        const {property} = action.payload;
        if (jobInfo) {
            const newPendings = {...jobInfo.pendingProperties};
            delete newPendings[property];
            return newStateWithJobInfoChanges(
                state,
                action.payload.job,
                {
                    pendingProperties: newPendings,
                },
            );
        }
    }

    return state;
};
