import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {deleteMultipleFiles, downloadFiles, fetchAllUserFiles} from '../../API/job';
import {_, getStringWithAmount} from '../../assets/localizedStrings';
import {colors, layout} from '../../assets/styleConstants';
import {Pages} from '../../routing/index';
import {getCurrentUserUUID} from '../../state/currentUser/selectors';
import {State} from '../../state/store';
import {
    DocumentFileGroup, getTimelineDocumentGroups,
    getTimelineJobID, isAllFilesFetched,
} from '../../state/timeline/selectors';
import {isMobileMode} from '../../state/viewMode/selectors';
import {Button} from '../Common/Button';
import {ConfirmPromptOverlay} from '../Common/DialoguePromptOverlay';
import {EmptyStatePage} from '../Common/EmptyStatePage';
import {OverflowMenu} from '../Common/OverflowMenu';
import {PageWrapper} from '../Common/PageWrapper';
import {RippleLoaderPage} from '../Common/RippleLoader';
import {HeaderText} from '../Common/StandardPageElements';
import {CheckFilledIcon} from '../Icons/CheckIcon';
import {DeleteIcon} from '../Icons/DeleteIcon';
import {DownloadIcon} from '../Icons/DownloadIcon';
import {FilesIllustration} from '../Icons/FilesIllustration';
import {MainNavBarDesktop} from '../Navigation/MainNavBarDesktop';
import {TopNavBarMobile} from '../Navigation/TopNavBarMobile';
import {SelectionToolbarDesktop, SelectionToolbarMobile} from '../Timeline/SelectionToolBar';
import {DocumentsList} from './DocumentsList';

const ContentWrapper = styled.div`
    max-width: ${layout.maxContainerWidth}px;
    margin: 0 auto;
`;

const Header = HeaderText.extend`
    margin-top: ${layout.selectionToolbarHeight}px;
`;

type PromptProps = {
    filesNum: number,
    onConfirm: () => any,
    onDeny: () => any,
};
const DeleteFilesPrompt: React.SFC<PromptProps> = ({filesNum, onConfirm, onDeny}) => {
    if (filesNum === 0) {return null; }

    const deletePromptText = getStringWithAmount(
        filesNum,
        _('delete_single_document_file'),
        _('delete_document_files__format'),
    );

    return (
        <ConfirmPromptOverlay
            onConfirm={onConfirm}
            onCancel={onDeny}
            confirmText={_('delete_')}
        >
            {deletePromptText}
        </ConfirmPromptOverlay>
    );
};

type StateProps = {
    isMobileMode: boolean,
    timelineJobID: JobID|undefined,
    currentUser: UserID|undefined,
    isDocumentsReady: boolean,
    documentGroups: DocumentFileGroup[],
};

type DocumentsAction = (jobID: JobID, files: FileID[]) => Promise<any>;
type DispatchProps = {
    fetchAllFiles: (jobID: JobID, currentUser: UserID) => any,
    deleteFiles: DocumentsAction,
    downloadFiles: DocumentsAction,
};

type Props = StateProps & DispatchProps;

type PageState = {
    selectedFiles: DictionaryOf<boolean>,
    isInSelectMode: boolean,
    deletePromptFiles: FileID[],
};

class DocumentsPageComponent extends React.Component<Props, PageState> {
    private initialState: PageState = {
        selectedFiles: {},
        isInSelectMode: false,
        deletePromptFiles: [],
    };
    public state: PageState = this.initialState;

    private tryFetchDocuments = ({isDocumentsReady, timelineJobID, currentUser}: Props) => {
        if (!isDocumentsReady && timelineJobID && currentUser) {
            this.props.fetchAllFiles(timelineJobID, currentUser);
        }
    }

    private handleSelectFile = (fileID: FileID) => {
        this.setState((s) => ({
            ...s,
            selectedFiles: {
                ...this.state.selectedFiles,
                [fileID]: true,
            },
            isInSelectMode: true,
        }));
    }

    private handleDeSelectFile = (fileID: FileID) => {
        const {[fileID]: omit, ...newFiles} = this.state.selectedFiles;
        this.setState((s) => ({
            ...s,
            selectedFiles: newFiles,
            isInSelectMode: Object.keys(newFiles).length > 0,
        }));
    }

    private resetSelection = () => {
        this.setState(this.initialState);
    }

    private doActionForFiles = (action: DocumentsAction, targetFiles: FileID[]) => {
        if (this.props.timelineJobID) {
            action(this.props.timelineJobID, targetFiles).then(this.resetSelection);
        }
    }

    private handleDownloadSelectedFiles = () => {
        this.doActionForFiles(this.props.downloadFiles, Object.keys(this.state.selectedFiles));
    }

    private handleDownloadSingleFile = (fileID: FileID) => {
        this.doActionForFiles(this.props.downloadFiles, [fileID]);
    }

    private triggerDeletePromptForSelectedFiles = () => {
        this.setState({
            ...this.state,
            deletePromptFiles: Object.keys(this.state.selectedFiles),
        });
    }

    private triggerDeletePromptForSingleFile = (fileID: FileID) => {
        this.setState({
            ...this.state,
            deletePromptFiles: [fileID],
        });
    }

    private confirmDelete = () => {
        if (this.state.deletePromptFiles.length > 0) {
            this.doActionForFiles(this.props.deleteFiles, this.state.deletePromptFiles);
        }
    }

    private closePrompt = () => {
        this.setState({
            ...this.state,
            deletePromptFiles: [],
        });
    }

    private startSelectingFiles = () => {
        this.setState((s) => ({...s, isInSelectMode: true}));
    }

    private selectionButtons = [
        Button(_('download'), this.handleDownloadSelectedFiles, {icon: DownloadIcon}),
        Button(_('delete_'), this.triggerDeletePromptForSelectedFiles, {icon: DeleteIcon}),
    ];

    private getMobileMenuItems = () => {
        const options = [
            Button(_('select_files'), this.startSelectingFiles, {icon: CheckFilledIcon}),
        ];
        return [
            <OverflowMenu key={'overflow'} menuOptions={options} />,
        ];
    }

    public componentWillMount() {
        this.tryFetchDocuments(this.props);
    }

    public componentWillReceiveProps(nextProps: Props) {
        if (this.props.isDocumentsReady !== nextProps.isDocumentsReady
            || this.props.timelineJobID !== nextProps.timelineJobID
            || this.props.currentUser !== nextProps.currentUser
        ) {this.tryFetchDocuments(nextProps); }
    }

    public render() {
        const navBarDesktop = <MainNavBarDesktop currentPage={Pages.Documents} />;
        const navBarMobile =  !this.state.isInSelectMode && (
            <TopNavBarMobile currentPage={Pages.Documents} rightElements={this.getMobileMenuItems} />
        );

        const Toolbar = this.props.isMobileMode ? SelectionToolbarMobile : SelectionToolbarDesktop;
        const toolbarComp = this.state.isInSelectMode && (
            <Toolbar
                buttons={this.selectionButtons}
                selectedFiles={Object.keys(this.state.selectedFiles)}
                cancelSelectMode={this.resetSelection}
            />
        );

        let content;
        if (!this.props.isDocumentsReady) {
            content = <RippleLoaderPage />;
        }
        else if (this.props.documentGroups.length === 0) {
            content = (
                <EmptyStatePage
                    header={_('documents_no_files')}
                    illustration={<FilesIllustration color={colors.captureBlue} size={100}/>}
                />
            );
        }
        else {
            content = (
                <DocumentsList
                    isMobile={this.props.isMobileMode}
                    isInSelectMode={this.state.isInSelectMode}
                    selectedFiles={this.state.selectedFiles}
                    documentGroups={this.props.documentGroups}
                    onFileSelected={this.handleSelectFile}
                    onFileDeSelected={this.handleDeSelectFile}
                    deleteFile={this.triggerDeletePromptForSingleFile}
                    downloadFile={this.handleDownloadSingleFile}
                />
            );
        }

        return (
            <PageWrapper
                navBar={this.props.isMobileMode ? navBarMobile : navBarDesktop}
                isContentReady={this.props.isDocumentsReady}
            >
                <ContentWrapper>
                    {!this.props.isMobileMode && <Header>{_('documents')}</Header>}
                    {content}
                </ContentWrapper>
                {toolbarComp}
                <DeleteFilesPrompt
                    filesNum={this.state.deletePromptFiles.length}
                    onConfirm={this.confirmDelete}
                    onDeny={this.closePrompt}
                />
            </PageWrapper>
        );
    }
}

const mapStateToProps = (state: State): StateProps => ({
    isMobileMode: isMobileMode(state),
    timelineJobID: getTimelineJobID(state),
    isDocumentsReady: isAllFilesFetched(state),
    currentUser: getCurrentUserUUID(state),
    documentGroups: getTimelineDocumentGroups(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    fetchAllFiles: (jobID: JobID, currentUser: UserID) => fetchAllUserFiles(dispatch, jobID, currentUser),
    deleteFiles: (jobID: JobID, files: FileID[]) => deleteMultipleFiles(dispatch, jobID, files),
    downloadFiles: (jobID: JobID, files: FileID[]) => downloadFiles(dispatch, jobID, files),
});

export const DocumentsPage = connect(mapStateToProps, mapDispatchToProps)(DocumentsPageComponent);
