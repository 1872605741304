import {Dispatch} from '../state/common/actions';
import {CaptureFile} from '../state/files/reducer';
import {
    DownloadShareClicked, DownloadShareFailed, DownloadShareSuccess,
    ShareCopiedToTimeline, ShareCopiedToTimelineFailed,
} from '../state/share/actions';
import {LongRunningTaskFinished, LongRunningTaskStarted} from '../state/statusNotifications/actions';
import {getServiceProvider} from './HostProvider';
import {tryCopyFilesToJobCompletely} from './job';

export const downloadShare = async (dispatch: Dispatch, shareID: JobID) => {
    dispatch(DownloadShareClicked(shareID));
    try {
        const service = await getServiceProvider().getAppServiceForJob(shareID);
        await service.downloadAllFilesInJob(shareID);
        dispatch(DownloadShareSuccess(shareID));
    } catch (e) {
        dispatch(DownloadShareFailed(shareID));
    }
};

export const copyShareFilesToTimeline = async (dispatch: Dispatch, timelineJobID: JobID, files: CaptureFile[]) => {
    try {
        dispatch(LongRunningTaskStarted('filesAreBeingCopied'));
        await tryCopyFilesToJobCompletely(dispatch, timelineJobID, files);
        dispatch(ShareCopiedToTimeline());
    }
    catch (e) {
        dispatch(ShareCopiedToTimelineFailed());
    }

    dispatch(LongRunningTaskFinished('filesAreBeingCopied'));
};
