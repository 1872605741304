import * as React from 'react';
import styled from 'styled-components';
import {appLanguages, getCurrentLocale, setCurrentLocale} from '../../../../config/constants';
import {_} from '../../assets/localizedStrings';
import {inArray} from '../../utilities/arrayUtils';
import {ChoiceMenu} from '../Common/ChoiceMenu';
import {ButtonWithExpandingContent} from '../Common/Positioning';
import {RadioButtonGroup, RadioButtonOption} from '../Common/RadioButtonGroup';
import {ChevronIcon, ChevronIconFlipped} from '../Icons/ChevronIcons';

const LangSelectorPlacement = styled.div`
    width: 100px;
    text-align: right;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    position: fixed;
    top: 16px;
    right: 16px;
`;
const ButtonWrapper = styled.div`
    color: ${(props: {color?: string}) => props.color || 'black'};
`;

const Chevron: React.SFC<{isExpanded: boolean, color?: string}> = ({isExpanded, color}) => {
    const Icon = isExpanded ? ChevronIconFlipped : ChevronIcon;
    return <Icon color={color || 'black'} size={12}/>;
};

const langOptions: Array<RadioButtonOption<string>> = appLanguages
    .filter((l) => inArray(['ENG', 'THA', 'BEN', 'BUR'], l.shortname))
    .map((lang) => ({text: lang.name, value: lang.key}))
    .sort((a, b) => a.text.localeCompare(b.text));

export const TakeoutLanguageSelectorInner = ButtonWithExpandingContent({h: 'right', v: 'bottom'})<{color?: string}>(
    ({toggleExpand, isExpanded, color}) => (
        <ButtonWrapper onClick={toggleExpand} color={color}>
            <Chevron isExpanded={isExpanded} color={color}/> Language
        </ButtonWrapper>
    ),
    () => (
        <ChoiceMenu title={_('select_language')}>
            <RadioButtonGroup
                value={getCurrentLocale()}
                options={langOptions}
                onSelectOption={setCurrentLocale}
            />
        </ChoiceMenu>
    ),
);

export const TakeoutLanguageSelector: React.SFC<{color?: string}> = (props) => (
    <LangSelectorPlacement>
        <TakeoutLanguageSelectorInner color={props.color}/>
    </LangSelectorPlacement>
);
