import * as React from 'react';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {ChoiceMenu} from '../Common/ChoiceMenu';
import {IconButton, TextButton} from '../Common/IconTextButton';
import {ButtonWithExpandingContent, ContentProps} from '../Common/Positioning';
import {IconProps} from '../Icons/index';
import {AlbumPrivacyModeSelector} from './AlbumPrivacyModeSelector';

type Props = {
    buttonText: string,
    icon: React.SFC<IconProps>,
};

const CreateAlbumPopup: React.SFC<ContentProps> = (props) => {
    if (!props.isExpanded) {
        return null;
    }
    return (
        <ChoiceMenu title={_('create_new')} onClickOutside={props.doCollapse}>
            <AlbumPrivacyModeSelector/>
        </ChoiceMenu>
    );
};

export const CreateAlbumTextButton = ButtonWithExpandingContent({v: 'bottom', h: 'right'})<Props>(
    ({toggleExpand, isExpanded, buttonText}) => (
        <TextButton
            onClick={toggleExpand}
            text={buttonText}
            color={isExpanded ? colors.captureBlue : ''}
        />
    ),
    CreateAlbumPopup,
);
export const CreateAlbumIconButton = ButtonWithExpandingContent({v: 'bottom', h: 'right'})<Props>(
    ({toggleExpand, isExpanded, icon}) => (
        <IconButton
            onClick={toggleExpand}
            icon={icon}
            color={isExpanded ? colors.captureBlue : ''}
            hoverColor={colors.captureBlue}
        />
    ),
    CreateAlbumPopup,
);
