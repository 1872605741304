import {SFC} from 'react';
import {IconProps} from '../Icons/index';

export type ButtonIcon = SFC<IconProps>;
export type ButtonProps = {
    onClick: () => void,
    text: string,
    isActive: boolean,
    icon?: ButtonIcon,
};

export function Button(text: string, action: (() => any), optional: Partial<ButtonProps> = {}): ButtonProps {
    return {
        text,
        onClick: action,
        isActive: optional.isActive !== false,
        icon: optional.icon,
    };
}
