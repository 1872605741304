import * as React from 'react';
import {IndexRedirect, Route, Router} from 'react-router';
import {CommentsListOverlay} from '../components/Album';
import {makeAlbumMount} from '../components/Album/AlbumMount';
import {AlbumReceiverPage} from '../components/Album/AlbumReceiverPage';
import {AlbumListPage} from '../components/AlbumList/AlbumListPage';
import {AlbumCarouselViewPage, TimelineCarouselViewPage} from '../components/CarouselView/CarouselViewPage';
import {DocumentsPage} from '../components/Documents/DocumentsPage';
import {EditAlbumPage} from '../components/EditAlbum/EditAlbumPage';
import {
    MorePhotosSelectionPage,
    PhotoSelectionPrivateAlbumPage,
    PhotoSelectionSharedAlbumPage,
} from '../components/EditAlbum/PhotoSelectionPage';
import {PageNotFound} from '../components/Info/PageNotFound';
import {LoginRequiredCopyAlbum} from '../components/Info/RequireLoginCopyAlbum';
import {RequireLoginCreateNewAlbum} from '../components/Info/RequireLoginCreateNewAlbum';
import {SelectFromTimelineToExternalPage} from '../components/SelectFromExternal/SelectFromTimelineToExternalPage';
import {SettingsPage} from '../components/Settings/SettingsPage';
import {RequireLoginCopyShare} from '../components/ShareReceiver/CopyShareToTimeline';
import {ShareMountUnpackedUUID} from '../components/ShareReceiver/ShareContainer';
import {TakeoutPage} from '../components/Takeout/TakeoutPage';
import {TimelinePage} from '../components/Timeline';
import {TrashPage} from '../components/Trash/TrashPage';
import {LoggedInApp, MaybeLoggedInApp} from './App';
import {RoutePath} from './routePath';

const AlbumMount = makeAlbumMount(AlbumReceiverPage); // TODO: New inner component

export const scrollToTop = () => window.scrollTo(0, 0);

/* tslint:disable:max-line-length */
export class MainRouter extends Router {

    public render() {
        return (
            <Router history={this.props.history} onUpdate={scrollToTop}>
                <Route path="/" component={LoggedInApp}>
                    <IndexRedirect to={'/' + RoutePath.Timeline} />
                    <Route path={RoutePath.Timeline} component={TimelinePage}/>
                    <Route path={RoutePath.getTimelineCarouselPath(':sourceGroup', ':fileID')} component={TimelineCarouselViewPage}/>
                    <Route path={RoutePath.Albums} component={AlbumListPage}/>
                    <Route path={RoutePath.getLoggedInAlbumPath(':albumIDB64')} component={AlbumMount}/>
                    <Route path={RoutePath.Trash} component={TrashPage}/>
                    <Route path={RoutePath.CreateSharedAlbum} component={PhotoSelectionSharedAlbumPage}/>
                    <Route path={RoutePath.CreatePrivateAlbum} component={PhotoSelectionPrivateAlbumPage}/>
                    <Route path={RoutePath.morePhotosAlbum(':albumIDB64')} component={MorePhotosSelectionPage}/>
                    <Route path={RoutePath.getEditAlbumPath(':albumIDB64', ':goToAfter')} component={EditAlbumPage}/>
                    <Route path={RoutePath.Documents} component={DocumentsPage}/>
                    <Route path={RoutePath.Settings} component={SettingsPage}/>
                    <Route path={RoutePath.SelectPhotosToExternal} component={SelectFromTimelineToExternalPage}/>
                </Route>
                <Route path="/" component={MaybeLoggedInApp}>
                    <Route path={RoutePath.getNotLoggedInAlbumPath(':albumIDB64')} component={AlbumMount}/>
                    <Route path={RoutePath.getAlbumCarouselPath(':albumIDB64', ':fileID')} component={AlbumCarouselViewPage}/>
                    <Route path={RoutePath.getCopyAlbumLoginPath(':albumIDB64', '(:fileID)')} component={LoginRequiredCopyAlbum}/>
                    <Route path={RoutePath.getAlbumUnpackedUUIDPath(':albumID')} component={AlbumMount}/>
                    <Route path={RoutePath.getAlbumFileCommentsPath(':albumIDB64', ':fileID')}component={CommentsListOverlay}/>
                    <Route path={RoutePath.getCreateAlbumLoginPath(':albumIDB64')} component={RequireLoginCreateNewAlbum}/>
                    <Route path={RoutePath.Takeout} component={TakeoutPage}/>
                    <Route path={RoutePath.getShareReceiverPath(':shareID')} component={ShareMountUnpackedUUID}/> {/*TODO: use b64 encoded uuids */}
                    <Route path={RoutePath.getCopyShareLoginPath(':shareID')} component={RequireLoginCopyShare}/>
                    <Route path="*" component={PageNotFound} />
                </Route>
            </Router>
        );
    }
}
