import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const BookIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke={props.color || colors.defaultIconColor} strokeWidth="2" transform="translate(3.000000, 4.000000)"  fill="none" fillRule="evenodd">
            <path d="M0,0.414634146 L0,14.097561 C0,14.326439 0.183272727,14.5121951 0.409090909,14.5121951 C5.247,14.5121951 9,15.8498049 9,17 L9,1.93136585 C7.44218182,0.702390244 3.88390909,0 0.409090909,0 C0.183272727,0 0,0.185756098 0,0.414634146" />
            <path d="M17.5909091,0 C14.1160909,0 10.5578182,0.702390244 9,1.93136585 L9,17 C9,15.8498049 12.7521818,14.5121951 17.5909091,14.5121951 C17.8167273,14.5121951 18,14.326439 18,14.097561 L18,0.414634146 C18,0.185756098 17.8167273,0 17.5909091,0" />
        </g>
    </svg>
);
