import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {fontSize} from '../../assets/styleConstants';
import {AlbumCarousel} from '../../routing/pages';
import {ClickedAlbumImage} from '../../state/album/actions';
import {AlbumFile} from '../../state/album/selectors';
import {CarouselViewInfo} from '../../state/carouselViewer/reducer';
import {NavigateTo} from '../../state/routing/actions';
import {GridStyle} from '../../utilities/gridElementSizeCalculator';
import {MaybeNumberOf} from '../Common/MaybeNumberOf';
import {VideoIndicator} from '../Common/VideoIndicator';
import {GridElement, GridElementIconContainerBottom} from '../GridView/GridElement';
import {HeartIcon} from '../Icons';
import {CommentIcon} from '../Icons/CommentIcon';
import {albumFileDOMClass} from './AlbumReceiverPage';

const Wrapper = styled.div`
    width: ${(props: {width: number}) => props.width}px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
`;

const ThumbElement = styled(GridElement).attrs({
    className: albumFileDOMClass,
})`
    position: relative;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
    &:focus {
        outline: 4px solid white;
    }
`;

const InfoElements = GridElementIconContainerBottom.extend`
    font-size: ${fontSize.small_12};
`;

const FileInfo = styled.div`
    display: inline-flex;
`;

type ElementProps = {
    file: AlbumFile,
    styling: GridStyle,
    onElementClick: (imageID: FileID) => any,
    showFileReactions?: boolean,
    shouldBeFocused?: boolean,
};
class AlbumGridElement extends React.Component<ElementProps> {
    private element = React.createRef<HTMLDivElement>();
    private onImageClick = () => {
        this.props.onElementClick(this.props.file.fileID);
    }
    public componentDidMount() {
        if (this.element.current && this.props.shouldBeFocused) {
            this.element.current.focus();
        }
    }
    public render() {
        const {file} = this.props;
        const showFileInfo = (file.duration || file.totalLoveCount || file.comments.length) > 0;
        const info = showFileInfo && (
            <InfoElements>
                <div>
                    {file.duration && <VideoIndicator duration={file.duration}/>}
                </div>
                {this.props.showFileReactions !== false && (
                    <FileInfo>
                        <MaybeNumberOf icon={HeartIcon} numberOf={file.totalLoveCount}/>
                        <MaybeNumberOf icon={CommentIcon} numberOf={file.comments.length}/>
                    </FileInfo>
                )}
            </InfoElements>
        );

        return (
            <ThumbElement
                style={{backgroundImage: `url('${file.thumbURLSmall}')`}}
                elementWidth={this.props.styling.elementWidth}
                elementHeight={this.props.styling.elementHeight}
                elementSpaceAround={this.props.styling.elementSpaceAround}
                onClick={this.onImageClick}
                key={file.fileID}
                innerRef={this.element}
            >
                {info}
            </ThumbElement>
        );
    }
}

type DispatchProps = {
    doEnterCarouselView: (imageID: FileID) => any,
};
type OwnProps = {
    albumID: JobID,
    files: AlbumFile[],
    styling: GridStyle,
    showFileReactions?: boolean,
    lastSeenElement?: FileID,
};
type Props = DispatchProps & OwnProps;
class _AlbumGridView extends React.Component<Props> {
    public render() {
        const files = this.props.files.map((file, i) => (
            <AlbumGridElement
                key={file.fileID}
                file={file}
                styling={this.props.styling}
                onElementClick={this.props.doEnterCarouselView}
                showFileReactions={this.props.showFileReactions}
                shouldBeFocused={this.props.lastSeenElement === file.fileID || i === 0}
            />
        ));

        return (
            <Wrapper width={this.props.styling.width}>
                {files}
            </Wrapper>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    doEnterCarouselView: (imageID: ImageID) => {
        dispatch(NavigateTo(AlbumCarousel(ownProps.albumID, imageID)));
        dispatch(ClickedAlbumImage({albumID: ownProps.albumID, imageID}));
    },
});

export const AlbumGridView = connect(
    null,
    mapDispatchToProps,
)(_AlbumGridView);
