import * as React from 'react';
import {Route, Router} from 'react-router';
import {ShutdownInfoPage} from '../../common/components/Takeout/ShutdownInfoPage';
import {ShutdownUserPage} from '../../common/components/Takeout/ShutdownUserPage';
import {scrollToTop} from '../../common/routing/MainRouter';
import {RoutePath} from '../../common/routing/routePath';
import {RedirectToWebComponent} from '../components/RedirectToWebComponent';
import {AlbumReceiverApp} from './AlbumReceiverApp';

/* tslint:disable:max-line-length */
export class AlbumReceiverRouter extends Router {
    public render() {
        return (
            <Router history={this.props.history} onUpdate={scrollToTop}>
                <Route path="/" component={AlbumReceiverApp}>
                    <Route path={RoutePath.ShutdownInfo} component={ShutdownInfoPage}/>
                    <Route path={RoutePath.ShutdownUser} component={ShutdownUserPage}/>
                    <Route path={RoutePath.getNotLoggedInAlbumPath(':albumIDB64')} component={RedirectToWebComponent}/>
                    <Route path={RoutePath.getAlbumUnpackedUUIDPath(':albumID')} component={RedirectToWebComponent}/>
                    <Route path={'*'} component={RedirectToWebComponent}/>
                </Route>
            </Router>
        );
    }
}
