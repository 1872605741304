import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {isOpenInsideAppWebView} from '../../API/externals';
import {createTakeout, fetchTakeoutStatus, resetTakeout} from '../../API/takeout';
import {getEmailAdressOfLoggedInUser, isLoggedIn} from '../../state/currentUser/selectors';
import {State} from '../../state/store';
import {getNotificationEmail, getTakeoutStatus, TakeoutStatus} from '../../state/takeout/selectors';
import {isMobileMode} from '../../state/viewMode/selectors';
import {LoadingPage} from '../Common/LoadingPage';
import {trackTakeoutPageview} from './analytics';
import {TakeoutBakingPage} from './TakeoutBakingPage';
import {TakeoutBeforeLoggedInPage} from './TakeoutBeforeLoggedInPage';
import {FixedTopLeftFAQButton} from './TakeoutFAQButton';
import {TakeoutFilesDownloadPage} from './TakeoutFilesDownloadPage';
import {TakeoutInfoPage} from './TakeoutInfoPage';
import {TakeoutLanguageSelector} from './TakeoutLanguageSelector';
import {TakeoutReadyDisabledInAppPage} from './TakeoutReadyDisabledInAppPage';

type StateProps = {
    isLoggedIn: boolean
    takeoutStatus: TakeoutStatus|undefined;
    userEmail: string|undefined;
    notificationEmail: string|undefined;
    isMobile: boolean,
};

type DispatchProps = {
    doLoadStatus: () => any;
    createTakeout: (notificationEmail: string) => any,
};

type Props = StateProps & DispatchProps;

class InnerTakeoutPage extends React.Component<Props, {}> {

    public componentWillReceiveProps(newProps: Props) {
        if (!this.props.isLoggedIn && newProps.isLoggedIn) {
            newProps.doLoadStatus();
        }
        if (this.props.takeoutStatus !== newProps.takeoutStatus) {
            trackTakeoutPageview('/takeout/' + newProps.takeoutStatus);
        }
    }
    public componentDidMount() {
        // Developer endpoint from console
        window.resetTakeout = resetTakeout;

        trackTakeoutPageview('/takeout');
        if (this.props.isLoggedIn) {
            this.props.doLoadStatus();
        }
    }

    private getContent() {
        if (! this.props.isLoggedIn) {
            return <TakeoutBeforeLoggedInPage />;
        }

        switch (this.props.takeoutStatus) {
            case 'notStarted':
                return (
                    <TakeoutInfoPage />
                );
            case 'baking':
                return (
                    <TakeoutBakingPage
                        email={this.props.notificationEmail}
                    />
                );
            case 'ready':
                return isOpenInsideAppWebView() ? <TakeoutReadyDisabledInAppPage /> : <TakeoutFilesDownloadPage />;
            default:
                return <LoadingPage />;
        }
    }

    public render() {
        return (
            <div>
                <FixedTopLeftFAQButton />
                <TakeoutLanguageSelector />
                {this.getContent()}
            </div>
        );
    }
}

const stateToProps = (state: State): StateProps => ({
    isLoggedIn: isLoggedIn(state),
    takeoutStatus: getTakeoutStatus(state),
    notificationEmail: getNotificationEmail(state),
    userEmail: getEmailAdressOfLoggedInUser(state),
    isMobile: isMobileMode(state),
});

const dispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doLoadStatus: () => fetchTakeoutStatus(dispatch),
    createTakeout: (notificationEmail: string) => createTakeout(dispatch, notificationEmail),
});

export const TakeoutPage = connect(stateToProps, dispatchToProps)(InnerTakeoutPage);

const SelectOverlay = styled.div`
    position: fixed;
    bottom: 5px;
    right: 5px;
    border: 1px solid palevioletred;
    padding 10px;
    background: rgba(100, 200, 50, 0.4);
`;
export class SelfServiceTakeoutPage extends React.PureComponent<{}, Props> {
    public state: Props = {
        isLoggedIn: false,
        takeoutStatus: 'notStarted',
        notificationEmail: undefined,
        userEmail: undefined,
        doLoadStatus: () => { /* handle manually by checkbox */ },
        createTakeout: (_notificationEmail: string) => {},
        isMobile: false,
    };

    private handleLoginChange = () => {
        this.setState((s) => ({...s, isLoggedIn: !s.isLoggedIn}));
    }
    private handleStatusChange = (e: React.SyntheticEvent<HTMLSelectElement>) => {
        this.setState({...this.state, takeoutStatus: e.currentTarget.value as TakeoutStatus});
    }
    private handleEmailChange = (e:  React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({...this.state, notificationEmail: e.currentTarget.value});
    }

    public render() {
        const takeoutStatuses: TakeoutStatus[] = ['notStarted', 'baking', 'ready'];

        return (
            <div>
                <SelectOverlay>
                    <b>Select takeout variables:</b><br />
                    <input type="checkbox" onClick={this.handleLoginChange} /> Is logged in?<br />
                    <select value={this.state.takeoutStatus} onChange={this.handleStatusChange}>
                        {takeoutStatuses.map((v) => (<option key={v} value={v}>{v}</option>))}
                    </select> Takeout status<br />
                    <input value={this.state.notificationEmail} onChange={this.handleEmailChange}/> User email
                </SelectOverlay>
                <InnerTakeoutPage {...this.state}/>
            </div>
        );
    }
}
