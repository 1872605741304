import {AnyAction, Middleware} from 'redux';
import {setCurrentVisibleRanges} from '../../API/syncers/TimelineChunkSyncer';
import {slideBarPaginationThumbs} from '../../components/CarouselView';
import {Album, AlbumCarousel, AlbumNotLoggedIn, Share, Timeline, TimelineCarousel} from '../../routing/pages';
import {getElementIndex} from '../../utilities/arrayUtils';
import {BulkOfActions, isType} from '../common/actions';
import {isLoggedIn} from '../currentUser/selectors';
import {getCaptureFilesForJob} from '../files/selectors';
import {FileWasAddedToJob, FileWasRemovedFromJob} from '../job/actions';
import {isTimelineJob} from '../jobInfo/selectors';
import {NavigateByReplacingCurrent, NavigateTo} from '../routing/actions';
import {State} from '../store';
import {getMonthByFileID, getTimelineMonths} from '../timeline/selectors';
import {
    ChangedCurrentCarouselFile, EnteredCarouselView,
} from './actions';
import {getCurrentViewInfo} from './pureSelectors';
import {getCurrentViewerNode, getCurrentViewJobType} from './selectors';

const checkToLoadMoreMonths = (state: State, timelineJob: JobID, currentFile: FileID) => {
    const files = getCaptureFilesForJob(state, timelineJob);
    const months = getTimelineMonths(state);
    const currentOffset = getElementIndex(files, (f) => f.fileID === currentFile);

    if (currentOffset !== -1 && months.length !== 0) {
        const paginationThreshold = 2 * slideBarPaginationThumbs;
        const prevPaginationIndex = Math.max(0, currentOffset - paginationThreshold);
        const nextPaginationIndex = currentOffset + paginationThreshold;

        const visibleRanges: Month[] = [];
        let monthStart: number = 0;
        months.forEach((m) => {
            const monthEnd = monthStart + m.count;
            if (monthStart < nextPaginationIndex && monthEnd >= prevPaginationIndex) {
                const {year, month} = m;
                visibleRanges.push({year, month});
            }

            monthStart = monthEnd;
        });

        setCurrentVisibleRanges(visibleRanges);
    }
};

export const carouselViewMiddleware: Middleware = (store) => {
    const handle = (action: AnyAction) => {
        const state: State = store.getState();

        if (isType(action, BulkOfActions)) {
            action.payload.forEach(handle);
        }

        if (isType(action, FileWasRemovedFromJob)) {
            const currentViewerNode = getCurrentViewerNode(state);
            if (currentViewerNode && currentViewerNode.file.fileID === action.payload.fileID) {
                const goTo = currentViewerNode.next || currentViewerNode.prev;
                if (goTo) {
                    store.dispatch(ChangedCurrentCarouselFile({
                        jobID: currentViewerNode.file.jobID,
                        fileID: goTo,
                    }));
                } else {
                    const currentJobType = getCurrentViewJobType(state);
                    const currentView = getCurrentViewInfo(state);
                    if (currentJobType && currentView) {
                        switch (currentJobType) {
                            case 'timeline':
                                store.dispatch(NavigateTo(Timeline));
                                break;
                            case 'share':
                                store.dispatch(NavigateTo(Share(currentView.jobID)));
                                break;
                            case 'album':
                                const albumPath = isLoggedIn(state) ? Album : AlbumNotLoggedIn;
                                store.dispatch(NavigateTo(albumPath(currentView.jobID)));
                                break;
                        }
                    }
                }
            }
        }

        if (isType(action, FileWasAddedToJob)) { // go back to deleted file after 'undo'
            const {prevView} = state.carouselViewer;
            if (prevView
                && prevView.fileID === action.payload.fileID
                && prevView.jobID === action.payload.jobID
            ) {
                store.dispatch(ChangedCurrentCarouselFile(prevView));
            }
        }

        if (isType(action, ChangedCurrentCarouselFile)) {
            const {jobID, fileID} = action.payload;
            if (isTimelineJob(state, jobID)) {
                checkToLoadMoreMonths(state, jobID, fileID);
                const sourceGroup = getMonthByFileID(state, fileID);
                if (sourceGroup) {
                    store.dispatch(NavigateByReplacingCurrent(
                        TimelineCarousel(sourceGroup, fileID),
                    ));
                }
            } else {
                store.dispatch(NavigateByReplacingCurrent(AlbumCarousel(jobID, fileID)));
            }
        }

        if (isType(action, EnteredCarouselView)) {
            const {jobID, fileID} = action.payload;
            if (isTimelineJob(state, jobID)) {
                checkToLoadMoreMonths(state, jobID, fileID);
            }
        }

    };
    return (next) => (action) => {
        handle(action);
        next(action);
    };
};
