import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {deleteMultipleFiles} from '../../API/job';
import {_} from '../../assets/localizedStrings';
import {State} from '../../state/store';
import {
    SelectionAction,
    TimelineSelectionActionConfirmHandled,
} from '../../state/timeline/actions';
import {
    getSelectedTimelineFileIDs,
    getTimelineJobID,
    getTimelineSelectionAction,
} from '../../state/timeline/selectors';
import {
    ConfirmPromptOverlay,
} from '../Common/DialoguePromptOverlay';

type StateProps = {
    timelineJob: JobID | undefined;
    selectedFiles: FileID[];
    selectionAction: SelectionAction | undefined;
};

type DispatchProps = {
    onConfirmUserAction: (
        type: SelectionAction,
        jobID: JobID,
        files: FileID[],
    ) => any;
    onConfirmPromptHandled: () => any;
};

type Props = StateProps & DispatchProps;

type DialogContent = {
    text: () => string;
};

type ConfirmUserAction = (
    dispatch: Dispatch,
    jobID: JobID,
    files: FileID[],
) => any;
const selectionActionConfirm: Record<SelectionAction, ConfirmUserAction> = {
    ['DELETE']: deleteMultipleFiles,
};

class DialogComponent extends React.PureComponent<Props, {}> {
    private confirms: Record<SelectionAction, DialogContent> = {
        ['DELETE']: {
            text: () =>
                this.props.selectedFiles.length === 1
                    ? _('delete_single_file_prompt')
                    : _('delete_files_prompt__format').replace(
                          '%d',
                          this.props.selectedFiles.length.toString(),
                      ),
        },
    };

    private onSelectionActionConfirm = () => {
        if (
            this.props.selectionAction !== undefined &&
            this.props.timelineJob
        ) {
            this.props.onConfirmUserAction(
                this.props.selectionAction,
                this.props.timelineJob,
                this.props.selectedFiles,
            );
        }
        this.props.onConfirmPromptHandled();
    }

    public render() {
        const {selectionAction} = this.props;
        if (selectionAction !== undefined) {
            return (
                <ConfirmPromptOverlay
                    onConfirm={this.onSelectionActionConfirm}
                    onCancel={this.props.onConfirmPromptHandled}
                    confirmText={_('delete_')}
                >
                    {this.confirms[selectionAction].text()}
                </ConfirmPromptOverlay>
            );
        }

        return null;
    }
}

const mapStateToProps = (state: State): StateProps => ({
    timelineJob: getTimelineJobID(state),
    selectedFiles: getSelectedTimelineFileIDs(state),
    selectionAction: getTimelineSelectionAction(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onConfirmUserAction: (
        type: SelectionAction,
        jobID: JobID,
        files: FileID[],
    ) => {
        selectionActionConfirm[type](dispatch, jobID, files);
    },
    onConfirmPromptHandled: () =>
        dispatch(TimelineSelectionActionConfirmHandled()),
});

export const SelectionActionDialogPlacement = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DialogComponent);
