import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {doLogin} from '../../API/login';
import {PageDescription, State} from '../../state';
import {isLoggedIn, isLoggingIn} from '../../state/currentUser/selectors';
import {NavigateByReplacingCurrent} from '../../state/routing/actions';
import {LoadingPage} from '../Common/LoadingPage';
import {LoginRequiredPage} from './LoginRequiredPage';

type OwnProps = {
    text: string,
    targetAfterLogin: PageDescription,
    afterLoginAction: (dispatch: Dispatch) => any,
};
type StateProps = {
    isLoggingIn: boolean,
    isLoggedIn: boolean,
};
type DispatchProps = {
    handleLoginOccurred: () => any,
};
type Props = OwnProps & StateProps & DispatchProps;

const stateToProps = (state: State): StateProps => ({
    isLoggedIn: isLoggedIn(state),
    isLoggingIn: isLoggingIn(state),
});
const dispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    handleLoginOccurred: () => {
        ownProps.afterLoginAction(dispatch);
        dispatch(NavigateByReplacingCurrent(ownProps.targetAfterLogin));
    },
});

class _RequireLoginActionPage extends React.Component<Props, {}> {
    public componentWillReceiveProps(newProps: Props) {
        if (newProps.isLoggedIn && !this.props.isLoggedIn) {
            newProps.handleLoginOccurred();
        }
    }
    private goBack = () => {
        history.back();
    }
    public render() {
        if (!this.props.isLoggingIn && !this.props.isLoggedIn) {
            return (
                <LoginRequiredPage
                    text={this.props.text}
                    onSignIn={doLogin}
                    onRegister={doLogin}
                    goBack={this.goBack}
                />
            );
        }
        return <LoadingPage />;
    }
}

export const RequireLoginActionPage = connect(stateToProps, dispatchToProps)(_RequireLoginActionPage);
