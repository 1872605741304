import {createSelector} from 'reselect';
import {RoutePath} from '../../routing/routePath';
import {b64ToUuid} from '../../utilities/uuid';
import {State} from '../store';
import {JobInfo, JobInfoElement, JobInfoState, JobInfoStatus} from './reducer';

const getJobInfoState = (state: State): JobInfoState => state.jobInfo || {};

export const getJobInfoElements = createSelector(
    getJobInfoState,
    (jobInfo) => Object.keys(jobInfo).map((k) => jobInfo[k]),
);

export const getJobUUIDFromURL = (currentURL: string): JobID|undefined => {
    const mountPoints: DictionaryOf<(match: RegExpMatchArray) => JobID> = {
        [RoutePath.getLoggedInAlbumPath('([^/]+)')]: (m) => b64ToUuid(m[1]),
        [RoutePath.getNotLoggedInAlbumPath('([^/]+)')]: (m) => b64ToUuid(m[1]),
        [RoutePath.getShareReceiverPath('([^/]+)')]: (m) => m[1], // TODO add b64ToUuid
        [RoutePath.getAlbumUnpackedUUIDPath('([^/]+)')]: (m) => m[1],
        [RoutePath.getAlbumFileCommentsPath('([^/]+)', '.*')]: (m) => b64ToUuid(m[1]),
        [RoutePath.getCreateAlbumLoginPath('([^/]+)')]: (m) => b64ToUuid(m[1]),
        [RoutePath.getAlbumCarouselPath('([^/]+)', '.*')]: (m) => b64ToUuid(m[1]),
    };

    return Object.keys(mountPoints).reduce<string|undefined>(
        (existing, url) => {
            const match = currentURL.match(new RegExp(url));
            return match ? mountPoints[url](match) : existing;
        },
        undefined,
    );
};

export const getJobInfoElement = (state: State, jobID: JobID): JobInfoElement => {
    return getJobInfoState(state)[jobID];
};

export const getJobInformation: (state: State, jobID: JobID) => JobInfo|undefined = createSelector(
    getJobInfoElement,
    (jobInfoElement: JobInfoElement|undefined) => {
        return jobInfoElement ? jobInfoElement.jobInfo : undefined;
    },
);

export const getJobTitle = (state: State, jobID: JobID, defaultText: string): string => {
    const info = getJobInformation(state, jobID);
    return info ? info.title : defaultText;
};

export const getStatusOfJob = (state: State, jobID: JobID): JobInfoStatus => {
    const jobInfoElement = getJobInfoElement(state, jobID);
    return jobInfoElement ? jobInfoElement.status : JobInfoStatus.NOT_STARTED;
};

export const getCreatorOfJob = (state: State, jobID: JobID): JobID|undefined => {
    const jobInfo = getJobInformation(state, jobID);
    return jobInfo ? jobInfo.owner : undefined;
};

export const getProvidedPassword = (state: State, jobID: JobID): string|undefined => {
    const jobInfoElement = getJobInfoElement(state, jobID);
    return jobInfoElement ? jobInfoElement.providedPassword : undefined;
};

export const isStoryJob = (state: State, jobID: JobID): boolean|undefined => {
    const jobInfo = getJobInformation(state, jobID);
    return jobInfo && jobInfo.type === 'story';
};
export const isTimelineJob = (state: State, jobID: JobID): boolean => {
    return state.timeline.timelineJob === jobID;
};
export const isShareJob = (state: State, jobID: JobID): boolean|undefined => {
    const jobInfo = getJobInformation(state, jobID);
    // Shares and timeline-jobs have the same type.
    // If a timeline-job is not the timeline of the current user it must be a share
    return jobInfo && jobInfo.type === 'timeline' && state.timeline.timelineJob !== jobID; // TODO: use getTimelineJobID()
};

export const isSharedJob = createSelector(
    getJobInformation,
    (info: JobInfo|undefined) => {
        if (info !== undefined) {
            const {pendingProperties} = info;
            return pendingProperties.isShared !== undefined ? pendingProperties.isShared : info.isShared;
        }

        return false;
    },
);
