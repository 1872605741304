import {createSelector, Selector} from 'reselect';
import {mediaBreakpoint__mobile, mediaBreakpoint__tabletLandscape} from '../../assets/styleConstants';
import {StateWithViewModeState as State} from '../store';

export const getViewportWidth = (state: State) => state.viewMode.width;
export const getViewportHeight = (state: State) => state.viewMode.height;

// If window width is less than breakpoint -> mobile, else desktop
export const isMobileMode: Selector<State, boolean> = createSelector(
    getViewportWidth,
    (viewportWidth) => viewportWidth <= mediaBreakpoint__mobile,
);
export const isLargerThanTablet = (state: State) => getViewportWidth(state) > mediaBreakpoint__tabletLandscape;

export const isLandscapeOrientation: Selector<State, boolean> = createSelector(
    getViewportWidth,
    getViewportHeight,
    (width, height) => width > height,
);
