type MobileDeviceTarget = 'Android' | 'iOS' | 'Windows' | 'otherDevice';
const mobileDeviceRegExp: Record<MobileDeviceTarget, RegExp> = {
    Android: /Android/i,
    iOS: /iPhone|iPad|iPod/i,
    Windows: /IEMobile|Windows Phone|Windows NT.*;.*Touch/i,
    otherDevice: /webOS|BlackBerry|BB|PlayBook|Kindle|Silk|Opera Mini/i,
};

export type MobileDeviceChecker = Record<MobileDeviceTarget|'any', () => boolean>;
export const isMobileDevice: MobileDeviceChecker = {
    Android: () => mobileDeviceRegExp['Android'].test(navigator.userAgent),
    iOS: () => mobileDeviceRegExp['iOS'].test(navigator.userAgent),
    Windows: () => mobileDeviceRegExp['Windows'].test(navigator.userAgent),
    otherDevice: () => mobileDeviceRegExp['otherDevice'].test(navigator.userAgent),
    any: () => (
        isMobileDevice.Android()
        || isMobileDevice.iOS()
        || isMobileDevice.Windows()
        || isMobileDevice.otherDevice()
    ),
};

export const isModernBrowser = (): boolean => { // IE 10+
    const isIE = navigator.userAgent.match(/MSIE (\d+)/);
    if (isIE && isIE.length === 2) {
        return parseInt(isIE[1], 10) > 9;
    }

    return true;
};

export const isAnyIE = () => /(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/.test(navigator.userAgent);

export const isSafariBrowser = (): boolean => {
    return navigator.userAgent.indexOf('Safari') !== -1;
};
