export type GridElementProps = {
    elementWidth: number,
    elementHeight: number,
    elementSpaceAround: number,
};
export type GridStyle = GridElementProps & {
    width: number,
};

export const getElementSize = (style: GridElementProps) => ({
    width: style.elementWidth + style.elementSpaceAround * 2,
    height: style.elementHeight + style.elementSpaceAround * 2,
});
export const computeGridContainerWidth = (screenWidth: number, maxContainerWidth: number, style: GridElementProps) => {
    const elementSize = getElementSize(style);
    return Math.floor(Math.min(maxContainerWidth, screenWidth) / elementSize.width) * elementSize.width;
};

export const calcImagesPerRow = (style: GridStyle) => {
    const elementSize = getElementSize(style);
    return Math.floor(style.width / elementSize.width);
};
export const calcNumberOfVisibleThumbs = (style: GridStyle, screenHeight: number): number => {
    const elementSize = getElementSize(style);
    const imagesPerRow = Math.floor(style.width / elementSize.width);

    return (
        Math.ceil(screenHeight / elementSize.height) * imagesPerRow

    );
};
