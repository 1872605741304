import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {deleteAlbum, setAlbumTitle} from '../../API/album';
import {deleteFile} from '../../API/job';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {Pages} from '../../routing';
import {NavigateTo} from '../../state';
import {ShowShareAlbumPrompt} from '../../state/album/actions';
import {getAlbumGridStyle, getPendingAlbum, PendingAlbum} from '../../state/album/selectors';
import {JobWasConfirmed} from '../../state/jobInfo/actions';
import {NavigateByReplacingCurrent} from '../../state/routing/actions';
import {State} from '../../state/store';
import {TimelineFileSelectingCanceled} from '../../state/timeline/actions';
import {isUploaderDone} from '../../state/uploader/selectors';
import {isMobileMode} from '../../state/viewMode/selectors';
import {GridStyle} from '../../utilities/gridElementSizeCalculator';
import {b64ToUuid} from '../../utilities/uuid';
import {EditableInput} from '../Common/EditableInput';
import {IconButton, TextButton} from '../Common/IconTextButton';
import {LoadingPage} from '../Common/LoadingPage';
import {CheckIcon} from '../Icons/CheckIcon';
import {CloseIcon} from '../Icons/CloseIcon';
import {PlusIcon} from '../Icons/PlusIcon';
import {TopNavPlaceholder} from '../Navigation/TopNavBar';
import {TwoAreasTopNavBar} from '../Navigation/TwoAreasTopNavBar';
import {UploadStatusPlacementMinified} from '../Uploader/UploadStatusPlacementMinified';
import {EditAlbumFileList} from './EditAlbumFileList';

const Wrapper = styled.div`
    display: block;
    margin: 0 auto;
`;

const InputWrapper = styled.div`
    width: ${(props: {isMobile: boolean}) => props.isMobile ? '100%' : '50%'};
    margin: 24px 10px;
    border-bottom: solid 1px #000;
`;

const ContentWrapper = styled.div`
    width: ${(props: {width: number}) => props.width}px;
    padding: 0 8px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContentHeader = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
const ButtonElementWrapper = styled.div`
    margin-left: 16px;
`;
const TextButtonWrapper = styled.div`
    text-transform: uppercase;
    font-weight: bold;
`;

type StateProps = {
    pendingAlbum: PendingAlbum|undefined,
    isMobile: boolean,
    uploaderDone: boolean,
    styling: GridStyle,
};

type DispatchProps = {
    cancelEditAlbum: () => any,
    addPhotos: () => any,
    confirmEditAlbum: (newTitle: string) => any,
    doRemoveFile: (fileID: FileID) => any,
    displaySharePrompt: () => any,
    doSetAlbumTitle: (newTitle: string) => any,
};

type OwnProps = {
    albumID: JobID,
    goToAfter: string,
};

type Props = StateProps & DispatchProps & OwnProps;

type EditAlbumPageState = {
    title: string,
};

class EditAlbumPageComponent extends React.Component<Props, EditAlbumPageState> {
    public state: EditAlbumPageState = {
        title: this.props.pendingAlbum && this.props.pendingAlbum.title || '',
    };
    private doCancelOnUnmount = true;
    private handleEditTitleChange = (newTitle: string) => {
        this.setState({
            ...this.state,
            title: newTitle.trim(),
        });
    }
    private handleEditTitleBlur = () => {
        this.props.doSetAlbumTitle(this.state.title);
    }
    private addPhotos = () => {
        this.doCancelOnUnmount = false;
        this.props.addPhotos();
    }
    private handleConfirmEditAlbum = () => {
        this.doCancelOnUnmount = false;
        this.props.confirmEditAlbum(this.state.title);
        if (this.props.pendingAlbum && this.props.pendingAlbum.isShared) {
            this.props.displaySharePrompt();
        }
    }
    private getNavElementsLeft = () => {
        return (
            <IconButton
                onClick={this.props.cancelEditAlbum}
                icon={CloseIcon}
            />
        );
    }
    private getNavElementsRight = () => {
        const isCreateButtonActive = this.props.uploaderDone
            && this.state.title !== ''
            && this.props.pendingAlbum
            && this.props.pendingAlbum.uploadedFiles.length !== 0;

        const confirmEditAlbumAction = isCreateButtonActive ? this.handleConfirmEditAlbum : undefined;
        if (this.props.isMobile) {
            return [
                (
                <ButtonElementWrapper key={'addPhotos'}>
                    <IconButton
                        onClick={this.addPhotos}
                        icon={PlusIcon}
                    />
                </ButtonElementWrapper>
                ),
                (
                <ButtonElementWrapper key={'createAlbum'}>
                    <IconButton
                        onClick={confirmEditAlbumAction}
                        icon={CheckIcon}
                    />
                </ButtonElementWrapper>
                ),
            ];
        }
        else {
            return [
                (
                <TextButtonWrapper key={'createAlbum'}>
                    <TextButton
                        onClick={confirmEditAlbumAction}
                        text={_('create_album')}
                        isActive={isCreateButtonActive}
                        color={isCreateButtonActive ? colors.captureBlue : colors.captureGrey400}

                    />
                </TextButtonWrapper>
                ),
            ];
        }

    }
    public componentWillUnmount() {
        if (this.doCancelOnUnmount) {
            this.props.cancelEditAlbum();
        }
        this.doCancelOnUnmount = true;
    }

    public render() {
        const {pendingAlbum} = this.props;
        if (pendingAlbum === undefined) {return <LoadingPage />; }

        return (
            <Wrapper id="edit-page-wrapper">
                <TopNavPlaceholder/>
                <ContentWrapper width={this.props.styling.width}>
                    <ContentHeader>
                        <InputWrapper isMobile={this.props.isMobile}>
                            <EditableInput
                                value={this.state.title}
                                iconSize={24}
                                iconColor={'#000'}
                                placeholder={_('add_album_title')}
                                onChange={this.handleEditTitleChange}
                                onBlur={this.handleEditTitleBlur}
                                maxLength={255}
                            />
                        </InputWrapper>
                    </ContentHeader>
                    <EditAlbumFileList
                        isMobile={this.props.isMobile}
                        onClickAddMore={this.addPhotos}
                        onRemoveFile={this.props.doRemoveFile}
                        pendingAlbum={pendingAlbum}
                        styling={this.props.styling}
                    />
                </ContentWrapper>
                {this.props.isMobile && <UploadStatusPlacementMinified />}
                <TwoAreasTopNavBar
                    left={this.getNavElementsLeft()}
                    right={this.getNavElementsRight()}
                    isMobile={this.props.isMobile}
                />
            </Wrapper>
        );
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    pendingAlbum: getPendingAlbum(state, ownProps.albumID),
    isMobile: isMobileMode(state),
    uploaderDone: isUploaderDone(state),
    styling: getAlbumGridStyle(state),
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    cancelEditAlbum: async () => {
        await deleteAlbum(dispatch, ownProps.albumID);
        dispatch(TimelineFileSelectingCanceled());
        dispatch(NavigateByReplacingCurrent(Pages.AfterAbortedAlbumEdit(ownProps.goToAfter)));
    },
    addPhotos: () => dispatch(NavigateTo(Pages.AddMorePhotosToAlbum(ownProps.albumID))),
    doSetAlbumTitle: (newTitle: string) => setAlbumTitle(dispatch, ownProps.albumID, newTitle),
    confirmEditAlbum: (newTitle: string) => {
        const albumID = ownProps.albumID;
        setAlbumTitle(dispatch, albumID, newTitle)
            .then(() => dispatch(JobWasConfirmed(albumID)))
            .then(() => dispatch(NavigateByReplacingCurrent(Pages.Album(albumID))));
    },
    doRemoveFile: (fileID: FileID) => deleteFile(dispatch, ownProps.albumID, fileID),
    displaySharePrompt: () => dispatch(ShowShareAlbumPrompt(ownProps.albumID)),
});

const TheEditAlbumPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditAlbumPageComponent);

type RouterPageProps = {
    params: {albumIDB64: JobID, goToAfter: string},
};

export const EditAlbumPage: React.SFC<RouterPageProps> = ({params}) => (
    <TheEditAlbumPage albumID={b64ToUuid(params.albumIDB64)} goToAfter={params.goToAfter} />
);
