/**
 * This file contains constants used throughout the app, and also a set of semi-constants that is based on the provisioning of the app.
 * As the exact same bundle are to be deployed in any environment and expected to work accordingly,
 * the provisioning is done runtime, based on where (domain) the application is deployed, in this file.
 */
import {localStorageGet, sessionStorageGet} from '../products/common/utilities/webStorage';

// Must do typeof-guarding because of test-runner not injecting webpack-variables.
export const isAlbumReceiver = typeof __ARTIFACT__ === 'string' && __ARTIFACT__ === 'albumReceiver';
export const isCaptureTV = typeof __ARTIFACT__ === 'string' && __ARTIFACT__ === 'captureTV';

export const DEVICE_ID = isAlbumReceiver ? 'fae886eb-0be9-471a-a174-6ea95a002f87' : 'fae886eb-0be9-471a-a174-6ea95a002f86';
export const DEVICE_NAME = (isAlbumReceiver ? 'AlbumReceiver' : 'CaptureWeb2');

export type LanguageDefinition = {key: string, name: string, shortname: string, crowdinKey: string, connectKey: string};
export const appLanguages: LanguageDefinition[] = [
    {key: 'nb_NO', name: 'Norsk',      shortname: 'NOR', crowdinKey: 'nb',    connectKey: 'no'},
    {key: 'sr_RS', name: 'Srpski',     shortname: 'SRB', crowdinKey: 'sr-CS', connectKey: 'sr-Latn-RS'},
    {key: 'en_US', name: 'English',    shortname: 'ENG', crowdinKey: 'en',    connectKey: 'en'},
    {key: 'sr_ME', name: 'Crnogorski', shortname: 'MNE', crowdinKey: 'me',    connectKey: 'sr-Latn-ME'},
    {key: 'sv_SE', name: 'Svenska',    shortname: 'SWE', crowdinKey: 'sv-SE', connectKey: 'sv'},
    {key: 'bg_BG', name: 'Български',  shortname: 'BGR', crowdinKey: 'bg',    connectKey: 'bg'},
];

// When deployed on .no-domains, the product is "Min Sky", not "Capture"
export const isMinSky = (typeof(location) === 'object' && /\.no$/.test(location.hostname));

// When deployed on a *.univex.no-domain, a *.cptr.no-domain, or running in dev-mode, we are in testing environment
const isTestEnv = (typeof(location) === 'object' && (/(\.univex\.no)$/.test(location.hostname) || location.hostname === 'redux-test' || __DEV__));

// When deployed on a staging.*-domain, we are in staging environment
const isStagingEnv = (typeof(location) === 'object' && location.hostname.indexOf('staging.') !== -1);

const isLocalhost = (typeof(location) === 'object' && location.hostname === 'localhost');

// Tool-function to provide environment-specific values
export function envSpecificValue<T>(testValue: T, stagingValue: T, productionValue: T): T {
    return isTestEnv ? testValue : isStagingEnv ? stagingValue : productionValue;
}

export const REDUX_HOST = envSpecificValue(
    isLocalhost ? 'localhost:9000' : 'redux.webdeployment.univex.no',
    'web-staging.min-sky.no',
    'web.min-sky.no',
);

// TODO: Insert correct Staging and Production-IDs
export const GOOGLE_ANALYTICS_ID__ALBUM_RECEIVER = envSpecificValue('UA-96656924-3', 'UA-96656924-2', 'UA-96656924-1');
export const GOOGLE_ANALYTICS_ID__FULL_WEB = envSpecificValue('UA-111309075-3', 'UA-111309075-2', 'UA-111309075-1');

export const GOOGLE_ANALYTICS_ID__ALL_PROJECTS = envSpecificValue(undefined, 'UA-88943738-1', 'UA-88943738-1'); // Don't do mirroring when developing and/or testing

// Try to get some language-setting from browser - and make sure it is one of the languages we know
const getValidatedBrowserLocale = () => {
    // Type-mock window.navigator to allow compiling with fallback-parameters for legacy-browsers and IE
    type Nav = Partial<NavigatorLanguage> & {userLanguage?: string};
    const n: Nav = typeof window !== 'undefined' && window.navigator ? window.navigator : {};
    const lang = n.languages ? n.languages[0] : (n.language || n.userLanguage);
    if (lang) {
        // The browser returns the user-language in different formats (may be 'nb', 'nb-no', 'nb_NO').
        // Therefore: Find match by comparing first two letters
        const matches = appLanguages.filter((l) => l.key.substring(0, 2) === lang.substring(0, 2));
        if (matches.length > 0) {
            return matches[0].key;
        }
    }
};

const defaultLocale = isMinSky ? 'nb_NO' : 'en_US';

export const setCurrentLocale = (langCode: string) => {
    document.cookie = 'lang=' + langCode + ';';
    window.location.reload();
};

export const getCurrentLocale = (): string => {
    // To be consistent with the way web-legacy stores and detects language, we are using a cookie-parameter "lang" to store the user language.
    // If the key is not present or is invalid, we fall back to the default language

    const langFromCookie: string|undefined = (/\blang=(\w+)\b/.exec(typeof(document) === 'object' ? document.cookie : '') || [])[1];
    if (langFromCookie && appLanguages.filter((l) => l.key === langFromCookie).length === 1) {
        return langFromCookie;
    }
    return getValidatedBrowserLocale() || defaultLocale;
};

export const getCurrentLangDefinition = (): LanguageDefinition => {
    const currentKey = getCurrentLocale();
    return appLanguages.filter((def) => def.key === currentKey)[0];
};

// The LOGIN_HOST is the start-point for all interaction with the app. From here the user logs in and from here the other hosts to be used by the API is provided
export const LOGIN_HOST = sessionStorageGet('capture_login_host') || (isTestEnv ? 'login-client.univex.no' : 'login.cptr.no');

export const PRODUCT_NAME = isMinSky ? 'Min sky' : 'Capture';

export const HOME_SITE_ADDRESS = isMinSky ? 'https://min-sky.no' : 'https://capture-app.com';
export const LOGGED_IN_SITE_ADDRESS = isMinSky ? 'https://web.min-sky.no' : 'https://web.capture-app.com';
export const SUPPORT_SITE_ADDRESS = isMinSky ? 'https://support.min-sky.no' : 'https://support.capture-app.com';
export const LEGACY_LOOGGED_IN_SITE_DOMAIN = envSpecificValue(
    isLocalhost ? 'localhost:9010' : isMinSky ? 'se-staging.min-sky.no' : 'see-staging.capture-app.com',
    isMinSky ? 'se-staging.min-sky.no' : 'see-staging.capture-app.com',
    isMinSky ? 'se.min-sky.no' : 'see.capture-app.com',
);

export const APP_STORE_LINK_ANDROID = 'https://play.google.com/store/apps/details?id=com.comoyo.capture';
export const APP_STORE_LINK_IOS = `https://itunes.apple.com/no/app/min-sky/id633980795?l=${getCurrentLocale()}&mt=8`;

export const BUY_MORE_STORAGE_LINK = `https://store.telenordigital.com/capture/products/?locale=${getCurrentLocale()}`;

export const stripeMode = sessionStorageGet('stripe_mode') || (isTestEnv ? 'test' : 'production');
export const STRIPE_API_TOKEN = stripeMode === 'test' ? 'pk_test_gh4Dv8dlZjJ2b5ONdOagN3jd' : 'pk_live_k4mPM31bcoAL1f5AtCCx9lav';

export const TERMS_OF_SERVICE_LINK =
    getCurrentLocale() === 'nb_NO' ? 'https://capture-app.com/ToS/capturetos-no.html' : 'https://capture-app.com/ToS/capturetos-en.html';
export const CUSTOMER_SERVICE_LINK = 'https://support.capture-app.com/hc/';
export const TELENOR_MOBILE_PLANS_LINK = 'https://www.telenor.no/privat/mobil/mobilabonnement/';
export const TELENOR_PRIVACY_SETTINGS_LINK = 'https://privacy.telenordigital.com/';

// Capture Facebook application
const isCaptuRe = (typeof location !== 'undefined' && /captu\.re$/.test(location.hostname));

export const FB_APP_ID: string = envSpecificValue(
    isLocalhost ? '635273616622100' : '653505944848708',
    isMinSky ? '805890336247076' : isCaptuRe ? '139280753359806' : '618375584978570',
    isMinSky ? '663125737190204' : isCaptuRe ? '139280753359806' : '277817832367682',
);

export const isFeatureEnabled = (key: string) => {
    const storedFlag = localStorageGet('_featureFlag__' + key);
    if (typeof storedFlag === 'string') {
        return storedFlag === '1';
    }
    return __DEV__;
};

export const FIREBASE_SENDER_ID: string = '427438894429';
export const FIREBASE_VAPID: string =
    'BPivdCbvdMd6U9rBHNnjBl6ffJ8Ka2HfNERcv1qgtt_0Ri1wKujCvV1XRtQfzTBZUm9Np51CQC3luUSaMLBuSvw';
