import {createActionCreator, createVoidActionCreator} from '../common/actions';
import {UserActionInitiated} from '../userActions/actions';
import {UserActionTypes} from '../userActions/reducer';

export const StartFetchingAccountInfo = createVoidActionCreator('START_FETCHING_ACCOUNT_INFO');

interface UserInformation {
    files_disabled: boolean;
    kissmetrics_id: string;
    logged_in_as: string;
    max_space: number;
    name: string;
    used_space: number;
    username: string;
    uuid: UserID;
    auth_token: string;
    isBeingShutDown?: boolean;
}

export const FetchedAccountInfo = createActionCreator<UserInformation>('FETCHED_ACCOUNT_INFO');

export const UnableToFetchAccountInfo = createVoidActionCreator('UNABLE_TO_FETCH_ACCOUNT_INFO');

export type DeviceInfo = {
    id: string,
    name: string,
    expires: number,
};
export const FetchingConnectedDevicesStarted = createVoidActionCreator('FETCHING_CONNECTED_DEVICES_STARTED');
export const ConnectedDevicesWasFetched = createActionCreator<{currentDeviceID: string, devices: DeviceInfo[]}>('CONNECTED_DEVICES_WAS_FETCHED');
export const FetchingConnectedDevicesFailed = createVoidActionCreator('FETCHING_CONNECTED_DEVICES_FAILED');

export const DeleteConnectedDeviceStarted = createActionCreator<{deviceID: string}>('DELETE_CONNECTED_DEVICE_STARTED');
export const ConnectedDeviceWasDeleted = createActionCreator<{deviceID: string}>('CONNECTED_DEVICE_WAS_DELETED');
export const DeleteConnectedDeviceFailed = createActionCreator<{deviceID: string}>('DELETE_CONNECTED_DEVICE_FAILED');

export const FetchedLastWebVersionSeen = createActionCreator<number>('FETCHED_LAST_WEB_VERSION_SEEN');
export const CurrentWebVersionWasSeen = createActionCreator<number>('CURRENT_WEB_VERSION_WAS_SEEN');

export const UserLoggedOut = createVoidActionCreator('USER_LOGGED_OUT');

// Dispatched when some action requires an authToken (such as commenting being blocked until an anonymous user is created)
export const ValidUserInfoRequired = createActionCreator<string|undefined>('VALID_USER_INFO_REQUIRED');
export const ValidUserInfoRequestDeniedByUser = createVoidActionCreator('VALID_USER_INFO_REQUEST_DENIED_BY_USER');

/** InvalidLoginStateDetected occurs when some calls to the server that assumes the user is logged in fails to authenticate */
export const InvalidLoginStateDetected = createVoidActionCreator('INVALID_LOGIN_STATE_DETECTED');

export const AnonymousUserNameProvided = createActionCreator<{name: string}>('ANONYMOUS_USER_NAME_PROVIDED');

type AnonymousUserInfo = {
    name: string
    uuid: UserID
    auth_token: string,
};
export const FetchedAnonymousUserInfo = createActionCreator<AnonymousUserInfo>('FETCHED_ANONYMOUS_USER_INFO');

export type TempPrintUserInfo = {
    auth_token: string,
    uuid: UserID,
    targetJob: JobID,
};
export const FetchedTempPrintUserInfo = createActionCreator<TempPrintUserInfo>('FETCHED_TEMP_PRINT_USER_INFO');

export const JobSubscriptionsDetected = createActionCreator<JobID[]>('JOB_SUBSCRIPTIONS_DETECTED');
export const UserSubscribedToAlbum = createActionCreator<JobID>('USER_SUBSCRIBED_TO_ALBUM');
export const UserUnsubscribedFromAlbum = createActionCreator<JobID>('USER_UNSUBSCRIBED_FROM_ALBUM');
export const AlbumUnsubscriptionConfirmed = createActionCreator<JobID>('ALBUM_UNSUBSCRIPTION_CONFIRMED');
export const AlbumUnsubscriptionFailed = createActionCreator<JobID>('ALBUM_UNSUBSCRIPTION_FAILED');

export const DeleteConnectedDeviceInitiated = (target: string) => UserActionInitiated({target, type: UserActionTypes.DELETE_DEVICE});
