import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {SideModuleContentType} from '../../state/carouselViewer/actions';
import {CommentsComponent} from '../Album/CommentsComponent';
import {CloseIcon} from '../Icons/CloseIcon';
import {FileInfoComponent} from './FileInfoComponent';
import {CarouselViewerNode} from './index';

type Props = {
    currentSideModuleContent: SideModuleContentType | undefined,
    viewerNode: CarouselViewerNode,
    onCollapse: () => any,
    mobileMode: boolean,
};

const Wrapper = styled.div`
    width: ${(props: {isExpanded: boolean, width: string}) => props.width};
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;

    position: fixed;
    right: 0;
    top: 0;

    transform: translate3d(${(props) => props.isExpanded ? 0 : props.width}, 0, 0);
    transition: transform 0.3s;
    transition-timing-function: ease-out;

    & > div:last-child {
        flex: 1;
    }
`;

const TopElement = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid ${colors.captureGrey300};
`;
const Header = styled.div`
    font-size: ${fontSize.medium_18};
    text-transform: capitalize;
    margin-left: 8px;
`;

const ButtonWrapper = styled.div`
    cursor: pointer;
`;
const headers: Record<SideModuleContentType, string> = {
    comments: _('comments'),
    info: _('info'),
};

export class SideModule extends React.Component<Props, {}> {
    private getHeaderText = () => {
        if (this.props.currentSideModuleContent) {
            return headers[this.props.currentSideModuleContent];
        }
    }
    private getContent = () => {
        const {viewerNode} = this.props;
        if (this.props.currentSideModuleContent === 'comments' && viewerNode.type === 'album') {
            return (
                <CommentsComponent
                    albumID={viewerNode.file.jobID}
                    fileID={viewerNode.file.fileID}
                    comments={viewerNode.file.comments}
                    commentsCanBeAdded={true}
                    isPendingNewComment={viewerNode.file.isPendingNewComment}
                />
            );
        } else if (this.props.currentSideModuleContent === 'info') {
            return (
                <FileInfoComponent
                    file={viewerNode.file}
                    metadata={viewerNode.file.metadata}
                />
            );
        }
        return null;
    }

    public render() {
        const elementWidth = this.props.mobileMode ? '100%' : '360px';

        return (
            <Wrapper isExpanded={this.props.currentSideModuleContent !== undefined} width={elementWidth}>
                <TopElement>
                    <Header>{this.getHeaderText()}</Header>
                    <ButtonWrapper onClick={this.props.onCollapse}>
                        <CloseIcon size={24}/>
                    </ButtonWrapper>
                </TopElement>
                {this.getContent()}
            </Wrapper>
        );
    }
}
