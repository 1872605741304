import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {fetchListOfJobs} from '../../API/job';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {Pages} from '../../routing';
import {State} from '../../state';
import {AlbumListFilterInitiated, CreateAlbumInitiated} from '../../state/album/actions';
import {AlbumListFilter} from '../../state/albumList/reducer';
import {
    AlbumJobInfo, getAlbumListFilter, getAlbumListStyle, getPrivacyModeFilteredAlbumList,
} from '../../state/albumList/selectors';
import {getCurrentUserUUID, isAlbumListReady} from '../../state/currentUser/selectors';
import {NavigateTo} from '../../state/routing/actions';
import {getViewportWidth, isMobileMode} from '../../state/viewMode/selectors';
import {AlbumOptionsPlacement} from '../AlbumOptions/AlbumOptionsPlacement';
import {IconButton} from '../Common/IconTextButton';
import {PageWrapper} from '../Common/PageWrapper';
import {RippleLoaderPage} from '../Common/RippleLoader';
import {CreateAlbumIconButton, CreateAlbumTextButton} from '../EditAlbum/CreateAlbumButton';
import {FilterIcon} from '../Icons/FilterIcon';
import {PlusIcon} from '../Icons/PlusIcon';
import {MainNavBarDesktop} from '../Navigation/MainNavBarDesktop';
import {TopNavBarMobile} from '../Navigation/TopNavBarMobile';
import {UploadOverlayAlbumListPlacement} from '../Uploader/UploadOverlayAlbumListPlacement';
import {UploadStatusPlacementMinified} from '../Uploader/UploadStatusPlacementMinified';
import {AlbumList} from './AlbumList';
import {AlbumListEmptyState} from './AlbumListEmptyState';
import {AlbumListFilterIconButton, AlbumListFilterTextButton} from './AlbumListFilterButton';
import {GridStyle} from '../../utilities/gridElementSizeCalculator';

type StateProps = {
    albums: AlbumJobInfo[],
    albumsLoaded: boolean,
    currentUser?: UserID,
    viewportWidth: number,
    isMobileMode: boolean,
    currentFilter: AlbumListFilter,
    albumListStyle: GridStyle,
};
type DispatchProps = {
    fetchAlbums: (currentUser: UserID) => any,
    initiateAlbumFiltering: () => any,
    initiateCreateAlbum: () => any,
    doGoToAlbum: (albumID: JobID) => any,
};
type Props = StateProps & DispatchProps;

export const ContentWrapper = styled.div`
    width: ${(props: {width: number, marginTop: number}) => props.width}px;
    margin: 0 auto;
    margin-top:${(props) => props.marginTop}px;
`;

class _AlbumListPage extends React.PureComponent<Props> {

    public componentDidMount() {
        if (this.props.currentUser) {
            this.props.fetchAlbums(this.props.currentUser);
        }
    }

    private goToAlbum = (albumID: JobID) => {
        this.props.doGoToAlbum(albumID);
    }

    private getMenuItems = (showButtonText?: boolean) => {
        if (this.props.isMobileMode) {
            return [
                (
                <IconButton
                    icon={FilterIcon}
                    key={'filter'}
                    onClick={this.props.initiateAlbumFiltering}
                    color={this.props.currentFilter !== 'all' ? (colors.captureBlue) : undefined}
                />
            ),
                <IconButton key={'create'} onClick={this.props.initiateCreateAlbum} icon={PlusIcon}/>,
            ];
        }
        else {
            const CreateAlbumBtn = showButtonText ? CreateAlbumTextButton : CreateAlbumIconButton;
            const AlbumListFilterBtn = showButtonText ? AlbumListFilterTextButton : AlbumListFilterIconButton;

            return [
                <AlbumListFilterBtn key={'albumFilter'} />,
                <CreateAlbumBtn key={'createAlbum'} buttonText={_('new_album')} icon={PlusIcon}/>,
            ];
        }
    }

    public render() {

        let content = null;
        if (!this.props.albumsLoaded) {
            content = <RippleLoaderPage />;
        } else if (this.props.albums.length === 0) {
            content = (
                <AlbumListEmptyState
                    currentFilter={this.props.currentFilter}
                    initiateAlbumCreation={this.props.initiateCreateAlbum}
                    isMobileMode={this.props.isMobileMode}
                />
            );
        } else {
            content = (
                <ContentWrapper
                    marginTop={this.props.isMobileMode ? 0 : 40}
                    width={this.props.albumListStyle.width}
                >
                    <AlbumList
                        albums={this.props.albums}
                        onAlbumClick={this.goToAlbum}
                        style={this.props.albumListStyle}
                    />
                </ContentWrapper>
            );
        }

        const navBar = this.props.isMobileMode
            ? <TopNavBarMobile currentPage={Pages.Albums} rightElements={this.getMenuItems}/>
            : <MainNavBarDesktop currentPage={Pages.Albums} mainActionElements={this.getMenuItems}/>;

        return (
            <PageWrapper navBar={navBar} isContentReady={this.props.albumsLoaded}>
                {content}
                {this.props.isMobileMode && <UploadStatusPlacementMinified />}
                <AlbumOptionsPlacement />
                <UploadOverlayAlbumListPlacement />
            </PageWrapper>
        );
    }
}

const mapStateToProps = (state: State): StateProps => ({
    albums: getPrivacyModeFilteredAlbumList(state),
    albumsLoaded: isAlbumListReady(state),
    currentUser: getCurrentUserUUID(state),
    viewportWidth: getViewportWidth(state),
    isMobileMode: isMobileMode(state),
    currentFilter: getAlbumListFilter(state),
    albumListStyle: getAlbumListStyle(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    fetchAlbums: (currentUser: UserID) => fetchListOfJobs(dispatch, currentUser),
    initiateAlbumFiltering: () => dispatch(AlbumListFilterInitiated()),
    initiateCreateAlbum: () => dispatch(CreateAlbumInitiated()),
    doGoToAlbum: (albumID: JobID) => dispatch(NavigateTo(Pages.Album(albumID))),
});

export const AlbumListPage = connect(mapStateToProps, mapDispatchToProps)(_AlbumListPage);
