import {Reaction} from '../../API/services/AppService';
import {Action, isType} from '../common/actions';
import {ReactionAdded, ReactionDeleted, Reactions} from './actions';

export type FileReaction = DictionaryOf<Reaction>;

const initialFileReaction = {};

export type ReactionState = DictionaryOf<FileReaction>;

const initialState: ReactionState = {};

const updateStateWithChanges = (state: ReactionState, fileID: FileID, changes: DictionaryOf<Reaction>): ReactionState => {
    return {
        ...state,
        [fileID]: {
            ...(state[fileID] || initialFileReaction),
            ...changes,
        },
    };
};

export const reactionReducer = (state: ReactionState = initialState, action: Action<any>): ReactionState => {
    if (isType(action, ReactionAdded)) {
        const {fileID, userUUID, reaction} = action.payload;
        return updateStateWithChanges(state, fileID, {
            [userUUID]: reaction,
        });
    }

    if (isType(action, ReactionDeleted)) {
        const {fileID, userUUID} = action.payload;
        return updateStateWithChanges(state, fileID, {
            [userUUID]: Reactions.None,
        });
    }

    return state;
};
