import {
    AutoGeneratedAlbumCreated,
    DownloadAlbumClicked, DownloadAlbumFailed, DownloadAlbumSuccess,
} from '../state/album/actions';
import {Dispatch} from '../state/common/actions';
import {guaranteeAUser} from '../state/currentUser';
import {
    AlbumUnsubscriptionConfirmed,
    AlbumUnsubscriptionFailed,
    UserSubscribedToAlbum,
    UserUnsubscribedFromAlbum,
} from '../state/currentUser/actions';
import * as FilesActions from '../state/files/actions';
import {JobFile} from '../state/files/reducer';
import {FilesCopiedToAlbumFailed, FileWasSetAsCoverPhoto, SetFileAsCoverPhotoFailed} from '../state/job/actions';
import * as JobInfoActions from '../state/jobInfo/actions';
import {ReactionAdded, ReactionDeleted, Reactions} from '../state/reaction/actions';
import {LongRunningTaskFinished, LongRunningTaskStarted} from '../state/statusNotifications/actions';
import {localStorageGet, localStorageRemove, localStorageSet} from '../utilities/webStorage';
import {getCommentsService, getReactionService} from './capabilities/index';
import {getServiceProvider} from './HostProvider';
import {copyMultipleFilesToJob} from './job'

const maybeResumeCommenting = async (dispatch: Dispatch) => {
    const stored = localStorageGet('pendingComment');
    localStorageRemove('pendingComment');
    if (stored) {
        const c = JSON.parse(stored);
        return postComment(dispatch, c.albumID, c.fileID, c.comment);
    }
};

const maybeResumeLove = async (dispatch: Dispatch) => {
    const stored = localStorageGet('pendingLove');
    localStorageRemove('pendingLove');
    if (stored) {
        const r = JSON.parse(stored);
        return loveAlbumFile(dispatch, r.albumID, r.fileID);
    }
};

export const maybeResumeAction = (dispatch: Dispatch) => {
    maybeResumeCommenting(dispatch);
    maybeResumeLove(dispatch);
};

export async function postComment(dispatch: Dispatch, albumID: JobID, fileID: FileID, comment: string) {
    dispatch(FilesActions.FileCommentSubmitted({fileID, comment}));

    // Store the pending comment in localStorage while fetching user (to allow re-inserting the text after redirects)
    localStorageSet('pendingComment', JSON.stringify({albumID, fileID, comment}));
    try {
        const userID = await guaranteeAUser(); // Will redirect to login if needed
        localStorageRemove('pendingComment'); // Clear as we managed to get a user.

        const service = await getCommentsService(albumID);
        try {
            const response = await service.addComment(albumID, fileID, comment);
            const payload = {
                fileID,
                comment,
                commentUUID: response.comment_uuid,
                timestamp: Math.floor(Date.now() / 1000),
                userUUID: userID,
            };
            dispatch(FilesActions.FileCommentSubmitSuccessful(fileID));
            dispatch(FilesActions.FileWasCommented(payload));
        } catch (e) {
            dispatch(FilesActions.FileCommentError(fileID));
        }
    } catch (e) {
        dispatch(FilesActions.FileCommentAborted(fileID));
        localStorageRemove('pendingComment'); // Clear as we were not redirected.
        return;
    }
}

export async function deleteComment(dispatch: Dispatch, albumID: JobID, fileID: FileID, commentID: CommentID) {
    dispatch(FilesActions.CommentDeletionStarted({commentID}));
    try {
        const service = await getCommentsService(albumID);
        await service.deleteComment(albumID, fileID, commentID);
        dispatch(FilesActions.CommentWasDeleted(commentID));
    } catch (e) {
        dispatch(FilesActions.DeleteCommentFailed({commentID}));
    }
}

export async function editComment(
    dispatch: Dispatch,
    albumID: JobID,
    fileID: FileID,
    commentID: CommentID,
    commentText: string,
) {
    dispatch(FilesActions.EditedCommentSubmitted({commentID, text: commentText}));
    try {
        const service = await getCommentsService(albumID);
        await service.editComment(albumID, fileID, commentID, commentText);
        dispatch(FilesActions.EditCommentSuccessful({
            commentID,
            timestamp: Math.floor((new Date()).getTime() / 1000),
        }));
    } catch (e) {
        dispatch(FilesActions.EditCommentFailed({commentID}));
    }
}

export const downloadAlbum = async (dispatch: Dispatch, albumID: JobID) => {
    dispatch(DownloadAlbumClicked(albumID));
    try {
        const service = await getServiceProvider().getAppServiceForJob(albumID);
        await service.downloadAllFilesInJob(albumID);
        dispatch(DownloadAlbumSuccess(albumID));
    } catch (e) {
        dispatch(DownloadAlbumFailed(albumID));
    }
};

export const checkVideoTranscode = async (dispatch: Dispatch, albumID: JobID, fileID: FileID): Promise<void> => {
    dispatch(FilesActions.VideoTranscodeStarted(fileID));
    const delay = new Promise<void>((resolve) => {window.setTimeout(resolve, 1000); });
    try {
        const service = await getServiceProvider().getVideoServiceForJob(albumID);
        const response = await service.checkVideo(albumID, fileID);

        // Still queued, refetch status recursively after waiting
        if (response.result === 'queued') {
            await delay;
            return checkVideoTranscode(dispatch, albumID, fileID);
        }

        if (response.result === 'ready') {
            dispatch(FilesActions.VideoTranscodeReady(fileID));
        } else {
            dispatch(FilesActions.VideoTranscodeError(fileID));
        }
    } catch (e) {
        dispatch(FilesActions.VideoTranscodeError(fileID));
    }
};

export const createAlbum = async (dispatch: Dispatch, name: string, allowSharing = false): Promise<JobID> => {
    const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
    const albumInfo = await service.createJob({type: 'story', allow_sharing: allowSharing ? 1 : 0, name});
    dispatch(JobInfoActions.JobWasCreated(albumInfo.id));
    return albumInfo.id;
};

export const createAlbumWithFiles = async (
    dispatch: Dispatch, name: string, files: JobFile[], allowSharing = false
): Promise<JobID> => {
    const albumID = await createAlbum(dispatch, name, allowSharing);
    dispatch(LongRunningTaskStarted('filesAreBeingCopied'));
    copyMultipleFilesToJob(dispatch, albumID, files).then((value) => {
        dispatch(LongRunningTaskFinished('filesAreBeingCopied'));
        if (value.failed.length > 0) {
            dispatch(FilesCopiedToAlbumFailed({jobID: albumID, files: value.failed}));
        }
    });
    return albumID;
};

export const createAutoGeneratedAlbum = async (dispatch: Dispatch, name: string, tempID: string): Promise<JobID> => {
    const jobID = await createAlbum(dispatch, name);
    dispatch(AutoGeneratedAlbumCreated({jobID, tempID, name}));
    return jobID;
};

export const deleteAlbum = async (dispatch: Dispatch, albumID: JobID): Promise<any> => {
    dispatch(JobInfoActions.JobDeletionStarted(albumID));
    const service = await getServiceProvider().getAppServiceForJob(albumID);
    try {
        await service.deleteJob(albumID);
        dispatch(JobInfoActions.JobWasDeleted(albumID));
    } catch (e) {
        dispatch(JobInfoActions.JobDeletionFailed(albumID));
    }
};

export const subscribeToAlbum = async (dispatch: Dispatch, albumID: JobID): Promise<any> => {
    const service = await getServiceProvider().getAppServiceForJob(albumID);
    await service.subscribeToJob(albumID);
    dispatch(UserSubscribedToAlbum(albumID));
};

export const unsubscribeFromAlbum = async (dispatch: Dispatch, albumID: JobID): Promise<any> => {
    dispatch(AlbumUnsubscriptionConfirmed(albumID));
    const service = await getServiceProvider().getAppServiceForJob(albumID);
    try {
        await service.unsubscribeFromJob(albumID);
        dispatch(UserUnsubscribedFromAlbum(albumID));
    } catch (e) {
        dispatch(AlbumUnsubscriptionFailed(albumID));
    }
};

export const setAlbumCoverPhoto = async (dispatch: Dispatch, albumID: JobID, fileID: FileID) => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(albumID);
        await service.setCoverPhoto(albumID, fileID);
        dispatch(FileWasSetAsCoverPhoto({jobID: albumID, fileID}));
    }
    catch (e) {
        dispatch(SetFileAsCoverPhotoFailed(albumID));
    }
};

export const setAlbumTitle = async (dispatch: Dispatch, albumID: JobID, title: string) => {
    dispatch(JobInfoActions.ChangeJobPropertySubmitted({job: albumID, property: 'title', value: title}));

    try {
        const service = await getServiceProvider().getAppServiceForJob(albumID);
        await service.setJobName(albumID, title);

        dispatch(JobInfoActions.ChangeJobPropertySucceeded({job: albumID, property: 'title', value: title}));
    } catch (e) {
        dispatch(JobInfoActions.ChangeJobPropertyFailed({job: albumID, property: 'title'}));
    }
};

export const setAlbumUploadsPermission = async (dispatch: Dispatch, albumID: JobID, enable: boolean) => {
    dispatch(JobInfoActions.ChangeJobPropertySubmitted({job: albumID, property: 'allow_uploads', value: enable}));

    try {
        const service = await getServiceProvider().getAppServiceForJob(albumID);
        await service.setPermissionsforJob(albumID, { allow_uploads: (enable ? 1 : 0) });

        dispatch(JobInfoActions.ChangeJobPropertySucceeded({job: albumID, property: 'allow_uploads', value: enable}));
    } catch (e) {
        dispatch(JobInfoActions.ChangeJobPropertyFailed({job: albumID, property: 'allow_uploads'}));
    }
};

export const setAlbumCommentsPermission = async (dispatch: Dispatch, albumID: JobID, enable: boolean) => {
    dispatch(JobInfoActions.ChangeJobPropertySubmitted({job: albumID, property: 'allow_comments', value: enable}));

    try {
        const service = await getServiceProvider().getAppServiceForJob(albumID);
        await service.setPermissionsforJob(albumID, { allow_comments: (enable ? 1 : 0) });

        dispatch(JobInfoActions.ChangeJobPropertySucceeded({job: albumID, property: 'allow_comments', value: enable}));
    } catch (e) {
        dispatch(JobInfoActions.ChangeJobPropertyFailed({job: albumID, property: 'allow_comments'}));
    }
};

export const setAlbumSharingProperty = async (dispatch: Dispatch, albumID: JobID, enableSharing: boolean) => {
    dispatch(JobInfoActions.ChangeJobPropertySubmitted({job: albumID, property: 'isShared', value: enableSharing}));

    try {
        const service = await getServiceProvider().getAppServiceForJob(albumID);
        await service.setPrivacyModeForJob(albumID, enableSharing ? 'shared' : 'private');

        dispatch(JobInfoActions.ChangeJobPropertySucceeded({job: albumID, property: 'isShared', value: enableSharing}));
    } catch (e) {
        dispatch(JobInfoActions.ChangeJobPropertyFailed({job: albumID, property: 'isShared'}));
    }
};

export const loveAlbumFile = async (dispatch: Dispatch, albumID: JobID, fileID: FileID): Promise<void> => {
    dispatch(FilesActions.ReactionChangesSubmitted({
        fileID,
        reaction: Reactions.Love,
    }));

    try {
        localStorageSet('pendingLove', JSON.stringify({albumID, fileID}));
        const userID = await guaranteeAUser();
        localStorageRemove('pendingLove');

        const service = await getReactionService(albumID);
        await service.loveFile(albumID, fileID);

        dispatch(ReactionAdded({
            fileID,
            userUUID: userID,
            reaction: Reactions.Love,
        }));
        dispatch(FilesActions.ReactionChangesSubmitSuccessfull(fileID));
    } catch (e) {
        dispatch(FilesActions.ReactionChangesSubmitError(fileID));
        localStorageRemove('pendingLove');
    }

};

export const unloveAlbumFile = async (dispatch: Dispatch, albumID: JobID, fileID: FileID): Promise<void> => {
    dispatch(FilesActions.ReactionChangesSubmitted({
        fileID,
        reaction: Reactions.None,
    }));

    try {
        const userID = await guaranteeAUser();

        const service = await getReactionService(albumID);
        await service.unLoveFile(albumID, fileID);

        dispatch(ReactionDeleted({
            fileID,
            userUUID: userID,
        }));
        dispatch(FilesActions.ReactionChangesSubmitSuccessfull(fileID));
    } catch (e) {
        dispatch(FilesActions.ReactionChangesSubmitError(fileID));
    }
};
