import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {fontSize} from '../../assets/styleConstants';
import {AlbumFile} from '../../state/album/selectors';
import {withoutTheBools} from '../../utilities/arrayUtils';
import {isMobileDevice} from '../../utilities/device';
import {FileTarget} from '../../utilities/fileTarget';
import {AvatarWithName} from '../Common/Avatar';
import {Button, ButtonProps} from '../Common/Button';
import {VideoIndicator} from '../Common/VideoIndicator';
import {AddIcon} from '../Icons/AddIcon';
import {DeleteIcon} from '../Icons/DeleteIcon';
import {DownloadIcon} from '../Icons/DownloadIcon';
import {ImageIcon} from '../Icons/ImageIcon';
import {AlbumImage} from '../Image/AlbumImage';
import {AlbumVideo, VideoPlayStatus} from '../Video/AlbumVideo';
import {albumFileDOMClass} from './AlbumReceiverPage';
import {CommentsSection} from './CommentsSection';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 14px;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
    background: #000000;
    filter: ${(props: {desaturate: boolean}) => props.desaturate ? 'saturate(0%)' : 'initial'};
    transition: filter 1s;

    &:last-child {
        margin-bottom: 8px;
    }
`;

const LargeScreenContainer = styled(Container)`
    min-height: 250px;
    height: ${(props: {height: number, desaturate: boolean}) => props.height}px;
`;

const MediaElementContainer = styled.div`
    width: ${(props: {mobileMode: boolean}) => props.mobileMode ? '100%' : '67%'};
    min-height: ${(props) => props.mobileMode ? '100px' : '250px'};
    height: 100%;
    overflow: hidden;
    position: relative;
`;

const FileElementWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
const AddedByWrapper = styled.div`
    width: 100%;
    height: 56px;
    position: absolute;
    ${(props: {isMobile: boolean}) => props.isMobile ? 'bottom: 0; display: flex; align-items: flex-end;' : 'top: 0;'}
    background: linear-gradient(${(props) => props.isMobile ? 'to top,' : ''} rgba(0,0,0,0.5), rgba(0,0,0,0));
    padding: 8px;
    box-sizing: border-box;
    color: white;
    font-size: ${fontSize.small_14};
`;

const CommentsWrapperMobile = styled.div`
    width: 100%;
    background: white;
`;
const CommentsWrapperDesktop = styled.div`
    width: 33%;
    height: 100%;
    overflow: hidden;
    flex: 1;
    background: white;
`;

type AlbumFileProps = {
    file: AlbumFile,
    mobileMode: boolean,
    onImageClick?: () => any,
    height: number,
    onImageDimensionsDetected?: (width: number, height: number) => any,
    userCanAddComment: boolean,
    userCanLove: boolean,
    onDeleteInitiate: () => any,
    doFileDownload: () => any,
    onLoveReactionChanged: (ifLove: boolean) => any,
    setAsCoverPhoto: () => any,
    currentCoverPhoto: FileID,
    doCopyToTimeline: () => any,
    isShared: boolean,
    doShowLoveList: () => any,
};
type LocalState = {isMouseOver: boolean, isInPlayMode: boolean};

export class AlbumFileComponent extends React.Component<AlbumFileProps, LocalState> {
    public state: LocalState = {isMouseOver: false, isInPlayMode: false};
    private maxImageHeight = 650;

    private getOverflowMenuItems = (): ButtonProps[] => {
        return withoutTheBools([
            (
            Button(_('download_file'), this.props.doFileDownload, { icon: DownloadIcon })
            ),
            Button(_('add_file_to_timeline'), this.props.doCopyToTimeline, { icon: AddIcon }),
            this.props.file.currentUserCan.setFileAsAlbumCoverPhoto && this.props.file.type === FileTarget.Pictures && (
                Button(
                    _('set_as_cover_photo'),
                    this.props.setAsCoverPhoto,
                    {icon: ImageIcon, isActive: this.props.file.fileID !== this.props.currentCoverPhoto},
                )
            ),
            this.props.file.currentUserCan.deleteFile && (
                Button(_('delete_file'), this.props.onDeleteInitiate, {icon: DeleteIcon})
            ),
        ]);
    }
    private makeAddedByElement = () => {
        if (this.props.isShared && (isMobileDevice.any() || this.state.isMouseOver) && !this.state.isInPlayMode) {
            return (
                <AddedByWrapper isMobile={this.props.mobileMode}>
                    <AvatarWithName
                        name={this.props.file.addedBy_obj.name}
                        img={this.props.file.addedBy_obj.profilePicture}
                    />
                </AddedByWrapper>
            );
        }
    }

    private handleVideoPlayStatusChanged = (newStatus: VideoPlayStatus) => {
        if (isMobileDevice.iOS()) {
            if (!this.state.isInPlayMode && newStatus === 'playing') {
                this.setState((prevState) => ({...prevState, isInPlayMode: true}));
            }
        }
        else {
            if (newStatus === 'playing' || newStatus === 'pause') {
                this.setState((prevState) => ({...prevState, isInPlayMode: true}));
            }
            else if (newStatus === 'end') {
                this.setState((prevState) => ({...prevState, isInPlayMode: false}));
            }
        }

    }

    private getMovieMetaDataView = () =>
        this.props.file.duration && <VideoIndicator duration={this.props.file.duration} />

    public makeMediaElement = (): React.ReactNode => {

        let mediaFile: React.ReactNode = (
            <FileElementWrapper onClick={this.props.onImageClick}>
                <AlbumImage
                    fileID={this.props.file.fileID}
                    thumbURL={this.props.file.thumbURLLarge}
                    onImageDimensionsDetected={this.props.onImageDimensionsDetected}
                    metaDataContent={this.props.file.type === FileTarget.Movies ? this.getMovieMetaDataView() : undefined}
                />
            </FileElementWrapper>
        );
        if (this.props.file.type === FileTarget.Movies && isMobileDevice.any()) {
            mediaFile = (
                <AlbumVideo
                    jobID={this.props.file.jobID}
                    fileID={this.props.file.fileID}
                    thumbURL={this.props.file.thumbURLLarge}
                    onPlayStatusChanged={this.handleVideoPlayStatusChanged}
                />
            );
        }

        return (
            <MediaElementContainer
                mobileMode={this.props.mobileMode}
            >
                {mediaFile}
                {this.makeAddedByElement()}
            </MediaElementContainer>
        );
    }
    private handleMouseEnter = () => {
        this.setState({isMouseOver: true});
    }

    private handleMouseLeave = () => {
        this.setState({isMouseOver: false});
    }

    public render() {
        const mediaElement: React.ReactNode = this.makeMediaElement();
        const comments: React.ReactNode = (
            <CommentsSection
                fileID={this.props.file.fileID}
                albumID={this.props.file.jobID}
                maxInitiallyVisibleComments={this.props.mobileMode ? 2 : 0}
                comments={this.props.file.comments}
                mobileMode={this.props.mobileMode}
                commentsCanBeAdded={this.props.userCanAddComment}
                lovesCanBeAdded={this.props.userCanLove}
                lovedByCurrentUser={this.props.file.lovedByCurrentUser}
                totalLoveCount={this.props.file.totalLoveCount}
                onLoveChanged={this.props.onLoveReactionChanged}
                doShowLoveList={this.props.doShowLoveList}
                isPendingNewComment={this.props.file.isPendingNewComment}
                elementHeight={Math.min(this.props.height, this.maxImageHeight)}
                overflowMenuOptions={this.getOverflowMenuItems()}
            />
        );

        if (this.props.mobileMode) {
            return (
                <Container
                    desaturate={this.props.file.isPendingServerDelete}
                    className={albumFileDOMClass}
                >
                    {mediaElement}
                    <CommentsWrapperMobile>{comments}</CommentsWrapperMobile>
                </Container>
            );
        }

        return (
            <LargeScreenContainer
                desaturate={this.props.file.isPendingServerDelete}
                height={Math.min(this.props.height, this.maxImageHeight)}
                className={albumFileDOMClass}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                {mediaElement}
                <CommentsWrapperDesktop>
                    {comments}
                </CommentsWrapperDesktop>
            </LargeScreenContainer>
        );
    }
}
