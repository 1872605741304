import {TakeoutFile} from '../../API/services/AppService';
import {Action, isType} from '../common/actions';
import {
    TakeoutInitiated, TakeoutIsBaking, TakeoutIsReady, TakeoutIsNotStarted
} from './actions';
import {TakeoutStatus} from './selectors';

export type TakeoutState = {
    status?: TakeoutStatus,
    contactEmail?: string,
    files?: TakeoutFile[],
    filesExpires?: number,
    rebakeAllowedAt?: number,
};

export const takeoutReducer = (state: TakeoutState = {}, action: Action<any>): TakeoutState => {
    if (isType(action, TakeoutIsNotStarted)) {
        return {...state, status: 'notStarted'};
    }
    if (isType(action, TakeoutIsBaking)) {
        return {...state, status: 'baking', contactEmail: action.payload.contactEmail};
    }
    if (isType(action, TakeoutIsReady)) {
        const {files, filesExpires, rebakeAllowedAt, contactEmail} = action.payload;
        return {...state, status: 'ready', files, filesExpires, rebakeAllowedAt, contactEmail};
    }
    if (isType(action, TakeoutInitiated)) {
        return {...state, status: 'baking', contactEmail: action.payload.contactEmail};
    }
    return state;
};
