import {PRODUCT_NAME, STRIPE_API_TOKEN} from '../../../../config/constants';
import {getRange} from '../../utilities/arrayUtils';

let stripeCheckoutSDKLoaded = false;
export const loadStripeCheckoutSDK = () => {
    if (!stripeCheckoutSDKLoaded) {
        stripeCheckoutSDKLoaded = true;
        const s = document.createElement('script');
        s.src = 'https://checkout.stripe.com/checkout.js';
        document.head.appendChild(s);
    }
};

export const ensureStripeJSIsLoaded = (): Promise<void> => {
    if (window.Stripe) {
        return Promise.resolve();
    }

    return new Promise((resolve: () => void) => {
        const s = document.createElement('script');
        s.src = 'https://js.stripe.com/v3/stripe.js';
        s.onload = resolve;
        document.head.appendChild(s);
    });
};

type StripeModalOptions = {
    email?: string,
    panelLabel?: string,
    description?: string,
    currency?: string,
    amount?: number,
};

type TokenCard = {id: string, last4: string, exp_month: number, exp_year: number}; // Subset of card
export type TokenResponse = {card: TokenCard, email: string, id: string}; // Subset of response
type TokenCallback = (token: TokenResponse) => any;

export const clearStripeCheckoutIFrames = () => {
    setTimeout(() => { // stripe will add additional iframe after close, need to defer clear process
        const frames = document.getElementsByName('stripe_checkout_app');
        getRange(frames.length).map((i) => frames[i]).forEach((f) => {
            if (f.parentNode) {f.parentNode.removeChild(f); }
        });
    }, 0);
};

const makeStandardHandler = (stripeKey: string, tokenCallback: TokenCallback) =>  window.StripeCheckout.configure({
    key: stripeKey,
    image: __BASEURL__ + 'img/captureCloudLogo.png',
    locale: 'auto',
    token: tokenCallback,
    closed: () => {
        clearStripeCheckoutIFrames();
    },
});

export const initializeStripePaymentModal = (
    modalOptions: Partial<StripeModalOptions>,
): Promise<TokenResponse> => {
    return new Promise((resolve) => {
        const handler = makeStandardHandler(STRIPE_API_TOKEN, resolve);

        handler.open({
            name: PRODUCT_NAME,
            zipCode: false,
            allowRememberMe: false,
            ...modalOptions,
        });
    });
};
