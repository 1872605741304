import * as React from 'react';
import {connect} from 'react-redux';
import {State} from '../../state';
import {
    getUsersLovedListByFile,
    isCurrentUserLovedFile,
} from '../../state/reaction/selectors';
import {UserInfo} from '../../state/users/selectors';
import {loveInfoText} from '../Reaction/AlbumFileLoveInfo';
import {UsersListModal} from './UsersListModal';

type OwnProps = {
    fileID: FileID,
    onClose: () => any,
    isMobile: boolean,
};
type StateProps = {
    usersList: UserInfo[];
    isCurrentUserLoved: boolean;
};
type Props = OwnProps & StateProps;

class LoveFileList extends React.Component<Props> {
    public render() {
        const loveText = loveInfoText(
            this.props.isCurrentUserLoved,
            this.props.usersList.length,
        );

        return (
            <UsersListModal
                isMobile={this.props.isMobile}
                usersList={this.props.usersList}
                headerText={loveText}
                onClose={this.props.onClose}
            />
        );
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    usersList: getUsersLovedListByFile(state, ownProps.fileID),
    isCurrentUserLoved: isCurrentUserLovedFile(state, ownProps.fileID),

});

export const UsersLoveFileList = connect(mapStateToProps)(LoveFileList);
