import * as React from 'react';
import {colors} from '../../assets';
import {IconProps} from './index';

export const NoInternetIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path fill={props.color || colors.defaultIconColor} d="M20.73,8.25 C20.3925,7.995 17.0325,5.25 12,5.25 C10.875,5.25 9.8325,5.3925 8.8875,5.61 L16.635,13.35 L20.73,8.25 L20.73,8.25 Z M15.78,14.415 L5.4525,4.08 L4.5,5.04 L6.0375,6.585 C4.4325,7.32 3.4425,8.115 3.27,8.25 L11.9925,19.1175 L12,19.125 L12.0075,19.1175 L14.9325,15.4725 L17.4225,17.9625 L18.375,17.01 L15.78,14.415 L15.78,14.415 Z" />
        </g>
    </svg>
);
