import {getCurrentLocale} from '../../../../config/constants';
import {translatedStrings} from './takeoutTranslatedStrings';

export const takeoutStrings: DictionaryOf<string> = {
    // Page Titles
    takeout_service: 'Capture Takeout Service',
    takeout_info: 'Important Information',

    before_login_info: 'With the takeout service you will get all your photos out of Capture.',

    // Alert
    shutdown_ultimatum: 'We are sorry to inform you that Capture is shutting down in Asia on:',
    shutdown_date: 'March 1st 2018',
    alert_copy: 'We have created an easy-to-use takeout service to easily get all your photos and videos out of Capture.',
    alert_confirm: 'Use Takeout service',
    alert_deny: 'I\'ll do it later',
    alert_banner_notStarted: 'Capture is shutting down in Asia and will be unavailable from 1 March 2018. You can download your photos and videos via the Takeout service.',
    alert_banner_baking: 'Your files are currently being prepared for download',
    alert_banner_ready: 'Your photos are ready to be downloaded.',
    alert_banner_link: 'Read more',
    alert_banner_link_ready: 'Click here to access your files',

    // baking
    baking_thanks: 'Thank you for using Capture takeout service!',
    baking_emailInfo: 'When your photos are ready to download you will receive an email to this address. This might take some time.',
    baking_close_tab_info: 'You can now close this tab',

    // info
    info_main_copy: 'Download all your photos and videos from Capture easily via the Takeout service. We will send an email with a link to your files. The link will be valid for 14 days for you to download the files.',
    info_cta_copy: 'Enter your email address to receive files:',
    info_cta_copy_known: 'Email will be sent to this address:',
    info_device_copy: 'Because of potentially large file sizes, it is recommended to use a desktop or laptop for downloading your files.',
    info_email_change: 'Use a different email address',
    info_takeout_ended: 'New takeouts cannot be started',

    // email dialogs
    confirm_email_header_text: 'Confirm your email address',
    input_email_header_text: 'Please enter email address:',
    confirm_email_btn_text: 'Yes, that\'s correct',
    change_email_btn_text: 'Change email',
    cancel_change_email_btn_text: 'Cancel email address change',
    email_input_error: 'Invalid email address',

    // download
    download_description: 'Your photos are ready to be downloaded.',
    mobileNotice: 'Please note that to open zip files on your phone you need an app that can do this.We highly recommend to use a PC/ Mac.',
    download: 'Download',

    // download disabled inside app
    use_other_device_message: 'In order to get access to your archived photos/videos please use laptop/desktop.',

    // FAQ (as provided by word-translation)
    faq_header: 'Capture Asia Shutdown FAQ',
    faq_question1: '1) I cannot log-in to the takeout',
    faq_answer1_p1: 'You can reset your password on the login page to the takeout service:',
    faq_answer1_p2: '1) Go to <g id="1">https://takeout.capture-app.com',
    faq_answer1_p3: '2) Enter your registered mobile number or e-mail',
    faq_answer1_p4: '3) Press forgotten password and follow the instructions',
    faq_answer1_p5: 'If you continue to experience login problems, contact customer support. We provide English live chat support 24/7, or you can send us an email and we’ll answer as soon as possible.',
    faq_question2: '2) I have problems using take out.',
    faq_answer2_p1: 'If you are experiencing problems to complete the process, please check again the takeout page: <g id="1">https://takeout.capture-app.com</g>  and follow the steps.',
    faq_answer2_p2: 'If you have done the takeout and you are still waiting for an email, please remember that it may take up to 48 hours to get your content ready for download (depending on how much data you have stored)',
    faq_answer2_p3: 'Please check your inbox and your spam folder in case the email got redirected there.',
    faq_answer2_p4: 'You can always log in to <g id="1">https://takeout.capture-app.com</g> and see the status of your take out request.',
    faq_answer2_p5: 'If the problem persists, please contact CS (see below).',
    faq_question3: '3) What happens with my data if I do not retrieve it',
    faq_answer3_p1: 'Telenor Digital will shut down the Capture service in Asia from March 1st, 2018. After this date all data will be deleted from the servers and it will not be possible to retrieve data anymore.',
    faq_question4: '4) I pay for a monthly subscription - how do I cancel it',
    faq_answer4_p1: 'If you have a <g id="1">monthly subscription paid using the web shop</g>, we will cancel the subscriptions during December 2017, no refund will be given.',
    faq_answer4_p2: 'If you have a <g id="1">yearly subscriptions paid using the web shop</g>, we will cancel the subscription during December and do a refund if the subscription is less than 6 months old.',
    faq_answer4_p3: 'If you have paid using the <g id="1">Apple App store in-app purchase</g> they will need to go to the Apple subscription page to cancel the subscription.',
    faq_answer4_p4: 'A description on how to cancel your subscriptions on Apple devices can be found here: https://support.apple.com/en-sg/HT202039',
    faq_answer4_p5: 'If there are any doubts or questions, please contact CS.',
    faq_question5: '5) I have a yearly subscription which I already have paid for - do I get a refund?',
    faq_answer5_p1: 'If you have yearly subscriptions we will do a refund if the subscription is less than 6 months old. Please contact CS to get a refund.',
    faq_question6: '6) I have a monthly subscription which I already have paid for - do I get a refund?',
    faq_answer6_p1: 'No, when you cancel it it will run until the next renewal and then be stopped. Any current subscription will be stopped during December if not cancelled by the end user earlier.',
    faq_footer_intro: 'Customer Service for Capture is available at:',

    // shutdown page
    dear_customer: 'Dear customer,',
    shutdown_capture_app_not_available: 'The Capture app is no longer available in Asia.',
    shutdown_capture_not_available: 'Capture is no longer available in Asia.',
    shutdown_remove_app: 'Please remove the app from your device.',
};

/**
 * How to deal with these strings and crowdin.
 *    They have a file in crowdin (added manually): Takeout service/2017ShutdownString.po
 *
 * To push new strings:
 *  1. Update translation/build_po_file.ts
 *     The push-strings method should push the above object to Takeout service/2017ShutdownString.po on Crowdin.
 *     Replace in the final line so that it reads
 *         pushStrings(takeoutStrings, 'Takeout service/2017ShutdownString.po').then((res) => console.log(res));
 *  2. Run that script, use yarn-alias in package.json
 *       $ yarn lang:push
 *
 * To pull the new strings (into neighbouring file, takeoutTranslatedStrings.ts):
 *  1. Update translation/build_translations.ts
 *     It should get constants for takeoutStrings (above) from Takeout service/2017ShutdownString.po
 *         getAllConstants(takeoutStrings, 'Takeout service/2017ShutdownString.po')
 *  2. Run build_translations-script and store output in takeoutTranslatedStrings.ts
 *       $ TS_NODE_COMPILER_OPTIONS='{"module":"commonjs"}' node_modules/.bin/ts-node translation/build_translations.ts > __tmpStr.ts && mv __tmpStr.ts products/common/components/Takeout/takeoutTranslatedStrings.ts
 *
 */

const localizedSet = translatedStrings[getCurrentLocale()] || {};

export function getTakeoutString(stringKey: string): string {

    return localizedSet[stringKey] || takeoutStrings[stringKey] || stringKey;
}
