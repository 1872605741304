import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets/styleConstants';
import {TelenorCaptureFooter} from '../Common/TelenorCaptureFooter';

type InfoExternalLink = {
    link: string,
    text: string,
};

export type InfoProps = {
    infoIcon: React.ReactNode,
    infoTitle?: string,
    extLink?: InfoExternalLink,
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    padding: 48px 16px 16px;
    box-sizing: border-box;
`;

const PlaceHolder = styled.div`
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-self: center;
`;

const Icon = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 31px auto 0;
    max-width: 480px;
    align-self: center;
`;

const ExtLink = styled.a`
    display: block;
    font-size: 14px;
    color: ${colors.captureBlue};
    text-align: center;
    text-decoration: underline;
    margin-top: 45px;
`;

export const InfoContainer: React.SFC<InfoProps> = (props) => {
    const externalLink = props.extLink && (
        <ExtLink className="external-link" href={props.extLink.link}>{props.extLink.text}</ExtLink>
    );

    return (
        <Container>
            <PlaceHolder />{/*PlaceHolder to align Content to center and Footer to bottom in space-between mode*/}
            <Content id="info-content">
                <Icon>{props.infoIcon}</Icon>
                {props.infoTitle && <Title id="info-title">{props.infoTitle}</Title>}
                {props.children}
                {externalLink}
            </Content>
            <TelenorCaptureFooter />
        </Container>
    );
};
