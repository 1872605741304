import * as React from 'react';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {FilledTextButton} from '../Common/IconTextButton';
import {PopupVerticalLayout} from '../Popup/PopupVerticalLayout';

type Props = {
    albumID: JobID,
    onShareNow: (albumID: JobID) => any,
    onShareLater: () => any,
};
export class ShareAlbumPrompt extends React.Component<Props> {
    private handleShareInitiated = () => {
        this.props.onShareNow(this.props.albumID);
    }
    public render() {
        const image = require('../../assets/img/illustration_album.png');

        const buttons = [
            (
            <FilledTextButton
                onClick={this.handleShareInitiated}
                text={_('share_now')}
                fillColor={colors.captureBlue}
                color={'white'}
                hoverColor={colors.captureBlue}
                hoverFillColor={colors.captureBlue100}
            />
            ),
            (
            <FilledTextButton
                onClick={this.props.onShareLater}
                text={_('share_later')}
                color={colors.captureGrey800}
                fillColor={'white'}
                hoverColor={colors.captureBlue}
                hoverFillColor={colors.captureBlue100}
            />
            ),
        ];
        return (
            <PopupVerticalLayout
                illustration={image}
                headerText={_('share_created_album_prompt_header')}
                textContent={_('share_created_album_prompt_text')}
                buttons={buttons}
            />
        );
    }
};
