import {Store} from 'redux';
import {getFileInNeedOfMetadata} from '../../state/fileMetadata/selectors';
import {State} from '../../state/store';
import {fetchFileMetadata} from '../job';

/**
 * The job of the FileMetadataSyncer:
 * - fetch the metadata for current viewer node file if it is not present
 */

class FileMetadataSyncer {
    private current?: FileID;

    constructor(private store: Store<State>) {
        store.subscribe(() => this.digestState(store.getState()));
    }

    private digestState = async (newState: State) => {
        const currentFile = getFileInNeedOfMetadata(newState);
        // Only fetch metadata for file that requires fetching
        if (currentFile === undefined || this.current === currentFile.fileID) {
            return;
        }
        this.current = currentFile.fileID;
        await fetchFileMetadata(this.store.dispatch, currentFile.jobID, currentFile.fileID);
    }
}

export const connectFileMetadataSyncer = (store: Store<State>) => {new FileMetadataSyncer(store); };
