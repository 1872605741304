import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {isMobileDevice} from '../../utilities/device';
import {ButtonProps} from '../Common/Button';

type MoreElementProps = {
    closeToBottom: boolean,
    linkLength: number,
};
const MoreElements = styled.div`
    position: absolute;
    top: ${(props: MoreElementProps) => props.closeToBottom ? '-50px' : 'auto'};
    left: ${(props) => props.linkLength / 2 - 26}px;
    color: ${colors.captureBlue};
    padding: 12px 4px;
    background-color: white;
    filter: drop-shadow(rgba(0,0,0,0.2) 0px 2px 5px);
    border-radius: 3px;
    font-size: 14px;
    z-index: 10;
    white-space: nowrap;
    transform: translateY(4px) translateZ(0);

    &::before{
        content: '';
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
        ${(props) => props.closeToBottom ? 'border-top: solid 10px white' : 'border-bottom: solid 10px white'};
        position: absolute;
        transform: ${(props) => props.closeToBottom ? 'translateY(7px)' : 'translateY(-7px)'};
        ${(props) => props.closeToBottom ? 'bottom:0' : 'top: 0'};
        left: 16px;
    }

    &:hover{
        cursor: pointer;
    }

    span {
        padding: 4px 12px;
        border-left: 1px solid ${colors.captureGrey300};
        &:first-child{
            border: none;
        }
        &:hover {
            color: ${colors.captureBlue900};
        }
    }
`;

const MoreLink = styled.span`
    position:relative;
    color: ${colors.captureBlue200};
    &:hover{
        cursor: pointer;
    }
`;

type Props = {
    commentActions: ButtonProps[]
    isMobileMode: boolean,
};

type ComponentState = {
    moreElements?: MoreElementProps,
};

export class CommentActionComponent extends React.Component<Props, ComponentState> {
    private moreLink = React.createRef<HTMLSpanElement>();
    public state: ComponentState = {};

    private handleMoreClick = (e: React.MouseEvent<any>) => {
        if (!this.state.moreElements) {
            const buffer = this.props.isMobileMode ? 200 : 100;
            const link = e.currentTarget;
            let closeToBottom = false;
            if (this.props.isMobileMode) {
                closeToBottom = window.innerHeight - link.getBoundingClientRect().bottom < buffer;
            }
            else {
                const distanceToTopOfList = link.offsetTop + link.offsetHeight - link.offsetParent.scrollTop;
                const distanceToBottomOfList = link.offsetParent.clientHeight - distanceToTopOfList;
                const distanceToBottomOfScreen = window.innerHeight - link.getBoundingClientRect().bottom;

                if (distanceToTopOfList < buffer) { // first visible in list
                    closeToBottom = false;
                } else {
                    closeToBottom = Math.min(distanceToBottomOfScreen, distanceToBottomOfList) < buffer;
                }
            }
            this.setState({
                moreElements: {
                    closeToBottom,
                    linkLength: link.offsetWidth,
                },
            });
            this.addOtherClickListener();
        } else {
           this.removeMoreElements();
        }
    }

    private removeMoreElements = () => {
        this.setState({moreElements: undefined});
        this.removeOtherClickListener();
    }

    private otherClickHandler = (e: any) => {
        if (e.target !== this.moreLink.current) {
           setTimeout(this.removeMoreElements, 10);
        }
    }

    private addOtherClickListener = () => {
        document.body.addEventListener('click', this.otherClickHandler);
        if (isMobileDevice.iOS()) {
            // Safari on iOS only dispatches click events on pointy elements. Hack-fix this to allow catching click-events on everything
            // http://www.shdon.com/blog/2013/06/07/why-your-click-events-don-t-work-on-mobile-safari
            document.body.style.cursor = 'pointer';
            document.body.style['-webkit-tap-highlight-color' as any] = 'transparent';
        }
    }

    private removeOtherClickListener = () => {
        document.body.removeEventListener('click', this.otherClickHandler);
        if (isMobileDevice.iOS()) {
            document.body.style.cursor = 'auto';
            document.body.style['-webkit-tap-highlight-color' as any] = 'black';
        }
    }

    public render() {
        const buttons = this.props.commentActions.map((button) => (
            <span key={button.text} onClick={button.onClick}>{button.text}</span>
        ));
        const moreElements = this.state.moreElements && (
            <MoreElements {...this.state.moreElements}>{buttons}</MoreElements>
        );

        return (
            <MoreLink
                innerRef={this.moreLink}
                onClick={this.handleMoreClick}
            >
                {_('more')}
                {moreElements}
            </MoreLink>
        );
    }
}
