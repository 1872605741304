import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {deleteMultipleTrashFiles, restoreMultipleFiles} from '../../API/job';
import {_, getStringWithAmount} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {Album} from '../../routing/pages';
import {NavigateTo} from '../../state/routing/actions';
import {StatusNotificationDismissed} from '../../state/statusNotifications/actions';
import {StatusNotification} from '../../state/statusNotifications/reducer';
import {RippleLoader} from '../Common/RippleLoader';

const ToastWrapper = styled.div`
    width: 100%;
    height: 48px;
    padding: 4px 16px;
    background: ${(props: {toastColor: string}) => props.toastColor};
    box-shadow: rgba(0, 0, 0, 0.3) 1px 2px 5px;
    color: white;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: ${fontSize.small_14};
    justify-content: space-between;

    & span {
        cursor: hover;
    }
`;

const ToastActionWrapper = styled.span`
    cursor: pointer;
    white-space: nowrap;
`;

type OwnProps = {
    toast: StatusNotification;
    isOwnerOfJob: (job: JobID) => boolean;
    getJobName: (job: JobID) => string;
};

type DispatchProps = {
    label: string;
    action?: {
        label: string;
        method: () => any;
    };
    dismissToast?: () => any;
    toastColor?: string;
};

type Props = OwnProps & DispatchProps;

class ToastComponent extends React.PureComponent<Props> {
    private dismissTimer?: number;

    private handleActionClicked = () => {
        if (this.props.action) {
            this.props.action.method();
        }
        if (this.props.dismissToast) {
            this.props.dismissToast();
        }
    }

    private getActionArea = () => {
        if (this.props.dismissToast === undefined) {
            return <RippleLoader size={32} color={'white'} />;
        } else {
            return (
                <ToastActionWrapper onClick={this.handleActionClicked}>
                    {this.props.action ? this.props.action.label : _('ok')}
                </ToastActionWrapper>
            );
        }
    }

    public componentDidMount() {
        const {dismissToast} = this.props;
        if (dismissToast) {
            this.dismissTimer = window.setTimeout(() => {
                dismissToast();
                this.dismissTimer = undefined;
            }, 4000);
        }
    }

    public componentWillUnmount() {
        if (this.dismissTimer !== undefined) {
            window.clearTimeout(this.dismissTimer);
        }
    }

    public render() {
        return (
            <ToastWrapper
                toastColor={this.props.toastColor || colors.captureBlue}
            >
                <ContentWrapper>
                    {this.props.label}
                    {this.getActionArea()}
                </ContentWrapper>
            </ToastWrapper>
        );
    }
}

const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
): DispatchProps => {
    const dismissToast = () =>
        dispatch(StatusNotificationDismissed(ownProps.toast.id));
    switch (ownProps.toast.type) {
        case 'albumCoverPhotoSet':
            return {
                label: _('toast__coverPhoto_was_set'),
                dismissToast,
            };
        case 'autoCreatedAlbum':
            const navigate = NavigateTo(Album(ownProps.toast.relatedJobID));
            return {
                label: _('toast__album_auto_crated__format').replace(
                    '%album_name%',
                    ownProps.toast.relatedJobName,
                ),
                action: {
                    label: _('toast__see_album'),
                    method: () => dispatch(navigate),
                },
                dismissToast,
            };
        case 'filesDeleted': {
            const {relatedJob, relatedFiles} = ownProps.toast;
            const label = getStringWithAmount(
                relatedFiles.length,
                _('toast__single_file_deleted'),
                _('toast__multiple_files_deleted__format'),
            );
            const restoreAction = {
                label: _('undo'),
                method: () => {
                    restoreMultipleFiles(dispatch, relatedJob, relatedFiles);
                },
            };
            return {
                label,
                action: ownProps.isOwnerOfJob(relatedJob)
                    ? restoreAction
                    : undefined,
                dismissToast,
            };
        }
        case 'trashFilesDeleted': {
            const label = getStringWithAmount(
                ownProps.toast.numFiles,
                _('toast__single_file_perm_deleted'),
                _('toast__multiple_files_perm_deleted__format'),
            );

            return {
                label,
                dismissToast,
            };
        }
        case 'trashFilesDeletionFailed': {
            const {relatedFiles} = ownProps.toast;
            const label = getStringWithAmount(
                relatedFiles.length,
                _('toast__single_file_perm_deleted_failed'),
                _('toast__multiple_files_perm_deleted_failed__format'),
            );
            const action = {
                label: _('retry'),
                method: () => deleteMultipleTrashFiles(dispatch, relatedFiles),
            };

            return {
                label,
                action,
                toastColor: colors.captureMagenta,
                dismissToast,
            };
        }
        case 'filesWereRestored': {
            const label = getStringWithAmount(
                ownProps.toast.numFiles,
                _('toast__single_file_restored'),
                _('toast__multiple_files_restored__format'),
            );

            return {
                label,
                dismissToast,
            };
        }
        case 'jobCopiedToTimeline':
            return {
                label: _('toast__album_added_to_timeline'),
                dismissToast,
            };
        case 'jobCopiedToTimelineFailed':
            return {
                label: _('toast__album_added_to_timeline_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            };
        case 'filesCopiedToTimeline':
            return {
                label: _('toast__file_added_to_timeline'),
                dismissToast,
            };
        case 'filesCopiedToTimelineFailed':
            return {
                label: _('toast__file_added_to_timeline_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            };
        case 'filesCopiedToAlbum':
            return {
                label: getStringWithAmount(
                    ownProps.toast.relatedFiles.length,
                    _('toast__single_file_added_to_album__format'),
                    _('toast__multiple_files_added_to_album__format'),
                ).replace('%album_name%', ownProps.getJobName(ownProps.toast.relatedJob)),
                dismissToast,
            };
        case 'filesCopiedToAlbumFailed':
            return {
                label: getStringWithAmount(
                    ownProps.toast.relatedFiles.length,
                    _('toast__single_file_added_to_album_failed__format'),
                    _('toast__multiple_file_added_to_album_failed__format'),
                ),
                toastColor: colors.captureMagenta,
                dismissToast,
            };
        case 'shareCopiedToTimeline':
            return {
                label: _('share_added_to_timeline'),
                dismissToast,
            };
        case 'shareCopiedToTimelineFailed':
            return {
                label: _('share_added_to_timeline_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            };
        case 'filesWereShared':
            return {
                label: _('toast__files_were_shared'),
                dismissToast,
            };
        case 'shareCreationFailed':
            return {
                label: _('share_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            };
        case 'filesDownloadFailed':
            return {
                label: _('download_files_failed_message'),
                toastColor: colors.captureMagenta,
                dismissToast,
            };
        case 'filesRestorationFailed': {
            const {relatedJob, relatedFiles} = ownProps.toast;
            const label = getStringWithAmount(
                ownProps.toast.relatedFiles.length,
                _('toast__single_file_restored_failed'),
                _('toast__multiple_files_restored_failed__format'),
            );

            const retryAction = {
                label: _('retry'),
                method: () => {
                    restoreMultipleFiles(dispatch, relatedJob, relatedFiles);
                },
            };
            return {
                label,
                toastColor: colors.captureMagenta,
                action: retryAction,
                dismissToast,
            };
        }
        case 'linkWasCopied':
            return {
                label: _('link_copied'),
                dismissToast,
            };
        case 'filesAreBeingDeleted':
            return {
                label: _('toast__files_are_deleting'),
            };
        case 'filesAreBeingRestored':
            return {
                label: _('toast__files_are_restoring'),
            };
        case 'filesAreBeingCopied':
            return {
                label: _('toast__files_are_copying'),
            };
        case 'purchaseSuccessful':
            return {
                label: _('toast__purchase_successful'),
                dismissToast,
            };
        case 'purchaseFailed':
            return {
                label: _('toast__purchase_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            };
        case 'creditCardUpdated':
            return {
                label: _('toast__credit_card_updated'),
                dismissToast,
            };
        case 'creditCardUpdateFailed':
            return {
                label: _('toast__credit_card_update_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            };
        case 'planCanceled':
            return {
                label: _('toast__plan_canceled'),
                dismissToast,
            };
        case 'planCancelFailed':
            return {
                label: _('toast__plan_cancel_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            };
        case 'planChangeSucceeded':
            return {
                label: _('toast__plan_change_succeeded'),
                dismissToast,
            };
        case 'planChangeFailed':
            return {
                label: _('toast__plan_change_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            };
        case 'planReactivated':
            return {
                label: _('toast__plan_reactivated'),
                dismissToast,
            };
        case 'planReactivationFailed':
            return {
                label: _('toast__plan_reactivation_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            };
    }
};

export const Toast = connect(null, mapDispatchToProps)(ToastComponent);
