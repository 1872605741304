import * as React from 'react';
import {TakeoutContent} from './TakeoutContent';
import {getTakeoutString} from './takeoutLocalizedStrings';

export const TakeoutReadyDisabledInAppPage: React.SFC = () => (
    <TakeoutContent title={getTakeoutString('takeout_service')}>
        <div>
            <p>{getTakeoutString('download_description')}</p>
            <p>{getTakeoutString('use_other_device_message')}</p>
        </div>
    </TakeoutContent>
)