import * as React from 'react';
import styled from 'styled-components';
import {_, colors} from '../../assets';
import {fontSize} from '../../assets/styleConstants';
import {BorderedTextButton, FilledTextButton, IconTextButton} from '../Common/IconTextButton';
import {BackArrowIcon} from '../Icons/BackArrowIcon';
import {CaptureLogoVertical} from '../Icons/CaptureLogo';
import {TopNavPlaceholder} from '../Navigation/TopNavBar';
import {TwoAreasTopNavBar} from '../Navigation/TwoAreasTopNavBar';
import {InfoContainer} from './InfoContainer';

const Content = styled.div`
    text-align: center;
`;

const Text = styled.div`
    font-size: ${fontSize.large_22};
    color: ${colors.captureGrey800};
    margin: 32px;
`;

const Buttons = styled.div`
    display: flex;
    margin: 48px auto;
    max-width: 416px;

    & > div {
        margin: 8px;
        flex: 1;
    }
`;

type Props = {
    text: string,
    onRegister: () => any,
    onSignIn: () => any,
    goBack: () => any,
};
export class LoginRequiredPage extends React.Component<Props> {

    public render() {

        const topNavBar = <IconTextButton onClick={this.props.goBack} icon={BackArrowIcon} text={_('back')} />;

        return (
            <div>
                <TopNavPlaceholder />
                <InfoContainer infoIcon={<CaptureLogoVertical size={92} />}>
                    <Content>
                        <Text>{this.props.text}</Text>
                        <Buttons>
                            <BorderedTextButton
                                onClick={this.props.onRegister}
                                text={_('create_account')}
                                color={colors.captureBlue}
                                hoverColor={colors.captureBlue}
                                hoverFillColor={colors.captureBlue100}
                            />
                            <FilledTextButton
                                onClick={this.props.onSignIn}
                                text={_('sign_in')}
                                hoverColor={colors.captureBlue}
                                hoverFillColor={colors.captureBlue100}
                            />
                        </Buttons>
                    </Content>
                </InfoContainer>
                <TwoAreasTopNavBar left={topNavBar} right={null} />
            </div>
        );
    }
}
