import * as React from 'react';
import {colors} from '../../assets';

interface Props {
    size: number;
    coloredRatio: number;
}

export class StorageChart extends React.Component<Props, {}> {

    private drawCircleIn = (canvas: HTMLCanvasElement|null) => {
        if (!canvas) {
            return;
        }
        const context = canvas.getContext('2d'),
            lines = 100;

        const radius = canvas.width / 2,
            linesWithGradient = this.props.coloredRatio * lines;

        if (context === null) {return; }

        context.clearRect(0, 0, canvas.width, canvas.height);
        context.lineWidth = 3 * radius / lines;

        for (let i = 0; i < lines; i++) {
            let color = colors.captureGrey300,
                currentAngle = 2 * Math.PI * (i / lines),
                dX = Math.sin(currentAngle) * radius,
                dY = Math.cos(currentAngle) * radius;

            if (i < linesWithGradient) {
                color = this.makeGradient(colors.captureGreen, colors.captureBlue, i / linesWithGradient);
            }

            context.beginPath();
            context.moveTo(
                radius + dX * 0.85,
                radius - dY * 0.85,
            );
            context.lineTo(
                radius + dX,
                radius - dY,
            );
            context.strokeStyle = color;
            context.stroke();
            context.closePath();
        }
        const fontSize = canvas.height / 5;
        context.font = fontSize + 'px Lato';
        context.fillStyle = colors.captureGreen;
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(
            (this.props.coloredRatio * 100).toFixed(0).toString() + '%',
            canvas.width / 2,
            canvas.height / 2,
        );
    }

    /**
     * Extract a hex-color-value between two hex-values. Takes ratio from the first color and the rest (1-ratio) from
     * the second color.
     *
     * ie makeGradient('#ABCDEF', '#FEDCBA', 0.3) to get the color 30% from #ABCDEF towards #FEDCBA.
     */
    private RGBhexToIntArr = (hex: string) => [1, 3, 5].map((i) => parseInt(hex.substr(i, 2), 16));
    private avgIntArr = (arr1: number[], arr2: number[], ratio: number) => {
        return arr1.map((e: number, i: number) => Math.round(e * (1 - ratio) + arr2[i] * ratio));
    }

    private makeGradient(hex1: string, hex2: string, ratio: number) {
        return '#'
            + this.avgIntArr(this.RGBhexToIntArr(hex1), this.RGBhexToIntArr(hex2), ratio)
                .map((i) => ((i < 16 ? '0' : '')
            + i.toString(16))).join('');
    }

    public render() {
        const deviceRatio = window.devicePixelRatio || 1;

        return (
            <canvas
                key={this.props.coloredRatio}
                ref={this.drawCircleIn}
                style={{width: this.props.size, height: this.props.size}}
                width={this.props.size * deviceRatio}
                height={this.props.size * deviceRatio}
            />
        );
    }
}
