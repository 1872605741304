import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {setLastSeenVersion} from '../../API/login';
import {getVersionNotes, VersionNote} from '../../state/currentUser/selectors';
import {State} from '../../state/store';
import {isMobileMode} from '../../state/viewMode/selectors';
import {FullscreenOverlay} from '../Common/FullscreenOverlay';
import {NewFeaturesPopupContent} from './NewFeaturesPopupContent';

const Wrapper = styled.div`
    width: 100%;
    max-width: 480px;
    max-height: 95%;
    overflow: auto;

    margin: 8px;
    background-color: white;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
    text-align: center;
`;

type StateProps = {
    currentVersionNotes: VersionNote[],
    isMobile: boolean,
};

type DispatchProps = {
    doSetLastVersionSeen: () => any,
};

type Props = StateProps & DispatchProps;

class NewFeaturesAlertPlacementComponent extends React.Component<Props> {
    public render() {
        if (this.props.currentVersionNotes.length === 0) {
            return null;
        }
        return (
            <FullscreenOverlay>
                <Wrapper>
                    <NewFeaturesPopupContent
                        versionNotes={this.props.currentVersionNotes}
                        onConfirm={this.props.doSetLastVersionSeen}
                    />
                </Wrapper>
            </FullscreenOverlay>
        );
    }
}

const mapStateToProps = (state: State): StateProps => ({
    currentVersionNotes: getVersionNotes(state),
    isMobile: isMobileMode(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doSetLastVersionSeen: () => setLastSeenVersion(dispatch),
});

export const NewFeaturesPopupPlacement =
    connect(mapStateToProps, mapDispatchToProps)(NewFeaturesAlertPlacementComponent);
