import * as React from 'react';
import {HOME_SITE_ADDRESS} from '../../../config/constants';
import {fetchAccount, requiresLogin} from '../components/Common/RequireLoggedIn';
import {StatusNotificationPlacement} from '../components/Common/StatusNotificationPlacement';
import {UserActionDialogPlacement} from '../components/Common/UserActionDialogPlacement';
import {NewFeaturesPopupPlacement} from '../components/NewFeaturesPopup/NewFeatuesPopupPlacement';
import {OutOfStoragePopupPlacement} from '../components/OutOfStoragePopup/OutOfStoragePopupPlacement';
import {UploadStatusPlacement} from '../components/Uploader';

const App: React.SFC = (props) => (
    <div style={{height: '100%'}}>
        {props.children}
        <UploadStatusPlacement />
        <StatusNotificationPlacement />
        <OutOfStoragePopupPlacement/>
        <UserActionDialogPlacement/>
        <NewFeaturesPopupPlacement/>
    </div>
);

export const LoggedInApp = requiresLogin({gotoIfLoginFails: HOME_SITE_ADDRESS})(App);
export const MaybeLoggedInApp = fetchAccount(App);
