import {ImageGroup, ScrollerYearGroup} from '../../state/timeline/selectors';

export const makeScrollerGroups = (groupList: ImageGroup[]): ScrollerYearGroup[] => {
    const yearList: DictionaryOf<ScrollerYearGroup> = {};
    const years: string[] = [];

    groupList.forEach((group) => {
        const [year, month] = group.header.split('-');

        if (yearList[year] === undefined) {
            years.push(year);
            yearList[year] = {
                yearStr: year,
                months: [],
            };
        }

        yearList[year].months.push({
            monthStr: month,
            scrollerEntryKey: [year, month].join('-'),
        });

    });

    return years.map((y) => yearList[y]);
};
