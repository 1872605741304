// browser may support localStorage, but not make it available to the scripts on the page
// https://gist.github.com/paulirish/5558557
const checkLocalStorageAvailable = () => {
    try {
        const x = '__storage_test__';

        window.localStorage.setItem(x, x);
        window.localStorage.removeItem(x);
        return true;
    } catch (e) {
        return false;
    }
};

const isLocalStorageAvailable: boolean = checkLocalStorageAvailable();

export const localStorageSet = (key: string, value: string): void => (
    isLocalStorageAvailable ? window.localStorage.setItem(key, value) : undefined
);
export const localStorageGet = (key: string): string|null => (
    isLocalStorageAvailable ? window.localStorage.getItem(key) : null
);
export const localStorageRemove = (key: string): void => (
    isLocalStorageAvailable ? window.localStorage.removeItem(key) : undefined
);

export const sessionStorageSet = (key: string, value: string): void => (
    isLocalStorageAvailable ? window.sessionStorage.setItem(key, value) : undefined
);
export const sessionStorageGet = (key: string): string|null => (
    isLocalStorageAvailable ? window.sessionStorage.getItem(key) : null
);
export const sessionStorageRemove = (key: string): void => (
    isLocalStorageAvailable ? window.sessionStorage.removeItem(key) : undefined
);
