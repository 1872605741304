import * as React from 'react';
import styled from 'styled-components';
import {animations, colors} from '../../../common/assets/styleConstants';
import {NextIconTV, PauseIconTV, PlayIconTV, PrevIconTV} from './CarouselNavIcons';

const NavWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0,0,0,0.5) 15%, rgba(0,0,0,0) 75%);
    padding: 16px 56px;
    display: flex;
    justify-content: space-between;
    opacity: 1;

    animation: ${(props: {displayNav: boolean}) => props.displayNav ? animations.fadeIn : animations.fadeOut} 0.2s;
    animation-fill-mode: forwards;
`;
const NavBtn = styled.button`
    background-color: transparent;
    border: none;
    padding: 0;
    border-radius: 50%;
    box-shadow: 0 0 24px 0 rgba(0,0,0,0.4);
    display: inherit;

    &:focus, &:hover {
        box-shadow: 0 0 24px 0 #fff;
        background-color: rgba(${colors.white_rgb}, 0.5);
    }
    &:focus svg {
        stroke: ${colors.captureBlue};
    }
`;
const NavBtnPlaceholder = styled.div`
    width: 68px;
    height: 68px;
`;

type Props = {
    displayButtons: boolean,
    autoPlay: boolean,
    onPlay: () => any,
    onPause: () => any,
    onNext?: () => any,
    onPrev?: () => any,
};

export const CarouselViewControls: React.SFC<Props> = (props) => {
    const prevNavBtn = !props.onPrev
        ? <NavBtnPlaceholder/>
        : (
            <NavBtn onClick={props.displayButtons ? props.onPrev : undefined}>
                <PrevIconTV size={68}/>
            </NavBtn>
        );

    const nextNavBtn = !props.onNext
        ? <NavBtnPlaceholder/>
        : (
            <NavBtn onClick={props.displayButtons ? props.onNext : undefined}>
                <NextIconTV size={68}/>
            </NavBtn>
        );

    const playPauseBtn = props.autoPlay ? (
        <NavBtn onClick={props.displayButtons ? props.onPause : undefined}>
            <PauseIconTV size={68}/>
        </NavBtn>
    ) : (
        <NavBtn onClick={props.displayButtons ? props.onPlay : undefined}>
            <PlayIconTV size={68}/>
        </NavBtn>
    );

    return (
        <NavWrapper displayNav={props.displayButtons}>
            {prevNavBtn}
            {playPauseBtn}
            {nextNavBtn}
        </NavWrapper>
    );
};
