import {Store} from 'redux';
import {StateWithCurrentUserState} from '../store';
import {ValidUserInfoRequired} from './actions';
import {
    getUUIDOfAnonymousUser, getUUIDOfLoggedInUser,
    hasAnonymousUserInfo, isLoggedIn, mustProvideUserInfo,
} from './selectors';

class UserExistenceGuarantee {
    private haveUser: boolean = false;
    private queue: Array<{ok: Function, reject: Function}> = [];

    constructor(private store: Store<StateWithCurrentUserState>) {
        store.subscribe(() => {
            const state = store.getState();
            const haveUser =  isLoggedIn(state) || hasAnonymousUserInfo(state);

            let f: {ok: Function, reject: Function}|undefined;
            if (haveUser !== this.haveUser) {
                this.haveUser = haveUser;
                const userId = getUUIDOfLoggedInUser(state) || getUUIDOfAnonymousUser(state);
                while (f = this.queue.pop()) {f.ok(userId); }
            }
            if (!mustProvideUserInfo(state) && this.queue.length > 0) {
                while (f = this.queue.pop()) {f.reject(); }
            }
        });
    }

    public requireUserInfo = (reason?: string): Promise<UserID> => {
        if (this.haveUser) {
            // isLoggedIn || hasAnonymousUserInfo selector return as true,
            // getUUIDOfLoggedInUser || getUUIDOfAnonymousUser selector
            // should return valid userId
            const state = this.store.getState();
            const userId = getUUIDOfLoggedInUser(state) || getUUIDOfAnonymousUser(state);
            return Promise.resolve(userId as string);
        } else {
            this.store.dispatch(ValidUserInfoRequired(reason));
            return new Promise<UserID>((ok: Function, reject: Function) => {this.queue.push({ok, reject}); });
        }
    }
}

let instance: UserExistenceGuarantee|undefined;
export const connectUserGuarantee = (store: Store<StateWithCurrentUserState>) => {
    instance = new UserExistenceGuarantee(store);
};

export const guaranteeAUser = (reason?: string): Promise<UserID> => {
    if (instance === undefined) {
        // Getting this error? It is because connectUserGuarantee-method (above) has not been called
        throw new Error('UserIfoProvider must be connected before it is being used');
    }
    return instance.requireUserInfo(reason);
};
