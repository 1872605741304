import * as React from 'react';
import {connect} from 'react-redux';
import {State} from '../../state';
import {JobInfoStatus} from '../../state/jobInfo/reducer';
import {getStatusOfJob} from '../../state/jobInfo/selectors';
import {LoadingPage} from '../Common/LoadingPage';
import {AlbumNotFoundPage, ProvidePasswordAlbumPage} from '../Info';

export type FetchedPageType = React.ComponentClass<{albumID: JobID}>;

type OwnProps = {
    albumID: JobID,
    fetchedPage: FetchedPageType,
};
type StateProps = {
    fetchStatus: JobInfoStatus;
};
type Props = OwnProps & StateProps;

class _AlbumContainer extends React.Component<Props, {}> {

    render() {
        switch (this.props.fetchStatus) {
            case JobInfoStatus.FETCHED:
            case JobInfoStatus.EXPIRED:
                return <this.props.fetchedPage albumID={this.props.albumID} />;
            case JobInfoStatus.NOT_FOUND:
            case JobInfoStatus.FETCH_FAILED:
                return <AlbumNotFoundPage />;
            case JobInfoStatus.PASSWORD_REQUIRED:
                return <ProvidePasswordAlbumPage albumID={this.props.albumID} />;
            case JobInfoStatus.NOT_STARTED:
            case JobInfoStatus.PENDING:
            default:
                return <LoadingPage />;
        }
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    fetchStatus: getStatusOfJob(state, ownProps.albumID),
});

export const AlbumContainer = connect(mapStateToProps)(_AlbumContainer);
