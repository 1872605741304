
import {DEVICE_ID, DEVICE_NAME, getCurrentLangDefinition} from '../../../../config/constants';
import {getJSON, HostUrl} from '../toolbox';

export type ServiceDictResponse = {
    service: {
        'thumb-host': string,
        'app-host': string,
        'video-host': string,
        'poll-host': string,
    },
};

export class LoginService {
    public hostUrl: HostUrl;

    constructor(hostname: string) {
        this.hostUrl = new HostUrl(hostname, {key: __API_KEY__});
    }

    public getLoginUrl() {
        const params = {
            redir: window.location.host.split(':')[0],
            locale: getCurrentLangDefinition().connectKey,
            device_id: DEVICE_ID,
            device_name: DEVICE_NAME,
        };
        return this.hostUrl.getPath('/st/4/connect/oauth2login', params);
    }

    public getServiceDictForJob(job: JobID): Promise<ServiceDictResponse> {
        return getJSON<ServiceDictResponse>(this.hostUrl.getPath(`/st/4/jobs/${job}/service`));
    }
}
