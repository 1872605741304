import * as React from 'react';
import styled from 'styled-components';
import {fontSize} from '../../assets/styleConstants';
import {TopNavBar} from './TopNavBar';

const Wrapper = styled.div`
    height: 100%;
    padding: ${(props: {isMobile: boolean}) => props.isMobile ? '8px' : '8px 24px;'};
    box-sizing: border-box;
    font-size: ${(props) => props.isMobile ? fontSize.small_12 : fontSize.small_14};

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LeftArea = styled.div`
    display: flex;
    justify-self: flex-start;
    align-self: center;
    display: flex;
    align-items: center;
`;

const RightArea = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

type Props = {
    left: React.ReactNode,
    right: React.ReactNode,
    isMobile?: boolean,
};

export const TwoAreasTopNavBar: React.SFC<Props> = (props) => (
    <TopNavBar>
        <Wrapper isMobile={props.isMobile !== false}>
            <LeftArea>{props.left}</LeftArea>
            <RightArea>{props.right}</RightArea>
        </Wrapper>
    </TopNavBar>
);
