import {AlbumFile} from '../../state/album/selectors';
import {CaptureFile} from '../../state/files/reducer';

export type AlbumViewerNode = {
    type: 'album',
    file: AlbumFile,
};
export type TimelineViewerNode = {
    type: 'timeline'|'share',
    file: CaptureFile,
};

export type ViewerNodeFiles<T extends AlbumViewerNode|TimelineViewerNode> = {
    type: T['type'],
    files: Array<T['file']>,
};
export type ViewerFiles = ViewerNodeFiles<AlbumViewerNode> | ViewerNodeFiles<TimelineViewerNode>;

type CarouselViewerNodeInternal<T extends AlbumViewerNode|TimelineViewerNode> = {
    type: T['type'],
    file: T['file'],
    next: FileID|null,
    prev: FileID|null,
    first: FileID|null,
    last: FileID|null,
};

export type CarouselViewerNode
    = CarouselViewerNodeInternal<AlbumViewerNode>
    | CarouselViewerNodeInternal<TimelineViewerNode>
    ;

export const slideBarPaginationThumbs = 4;
