import * as React from 'react';
import styled from 'styled-components';
import {fontSize} from '../../assets/styleConstants';
import {PageDescription} from '../../state';
import {SlideInBurgerMenu} from './SlideInBurgerMenu';
import {TwoAreasTopNavBar} from './TwoAreasTopNavBar';

const ElementWrapper = styled.div`
    margin-left: 24px;
    display: inline-flex;
    align-self: center;
`;
const PageTitle = styled(ElementWrapper)`
    font-size: ${fontSize.medium_16};
`;

type Props = {
    rightElements?: () => React.ReactNode[],
    currentPage?: PageDescription,
};

export class TopNavBarMobile extends React.Component<Props, {}> {
    public render() {

        let pageSpecificElements = null;
        if (this.props.rightElements) {
            pageSpecificElements = this.props.rightElements().map((el, i) => (
                <ElementWrapper key={i}>{el}</ElementWrapper>
            ));
        }
        const leftElements = [
            <SlideInBurgerMenu key={'menu_mobile'} currentPage={this.props.currentPage}/>,
            <PageTitle key={'title'}>{this.props.currentPage && this.props.currentPage.pageTitle}</PageTitle>,
        ];

        return (
            <TwoAreasTopNavBar
                left={leftElements}
                right={pageSpecificElements}
            />
        );
    }
}
