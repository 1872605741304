import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets/styleConstants';
import {IconProps} from '../Icons';

type BtnProps = {
    isActive?: boolean,
    fontSize?: string,
    color?: string,
    fillColor?: string,
};

const Btn = styled.div`
    cursor: ${(props: BtnProps) => props.isActive === false ? 'default' : 'pointer'};

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: ${(props) => props.fontSize || 'inherit'};
    color: ${(props) => props.color || 'inherit'};
    user-select: none;
    border-radius: 3px;
`;
const LeftAlignedBtn = styled(Btn)`
    justify-content: flex-start;
    padding: 8px 12px;

    & > div > div:last-child{
        margin-left: 8px;
    }
`;
const FilledBtn = styled(Btn)`
    color: ${(props) => props.color || 'white'};
    background-color: ${(props) => props.fillColor || colors.captureBlue};
    padding: 12px 16px;
`;

const BorderedBtn = styled(Btn)`
    border: solid 1px ${(props) => props.color || colors.captureGrey800};
    background-color: ${(props) => props.fillColor || 'transparent'};
    padding: 12px 16px;
`;
const IconWrapper = styled.div`
    cursor: pointer;
`;
const IconTextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
const IconBtnText = styled.div`
   margin-left: 4px;
`;

type TWithColor<T> = T & {color?: string, hoverColor?: string, hoverFillColor?: string};
type Base = {color?: string, fillColor?: string, onMouseEnter?: () => void, onMouseLeave?: () => void};
const withHoverColor = <T extends Base>(Button: React.ComponentType<T>): React.ComponentClass<TWithColor<T>> => {
    type State = {isHovering: boolean};
    return class extends React.Component<TWithColor<T>, State> {
        public state: State = {isHovering: false};
        private handleMouseEnter = () => {
            this.setState({isHovering: true});
        }
        private handleMouseLeave = () => {
            this.setState({isHovering: false});
        }
        public render() {
            const color = (this.state.isHovering && this.props.hoverColor) ? this.props.hoverColor : this.props.color;
            const fillColor = this.state.isHovering && this.props.hoverFillColor ? this.props.hoverFillColor : this.props.fillColor;
            return (
                <Button
                    {...this.props}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    color={color}
                    fillColor={fillColor}
                />
            );
        }
    };
};

// Do not export. The internal component props do not do type checking.
const combinedStyledWrapperAndContent = <T1, T2>(C1: React.ComponentType<T1>, C2: React.ComponentType<T2>) => (
    class Combined extends React.Component<T1 & T2> {
        public render() {
            return <C1 {...this.props}><C2 {...this.props}>{this.props.children}</C2></C1>;
        }
    }
);

// Button-content-components
type IconContentProps = {icon: React.SFC<IconProps>, color?: string, shadowColor?: string, iconSize?: number};
const IconContent = (p: IconContentProps) => (
    <p.icon color={p.color || colors.captureGrey800} size={p.iconSize || 24} shadowColor={p.shadowColor}/>
);

type TextContentProps = {text: string};
const TextContent = (p: TextContentProps) => <div>{p.text}</div>;

const IconTextContent = (p: IconContentProps & TextContentProps) => (
    <IconTextWrapper>
        <IconContent {...p}/>
        <IconBtnText>{p.text}</IconBtnText>
    </IconTextWrapper>
);

// The actual Button-classes
export const IconTextButton = withHoverColor(combinedStyledWrapperAndContent(Btn, IconTextContent));
export const IconTextListButton = withHoverColor(combinedStyledWrapperAndContent(LeftAlignedBtn, IconTextContent));
export const FilledIconTextButton = withHoverColor(combinedStyledWrapperAndContent(FilledBtn, IconTextContent));
export const BorderedIconTextButton = withHoverColor(combinedStyledWrapperAndContent(BorderedBtn, IconTextContent));

export const TextButton = withHoverColor(combinedStyledWrapperAndContent(Btn, TextContent));
export const FilledTextButton = withHoverColor(combinedStyledWrapperAndContent(FilledBtn, TextContent));
export const BorderedTextButton = withHoverColor(combinedStyledWrapperAndContent(BorderedBtn, TextContent));

export const IconButton = withHoverColor(combinedStyledWrapperAndContent(IconWrapper, IconContent));
