import * as React from 'react';
import styled from 'styled-components';
import {PRODUCT_NAME, TELENOR_MOBILE_PLANS_LINK} from '../../../../config/constants';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {CaptureStripeProduct, PlanGroup, StoragePlan} from '../../state/storagePlan/selectors';
import {getRange} from '../../utilities/arrayUtils';
import {FilledTextButton} from '../Common/IconTextButton';
import {InfiniteIconSmall} from '../Icons/InfiniteIcon';

const makeIEGridRowPositions = (numRows: number) => {
    return getRange(numRows).reduce((accString: string, currRowNumber: number) => accString +
    `& > div:nth-child(${currRowNumber + 1}) {
        -ms-grid-column: 1;
        -ms-grid-row: ${currRowNumber + 1};
    }

    `, '');
};

const makeIEGridColumnPositions = (numColumns: number, columnAlignments: string[]) => {
    return getRange(numColumns).reduce((accString: string, currColNumber: number) => accString +
    `& > div:nth-child(${currColNumber + 1}) {
        -ms-grid-column: ${currColNumber + 1};
        -ms-grid-row: 1;
        -ms-grid-column-align: ${ columnAlignments[currColNumber] || 'inherit'};
    }

    `, '');
};

const BuyMoreWrapper = styled.div`
    display: grid;
    grid-template-rows: 1fr 2fr 2fr 2fr 2fr;
    grid-template-columns: 1fr;
    max-width: 640px;
    margin: 0 auto;
    padding-bottom: 48px;

    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 1fr 2fr 2fr 2fr 2fr;

    ${makeIEGridRowPositions(6)}
`;

const ProductRow = styled.div`
    text-align: center;
    grid-column: 1/4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 2fr 1fr;
    -ms-grid-columns: 1fr 2fr 1fr;
    justify-items: center;
    align-items: center;
    background-image: linear-gradient(to right, ${colors.captureGrey300} 50%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 12px 1px;
    background-repeat: repeat-x;
    padding: ${(props: {isMobile: boolean}) => props.isMobile ? '16px 0' : '16px 24px'};

    ${makeIEGridColumnPositions(3, [
        'start',
        'center',
        'center',
    ])}
`;

const ProductSize = styled.div`
    font-size: ${fontSize.large_24};
    font-weight: bold;
    color: ${(props: {color: string}) => props.color};
`;

const UnavailablePlan = styled.div`
    width: ${(props: {isMobile: boolean, isSelected: boolean}) => props.isMobile ? 96 : 116}px;
    height: 35px;
    padding: 4px;
    box-sizing: border-box;
    cursor: ${(props) => props.isSelected ? 'inherit' : 'pointer'};
    color: ${(props) => props.isSelected ? colors.captureBlue : colors.captureGrey500};
`;

const AvailablePlan = styled(UnavailablePlan)`
    background-color: ${(props) => props.isSelected ? 'none' : colors.captureGrey200};
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.isSelected ? colors.captureBlue : 'inherit'};
    font-weight: bold;
    cursor: ${(props) => props.isSelected ? 'inherit' : 'pointer'};
    user-select: none;

    &:hover {
        background-color: ${(props) => props.isSelected ? 'inherit' : colors.captureBlue50};
    }
`;

const UnlimitedStorageInfo = ProductRow.extend`
    background-color: rgba(${colors.captureBlueRGB}, 0.04);
    border-bottom: 0px;
    font-weight: bold;
    background-repeat: no-repeat;
    background-position: center 25%;
    background-size: auto;
`;
const TelenorAbbUnlimited = styled.div`
    text-align: left;
    margin: 0 24px 0 0;

    ${(props: {isMobile: boolean}) => props.isMobile ? `
        grid-column: 2/4;
        margin: 0 0 0 8px;
    ` : ''}
`;
const GetStartedBtn = styled.div`
    width: 116px;
    ${(props: {isMobile: boolean}) => props.isMobile ? `
        grid-column: 1/4;
        margin-top: 8px;
    ` : ''}
`;

const UnlimitedStorageLowerArea = styled.div`
    font-size: ${fontSize.small_12};
    color: ${colors.captureGrey400};
    text-align: center;
    margin: 8px;
`;

const PlanLowerText = styled.div`
    width: 100%;
    margin: 0 auto;
    font-size: 12px;
    padding-top: 4px;
    color: ${colors.captureGrey500};
`;

type PlanSectionProps = {
    price: string,
    isMobile: boolean,
    lowerText?: string,
    isSelected: boolean,
    canPurchase: boolean,
    onClick?: () => any,
};

const PlanSection: React.SFC<PlanSectionProps> = (props) => {
    const SubscriptionPlan = props.canPurchase ? AvailablePlan : UnavailablePlan;
    return (
        <div>
            <SubscriptionPlan
                onClick={props.onClick}
                isMobile={props.isMobile}
                isSelected={props.isSelected}
            >
                {props.price}
            </SubscriptionPlan>
            <PlanLowerText>{props.lowerText}</PlanLowerText>
        </div>
    );
};

type ProductProps = {
    group: PlanGroup,
    isMobile: boolean,
    selectedProductID?: string,
    onClickPlan: (plan: CaptureStripeProduct) => any,
};

class Product extends React.Component<ProductProps> {
    private handleMonthlyProductClicked = () => {
        this.props.onClickPlan(this.props.group.monthly);
    }

    private handleYearlyProductClicked = () => {
        this.props.onClickPlan(this.props.group.yearly);
    }

    public render() {
        const {selectedProductID, isMobile} = this.props;
        const {monthly, yearly, size} = this.props.group;
        const [monthlySelected, yearlySelected] = [
            monthly.id === selectedProductID,
            yearly.id === selectedProductID,
        ];

        const discount = Math.round(100 * ((monthly.price * 12) - yearly.price) / (monthly.price * 12));
        const yearlyDiscount = _('save__format').replace('%d', discount.toString()) + '%';

        return (
            <ProductRow isMobile={isMobile}>
                <ProductSize
                    color={monthlySelected || yearlySelected ? colors.captureBlue : 'inherit'}
                >
                    {size} GB
                </ProductSize>
                <PlanSection
                    price={`NOK ${monthly.price / 100},-`}
                    onClick={monthlySelected ? undefined : this.handleMonthlyProductClicked}
                    lowerText={monthlySelected ? _('active') : undefined}
                    isMobile={isMobile}
                    isSelected={monthlySelected}
                    canPurchase={monthly.isPurchasable}
                />
                <PlanSection
                    price={`NOK ${yearly.price / 100},-`}
                    onClick={yearlySelected ? undefined : this.handleYearlyProductClicked}
                    lowerText={yearlySelected ? _('active') : yearlyDiscount}
                    isMobile={isMobile}
                    isSelected={yearlySelected}
                    canPurchase={yearly.isPurchasable}
                />
            </ProductRow>
        );
    }
}

const navigateToTelenorMobilePlans = () => window.location.href = TELENOR_MOBILE_PLANS_LINK;

type Props = {
    planGroups: PlanGroup[],
    currentPlan?: StoragePlan,
    isMobile: boolean,
    onPlanSelection: (plan: CaptureStripeProduct) => any,
};

export class BuyMoreStorage extends React.Component<Props> {
    private getPlanGroupElem = (planGroup: PlanGroup) => {
        const selectedID = this.props.currentPlan && this.props.currentPlan.id;
        return (
            <Product
                key={'prod_' + planGroup.size}
                group={planGroup}
                isMobile={this.props.isMobile}
                selectedProductID={selectedID}
                onClickPlan={this.props.onPlanSelection}
            />
        );
    }

    public render() {
        const telenorPropeller = require('../../assets/img/telenor_propeller_small.png');
        const unlimitedStorageInfo = (
            <>
                <UnlimitedStorageInfo
                    style={{backgroundImage: 'url(' + telenorPropeller + ')'}}
                    isMobile={this.props.isMobile}
                >
                    <div><InfiniteIconSmall width={71} height={32}/></div>
                    <TelenorAbbUnlimited isMobile={this.props.isMobile}>
                        {_('telenor_unlimited_hook__format').replace('%product_name%', PRODUCT_NAME) + '*'}
                    </TelenorAbbUnlimited>
                    <GetStartedBtn isMobile={this.props.isMobile}>
                        <FilledTextButton
                            onClick={navigateToTelenorMobilePlans}
                            fillColor={colors.captureBlue}
                            text={_('get_started')}
                        />
                    </GetStartedBtn>
                </UnlimitedStorageInfo>
                <UnlimitedStorageLowerArea>*{_('telenor_unlimited_hook_subtext')}</UnlimitedStorageLowerArea>
            </>
        );

        return (
                <BuyMoreWrapper>
                    <ProductRow isMobile={this.props.isMobile}>
                        <div>{_('storage')}</div>
                        <div>{_('monthly_sub')}</div>
                        <div>{_('yearly_sub')}</div>
                    </ProductRow>
                    {this.props.planGroups.map(this.getPlanGroupElem)}
                    {unlimitedStorageInfo}
                </BuyMoreWrapper>
        );
    }
}
