import {TimelineMonth} from '../../API/services/AppService';
import {createActionCreator, createVoidActionCreator} from '../common/actions';
import {TimelineFilterMode} from './reducers';

type TimelineMonthsFetchedPayload = {
    jobID: JobID,
    months: TimelineMonth[],
};

export const TimelineMonthsFetched = createActionCreator<TimelineMonthsFetchedPayload>('TIMELINE_MONTHS_FETCHED');

export const TimelineFilterSet = createActionCreator<TimelineFilterMode>('TIMELINE_FILTER_SET');

export const TimelineFileSelected = createActionCreator<FileID>('TIMELINE_FILE_SELECTED');
export const TimelineFileDeSelected = createActionCreator<FileID>('TIMELINE_FILE_DE_SELECTED');
export const TimelineFileSelectingStarted = createVoidActionCreator('TIMELINE_FILE_SELECTING_STARTED');
export const TimelineFileSelectingCanceled = createVoidActionCreator('TIMELINE_FILE_SELECTING_CANCELED');

export type SelectionAction = 'DELETE';

export const TimelineSelectionActionInitiated =
    createActionCreator<SelectionAction>('TIMELINE_SELECTION_ACTION_INITIATED');
export const TimelineSelectionActionConfirmHandled =
    createVoidActionCreator('TIMELINE_SELECTION_ACTION_CONFIRM_HANDLED');

export const DeleteSelectedFilesInitiated = () => TimelineSelectionActionInitiated('DELETE');
