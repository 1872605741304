import * as React from 'react';

type Ext = {onClickOutside?: () => any};
export const withClickOutsideHandling = <T extends {}>(Inner: React.ComponentType<T>): React.ComponentClass<T & Ext> => {
    return class HandleClickOutside extends React.Component<T & Ext> {
        private element = React.createRef<HTMLDivElement>();

        private handleClickOutsideMenu = (event: any) => {
            if (this.props.onClickOutside && this.element.current && !this.element.current.contains(event.target)) {
                this.props.onClickOutside();
            }
        }
        public componentDidMount() {
            document.addEventListener('click', this.handleClickOutsideMenu);
            document.addEventListener('touchend', this.handleClickOutsideMenu);
        }
        public componentWillUnmount() {
            document.removeEventListener('click', this.handleClickOutsideMenu);
            document.removeEventListener('touchend', this.handleClickOutsideMenu);
        }

        public render() {
            return (
                <div ref={this.element}>
                    <Inner {...this.props} />
                </div>
            );
        }
    };
};
