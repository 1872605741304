import * as React from 'react';
import {hot} from 'react-hot-loader';
import {Provider} from 'react-redux';
import {Router} from 'react-router';
import {syncHistoryWithStore} from 'react-router-redux';
import {Store} from 'redux';
import {getBrowserHistory} from '../../routing';

type RouterBasedAppProps = {
    router: Router,
};

export const createRouterBasedApp = <S extends {}>(store: Store<S>): React.SFC<RouterBasedAppProps> => {
    const history = syncHistoryWithStore(getBrowserHistory(), store);

    return (props) => {
        const App = () => (
            <Provider store={store}>
                <props.router history={history} />
            </Provider>
        );

        const RootNode = __DEV__ ? hot(module)(App) : App;

        return <RootNode />;
    };
};
