import {inArray} from '../../utilities/arrayUtils';
import {compareMonth} from '../../utilities/dateOperations';
import {makeWhitelist} from '../../utilities/inputValidation';
import {Action, isType} from '../common/actions';
import {
    AllJobFilesWasFetched, FetchedDefaultJob,
    FetchedLastSerialOfDefaultJob, FileRangeFetchFailed,
    FileRangeFetchingStarted, FileRangeWasFetched,
    FileWasRemovedFromJob,
} from '../job/actions';
import {
    SelectionAction, TimelineFileDeSelected,
    TimelineFileSelected, TimelineFileSelectingCanceled,
    TimelineFileSelectingStarted, TimelineFilterSet,
    TimelineMonthsFetched,
    TimelineSelectionActionConfirmHandled,
    TimelineSelectionActionInitiated,
} from './actions';

export enum MonthFetchStatus {
    UNFETCHED,
    FETCHING,
    FETCHED,
}
export type TimelineMonth =  {
    month: number,
    year: number,
    count: number,
    countGrouped?: {
        images: number,
        videos: number,
        screenshots: number,
    }
    fetchStatus: MonthFetchStatus,
};

export type TimelineFilterMode = 'all' | 'only_images' | 'only_videos' | 'only_screenshots';

export const asTimelineFilterValue = makeWhitelist<TimelineFilterMode>(
    ['all', 'only_images', 'only_videos', 'only_screenshots'],
);

export type TimelineState = {
    timelineJob?: JobID,
    initialLastChange: number,
    allFilesFetched: boolean,
    months?: TimelineMonth[],
    filter: TimelineFilterMode,
    isSelectMode: boolean,
    selectedFiles: FileID[],
    selectionAction?: SelectionAction,
};

const initialState: TimelineState = {
    initialLastChange: -1,
    allFilesFetched: false,
    filter: 'all',
    isSelectMode: false,
    selectedFiles: [],
    selectionAction: undefined,
};

const statuses = {
    [FileRangeFetchingStarted.type]: MonthFetchStatus.FETCHING,
    [FileRangeWasFetched.type]: MonthFetchStatus.FETCHED,
    [FileRangeFetchFailed.type]: MonthFetchStatus.UNFETCHED,
};
const updateStateWithFileRangeAction = (
    state: TimelineState,
    action: Action<any>,
): TimelineState => {
    if (state.months && !state.allFilesFetched) {
        return {
            ...state,
            months: state.months.map((m) => {
                if (compareMonth(m, action.payload.start) >= 0 && compareMonth(m, action.payload.end) <= 0) {
                    return {...m, fetchStatus: statuses[action.type] || MonthFetchStatus.UNFETCHED};
                }
                return m;
            }),
        };

    }

    return state;
};

export function timelineReducer(state: TimelineState = initialState, action: Action<any>): TimelineState {
    if (isType(action, FetchedDefaultJob)) {
        return {...state, timelineJob: action.payload};
    }

    if (isType(action, FetchedLastSerialOfDefaultJob)) {
        return {...state, initialLastChange: action.payload};
    }

    if (isType(action, TimelineMonthsFetched) && state.timelineJob === action.payload.jobID) {
        return {
            ...state,
            months: action.payload.months.map(({year, month, count, picture_count, screenshot_count, video_count}) => {
                // Todo: remove if-check and make countGrouped requred when production backend returns count-groups
                let countGrouped;
                if (picture_count !== undefined) {
                    countGrouped = {
                        images: picture_count || 0,
                        videos: video_count || 0,
                        screenshots: screenshot_count || 0,
                    };
                }
                return {
                    year, month, count, countGrouped,
                    fetchStatus: state.allFilesFetched ? MonthFetchStatus.FETCHED : MonthFetchStatus.UNFETCHED,
                };
            }),
        };
    }

    if (isType(action, TimelineFilterSet)) {
        return {...state, filter: action.payload};
    }

    if (isType(action, TimelineFileSelected)) {
        const selectedFiles = [...state.selectedFiles, action.payload];
        return {...state, isSelectMode: true, selectedFiles};
    }

    if (isType(action, TimelineFileDeSelected)) {
        const selectedFiles = state.selectedFiles.filter((id) => id !== action.payload);
        return {...state, isSelectMode: selectedFiles.length !== 0, selectedFiles};
    }

    if (isType(action, TimelineFileSelectingStarted)) {
        return {...state, isSelectMode: true};
    }

    if (isType(action, TimelineFileSelectingCanceled)) {
        return {...state, isSelectMode: false, selectedFiles: []};
    }

    if (isType(action, TimelineSelectionActionInitiated)) {
        return {...state, selectionAction: action.payload};
    }

    if (isType(action, TimelineSelectionActionConfirmHandled)) {
        return {...state, isSelectMode: false, selectedFiles: [], selectionAction: undefined};
    }

    if (isType(action, FileRangeFetchingStarted)
        || isType(action, FileRangeWasFetched)
        || isType(action, FileRangeFetchFailed)
    ) {
        return updateStateWithFileRangeAction(state, action);
    }

    if (isType(action, AllJobFilesWasFetched)
        && state.timelineJob === action.payload.jobID
        && state.initialLastChange <= action.payload.lastEvent
    ) {
        const months = state.months && state.months.map((m) => ({...m, fetchStatus: MonthFetchStatus.FETCHED}));
        return {
            ...state,
            allFilesFetched: true,
            months,
        };
    }

    if (isType(action, FileWasRemovedFromJob) && inArray(state.selectedFiles, action.payload.fileID)) {
        const selectedFiles = state.selectedFiles.filter((id) => id !== action.payload.fileID);
        return {...state, isSelectMode: selectedFiles.length !== 0, selectedFiles};
    }

    return state;
}
