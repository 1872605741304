import {createSelector} from 'reselect';
import {_} from '../../assets/localizedStrings';
import {inArray} from '../../utilities/arrayUtils';
import {getCreatorOfJob, getJobInformation, getProvidedPassword} from '../jobInfo/selectors';
import {State, StateWithCurrentUserState} from '../store';
import {getUsersInfo, UserInfo} from '../users/selectors';

export const isLoggedIn = (state: StateWithCurrentUserState) => state.currentUser.isLoggedIn;
export const isLoggingIn = (state: StateWithCurrentUserState) => state.currentUser.isLoggingIn;

export const getUsedStorage = (state: StateWithCurrentUserState) => {
    if (state.currentUser.accountInfo && state.currentUser.accountInfo.type === 'capture') {
        return state.currentUser.accountInfo.used_space;
    } else {
        return 0;
    }
};
export const getMaxStorage = (state: StateWithCurrentUserState) => {
    if (state.currentUser.accountInfo && state.currentUser.accountInfo.type === 'capture') {
        // for users with unlimited storage the available storage is set to 1002GB by the backend
        const hasUnlimited = state.currentUser.accountInfo.max_space >= Math.pow(1024, 3) * 1002;
        return hasUnlimited ? Infinity : state.currentUser.accountInfo.max_space;
    } else {
        return 0;
    }
};
export const getUnusedStorage = (state: StateWithCurrentUserState) => getMaxStorage(state) - getUsedStorage(state);
export const getTotalPendingFileSize = (state: StateWithCurrentUserState) => {
    return Object.keys(state.currentUser.fileSizesPending)
                .reduce((sum, key) => sum + (state.currentUser.fileSizesPending[parseInt(key, 10)] || 0), 0);
};
export const getAvailableStorage = (state: StateWithCurrentUserState) => getUnusedStorage(state) - getTotalPendingFileSize(state);
export const isOutOfStorage = (state: StateWithCurrentUserState) => {
    return state.currentUser.accountInfo !== undefined
        && state.currentUser.accountInfo.type === 'capture'
        && getMaxStorage(state) <= getUsedStorage(state);
};
export const getUsedStorageRatio = (state: StateWithCurrentUserState) => getUsedStorage(state) / getMaxStorage(state);

export const getConnectedDevices = (state: StateWithCurrentUserState) => state.currentUser.connectedDevices;

export const getUUIDOfLoggedInUser = (state: StateWithCurrentUserState): UserID|undefined => {
    if (state.currentUser.accountInfo) {
        return state.currentUser.accountInfo.uuid;
    }
};
export const getEmailAdressOfLoggedInUser = (state: StateWithCurrentUserState): string|undefined => {
    if (state.currentUser.accountInfo && state.currentUser.accountInfo.type === 'capture') {
        return state.currentUser.accountInfo.logged_in_as;
    }
};

export const hasAnonymousUserInfo = (state: StateWithCurrentUserState): boolean => {
    return state.currentUser.accountInfo !== undefined && state.currentUser.accountInfo.type === 'anonymous';
};
export const getUUIDOfAnonymousUser = (state: StateWithCurrentUserState): UserID|undefined => {
    if (state.currentUser.accountInfo !== undefined && state.currentUser.accountInfo.type === 'anonymous') {
        return state.currentUser.accountInfo.uuid;
    }
};
export const getCurrentUserUUID = createSelector(
    getUUIDOfLoggedInUser,
    getUUIDOfAnonymousUser,
    (loggedInUser: UserID|undefined, anonymousUser: UserID|undefined) => {
        return loggedInUser || anonymousUser;
    },
);

export const mustProvideUserInfo = (state: StateWithCurrentUserState): boolean => {
    return state.currentUser.userInfoRequired.isRequired
        && !isLoggedIn(state)
        && !hasAnonymousUserInfo(state);
};

export const getProvideUserInfoReason = (state: StateWithCurrentUserState): string|undefined => {
    return state.currentUser.userInfoRequired.reason;
};

export const isJobCreatedByCurrentUser = (state: State, job: JobID): boolean|undefined => {
    const info = getJobInformation(state, job);
    if (info && info.type === 'timeline') {
        return true;
    }
    const creator = getCreatorOfJob(state, job);
    if (creator !== undefined) {
        return creator === getCurrentUserUUID(state);
    }
};

export const getAuthTokenForJob = (state: State, job: JobID): string => {
    // If the job required password and the user have provided one: Use that as auth token
    const providedPassword = getProvidedPassword(state, job);
    if (providedPassword !== undefined) {
        return providedPassword;
    }

    // Authenticate as logged in user unless we know the job is created by another user
    if (isJobCreatedByCurrentUser(state, job) !== false && state.currentUser.accountInfo) {
        return state.currentUser.accountInfo.auth_token;
    }

    return '';
};

export const getCurrentAuthToken = (state: StateWithCurrentUserState): string|undefined => {
    return state.currentUser.accountInfo ? state.currentUser.accountInfo.auth_token : undefined;
};

// ForeignAuth is used when the current user is not the owner of the job
// and is required to perform write-operations on the job
export const getForeignAuthForJob = (state: State, job: JobID): string|undefined => {
    if (isJobCreatedByCurrentUser(state, job) === false) {
        if (state.currentUser.accountInfo) {
            return state.currentUser.accountInfo.auth_token;
        }
    }
};

export const getSubscribedJobs = (state: StateWithCurrentUserState): JobID[] => (
    state.currentUser.subscribedJobs
);

export const isAlbumListReady = (state: State): boolean => state.currentUser.haveFetchedAlbumList;

export const isCurrentUserSubscribedToJob = (state: State, job: JobID): boolean => {
    const isOwnJob = isJobCreatedByCurrentUser(state, job);
    const subscribedJobs = getSubscribedJobs(state);

    return isOwnJob || inArray(subscribedJobs, job);
};

export const getCurrentUserInfo = (state: State): UserInfo|undefined => {
    const accountInfo = state.currentUser.accountInfo;

    if (accountInfo) {
        const userInfo = getUsersInfo(state)[accountInfo.uuid];
        if (userInfo) {
            return {
                userID: accountInfo.uuid,
                name: accountInfo.name,
                email: userInfo.email,
                profilePicture: userInfo.profilePicture,
            };
        }

        return {
            userID: accountInfo.uuid,
            name: accountInfo.name,
        };
    }
};

export const isCurrentUserBeingShutDown = (state: State): boolean => {
    return state.currentUser.accountInfo !== undefined
        && state.currentUser.accountInfo.type === 'capture'
        && state.currentUser.accountInfo.isBeingShutDown === true;
};

export const getLastVersionSeen = (state: State): number|undefined  => {
    return state.currentUser.lastWebVersionSeen;
};

export type VersionNote = {
    header: string,
    notes: string[],
};

export const versionNotes: VersionNote[] = [
    {
        header: _('releaseNotes_v0_header'),
        notes: [
            _('releaseNotes_v0_p1'),
            _('releaseNotes_v0_p2'),
            _('releaseNotes_v0_p3'),
            _('releaseNotes_v0_p4'),
            _('releaseNotes_v0_p5'),
        ],
    },
];

export const currentAppVersion = versionNotes.length - 1;

export const getVersionNotes = createSelector(
    getLastVersionSeen,
    (lastVersionSeen: number|undefined) => {
        if (lastVersionSeen === undefined || lastVersionSeen === currentAppVersion) {
            return [];
        }

        return versionNotes.slice(lastVersionSeen + 1);
    },
);

export const getPrintUserTargetJob = (state: State): JobID|undefined => {
    if (state.currentUser.accountInfo && state.currentUser.accountInfo.type === 'print') {
        return state.currentUser.accountInfo.targetJob;
    }
};
