import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const ViewModeGridIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path fill={props.color || colors.defaultIconColor} fillRule="nonzero" d="M4,11.4615385 L8.70588235,11.4615385 L8.70588235,5 L4,5 L4,11.4615385 Z M4,19 L8.70588235,19 L8.70588235,12.5384615 L4,12.5384615 L4,19 Z M9.64705882,19 L14.3529412,19 L14.3529412,12.5384615 L9.64705882,12.5384615 L9.64705882,19 Z M15.2941176,19 L20,19 L20,12.5384615 L15.2941176,12.5384615 L15.2941176,19 Z M9.64705882,11.4615385 L14.3529412,11.4615385 L14.3529412,5 L9.64705882,5 L9.64705882,11.4615385 Z M15.2941176,5 L15.2941176,11.4615385 L20,11.4615385 L20,5 L15.2941176,5 Z" />
        </g>
    </svg>
);
export const ViewModeFlowIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="nonzero">
            <path fill={props.color || colors.defaultIconColor}  d="M4,19 L20,19 L20,12.5384615 L4,12.5384615 L4,19 Z M4,5 L4,11.4615385 L20,11.4615385 L20,5 L4,5 Z" />
        </g>
    </svg>
);
