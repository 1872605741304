import * as React from 'react';

export const SadFaceIcon: React.SFC<{}> = () => {

    return (
        <svg width="38px" height="38px" viewBox="0 0 38 38" version="1.1">
            <g id="Receiver-pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Can't-display-image" transform="translate(-3.000000, -3.000000)">
                    <polygon id="Shape" points="0 0 44 0 44 44 0 44"></polygon>
                    <path d="M21.9816667,3.66666667 C11.8616667,3.66666667 3.66666667,11.88 3.66666667,22 C3.66666667,32.12 11.8616667,40.3333333 21.9816667,40.3333333 C32.12,40.3333333 40.3333333,32.12 40.3333333,22 C40.3333333,11.88 32.12,3.66666667 21.9816667,3.66666667 Z M22,36.6666667 C13.8966667,36.6666667 7.33333333,30.1033333 7.33333333,22 C7.33333333,13.8966667 13.8966667,7.33333333 22,7.33333333 C30.1033333,7.33333333 36.6666667,13.8966667 36.6666667,22 C36.6666667,30.1033333 30.1033333,36.6666667 22,36.6666667 Z M28.4166667,20.1666667 C29.9383333,20.1666667 31.1666667,18.9383333 31.1666667,17.4166667 C31.1666667,15.895 29.9383333,14.6666667 28.4166667,14.6666667 C26.895,14.6666667 25.6666667,15.895 25.6666667,17.4166667 C25.6666667,18.9383333 26.895,20.1666667 28.4166667,20.1666667 Z M15.5833333,20.1666667 C17.105,20.1666667 18.3333333,18.9383333 18.3333333,17.4166667 C18.3333333,15.895 17.105,14.6666667 15.5833333,14.6666667 C14.0616667,14.6666667 12.8333333,15.895 12.8333333,17.4166667 C12.8333333,18.9383333 14.0616667,20.1666667 15.5833333,20.1666667 Z M22,22.8333333 C26.2716667,22.8333333 29.9016667,25.51 31.3683333,29.25 L12.6316667,29.25 C14.0983333,25.51 17.7283333,22.8333333 22,22.8333333 Z"
                        id="Shape" fill="#FFFFFF"></path>
                </g>
            </g>
        </svg>
    );
};
