import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {trackEvent} from '../../analytics/eventTracking';
import {downloadAlbum} from '../../API/album';
import {copyAlbumToTimeline} from '../../API/job';
import {loginAndGoTo} from '../../API/login';
import {_} from '../../assets';
import {colors} from '../../assets/styleConstants';
import {Pages} from '../../routing';
import {AlbumCarousel} from '../../routing/pages';
import {NavigateTo, State} from '../../state';
import {
    AlbumDeletionInitiated,
    AlbumSharingInitiated,
    AlbumUnsubscriptionInitiated,
    EditAlbumSettingsInitiated,
    ShowAlbumSortModePrompt,
} from '../../state/album/actions';
import {AlbumViewMode, getAlbumViewMode} from '../../state/album/selectors';
import {AvailableActions} from '../../state/albumList/selectors';
import {FullscreenModeEntered} from '../../state/carouselViewer/actions';
import {isLoggedIn} from '../../state/currentUser/selectors';
import {guaranteeAUser} from '../../state/currentUser/UserExistenceGuarantee';
import {AlbumViewModeChanged} from '../../state/job/actions';
import {getConnectedInstance} from '../../state/uploader/uploadQueue';
import {isLargerThanTablet, isMobileMode} from '../../state/viewMode/selectors';
import {withoutTheBools} from '../../utilities/arrayUtils';
import {isMobileDevice} from '../../utilities/device';
import {isFullscreenEnabled} from '../../utilities/fullscreen';
import {Button, ButtonProps} from '../Common/Button';
import {IconButton, IconTextButton, TextButton} from '../Common/IconTextButton';
import {OverflowMenu} from '../Common/OverflowMenu';
import {AddIcon} from '../Icons/AddIcon';
import {BackArrowIcon} from '../Icons/BackArrowIcon';
import {BookIcon} from '../Icons/BookIcon';
import {CancelIcon} from '../Icons/CancelIcon';
import {CaptureIcon, CaptureLogoHorizontal} from '../Icons/CaptureLogo';
import {DeleteIcon} from '../Icons/DeleteIcon';
import {DownloadIcon} from '../Icons/DownloadIcon';
import {FullscreenIcon} from '../Icons/FulscreenIcon';
import {GearIcon} from '../Icons/GearIcon';
import {PlusIcon} from '../Icons/PlusIcon';
import {ShareIcon} from '../Icons/ShareIcon';
import {SortIcon} from '../Icons/SortIcon';
import {ViewModeFlowIcon, ViewModeGridIcon} from '../Icons/ViewModeIcon';
import {TwoAreasTopNavBar} from '../Navigation/TwoAreasTopNavBar';
import {InvisibleFileInput} from '../Uploader/InvisibleFileInput';

const ButtonElementLeft = styled.div`
    display: inline-block;
`;
const NavElementRight = styled.div`
    margin-left: ${(props: {isMobile: boolean}) => props.isMobile ? 24 : 32}px;
`;
const ButtonWrapper = styled.div`
    text-transform: uppercase;
    font-weight: bold;
    color: ${colors.captureGrey600};
`;

type OwnProps = {
    albumID: JobID;
    firstAlbumFileID: FileID;
    isSharedAlbum: boolean;
    currentUserCan: AvailableActions;
};

type StateProps = {
    isLoggedIn: boolean;
    isMobileMode: boolean;
    showButtonText: boolean;
    viewMode: AlbumViewMode;
};

type DispatchProps = {
    goToRequireLoginCreateAlbum: () => any;
    loginAndGoToAlbums: () => any;
    doCopyToTimeline: (targetAlbumID: JobID) => any;
    doDownloadAlbum: () => any;
    navigateToAlbumListPage: () => void;
    onUnsubscribeInitiate: () => any;
    onDeleteInitiate: () => any;
    onShareInitiate: () => any;
    onEditSettingsInitiate: () => any;
    onSortAlbumFilesInitiate: () => any;
    doEnterSlideShow: () => any;
    addPhotosFromTimeline: () => any;
    changeAlbumViewMode: (viewMode: AlbumViewMode) => any;
    goToRequireLoginCopyAlbum: () => any;
};

type Props = OwnProps & StateProps & DispatchProps;

type ComponentState = {showHeader: boolean};

class _AlbumReceiverTopNav extends React.Component<Props, ComponentState> {
    public state: ComponentState = {showHeader: true};

    private fileInput: InvisibleFileInput|null = null;

    private addPhotos = () => {
        trackEvent('AlbumPage', 'AddPhotosButton_click');
        if (this.props.isLoggedIn) {
            this.props.addPhotosFromTimeline();
        }
        else if (this.fileInput) {
            this.fileInput.triggerFileDialog();
        }
    }

    private notLoggedInCreation = () => {
        trackEvent('AlbumPage', 'CreateAlbumButton_click');
        this.props.goToRequireLoginCreateAlbum();
    }

    private handleFiles = async (files: File[]) => {
        await guaranteeAUser();
        getConnectedInstance().addFiles(files, this.props.albumID);
    }
    private copyToTimeline = () => {
        if (this.props.isLoggedIn) {
            this.props.doCopyToTimeline(this.props.albumID);
        } else {
            this.props.goToRequireLoginCopyAlbum();
        }
    }
    private downloadAlbum = () => {
        trackEvent('AlbumPage', 'DownloadAlbumButton_click');
        this.props.doDownloadAlbum();
    }
    private changeViewMode = () => {
        if (this.props.viewMode === 'flow') {
            this.props.changeAlbumViewMode('grid');
        } else {
            this.props.changeAlbumViewMode('flow');
        }
    }

    private bindFileInput = (me: InvisibleFileInput|null) => {
        this.fileInput = me;
    }

    private getContentLeft = () => {
        if (this.props.isLoggedIn) {
            return (
                <ButtonElementLeft>
                    <IconTextButton
                        data-cy="backToAlbums"
                        onClick={this.props.navigateToAlbumListPage}
                        text={_('albums')}
                        icon={BackArrowIcon}
                    />
                </ButtonElementLeft>
            );
        } else {
            return this.props.isMobileMode ? (
                <CaptureIcon size={36} />
            ) : (
                <CaptureLogoHorizontal />
            );
        }
    }

    private getOverflowMenuItems = (): ButtonProps[] => {
        const viewModeIcon =
            this.props.viewMode === 'flow'
                ? ViewModeGridIcon
                : ViewModeFlowIcon;
        return withoutTheBools([
            this.props.isSharedAlbum &&
                !this.props.isLoggedIn &&
                Button(_('share_album'), this.props.onShareInitiate, {
                    icon: ShareIcon,
                }),
            this.props.currentUserCan.editAlbum &&
                Button(_('settings'), this.props.onEditSettingsInitiate, {
                    icon: GearIcon,
                }),
            Button(_('sort_album_files'), this.props.onSortAlbumFilesInitiate, {
                icon: SortIcon,
            }),
            (isMobileDevice.any() || isFullscreenEnabled()) &&
                Button(_('slideshow'), this.props.doEnterSlideShow, {
                    icon: FullscreenIcon,
                }),
            !isMobileDevice.any() &&
                Button(_('download_album'), this.downloadAlbum, {
                    icon: DownloadIcon,
                }),
            Button(_('add_album_to_timeline'), this.copyToTimeline, {
                icon: AddIcon,
            }),
            this.props.currentUserCan.deleteAlbum &&
                Button(_('delete_album'), this.props.onDeleteInitiate, {
                    icon: DeleteIcon,
                }),
            this.props.currentUserCan.leaveAlbum &&
                Button(_('leave_album'), this.props.onUnsubscribeInitiate, {
                    icon: CancelIcon,
                }),
            Button(_('change_album_viewMode'), this.changeViewMode, {
                icon: viewModeIcon,
            }),
        ]);
    }

    private getContentRight = (): React.ReactNode[] => {
        const TextOrIconButton = this.props.showButtonText
            ? TextButton
            : IconButton;

        return withoutTheBools([
            !this.props.isLoggedIn && (
                <NavElementRight
                    data-cy="newAlbum"
                    key="newAlbum"
                    isMobile={this.props.isMobileMode}
                >
                    <ButtonWrapper>
                        <TextOrIconButton
                            onClick={this.notLoggedInCreation}
                            text={_('create_album')}
                            icon={BookIcon}
                            hoverColor={colors.captureBlue}
                        />
                    </ButtonWrapper>
                </NavElementRight>
            ),
            this.props.currentUserCan.addPhotos && (
                <NavElementRight
                    data-cy="addPhotos"
                    key="addPhotos"
                    isMobile={this.props.isMobileMode}
                >
                    <ButtonWrapper>
                        <TextOrIconButton
                            onClick={this.addPhotos}
                            text={_('add_photos')}
                            icon={PlusIcon}
                            hoverColor={colors.captureBlue}
                        />
                    </ButtonWrapper>
                </NavElementRight>
            ),
            this.props.currentUserCan.addPhotos && (
                <InvisibleFileInput
                    key="fileInput"
                    ref={this.bindFileInput}
                    fileHandler={this.handleFiles}
                />
            ),
            this.props.isLoggedIn &&
                this.props.isSharedAlbum && (
                    <NavElementRight
                        data-cy="shareAlbum"
                        key="shareAlbum"
                        isMobile={this.props.isMobileMode}
                    >
                        <ButtonWrapper>
                            <TextOrIconButton
                                onClick={this.props.onShareInitiate}
                                text={_('share_album')}
                                icon={ShareIcon}
                                hoverColor={colors.captureBlue}
                            />
                        </ButtonWrapper>
                    </NavElementRight>
                ),
            <NavElementRight
                data-cy="overflowNav"
                key="overflow"
                isMobile={this.props.isMobileMode}
            >
                <OverflowMenu
                    menuOptions={this.getOverflowMenuItems()}
                    hoverColor={colors.captureBlue}
                />
            </NavElementRight>,
        ]);
    }

    public render() {
        return (
            <TwoAreasTopNavBar
                left={this.getContentLeft()}
                right={this.getContentRight()}
            />
        );
    }
}
const stateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    isLoggedIn: isLoggedIn(state),
    isMobileMode: isMobileMode(state),
    showButtonText: isLargerThanTablet(state),
    viewMode: getAlbumViewMode(state, ownProps.albumID),
});

const dispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
): DispatchProps => ({
    goToRequireLoginCreateAlbum: () =>
        dispatch(NavigateTo(Pages.CreateAlbumRequireLogin(ownProps.albumID))),
    loginAndGoToAlbums: () => loginAndGoTo(Pages.Albums),
    doCopyToTimeline: (targetAlbumID: JobID) =>
        copyAlbumToTimeline(dispatch, targetAlbumID),
    doDownloadAlbum: () => downloadAlbum(dispatch, ownProps.albumID),
    navigateToAlbumListPage: () => dispatch(NavigateTo(Pages.Albums)),
    onDeleteInitiate: () => dispatch(AlbumDeletionInitiated(ownProps.albumID)),
    onUnsubscribeInitiate: () =>
        dispatch(AlbumUnsubscriptionInitiated(ownProps.albumID)),
    onShareInitiate: () => dispatch(AlbumSharingInitiated(ownProps.albumID)),
    onEditSettingsInitiate: () =>
        dispatch(EditAlbumSettingsInitiated(ownProps.albumID)),
    onSortAlbumFilesInitiate: () =>
        dispatch(ShowAlbumSortModePrompt(ownProps.albumID)),
    doEnterSlideShow: () => {
        dispatch(NavigateTo(AlbumCarousel(ownProps.albumID, ownProps.firstAlbumFileID)));
        dispatch(FullscreenModeEntered());
    },
    addPhotosFromTimeline: () =>
        dispatch(NavigateTo(Pages.AddMorePhotosToAlbum(ownProps.albumID))),
    changeAlbumViewMode: (viewMode: AlbumViewMode) => {
        dispatch(AlbumViewModeChanged({jobID: ownProps.albumID, viewMode}));
    },
    goToRequireLoginCopyAlbum: () =>
        dispatch(NavigateTo(Pages.CopyAlbumRequireLogin(ownProps.albumID))),
});

export const AlbumReceiverTopNav = connect(stateToProps, dispatchToProps)(
    _AlbumReceiverTopNav,
);
