import * as React from 'react';

type Props = {
    fileHandler: (files: File[]) => any;
    acceptTypes?: string;
};

export class InvisibleFileInput extends React.Component<Props, {}> {
    private input: HTMLInputElement|null = null;

    public triggerFileDialog = () => {
        if (this.input) {
            this.input.click();
        }
    }

    private handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const testFiles = e.nativeEvent && (e.nativeEvent as any).testFiles; // allow cypress e2e test
        const files = testFiles || e.target.files;
        if (files) {
            const fileArr: File[] = [];
            for (let i = 0, l = files.length; i < l; ++i) {
                fileArr.push(files[i]);
            }
            this.props.fileHandler(fileArr);
            e.target.value = '';
        }

        // If after the above reset of value, the input still have files, try harder to reset the element
        if (files && files.length && e.target.parentNode) {
            // workaround for IE 10 and lower, pre-webkit Opera

            const form = document.createElement('form');
            e.target.parentNode.insertBefore(form, e.target);

            form.appendChild(e.target);
            form.reset();

            if (form.parentNode) {
                form.parentNode.insertBefore(e.target, form);
            }
            e.target.parentNode.removeChild(form);
        }
    }

    private bindInput = (me: HTMLInputElement|null) => {
        this.input = me;
    }

    public render() {
        const fileInputStyle: React.CSSProperties = {
            width: 0.1,
            height: 0.1,
            overflow: 'hidden',
        };
        return (
            <input
                data-cy="invisibleFileInput"
                type="file"
                accept={
                    this.props.acceptTypes ||
                    'image/*, video/mp4, video/x-m4v, video/*'
                }
                ref={this.bindInput}
                style={fileInputStyle}
                onChange={this.handleFileChange}
                multiple={true}
            />
        );
    }
}
