import {
    applyMiddleware,
    compose,
    createStore,
    Middleware,
} from 'redux';
import RavenMiddleware from 'redux-raven-middleware';
import {analyticsMiddleware} from '../analytics/analyticsMiddleware';
import {createAutoGeneratedAlbum} from '../API/album';
import {connectServiceProvider} from '../API/HostProvider';
import {uploaderWithTimelineMirroring, uploadFile} from '../API/job';
import {connectSyncers} from '../API/syncers';
import {getBrowserHistory} from '../routing';
import {getAnalyticsSafeURL} from '../utilities/urlParsing';
import {localStorageGet} from '../utilities/webStorage';
import {autoAlbumMiddleware} from './album/autoAlbumMiddleware';
import {carouselViewMiddleware} from './carouselViewer/carouselMiddleware';
import {Action, isType} from './common/actions';
import {FetchedAnonymousUserInfo} from './currentUser/actions';
import {connectUserGuarantee} from './currentUser/index';
import {changesSyncerMiddleware} from './job/changesSyncerMiddleware';
import {popupMiddleware} from './popup/popupMiddleware';
import {NavigateTo, PageDescription} from './routing/actions';
import {routerMiddleware} from './routing/routerMiddleware';
import {mainReducer, State} from './store';
import {getTimelineJobID} from './timeline/selectors';
import {trashMiddleware} from './trash/trashMiddleware';
import {uploaderWithAlbumCreationSupport} from './uploader/AlbumAutoCreator';
import {
    checkFolder,
    connectUploadQueue,
    makeEnforceAvailableStorageCheck,
    makeFileTypeCheck,
    makeUploadFunction,
} from './uploader/uploadQueue';
import {ClientViewportSizeChanged} from './viewMode/actions';

type StoreConfig = {
    sentryDSN?: string;
};

export const makeStore = (
    config: StoreConfig = {},
) => {
    const middlewares: Middleware[] = [
        routerMiddleware(getBrowserHistory()),
        analyticsMiddleware,
        changesSyncerMiddleware,
        popupMiddleware,
        carouselViewMiddleware,
        autoAlbumMiddleware,
        trashMiddleware,
    ];

    // For production build, ravenMiddleware need to be insert first
    if (config.sentryDSN) {
        const ignoreErrors = [
            // Ignore this error as it's flooding our logs and is impossible to find #YOLO (#CAPTURE-ERRORS-AV)
            "Cannot read property 'innerHTML' of null",
            // Ignore ResizeObserver error since it is not standard specification which only supported by Chrome 64+,
            // and it does not break app
            'ResizeObserver loop limit exceeded',
        ];

        middlewares.unshift(
            RavenMiddleware(config.sentryDSN, {
                release: __VERSION__,
                ignoreErrors,
                whitelistUrls: [/web./, /staging./], // TODO: Remove once provisioning sets DSN for domains of interest
                dataCallback: (data: any) => ({ // obfuscate URLs with IDs
                    ...data,
                    request: {...data.request, url: getAnalyticsSafeURL(data.request.url)},
                }),
            }),
        );
    }

    const store = createStore(
        mainReducer,
        compose(
            applyMiddleware(...middlewares),
            window.devToolsExtension
                ? window.devToolsExtension()
                : (f: any) => f,
        ),
    );

    const doUpload = [
        uploaderWithTimelineMirroring(() => getTimelineJobID(store.getState())),
        uploaderWithAlbumCreationSupport((name, tempID) =>
            createAutoGeneratedAlbum(store.dispatch, name, tempID),
        ),
    ].reduce((p, m) => m(p), uploadFile);
    connectUploadQueue(
        store,
        makeUploadFunction(store.dispatch, doUpload),
        [checkFolder, makeFileTypeCheck()],
        [makeEnforceAvailableStorageCheck(store)],
    );
    connectServiceProvider(store);
    connectSyncers(store);
    connectUserGuarantee(store);

    window.addEventListener('resize', () =>
        store.dispatch(
            ClientViewportSizeChanged({
                width: document.body.clientWidth,
                height: window.innerHeight,
            }),
        ),
    );

    // If the user have created an anonymous user before, try to recover that from localStorage.
    const anonUserInfo = JSON.parse(
        localStorageGet('captureAnonymousUser') || '{}',
    );
    if (anonUserInfo.name && anonUserInfo.uuid && anonUserInfo.auth_token) {
        store.dispatch(FetchedAnonymousUserInfo(anonUserInfo));
    }

    if (__DEV__ && module.hot) {
        module.hot.accept('./store', () => {
            // Use require when module updates to make sure the updated module is used.
            store.replaceReducer(require('./store').mainReducer);
        });
    }

    return store;
};

export {State, Action, isType, PageDescription, NavigateTo};
