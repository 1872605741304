import * as React from 'react';
import styled from 'styled-components';
import {colors, fontSize} from '../../assets/styleConstants';
import {RadioButtonFilledIcon, RadioButtonIcon} from '../Icons/RadioButtonIcon';

const RadioButtonWrapper = styled.div`
    font-size: ${fontSize.small_14};
    color: ${colors.captureGrey800};

    display: flex;
    align-items: center;
    padding: 8px 0;
    cursor: pointer;

    &:hover {
        color: ${colors.captureBlue};
    }

    svg {
        margin-right: 12px;
    }
`;

export type RadioButtonOption<T> = {
    text: string,
    value: T,
};

type Props<T> = {
    value: T,
    options: Array<RadioButtonOption<T>>,
    onSelectOption: (newValue: T) => any,
};

export class RadioButtonGroup<T> extends React.Component<Props<T>> {
    public render() {
        const options = this.props.options.map((opt, index) => {
            const clickEvent = () => this.props.onSelectOption(opt.value);
            const RadioIcon = this.props.value === opt.value ? RadioButtonFilledIcon : RadioButtonIcon;
            return (
                <RadioButtonWrapper
                    key={index}
                    onClick={clickEvent}
                >
                    <RadioIcon size={16} />
                    {opt.text}
                </RadioButtonWrapper>
            );
        });

        return (
            <div>{options}</div>
        );
    }
}
