import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {PendingAlbum} from '../../state/album/selectors';
import {FileUploadStatus} from '../../state/uploader/reducer';
import {getConnectedInstance} from '../../state/uploader/uploadQueue';
import {GridStyle} from '../../utilities/gridElementSizeCalculator';
import {IconButton} from '../Common/IconTextButton';
import {VideoIndicator} from '../Common/VideoIndicator';
import {GridElement, GridElementIconContainerBottom, GridElementIconContainerTop} from '../GridView/GridElement';
import {CancelIcon} from '../Icons';
import {PlusIcon} from '../Icons/PlusIcon';

const FileListWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const AddMoreGridButton = GridElement.extend`
    border: dashed 2px ${colors.captureBlue};
    box-sizing: border-box;

    font-size: ${fontSize.large_24};
    color: ${colors.captureBlue};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    &:hover{
        background-color: white;
    }

    svg {
        margin-bottom: 4px;
    }
`;

const FileWrapper = styled(GridElement)`
    position: relative;

    ${(props: {uploaded: boolean}) => !props.uploaded ? `
        opacity: 0.4;
        filter: alpha(opacity=40);
    ` : ''}
`;

const IconContainerTop = GridElementIconContainerTop.extend`
    & > * {
        display: inline-block;
    }
`;

type PendingFileEntry = {
    url?: string,
    uploadIndex?: number,
    fileID?: FileID,
    uploaded: boolean,
    duration?: number,
};

type PendingFileProps = {
    file: PendingFileEntry,
    isMobile: boolean,
    removeFile: (fileID: FileID) => any,
    styling: GridStyle,
};

type PendingFileState = {
    thumbURL?: string,
};

class PendingFile extends React.Component<PendingFileProps, PendingFileState> {
    public state: PendingFileState = {};

    private doRemoveFile = () => {
        if (this.props.file.fileID) {
            this.props.removeFile(this.props.file.fileID);
        }
    }

    private updateUploadThumb = (uploadIndex: number|undefined) => {
        if (uploadIndex !== undefined) {
            getConnectedInstance()
                .getUploadFilePreviewThumb(uploadIndex)
                .then((thumbURL) => {
                    this.setState({...this.state, thumbURL});
                });
        } else {
            this.setState({...this.state, thumbURL: undefined});
        }
    }

    public componentDidMount() {
        this.updateUploadThumb(this.props.file.uploadIndex);
    }

    public componentWillReceiveProps(newProps: PendingFileProps) {
        if (newProps.file.uploadIndex !== this.props.file.uploadIndex) {
            this.updateUploadThumb(newProps.file.uploadIndex);
        }
    }

    public render() {
        const videoIndicator = this.props.file.duration && (
            <GridElementIconContainerBottom>
                <VideoIndicator duration={this.props.file.duration}/>
            </GridElementIconContainerBottom>
        );

        return (
            <FileWrapper
                style={{backgroundImage: `url('${this.props.file.url || this.state.thumbURL}')`}}
                uploaded={this.props.file.uploaded}
                elementWidth={this.props.styling.elementWidth}
                elementHeight={this.props.styling.elementHeight}
                elementSpaceAround={this.props.styling.elementSpaceAround}
            >
                <IconContainerTop>
                    <IconButton
                        onClick={this.doRemoveFile}
                        icon={CancelIcon}
                        iconSize={28}
                        color={'transparent'}
                        hoverColor={'rgba(' + colors.white_rgb + ', 0.2)'}
                    />
                </IconContainerTop>
                {videoIndicator}
            </FileWrapper>
        );
    }
}

type Props = {
    isMobile: boolean,
    onClickAddMore: () => any,
    onRemoveFile: (fileID: FileID) => any,
    pendingAlbum: PendingAlbum,
    styling: GridStyle,
};

export const EditAlbumFileList: React.SFC<Props> = (props) => {
    const files = props.pendingAlbum.pendingFiles.map((info): PendingFileEntry => ({
        uploadIndex: info.id,
        fileID: info.fileUUID,
        uploaded: info.status === FileUploadStatus.Succeeded,
    })).concat(props.pendingAlbum.uploadedFiles
        .filter((file) => file.thumbURL !== undefined)
        .map((file): PendingFileEntry => ({
            url: file.thumbURL,
            fileID: file.fileID,
            uploaded: true,
            duration: file.duration,
        })),
    );

    const pendingFiles = files.map((file, index) => {
        return (
            <PendingFile
                key={index}
                file={file}
                isMobile={props.isMobile}
                removeFile={props.onRemoveFile}
                styling={props.styling}
            />
        );
    });
    const addMoreButton = !props.isMobile && (
        <AddMoreGridButton
            elementWidth={props.styling.elementWidth}
            elementHeight={props.styling.elementHeight}
            elementSpaceAround={props.styling.elementSpaceAround}
            onClick={props.onClickAddMore}
        >
            <PlusIcon size={48} color={colors.captureBlue} />
            {_('add_photos')}
        </AddMoreGridButton>
    );

    return (
        <FileListWrapper>
            {addMoreButton}
            {pendingFiles}
        </FileListWrapper>
    );
};
