import * as React from 'react';
import styled from 'styled-components';
import {fontSize} from '../../assets/styleConstants';
import {Icon} from '../Icons';

const IconWrapper = styled.div`
    display: inline-flex;
    margin-right: 4px;
    align-items: center;
    font-size: ${fontSize.small_12};

    svg {
        margin-right: 2px;
    }
`;

export const MaybeNumberOf: React.SFC<{icon: Icon, numberOf: number, color?: string}> = (props) => {
    return props.numberOf > 0 ? (
        <IconWrapper><props.icon size={24} color={props.color || 'white'}/>{props.numberOf}</IconWrapper>
    ) : null;
};
