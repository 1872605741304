import styled, {keyframes} from 'styled-components';

const movingGradient = keyframes`
    0% {
        background-position: right;
    }
    100% {
        background-position: left;
    }
`;

export type AnimatedGradientProps = {
    color1: string,
    color2: string,
    iterationCount?: string,
};

const makeGradient = ({color1, color2}: AnimatedGradientProps) =>
    `${color1} 0%, ${color2} 33%, ${color1} 66%, ${color2} 100%`;

export const AnimatedGradient = styled.div`
    /* For Firefox 3.6 to 15 */
    background: -moz-linear-gradient(right,${(props: AnimatedGradientProps) => makeGradient(props)});

    background: linear-gradient(to left,${(props) => makeGradient(props)});
    background-size: 300%;

    animation: ${movingGradient} 3s linear;
    animation-fill-mode: forwards;
    animation-iteration-count: ${(props) => props.iterationCount || 'infinite'};

    width: 100%;
    height: 100%;
`;
