import {Action, isType} from '../common/actions';
import {UserInfo} from '../users/selectors';
import {SubscribersWereFetched} from './actions';

export type SubscribersState = DictionaryOf<UserInfo[]>;

export const subscribersReducer = (state: SubscribersState = {}, action: Action<any>): SubscribersState => {

    if (isType(action, SubscribersWereFetched)) {
        return {
            ...state,
            [action.payload.jobID]: action.payload.subscribers,
        };
    }

    return state;
};
