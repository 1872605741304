import {enc, lib, PBKDF2, SHA1} from 'crypto-js';

/**
 * By convention amongst clients, album-passwords are hashed with the album UUID into some magic string and used as password.
 * This tool-function does that.
 *
 * @param password (user provided string)
 * @param albumID  (UUID of album)
 * @returns {string}
 */
export const getAlbumPasswordHash = (password: string, albumID: JobID): string => {
    return enc.Base64.stringify(
        PBKDF2(enc.Hex.stringify(SHA1(password + albumID)), albumID, { iterations: 1001, keySize: 5 }),
    );
};

export const getObfuscated = (s: string): string => SHA1(s).toString();

export const getFileChecksum = (file: File): Promise<string> => {
    return new Promise((ok, err) => {
        const FR = new FileReader();
        FR.onloadend = () => {
            const wa = lib.WordArray.create(FR.result);
            ok(SHA1(wa).toString());
        };
        FR.onerror = err;
        FR.readAsArrayBuffer(file);
    });
};
