/**
 * Auto-generated translations-file.
 * Any changes to the strings are to be made in crowdin and built into this file using `yarn lang:build`.
 */

export const translatedStrings: DictionaryOf<any> = {
    "my_MM": {
        "takeout_service": "Capture ပြင်ပထုတ်ဝန်ဆောင်မှု",
        "takeout_info": "အရေးကြီးသတင်းစကား",
        "before_login_info": "ပြင်ပထုတ်ဝန်ဆောင်မှုသုံးပြီး သင့်ဓာတ်ပုံအားလုံးကို  Capture မှ ထုတ်ယူနိုင်ပါသည်။",
        "shutdown_ultimatum": "ဤနေ့ရက်တွင် Capture ကို အာရှ၌ ဖျက်သိမ်းတော့မည်ဖြစ်ကြောင်း ဝမ်းနည်းစွာ အသိပေးလိုက်ရပါသည်-",
        "shutdown_date": "၂၀၁၈ မတ်လ ၁ ရက်နေ့",
        "alert_copy": "သင့်ဓာတ်ပုံနှင့် ဗီဒီယိုအားလုံးကို Capture မှ အလွယ်တကူထုတ်ယူနိုင်မည့် ဝန်ဆောင်မှုတစ်ခုကို ဖန်တီးထားပြီးပါပြီ။",
        "alert_confirm": "ပြင်ပထုတ်ဝန်ဆောင်မှုကို အသုံးပြုရန်",
        "alert_deny": "နောက်မှလုပ်ပါမည်",
        "alert_banner_notStarted": "၂၀၁၈ မတ်လ ၁ ရက်နေ့တွင် Capture ကို အာရှ၌ ဖျက်သိမ်းတော့မည်ဖြစ်ပြီး အသုံးပြု၍ ရတော့မည်မဟုတ်ပါ။ သင့်ဓာတ်ပုံနှင့် ဗီဒီယိုများကို ပြင်ပထုတ်ဝန်ဆောင်မှုသုံးပြီး ဒေါင်းလုဒ်ရယူနိုင်ပါသည်။",
        "alert_banner_baking": "လတ်တလောတွင် သင့်ဖိုင်များကို ဒေါင်းလုဒ်လုပ်နိုင်ရန် ပြင်ဆင်နေပါသည်",
        "alert_banner_ready": "သင့်ဖိုင်များသည် ဒေါင်းလုဒ်လုပ်ရန် အသင့်ဖြစ်ပါပြီ။",
        "alert_banner_link": "ပိုမိုဖတ်ရှုရန်",
        "alert_banner_link_ready": "သင့်ဖိုင်များကို ဝင်ရောက်အသုံးပြုနိုင်ရန် ဤနေရာကို နှိပ်ပါ",
        "baking_thanks": "Capture ပြင်ပထုတ်ဝန်ဆောင်မှုကို အသုံးပြုသည့်အတွက် ကျေးဇူးတင်ပါသည်။",
        "baking_emailInfo": "သင့်ဓာတ်ပုံများ ဒေါင်းလုဒ်လုပ်ရန် အသင့်ဖြစ်သည့်အခါ ဤအီးမေးလ်လိပ်စာသို့ အီးမေးလ်တစ်စောင် ပို့ပေးပါမည်။ အချိန်အနည်းငယ် ကြာနိုင်ပါသည်။",
        "baking_close_tab_info": "ဤတက်(ဘ်)ကို ပိတ်လိုက်နိုင်ပါပြီ",
        "info_main_copy": "ပြင်ပထုတ်ဝန်ဆောင်မှုသုံးပြီး သင့်ဓာတ်ပုံအားလုံးကို  Capture မှ ထုတ်ယူနိုင်ပါသည်။ သင့်ဖိုင်များအတွက် လင့်ခ်တစ်ခုကို အီးမေးလ်ပို့ပေးပါမည်။ ၎င်းဖိုင်များကို ၁၄ ရက်အတွင်း ဒေါင်းလုဒ်လုပ်ရပါမည်။",
        "info_cta_copy": "ဖိုင်များရယူရန်အတွက် သင့်အီးမေးလ်လိပ်စာကို ထည့်ပါ-",
        "info_cta_copy_known": "ဤအီးမေးလ်လိပ်စာသို့ အီးမေးလ်တစ်စောင် ပို့ပေးပါမည်-",
        "info_device_copy": "ဖိုင်အရွယ်အစားများကြီးနိုင်သည့်အတွက် စားပွဲတင်ကွန်ပျူတာ သို့မဟုတ် လက်ပတော့မှတစ်ဆင့် ဒေါင်းလုဒ်လုပ်ရန် အကြံပြုပါသည်။",
        "info_email_change": "အခြားအီးမေးလ်လိပ်စာကို သုံးရန်",
        "info_takeout_ended": "ပြင်ပထုတ် ဝန်ဆောင်မှု အသစ်ပြုလုပ်ခြင်း မစနိုင်ပါ",
        "confirm_email_header_text": "သင့်အီးမေးလ်လိပ်စာကို အတည်ပြုပါ",
        "input_email_header_text": "သင့်အီးမေးလ်လိပ်စာကို ထည့်ပါ-",
        "confirm_email_btn_text": "မှန်ပါသည်",
        "change_email_btn_text": "အီးမေးလ်ပြောင်းရန်",
        "cancel_change_email_btn_text": "အီးမေးလ်မပြောင်းတော့ရန်",
        "email_input_error": "မမှန်ကန်သည့် အီးမေးလ်လိပ်စာ",
        "download_description": "သင့်ဖိုင်များသည် ဒေါင်းလုဒ်လုပ်ရန် အသင့်ဖြစ်ပါပြီ။",
        "mobileNotice": "သင့်ဖုန်းတွင် zip ဖိုင်များ ဖွင့်ရန်အတွက် အက်ပ်တစ်ခု လိုအပ်ပါသည်။ PC သို့မဟုတ် Mac ကွန်ပျူတာကို သုံးရန် အလေးအနက် အကြံပြုပါသည်။",
        "download": "ဒေါင်းလုတ်ပါ",
        "use_other_device_message": "သိမ်းဆည်းထားသော သင်၏ ဓာတ်ပုံနှင့် ဗီဒီယိုများကို ရယူလိုပါက စားပွဲတင်ကွန်ပျူတာ သို့မဟုတ် လက်ပတော့ကို သုံးပါ။",
        "faq_header": "Capture အာရှတိုက် ပိတ်သိမ်းမှုနှင့်ပတ်သက်ပြီး အမေးများသော မေးခွန်းများ",
        "faq_question1": "၁) ကျွန်ုပ်သည် ပြင်ပထုတ်ဝန်ဆောင်မှုထဲသို့ ဝင်၍မရ",
        "faq_answer1_p1": "ပြင်ပထုတ်ဝန်ဆောင်မှုသို့ ဝင်ရောက်သည့် စာမျက်နှာတွင် စကားဝှက်ကို ပြန်သတ်မှတ်နိုင်သည်-",
        "faq_answer1_p2": "၁) <g id=\"1\">https://takeout.capture-app.com သို့ သွားပါ",
        "faq_answer1_p3": "၂) မှတ်ပုံတင်စဉ်က သုံးထားသော မိုဘိုင်းဖုန်းနံပါတ်နှင့် အီးမေးလ်ကို ထည့်ပါ",
        "faq_answer1_p4": "၃) 'စကားဝှက်မေ့သွားပြီ' ကိုနှိပ်ပြီး ညွှန်ကြားချက်များအတိုင်း လိုက်နာပါ",
        "faq_answer1_p5": "ဝင်ရောက်ရာတွင် အခက်အခဲဆက်ရှိနေပါက အသုံးပြုသူဝန်ဆောင်မှုဌာနသို့ ဆက်သွယ်ပါ။ အင်္ဂလိပ်ဘာသာဖြင့် တိုက်ရိုက်ချတ်လုပ်လိုပါက အချိန်မရွေး ၂၄/၇ ပြုလုပ်နိုင်ပြီး ကျွန်ုပ်တို့ထံ အီးမေးလ်ပို့လျှင်လည်း အမြန်ဆုံး ဖြေကြားပေးပါမည်။",
        "faq_question2": "၂) ပြင်ပထုတ်ဝန်ဆောင်မှုကို သုံးရာတွင် အခက်အခဲများရှိပါသည်။",
        "faq_answer2_p1": "လုပ်ဆောင်ချက် ပြီးမြောက်အောင် ဆောင်ရွက်ရာတွင် ပြဿနာရှိပါက ပြင်ပထုတ်ဝန်ဆောင်မှု စာမျက်နှာ -  <g id=\"1\">https://takeout.capture-app.com</g> ကို ပြန်စစ်ကြည့်ပြီး အဆင့်များအတိုင်း လိုက်လုပ်ပါ။",
        "faq_answer2_p2": "ပြင်ပထုတ်ဝန်ဆောင်မှုကို ပြုလုပ်ပြီးသွားသည့်နောက် ဒေါင်းလုဒ်လုပ်ရမည့် အီးမေးလ်ကို စောင့်နေဆဲ ဖြစ်ပါက သင့်ဓာတ်ပုံနှင့် ဗီဒီယိုများကို ဒေါင်းလုဒ်လုပ်နိုင်ရန် အသင့်ပြင်ရာတွင် (သင်သိမ်းဆည်းထားသော ဒေတာပမာဏအပေါ်မူတည်၍) ၄၈ နာရီအထိ ကြာနိုင်ကြောင်း သတိပြုပါ။",
        "faq_answer2_p3": "အီးမေးလ်သည် စပမ်းဖိုဒါသို့ လမ်းလွဲရောက်သွားနိုင်သောကြောင့် သင့်ဝင်စာနှင့်အတူ စပမ်းဖိုဒါကိုပါ စစ်ဆေးပါ။",
        "faq_answer2_p4": "<g id=\"1\">https://takeout.capture-app.com</g> သို့ အချိန်မရွေး ဝင်ရောက်ပြီး ပြင်ပထုတ်ရန် တောင်းဆိုထားမှု၏ အခြေအနေကို ကြည့်ရှုနိုင်ပါသည်။",
        "faq_answer2_p5": "ပြဿနာဆက်ရှိနေပါက အသုံးပြုသူဝန်ဆောင်မှု (အောက်တွင်ကြည့်ပါ) သို့ ဆက်သွယ်ပါ။",
        "faq_question3": "၃) ကျွန်ုပ်က ပြန်လည်ရယူခြင်းမရှိလျှင် ကျွန်ုပ်၏ဒေတာများ ဘာဖြစ်သွားမည်နည်း။",
        "faq_answer3_p1": "Telenor Digital သည် Capture ကို အာရှတိုက်တွင် ၂၀၁၈ မတ်လ ၂ ရက်နေ့၌ အပြီးတိုင်ပိတ်သိမ်းပါတော့မည်။ ၎င်းနောက်တွင် ဒေတာအားလုံးကို ဆာဗာများထဲမှ ဖျက်လိုက်မည်ဖြစ်ပြီး ဒေတာများကို ပြန်လည်ရယူ၍ မရတော့ပါ။",
        "faq_question4": "၄) လစဉ်ကြေးပေးထားပါက - မည်သို့ ပယ်ဖျက်ရမည်နည်း",
        "faq_answer4_p1": "<g id=\"1\">ဝက်ဘဆိုက်သုံးပြီး လစဉ်ကြေးပေးထားပါက</g> ၂၀၁၇ ဒီဇင်ဘာအတွင်း ၎င်းကို ပယ်ဖျက်လိုက်မည်ဖြစ်ပြီး ငွေပြန်အမ်းမည်မဟုတ်ပါ။",
        "faq_answer4_p2": "<g id=\"1\">ဝက်ဘဆိုက်သုံးပြီး နှစ်စဉ်ကြေးပေးထားပါက</g> ဒီဇင်ဘာလအတွင်း ၎င်းကို ပယ်ဖျက်လိုက်မည်ဖြစ်ပြီး၊ ပေးသွင်းထားသည်မှာ ၆ လ မကျော်သေးပါက ငွေပြန်အမ်းပေးပါမည်။",
        "faq_answer4_p3": "<g id=\"1\">Apple အက်ပ်စတိုးရှိ အက်ပ်အတွင်းဝယ်ယူမှု</g> ကို သုံးပြီး လစဉ်/နှစ်စဉ်ကြေး ပေးထားပါက Apple လစဉ်/နှစ်စဉ်ကြေးစာမျက်နှာသို့ သွားပြီး စာရင်းသွင်းထားမှုကို ပယ်ဖျက်ရပါမည်။",
        "faq_answer4_p4": "Apple စက်ပစ္စည်းများတွင် လစဉ်/နှစ်စဉ်ကြေးကို ပယ်ဖျက်ရန်နည်းလမ်းအကြောင်းကို ဤနေရာတွင် ဖော်ပြထားသည်- https://support.apple.com/en-sg/HT202039",
        "faq_answer4_p5": "သံသယများနှင့် မေးမြန်းလိုသည်များရှိပါက အသုံးပြုသူဝန်ဆောင်မှုဌာသို့ ဆက်သွယ်ပါ။",
        "faq_question5": "၅) တစ်နှစ်စာ နှစ်စဉ်ကြေးပေးပြီးသား ဖြစ်ပါက - ငွေပြန်အမ်းပါသလား။",
        "faq_answer5_p1": "သင်သည် တစ်နှစ်စာ နှစ်စဉ်ကြေးပေးထားပြီး၊ ပေးသွင်းထားသည်မှာ ၆ လ မကျော်သေးပါက ငွေပြန်အမ်းပေးပါမည်။ ပြန်အမ်းငွေရရှိရန် အသုံးပြုသူဝန်ဆောင်မှုဌာနကို ဆက်သွယ်ပါ။",
        "faq_question6": "၆) လစဉ်ကြေးပေးပြီးသားဖြစ်ပါက - ငွေပြန်အမ်းပါသလား။",
        "faq_answer6_p1": "မအမ်းပါ။ သင်ပယ်ဖျက်ချိန်တွင် ၎င်းသည် နောက်သက်တမ်းတိုးချိန်အထိ ဆက်သွားမည်ဖြစ်ပြီး ၎င်းနောက်တွင် ရပ်သွားပါမည်။ လက်ရှိလစဉ်ကြေးပေးထားမှုအားလုံးသည် အသုံးပြုသူဘက်မှ စောစီးစွာ ရပ်လိုက်ခြင်းမရှိပါက ဒီဇင်ဘာလအတွင်းတွင် ရပ်တန့်ပါမည်။",
        "faq_footer_intro": " Capture အတွက် အသုံးပြုသူဝန်ဆောင်မှုကို ဤနေရာတွင် ရရှိနိုင်သည်- ",
        "dear_customer": "ချစ်ခင်ရပါသော ဖောက်သည်,",
        "shutdown_capture_app_not_available": "Capture အက်(ပ်) ကို အာရှတွင် အသုံးပြု၍ မရနိုင်တော့ပါ။",
        "shutdown_capture_not_available": "Capture ကို အာရှတွင် အသုံးပြု၍ မရနိုင်တော့ပါ။",
        "shutdown_remove_app": "သင့်ရဲ့ စက်မှ အက်(ပ်)လီကေးရှင်းကို ကျေးဇူးပြု၍ ဖယ်ရှားပါ။"
    },
    "bn_BD": {
        "takeout_service": "ক্যাপচার টেকআউট সেবা",
        "takeout_info": "গুরুত্বপূর্ণ তথ্য",
        "before_login_info": "টেকআউট সেবার মাধ্যমে আপনি আপনার ক্যাপচার একাউন্টের সব ছবিগুলো পাবেন।",
        "shutdown_ultimatum": "আমরা অত্যন্ত দুঃখের সাথে জানাচ্ছি যে ক্যাপচার ১লা মার্চ ২০১৮ থেকে বন্ধ করে দেয়া হচ্ছ:",
        "shutdown_date": "১লা মার্চ, ২০১৮",
        "alert_copy": "আমরা সহজেই আপনার সমস্ত ফটো/ভিডিও পেতে সহজে ব্যবহারযোগ্য টেকআউট সেবা তৈরি করেছি।",
        "alert_confirm": "টেকআউট সেবা ব্যবহার করুন",
        "alert_deny": "আমি এটা পরে করব",
        "alert_banner_notStarted": "এশিয়াতে ক্যাপচার বন্ধ করে দেওয়া হচ্ছে এবং ১লা মার্চ ২০১৮ এরপর উপলভ্য থাকবে না। আপনি টেকআউট সেবা ব্যবহার করে আপনার ছবিগুলো ডাউনলোড করতে পারেন।",
        "alert_banner_baking": "আপনার ফাইলগুলো বর্তমানে ডাউনলোডের জন্য প্রস্তুত হচ্ছে",
        "alert_banner_ready": "আপনার ছবিগুলো ডাউনলোড করার জন্য প্রস্তুত।",
        "alert_banner_link": "আরো পড়ুন",
        "alert_banner_link_ready": "আপনার ফাইল অ্যাক্সেস করতে এখানে ক্লিক করুন",
        "baking_thanks": "ক্যাপচার টেকআউট সেবা ব্যবহার করার জন্য আপনাকে ধন্যবাদ!",
        "baking_close_tab_info": "আপনি নিরাপদে এই ট্যাবটি বন্ধ করতে পারেন",
        "info_cta_copy": "ফাইলগুলি পেতে আপনার ইমেইল ঠিকানা লিখুন:",
        "info_cta_copy_known": "ইমেইল এই ঠিকানায় পাঠানো হবেঃ",
        "info_device_copy": "সম্ভাব্য বড় ফাইল আকারের কারণে, আপনার ফাইলগুলো ডাউনলোড করার জন্য ডেস্কটপ বা ল্যাপটপ ব্যবহার করার জন্য সুপারিশ করা হচ্ছে।",
        "info_email_change": "একটি ভিন্ন ইমেইল ঠিকানা ব্যবহার করুন",
        "info_takeout_ended": "খবর টেকআউট শুরু করা যাচ্ছে না",
        "confirm_email_header_text": "আপনার ইমেইল ঠিকানা নিশ্চিত করুন",
        "input_email_header_text": "দয়া করে ইমেইল ঠিকানা লিখুনঃ",
        "confirm_email_btn_text": "হ্যাঁ, এটা সঠিক",
        "change_email_btn_text": "ইমেইল পরিবর্তন করুন",
        "cancel_change_email_btn_text": "ইমেইল ঠিকানা পরিবর্তন বাতিল করুন",
        "email_input_error": "ভুল ইমেইল ঠিকানা",
        "download_description": "আপনার ছবিগুলো ডাউনলোড করার জন্য প্রস্তুত।",
        "mobileNotice": "অনুগ্রহ করে মনে রাখবেন যে আপনার ফোনে জিপ ফাইল খুলতে একটি অ্যাপ্লিকেশন প্রয়োজন। আমরা এই ব্যাপারে কম্পিউটার/ম্যাক ব্যবহার করার জন্য সুপারিশ করবো।",
        "download": "ডাউনলোড করুন",
        "use_other_device_message": "আপনার আর্কাইভে থাকা ছবি/ভিডিও দেখতে অনুগ্রহ করে ল্যাপটপ/ডেস্কটপ ব্যবহার করুন।",
        "faq_header": "ক্যাপচার এশিয়া বন্ধ হয়ে যাওয়া সংক্রান্ত অধিক জিজ্ঞাসিত প্রশ্নাবলী",
        "faq_question1": "১) টেকআউটে লগ ইন করতে পারছি না",
        "faq_answer1_p1": "টেকআউট সেবাটিতে আপনি লগইন পেজে আপনার পাসওয়ার্ড রিসেট করতে পারবেনঃ:",
        "faq_answer1_p2": "১) এখানে যান <g id=\"1\">https://takeout.capture-app.com",
        "faq_answer1_p3": "২) আপনার নিবন্ধিত মোবাইল নম্বর অথবা ইমেইল প্রবেশ করান",
        "faq_answer1_p4": "৩) 'পাসওয়ার্ড ভুলে গেছি' চাপুন এবং নির্দেশনা অনুসরণ করুন",
        "faq_answer1_p5": "আপনি যদি অনবরত লগইন সমস্যার মুখোমুখি হতে থাকেন তাহলে কাস্টমার সাপোর্ট এ যোগাযোগ করুন। আমরা ২৪/৭ ইংরেজি লাইভ চ্যাট সাপোর্ট এর ব্যবস্থা রেখেছি, অথবা আপনি আমাদের ইমেইল পাঠাতে পারেন এবং আমরা যত দ্রুত সম্ভব এর উত্তর দেব।",
        "faq_question2": "২) টেকআউট ব্যবহারে আমার সমস্যা হচ্ছে।",
        "faq_answer2_p1": "পুরো প্রক্রিয়াট সম্পন্ন করতে যদি সমস্যা হয়ে থাকে, তাহলে অনুগ্রহ করে টেকআউট পেজ দেখুনঃ <g id=\"1\">https://takeout.capture-app.com</g>  এবং ধাপগুলো অনুসরণ করুন।",
        "faq_answer2_p2": "আপনি যদি সবকিছু করে থাকেন এবং এখনো একটি ইমেইলের জন্য অপেক্ষায় থাকেন, তবে অনুগ্রহ করে মনে রাখবেন আপনার কন্টেন্ট ডাউনলোড এর জন্য তৈরী করতে সর্বোচ্চ ৪৮ ঘন্টা লাগতে পারে (আপনি কি পরিমাণ ডাটা সংরক্ষণ করেছেন তার উপর নির্ভর করে)",
        "faq_answer2_p3": "অনুগ্রহ করে আপনার ইনবক্স এবং স্প্যাম ফোল্ডারটি দেখবেন ইমেইলটি সেখানে গিয়ে যেতে পারে।",
        "faq_answer2_p4": "আপনি <g id=\"1\">https://takeout.capture-app.com</g> এ সবসময় লগইন করতে পারবেন এবং আপনার টেকআউট অনুরোধের অবস্থা দেখতে পারবেন।",
        "faq_answer2_p5": "যদি সমস্যা এখনও থেকে থাকে, অনুগ্রহ করে সিএস (নিচে দ্রষ্টব্য) এর সাথে যোগাযোগ করুন।",
        "faq_question3": "৩) আমি যদি পুনরুদ্ধার না করি তবে আমার ডাটার কি হবে",
        "faq_answer3_p1": "টেলেনর ডিজিটাল ক্যাপচার সেবাটি ১লা মার্চ, ২০১৮ তে এশিয়ায় বন্ধ করে ফেলবে। এরপর সকল ডাটা সার্ভার থেকে মুছে যাবে এবং এই ডাটা আর পুনরুদ্ধার করা একেবারেই সম্ভব নয়।",
        "faq_question4": "৪) আমি একটি মাসিক সাবস্ক্রিপশন পে করে থাকি - কিভাবে এটা বাতিল করব",
        "faq_answer4_p1": "যদি আপনার <g id=\"1\">ওয়েব শপ ব্যবহার করে পে করা মাসিক সাবস্ক্রিপশন </g> থেকে থাকে, আমরা ডিসেম্বর ২০১৭ তে তা বাতিল করে দেব, যা রিফান্ড করা হবেনা।",
        "faq_answer4_p2": "যদি আপনার <g id=\"1\">ওয়েব শপ ব্যবহার করে পে করা বার্ষিক সাবস্ক্রিপশন</g> থেকে থাকে, আমরা ডিসেম্বর মাসে তা বাতিল করে দেব এবং সাবস্ক্রিপশনটি যদি ৬ মাসের কম হয়ে থাকে, তবে তা রিফান্ড করা হবে ।",
        "faq_answer4_p3": "আপনি যদি <g id=\"1\">আপল অ্যাপ স্টোরের ইন অ্যাপ পারচেইজ</g> এর মাধ্যমে মূল্য পরিশোধ করে থাকেন, তাহলে অ্যাপল সাবস্ক্রিপশন পেজে গিয়ে সাবস্ক্রিপশন বাতিল করতে হবে।",
        "faq_answer4_p4": "কিভাবে অ্যাপল ডিভাইসে সাবস্ক্রিপশন বাতিল করতে হয় তার একটি বর্ণনা এখানে পাওয়া যাবেঃ https://support.apple.com/en-sg/HT202039",
        "faq_answer4_p5": "যদি এখনও কোন সন্দেহ বা প্রশ্ন থেকে থাকে, অনুগ্রহ করা সিএস এ যোগাযোগ করুন।",
        "faq_question5": "৫) আমার একটি বাৎসরিক সাবস্ক্রিপশন আছে যা আমি ইতোমধ্যে পরিশোধ করে ফেলেছি - আমি কি রিফান্ড পাব?",
        "faq_answer5_p1": "যদি আপনার একটি বাৎসরিক সাবস্ক্রিপশন থেকে থাকে তাহলে আমরা তা পরিশোধ করব যদি তার মেয়াদ ৬ মাসের কম হয়ে থাকে। রিফান্ড এর জন্য অনুগ্রহ করে সিএস এর সাথে যোগাযোগ করুন।",
        "faq_question6": "৬) আমার একটি মাসিক সাবস্ক্রিপশন আছে যা আমি ইতোমধ্যে পরিশোধ করে ফেলেছি - আমি কি রিফান্ড পাব?",
        "faq_answer6_p1": "না, আপনি যখন এটা বাতিল করবেন, এটা পরবর্তী নবায়ন পর্যন্ত চালু থাকবে তারপর বন্ধ হবে। যেকোন চলমান সাবস্ক্রিপশন এই ডিসেম্বরের মধ্যেই বন্ধ হবে যদি না ব্যবহারকারী কর্তৃক আগেই বাতিল করা হয়।",
        "faq_footer_intro": "ক্যাপচারের গ্রাহক সেবা এখানে পাবেনঃ",
        "dear_customer": "প্রিয় গ্রাহক,",
        "shutdown_capture_app_not_available": "এশিয়াতে ক্যাপচার অ্যাপটি আর উপলভ্য নয়।",
        "shutdown_capture_not_available": "এশিয়াতে ক্যাপচার আর উপলভ্য নয়।",
        "shutdown_remove_app": "অনুগ্রহ করে আপনার ডিভাইস থেকে অ্যাপটি অপসারণ করুন।"
    },
    "bg_BG": {
        "email_input_error": "Невалиден имейл адрес",
        "download": "Изтеглете"
    },
    "sv_SE": {
        "email_input_error": "Ogiltig e-postadress",
        "download": "Hämta"
    },
    "th_TH": {
        "takeout_service": "บริการนำรูปออกจาก Capture",
        "takeout_info": "ประกาศปิดบริการ",
        "before_login_info": "ด้วยบริการดาวน์โหลดรูปภาพนี้ คุณจะสามารถดาวน์โหลดรูปภาพและวิดีโอทั้งหมดออกจาก Capture",
        "shutdown_ultimatum": "เราเสียใจที่ต้องแจ้งให้ทุกท่านทราบว่า Capture จะปิดให้บริการตั้งแต่วันที่:",
        "shutdown_date": "1 มีนาคม 2561",
        "alert_copy": "เราได้เตรียมบริการดาวน์โหลดรูปภาพออกจาก Capture อย่างง่ายในรูปแบบไฟล์ .zip เพื่ออำนวยความสะดวกในการดาวน์โหลดรูปภาพและวิดีโอของคุณออกจาก Capture",
        "alert_confirm": "ใช้บริการดาวน์โหลดรูปภาพ",
        "alert_deny": "ยังไม่ใช้บริการดาวน์โหลดตอนนี้",
        "alert_banner_notStarted": "เราเสียใจที่จะต้องแจ้งให้ทุกท่านทราบว่า Capture จะปิดให้บริการตั้งแต่วันที่ 1 มีนาคม 2561 เป็นต้นไป คุณสามารถใช้บริการดาวน์โหลดรูปภาพในรูปแบบไฟล์ .zip ที่เราเตรียมไว้เพื่อดาวน์โหลดรูปออกจาก Capture ได้",
        "alert_banner_baking": "กำลังเตรียมไฟล์สำหรับการดาวน์โหลด",
        "alert_banner_ready": "รูปของคุณพร้อมสำหรับการดาวน์โหลดแล้ว",
        "alert_banner_link": "อ่านเพิ่มเติม",
        "alert_banner_link_ready": "คลิ้กที่นี่เพื่อเข้าสู่ไฟล์ของคุณ",
        "baking_thanks": "ขอบคุณที่ใช้บริการนำรูปออกจาก Capture",
        "baking_emailInfo": "คุณจะได้รับอีเมลตอบกลับเมื่อไฟล์ของคุณพร้อมสำหรับการดาวน์โหลด ซึ่งขั้นตอนนี้อาจใช้เวลาระยะหนึ่ง ขึ้นอยู่กับจำนวนไฟล์ของคุณที่อยู่บน Capture โดยอีเมลพร้อม link สำหรับดาวน์โหลดจะส่งไปที่",
        "baking_close_tab_info": "คุณสามารถปิดหน้าต่างนี้ได้",
        "info_main_copy": "ด้วยบริการดาวน์โหลดรูปภาพนี้ คุณจะสามารถดาวน์โหลดรูปภาพและวิดีโอทั้งหมดออกจาก Capture โดยเราจะส่งอีเมลพร้อมลิงค์สำหรับดาวน์โหลดไฟล์ทั้งหมดของคุณ โดยไฟล์ที่ได้จะเป็นไฟล์ .zip ขนาดไฟล์ละไม่เกิน 2GB (หากคุณมีรูปเกิน 2GB จะถูกแบ่งเป็นหลายไฟล์) และ link จะมีอายุ 14 วัน \n",
        "info_cta_copy": "กรอกอีเมลสำหรับรับลิงค์",
        "info_cta_copy_known": "อีเมลพร้อมลิงค์สำหรับดาวน์โหลดจะถูกส่งไปยังอีเมล:",
        "info_device_copy": "เนื่องจากอาจจะเป็นไฟล์ขนาดใหญ่ เราจึงแนะนำให้ท่านทำการดาวน์โหลดผ่านจากคอมพิวเตอร์ของคุณ",
        "info_email_change": "ใช้อีเมล์อื่น",
        "info_takeout_ended": "ไม่สามารถเริ่มต้นการนำออกใหม่ได้",
        "confirm_email_header_text": "กรุณาตรวจสอบความถูกต้องและยืนยันอีเมล",
        "input_email_header_text": "กรุณากรอกอีเมลของคุณ",
        "confirm_email_btn_text": "อีเมลถูกต้อง",
        "change_email_btn_text": "เปลี่ยนอีเมล",
        "cancel_change_email_btn_text": "ยกเลิก",
        "email_input_error": "อีเมลไม่ถูกต้อง",
        "download_description": "รูปของคุณพร้อมสำหรับการดาวน์โหลดแล้ว",
        "mobileNotice": "เราแนะนำให้คุณใช้คอมพิวเตอร์ในการดาวน์โหลดไฟล์เหล่านี้ เนื่องจากคุณต้องโหลดแอพลิเคชั่นเพิ่มเติมเพื่อเปิดไฟล์ zip บนโทรศัพท์มือถือ",
        "download": "ดาวน์โหลด",
        "use_other_device_message": "เราแนะนำให้คุณใช้คอมพิวเตอร์สำหรับการเก็บรูปและวีดีโอ",
        "faq_header": "คำถามที่พบบ่อยกับบริการนำรูปออก",
        "faq_question1": "1) ไม่สามารถล๊อกอินได้",
        "faq_answer1_p1": "คุณสามารถทำการรีเซ็ตรหัสผ่านของคุณในหน้าล๊อกอินได้",
        "faq_answer1_p2": "1) ไปที่ <g id=\"1\">https://takeout.capture-app.com",
        "faq_answer1_p3": "2) กรอกหมายเลขโทรศัพท์หรืออีเมล์ที่เคยทำการสมัครไว้",
        "faq_answer1_p4": "3) กด ลืมรหัสผ่าน และทำตามขั้นตอนที่แสดง",
        "faq_answer1_p5": "หากคุณยังประสบปัญหาในการลงทะเบียน กรุณาติดต่อแผนกบริการลูกค้า เราให้การสนับสนุนผ่านการแชทสด 24/7 และคุณยังสามารถส่งอีเมลมาให้เราได้ แล้วเราจะตอบให้เร็วที่สุดเท่าที่จะทำได้",
        "faq_question2": "2) ปัญหาการใช้งาน บริการนำรูปออก",
        "faq_answer2_p1": "ถ้าหากคุณพบปัญหาระหว่างการใช้งาน กรุณาไปยัง <g id=\"1\">https://takeout.capture-app.com</g>  และทำตามขั้นตอนต่อไปนี้",
        "faq_answer2_p2": "ถ้าหาคุณทำครบทุกขั้นตอนแล้วแต่ยังไม่ได้รับอีเมล์จากระบบ โปรดทราบว่าอาจต้องใช้เวลาถึง 48 ชั่วโมง เพื่อทำการเตรียมข้อมูลของคุณ (ขึ้นอยู่กับจำนวนข้อมูลที่คุณเก็บไว้)",
        "faq_answer2_p3": "กรุณาลองตรวจสอบ กล่องจดหมาย หรือ โฟลเดอร์สแปม ของบัญชีอีเมล์ของคุณ",
        "faq_answer2_p4": "คุณสามารถตรวจสอบสถานะได้ที่ <g id=\"1\">https://takeout.capture-app.com</g>",
        "faq_answer2_p5": "ถ้าหายังพบปัญหากรุณาติดต่อฝ่ายลูกค้าสัมพันธ์(ตามรายละเอียดที่อยู่ด้านล่าง)",
        "faq_question3": "3) จะเกิดอะไรขึ้นกับข้อมูลของฉันหากฉันไม่ได้นำมันออกมา",
        "faq_answer3_p1": "เทเลนอร์ ดิจิตอล จะปิดบริการ Capture ในภาคเอเชียตั้งแต่วันที่ 1 มีนาคม 2018 หลังจากวันที่นี้ข้อมูลทั้งหมดจะถูกลบออกจากเซิร์ฟเวอร์และจะไม่สามารถดึงข้อมูลได้อีกต่อไป",
        "faq_question4": "4) ฉันจ่ายเงินซื้อเนื้อที่เพิ่มรายเดือน - ฉันจะยกเลิกได้อย่างไร",
        "faq_answer4_p1": "ถ้าหากคุณ <g id=\"1\"> ซื้อพื้นที่เพิ่มเติมแบบรายเดือนและที่ชำระเงินโดยผ่านเว็บสโตร์ </g> เราจะยกเลิกการสมัครสมาชิกในช่วงเดือนธันวาคม 2560 และจะไม่มีการคืนเงิน",
        "faq_answer4_p2": "หากคุณมีการ <g id=\"1\">ซื้อพื้นที่เพิ่มเติมแบบรายปีผ่านเว็บสโตร์ </g> ทุกปีเราจะยกเลิกการสมัครรับข้อมูลในช่วงเดือนธันวาคมและจะคืนเงินหากมีการใช้งานน้อยกว่า 6 เดือน",
        "faq_answer4_p3": "ถ้าหากคุณซื้อผ่าน <g id=\"1\"> แอปเปิ้ล สโตร์ </g> คุณต้องไปหน้า การจัดการสมัคร ของแอปเปิ้ล เพื่อยกเลิก",
        "faq_answer4_p4": "รายละเอียดการยกเลิกท่านสามารถดูได้จาก https://support.apple.com/th-th/HT202039",
        "faq_answer4_p5": "หากท่านมีข้อสงสัยกรุณาติดต่อฝ่ายลูกค้าสัมพันธ์",
        "faq_question5": "5) ฉันได้ซื้อเนื้อที่เพิ่มแบบรายปี - ฉันจะทำเรื่องขอคืนเงินได้อย่างไร",
        "faq_answer5_p1": "หากคุณมีการ ซื้อพื้นที่เพิ่มเติมแบบรายปีผ่านเว็บสโตร์ และมีการใช้งานน้อยกว่า 6 เดือน เราจะทำการคืนเงินให้แก่คุณ กรุณติดต่อทีมงานลูกค้าสัมพันธ์เพื่อยื่นเรื่องขอคืนเงิน",
        "faq_question6": "6) ฉันได้ซื้อเนื้อที่เพิ่มแบบรายเดือน - ฉันจะทำเรื่องขอคืนเงินได้อย่างไร",
        "faq_answer6_p1": "ไม่สามารถทำได้ เมื่อคุณทำการยกเลิกระบบจะทำการยกเลิกการต่ออายุในครั้งถัดไปให้ การสมัครทุกประเภทจะถูกระงับในเดือนธันวาคม หากผู้ใช้ไม่ได้ทำการยกเลิกก่อนหน้านี้",
        "faq_footer_intro": "สามารถติดต่อฝ่ายลูกค้าสัมพันธ์ได้ที่:",
        "dear_customer": "เรียนลูกค้า",
        "shutdown_capture_app_not_available": "แอป Capture ไม่สามารถใช้ในเอเชียได้อีกต่อไป",
        "shutdown_capture_not_available": "ไม่สามารถใช้ Capture ในเอเชียได้อีกต่อไป",
        "shutdown_remove_app": "โปรดลบแอปออกจากอุปกรณ์ของคุณ"
    },
    "sr_ME": {
        "email_input_error": "Nevažeća adresa e-pošte",
        "download": "Preuzmi"
    },
    "en_US": {},
    "sr_RS": {
        "email_input_error": "Nevažeća e-mail adresa",
        "download": "Preuzimanje"
    },
    "nb_NO": {
        "alert_banner_link": "Les mer",
        "email_input_error": "Ugyldig e-postadresse",
        "download": "Last ned"
    }
};
