import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const AlbumIconHeart: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path fill={props.color || colors.defaultIconColor} d="M12.0912259,15.9363897 C10.0456129,14.1005832 8,12.2123252 8,10.3765187 C8,9.3274864 8.89167743,8.5407122 10.0456129,8.5407122 C11.0421936,8.5407122 11.5667097,8.9603251 12.0912259,9.90445415 C12.5632904,8.9603251 13.0878065,8.5407122 14.0843872,8.5407122 C15.2383227,8.5407122 16.1300001,9.3274864 16.1300001,10.3765187 C16.1300001,12.2123252 14.0843872,14.1005832 12.0912259,15.9363897 Z M18.0632911,4.41344565 L4.93670886,4.41344565 C4.57805907,4.41344565 4.00421941,3.76295373 4.93670886,3.43770777 L17.9915612,3.43770777 C17.9915612,3.43770777 17.9198312,2.20177313 16.628692,2.13672394 L4.21940928,2.07167474 C4.21940928,2.07167474 3,2.00662555 3,3.30760939 C3,9.94262696 3,14.040726 3,19.439809 C3,20.4155468 3.86075949,21.1961371 4.93670886,21.1961371 L18.0632911,21.1961371 C19.1392405,21.1961371 20,20.4155468 20,19.439809 L20,6.23482302 C20,5.25908515 19.1392405,4.41344565 18.0632911,4.41344565 Z" />
        </g>
    </svg>
);
