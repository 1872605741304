import * as React from 'react';
import {trackEvent} from '../../analytics';
import {_} from '../../assets';
import {getConnectedInstance} from '../../state/uploader/uploadQueue';
import {FileAcceptElement} from './FileAcceptElement';

interface Props {
    targetJob: JobID;
    targetFolderProvider: () => string;
}
class _UploadButton extends React.Component<Props, {}> {

    private fileHandler = (files: File[]) => {
        trackEvent('Uploader', 'FilesAddedBySelection', '', files.length);
        getConnectedInstance().addFiles(files, this.props.targetJob, this.props.targetFolderProvider());
    }

    render() {
        return <FileAcceptElement fileHandler={this.fileHandler}>
            <span className="uploadLink" onClick={() => trackEvent('Uploader', 'UploadButton_click')}>{_('upload')}</span>
        </FileAcceptElement>;

    }
}

/** UploadButton for insertion in Capture Web Legacy **/

export const UploadButtonLegacy = _UploadButton;
