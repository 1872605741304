import {StripePaymentInfoResponse, StripeProduct, UserGrant} from '../../API/services/AppService';
import {createActionCreator, createVoidActionCreator} from '../common/actions';
import {UserActionInitiated} from '../userActions/actions';
import {UserActionTypes} from '../userActions/reducer';

export const PlanChangeInitiated = (planID: string) =>
    UserActionInitiated({target: planID, type: UserActionTypes.CHANGE_STORAGE_PLAN});
export const PurchaseSucceeded = createVoidActionCreator('PURCHASE_SUCCESSFUL');
export const PurchaseFailed = createVoidActionCreator('PURCHASE_FAILED');

export const PlanChangeSucceeded = createVoidActionCreator('PLAN_CHANGE_SUCCEEDED');
export const PlanChangeFailed = createVoidActionCreator('PLAN_CHANGE_FAILED');

export const PaymentInfoFetched = createActionCreator<StripePaymentInfoResponse>('PAYMENT_INFO_FETCHED');
export const CreditCardUpdated = createVoidActionCreator('CREDIT_CARD_UPDATED');
export const CreditCardUpdateFailed =
    createVoidActionCreator('CREDIT_CARD_UPDATE_FAILED');

export const StorageProductsFetched = createActionCreator<StripeProduct[]>('STORAGE_PRODUCTS_FETCHED');
export const UserStoragePlanFetched = createActionCreator<UserGrant[]>('USER_STORAGE_PLAN_FETCHED');

export const PlanCancelInitiated = (url: string) =>
    UserActionInitiated({target: url, type: UserActionTypes.CANCEL_STORAGE_PLAN});
export const PlanCanceled = createVoidActionCreator('PLAN_CANCELED');
export const PlanCancelFailed = createVoidActionCreator('PLAN_CANCEL_FAILED');

export const PlanReactivationInitiated = (url: string) =>
    UserActionInitiated({target: url, type: UserActionTypes.REACTIVATE_STORAGE_PLAN});

export const UnavailablePlanClicked = createVoidActionCreator('UNAVAILABLE_PLAN_CLICKED');
export const PlanReactivated = createVoidActionCreator('PLAN_REACTIVATED');
export const PlanReactivationFailed = createVoidActionCreator('PLAN_REACTIVATION_FAILED');
