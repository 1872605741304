import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {trackEvent} from '../../analytics';
import {addPhotoToFBAlbum, getAlbumIDByName, getFBAccessTokenForceLogin} from '../../API/FBConnect';
import {createShareWithFiles, publishJobByEmail} from '../../API/job';
import {_} from '../../assets/localizedStrings';
import {colors} from '../../assets/styleConstants';
import {Pages} from '../../routing/index';
import {AlbumLinkWasCopied} from '../../state/albumOptions/actions';
import {FilesWereShared} from '../../state/files/actions';
import {CaptureFile} from '../../state/files/reducer';
import {FilesOptionsTriggered} from '../../state/FilesOptions/actions';
import {isMobileDevice} from '../../utilities/device';
import {FileTarget} from '../../utilities/fileTarget';
import {Button} from '../Common/Button';
import {OptionsOverlay} from '../Common/Overlay';
import {CopyShareLinkComponent} from '../Share/CopyLinkScreen';
import {SharePhotosToFBForm} from '../Share/SharePhotosToFBForm';
import {ShareToComponent} from '../Share/ShareToComponent';
import {EmailSharingOptions, ShareToEmailForm} from '../Share/ShareToEmailForm';
import {AddToAlbumButton, GetLinkButton, ShareByMailButton, ShareToFBButton} from '../Share/ShareToOptionButtons';

const DisabledButtonInfoArea = styled.div`
    margin-top: 10px;
    color: ${colors.captureMagenta};
    text-align: center;
`;
type OwnProps = {
    isMobile: boolean,
    files: CaptureFile[],
    onCancel: () => any,
    onConfirm: () => any,
};

type DispatchProps = {
    shareFileToAlbum: () => any,
    shareToFB: (fbAlbumName: string) => Promise<any>,
    shareToEmail: (options: EmailSharingOptions) => Promise<any>,
    getShareLink: () => any,
    onLinkCopied: () => void,
};

type Props = OwnProps & DispatchProps;

type ShareFilesOptionView = 'options' | 'fb' | 'email' | 'copyLink';
type ShareFilesOptionsState = {
    optionView: ShareFilesOptionView,
    isPending: boolean,
    fbPermissionError?: boolean,
    disabledIconInfo?: string,
    shareLink?: string,
    optionsHeight?: number,
};

class ShareFilesOptionsComp extends React.Component<Props, ShareFilesOptionsState> {
    public state: ShareFilesOptionsState = {
        optionView: 'options',
        isPending: false,
        fbPermissionError: undefined,
        shareLink: undefined,
        optionsHeight: undefined,
    };

    private handleShareToFBClicked = () => {
        this.setState({...this.state, optionView: 'fb'});
    }

    private handleShareToEmailClicked = () => {
        this.setState({...this.state, optionView: 'email'});
    }

    private updateStateWithShare = (shareMethod: Promise<any>) => {
        this.setState({...this.state, isPending: true});
        shareMethod.then(() => {
            this.setState({...this.state, isPending: false});
            this.props.onConfirm();
        }).catch(() => {
            this.setState({...this.state, isPending: false});
        });
    }
    private getShareURL = (shareID: string) => location.origin + Pages.Share(shareID).url;

    private handleGetShareLink = () => {
        this.setState((s) => ({...s, optionView: 'copyLink'}));
        this.props.getShareLink()
        .then((shareID: string) => {
            this.setState((s) => ({...s, shareLink: this.getShareURL(shareID)}));
        })
        .catch(() => this.setState((s) => ({...s, optionView: 'options'})));
    }

    private onLinkCopied = () => {
        trackEvent('Share', 'clickedShareByCopying');
        this.props.onLinkCopied();
        this.props.onConfirm();
    }

    private handleConfirmShareEmail = (options: EmailSharingOptions) => {
        this.updateStateWithShare(this.props.shareToEmail(options));
    }

    private handleConfirmShareFB = (albumName: string) => {
        this.updateStateWithShare(new Promise((ok, onerror) => {
            this.props.shareToFB(albumName)
            .then(ok)
            .catch((error) => {
                    if (error.code && error.code === 10 || error.code === 200) { // FB permissions error
                    this.setState({...this.state, fbPermissionError: true});
                    onerror();
                }
            });
        }),
        );
    }

    private handleDisabledFBClick = () => {
        this.setState((s) => ({...s, disabledIconInfo: _('can_not_share_video_to_fb_album')}));
    }

    private shareToElem = React.createRef<HTMLDivElement>();

    public componentDidMount() {
        this.setState((s) => ({
            ...s, optionsHeight: this.shareToElem.current ? this.shareToElem.current.clientHeight : undefined,
        }));
    }

    public render() {
        const disabledArea = (
            <DisabledButtonInfoArea>
                {this.state.disabledIconInfo}
            </DisabledButtonInfoArea>
        );
        switch (this.state.optionView) {
            case 'options':
                // FB does not provide upload videos to album
                const disableShareToFB = this.props.files.some((f) => f.type === FileTarget.Movies);
                const internalShareOptions = [
                      <AddToAlbumButton key="addToAlbum" onClick={this.props.shareFileToAlbum}/>,
                ];
                const shareOptions = [
                    (
                    <ShareToFBButton
                        key="shareFB"
                        onClick={disableShareToFB ? this.handleDisabledFBClick : this.handleShareToFBClicked}
                        isDisabled={disableShareToFB}
                    />
                    ),
                    <ShareByMailButton key="shareByMail" onClick={this.handleShareToEmailClicked} />,
                    <GetLinkButton key="getLink" onClick={this.handleGetShareLink} />,
                ];
                return (
                    <OptionsOverlay
                        onClose={this.props.onCancel}
                        cancelButton={Button(_('cancel'), this.props.onCancel)}
                        isMobile={this.props.isMobile}
                    >
                        <div ref={this.shareToElem}>
                            <ShareToComponent
                                shareOptions={shareOptions}
                                internalOptions={internalShareOptions}
                                isMobile={this.props.isMobile}
                                bottomElement={disabledArea}
                            />
                        </div>
                    </OptionsOverlay>
                );
            case 'fb':
                return (
                    <SharePhotosToFBForm
                        isMobile={this.props.isMobile}
                        isPending={this.state.isPending}
                        onCancel={this.props.onCancel}
                        onConfirm={this.handleConfirmShareFB}
                        hasError={this.state.fbPermissionError}
                    />
                );
            case 'email':
                return (
                    <ShareToEmailForm
                        isMobile={this.props.isMobile}
                        isPending={this.state.isPending}
                        onCancel={this.props.onCancel}
                        onConfirm={this.handleConfirmShareEmail}
                    />
                );
            case 'copyLink':
                return (
                    <CopyShareLinkComponent
                        isMobile={this.props.isMobile}
                        isMobileDevice={isMobileDevice.any()}
                        onCancel={this.props.onCancel}
                        onConfirm={this.onLinkCopied}
                        link={this.state.shareLink}
                        height={this.state.optionsHeight}
                    />
                );
        }
    }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    shareFileToAlbum: () => dispatch(FilesOptionsTriggered({type: 'share_to_album'})),
    shareToFB: async (fbAlbumName: string) => {
        const reqPermissions = ['publish_actions', 'user_photos'];
        await getFBAccessTokenForceLogin(reqPermissions);

        const albumID = await getAlbumIDByName(fbAlbumName);
        return Promise.all(ownProps.files.map((f) => addPhotoToFBAlbum(albumID, f.thumbURLLarge)));
    },
    shareToEmail: async (options: EmailSharingOptions) => {
        const shareID = await createShareWithFiles(dispatch, options.subject, ownProps.files);
        if (shareID) {
            await publishJobByEmail(dispatch, shareID, options.recipients, options.message);
            dispatch(FilesWereShared());
        } else {
            throw Error('create share failed');
        }
    },
    getShareLink: async () => {
        const shareID = await createShareWithFiles(dispatch, '', ownProps.files);
        if (shareID) {
            return shareID;
        }
        else {
            throw Error('create share failed');
        }
    },
    onLinkCopied: () => dispatch(AlbumLinkWasCopied()),
});

export const ShareFilesOptions = connect(null, mapDispatchToProps)(ShareFilesOptionsComp);
