import * as React from 'react';
import {_} from '../../assets/localizedStrings';
import {openAlbumInApp} from '../../utilities/appLink';
import {FloatingPill} from '../Common/FloatingPill';

type OpenInAppButtonProps = {
    albumID: JobID,
};

export class OpenAlbumInAppButton extends React.Component<OpenInAppButtonProps, {}> {
    private goToApp = () => {
        openAlbumInApp(this.props.albumID);
    }

    public render() {
        return <FloatingPill action={this.goToApp} text={_('open_in_app')} />;
    }
}
