import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {CaptureIcon, CaptureIconWhite} from '../Icons/CaptureLogo';
import {RippleAnimationElement} from './RippleAnimationElement';

type Props = {
    size: number,
};

export const LoadingLogo: React.SFC<Props> = (props) => (
    <RippleAnimationElement
        illustration={<CaptureIcon size={props.size}/>}
        rippleSelector={'path#CaptureLogo_cloud_dot'}
        rippleColor={colors.captureBlue}
        baseOpacity={0.2}
        scale={4}
        animationDuration={1.3}
    />
);
export const LoadingLogoWhite: React.SFC<Props> = (props) => (
    <RippleAnimationElement
        illustration={<CaptureIconWhite size={props.size}/>}
        rippleSelector={'path#CaptureLogo_cloud_dot'}
        rippleColor={'white'}
        baseOpacity={0.4}
        scale={4}
        animationDuration={1.3}
    />
);
