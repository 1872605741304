import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets';
import {_} from '../../assets/localizedStrings';
import {UserInfo} from '../../state/users/selectors';
import {Avatar} from '../Common/Avatar';
import {Bullet} from '../Common/Bullet';
import {ButtonProps} from '../Common/Button';
import {TimeSinceString} from '../Common/TimeStrings';
import {CommentActionComponent} from './CommentActionComponent';

type Props = {
    now: Date,
    text: string,
    author: UserInfo,
    timeCreated: Date,
    currentUserCan: {
        editComment: boolean,
        deleteComment: boolean,
    },
    commentActions: ButtonProps[],
    isMobileMode: boolean,
    hasBeenEdited: boolean,
    editFailed: boolean,
    deleteFailed: boolean,
};

const CommentWrapper = styled.div`
    display: flex;
    margin: 8px 0;
`;

const AvatarWrapper = styled.div`
    text-align: center;
    display: inline-block;
    margin-right: 8px;
    height: 24px;
`;

const CommentContent = styled.div`
    align-self: center;
    flex: 1;

    font-size: 14px;
    word-break: break-word;
    word-wrap: break-word;
    max-width: 95%;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    span{
        line-height: 16px;
    }
`;

const CommentText = styled.span`
    color: ${colors.captureGrey800};
`;

const Name = styled.span`
    color: ${colors.captureGrey500};
    margin-right: 4px;
`;

const SubText = styled.div`
    color: ${colors.captureGrey500};
    display: ${(props: {showMore: boolean}) => props.showMore ? 'block' : 'inline-block'};
`;

const Comment = styled.span`
    margin-bottom: 4px;
`;
const Edited = styled.span`
    color: ${colors.captureGrey500};
    margin-left: 4px;
`;

const ErrorMessage = styled.span`
    color: ${colors.captureMagenta};
`;

export class SingleComment extends React.Component<Props> {

    public render() {
        const commentText = this.props.text.split('\n');
        const textNodes: React.ReactNode[] = [];

        commentText.forEach((line, i) => {
            if (i > 0) {
                textNodes.push(<br key={'br' + i}/>);
            }
            textNodes.push(<span key={i}>{line}</span>);
        });

        const canSeeMore = this.props.currentUserCan.deleteComment || this.props.currentUserCan.editComment;
        const moreElements = canSeeMore && (
            <CommentActionComponent
                commentActions={this.props.commentActions}
                isMobileMode={this.props.isMobileMode}
            />
        );

        let message = null;
        if (this.props.editFailed) {
            message = <ErrorMessage>{_('edit_comment_error')}</ErrorMessage>;
        }
        else if (this.props.deleteFailed) {
            message = <ErrorMessage>{_('delete_comment_error')}</ErrorMessage>;
        } else {
            message = <TimeSinceString then={this.props.timeCreated} now={this.props.now}/>;
        }

        return (
            <CommentWrapper className="comment">
                <AvatarWrapper>
                    <Avatar
                        name={this.props.author.name}
                        img={this.props.author.profilePicture}
                        size={24}
                    />
                </AvatarWrapper>
                <CommentContent>
                    <Comment>
                        <Name>{this.props.author.name}:</Name>
                        <CommentText>{textNodes}</CommentText>
                        {this.props.hasBeenEdited ? <Edited>{'(' + _('edited') + ')'}</Edited> : null}
                    </Comment>
                    <SubText showMore={canSeeMore}>
                        {moreElements}
                        <Bullet/>
                        {message}
                    </SubText>
                </CommentContent>
            </CommentWrapper>
        );
    }
}
