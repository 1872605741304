import * as React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {HOME_SITE_ADDRESS} from '../../../../config/constants';
import {_} from '../../assets';
import {fontSize} from '../../assets/styleConstants';
import {State} from '../../state';
import {isMobileMode} from '../../state/viewMode/selectors';
import {CaptureIconInactive} from '../Icons/CaptureLogo';
import {InfoContainer, InfoProps} from './InfoContainer';

const Description = styled.div`
    font-size: ${fontSize.small_12};
    margin-top: 46px;
    max-width: ${ (props: {mobile: boolean}) => props.mobile ? '100%' : '480px' };
    padding: 0 16px;
    line-height: 21px;
    letter-spacing: -0.24px;
`;

type StateProps = {
    isMobileMode: boolean,
};
type OwnProps = {
    title: string,
    description: string,
};
type Props = StateProps & OwnProps;

const _NotFoundPage: React.SFC<Props> = (props) => {
    const infoProps: InfoProps = {
        infoIcon: (<CaptureIconInactive size={78}/>),
        infoTitle: props.title,
        extLink: {
            link: HOME_SITE_ADDRESS,
            text: _('capture_more_link'),
        },
    };

    return (
        <InfoContainer {...infoProps}>
            <Description mobile={props.isMobileMode}>
                {props.description}
                <br /> <br />
                {_('capture_create_user_description')}
            </Description>
        </InfoContainer>
    );
};

const mapStateToProps = (state: State): StateProps => ({
    isMobileMode: isMobileMode(state),
});

const NotFoundPage = connect<StateProps, {}, OwnProps, State>(mapStateToProps)(_NotFoundPage);

export const AlbumNotFoundPage = () => (
    <NotFoundPage title={_('album_not_found')} description={_('album_not_found_description')} />
);
export const ShareNotFoundPage = () => (
    <NotFoundPage title={_('share_not_found')} description={_('share_not_found_description')} />
);
