import * as React from 'react';
import * as ReactDOM from 'react-dom';

import {
    FB_APP_ID, GOOGLE_ANALYTICS_ID__ALBUM_RECEIVER, GOOGLE_ANALYTICS_ID__ALL_PROJECTS,
} from '../../config/constants';
import {
    addTracker, GoogleAnalyticsMirrorTracker,
} from '../common/analytics';
import {enableFBSDK} from '../common/API/FBConnect/index';
import {loadAccountInfoIfAvailable} from '../common/API/login';
import {fonts} from '../common/assets/styleConstants';
import {bootstrap} from '../common/bootstrap';
import {createRouterBasedApp} from '../common/components/Common/RouterBasedApp';
import {SuggestModernBrowser} from '../common/components/Info/SuggestModernBrowser';
import {makeStore} from '../common/state/index';
import {isModernBrowser} from '../common/utilities/device';
import {setDocumentTitle} from '../common/utilities/documentTitle';
import {consoleLog} from '../common/utilities/logging';
import {GoogleAlbumTracker} from './analytics/GoogleAlbumTracker';
import {SENTRY_DSN__ALBUM_RECEIVER} from './config/constants';
import {AlbumReceiverRouter} from './routing/AlbumReceiverRouter';

import '../common/assets/globalStyle.scss';

bootstrap(() => {
    setDocumentTitle(); // Setting the default-title to have the localized version set by default
    addTracker(new GoogleAlbumTracker(GOOGLE_ANALYTICS_ID__ALBUM_RECEIVER));
    const mirrorID = GOOGLE_ANALYTICS_ID__ALL_PROJECTS; // Since TS does not understand type-checks if used directly.
    if (__PROD__ && mirrorID) {
        addTracker(new GoogleAnalyticsMirrorTracker(mirrorID, 'album_receiver'));
    }

    const store = makeStore({sentryDSN: SENTRY_DSN__ALBUM_RECEIVER});
    const RouterBasedApp = createRouterBasedApp(store);

    const root = document.createElement('div');
    root.setAttribute('id', 'app');
    root.setAttribute('style', 'font-family: ' + fonts.Lato);
    document.body.appendChild(root);

    if (isModernBrowser()) {
        consoleLog('Launching AlbumReceiver version ' + __VERSION__);
        loadAccountInfoIfAvailable(store.dispatch);

        enableFBSDK(FB_APP_ID);

        ReactDOM.render(<RouterBasedApp router={AlbumReceiverRouter} />, root);

        if (__DEV__ && module.hot) {
            module.hot.accept(
                './routing/AlbumReceiverRouter',
                () => {
                    ReactDOM.render(
                        <RouterBasedApp router={require('./routing/AlbumReceiverRouter').AlbumReceiverRouter} />,
                        root,
                    );
                },
            );
        }

    } else {
        ReactDOM.render(<SuggestModernBrowser />, root);
    }
});
