import {inArray} from '../../utilities/arrayUtils';
import {Action, isType} from '../common/actions';
import {FileRangeWasFetched, FileWasAddedToJob, FileWasRemovedFromJob} from './actions';

export type JobState = DictionaryOf<Job>;

type Job = {
    jobID: JobID,
    files: FileID[],
};

const makeJob = (jobID: JobID, files: FileID[] = []): Job => {
    return {jobID, files};
};

const initialState: JobState = {};

// TODO: Remove jobReducer,
// selectors should use filesReducer to get JobFileIDs
export const jobReducer = (state: JobState = initialState, action: Action<any>): JobState => {
    if (isType(action, FileRangeWasFetched)) {
        const job = state[action.payload.jobID] || makeJob(action.payload.jobID);
        const fetchedFileIDs = action.payload.files.map((f) => f.fileID);

        // De-duplicate new fetched file IDs and already fetched onces
        const fileList: DictionaryOf<boolean> = {};
        fetchedFileIDs.forEach((f) => fileList[f] = true);
        job.files.forEach((f) => fileList[f] = true);

        const files = Object.keys(fileList);
        const job_after_add = {...job, files};
        return {
            ...state,
            [action.payload.jobID]: job_after_add,
        };
    }

    // TODO: can not do de-duplicate by path and jobID,
    // should not in use when jobReducer is removed
    if (isType(action, FileWasAddedToJob)) {
        const job = state[action.payload.jobID] || makeJob(action.payload.jobID);
        if (inArray(job.files, action.payload.fileID)) {
            return state;
        }

        const files = [...job.files, action.payload.fileID];
        const job_after_add = {...job, files};
        return {
            ...state,
            [action.payload.jobID]: job_after_add,
        };
    }

    if (isType(action, FileWasRemovedFromJob)) {
        const job = state[action.payload.jobID] || makeJob(action.payload.jobID);
        const files =  job.files.filter((file) => file !== action.payload.fileID);
        const job_after_remove = {...job, files};
        return {
            ...state,
            [action.payload.jobID]: job_after_remove,
        };
    }

    return state;
};
