import {createSelector} from 'reselect';
import {makeScrollerGroups} from '../../components/TimelineScroller/makeScrollerGroups';
import {inArray} from '../../utilities/arrayUtils';
import {FileTarget} from '../../utilities/fileTarget';
import {
    calcImagesPerRow, computeGridContainerWidth, getElementSize, GridStyle,
} from '../../utilities/gridElementSizeCalculator';
import {CaptureFile, JobFile} from '../files/reducer';
import {getCaptureFilesForJob, getDocumentFilesForJob, getFiles} from '../files/selectors';
import {State} from '../store';
import {getViewportHeight, getViewportWidth, isMobileMode} from '../viewMode/selectors';
import {MonthFetchStatus, TimelineFilterMode, TimelineMonth} from './reducers';
import {getLastSeenElementForJob} from '../lastSeenElement/selectors';

export const getTimelineJobID = (state: State) => state.timeline.timelineJob;

export const getTimelineInitialLastChangeEventID = (state: State) => state.timeline.initialLastChange;

export const getTimelineFilterMode = (state: State): TimelineFilterMode => state.timeline.filter;

export const getTimelineMonths = (state: State): TimelineMonth[] => {
    return (state.timeline.months || []).filter((m) => m.count !== 0).sort((am, bm) => {
        if (am.year !== bm.year) {
            return bm.year - am.year;
        } else {
            return bm.month - am.month;
        }
    });
};

export const haveTimelineMonths = (state: State): boolean => {
    return state.timeline.months !== undefined;
};

export const getSelectedTimelineFileIDs = createSelector(
    (state: State) => state.timeline.selectedFiles,
    getFiles,
    (selecedFiles, files) => selecedFiles.filter((fileID) => files[fileID]),
);

export const isTimelineInSelectMode = (state: State) => state.timeline.isSelectMode;

export const getUnfetchedTimelineMonths: (state: State) => Month[] = createSelector(
    getTimelineMonths,
    (months) => {
        return months
            .filter((m) => m.fetchStatus === MonthFetchStatus.UNFETCHED)
            .map(({month, year}) => ({month, year}));
    },
);

const getFileTime = (f: JobFile) => new Date((f.ctime || f.mtime) * 1000);

const makeMonthGroupHeader = (f: JobFile): string => {
    return getFileTime(f).toISOString().slice(0, 7);
};

export const getTimelineFiles = (state: State) => {
    const jobID = getTimelineJobID(state);
    return jobID !== undefined ? getCaptureFilesForJob(state, jobID) : [];
};

export const getMonthByFileID = (state: State, fileID: FileID): string|undefined => {
    const files = getFiles(state);
    const file = files[fileID];
    if (file) {
        return makeMonthGroupHeader(file);
    }
};

export type TimelineGroupStyle = GridStyle & {
    headerHeight: number,
    headerBottomGap: number,
    groupBottomGap: number,
};
const timelineGroupStyleDesktop = {
    elementWidth: 180,
    elementHeight: 180,
    elementSpaceAround: 2,
    headerHeight: 30,
    headerBottomGap: 12,
    groupBottomGap: 44,
};
const timelineGroupStyleMobile = {
    elementWidth: 176,
    elementHeight: 176,
    elementSpaceAround: 2,
    headerHeight: 30,
    headerBottomGap: 8,
    groupBottomGap: 24,
};
const timelineMaxWidth =
    (timelineGroupStyleDesktop.elementWidth + (timelineGroupStyleDesktop.elementSpaceAround * 2)) * 8;

export const computeTimelineGroupStyle = (screenWidth: number, isMobile: boolean): TimelineGroupStyle => {
    const groupStyle = isMobile ? timelineGroupStyleMobile : timelineGroupStyleDesktop;
    return {
        ...groupStyle,
        width: computeGridContainerWidth(screenWidth, timelineMaxWidth, groupStyle),
    };
};
const computeTimelineGroupHeight = (
    groupImageCount: number,
    style: TimelineGroupStyle,
): number => {
    const elementSize = getElementSize(style);
    const imagesPerRow = Math.floor(style.width / elementSize.width);

    return (
        Math.ceil(groupImageCount / imagesPerRow) * elementSize.height
        + style.headerHeight + style.headerBottomGap + style.groupBottomGap
    );
};
export const getTimelineGroupStyle = createSelector(
    getViewportWidth,
    isMobileMode,
    computeTimelineGroupStyle,
);

export type ImageGroup = {
    header: string,
    imageCount: number, // will be different than images.length if the images for that month is not loaded
    images: CaptureFile[],
    imagesSelectedStatus: DictionaryOf<boolean>,
    height: number,
    position: number,
};

// An image is considered a screenshot if name of file begins wit screenshot_ (the Apps prefixes screenshots this way)
const isScreenshotRegexp = /(\/|^)screenshot_[^/]*$/;
export const timelineImageFilters: Record<TimelineFilterMode, (file: CaptureFile) => boolean> = {
    all: () => true,
    only_videos: (f) => f.type === FileTarget.Movies,
    only_images: (f) => f.type === FileTarget.Pictures,
    only_screenshots: (f) => f.type === FileTarget.Pictures && isScreenshotRegexp.test(f.path),
};

const timelineGroupCountByFilter: Record<TimelineFilterMode, (group: TimelineMonth) => number> = {
    all: (g) => g.count,
    only_videos: (g) => g.countGrouped ? g.countGrouped.videos : g.count,
    only_images: (g) => g.countGrouped ? g.countGrouped.images : g.count,
    only_screenshots: (g) => g.countGrouped ? g.countGrouped.screenshots : g.count,
};

export const getFilteredTimelineFiles: (s: State) => CaptureFile[] = createSelector(
    getTimelineFiles,
    getTimelineFilterMode,
    (files, filter) => files.filter(timelineImageFilters[filter]),
);

export const makeTimelineImageGroups = (
    files: CaptureFile[],
    timelineMonths: TimelineMonth[],
    selectedFiles: FileID[],
    groupStyle: TimelineGroupStyle,
    filterBy: TimelineFilterMode = 'all',
) => {
    const groupKeys: DictionaryOf<boolean> = {};
    const timelineImages: DictionaryOf<CaptureFile[]> = {};
    const timelineImagesSelectedStatus: DictionaryOf<DictionaryOf<boolean>> = {};

    files.filter(timelineImageFilters[filterBy])
        .forEach((file) => {
            const g = makeMonthGroupHeader(file);

            groupKeys[g] = true;
            timelineImages[g] = (timelineImages[g] || []);
            timelineImages[g].push(file);
            timelineImagesSelectedStatus[g] = timelineImagesSelectedStatus[g] || {};
            timelineImagesSelectedStatus[g][file.fileID] = inArray(selectedFiles, file.fileID);
        });

    const knownImageCount: DictionaryOf<number> = {};
    timelineMonths.forEach((tlGroup) => {
        if (tlGroup.fetchStatus !== MonthFetchStatus.FETCHED) {
            const g = tlGroup.year + (tlGroup.month > 9 ? '-' : '-0') + tlGroup.month;
            const num = timelineGroupCountByFilter[filterBy](tlGroup);
            if (num > 0) {
                groupKeys[g] = true;
                knownImageCount[g] = num;
            }
        }
    });
    let accumulatHeight = -8;
    return Object.keys(groupKeys).sort((a, b) => b.localeCompare(a))
        .map((groupKey: string): ImageGroup => {
            const images = (timelineImages[groupKey] || []);
            const imageCount = Math.max(images.length, knownImageCount[groupKey] || 0);
            const height = computeTimelineGroupHeight(imageCount, groupStyle);
            const position = accumulatHeight;
            accumulatHeight += height;
            const imagesSelectedStatus = timelineImagesSelectedStatus[groupKey] || {};

            return {
                header: groupKey,
                imageCount,
                images,
                imagesSelectedStatus,
                height,
                position,
            };
        });
};

export const getTimelineImageGroups: (s: State, f?: TimelineFilterMode) => ImageGroup[] = createSelector(
    getTimelineFiles,
    getTimelineMonths,
    getSelectedTimelineFileIDs,
    getTimelineGroupStyle,
    (_state: State, filterBy?: TimelineFilterMode) => filterBy,
    makeTimelineImageGroups,
);

export const getTimelineSelectionAction = (state: State) => state.timeline.selectionAction;

export type ScrollerMonthEntry = {
    monthStr: string,
    scrollerEntryKey: string,
};

export type ScrollerYearGroup = {
    yearStr: string,
    months: ScrollerMonthEntry[],
};

type ImageGroupSelector = (state: State, f?: TimelineFilterMode) => ImageGroup[];
type ScrollerGroupSelector = (state: State, f?: TimelineFilterMode) => ScrollerYearGroup[];

export const makeScrollerGroupSelector = (groupSelector: ImageGroupSelector): ScrollerGroupSelector => (
    createSelector(groupSelector, makeScrollerGroups)
);

export const getTimelineScrollerGroups = makeScrollerGroupSelector(getTimelineImageGroups);

export const isAllFilesFetched = (state: State) => state.timeline.allFilesFetched;

type DocumentFile = {
    fileID: FileID,
    fileName: string,
    uploadedTime: Date,
};

const getTimelineDocumentsFile = (state: State) => {
    const jobID = getTimelineJobID(state);
    return jobID === undefined ? [] : getDocumentFilesForJob(state, jobID);
};

export type DocumentFileGroup = {
    header: string,
    files: DocumentFile[],
};
export const getTimelineDocumentGroups = createSelector(
    getTimelineDocumentsFile,
    (files): DocumentFileGroup[] => {
        const groupKeys: DictionaryOf<boolean> = {};
        const documentGroups: DictionaryOf<DocumentFile[]> = {};

        files
            .filter((f) => !f.isPendingServerDelete)
            .forEach((f) => {
                const g = makeMonthGroupHeader(f);
                groupKeys[g] = true;

                const documentFile = {
                    fileID: f.fileID,
                    fileName: f.path,
                    uploadedTime: new Date(getFileTime(f)),
                };
                if (documentGroups[g] === undefined) {
                    documentGroups[g] = [documentFile];
                } else {
                    documentGroups[g].push(documentFile);
                }
            });

        return Object.keys(groupKeys)
            .sort((a, b) => b.localeCompare(a))
            .map((g) => ({
                header: g,
                files: documentGroups[g],
            }));
    },
);

const getLastSeenTimelineElement = (state: State) => {
    const timelineID = getTimelineJobID(state);
    if (timelineID) {
        return getLastSeenElementForJob(state, timelineID);
    }
}
export const getLastViewedRowPos = (style: GridStyle): (s: State, f?: TimelineFilterMode) => number|undefined => createSelector(
    getTimelineImageGroups,
    getLastSeenTimelineElement,
    (groups, lastSeenElement): number|undefined => {
        if (lastSeenElement) {
            const offsetData = groups.reduce(
                (acc, currGroup) => {
                    const imageIDs = currGroup.images.map((f) => f.fileID);
                    if (inArray(imageIDs, lastSeenElement)) {
                        return {
                            groupOffset: currGroup.position,
                            fileIndex: imageIDs.indexOf(lastSeenElement),
                        };
                    }
                    return acc;
                },
                {groupOffset: 0, fileIndex: 0},
            );

            const offset = offsetData.groupOffset +
                (Math.floor(offsetData.fileIndex / calcImagesPerRow(style)) * style.elementHeight); // inner group offset
            return offset;
        }
        return undefined;
    });
