import * as EXIF from 'exif-js';

const getOrientation = (file: File): Promise<number> => {
    return new Promise((resolve, reject) => {
        const f = new FileReader();
        f.onload = () => {
            try {
                const exif = EXIF.readFromBinaryFile(f.result);
                if (exif) {
                    resolve(exif);
                } else {
                    reject();
                }
            } catch (e) {
                // Since EXIF throws every now and again - catch the error here rather than later
                reject();
            }
        };
        f.onerror = () => reject();
        f.readAsArrayBuffer(file);
    });
};

const getRotatedImg = (file: File, orientation: 3|6|8): Promise<HTMLCanvasElement> => {
    return new Promise((resolve, reject) => {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (ctx !== null) {
            const dataURL = window.URL.createObjectURL(file);
            const img = new Image();

            img.onload = () => {
                const width = img.width;
                const height = img.height;

                // Place the image in a canvas
                canvas.width = ((orientation > 4) ? height : width); // Orientations > 4 are rotated 90°
                canvas.height = ((orientation > 4) ? width : height);

                switch (orientation) {
                    case 3:
                        // 180° rotate left
                        ctx.translate(width, height);
                        ctx.rotate(Math.PI);
                        break;
                    case 6:
                        // 90° rotate right
                        ctx.rotate(0.5 * Math.PI);
                        ctx.translate(0, -height);
                        break;
                    case 8:
                        // 90° rotate left
                        ctx.rotate(-0.5 * Math.PI);
                        ctx.translate(-width, 0);
                        break;
                }

                ctx.drawImage(img, 0, 0, width, height);
                window.URL.revokeObjectURL(dataURL);
                resolve(canvas);
            };

            img.onerror = () => {
                window.URL.revokeObjectURL(dataURL);
                reject('unable to load as image. Maybe it is a video or something else??');
            };

            img.src = dataURL;
        } else {
            reject();
        }
    });
};

export const getFileURLWithCorrectOrientation = async (file: File): Promise<string> => {
    try {
        const orientation = await getOrientation(file);

        switch (orientation) {
            case 3:
            case 6:
            case 8:
                const canvas = await getRotatedImg(file, orientation);
                return canvas.toDataURL();
        }
    } catch (e) {}

    return window.URL.createObjectURL(file);
};
