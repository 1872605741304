import {EventTracker} from '../eventTracking';
import {GATracker} from './GATracker';

export class GoogleAnalyticsMirrorTracker implements EventTracker {
    private tracker: GATracker;
    constructor(googleAnalyticsID: string, productName: string) {
        this.tracker = new GATracker(googleAnalyticsID, 'prism');
        this.tracker.set('dimension1', productName);
        this.tracker.sendEvent('initialized', productName);
    }

    public trackEvent() {
        // Ignored for Mirror-service
    }

    public trackPageChange(newPath: string) {
        this.tracker.set('page', newPath);
        this.tracker.sendPageview();
    }

    public trackUserID(userId: string) {
        this.tracker.set('userId', userId);
    }

    public trackAction() {
        // Ignored for Mirror-service
    }
}
