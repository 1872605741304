import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {Tooltip} from '../Common/Tooltip';
import {FullscreenExitIcon} from '../Icons/FullscreenExitIcon';
import {NextIcon} from '../Icons/NextIcon';
import {PauseIcon} from '../Icons/PauseIcon';
import {PlayIcon} from '../Icons/PlayIcon';
import {PreviousIcon} from '../Icons/PreviousIcon';

type Props = {
    startAutoPlay: () => any,
    stopAutoPlay: (disallowSystemAutoPlay?: boolean) => any,
    isPlaying: boolean,
    onExitClick: () => any,
    onPrevClick: () => any,
    onNextClick: () => any,
    hasPrev: boolean;
    hasNext: boolean;
};

const Wrapper = styled.div`
    width: 192px;
    height: 48px;
    background-color: rgba(0,0,0,0.7);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: space-around;

    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
`;

const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
    position: relative;
    cursor: pointer;
`;

const stopEventPropagation = (event: React.MouseEvent<any>) => {
    event.stopPropagation();
};

export class SlideShowControls extends React.PureComponent<Props, {}> {
    private userStoppedAutoPlay = () => {
        this.props.stopAutoPlay(true);
    }
    public render() {
        const prevButton = this.props.hasPrev && (
            <div onClick={this.props.onPrevClick}>
                <PreviousIcon size={24}/>
            </div>
        );
        const nextButton = this.props.hasNext && (
            <div onClick={this.props.onNextClick}>
                <NextIcon size={24}/>
            </div>
        );
        const controlButton = this.props.isPlaying ? (
            <Tooltip text={_('pause')} position={'top'}>
                <div onClick={this.userStoppedAutoPlay}><PauseIcon size={24}/></div>
            </Tooltip>
        ) : (
            <Tooltip text={_('play')} position={'top'}>
                <div onClick={this.props.startAutoPlay}><PlayIcon size={24}/></div>
            </Tooltip>
        );

        return (
            <Wrapper className="slideshow-controls" onClick={stopEventPropagation}>
                <IconWrapper>
                    <Tooltip text={_('previous')} position={'top'}>
                        {prevButton}
                    </Tooltip>
                </IconWrapper>
                <IconWrapper>{controlButton}</IconWrapper>
                <IconWrapper>
                    <Tooltip text={_('next')} position={'top'}>
                        {nextButton}
                    </Tooltip>
                </IconWrapper>
                <IconWrapper>
                    <Tooltip text={_('exit_fullscreen')} position={'top'}>
                        <div onClick={this.props.onExitClick}>
                            <FullscreenExitIcon size={24}/>
                        </div>
                    </Tooltip>
                </IconWrapper>
            </Wrapper>
        );
    }
}
