import {Store} from 'redux';
import {trackEvent} from '../../analytics/eventTracking';
import {Action, BulkOfActions} from '../../state/common/actions';
import {getUUIDOfLoggedInUser} from '../../state/currentUser/selectors';
import {
    CommentWasDeleted, FileWasCommented,
    IgnoredFileEvent,
} from '../../state/files/actions';
import {
    FileWasAddedToJob, FileWasRemovedFromJob, JobChangesWasFetched, JobInfoChangeDetected, UnableToFetchJobChanges,
} from '../../state/job/actions';
import {isStoryJob} from '../../state/jobInfo/selectors';
import {getInitialChangeEventID, getTheJobCurrentlyFetchingChangesFor} from '../../state/jobSyncing/selectors';
import {ReactionAdded, ReactionDeleted, Reactions} from '../../state/reaction/actions';
import {State} from '../../state/store';
import {getServiceProvider} from '../HostProvider';
import {fetchContributingUsers} from '../job';
import {AppService, JobChange, Reaction} from '../services/AppService';
import {PollResponseStatus, PollService} from '../services/PollService';

class JobChangesSyncer {
    private currentSeq = 0;
    private isRunning = false;
    private isCurrentlyFetching = false;
    private currentPoller?: AbortablePromise<PollResponseStatus>;
    constructor(private jobID: JobID,
                private handleChanges: (changes: JobChange[], newestSerial: number) => any,
                private errorHandler: (error: Error) => any,
                private newestSerialSeen: number,
    ) { }

    private handlePollerResponse = (resp: PollResponseStatus) => {
        this.currentPoller = undefined;
        switch (resp.status) {
            case 'ok':
                this.currentSeq = resp.seq;
                this.lookForChanges(true);
                break;
            case 'aborted':
                this.isRunning = false;
                break;
            default:
            case 'error':
                // Probable cause of error is longPoller timeout. This implies no changes for the lifetime of the request.
                // Wait 1s (to avoid flooding if caused by network error) and poll to again fetch new changes if they occur.
                setTimeout(this.poll, 1000);
                break;
        }
    }

    private poll = () => {
        getServiceProvider().getPollServiceForJob(this.jobID).then(
            (pollService: PollService) => {
                if (this.isRunning && !this.currentPoller) {
                    this.currentPoller = pollService.pollForChanges(this.jobID, this.currentSeq);
                    this.currentPoller.then(this.handlePollerResponse);
                }
            },
        );
    }

    public start = () => {
        this.isRunning = true;
        this.lookForChanges();
        this.poll();
    }

    public stop = () => {
        this.isRunning = false;
        if (this.currentPoller) {
            this.currentPoller.abort();
        }
    }

    public lookForChanges = (retryIfNoChanges = false) => {
        if (!this.isRunning || this.isCurrentlyFetching) {return; }
        this.isCurrentlyFetching = true;

        getServiceProvider().getAppServiceForJob(this.jobID).then(
            (ah: AppService) => ah.getJobChanges(this.jobID, this.newestSerialSeen).then(
                (changes: JobChange[]) => {
                    if (changes.length) {
                        this.newestSerialSeen = changes.reduce((a, c) => Math.max(a, c.serial), this.newestSerialSeen);
                        this.handleChanges(changes, this.newestSerialSeen);
                    } else {
                        // No changes detected from the poller. Maybe we were to quick? Retry later unless is a retry
                        if (retryIfNoChanges) {
                            setTimeout(this.lookForChanges, 400);
                        }
                    }

                    if (changes.length === 0 && this.newestSerialSeen === 0) {// no changes ever to job
                        this.errorHandler(new Error('no changes job'));
                    }
                },
                this.errorHandler,
            ).then(() => {this.isCurrentlyFetching = false; this.poll(); }),
        );
    }
}

type CommentedJobChange = JobChange & {
    comment_uuid: string,
    comment: string,
};

type ReactionJobChange = JobChange & {
    reaction: Reaction,
};

function jobChangeToJobAction(jobID: JobID, change: JobChange, currentUser: UserID): Action<any> {

    switch (change.type) {
        case 0: // Image was created
            return FileWasAddedToJob({
                jobID,
                fileID: change.id,
                user_uuid: change.user_uuid || currentUser,
                duration: change.duration,
                path: change.path,
                size: change.size,
                checksum: change.checksum,
                ctime: change.ctime,
                mtime: change.mtime,
                timestamp: change.timestamp,
                width: change.width,
                height: change.height,
            });
        case 1: // Image was deleted
            return FileWasRemovedFromJob({
                jobID,
                fileID: change.id,
            });
        case 10: // Comment added/changed
            // Type 10 is used backend for any comment-related change.
            // If the comment exists already it is supposed to be overwritten (handled by reducer as if it was added)
            // If the comment-text is an empty string, it means that the comment has been deleted.
            const commentedChange = change as CommentedJobChange;
            if (commentedChange.comment === '') {
                return CommentWasDeleted(commentedChange.comment_uuid);
            }
            return FileWasCommented({
                fileID: commentedChange.id,
                comment: commentedChange.comment,
                commentUUID: commentedChange.comment_uuid,
                timestamp: commentedChange.timestamp,
                userUUID: commentedChange.user_uuid || currentUser,
            });
        case 11: // Property-changes on JobInfo (changes on job not related to a single file)
            return JobInfoChangeDetected({jobID, eventID: change.serial});
        case 12: // Reaction added/deleted
            // reaction: 'love' -> added love reaction
            // reaction: '' -> deleted any reaction
            const reactionChange = change as ReactionJobChange;
            switch (reactionChange.reaction) {
                case 'love':
                    return ReactionAdded({
                        fileID: reactionChange.id,
                        reaction: Reactions.Love,
                        userUUID: reactionChange.user_uuid || currentUser,
                    });
                case '':
                    return ReactionDeleted({
                        fileID: reactionChange.id,
                        userUUID: reactionChange.user_uuid || currentUser,
                    });
                default:
                    console.warn('Unknown reaction change: ', reactionChange.reaction);
                    return IgnoredFileEvent();
            }
        default:
            console.warn('Unknown jobChange-type: ' + change.type, change);
            trackEvent('JobChanges', 'UnknownJobChangeType_' + change.type);
            return IgnoredFileEvent(); // To return some Action
    }

}

/**
 * The connected job-syncer observes the current redux-store to detect which job is currently in focus and observes the
 * state for that job and make changes available to the store (by dispatching actions when changes are detected).
 * It waits until the jobInfo for the job in focus is fetched to make sure the job is available for the user.
 */
class ConnectedJobChangesSyncer {
    private syncers: {[key: string]: JobChangesSyncer} = {};
    private currentJob: JobID|undefined;

    constructor(private store: Store<State>) {
        store.subscribe(() => {
            const state: State = store.getState();
            const displayedJob = getTheJobCurrentlyFetchingChangesFor(state);
            if (displayedJob !== this.currentJob) {
                this.handleChangedFocus(displayedJob);
            }
        });
    }

    private handleChangedFocus(newJobToWatch: JobID|undefined) {
        // Stop the poller for current job.
        if (this.currentJob && this.syncers[this.currentJob]) {
            this.syncers[this.currentJob].stop();
        }

        this.currentJob = newJobToWatch;
        if (newJobToWatch === undefined) {
            return;
        }

        // Reuse if we have fetched this job before
        if (! this.syncers[newJobToWatch]) {
            this.syncers[newJobToWatch] = new JobChangesSyncer(
                newJobToWatch,
                (changes, lastSerial) => {
                    // Since the changes-api is not including UUID for changes performed by current user,
                    // provide it as fallback here.
                    const currentUser: UserID = getUUIDOfLoggedInUser(this.store.getState()) || '';
                    const actions = changes
                        .map((e) => jobChangeToJobAction(newJobToWatch, e, currentUser));
                    actions.push(JobChangesWasFetched({jobID: newJobToWatch, lastSerial}));
                    this.store.dispatch(BulkOfActions(actions));

                    // Whenever changes to a job is detected, fetch the list of contributing users.
                    // TODO: check if changes introduces a new user and only fetch when new users are introduced
                    if (isStoryJob(this.store.getState(), newJobToWatch)) {
                        fetchContributingUsers(this.store.dispatch, newJobToWatch);
                    }
                },
                () => {
                    this.store.dispatch(UnableToFetchJobChanges(newJobToWatch));
                },
                getInitialChangeEventID(this.store.getState(), newJobToWatch),
            );
        }
        this.syncers[newJobToWatch].start();
    }

    public triggerManually() {
        if (this.currentJob && this.currentJob in this.syncers) {
            this.syncers[this.currentJob].lookForChanges();
        }
    }
}

let instance: ConnectedJobChangesSyncer|undefined;
export const connectJobChangesSyncer = (store: Store<State>) => {instance = new ConnectedJobChangesSyncer(store); };
export const triggerManualChangesPolling = () => {
    if (instance) {instance.triggerManually(); } else {console.warn('Must connectJobChangesSyncer before using it'); }
};
