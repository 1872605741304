import {Middleware} from 'redux';
import {deleteAlbum} from '../../API/album';
import {fetchListOfJobs} from '../../API/job';
import {
    getPendingAlbums, getRecentlyCompletedAlbumFromUploadedFileID, havePendingAutoCreatedAlbums,
} from '../albumList/selectors';
import {isType} from '../common/actions';
import {getCurrentUserUUID} from '../currentUser/selectors';
import {State} from '../store';
import {FileUploadSucceeded, UploaderStopped} from '../uploader/actions';
import {getCurrentOrNextUploadingFile} from '../uploader/selectors';
import {AutoGeneratedAlbumCanceled, AutoGeneratedAlbumFinished} from './actions';

export const autoAlbumMiddleware: Middleware = (store) => (next) => (action) => {
    const state: State = store.getState();
    if (havePendingAutoCreatedAlbums(state)) {
        if (isType(action, FileUploadSucceeded)) {
            const album = getRecentlyCompletedAlbumFromUploadedFileID(state, action.payload.fileID);
            if (album) {
                store.dispatch(AutoGeneratedAlbumFinished(album));
                const currentUser = getCurrentUserUUID(state);
                if (currentUser) {
                    fetchListOfJobs(store.dispatch, currentUser).catch();
                }
            }
        }

        if (isType(action, UploaderStopped)) {
            const nextFile = getCurrentOrNextUploadingFile(state);
            const pendingAlbums = getPendingAlbums(state);

            if (nextFile) {
                const album = pendingAlbums.filter((a) => a.tempID === nextFile.targetJob)[0];
                if (album) {
                    store.dispatch(AutoGeneratedAlbumCanceled(album));
                    deleteAlbum(store.dispatch, album.jobID);
                }
            }
        }
    }
    next(action);
};
