import * as React from 'react';
import {PRODUCT_NAME} from '../../../../config/constants';
import {_} from '../../assets';
import {Share} from '../../routing/pages';
import {localStorageSet} from '../../utilities/webStorage';
import {RequireLoginActionPage} from '../Info/RequireLoginActionPage';

type Props = {
    params: {shareID: string};
};

export class RequireLoginCopyShare extends React.Component<Props> {

    private copyToTimeline = () => {
        // Store addShareFilesToCapture in localStorage (to allow copying
        // files to timeline job after redirects)
        localStorageSet('addShareFilesToCapture', this.props.params.shareID);
    }

    public render() {
        return (
            <RequireLoginActionPage
                text={_('needs_login_to_copy_files_to_timeline').replace('%product_name%', PRODUCT_NAME)}
                targetAfterLogin={Share(this.props.params.shareID)}
                afterLoginAction={this.copyToTimeline}
            />
        );
    }
}
