import {connect, Dispatch} from 'react-redux';
import {_} from '../../assets/localizedStrings';
import {AlbumListFilterSet} from '../../state/albumList/actions';
import {AlbumListFilter} from '../../state/albumList/reducer';
import {getAlbumListFilter} from '../../state/albumList/selectors';
import {State} from '../../state/store';
import {FilterIconButton, FilterTextButton} from '../Common/FilterButton';
import {RadioButtonOption} from '../Common/RadioButtonGroup';

type StateProps = {
    current: AlbumListFilter,
    options: Array<RadioButtonOption<string>>,
    buttonText: string,
    isFilterApplied: boolean,
};

type DispatchProps = {
    onSelect: (filter: AlbumListFilter) => any,
};

const buttonText: Record<AlbumListFilter, string> =  {
    all: _('filter_albums'),
    shared: _('filtered_by_shared'),
    private: _('filtered_by_private'),
};
export const albumListFilterOptions: Array<RadioButtonOption<AlbumListFilter>> = [
    {
        text: _('all_albums'),
        value: 'all',
    },
    {
        text: _('shared_albums'),
        value: 'shared',
    },
    {
        text: _('private_albums'),
        value: 'private',
    },
];

const stateToProps = (state: State): StateProps => ({
    current: getAlbumListFilter(state),
    options: albumListFilterOptions,
    buttonText: buttonText[getAlbumListFilter(state)],
    isFilterApplied: getAlbumListFilter(state) !== 'all',
});

const dispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onSelect: (filter: AlbumListFilter) => dispatch(AlbumListFilterSet(filter)),
});

export const AlbumListFilterTextButton = connect(
    stateToProps,
    dispatchToProps,
)(FilterTextButton<AlbumListFilter>());
export const AlbumListFilterIconButton = connect(
    stateToProps,
    dispatchToProps,
)(FilterIconButton<AlbumListFilter>());
