import {getElementIndex} from '../../utilities/arrayUtils';
import {State} from '../store';
import {StatusNotification} from './reducer';

export const isPendingStatus = (msg: StatusNotification) => {
    return msg.type === 'filesAreBeingDeleted'
            || msg.type === 'filesAreBeingRestored'
            || msg.type === 'filesAreBeingCopied';
};

export const getStatusNotifications = (state: State): StatusNotification[] => {
    return state.statusNotifications.messages.filter((m, i, self) => {
        return !isPendingStatus(m) || getElementIndex(self, (e) => e.type === m.type) === i;
    });
};
