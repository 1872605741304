import * as React from 'react';
import styled from 'styled-components';
import {AlbumFile} from '../../state/album/selectors';
import {CaptureFile} from '../../state/files/reducer';
import {FileTarget} from '../../utilities/fileTarget';
import {AlbumImage} from '../Image/AlbumImage';
import {AlbumVideo, VideoPlayStatus} from '../Video/AlbumVideo';

const FileElementWrapper = styled.div`
    width: ${(props: {width: number, height: number}) => props.width}px;
    height: ${(props) => props.height}px;
    img{
        animation: fadeIn 0.3s ease-in both;
        width: ${(props) => props.width}px;
        height: ${(props) => props.height}px;
    }
`;
type Props = {
    currentFile: AlbumFile|CaptureFile,
    viewerNodeSize: {width: number, height: number},
    onVideoPlayStatusChanged?: (newStatus: VideoPlayStatus) => any,
    onImageDimensionsDetected?: (width: number, height: number) => any,
};
export class MediaElementComponent extends React.Component<Props> {

    private imageSizeChangedHandler = (imageWidth: number, imageHeight: number) => {
        const hasSize = this.props.currentFile.width === undefined
            || this.props.currentFile.height === undefined;
        if (hasSize && this.props.onImageDimensionsDetected) {
            this.props.onImageDimensionsDetected(imageWidth, imageHeight);
        }
    }

    private makeFileElement = (): React.ReactNode => {
        const file = this.props.currentFile;
        switch (file.type) {
            case FileTarget.Pictures:
                const image = (
                    <AlbumImage
                        key={file.fileID/* Key added to avoid dom-node-reuse when image changes */}
                        fileID={file.fileID}
                        thumbURL={file.thumbURLLarge}
                        onImageDimensionsDetected={this.imageSizeChangedHandler}
                    />
                );
                // Gotcha (CAPWEB-1420):
                // When height/width is unknown, the AlbumImage will render a stretched image while loading it.
                // When it is loaded it will dispatch the size, and we'll get new props with file.height set.
                // Load hidden (to detect size) and redraw in visible state once the size is known.
                if (file.height === undefined) {
                    return <div style={{visibility: 'hidden'}}>{image}</div>;
                }
                return image;
            case FileTarget.Movies:
                return (
                    <AlbumVideo
                        jobID={file.jobID}
                        fileID={file.fileID}
                        thumbURL={file.thumbURLLarge}
                        shouldAutoPlay={true}
                        onPlayStatusChanged={this.props.onVideoPlayStatusChanged}
                    />
                );
            default:
                return null;
        }
    }
    public render() {
        return (
            <FileElementWrapper
                width={this.props.viewerNodeSize.width}
                height={this.props.viewerNodeSize.height}
            >
                {this.makeFileElement()}
            </FileElementWrapper>
        );
    }
}
