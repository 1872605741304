let haveInjectedScript = false;
const injectScriptTag = () => {
    window['GoogleAnalyticsObject'] = 'ga';
    window.ga = window.ga || function(){(window.ga.q = window.ga.q || []).push(arguments); };
    window.ga.l = (new Date()).getTime();
    const a = document.createElement('script'); a.async = true; a.src = '//www.google-analytics.com/analytics.js';
    const m = document.getElementsByTagName('script')[0];
    if (m && m.parentNode) {
        m.parentNode.insertBefore(a, m);
    }
};

/**
 * GATracker exposes the functions of Google Analytics, allowing multiple trackers on the same site etc
 */
export class GATracker {
    constructor(googleAnalyticsID: string, private name: string = 'default') {
        if (! haveInjectedScript) {
            injectScriptTag();
            haveInjectedScript = true;
        }
        window.ga('create', googleAnalyticsID, 'auto', name);
        // GDPR-compliance requires IP-anonymisation on trackers
        this.set('anonymizeIp', true);
    }

    public set(key: string, value: string|number|boolean) {
        window.ga(this.name + '.set', key, value);
    }

    public sendEvent(context: string, event: string, label?: string, value?: number) {
        window.ga(this.name + '.send', 'event', context, event, label, value);
    }

    public sendPageview() {
        window.ga(this.name + '.send', 'pageview');
    }
}