import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {_, colors, StorageChart} from '../../assets';
import {State} from '../../state';
import {getUsedStorageRatio} from '../../state/currentUser/selectors';
import {PopupClosed} from '../../state/popup/actions';
import {StoragePopupType} from '../../state/popup/reducer';
import {FilledTextButton} from '../Common/IconTextButton';
import {PopupHorizontalLayout} from '../Popup/PopupHorizontalLayout';

const popupTexts: Record<StoragePopupType, {mainText: string, subText: string}> = {
    AddedMoreFilesThanAvailableStorage: {
        mainText: _('not_enough_storage_for_add'),
        subText: _('not_enough_storage_for_add_subText'),
    },
    RestoredMoreFilesThanAvailableStorage: {
        mainText: _('not_enough_storage_for_restore'),
        subText: _('not_enough_storage_for_restore_subText'),
    },
    UsedMoreThan80PercentOfStorage: {
        mainText: _('reached_given_storage_ratio'),
        subText: _('reached_given_storage_ratio_subText'),
    },
    UsedMoreThan95PercentOfStorage: {
        mainText: _('reached_given_storage_ratio'),
        subText: _('reached_given_storage_ratio_subText'),
    },
};

type StateProps = {
    type: StoragePopupType,
    usedStorageRatio: number,
};

type DispatchProps = {
    doClosePopup: (type: StoragePopupType) => any,
};

type Props = StateProps & DispatchProps;

export class _PopupPlacement extends React.Component<Props> {
    private doClosePopup = () => {
        this.props.doClosePopup(this.props.type);
    }

    public render() {
        if (!this.props.type) {
            return null;
        }
        const button = (
            <FilledTextButton
                onClick={this.doClosePopup}
                text={_('ok')}
                fillColor={colors.captureBlue}
            />
        );

        return (
            <PopupHorizontalLayout
                mainText={popupTexts[this.props.type].mainText}
                subText={<span>{popupTexts[this.props.type].subText}</span>}
                illustration={<StorageChart size={120} coloredRatio={this.props.usedStorageRatio} />}
                buttons={[button]}
            />
        );
    }
}

const stateToProps = (state: State): StateProps => ({
    type: state.popup.queue[0],
    usedStorageRatio: getUsedStorageRatio(state),
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doClosePopup: (type: StoragePopupType) => dispatch(PopupClosed({type})),
});

export const OutOfStoragePopupPlacement = connect(stateToProps, mapDispatchToProps)(_PopupPlacement);
