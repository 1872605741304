import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {isOpenInsideAppWebView} from '../../API/externals';
import {downloadTakeoutFile} from '../../API/takeout';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize, layout} from '../../assets/styleConstants';
import {State} from '../../state/store';
import {getFileList, TakeoutFile} from '../../state/takeout/selectors';
import {isMobileDevice} from '../../utilities/device';
import {bytesToSize} from '../../utilities/fileSizeFormatting';
import {FilledTextButton} from '../Common/IconTextButton';
import {CaptureIcon} from '../Icons/CaptureLogo';
import {trackTakeoutEvent} from './analytics';
import {FixedTopLeftLogoutButton} from './FixedTopLeftLogoutButton';
import {getTakeoutString} from './takeoutLocalizedStrings';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: ${layout.maxContainerWidth}px;
    margin: 5% auto;
`;

const HeaderText = styled.div`
    margin-top: 20px;
    font-size: ${fontSize.large_22};
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
`;

const DescriptionText = styled.div`
    margin-top: 25px;
    font-size: ${fontSize.medium_16};
    text-align: center;
    box-sizing: border-box;
    padding: 0 20px;
`;

const MobileNoticeText = styled.div`
    color: ${colors.captureGrey500};
    margin-top: 20px;
    font-size: ${fontSize.medium_16};
    font-style: italic;
    text-align: center;
    box-sizing: border-box;
    padding: 0 20px;
`;

const FileListWrapper = styled.div`
    width: 100%;
    margin-top: 10px;

    & > div:last-of-type {
        border-bottom: none;
    }
`;

const TakeoutFileWrapper = styled.div`
    display: flex;
    flex-direction: ${(props: {isMobile: boolean}) => (props.isMobile ? 'column' : 'row')};
    justify-content: ${(props) =>
        props.isMobile ? 'center' : 'space-between'};
    align-items: center;
    box-sizing: border-box;
    padding: 20px 0;
    margin: 0 20px;
    border-bottom: 1px solid ${colors.captureGrey300};
`;

const TakeoutFileInfo = styled.div`
    padding: 10px 0;
    font-size: ${fontSize.medium_16};
`;

const TakeoutfileSize = styled.span`
    color: ${colors.captureGrey500};
    font-style: italic;
    margin-left: 20px;
`;

type RowProps = {
    isMobile: boolean;
    file: TakeoutFile;
    onClickDownload: (fileURL: URLstring) => any;
};

type RowState = {
    isDownloadClicked: boolean;
};

class TakeoutFileRow extends React.Component<RowProps, RowState> {
    public state: RowState = {
        isDownloadClicked: false,
    };
    public componentDidMount() {
        trackTakeoutEvent('downloadPage_loaded');
    }

    private handleClick = () => {
        trackTakeoutEvent('downloadPage_downloadButtonClicked');
        this.props.onClickDownload(this.props.file.url);
        this.setState({
            ...this.state,
            isDownloadClicked: true,
        });
    }

    public render() {
        return (
            <TakeoutFileWrapper isMobile={this.props.isMobile}>
                <TakeoutFileInfo>
                    <span>{this.props.file.name}</span>
                    <TakeoutfileSize>
                        ({bytesToSize(this.props.file.size)})
                    </TakeoutfileSize>
                </TakeoutFileInfo>
                <FilledTextButton
                    onClick={this.handleClick}
                    text={_('download')}
                    fillColor={
                        this.state.isDownloadClicked
                            ? colors.captureGreen
                            : 'black'
                    }
                />
            </TakeoutFileWrapper>
        );
    }
}

type StateProps = {
    takeoutFiles: TakeoutFile[];
};

type DispatchProps = {
    onDownloadFile: (url: URLstring) => any;
};

type Props = StateProps & DispatchProps;

class TakeoutFilesDownloadComponent extends React.Component<Props> {
    public render() {
        const isMobile = isMobileDevice.any();

        const files = this.props.takeoutFiles.map((f, i) => {
            return (
                <TakeoutFileRow
                    key={i}
                    isMobile={isMobile}
                    file={f}
                    onClickDownload={this.props.onDownloadFile}
                />
            );
        });

        return (
            <Wrapper>
                {!isOpenInsideAppWebView() && <FixedTopLeftLogoutButton />}
                <CaptureIcon size={78} />
                <HeaderText>{getTakeoutString('takeout_service')}</HeaderText>
                <DescriptionText>
                    {getTakeoutString('download_description')}
                </DescriptionText>
                {isMobile && (
                    <MobileNoticeText>
                        {getTakeoutString('mobileNotice')}
                    </MobileNoticeText>
                )}
                <FileListWrapper>{files}</FileListWrapper>
            </Wrapper>
        );
    }
}

const mapStateToProps = (state: State): StateProps => ({
    takeoutFiles: getFileList(state),
});

const mapDispatchToProps = (_dispatch: Dispatch): DispatchProps => ({
    onDownloadFile: downloadTakeoutFile,
});

export const TakeoutFilesDownloadPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TakeoutFilesDownloadComponent);
