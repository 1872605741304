import React from 'react';
import styled from 'styled-components';
import {TELENOR_PRIVACY_SETTINGS_LINK} from '../../../../config/constants';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {BorderedTextButton} from '../Common/IconTextButton';
import {ShieldIcon} from '../Icons/ShieldIcon';

const Wrapper = styled.div`
    display: flex;
`;

const IconWrapper = styled.div`
    margin-right: 24px;

    display: flex;
    justify-content: flex-start;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;

    font-size: ${fontSize.medium_16};
`;

const Info = styled.div`
    margin-bottom: 24px;
`;

const PrivacyBtn = styled(BorderedTextButton)`
    width: 50%;
`;

const navigateToPrivacySettings = () => {
    window.open(TELENOR_PRIVACY_SETTINGS_LINK, '_blank');
};

export const PrivacySetting: React.SFC = () => {
    return (
        <Wrapper>
            <IconWrapper><ShieldIcon size={24} color={colors.captureBlue} /></IconWrapper>
            <InfoWrapper>
                <Info>{_('privacy_info')}</Info>
                <PrivacyBtn
                    text={_('open_privacy_pages')}
                    onClick={navigateToPrivacySettings}
                    color={colors.captureBlue}
                />
            </InfoWrapper>
        </Wrapper>
    );
};
