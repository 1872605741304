import {Action, isType} from '../common/actions';
import {
    CommentDeletionStarted, CommentWasDeleted, DeleteCommentCanceled,
    DeleteCommentClicked, DeleteCommentFailed, EditCommentCanceled, EditCommentClicked,
    EditCommentFailed, EditCommentSuccessful, EditedCommentSubmitted, FileWasCommented,
} from '../files/actions';

export enum UserActionStatus {
    PENDING_USER__EDIT,     // User updates text
    PENDING_SERVER__EDIT,   // Edited text submitted by user, waiting for server
    SERVER_ERROR__EDIT,     // Something went wrong when trying to save comment
    PENDING_USER__DELETE,   // Awaits user decision
    PENDING_SERVER__DELETE, // Waiting for server to delete comment
    SERVER_ERROR__DELETE,   // Something went wrong when trying to delete comment
}

export interface FileComment {
    fileID: FileID;
    commentUUID: CommentID;
    comment: string;
    timestamp: number;
    userUUID: string;
    userActionStatus?: UserActionStatus;
    originalComment?: string;
    lastEditTimestamp?: number;
}

export type CommentsState = DictionaryOf<FileComment>;

const initialState: CommentsState = {};

const newStateWithElementChanges = (state: CommentsState, elementId: CommentID, changes: any): CommentsState => {
    const element: FileComment = {
        ...state[elementId],
        ...changes,
    };

    return {
        ...state,
        [elementId]: element,
    };
};

export const commentsReducer = (state: CommentsState = initialState, action: Action<any>): CommentsState => {

    if (isType(action, FileWasCommented)) {
        // Backend emits same action-type for new comments as for edits. Only able to detect by inspecting state
        const oldComment = state[action.payload.commentUUID];
        const isExistingComment = oldComment !== undefined;
        if (isExistingComment && oldComment.comment !== action.payload.comment) {
            // Only mark as edit when text updates
            // (as identical actions are provided from UI and from backend when commenting)
            return newStateWithElementChanges(
                state,
                action.payload.commentUUID,
                {
                    comment: action.payload.comment,
                    lastEditTimestamp: action.payload.timestamp,
                },
            );
        } else if (!isExistingComment) {
            return newStateWithElementChanges(
                state,
                action.payload.commentUUID,
                action.payload,
            );
        } else {
            return state;
        }
    }

    if (isType(action, CommentWasDeleted)) {
        const {[action.payload]: omit, ...comments} = state;
        return comments;
    }

    if (isType(action, DeleteCommentClicked)) {
        if (state[action.payload.commentID] !== undefined) {
            return newStateWithElementChanges(
                state,
                action.payload.commentID,
                {
                    userActionStatus: UserActionStatus.PENDING_USER__DELETE,
                },
            );
        }
    }

    if (isType(action, CommentDeletionStarted)) {
        if (state[action.payload.commentID] !== undefined) {
            return newStateWithElementChanges(
                state,
                action.payload.commentID,
                {
                    userActionStatus: UserActionStatus.PENDING_SERVER__DELETE,
                },
            );
        }
    }

    if (isType(action, DeleteCommentCanceled)) {
        if (state[action.payload.commentID] !== undefined) {
            return newStateWithElementChanges(
                state,
                action.payload.commentID,
                {
                    userActionStatus: undefined,
                },
            );
        }
    }

    if (isType(action, DeleteCommentFailed)) {
        if (state[action.payload.commentID] !== undefined) {
            return newStateWithElementChanges(
                state,
                action.payload.commentID,
                {
                    userActionStatus: UserActionStatus.SERVER_ERROR__DELETE,
                },
            );
        }
    }

    if (isType(action, EditCommentClicked)) {
        const oldComment = state[action.payload.commentID];
        if (oldComment !== undefined) {
            return newStateWithElementChanges(
                state,
                action.payload.commentID,
                {
                    userActionStatus: UserActionStatus.PENDING_USER__EDIT,
                    originalComment: oldComment.comment,
                },
            );
        }
    }

    if (isType(action, EditCommentCanceled)) {
        const oldComment = state[action.payload.commentID];
        if (oldComment !== undefined) {
            return newStateWithElementChanges(
                state,
                action.payload.commentID,
                {
                    userActionStatus: undefined,
                    originalComment: oldComment.originalComment,
                },
            );
        }
    }

    if (isType(action, EditedCommentSubmitted)) {
        if (state[action.payload.commentID] !== undefined) {
            return newStateWithElementChanges(
                state,
                action.payload.commentID,
                {
                    comment: action.payload.text,
                    userActionStatus: UserActionStatus.PENDING_SERVER__EDIT,
                },
            );
        }
    }

    if (isType(action, EditCommentSuccessful)) {
        if (state[action.payload.commentID] !== undefined) {
            return newStateWithElementChanges(
                state,
                action.payload.commentID,
                {
                    userActionStatus: undefined,
                    lastEditTimestamp: action.payload.timestamp,
                },
            );
        }
    }

    if (isType(action, EditCommentFailed)) {
        const oldComment = state[action.payload.commentID];
        if (oldComment !== undefined) {
            return newStateWithElementChanges(
                state,
                action.payload.commentID,
                {
                    userActionStatus: UserActionStatus.SERVER_ERROR__EDIT,
                    comment: oldComment.originalComment,
                },
            );
        }
    }

    return state;
};
