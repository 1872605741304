import * as React from 'react';
import styled from 'styled-components';
import {colors, fontSize} from '../../assets/styleConstants';
import {CloseIcon} from '../Icons/CloseIcon';
import {EmptyIcon} from '../Icons/EmptyIcon';
import {ButtonProps} from './Button';
import {IconTextButton} from './IconTextButton';
import {OverlayModal} from './OverlayModal';

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: ${(props: {closeButtonHidden: boolean}) => props.closeButtonHidden ? 'flex-end' : 'space-between'};
    font-size: ${fontSize.small_12};
    font-weight: bold;
`;

type Props = {
    isMobile: boolean,
    onClose: () => any,
    cancelButton?: ButtonProps,
    confirmButton?: ButtonProps,
    title?: string,
};

export class OptionsOverlay extends React.Component<Props, {}> {
    public render() {
        const closeButton = this.props.cancelButton && (
            <IconTextButton
                onClick={this.props.cancelButton.onClick}
                text={this.props.cancelButton.text}
                icon={this.props.cancelButton.icon || CloseIcon}
            />
        );
        const confirmButton = this.props.confirmButton && (
            <IconTextButton
                onClick={this.props.confirmButton.onClick}
                text={this.props.confirmButton.text}
                icon={this.props.confirmButton.icon || EmptyIcon}
                color={this.props.confirmButton.isActive !== false ? colors.captureBlue : colors.captureGrey400}
                isActive={this.props.confirmButton.isActive}
            />
        );
        const headerText = this.props.title && (
            <div style={{marginTop: (closeButton || confirmButton) ? 24 : 0, paddingBottom: 4}}>{this.props.title}</div>
        );
        const headerElements = (
            <div>
                <ButtonsWrapper closeButtonHidden={!this.props.cancelButton}>
                    {closeButton}
                    {confirmButton}
                </ButtonsWrapper>
                {headerText}
            </div>
        );

        return (
            <OverlayModal onClose={this.props.onClose} isMobile={this.props.isMobile} headerElements={headerElements}>
                {this.props.children}
            </OverlayModal>
        );
    }
}
