import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {IconProps} from '../Icons';
import {AlbumIconHeart} from '../Icons/AlbumIcon';
import {FacebookIconSquare} from '../Icons/FacebookIcon';
import {LinkIcon} from '../Icons/LinkIcon';
import {MailIcon} from '../Icons/MailIcon';
import {SMSIcon} from '../Icons/SMSIcon';

type Props = {
    onClick: () => any,
};
const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const IconText = styled.span`
    color: ${colors.captureGrey500};
    font-size: ${fontSize.small_12};
    margin-top: 4px;
`;

const DisabledIcon = styled.div`
    opacity: 0.5;

    & > svg {
        display: block;
    }
`;

const ShareByOption: React.SFC<{icon: React.SFC<IconProps>, text: string, onClick: () => any, iconColor?: string}> = (props) => (
    <IconWrapper onClick={props.onClick}>
        <props.icon size={48} color={props.iconColor} />
        <IconText>{props.text}</IconText>
        {props.children}
    </IconWrapper>
);

export const ShareByMailButton: React.SFC<Props> = (props) => (
    <ShareByOption icon={MailIcon} text={_('email')} onClick={props.onClick} />
);
export const ShareBySMSButton: React.SFC<Props> = (props) => (
    <ShareByOption icon={SMSIcon} text={_('SMS')} onClick={props.onClick} />
);

export const GetLinkButton: React.SFC<Props> = (props) => (
    <ShareByOption icon={LinkIcon} text={_('get_link')} onClick={props.onClick} />
);

export const ShareToFBButton: React.SFC<Props & {isDisabled?: boolean}> = (props) => {
    const fbShareIcon = props.isDisabled ? (
            <DisabledIcon>
                <FacebookIconSquare size={48} />
            </DisabledIcon>
    ) : (
        <FacebookIconSquare size={48} />
    );
    return (
        <IconWrapper onClick={props.onClick}>
            {fbShareIcon}
            <IconText>Facebook</IconText>
        </IconWrapper>
    );
};

export const AddToAlbumButton: React.SFC<Props> = (props) => (
    <ShareByOption
        icon={AlbumIconHeart}
        iconColor={colors.captureGrey800}
        text={_('add_to_album')}
        onClick={props.onClick}
    />
);
