/**
 * Auto-generated translations-file.
 * Any changes to the strings are to be made in crowdin and built into this file using `yarn lang:build`.
 */

export const translatedStrings: DictionaryOf<any> = {
    "bg_BG": {
        "to": "до",
        "of": "на",
        "ok": "ОК",
        "yes": "Да",
        "got_it": "Разбрах",
        "see_more": "Вижте повече",
        "skip": "Пропусни",
        "no": "Не",
        "done": "Готово",
        "reactivate": "Активирай отново",
        "unsubscribe": "Отписване",
        "delete_": "Изтрийте",
        "edit": "Редакция",
        "update": "Актуализация",
        "cancel": "Отменете",
        "share": "Споделяйте",
        "undo": "Назад",
        "back": "Назад",
        "select_all": "Избери всички",
        "restore": "Възстанови",
        "filename": "име на файла",
        "file_type": "вид на файла",
        "deleted": "изтрит",
        "unknown": "неизвестен",
        "retry": "Опитайте отново",
        "create_account": "Създайте профил",
        "sign_in": "Вход",
        "logged_out_prompt": "Вие излязохте. За да продължите, моля влезте",
        "capture_save_see_share": "Запазвайте, разглеждайте и споделяйте всичките си снимки и клипове.",
        "feature_only_available_in_app": "Тази функция в момента е налице само в приложението Capture",
        "open_capture": "Отворете Capture",
        "open_in_app": "Отвори в приложението",
        "storage": "Пространство",
        "active": "Активни",
        "save": "Запазвайте",
        "january": "януари",
        "february": "февруари",
        "march": "март",
        "april": "април",
        "may": "май",
        "june": "юни",
        "july": "юли",
        "august": "август",
        "september": "септември",
        "october": "октомври",
        "november": "ноември",
        "december": "декември",
        "day": "Ден",
        "month": "Месец",
        "year": "Година",
        "monthly": "месечно",
        "yearly": "ежегодно",
        "jan": "ян.",
        "feb": "февр.",
        "mar": "март",
        "apr": "апр.",
        "jun": "юни",
        "jul": "юли",
        "aug": "авг.",
        "sep": "септ.",
        "oct": "окт.",
        "nov": "ноем.",
        "dec": "дек.",
        "delete_files_prompt__format": "%d файла ще бъдат преместени в папка Изтрити елементи. Тези файлове ще бъдат изтрити завинаги след 30 дни.",
        "delete_single_file_prompt": "Файлът ще бъде преместен в папка Изтрити елементи. Файлът ще бъде изтрит завинаги след 30 дни.",
        "delete_file_failed_message": "Файлът не може да бъде изтрит",
        "delete_file_failed_message__format": "При изтриването на \"%s\" възникна грешка. Моля, опитайте отново.",
        "delete_album_file_prompt_text": "Файлът ще бъде премахнат от албума. Всички коментари и харесвания, свързани с този файл, също ще бъдат изтрити.",
        "delete_last_file_prompt_text": "Файлът ще бъде премахнат от албума. Премахването на последния файл в този албум ще изтрие и албума.",
        "unsubscribe_album_prompt_text": "Вие вече няма да виждате този албум в списъка с албумите, нито ще получавате актуализации за него.",
        "delete_album_prompt_text": "Албумът ще бъде изтрит и премахнат от списъка с албумите.",
        "delete_comment_prompt_text": "Коментарът ще бъде изтрит.",
        "remove_device_prompt__format": "Вие ще бъдете отписани от %s на това устройство.",
        "cancel_storage_plan_prompt__format": "Потвърдете отмяната на текущия план за съхранение, запазете %storage_amount% до %expired_date% и няма да бъдете таксувани за в бъдеще.",
        "change_storage_plan_period_prompt": "Потвърдете промяна в текущия план на периода на фактуриране. Новият период на фактуриране ще започне незабавно",
        "upgrade_storage_plan_prompt__format": "Потвърдете промяната в текущия си план, ако искате да увеличите плана си за съхранение до %newSize%GB от %currentSize%GB. Промените ще влязат в сила незабавно",
        "downgrade_storage_plan_prompt__format": "Потвърдете промяната в текущия си план, ако искате да увеличите плана си за съхранение до %newSize%GB от %currentSize%GB. Вече използваното пространство няма да бъде изтрито.",
        "delete_single_document_file": "Файлът ще бъде изтрит завинаги.",
        "delete_document_files__format": "%d файла ще бъдат изтрити завинаги.",
        "restore_single_file_prompt": "Файлът ще бъде възстановен в снимките Ви.",
        "restore_files_prompt__format": "%d файла ще бъдат възстановени в снимките Ви.",
        "permanently_delete_single_file_prompt": "Файлът ще бъде изтрит завинаги. Това действие не може да бъде отменено.",
        "permanently_delete_files_prompt__format": "%d файла ще бъдат изтрити завинаги. Това действие не може да бъде отменено.",
        "wall": "Стена",
        "timeline": "Снимки",
        "albums": "Албуми",
        "your_albums": "Твоите албуми",
        "menu": "Меню",
        "add_files": "Добавяне на файлове",
        "documents": "Файлове",
        "settings": "Настройки",
        "account": "Профил",
        "help": "Помощ",
        "deleted_items": "Изтрити",
        "login": "Вход",
        "log_out": "Изход",
        "close": "Затвори",
        "wall_desc": "Лична стена с всичките Ви снимки, която можете да редактирате сам(а).",
        "timeline_desc": "Всичките Ви снимки и клипове, организирани по дати.",
        "albums_desc": "Добавяй снимки в албум и споделяйте с приятели!",
        "go_back_to_legacy_ribbon": "Благодарим Ви, че ползвате тази нова функция. Кликнете тук, за да се върнете към начален екран и за да получите достъп до всички функции.",
        "drag_drop": "Провлачете и пуснете ",
        "dropZone_text__timeline": "файлове за качване или папка за създаване на нов албум",
        "dropZone_text__albums": "файлове или папки за създаване на нови албуми",
        "dropZone_text__singleAlbum": "файлове отвсякъде за качване в %album_name%",
        "files": "файл(ове)",
        "uploaded": "качени",
        "did_not_upload": "неуспешно качени",
        "upload_folder_reject": "Не може да се качват папки",
        "upload_folder_safari_reject": "Safari не поддържа качването на папки",
        "unsupported_file_reject": "Неподдържан файл",
        "was_not_uploaded": "Не беше качен",
        "see_which": "Вижте кои",
        "offline": "Няма връзка с интернет",
        "upload": "Качване",
        "uploading": "Качване...",
        "retrying_in": "Повторен опит след",
        "sec": "сек.",
        "retrying_now": "Протича повторен опит...",
        "try_again": "Опитайте отново",
        "stop": "Стоп",
        "complete": "Завършено",
        "cancelled": "Отменено",
        "preparing": "Подготвяне...",
        "waiting_for_internet_connection": "Изчакване на интернет връзка...",
        "out_of_storage": "Недостатъчно пространство",
        "free_up_space": "Изтрийте файлове, за да възобновите качването",
        "cancelling_upload_are_you_sure": "Сигурен/сигурна ли сте, че искате да спрете качването?",
        "reached_given_storage_ratio": "Пространството се изчерпва",
        "reached_given_storage_ratio_subText": "Можете да освободите пространство като изтриете снимки или видео клипове, или като закупите още пространство от меню Настройки.",
        "not_enough_storage_for_add": "Недостатъчно пространство",
        "not_enough_storage_for_add_subText": "Нямате достатъчно пространство за всички файлове, които се опитвате да добавите. Освободете място, като изтриете снимки или видео клипове или купете още пространство от меню Настройки.",
        "not_enough_storage_for_restore": "Недостатъчно пространство",
        "not_enough_storage_for_restore_subText": "Нямате достатъчно пространство за всички файлове, които се опитвате да възстановите. Освободете място като изтриете снимки или видео клипове или купете още пространство от меню Настройки.",
        "timeline_is_empty": "Вашата хронология е празна.",
        "timeline_filtered_photos_empty": "Нямате никакви снимки.",
        "start_uploading_photos_now": "Защо не започнете да качвате снимки още сега?",
        "start_uploading_now": "Защо не започнете да качвате снимки още сега?",
        "drag_drop_photos_to_upload": "Можете да влачите и пускате снимките си тук или да натиснете бутона по-долу.",
        "download_app_to_upload_directly_from_device": "Инсталирайте приложението Capture на Вашия мобилен телефон, за да качвате снимки и клипове директно от камерата или галерията със снимки на Вашето устройство",
        "timeline_empty_screenshot_filter": "Нямате никакви скрийншоти. Браво на Вас!",
        "timeline_filtered_videos_empty": "Нямате никакви видео клипове.",
        "drag_drop_files_to_upload": "Можете да влачите и пускате Вашите файлове тук или да натиснете бутона по-долу.",
        "add_videos": "Добавете видео клипове",
        "selected__format": "%d избрани",
        "select_files": "Изберете файлове",
        "download_limit_exceeded": "Не можете да теглите повече от 500 обекти едновременно. Моля, изберете по-малко обекти.",
        "filter_by_screenshots": "Снимки на екрана",
        "filter_by_videos": "Клипове",
        "filter_by_images": "Снимки",
        "show_all": "Покажи всички",
        "album": "албум",
        "item__format": "%d обект",
        "items__format": "%d обекти",
        "participant__format": "%d участник",
        "participants__format": "%d участници",
        "participants": "Участници",
        "by": "от",
        "create_album": "Създайте албум",
        "auto_created_album_title": "Неозаглавен албум",
        "new_album": "Нов албум",
        "filter_by": "Филтриране по",
        "filter_albums": "Филтрирайте албумите",
        "filtered_by_private": "Филтрирани като лични",
        "filtered_by_shared": "Филтрирани като споделени",
        "all_albums": "Всички албуми",
        "shared_albums": "Споделени албуми",
        "private_albums": "Лични албуми",
        "download_album": "Изтеглете албум",
        "add_photos": "Добавете снимки",
        "add_to_album": "Добавете към албума",
        "error_msg_unsupported_media_type": "Ами сега! Нещо се обърка.",
        "you_and_others_love_message_format": "Вие и %d други харесвате това",
        "you_and_one_love_message": "Вие и 1 друг харесвате това",
        "you_love_message": "Вие харесвате това",
        "one_person_love_message": "1 човек харесва това",
        "people_love_message_format": "%d харесва това",
        "leave_album": "Излезте от албум",
        "unsubscribe_album_failed_message__format": "При опита да излезете от албума %s възникна грешка. Моля, опитайте отново.",
        "delete_album": "Изтрийте албум",
        "delete_file": "Изтрий файла",
        "download_file": "Изтеглете файла",
        "download_files_failed_message": "Възникна грешка при свалянето. Моля, опитайте отново.",
        "delete_album_failed_message__format": "При опита да изтриете албума %s възникна грешка. Моля, опитайте отново.",
        "set_as_cover_photo": "Задай като снимка на корицата",
        "set_cover_photo_failed_message": "Неуспешна промяна на основната снимка. Моля, опитайте отново.",
        "add_file_to_timeline": "Добавете в \"Снимки\"",
        "add_album_to_timeline": "Добавете албума в \"Снимки\"",
        "change_album_viewMode": "Променете изгледа",
        "sort_album_files": "Подредете албума",
        "sort_album_files_title": "Подредете албума по",
        "sort_album_files_by_added": "наскоро добавени",
        "sort_album_files_by_newest_first": "Първо най-новите",
        "sort_album_files_by_oldest_first": "Първо най-старите",
        "album_list_is_empty": "Все още нямате никакви албуми.",
        "empty_albumList_secondTitle": "Защо не създадете такъв още сега?",
        "empty_filtered_private_albumList_title": "Нямате никакви лични албуми.",
        "empty_filtered_shared_albumList_title": "Нямате никакви споделени албуми.",
        "empty_filtered_albumList_subtext": "Създаването на албум е лесно! Натиснете бутона по-долу, за да започнете.",
        "album_select": "Изберете албум",
        "create_new": "Създайте нов",
        "private_album": "Лични албуми",
        "shared_album": "Споделени албуми",
        "share_created_album_prompt_header": "Вие създадохте албум!",
        "share_created_album_prompt_text": "Споделете го с приятелите и семейството си, за да могат и те да добавят снимки и коментари към Вашия албум.",
        "share_later": "Споделете по-късно",
        "share_now": "Споделете сега",
        "comment": "коментар",
        "comments": "коментари",
        "write_your_comment": "Напишете коментара си",
        "comment_error_message": "Грешка при публикуването на коментара. Моля, опитайте отново.",
        "more": "Повече",
        "edited": "Редактирано",
        "edit_comment_error": "Коментарът не може да бъде редактиран",
        "delete_comment_error": "Коментарът не може да бъде изтрит",
        "view_all_comments": "Вижте всички коментари",
        "what_is_your_name": "Как се казвате?",
        "tap_here_to_enter_name": "Натиснете тук, за да въведете име",
        "click_here_to_enter_name": "Кликнете тук, за да въведете име",
        "enter": "въведете",
        "for_best_experience": "За най-добро преживяване",
        "register_or_sign_in": "регистрирайте се или се впишете сега!",
        "year_ago__format": "преди %d година",
        "years_ago__format": "преди %d години",
        "month_ago__format": "преди %d месец",
        "months_ago__format": "преди %d месеца",
        "day_ago__format": "преди %d ден",
        "days_ago__format": "преди %d дни",
        "hour_ago__format": "преди %d час",
        "hours_ago__format": "преди %d часа",
        "minute_ago__format": "преди %d минута",
        "minutes_ago__format": "преди %d минути",
        "just_now": "в момента",
        "post": "Публикуване",
        "slideshow": "Слайдшоу",
        "play": "Пусни",
        "pause": "Пауза",
        "previous": "Предишна",
        "next": "Следваща",
        "exit_fullscreen": "Изход",
        "info": "Информация",
        "releaseNotes_v0_header": "Нов и подобрен Capture!",
        "releaseNotes_v0_p1": "Откривайте снимките по-бързо с новите функции за бързо превъртане и филтриране",
        "releaseNotes_v0_p2": "Качвайте снимки, като плъзгате и поставяте навсякъде по екрана",
        "releaseNotes_v0_p3": "Създавайте, редактирайте и споделяйте албуми",
        "releaseNotes_v0_p4": "Добавяйте снимки и клипове в албумите заедно с приятелите и семейството си",
        "releaseNotes_v0_p5": "И още много...",
        "profile_info": "Информация за профила",
        "enter_your_name": "Въведете името си",
        "storage_management": "Управление на пространството",
        "total_storage": "Общо пространство",
        "remaining_storage": "Оставащо",
        "used_storage": "Използвано",
        "buy_more_storage": "Допълнително пространство",
        "connected_devices": "Включени устройства",
        "unlimited": "Неограничено",
        "your_storage_plan": "Вашият план за съхранение",
        "storage_used_out_of__format": "%storage_used% използвано от %storage_total%",
        "cancel_storage_plan": "Отменете плана за съхранение",
        "renew_status__format": "Планът ще бъде подновен на %s",
        "expired_status__format": "Планът ще изтече на %s",
        "reactivate_storage_plan": "Активирайте плана за съхранение",
        "payment_settings": "Настройки на плащането",
        "payment_settings_info_text": "Информация за Вашата регистрирана карта:",
        "update_card": "Променете информацията за карта",
        "card_number": "Номер на карта",
        "card_number_placeholder": "Въведете номера на картата си",
        "card_exp_date": "Дата на валидност",
        "card_cvc": "CVC/CVV",
        "confirm_payment": "Потвърдете плащането",
        "payment": "Плащане",
        "choose_payment_method": "Изберете Вашия метод на плащане",
        "pay_with_vipps": "Платете с Vipps",
        "save__format": "Спестете %d",
        "get_started": "Започнете!",
        "telenor_unlimited_hook__format": "Като клиент на Теленор мобайл, Вие имате достъп до неограничено пространство за %product_name%",
        "telenor_unlimited_hook_subtext": "Избираеми абонаменти: Fleksi/Medium/Trygg/Yng/U18/Frihet/Bedrift Fri+",
        "monthly_sub": "Месечен план",
        "yearly_sub": "Годишен план",
        "language": "Език",
        "select_language": "Изберете език",
        "privacy_info": "Прегледайте данните си и контролирайте настройките си от едно място.",
        "privacy": "Поверителност",
        "open_privacy_pages": "Отворете страниците за поверителност",
        "thanks_for_signing_in": "Благодарим Ви за регистрацията!",
        "get_capture_to_create_album": "Инсталирайте Capture на мобилното си устройство, за да създавате свои собствени албуми.",
        "return_to_album": "Връщане в албума",
        "telenor_capture_text": "Capture е разработено от Telenor Digital, компания на Telenor, която предоставя дигиталните решения на бъдещето. Профилът в Connect се използва за вход в услугите, предоставяни от Telenor Digital.",
        "terms_of_service": "Правила за ползване",
        "customer_service": "Обслужване на клиенти",
        "capture_footer_text_mobile": "За по-пълно изживяване, свалете приложението ",
        "open": "отвори",
        "download": "Изтеглете",
        "album_not_found": "Албумът не е намерен",
        "album_not_found_description": "Съжаляваме! Не успяхме да открием този албум. Възможно е линкът да е изписан погрешно или албумът да е бил изтрит.",
        "capture_create_user_description": "Capture е безопасна и лесна за ползване услуга за съхранение за снимки и други файлове онлайн. Ако все още не сте потребител на Capture, можете да се регистрирате.",
        "capture_more_link": "Научете повече за Capture ",
        "share_not_found": "Споделянето не е открито",
        "share_not_found_description": "Може би сте кликнали на изтекъл, изтрит или погрешно написан линк.",
        "page_not_found": "Не можахме да намерим страницата, която търсите!",
        "helpful_links_description": "Вместо това, ето някои полезни линкове",
        "helpful_links_about_capture": "За Capture",
        "password_required": "Необходима е парола",
        "password_input_placeholder": "Кликнете тук, за да въведете парола",
        "password_required_submit": "въведете",
        "password_incorrect": "Невалидна парола",
        "album_password_required_description": "Подателят е защитил този албум с парола. Моля, въведете паролата в полето по-горе, за да отключите албума.",
        "needs_connect_to_create_album": "Необходим Ви е %product_name% профил, за да създавате албуми",
        "needs_login_to_copy_files_to_timeline": "Необходим Ви е %product_name% профил, за да запазите тези файлове",
        "needs_login_to_copy_album_to_timeline": "Необходим Ви е %product_name% профил, за да запазите файловете от този албум",
        "no_title_yet": "Все още няма заглавие",
        "add_album_title": "Въведете заглавие на албума",
        "upgrade_your_browser": "Моля, актуализирайте браузъра си",
        "upgrade_your_browser_des": "Съжаляваме! Това съдържание не може да се гледа с текущата версия на Вашия браузър. Актуализирайте я или използвайте друг браузър като Chrome, Firefox или Microsoft Edge.",
        "share_album": "Споделете албум",
        "edit_album_settings": "Редактирайте настройките на албума",
        "enabled": "Позволено",
        "disabled": "Непозволено",
        "privacy_private": "Лично",
        "privacy_shared": "Споделено",
        "edit_album_title_text": "Заглавие на албума (Натиснете, за да промените):",
        "edit_allow_comments_text": "Всеки може да вижда и да добавя коментари",
        "edit_allow_upload_text": "Всеки може да добавя снимки",
        "edit_privacy_mode_text": "Ниво на поверителност",
        "toast__coverPhoto_was_set": "Основната снимка е променена",
        "toast__multiple_files_deleted__format": "%d файла бяха изтрити",
        "toast__single_file_deleted": "Файлът беше изтрит",
        "toast__multiple_files_perm_deleted__format": "%d файла бяха изтрити завинаги",
        "toast__single_file_perm_deleted": "Файлът беше изтрит завинаги",
        "toast__multiple_files_perm_deleted_failed__format": "Грешка - %d файла не бяха изтрити завинаги",
        "toast__single_file_perm_deleted_failed": "Грешка - файлът не беше изтрит завинаги",
        "toast__single_file_restored": "Файлът беше възстановен",
        "toast__multiple_files_restored__format": "%d файла бяха възстановени",
        "toast__single_file_restored_failed": "Грешка - файлът не беше възстановен",
        "toast__multiple_files_restored_failed__format": "Грешка - %d файла не бяха възстановени",
        "toast__single_file_added_to_album__format": "Файлът беше добавен към албум \"%album_name%\"",
        "toast__multiple_files_added_to_album__format": "%d файла бяха добавени към албум \"%album_name%\"",
        "toast__single_file_added_to_album_failed__format": "Грешка - файлът не беше добавен",
        "toast__multiple_file_added_to_album_failed__format": "Грешка - %d файла не бяха добавени",
        "toast__album_auto_crated__format": "Албумът \"%album_name%\" е създаден за Вас",
        "toast__see_album": "Вижте албума",
        "toast__album_added_to_timeline": "Албумът е добавен в Снимки",
        "toast__album_added_to_timeline_failed": "Неуспешно добавяне на албум. Моля, опитайте отново",
        "toast__file_added_to_timeline": "Файлът е добавен в Снимки",
        "toast__file_added_to_timeline_failed": "Неуспешно добавяне на файла. Моля, опитайте отново",
        "toast__files_were_shared": "Вашите файлове бяха споделени!",
        "toast__files_are_deleting": "Изтриване на файлове...",
        "toast__files_are_restoring": "Възстановяване на файлове...",
        "toast__files_are_copying": "Копиране на файлове...",
        "toast__credit_card_updated": "Информацията за кредитната карта е актуализирана",
        "toast__credit_card_update_failed": "Информацията не успя да се актуализира",
        "toast__purchase_successful": "Успешна покупка на план за пространство",
        "toast__purchase_failed": "Неуспешна покупка на план за пространство",
        "toast__plan_canceled": "Планът за пространството е отменен",
        "toast__plan_cancel_failed": "Планът за пространството не успя да се отмени",
        "toast__plan_change_succeeded": "Планът беше успешно актуализиран",
        "toast__plan_change_failed": "Планът не успя да се актуализира",
        "toast__plan_reactivated": "Планът за пространството е активиран",
        "toast__plan_reactivation_failed": "Планът за пространството не успя да се активира",
        "trash_delete_info": "Изтритите файлове ще бъдат изтрити завинаги след 1 месец.",
        "trash_no_files": "Вие нямате изтрити файлове.",
        "trash_restore_info": "Вие можете да ги възстановите по всяко време преди това!",
        "documents_no_files": "Нямате никакви файлове.",
        "share_to": "Споделете чрез",
        "email": "Имейл",
        "SMS": "SMS",
        "get_link": "Копирайте линка",
        "hold_to_copy_link": "Натиснете и задръжте, за да копирате линка",
        "copy_link": "Копирайте линка",
        "link_copied": "Линкът беше копиран!",
        "share_files_success_header": "Вие споделихте албум!",
        "can_not_share_video_to_fb_album": "Видеоклиповете не могат да бъдат споделяни във Facebook албума",
        "send_email_to": "Изпратете имейл на:",
        "enter_receivers_address": "Въведете адреса на получателя тук",
        "email_subject": "Относно:",
        "enter_subject": "Въведете темата тук",
        "email_message": "Съобщение (по избор):",
        "enter_message": "Въведете съобщението си тук",
        "invalid_input": "Невалидно",
        "fb_album_share_desc": "Озаглавете Facebook албума",
        "enter_fb_album_name": "Име на Facebook албума",
        "fb_permissions_error": "Опа! За да споделяте снимките си във Фейсбук, трябва да дадете разрешение да публикуваме на Вашия профил.",
        "fb_permissions_cta": " След като натиснете \"готово\", ще Ви напомним за тези разрешения.",
        "share__header_SingleFile__format": "%user_name% сподели файл с вас чрез %product_name%",
        "share__header_MultipleFiles__format": "%user_name% сподели няколко файла с вас чрез %product_name%",
        "share__add_singleFile_to_capture__format": "Добавете файл към своя %s",
        "share__add_multipleFiles_to_capture__format": "Добавете файлове към своя %s",
        "download_all": "Изтегли всички",
        "share_added_to_timeline": "Файлове бяха добавени",
        "share_added_to_timeline_failed": "Неуспешно добавяне на файлове. Моля, опитайте отново",
        "share_failed": "Неуспешно споделяне на файлове. Моля, опитайте отново",
        "share__not_capture_user": "Не сте потребител на Capture?",
        "share__try_capture": "Кликнете тук, за да го изпробвате!",
        "share__service_info": "Capture е безопасна и безплатна услуга за съхранение на всички ваши снимки и видео клипове. Може да получите допълнително пространство за съхранение с абонаментен план на Теленор.",
        "share_password_required_description": "Подателят е защитил това споделяне с парола. Моля, въведете паролата в полето по-горе, за да видите споделянето."
    },
    "sv_SE": {
        "to": "till",
        "of": "av",
        "ok": "OK",
        "yes": "Ja",
        "got_it": "Jag förstår",
        "see_more": "Se mer",
        "skip": "Hoppa över",
        "no": "Nej",
        "done": "Klar",
        "reactivate": "Återaktivera",
        "unsubscribe": "Avsluta prenumerationen",
        "delete_": "Radera",
        "edit": "Redigera",
        "update": "Uppdatera",
        "cancel": "Avbryt",
        "share": "Dela",
        "undo": "Ångra",
        "back": "Tillbaka",
        "select_all": "Markera alla",
        "restore": "Återställ",
        "filename": "filnamn",
        "file_type": "filtyp",
        "deleted": "raderad",
        "unknown": "okänd",
        "retry": "Försök igen",
        "create_account": "Skapa ett konto",
        "sign_in": "Logga in",
        "logged_out_prompt": "Ojdå, du loggades ut. Logga in för att fortsätta",
        "capture_save_see_share": "Spara, se och dela alla dina bilder och videor.",
        "feature_only_available_in_app": "Denna funktion är för tillfället endast tillgänglig i appen Capture.",
        "open_capture": "Öppna Capture",
        "open_in_app": "Öppna i appen",
        "storage": "Lagring",
        "active": "Aktiv",
        "save": "Spara",
        "january": "Januari",
        "february": "Februari",
        "march": "Mars",
        "april": "April",
        "may": "Maj",
        "june": "Juni",
        "july": "Juli",
        "august": "Augusti",
        "september": "September",
        "october": "Oktober",
        "november": "November",
        "december": "December",
        "day": "Dag",
        "month": "Månad",
        "year": "År",
        "monthly": "månatlig",
        "yearly": "årsvis",
        "jan": "Jan",
        "feb": "Feb",
        "mar": "Mar",
        "apr": "Apr",
        "jun": "Jun",
        "jul": "Jul",
        "aug": "Aug",
        "sep": "Sep",
        "oct": "Okt",
        "nov": "Nov",
        "dec": "Dec",
        "delete_files_prompt__format": "%d filer kommer att flyttas till Raderade objekt. Filerna kommer att raderas permanent efter 30 dagar.",
        "delete_single_file_prompt": "Filen kommer att flyttas till Raderade objekt. Filen kommer att raderas permanent efter 30 dagar.",
        "delete_file_failed_message": "Filen kunde inte raderas",
        "delete_file_failed_message__format": "Ett fel uppstod vid försöket att radera \"%s\". Vänligen försök igen.",
        "delete_album_file_prompt_text": "Filen kommer att tas bort från album. Alla kommentarer och älskar relaterade till denna fil kommer också att raderas.",
        "delete_last_file_prompt_text": "Filen kommer att tas bort från album. Borttag av den sista filen i ett album kommer också radera albumet.",
        "unsubscribe_album_prompt_text": "Du kommer inte längre kunna se detta album i din albumlista eller få uppdateringar om detta album.",
        "delete_album_prompt_text": "Albumet kommer att raderas och tas bort från din albumlista.",
        "delete_comment_prompt_text": "Kommentaren kommer att raderas.",
        "remove_device_prompt__format": "Du kommer att loggas ut från %s på denna enhet.",
        "cancel_storage_plan_prompt__format": "Bekräfta för att avbryta aktuell lagringsplan, behåll %storage_amount% fram till %expired_date% och inga ytterligare debiteringar kommer utföras.",
        "change_storage_plan_period_prompt": "Bekräfta ändring av aktuell plans faktureringsperiod. Den nya faktureringsperioden kommer börja omedelbart",
        "upgrade_storage_plan_prompt__format": "Bekräfta ändring i aktuell plan om du vill öka på din lagringsplan till %newSize%GB från %currentSize%GB. Ändringarna kommer träda i kraft omedelbart",
        "downgrade_storage_plan_prompt__format": "Bekräfta ändring i aktuell plan om du vill minska på din lagringsplan till %newSize%GB från %currentSize%GB. All lagring som redan används kommer inte att raderas.",
        "delete_single_document_file": "Filen kommer att raderas permanent.",
        "delete_document_files__format": "%d filer kommer att raderas permanent.",
        "restore_single_file_prompt": "Filen kommer att återställas till dina bilder.",
        "restore_files_prompt__format": "%d filer kommer att återställas till dina bilder.",
        "permanently_delete_single_file_prompt": "Filen kommer att raderas permanent. Denna åtgärd kan inte ångras.",
        "permanently_delete_files_prompt__format": "%d filer kommer att raderas permanent. Denna åtgärd kan inte ångras.",
        "wall": "Vägg",
        "timeline": "Bilder",
        "albums": "Album",
        "your_albums": "Dina album",
        "menu": "Meny",
        "add_files": "Lägg till filer",
        "documents": "Filer",
        "settings": "Inställningar",
        "account": "Konto",
        "help": "Hjälp",
        "deleted_items": "Raderade objekt",
        "login": "Inloggning",
        "log_out": "Logga ut",
        "close": "Stäng",
        "wall_desc": "En personlig vägg med alla dina bilder, som du kan redigera själv.",
        "timeline_desc": "Alla dina bilder och videor, ordnade efter datum.",
        "albums_desc": "Lägg till bilder i ett album och dela med dina vänner!",
        "go_back_to_legacy_ribbon": "Tack för att du använder den här nya funktionen. Klicka här för att återgå till den ordinarie webben och få åtkomst till alla funktioner.",
        "drag_drop": "Dra & släpp",
        "dropZone_text__timeline": "filer för att ladda upp dem, eller en mapp för att skapa ett nytt album",
        "dropZone_text__albums": "filer eller mappar för att skapa nya album",
        "dropZone_text__singleAlbum": "filer varsomhelst för att ladda upp dem till %album_name%",
        "files": "fil(er)",
        "uploaded": "uppladdade",
        "did_not_upload": "kunde inte laddas upp",
        "upload_folder_reject": "Kan inte ladda upp mappar",
        "upload_folder_safari_reject": "Safari stöder inte uppladdningsmappar",
        "unsupported_file_reject": "Filen stöds inte",
        "was_not_uploaded": "Laddades inte upp",
        "see_which": "Se vilken",
        "offline": "Ingen internetanslutning",
        "upload": "Ladda upp",
        "uploading": "Laddar upp",
        "retrying_in": "Försöker igen om",
        "sec": "sek",
        "retrying_now": "Försöker igen nu...",
        "try_again": "Försök igen",
        "stop": "Stopp",
        "complete": "Slutförd",
        "cancelled": "Avbruten",
        "preparing": "Förbereder...",
        "waiting_for_internet_connection": "Väntar på internetanslutning...",
        "out_of_storage": "Slut på lagringsutrymme",
        "free_up_space": "Radera filer för att återuppta uppladdning",
        "cancelling_upload_are_you_sure": "Är du säker på att du vill avbryta uppladdningen?",
        "reached_given_storage_ratio": "Lagringsutrymmet är nästan fullt",
        "reached_given_storage_ratio_subText": "Du kan frigöra utrymme genom att radera bilder eller videor, eller köpa mer utrymme under Inställningar.",
        "not_enough_storage_for_add": "Otillräckligt lagringsutrymme",
        "not_enough_storage_for_add_subText": "Du har inte tillräckligt med lagringsutrymme för alla de filer du försöker lägga till. Frigör utrymme genom att radera bilder eller videor, eller köp mer lagringsutrymme under Inställningar.",
        "not_enough_storage_for_restore": "Otillräckligt lagringsutrymme",
        "not_enough_storage_for_restore_subText": "Du har inte tillräckligt utrymme för alla filer du försöker återställa. Frigör utrymme genom att radera bilder eller videor, eller köp mer utrymme under Inställningar.",
        "timeline_is_empty": "Din tidslinje är tom.",
        "timeline_filtered_photos_empty": "Du har inga foton.",
        "start_uploading_photos_now": "Varför börjar du inte ladda upp foton nu?",
        "start_uploading_now": "Varför börjar du inte ladda upp lite nu?",
        "drag_drop_photos_to_upload": "Du kan dra och släppa dina foton här eller trycka på knappen nedan.",
        "download_app_to_upload_directly_from_device": "Installera Capture-appen på din mobila enhet för att ladda upp foton och videor direkt från din enhets kamera och fotogalleri",
        "timeline_empty_screenshot_filter": "Du har inga skärmbilder. Bra för dig!",
        "timeline_filtered_videos_empty": "Du har inga videor.",
        "drag_drop_files_to_upload": "Du kan dra och släppa dina filer här eller trycka på knappen nedan.",
        "add_videos": "Lägg till videor",
        "selected__format": "%d vald",
        "select_files": "Välj filer",
        "download_limit_exceeded": "Du kan inte ladda ned mer än 500 objekt vid ett tillfälle. Vänligen välj färre objekt.",
        "filter_by_screenshots": "Skärmdumpar",
        "filter_by_videos": "Videor",
        "filter_by_images": "Bilder",
        "show_all": "Visa alla",
        "album": "album",
        "item__format": "%d objekt",
        "items__format": "%d objekt",
        "participant__format": "%d deltagare",
        "participants__format": "%d deltagare",
        "participants": "Deltagare",
        "by": "av",
        "create_album": "Skapa album",
        "auto_created_album_title": "Namnlöst album",
        "new_album": "Nytt album",
        "filter_by": "Filtrera efter",
        "filter_albums": "Filtrera album",
        "filtered_by_private": "Filtrerat efter privata",
        "filtered_by_shared": "Filtrerat efter delade",
        "all_albums": "Alla album",
        "shared_albums": "Delade album",
        "private_albums": "Privata album",
        "download_album": "Ladda ned album",
        "add_photos": "Lägg till bilder",
        "add_to_album": "Lägg till i album",
        "error_msg_unsupported_media_type": "Ojdå! Nånting gick fel.",
        "you_and_others_love_message_format": "Du och %d andra älskar detta",
        "you_and_one_love_message": "Du och en annan älskar detta",
        "you_love_message": "Du älskar detta",
        "one_person_love_message": "En älskar detta",
        "people_love_message_format": "%d älskar detta",
        "leave_album": "Lämna album",
        "unsubscribe_album_failed_message__format": "Ett fel uppstod vid försöket att lämna album %s. Vänligen försök igen.",
        "delete_album": "Radera album",
        "delete_file": "Radera fil",
        "download_file": "Ladda ned fil",
        "download_files_failed_message": "Ett fel uppstod vid försöket att ladda ned. Vänligen försök igen senare.",
        "delete_album_failed_message__format": "Ett fel uppstod vid försöket att radera album %s. Vänligen försök igen.",
        "set_as_cover_photo": "Använd som omslagsfoto",
        "set_cover_photo_failed_message": "Omslagsfotot kunde inte ändras. Vänligen försök igen.",
        "add_file_to_timeline": "Lägg till i \"Bilder\"",
        "add_album_to_timeline": "Lägg till album i \"Bilder\"",
        "change_album_viewMode": "Ändra visningsläge",
        "sort_album_files": "Sortera album",
        "sort_album_files_title": "Sortera album efter",
        "sort_album_files_by_added": "Nyligen tillagda",
        "sort_album_files_by_newest_first": "Nyast först",
        "sort_album_files_by_oldest_first": "Äldst först",
        "album_list_is_empty": "Du har inga album ännu.",
        "empty_albumList_secondTitle": "Varför skapar du inte ett nu?",
        "empty_filtered_private_albumList_title": "Du har inga privata album.",
        "empty_filtered_shared_albumList_title": "Du har inga delade album.",
        "empty_filtered_albumList_subtext": "Det är enkelt att göra ett album! Tryck på knappen nedan för att starta.",
        "album_select": "Välj ett album",
        "create_new": "Skapa nytt",
        "private_album": "Privat album",
        "shared_album": "Delat album",
        "share_created_album_prompt_header": "Du har skapat ett album!",
        "share_created_album_prompt_text": "Dela det med vänner och familj, så att de kan lägga till bilder och kommentarer i ditt album.",
        "share_later": "Dela senare",
        "share_now": "Dela nu",
        "comment": "kommentar",
        "comments": "kommentarer",
        "write_your_comment": "Skriv en kommentar",
        "comment_error_message": "Fel uppstod vid kommentering. Var god försök igen.",
        "more": "Mer",
        "edited": "Redigerad",
        "edit_comment_error": "Kunde inte redigera kommentar",
        "delete_comment_error": "Kunde inte radera kommentar",
        "view_all_comments": "Visa alla kommentarer",
        "what_is_your_name": "Vad heter du?",
        "tap_here_to_enter_name": "Tryck här för att ange namn",
        "click_here_to_enter_name": "Klicka här för att ange namn",
        "enter": "skicka",
        "for_best_experience": "För bästa upplevelse",
        "register_or_sign_in": "registrera dig eller logga in nu!",
        "year_ago__format": "%d år sedan",
        "years_ago__format": "%d år sedan",
        "month_ago__format": "%d månad sedan",
        "months_ago__format": "%d månader sedan",
        "day_ago__format": "%d dag sedan",
        "days_ago__format": "%d dagar sedan",
        "hour_ago__format": "%d timme sedan",
        "hours_ago__format": "%d timmar sedan",
        "minute_ago__format": "%d minut sedan",
        "minutes_ago__format": "%d minuter sedan",
        "just_now": "just nu",
        "post": "Skicka",
        "slideshow": "Bildspel",
        "play": "Spela",
        "pause": "Pausa",
        "previous": "Föregående",
        "next": "Nästa",
        "exit_fullscreen": "Avsluta",
        "info": "Information",
        "releaseNotes_v0_header": "Nya och förbättrade Capture!",
        "releaseNotes_v0_p1": "Hitta bilder snabbare med nya snabbscrollnings- och filtreringsfunktionen",
        "releaseNotes_v0_p2": "Ladda upp bilder genom att dra och släppa dem varsomhelst på skärmen",
        "releaseNotes_v0_p3": "Skapa, redigera och dela album",
        "releaseNotes_v0_p4": "Samarbeta med album med vänner och familj",
        "releaseNotes_v0_p5": "Och mycket mer...",
        "profile_info": "Profilinfo",
        "enter_your_name": "Ange ditt namn",
        "storage_management": "Lagringshantering",
        "total_storage": "Totalt utrymme",
        "remaining_storage": "Återstående",
        "used_storage": "Använt",
        "buy_more_storage": "Köp mer lagringsutrymme",
        "connected_devices": "Inloggade enheter",
        "unlimited": "Obegränsat",
        "your_storage_plan": "Din lagringsplan",
        "storage_used_out_of__format": "%storage_used% använt av %storage_total%",
        "cancel_storage_plan": "Avbryt lagringsplan",
        "renew_status__format": "Planen kommer att förnyas %s",
        "expired_status__format": "Planen kommer att löpa ut %s",
        "reactivate_storage_plan": "Återaktivera lagringsplan",
        "payment_settings": "Betalningsinställningar",
        "payment_settings_info_text": "Din registrerade kortinformation:",
        "update_card": "Ändra kortinfo",
        "card_number": "Kortnummer",
        "card_number_placeholder": "Ange ditt kortnummer",
        "card_exp_date": "Utgångsdatum",
        "card_cvc": "CVC/CVV",
        "confirm_payment": "Bekräfta betalning",
        "payment": "Betalning",
        "choose_payment_method": "Välj din betalningsmetod",
        "pay_with_vipps": "Betala med Vipps",
        "save__format": "Spara %d",
        "get_started": "Kom igång!",
        "telenor_unlimited_hook__format": "Som Telenor mobilkund har du tillgång till obegränsad lagring för %product_name%",
        "telenor_unlimited_hook_subtext": "Berättigade prenumerationer: Fleksi/Medium/Trygg/Yng/U18/Frihet/Bedrift Fri+",
        "monthly_sub": "Månatlig plan",
        "yearly_sub": "Årlig plan",
        "language": "Språk",
        "select_language": "Välj språk",
        "privacy_info": "Granska din data och ta kontroll över dina inställningar på en och samma plats.",
        "privacy": "Sekretess",
        "open_privacy_pages": "Öppna sekretess-sidor",
        "thanks_for_signing_in": "Tack för att du loggade in!",
        "get_capture_to_create_album": "Skaffa Capture på mobilen för att skapa dina egna album.",
        "return_to_album": "Gå tillbaka till albumet",
        "telenor_capture_text": "Capture har utvecklats av Telenors företag Telenor Digital, som ger dig innovativa digitala lösningar. För att logga in på tjänster från Telenor Digital använder du Connect-kontot.",
        "terms_of_service": "Tjänstevillkor",
        "customer_service": "Kundservice",
        "capture_footer_text_mobile": "För den fullständiga upplevelsen, skaffa appen",
        "open": "öppna",
        "download": "Hämta",
        "album_not_found": "Album hittades inte",
        "album_not_found_description": "Vi kunde tyvärr inte hitta albumet! Det kan bero på att länken är utgången eller felstavad. Det är också möjligt att albumet har raderats.",
        "capture_create_user_description": "Capture är en säker och användarvänlig lagringslösning för bilder och andra filer som lagras online. Om du inte är en Capture-användare är du välkommen att skapa ett användarkonto.",
        "capture_more_link": "Läs mer om Capture",
        "share_not_found": "Delning hittades inte",
        "share_not_found_description": "Du kan ha klickat på en utgången, raderad eller felstavad länk.",
        "page_not_found": "Vi verkar inte kunna hitta sidan du letar efter!",
        "helpful_links_description": "Här är några användbara länkar istället",
        "helpful_links_about_capture": "Om Capture",
        "password_required": "Lösenord krävs",
        "password_input_placeholder": "Klicka här för att ange lösenord",
        "password_required_submit": "skicka",
        "password_incorrect": "Felaktigt lösenord",
        "album_password_required_description": "Det här albumet är skyddat med ett lösenord. Var god ange lösenordet i rutan ovan för att låsa upp albumet.",
        "needs_connect_to_create_album": "För att skapa Album krävs ett %product_name%-konto",
        "needs_login_to_copy_files_to_timeline": "Du behöver ett %product_name%-konto för att spara dessa filer",
        "needs_login_to_copy_album_to_timeline": "Du behöver ett %product_name%-konto för att spara filer från detta album",
        "no_title_yet": "Ingen titel ännu",
        "add_album_title": "Ange albumets titel",
        "upgrade_your_browser": "Vänligen uppdatera din webbläsare",
        "upgrade_your_browser_des": "Tyvärr! Detta innehåll kan inte ses i din aktuella webbläsare. Uppdatera eller använd en annan webbläsare som Chrome, Firefox eller Microsoft Edge.",
        "share_album": "Dela album",
        "edit_album_settings": "Redigera albuminställningar",
        "enabled": "Aktiverat",
        "disabled": "Inaktiverat",
        "privacy_private": "Privat",
        "privacy_shared": "Delat",
        "edit_album_title_text": "Albumets titel (Tryck för att ändra):",
        "edit_allow_comments_text": "Alla kan se och lägga till kommentarer",
        "edit_allow_upload_text": "Alla kan lägga till foton",
        "edit_privacy_mode_text": "Sekretessnivå",
        "toast__coverPhoto_was_set": "Omslagsfotot ändrat",
        "toast__multiple_files_deleted__format": "%d filer raderade",
        "toast__single_file_deleted": "Filen raderad",
        "toast__multiple_files_perm_deleted__format": "%d filer permanent raderade",
        "toast__single_file_perm_deleted": "Filen permanent raderad",
        "toast__multiple_files_perm_deleted_failed__format": "Fel - %d filer raderades inte permanent",
        "toast__single_file_perm_deleted_failed": "Fel - filen raderades inte permanent",
        "toast__single_file_restored": "Filen återställd",
        "toast__multiple_files_restored__format": "%d filer återställda",
        "toast__single_file_restored_failed": "Fel - filen återställdes inte",
        "toast__multiple_files_restored_failed__format": "Fel - %d filer återställdes inte",
        "toast__single_file_added_to_album__format": "Filen tillagd till album \"%album_name%\"",
        "toast__multiple_files_added_to_album__format": "%d filer tillagda till album \"%album_name%\"",
        "toast__single_file_added_to_album_failed__format": "Fel - filen inte tillagd",
        "toast__multiple_file_added_to_album_failed__format": "Fel - %d filer inte tillagda",
        "toast__album_auto_crated__format": "Album %album_name% skapades till dig",
        "toast__see_album": "Se album",
        "toast__album_added_to_timeline": "Album tillagt till Bilder",
        "toast__album_added_to_timeline_failed": "Det gick inte att lägga till albumet - försök igen",
        "toast__file_added_to_timeline": "Fil tillagd till Bilder",
        "toast__file_added_to_timeline_failed": "Det gick inte att lägga till filen - försök igen",
        "toast__files_were_shared": "Dina filer delades!",
        "toast__files_are_deleting": "Raderar filer...",
        "toast__files_are_restoring": "Återställer filer...",
        "toast__files_are_copying": "Kopierar filer...",
        "toast__credit_card_updated": "Kreditkortsnummer uppdaterat",
        "toast__credit_card_update_failed": "Kunde inte uppdatera info",
        "toast__purchase_successful": "Lagringsplan köpt",
        "toast__purchase_failed": "Kunde inte köpa lagringsplan",
        "toast__plan_canceled": "Lagringsplan avbruten",
        "toast__plan_cancel_failed": "Kunde inte avbryta lagringsplan",
        "toast__plan_change_succeeded": "Plan framgångsrikt uppdaterad",
        "toast__plan_change_failed": "Kunde inte uppdatera plan",
        "toast__plan_reactivated": "Lagringsplan återaktiverad",
        "toast__plan_reactivation_failed": "Kunde inte återaktivera lagringsplan",
        "trash_delete_info": "Raderade filer kommer raderades permanent efter 1 månad.",
        "trash_no_files": "Du har inga raderade filer.",
        "trash_restore_info": "Du kan återställa dem närsomhelst innan det!",
        "documents_no_files": "Du har inga filer.",
        "share_to": "Dela via",
        "email": "E-post",
        "SMS": "SMS",
        "get_link": "Hämta länk",
        "hold_to_copy_link": "Tryck och håll ner för att kopiera länken",
        "copy_link": "Kopiera länk",
        "link_copied": "Länken kopierades!",
        "share_files_success_header": "Du har delat ett album!",
        "can_not_share_video_to_fb_album": "Oj då! Videor kan inte delas till Facebook-album",
        "send_email_to": "Skicka email till:",
        "enter_receivers_address": "Ange mottagarens adress här",
        "email_subject": "Ämne:",
        "enter_subject": "Ange ämne här",
        "email_message": "Meddelande (frivilligt):",
        "enter_message": "Ange ditt meddelande här",
        "invalid_input": "Ogiltigt",
        "fb_album_share_desc": "Skriv ett namn för Facebook-albumet",
        "enter_fb_album_name": "Facebook-albumets namn",
        "fb_permissions_error": "Ojdå! För att kunna dela din bilder till Facebook, behöver vi tillåtelse att publicera till ditt konto.",
        "fb_permissions_cta": " Du kommer att promptas för dessa tillåtelser efter att du klickat på slutfört.",
        "share__header_SingleFile__format": "%user_name% har delat en fil med dig genom %product_name%",
        "share__header_MultipleFiles__format": "%user_name% har delat lite filer med dig genom %product_name%",
        "share__add_singleFile_to_capture__format": "Lägg till fil till min %s",
        "share__add_multipleFiles_to_capture__format": "Lägg till filer till min %s",
        "download_all": "Hämta alla",
        "share_added_to_timeline": "Filer lades till",
        "share_added_to_timeline_failed": "Det gick inte att lägga till filer - försök igen",
        "share_failed": "Kunde inte dela filer - vänligen försök igen",
        "share__not_capture_user": "Inte en Capture-användare?",
        "share__try_capture": "Klicka här för att ge det ett försök!",
        "share__service_info": "Capture är en säker och gratis lagringstjänst för alla dina bilder och videor. Obegränsat utrymme ingår med en Telenor-prenumeration.",
        "share_password_required_description": "Den här Delningen är skyddad med ett lösenord. Var god ange lösenordet i rutan ovan för att visa Delningen."
    },
    "sr_ME": {
        "to": "u",
        "of": "od",
        "ok": "U redu",
        "yes": "Da",
        "no": "Ne",
        "done": "Gotovo",
        "delete_": "Obriši",
        "edit": "Uredi",
        "cancel": "Otkaži",
        "share": "Podijeli",
        "back": "Nazad",
        "select_all": "Izaberi sve",
        "restore": "Vrati",
        "retry": "Pokušaj ponovo",
        "capture_save_see_share": "Sačuvajte, vidite i podijelite svoje fotografije i video klipove.",
        "january": "Januar",
        "february": "Februar",
        "march": "Mart",
        "april": "April",
        "may": "Maj",
        "june": "Juni",
        "july": "Juli",
        "august": "Avgust",
        "september": "Septembar",
        "october": "Oktobar",
        "november": "Novembar",
        "december": "Decembar",
        "jan": "Jan",
        "feb": "Feb",
        "mar": "Mar",
        "apr": "Apr",
        "jun": "Jun",
        "jul": "Jul",
        "aug": "Avg",
        "sep": "Sep",
        "oct": "Okt",
        "nov": "Nov",
        "dec": "Dec",
        "wall": "Zid",
        "timeline": "Slike",
        "albums": "Albumi",
        "menu": "Meni",
        "add_files": "Dodaj datoteke",
        "documents": "Fajlovi",
        "settings": "Podješavanja",
        "account": "Nalog",
        "help": "Pomoć",
        "deleted_items": "Obrisane stavke",
        "log_out": "Odjava",
        "close": "Zatvori",
        "wall_desc": "Lični zid sa svim vašim fotografijama koje možete sami uređivati.",
        "timeline_desc": "Sve vaše fotografije i video snimci, organizovani prema datumu.",
        "albums_desc": "Dodajte fotografije u Album i podijelite ih sa prijateljima!",
        "drag_drop": "Prevuci i otpusti",
        "files": "datoteka(e)",
        "uploaded": "otprijemljeno",
        "did_not_upload": "nije otprijemljeno",
        "was_not_uploaded": "Nije otprijemljeno",
        "see_which": "Pogledaj koje",
        "offline": "Nema mrežne veze",
        "upload": "Otprijemi",
        "uploading": "Otprijemanje",
        "retrying_in": "Ponovni pokušaj za",
        "sec": "sek",
        "retrying_now": "Ponovni pokušaj...",
        "try_again": "Pokušajte ponovo",
        "stop": "Zaustavi",
        "complete": "Dovršeno",
        "cancelled": "Otkazano",
        "preparing": "Priprema...",
        "waiting_for_internet_connection": "Čeka se na internet vezu...",
        "out_of_storage": "Ponestalo je skladišnog prostora",
        "free_up_space": "Obrišite datoteke da biste nastavili otprijemanje",
        "cancelling_upload_are_you_sure": "Jeste li sigurni da želite da zaustavite otprijemanje?",
        "reached_given_storage_ratio": "Ponestaje vam skladišnog prostora",
        "reached_given_storage_ratio_subText": "Možete osloboditi prostor tako što ćete obrisati fotografije ili video snimke ili kupiti više skladišnog prostora prijeko naloga.",
        "not_enough_storage_for_add": "Nedovoljno skladišnog prostora",
        "not_enough_storage_for_restore": "Nedovoljno skladišnog prostora",
        "selected__format": "odabrano %d",
        "by": "od",
        "download_album": "Preuzmi album",
        "add_photos": "Dodaj fotografije",
        "set_as_cover_photo": "Postavi kao naslovnu fotografiju",
        "sort_album_files_by_newest_first": "Prvo najnovije",
        "sort_album_files_by_oldest_first": "Prvo najstarije",
        "comments": "komentari",
        "write_your_comment": "Ostavite svoj komentar",
        "comment_error_message": "Greška u postavljanju komentara. Pokušajte ponovo.",
        "view_all_comments": "Vidite sve komentare",
        "what_is_your_name": "Kako se zovete?",
        "tap_here_to_enter_name": "Kliknite ovde da upišete svoje ime",
        "click_here_to_enter_name": "Kliknite ovde da upišete ime",
        "enter": "unesi",
        "year_ago__format": "Prije %d godinu",
        "years_ago__format": "Prije %d godine/a",
        "month_ago__format": "Prije %d mjesec",
        "months_ago__format": "Prije %d mjeseca/i",
        "day_ago__format": "Prije %d dan",
        "days_ago__format": "Prije %d dana",
        "hour_ago__format": "Prije %d sat",
        "hours_ago__format": "Prije %d sata/i",
        "minute_ago__format": "Prije %d minut",
        "minutes_ago__format": "Prije %d minuta",
        "just_now": "upravo sada",
        "post": "Postavi",
        "enter_your_name": "Upišite svoje ime",
        "used_storage": "Iskorišćeno",
        "language": "Jezik",
        "thanks_for_signing_in": "Hvala što ste se prijavili!",
        "get_capture_to_create_album": "Preuzmite Capture na svoj mobilni uređaj da biste kreirali svoje lične Albume.",
        "return_to_album": "Vrati se u album",
        "telenor_capture_text": "Capture je razvijen od strane Telenor Digital, Telenor kompanije koja pruža digitalna rješenja budućnosti. Connect račun se koristi da se prijavite na usluge koje pruža Telenor Digital.",
        "terms_of_service": "Uslovi korišćenja usluge",
        "customer_service": "Pomoć i korisnička podrška",
        "download": "Preuzmi",
        "album_not_found": "Album nije pronađen",
        "album_not_found_description": "Izvinjavamo se! Nismo bili u mogućnosti da pronađemo album. Moguće je da je link pogrešan ili je istekao, ili da je album obrisan.",
        "capture_create_user_description": "Capture je sigurna usluga za skladištenje, praktična za korisnike, za slike i druge fajlove skladištene na mreži. U slučaju da niste korisnik Capture-a, onda ste dobrodošli da napravite korisnički profil.",
        "capture_more_link": "Saznajte više o usluzi Capture",
        "share_not_found": "Fajlovi koje ste podelili sa drugima ne mogu biti pronadjeni",
        "password_required": "Lozinka je neophodna",
        "password_required_submit": "unesi",
        "password_incorrect": "Neispravna lozinka",
        "album_password_required_description": "Pošiljalac je ovaj album zaštitio lozinkom. Molimo unesite lozinku u polje iznad za otključavanje albuma.",
        "email": "E-pošta",
        "download_all": "Preuzmi sve"
    },
    "en_US": {},
    "sr_RS": {
        "to": "u",
        "of": "od",
        "ok": "U redu",
        "yes": "Da",
        "got_it": "Razumem",
        "see_more": "Pogledaj više",
        "skip": "Preskoči",
        "no": "Ne",
        "done": "Završeno",
        "reactivate": "Ponovo aktiviraj",
        "unsubscribe": "Otkaži pretplatu",
        "delete_": "Obriši",
        "edit": "Uredi",
        "update": "Ažuriraj",
        "cancel": "Otkazati",
        "share": "Podelite",
        "undo": "Opozovi radnju",
        "back": "Nazad",
        "select_all": "Izaberi sve",
        "restore": "Vrati",
        "filename": "ime datoteke",
        "file_type": "tip datoteke",
        "deleted": "izbrisano",
        "unknown": "nepoznato",
        "retry": "Pokušaj ponovo",
        "create_account": "Napravi nalog",
        "sign_in": "Prijavite se",
        "logged_out_prompt": "Ups, odjavili ste se. Prijavite se da biste nastavili",
        "capture_save_see_share": "Sačuvajte, gledajte i delite sve svoje fotografije i video snimke.",
        "feature_only_available_in_app": "Ova funkcija je trenutno dostupna samo u aplikaciji Capture.",
        "open_capture": "Otvori Capture",
        "open_in_app": "Otvori u aplikaciji",
        "storage": "Skladišni prostor",
        "active": "Aktivan",
        "save": "Sačuvajte",
        "january": "Januar",
        "february": "Februar",
        "march": "Mart",
        "april": "April",
        "may": "Maj",
        "june": "Juni",
        "july": "Juli",
        "august": "Avgust",
        "september": "Septembar",
        "october": "Oktobar",
        "november": "Novembar",
        "december": "Decembar",
        "day": "Dan",
        "month": "Mesec",
        "year": "Godina",
        "monthly": "mesečno",
        "yearly": "godišnje",
        "jan": "Jan",
        "feb": "Feb",
        "mar": "Mar",
        "apr": "Apr",
        "jun": "Jun",
        "jul": "Jul",
        "aug": "Avg",
        "sep": "Sep",
        "oct": "Okt",
        "nov": "Nov",
        "dec": "Dec",
        "delete_files_prompt__format": "%d datoteka će biti premešteno u Izbrisane stavke. Datoteke će biti trajno izbrisane nakon 30 dana.",
        "delete_single_file_prompt": "Datoteka će biti premeštena u Izbrisane stavke. Datoteka će biti trajno izbrisana nakon 30 dana.",
        "delete_file_failed_message": "Nije bilo moguće obrisati ovu datoteku",
        "delete_file_failed_message__format": "Došlo je do greške kada smo pokušali da izbrišemo „%s“. Molimo vas da pokušate ponovo.",
        "delete_album_file_prompt_text": "Datoteka će biti uklonjena iz albuma. Svi komentari i sviđanja vezani za ovu datoteku će takođe biti izbrisani.",
        "delete_last_file_prompt_text": "Datoteka će biti uklonjena iz albuma. Uklanjanje poslednje datoteke u albumu će takođe izbrisati album.",
        "unsubscribe_album_prompt_text": "Više nećete videti ovaj album na vašoj listi albuma ili primati ažuriranja na ovom albumu.",
        "delete_album_prompt_text": "Album će biti izbrisan i uklonjen sa vaše liste albuma.",
        "delete_comment_prompt_text": "Komentar će biti izbrisan.",
        "remove_device_prompt__format": "Bićete odjavljeni sa %s na ovom uređaju.",
        "cancel_storage_plan_prompt__format": "Potvrdite da biste otkazali trenutni plan skladištenja, zadržite %storage_amount% do %expired_date% i neće biti izvršeni dodatni troškovi.",
        "change_storage_plan_period_prompt": "Potvrdite promenu u obračunskom periodu tekućeg plana. Novi ciklus naplate počinje odmah",
        "upgrade_storage_plan_prompt__format": "Potvrdite promenu u trenutnom planu ako želite da povećate svoj plan skladištenja na %newSize%GB sa %currentSize%GB. Promene će se odmah primeniti",
        "downgrade_storage_plan_prompt__format": "Potvrdite promenu u trenutnom planu ako želite da smanjite svoj plan skladištenja na %newSize%GB sa %currentSize%GB. Skladišni prostor koji je već iskorišćen neće biti izbrisan.",
        "delete_single_document_file": "Datoteka će biti trajno izbrisana.",
        "delete_document_files__format": "%d datoteka će biti trajno izbrisano.",
        "restore_single_file_prompt": "Datoteka će biti vraćena u vaše fotografije.",
        "restore_files_prompt__format": "%d datoteka će biti vraćeno u vaše fotografije.",
        "permanently_delete_single_file_prompt": "Datoteka će biti trajno izbrisana. Ova radnja se ne može poništiti.",
        "permanently_delete_files_prompt__format": "%d datoteka će biti trajno izbrisano. Ova radnja se ne može poništiti.",
        "wall": "Zid",
        "timeline": "Slike",
        "albums": "Albumi",
        "your_albums": "Vaši albumi",
        "menu": "Meni",
        "add_files": "Dodaj datoteke",
        "documents": "Datoteke",
        "settings": "Podešavanja",
        "account": "Korisnički profil",
        "help": "Pomoć",
        "deleted_items": "Obrisane stavke",
        "login": "Prijavi se",
        "log_out": "Odjava",
        "close": "Zatvorite",
        "wall_desc": "Lični zid sa svim vašim fotografijama koje možete sami uređivati.",
        "timeline_desc": "Sve vaše fotografije i video snimci, organizovani prema datumu.",
        "albums_desc": "Dodajte fotografije u Album i podelite ih sa prijateljima!",
        "go_back_to_legacy_ribbon": "Hvala što koristite ovu novu funkciju. Kliknite ovde da biste se vratili na redovnu mrežu i pristupili svim funkcijama.",
        "drag_drop": "Prevuci i otpusti",
        "dropZone_text__timeline": "datoteke da biste ih otpremili ili fasciklu da biste napravili novi album",
        "dropZone_text__albums": "datoteke ili fascikle da biste napravili nove albume",
        "dropZone_text__singleAlbum": "datoteke bilo gde da biste ih otpremili u %album_name%",
        "files": "datoteka(e)",
        "uploaded": "otpremljeno",
        "did_not_upload": "nije otpremljeno",
        "upload_folder_reject": "Otpremanje fascikli nije uspelo",
        "upload_folder_safari_reject": "Safari ne podržava otpremanje fascikli",
        "unsupported_file_reject": "Nepodržana datoteka",
        "was_not_uploaded": "Nije otpremljeno",
        "see_which": "Pogledaj koje",
        "offline": "Nema internet veze",
        "upload": "Otpremi",
        "uploading": "Otpremanje",
        "retrying_in": "Ponovni pokušaj za",
        "sec": "sek",
        "retrying_now": "Ponovni pokušaj...",
        "try_again": "Pokušajte ponovo",
        "stop": "Zaustavi",
        "complete": "Dovršeno",
        "cancelled": "Otkazano",
        "preparing": "Priprema...",
        "waiting_for_internet_connection": "Čeka se na Internet vezu...",
        "out_of_storage": "Ponestalo je skladišnog prostora",
        "free_up_space": "Obrišite datoteke da biste nastavili otpremanje",
        "cancelling_upload_are_you_sure": "Jeste li sigurni da želite da zaustavite otpremanje?",
        "reached_given_storage_ratio": "Ponestaje vam skladišnog prostora",
        "reached_given_storage_ratio_subText": "Možete osloboditi prostor tako što ćete obrisati fotografije ili video snimke ili kupiti više skladišnog prostora u Podešavanjima.",
        "not_enough_storage_for_add": "Nedovoljno skladišnog prostora",
        "not_enough_storage_for_add_subText": "Nemate dovoljno skladišnog prostora za sve datoteke koje želite da dodate. Oslobodite prostor tako što ćete obrisati fotografije ili video snimke ili kupiti više skladišnog prostora u Podešavanjima.",
        "not_enough_storage_for_restore": "Nedovoljno skladišnog prostora",
        "not_enough_storage_for_restore_subText": "Nemate dovoljno prostora za sve datoteke koje želite da vratite. Oslobodite prostor tako što ćete obrisati fotografije ili video snimke ili kupiti više skladišnog prostora u Podešavanjima.",
        "timeline_is_empty": "Vaša vremenska linija je prazna.",
        "timeline_filtered_photos_empty": "Nemate nijednu sliku.",
        "start_uploading_photos_now": "Zašto ne biste sada počeli da otpremate fotografije?",
        "start_uploading_now": "Zašto ne biste sada počeli da neke otpremate?",
        "drag_drop_photos_to_upload": "Možete da prevlačite i otpuštate svoje fotografije ovde ili da pritisnete dugme ispod.",
        "download_app_to_upload_directly_from_device": "Instalirajte aplikaciju Capture na svoj mobilni uređaj da biste otpremali fotografije i video zapise direktno iz kamere i galerije svog uređaja",
        "timeline_empty_screenshot_filter": "Nemate nijedan snimak ekrana. Blago vama!",
        "timeline_filtered_videos_empty": "Nemate nijedan video.",
        "drag_drop_files_to_upload": "Možete da prevlačite i otpuštate svoje datoteke ovde ili da pritisnete dugme ispod.",
        "add_videos": "Dodaj video klipove",
        "selected__format": "odabrano %d",
        "select_files": "Odaberite datoteke",
        "download_limit_exceeded": "Ne možete preuzeti više od 500 stavki istovremeno. Izaberite manje stavki.",
        "filter_by_screenshots": "Snimci ekrana",
        "filter_by_videos": "Video snimci",
        "filter_by_images": "Slike",
        "show_all": "Prikaži sve",
        "album": "album",
        "item__format": "%d stavka",
        "items__format": "%d stavki",
        "participant__format": "%d učesnik",
        "participants__format": "%d učesnika",
        "participants": "Učesnici",
        "by": "napravio/la",
        "create_album": "Kreiraj album",
        "auto_created_album_title": "Neimenovani album",
        "new_album": "Novi album",
        "filter_by": "Filtriraj po",
        "filter_albums": "Filtriraj albume",
        "filtered_by_private": "Filtriraj privatne",
        "filtered_by_shared": "Filtriraj podeljene",
        "all_albums": "Svi albumi",
        "shared_albums": "Deljeni albumi",
        "private_albums": "Privatni albumi",
        "download_album": "Preuzmi album",
        "add_photos": "Dodaj fotografije",
        "add_to_album": "Dodaj u album",
        "error_msg_unsupported_media_type": "Ups! Nešto nije u redu.",
        "you_and_others_love_message_format": "Vama i %d drugih se ovo sviđa",
        "you_and_one_love_message": "Vama i još 1 osobi se ovo sviđa",
        "you_love_message": "Ovo vam se sviđa",
        "one_person_love_message": "1 osobi se ovo sviđa",
        "people_love_message_format": "%d osoba voli ovo",
        "leave_album": "Ostavi album",
        "unsubscribe_album_failed_message__format": "Došlo je do greške prilikom napuštanja albuma %s. Molimo vas da pokušate ponovo.",
        "delete_album": "Obriši album",
        "delete_file": "Obriši datoteku",
        "download_file": "Preuzmi datoteku",
        "download_files_failed_message": "Došlo je do greške prilikom pokušaja preuzimanja. Molimo vas da pokušate ponovo kasnije.",
        "delete_album_failed_message__format": "Došlo je do greške prilikom pokušaja brisanja albuma %s. Molimo vas da pokušate ponovo.",
        "set_as_cover_photo": "Postavi kao naslovnu fotografiju",
        "set_cover_photo_failed_message": "Naslovna fotografija nije mogla biti promenjena. Molimo vas da pokušate ponovo.",
        "add_file_to_timeline": "Dodaj u „Fotografije“",
        "add_album_to_timeline": "Dodaj album u „Fotografije“",
        "change_album_viewMode": "Promeni režim prikaza",
        "sort_album_files": "Sortiraj album",
        "sort_album_files_title": "Sortiraj album po",
        "sort_album_files_by_added": "Nedavno dodato",
        "sort_album_files_by_newest_first": "Najnoviji prvo",
        "sort_album_files_by_oldest_first": "Najstariji prvo",
        "album_list_is_empty": "Još uvek nemate nijedan album.",
        "empty_albumList_secondTitle": "Zašto ne biste sada stvorili jedan?",
        "empty_filtered_private_albumList_title": "Nemate nijedan privatni album.",
        "empty_filtered_shared_albumList_title": "Nemate nijedan deljeni album.",
        "empty_filtered_albumList_subtext": "Lako je napraviti album! Pritisnite dugme ispod da biste počeli.",
        "album_select": "Odaberite album",
        "create_new": "Kreiraj novi",
        "private_album": "Privatan album",
        "shared_album": "Deljeni album",
        "share_created_album_prompt_header": "Stvorili ste album!",
        "share_created_album_prompt_text": "Podelite ga sa prijateljima i porodicom da bi i oni mogli da dodaju fotografije i komentare u vaš album.",
        "share_later": "Podeli kasnije",
        "share_now": "Podeli sada",
        "comment": "komentar",
        "comments": "komentari",
        "write_your_comment": "Napišite svoj komentar",
        "comment_error_message": "Greška pri objavljivanju komentara. Pokušajte ponovo.",
        "more": "Više",
        "edited": "Uređeno",
        "edit_comment_error": "Neuspelo uređivanje komentara",
        "delete_comment_error": "Neuspelo brisanje komentara",
        "view_all_comments": "Prikaži sve komentare",
        "what_is_your_name": "Kako se zovete?",
        "tap_here_to_enter_name": "Pritisnite ovde za unos imena",
        "click_here_to_enter_name": "Kliknite ovde za unos imena",
        "enter": "unesite",
        "for_best_experience": "Za najbolje iskustvo",
        "register_or_sign_in": "registrujte se ili prijavite odmah!",
        "year_ago__format": "pre %d godinu",
        "years_ago__format": "pre %d godine/a",
        "month_ago__format": "pre %d mesec",
        "months_ago__format": "pre %d meseca/i",
        "day_ago__format": "pre %d dan",
        "days_ago__format": "pre %d dana",
        "hour_ago__format": "pre %d sat",
        "hours_ago__format": "pre %d sata/i",
        "minute_ago__format": "pre %d minut",
        "minutes_ago__format": "pre %d minuta",
        "just_now": "upravo sada",
        "post": "Objavi",
        "slideshow": "Prikazivanje slajdova",
        "play": "Reprodukuj",
        "pause": "Puza",
        "previous": "Prethodno",
        "next": "Dalje",
        "exit_fullscreen": "Izaći",
        "info": "Informacije",
        "releaseNotes_v0_header": "Nova i unapređena usluga Capture!",
        "releaseNotes_v0_p1": "Pronađite fotografije brže pomoću novih funkcija za brzo pomeranje i filtriranje",
        "releaseNotes_v0_p2": "Otpremite fotografije tako što ćete ih prevući i otpustiti bilo gde na ekranu",
        "releaseNotes_v0_p3": "Kreirajte, uređujte i delite albume",
        "releaseNotes_v0_p4": "Sarađujte na albumima sa prijateljima i porodicom",
        "releaseNotes_v0_p5": "I još puno toga...",
        "profile_info": "Informacije o profilu",
        "enter_your_name": "Unesite svoje ime",
        "storage_management": "Upravljanje skladišnim prostorom",
        "total_storage": "Ukupan prostor",
        "remaining_storage": "Preostalo",
        "used_storage": "Iskorišćeno",
        "buy_more_storage": "Kupite dodatni skladišni prostor",
        "connected_devices": "Prijavljeni uređaji",
        "unlimited": "Neograničeno",
        "your_storage_plan": "Vaš plan skladištenja",
        "storage_used_out_of__format": "%storage_used% iskorišćeno od %storage_total%",
        "cancel_storage_plan": "Otkažite plan skladištenja",
        "renew_status__format": "Plan će biti obnovljen dana %s",
        "expired_status__format": "Plan će isteći dana %s",
        "reactivate_storage_plan": "Ponovo aktivirajte plan skladištenja",
        "payment_settings": "Podešavanja plaćanja",
        "payment_settings_info_text": "Vaše registrovane informacije o kartici:",
        "update_card": "Promenite informacije o kartici",
        "card_number": "Broj kartice",
        "card_number_placeholder": "Unesite svoj broj kartice",
        "card_exp_date": "Datum isteka",
        "card_cvc": "CVC/CVV",
        "confirm_payment": "Potvrdite plaćanje",
        "payment": "Plaćanje",
        "choose_payment_method": "Izaberite način plaćanja",
        "pay_with_vipps": "Plati koristeći Vipps",
        "save__format": "Sačuvaj %d",
        "get_started": "Počni!",
        "telenor_unlimited_hook__format": "Kao mobilni korisnik Telenora, imate pristup neograničenom skladišnom prostoru za %product_name%",
        "telenor_unlimited_hook_subtext": "Prihvatljive pretplate: Fleksi/Medium/Trygg/Yng/U18/Frihet/Bedrift Fri+",
        "monthly_sub": "Mesečni plan",
        "yearly_sub": "Godišnji plan",
        "language": "Jezik",
        "select_language": "Izaberite jezik",
        "privacy_info": "Pregledajte svoje podatke i preuzmite kontrolu nad svojim podešavanjima na jednom mestu.",
        "privacy": "Privatnost",
        "open_privacy_pages": "Otvori stranice za privatnost",
        "thanks_for_signing_in": "Hvala što ste se prijavili!",
        "get_capture_to_create_album": "Preuzmite Capture na svom mobilnom uređaju i stvorite svoje Albume.",
        "return_to_album": "Povratak na album",
        "telenor_capture_text": "Capture je razivo Telenor Digital, Telenorova kompanija koja obezbeđuje digitalna rešenja sutrašnjice. Connect ID nalog se koristi za prijavljivanje u usluge koje obezbeđuje Telenor Digital.",
        "terms_of_service": "uslovi korišćenja",
        "customer_service": "Pomoć i korisnički servis",
        "capture_footer_text_mobile": "Za potpuni doživljaj nabavite aplikaciju",
        "open": "otvori",
        "download": "Preuzimanje",
        "album_not_found": "Album nije pronađen",
        "album_not_found_description": "Žao nam je! Nismo mogli da nađemo album. Moguće je da je veza pogrešno ukucana ili je istekla ili da je album izbrisan.",
        "capture_create_user_description": "Capture je jednostavna online usluga koja omogućava bezbedno čuvanje fotografija i drugih tipova datoteka. Ukoliko nemaš korisnički nalog, možeš da ga kreiraš sada veoma brzo i jendostavno.",
        "capture_more_link": "Saznaj vise o Capture servisu",
        "share_not_found": "Fajlovi koje ste podelili sa drugima ne mogu biti pronadjeni",
        "share_not_found_description": "Moguće je da je veza pogrešno ukucana, istekla ili da je izbrisana.",
        "page_not_found": "Izgleda da ne možemo da pronađemo stranicu koju tražite!",
        "helpful_links_description": "Evo nekih pomoćnih linkova",
        "helpful_links_about_capture": "O usluzi Capture",
        "password_required": "Neophodna je lozinka",
        "password_input_placeholder": "Klikni ovde za unos lozinke",
        "password_required_submit": "unesite",
        "password_incorrect": "Pogrešna lozinka.",
        "album_password_required_description": "Pošiljalac je zaštitio ovaj album lozinkom. Molimo vas da unesete lozinku u polje iznad da biste otključali album.",
        "needs_connect_to_create_album": "Potreban vam je %product_name% nalog da biste pravili albume",
        "needs_login_to_copy_files_to_timeline": "Potreban vam je %product_name% nalog da biste sačuvali ove datoteke",
        "needs_login_to_copy_album_to_timeline": "Potreban vam je %product_name% nalog da biste sačuvali datoteke iz ovog albuma",
        "no_title_yet": "Još uvek nema naslova",
        "add_album_title": "Unesite naslov albuma",
        "upgrade_your_browser": "Molimo te da ažuriraš svoj pregledač",
        "upgrade_your_browser_des": "Žao nam je! Ovaj sadržaj se ne može pregledati u tvom trenutnom pregledaču. Ažuriraj ga ili koristi drugi pregledač kao što je Chrome, Firefox ili Microsoft Edge.",
        "share_album": "Podeli album",
        "edit_album_settings": "Uredi podešavanja albuma",
        "enabled": "Omogućeno",
        "disabled": "Onemogućeno",
        "privacy_private": "Privatno",
        "privacy_shared": "Podeljeno",
        "edit_album_title_text": "Naslov albuma (dodirnite da promenite):",
        "edit_allow_comments_text": "Svako može da vidi i dodaje komentare",
        "edit_allow_upload_text": "Svako može da dodaje fotografije",
        "edit_privacy_mode_text": "Nivo privatnosti",
        "toast__coverPhoto_was_set": "Naslovna fotografija je promenjena",
        "toast__multiple_files_deleted__format": "%d datoteka je obrisano",
        "toast__single_file_deleted": "Datoteka je obrisana",
        "toast__multiple_files_perm_deleted__format": "%d datoteka je trajno izbrisano",
        "toast__single_file_perm_deleted": "Datoteka je trajno izbrisana",
        "toast__multiple_files_perm_deleted_failed__format": "Greška - %d datoteka nije trajno izbrisano",
        "toast__single_file_perm_deleted_failed": "Greška - datoteka nije trajno izbrisana",
        "toast__single_file_restored": "Datoteka je vraćena",
        "toast__multiple_files_restored__format": "%d datoteka je vraćeno",
        "toast__single_file_restored_failed": "Greška - datoteka nije vraćena",
        "toast__multiple_files_restored_failed__format": "Greška - %d datoteke su vraćene",
        "toast__single_file_added_to_album__format": "Datoteka je dodata u album „%album_name%“",
        "toast__multiple_files_added_to_album__format": "%d datoteka je dodato u album „%album_name%“",
        "toast__single_file_added_to_album_failed__format": "Greška - datoteka nije dodata",
        "toast__multiple_file_added_to_album_failed__format": "Greška - %d datoteka nije dodato",
        "toast__album_auto_crated__format": "Album „%album_name%“ vam je napravljen",
        "toast__see_album": "Pogledaj album",
        "toast__album_added_to_timeline": "Album je dodat u Fotografije",
        "toast__album_added_to_timeline_failed": "Neuspelo dodavanje albuma — pokušajte ponovo",
        "toast__file_added_to_timeline": "Datoteka dodata u Fotografije",
        "toast__file_added_to_timeline_failed": "Neuspelo dodavanje datoteke — pokušajte ponovo",
        "toast__files_were_shared": "Vaše datoteke su podeljene!",
        "toast__files_are_deleting": "Brisanje datoteka...",
        "toast__files_are_restoring": "Vraćanje datoteka...",
        "toast__files_are_copying": "Kopiranje datoteka...",
        "toast__credit_card_updated": "Informacije o kreditnoj kartici su ažurirane",
        "toast__credit_card_update_failed": "Ažuriranje informacija nije uspelo",
        "toast__purchase_successful": "Plan skladištenja je kupljen",
        "toast__purchase_failed": "Kupovina plana skladištenja nije uspela",
        "toast__plan_canceled": "Plan skladištenja je otkazan",
        "toast__plan_cancel_failed": "Otkazivanje plana skladištenja nije uspelo",
        "toast__plan_change_succeeded": "Plan je uspešno ažuriran",
        "toast__plan_change_failed": "Ažuriranje plana nije uspelo",
        "toast__plan_reactivated": "Plan skladištenja je ponovo aktiviran",
        "toast__plan_reactivation_failed": "Ponovno aktiviranje plana skladištenja nije uspelo",
        "trash_delete_info": "Izbrisane datoteke će biti trajno izbrisane nakon 1 meseca.",
        "trash_no_files": "Nemate nijednu izbrisanu datoteku.",
        "trash_restore_info": "Možete ih vratiti u bilo kom trenutku do tada!",
        "documents_no_files": "Nemate nijednu datoteku.",
        "share_to": "Podeli pomoću",
        "email": "E-mail",
        "SMS": "SMS",
        "get_link": "Nabavite vezu",
        "hold_to_copy_link": "Pritisnite i držite da biste kopirali vezu",
        "copy_link": "Kopiraj link",
        "link_copied": "Veza je kopirana!",
        "share_files_success_header": "Podelili ste album!",
        "can_not_share_video_to_fb_album": "O, ne! Video zapisi ne mogu da se postave u Facebook Album",
        "send_email_to": "Pošalji e-poštu primaocu:",
        "enter_receivers_address": "Unesite adresu primaoca ovde",
        "email_subject": "Predmet:",
        "enter_subject": "Unesite predmet ovde",
        "email_message": "Poruka (neobavezno):",
        "enter_message": "Unesite poruku ovde",
        "invalid_input": "Nije važeće",
        "fb_album_share_desc": "Napišite ime za Fejsbuk album",
        "enter_fb_album_name": "Ime Fejsbuk albuma",
        "fb_permissions_error": "Ups! Treba nam dozvola da objavljujemo na vašem nalogu da biste svoje slike delili na Fejsbuku.",
        "fb_permissions_cta": " Nakon što kliknete na Završeno, od vas će biti zatražene ove dozvole.",
        "share__header_SingleFile__format": "%user_name% je podelio/la ovu datoteku sa vama pomoću %product_name%",
        "share__header_MultipleFiles__format": "%user_name% je podelio/la datoteke sa vama pomoću %product_name%",
        "share__add_singleFile_to_capture__format": "Dodaj datoteku u moj %s",
        "share__add_multipleFiles_to_capture__format": "Dodaj datoteke u moj %s",
        "download_all": "Preuzmi sve",
        "share_added_to_timeline": "Datoteke su dodate",
        "share_added_to_timeline_failed": "Dodavanje datoteka nije uspelo — pokušajte ponovo",
        "share_failed": "Deljenje datoteka nije uspelo - pokušajte ponovo",
        "share__not_capture_user": "Niste korisnik usluge Capture?",
        "share__try_capture": "Kliknite ovde da probate!",
        "share__service_info": "Capture je bezbedna i besplatna usluga za sve vaše fotografije i video zapise. Neograničeni skladišteni prostor je uključen u Telenorovu pretplatu.",
        "share_password_required_description": "Pošiljalac je zaštitio ovo deljenje lozinkom. Molimo vas da unesete lozinku u polje iznad da biste videli deljenje."
    },
    "nb_NO": {
        "to": "til",
        "of": "av",
        "ok": "OK",
        "yes": "Ja",
        "got_it": "Den er grei",
        "see_more": "Se mer",
        "skip": "Hopp over",
        "no": "Nei",
        "done": "Ferdig",
        "reactivate": "Reaktiver",
        "unsubscribe": "Forlat",
        "delete_": "Slett",
        "edit": "Rediger",
        "update": "Oppdater",
        "cancel": "Avbryt",
        "share": "Del",
        "undo": "Angre",
        "back": "Tilbake",
        "select_all": "Velg alle",
        "restore": "Gjenopprett",
        "filename": "filnavn",
        "file_type": "filtype",
        "deleted": "slettet",
        "unknown": "ukjent",
        "retry": "Prøv igjen",
        "create_account": "Opprett konto",
        "sign_in": "Logg på",
        "logged_out_prompt": "Ops, du ble logget ut. Vennligst logg inn for å fortsette",
        "capture_save_see_share": "Lagre, se og del alle dine bilder og videoer.",
        "feature_only_available_in_app": "Denne funksjonen er foreløpig kun tilgjengelig i Min Sky-appen.",
        "open_capture": "Åpne Min Sky",
        "open_in_app": "Åpne i app",
        "storage": "Lagring",
        "active": "Aktiv",
        "save": "Lagre",
        "january": "Januar",
        "february": "Februar",
        "march": "Mars",
        "april": "April",
        "may": "Mai",
        "june": "juni",
        "july": "juli",
        "august": "August",
        "september": "September",
        "october": "Oktober",
        "november": "november",
        "december": "Desember",
        "day": "Dag",
        "month": "Måned",
        "year": "År",
        "monthly": "månedlig",
        "yearly": "årlig",
        "jan": "Jan",
        "feb": "Feb",
        "mar": "Mar",
        "apr": "Apr",
        "jun": "Jun",
        "jul": "Jul",
        "aug": "Aug",
        "sep": "Sep",
        "oct": "Okt",
        "nov": "Nov",
        "dec": "Des",
        "delete_files_prompt__format": "%d filer blir flyttet til Slettede elementer. Filene blir slettet permanent etter 30 dager.",
        "delete_single_file_prompt": "Filen blir flyttet til Slettede elementer. Filen blir slettet permanent etter 30 dager.",
        "delete_file_failed_message": "Filen kunne ikke slettes",
        "delete_file_failed_message__format": "En feil oppstod under forsøk på å slette \"%s\". Prøv igjen.",
        "delete_album_file_prompt_text": "Filen blir fjernet fra albumet. Kommentarer og reaksjoner på filen blir også slettet.",
        "delete_last_file_prompt_text": "Filen blir fjernet fra albumet. Fjerning av siste filen i et album vil også slette albumet.",
        "unsubscribe_album_prompt_text": "Du vil ikke lenger se dette albumet i albumlisten eller motta oppdateringer om albumet.",
        "delete_album_prompt_text": "Albumet blir slettet og fjernet fra albumlisten.",
        "delete_comment_prompt_text": "Kommentaren vil bli slettet.",
        "remove_device_prompt__format": "Du vil bli logget ut av %s på denne enheten.",
        "delete_single_document_file": "Filen blir slettet permanent.",
        "delete_document_files__format": "%d filer blir slettet permanent.",
        "restore_single_file_prompt": "Filen blir gjenopprettet til dine bilder.",
        "restore_files_prompt__format": "%d filer blir gjenopprettet til dine bilder.",
        "permanently_delete_single_file_prompt": "Filen blir slettet permanent. Dette kan ikke angres.",
        "permanently_delete_files_prompt__format": "%d filer blir slettet permanent. Dette kan ikke angres.",
        "wall": "Vegg",
        "timeline": "Bilder",
        "albums": "Album",
        "your_albums": "Dine album",
        "menu": "Meny",
        "add_files": "Legg til filer",
        "documents": "Filer",
        "settings": "Innstillinger",
        "account": "Konto",
        "help": "Hjelp",
        "deleted_items": "Slettede elementer",
        "login": "Logg inn",
        "log_out": "Logg ut",
        "close": "Lukk",
        "wall_desc": "En personlig side med bildene dine som du kan redigere selv.",
        "timeline_desc": "Alle bilder og videoer, organisert etter dato.",
        "albums_desc": "Legg til bilder i et album og del med vennene dine!",
        "go_back_to_legacy_ribbon": "Takk for at du bruker denne nye funksjonen. Klikk her for å gå tilbake til vanlig versjon og få tilgang til alle funksjoner.",
        "drag_drop": "Dra & slipp",
        "dropZone_text__timeline": "bilder for å laste dem opp, eller en mappe for å også lage album",
        "dropZone_text__albums": "bilder eller mapper for å lage nye album",
        "dropZone_text__singleAlbum": "bilder hvor som helst for å laste de opp til %album_name%",
        "files": "fil(er)",
        "uploaded": "lastet opp",
        "did_not_upload": "ble ikke lastet opp",
        "upload_folder_reject": "Kan ikke laste opp mapper",
        "upload_folder_safari_reject": "Safari støtter ikke opplasting av mapper",
        "unsupported_file_reject": "Filen støttes ikke",
        "was_not_uploaded": "Filen ble ikke lastet opp",
        "see_which": "Se hvilke",
        "offline": "Ingen internettforbindelse",
        "upload": "Last opp",
        "uploading": "Laster opp",
        "retrying_in": "Prøver igjen om",
        "sec": "sek",
        "retrying_now": "Prøver igen nå...",
        "try_again": "Prøv igjen",
        "stop": "Stopp",
        "complete": "Ferdig",
        "cancelled": "Avbrutt",
        "preparing": "Forbereder...",
        "waiting_for_internet_connection": "Venter på internettforbindelse...",
        "out_of_storage": "Tomt for lagringsplass",
        "free_up_space": "Slett bilder eller videoer for å fortsette opplastingen",
        "cancelling_upload_are_you_sure": "Sikker på at du vil stoppe opplastingen?",
        "reached_given_storage_ratio": "Lite lagringsplass",
        "reached_given_storage_ratio_subText": "Du kan frigjøre plass ved å slette bilder eller videoer, eller kjøpe mer lagringsplass i Innstillinger.",
        "not_enough_storage_for_add": "Ikke nok lagringsplass",
        "not_enough_storage_for_add_subText": "Du har ikke nok lagringsplass for alle filene du prøver å legge til. Frigjør plass ved å slette bilder eller videoer, eller kjøp mer lagringsplass i Innstillinger.",
        "not_enough_storage_for_restore": "Ikke nok lagringsplass",
        "not_enough_storage_for_restore_subText": "Du har ikke nok lagringsplass for alle filene du prøver å gjenopprette. Frigjør plass ved å slette bilder eller videoer, eller kjøp mer lagringsplass i Innstillinger.",
        "timeline_is_empty": "Tidslinjen din er tom.",
        "timeline_filtered_photos_empty": "Du har ingen bilder.",
        "start_uploading_photos_now": "Hvorfor ikke laste opp noen bilder nå?",
        "start_uploading_now": "Hvorfor ikke begynne å laste opp noen nå?",
        "drag_drop_photos_to_upload": "Dra og slipp bildene hit, eller trykk på knappen under.",
        "download_app_to_upload_directly_from_device": "Installer Min Sky-appen på din mobil eller nettbrett for å laste opp bilder og videoer direkte fra enhetens kamera og bildegalleri",
        "timeline_empty_screenshot_filter": "Du har ingen skjermbilder.",
        "timeline_filtered_videos_empty": "Du har ingen videoer.",
        "drag_drop_files_to_upload": "Du kan dra og slippe filene her, eller trykke på knappen under.",
        "add_videos": "Legg til video",
        "selected__format": "%d valgt",
        "select_files": "Velg filer",
        "download_limit_exceeded": "Du kan ikke laste ned mer enn 500 elementer av gangen. Vennligst velg færre elementer.",
        "filter_by_screenshots": "Skjermbilder",
        "filter_by_videos": "Video",
        "filter_by_images": "Bilder",
        "show_all": "Vis alle",
        "album": "album",
        "item__format": "%d element",
        "items__format": "%d elementer",
        "participant__format": "%d deltaker",
        "participants__format": "%d deltakere",
        "participants": "Deltakere",
        "by": "av",
        "create_album": "Lag album",
        "auto_created_album_title": "Album uten navn",
        "new_album": "Nytt album",
        "filter_by": "Filtrer etter",
        "filter_albums": "Filtrer album",
        "filtered_by_private": "Filtrert etter privat",
        "filtered_by_shared": "Filtrert etter delt",
        "all_albums": "Alle album",
        "shared_albums": "Delte album",
        "private_albums": "Private album",
        "download_album": "Last ned album",
        "add_photos": "Legg til bilder",
        "add_to_album": "Legg til i album",
        "error_msg_unsupported_media_type": "Ops! Noe gikk galt.",
        "you_and_others_love_message_format": "Du og %d andre liker dette",
        "you_and_one_love_message": "Du og 1 liker dette",
        "you_love_message": "Du liker dette",
        "one_person_love_message": "1 liker dette",
        "people_love_message_format": "%d liker dette",
        "leave_album": "Forlat album",
        "unsubscribe_album_failed_message__format": "En feil oppstod under forsøk på å gå ut av album \"%s\". Prøv igjen.",
        "delete_album": "Slett album",
        "delete_file": "Slett fil",
        "download_file": "Last ned fil",
        "download_files_failed_message": "En feil oppsto ved forsøk på nedlasting. Vennligst prøv igjen senere.",
        "delete_album_failed_message__format": "En feil oppstod under forsøk på å slette albumet \"%s\". Prøv igjen.",
        "set_as_cover_photo": "Sett som forsidebilde",
        "set_cover_photo_failed_message": "Forsidebildet kunne ikke endres. Vennligst prøv igjen.",
        "add_file_to_timeline": "Legg til i dine bilder",
        "add_album_to_timeline": "Legg albumet til dine bilder",
        "change_album_viewMode": "Endre bildevisning",
        "sort_album_files": "Sorter album",
        "sort_album_files_title": "Sorter album etter",
        "sort_album_files_by_added": "Nylig lagt til",
        "sort_album_files_by_newest_first": "Nyeste først",
        "sort_album_files_by_oldest_first": "Eldste først",
        "album_list_is_empty": "Du har ingen album ennå.",
        "empty_albumList_secondTitle": "Hvorfor ikke lage et album nå?",
        "empty_filtered_private_albumList_title": "Du har ingen private album.",
        "empty_filtered_shared_albumList_title": "Du har ingen delte album.",
        "empty_filtered_albumList_subtext": "Det er enkelt å opprette et nytt album! Trykk på knappen under for å starte.",
        "album_select": "Velg et album",
        "create_new": "Lag nytt",
        "private_album": "Privat album",
        "shared_album": "Delt album",
        "share_created_album_prompt_header": "Du har laget et album!",
        "share_created_album_prompt_text": "Del det med venner og familie, slik at de kan legge til sine egne bilder og kommentarer.",
        "share_later": "Del senere",
        "share_now": "Del nå",
        "comment": "kommentar",
        "comments": "kommentarer",
        "write_your_comment": "Skriv kommentaren din her",
        "comment_error_message": "En feil oppstod ved kommentering. Vennligst prøv igjen.",
        "more": "Mer",
        "edited": "Redigert",
        "edit_comment_error": "Klarte ikke å redigere kommentar",
        "delete_comment_error": "Klarte ikke å slette kommentar",
        "view_all_comments": "Se alle kommentarer",
        "what_is_your_name": "Hva heter du?",
        "tap_here_to_enter_name": "Trykk her for å angi navn",
        "click_here_to_enter_name": "Legg til navn her",
        "enter": "enter",
        "for_best_experience": "For den beste opplevelsen",
        "register_or_sign_in": "registrer eller logg inn nå!",
        "year_ago__format": "%d år siden",
        "years_ago__format": "%d år siden",
        "month_ago__format": "%d måned siden",
        "months_ago__format": "%d måneder siden",
        "day_ago__format": "%d dag siden",
        "days_ago__format": "%d dager siden",
        "hour_ago__format": "%d time siden",
        "hours_ago__format": "%d timer siden",
        "minute_ago__format": "%d minutt siden",
        "minutes_ago__format": "%d minutter siden",
        "just_now": "akkurat nå",
        "post": "Send",
        "slideshow": "Bildefremvisning",
        "play": "Spill av",
        "pause": "Pause",
        "previous": "Forrige",
        "next": "Neste",
        "exit_fullscreen": "Avslutt",
        "info": "Info",
        "releaseNotes_v0_header": "Ny og oppdatert Min Sky!",
        "releaseNotes_v0_p1": "Finn bilder raskere med ny hurtignavigering og filtrering",
        "releaseNotes_v0_p2": "Last opp ved å dra og slippe bilder hvor som helst på skjermen",
        "releaseNotes_v0_p3": "Opprett, rediger og del album",
        "releaseNotes_v0_p4": "Lag album sammen med venner og familie",
        "releaseNotes_v0_p5": "Og mye mer ...",
        "profile_info": "Profilinformasjon",
        "enter_your_name": "Oppgi ditt navn",
        "storage_management": "Lagringsinnstillinger",
        "total_storage": "Total lagringsplass",
        "remaining_storage": "Gjenstående",
        "used_storage": "Brukt",
        "buy_more_storage": "Kjøp mer plass",
        "connected_devices": "Tilkoblede enheter",
        "unlimited": "Ubegrenset",
        "your_storage_plan": "Ditt lagringsabonnement",
        "storage_used_out_of__format": "%storage_used% brukt av %storage_total%",
        "cancel_storage_plan": "Stopp lagringsabonnement",
        "renew_status__format": "Lagringsabonnementet fornyes på %s",
        "expired_status__format": "Lagringsabonnementet utløper på %s",
        "reactivate_storage_plan": "Reaktiver lagringsabonnement",
        "payment_settings": "Betalingsinnstillinger",
        "payment_settings_info_text": "Registrert betalingsinformasjon:",
        "update_card": "Endre betalingsinformasjon",
        "card_number": "Kortnummer",
        "card_number_placeholder": "Fyll inn kortnummer",
        "card_exp_date": "Utløpsdato",
        "card_cvc": "CVC/CVV",
        "confirm_payment": "Bekreft betaling",
        "payment": "Betaling",
        "choose_payment_method": "Velg betalingsmåte",
        "pay_with_vipps": "Betal med Vipps",
        "save__format": "Spar %d",
        "get_started": "Kom i gang!",
        "telenor_unlimited_hook__format": "Som Telenor mobilkunde kan du få ubegrenset lagringsplass i %product_name%",
        "telenor_unlimited_hook_subtext": "Gjelder abonnementene Fleksi/Medium/Trygg/Yng/U18/Frihet/Bedrift Fri+",
        "monthly_sub": "Månedlig betaling",
        "yearly_sub": "Årlig betaling",
        "language": "Språk",
        "select_language": "Velg språk",
        "privacy": "Personvern",
        "thanks_for_signing_in": "Takk for at du logget inn!",
        "get_capture_to_create_album": "Last ned Min Sky på mobilen for å lage dine egne album.",
        "return_to_album": "Gå tilbake til albumet",
        "telenor_capture_text": "Min Sky er utviklet av Telenor Digital, et Telenor-selskap som tilbyr morgendagens digitale løsninger. For å bruke tjenester fra Telenor Digital bruker du din Connect-konto.",
        "terms_of_service": "Brukervilkår",
        "customer_service": "Hjelp og kundeservice",
        "capture_footer_text_mobile": "Få mer ut av Min Sky, last ned appen",
        "open": "åpne",
        "download": "Last ned",
        "album_not_found": "Fant ikke albumet",
        "album_not_found_description": "Vi fant dessverre ikke albumet! Det er mulig lenken er feilstavet eller har utløpt, eller at albumet er blitt slettet.",
        "capture_create_user_description": "Min Sky er en trygg og brukervennlig lagringstjeneste for bilder og videoer. Dersom du ikke allerede har Min Sky, last ned appen i dag! ",
        "capture_more_link": "Lær mer om Min Sky",
        "share_not_found": "Finner ikke de delte filene",
        "share_not_found_description": "Du kan ha klikket på en utgått, slettet eller feilskrevet link.",
        "page_not_found": "Vi kan ikke finne siden du leter etter!",
        "helpful_links_description": "Her er noen nyttige lenker i steden",
        "helpful_links_about_capture": "Om Min Sky",
        "password_required": "Passord påkrevet",
        "password_input_placeholder": "Klikk her for å angi passord",
        "password_required_submit": "enter",
        "password_incorrect": "Feil passord",
        "album_password_required_description": "Avsenderen har beskyttet dette albumet med et passord. Vennligst oppgi passordet i boksen over for å få tilgang til albumet.",
        "needs_connect_to_create_album": "Du trenger en %product_name%-konto for å opprette album",
        "needs_login_to_copy_files_to_timeline": "Du trenger en %product_name%-konto for å lagre disse filene",
        "needs_login_to_copy_album_to_timeline": "Du trenger en %product_name%-konto for å lagre filer fra dette albumet",
        "no_title_yet": "Ingen tittel ennå",
        "add_album_title": "Skriv inn albumtittel",
        "upgrade_your_browser": "Vennligst oppgrader nettleseren din",
        "upgrade_your_browser_des": "Beklager! Dette innholdet kan ikke ses i din nåværende nettleser. Oppgrader eller bruk en annen nettleser som Chrome, Firefox eller Microsoft Edge.",
        "share_album": "Del album",
        "edit_album_settings": "Rediger albuminnstillinger",
        "enabled": "Aktivert",
        "disabled": "Deaktivert",
        "privacy_private": "Privat",
        "privacy_shared": "Delt",
        "edit_album_title_text": "Albumtittel (Trykk for å endre):",
        "edit_allow_comments_text": "Alle kan se og legge til kommentarer",
        "edit_allow_upload_text": "Alle kan legge til bilder",
        "edit_privacy_mode_text": "Personvernnivå",
        "toast__coverPhoto_was_set": "Forsidebilde endret",
        "toast__multiple_files_deleted__format": "%d filer slettet",
        "toast__single_file_deleted": "Fil slettet",
        "toast__multiple_files_perm_deleted__format": "%d filer slettet permanent",
        "toast__single_file_perm_deleted": "Fil permanent slettet",
        "toast__multiple_files_perm_deleted_failed__format": "Feil - %d filer ble ikke permanent slettet",
        "toast__single_file_perm_deleted_failed": "Feil - fil ble ikke permanent slettet",
        "toast__single_file_restored": "Fil gjenopprettet",
        "toast__multiple_files_restored__format": "%d filer gjenopprettet",
        "toast__single_file_restored_failed": "Feil - fil ikke gjenopprettet",
        "toast__multiple_files_restored_failed__format": "Feil - %d filer ikke gjenopprettet",
        "toast__single_file_added_to_album__format": "Fil lagt til i albumet \"%album_name%\"",
        "toast__multiple_files_added_to_album__format": "%d filer lagt til i albumet \"%album_name%\"",
        "toast__single_file_added_to_album_failed__format": "Feil - filen ble ikke lagt til",
        "toast__multiple_file_added_to_album_failed__format": "Feil - %d filer ble ikke lagt til",
        "toast__album_auto_crated__format": "Albumet \"%album_name%\" ble laget for deg",
        "toast__see_album": "Se albumet",
        "toast__album_added_to_timeline": "Album lagt til i dine bilder",
        "toast__album_added_to_timeline_failed": "Kunne ikke legge til album - prøv igjen",
        "toast__file_added_to_timeline": "Fil lagt til i Bilder",
        "toast__file_added_to_timeline_failed": "Kunne ikke legge til fil - prøv igjen",
        "toast__files_were_shared": "Filene dine ble delt!",
        "toast__files_are_deleting": "Sletter filer...",
        "toast__files_are_restoring": "Gjenoppretter filer...",
        "toast__files_are_copying": "Kopierer filer...",
        "toast__credit_card_updated": "Betalingsinformasjon oppdatert",
        "toast__credit_card_update_failed": "Kunne ikke oppdatere informasjon",
        "toast__purchase_successful": "Lagringsabonnement kjøpt",
        "toast__purchase_failed": "Kunne ikke kjøpe lagringsabonnement",
        "toast__plan_canceled": "Lagringsabonnement stoppet",
        "toast__plan_cancel_failed": "Kunne ikke stoppe lagringsabonnement",
        "toast__plan_change_succeeded": "Lagringsabonnement oppdatert",
        "toast__plan_change_failed": "Kunne ikke oppdatere lagringsabonnement",
        "toast__plan_reactivated": "Lagringsabonnement reaktivert",
        "toast__plan_reactivation_failed": "Kunne ikke reaktivere lagringsabonnement",
        "trash_delete_info": "Slettede filer vil bli permanent slettet etter 1 måned.",
        "trash_no_files": "Du har ingen slettede filer.",
        "trash_restore_info": "Du kan gjenopprette dem når som helst før det!",
        "documents_no_files": "Du har ingen filer.",
        "share_to": "Del via",
        "email": "E-post",
        "SMS": "SMS",
        "get_link": "Få link",
        "hold_to_copy_link": "Trykk og hold for å kopiere linken",
        "copy_link": "Kopier lenke",
        "link_copied": "Link ble kopiert!",
        "share_files_success_header": "Du har delt et album!",
        "can_not_share_video_to_fb_album": "OBS! Videoer kan ikke deles i Facbook-album",
        "send_email_to": "Send e-post til:",
        "enter_receivers_address": "Skriv inn mottakers adresse her",
        "email_subject": "Emne:",
        "enter_subject": "Skriv inn emne her",
        "email_message": "Melding (valgfritt):",
        "enter_message": "Skriv inn meldingen her",
        "invalid_input": "Ugyldig",
        "fb_album_share_desc": "Gi Facbook-albumet et navn",
        "enter_fb_album_name": "Navnet på Facebook-album",
        "fb_permissions_error": "Obs! For å dele bilder til Facebook trenger vi tillatelse fra deg til å publisere på kontoen din.",
        "fb_permissions_cta": " Du vil bli bedt om disse rettighetene etter å ha klikket ferdig.",
        "share__header_SingleFile__format": "%user_name% har delt en fil med deg ved hjelp av %product_name%",
        "share__header_MultipleFiles__format": "%user_name% har delt noen filer med deg ved hjelp av %product_name%",
        "share__add_singleFile_to_capture__format": "Legg til fil i min %s",
        "share__add_multipleFiles_to_capture__format": "Legg til filene i min %s",
        "download_all": "Last ned alle",
        "share_added_to_timeline": "Filene ble lagt til",
        "share_added_to_timeline_failed": "Kunne ikke legge til filer - vennligst prøv igjen",
        "share_failed": "Kunne ikke dele filer - vennligst prøv igjen",
        "share__not_capture_user": "Ikke en Min Sky-bruker?",
        "share__try_capture": "Klikk her for å prøve!",
        "share__service_info": "Capture er en trygg og gratis lagringstjeneste for alle dine bilder og videoer. Ubegrenset lagringsplass medfølger et Telenor-abonnement.",
        "share_password_required_description": "Avsenderen har beskyttet det delte innholdet med et passord. Vennligst oppgi passordet i boksen over for å få tilgang til innholdet."
    }
};
