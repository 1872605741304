import * as React from 'react';
import {connect} from 'react-redux';
import {_} from '../../assets';
import {State} from '../../state';
import {getSubscribersForJob} from '../../state/album/selectors';
import {UserInfo} from '../../state/users/selectors';
import {UsersListModal} from './UsersListModal';

type OwnProps = {
    albumID: JobID,
    onClose: () => any,
    isMobile: boolean,
};
type StateProps = {
    participantsList: UserInfo[]|undefined,
};
type Props = OwnProps & StateProps;

class ParticipantsList extends React.Component<Props> {
    public render() {

        return (
            <UsersListModal
                isMobile={this.props.isMobile}
                usersList={this.props.participantsList}
                headerText={_('participants')}
                onClose={this.props.onClose}
            />
        );
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    participantsList: getSubscribersForJob(state, ownProps.albumID),
});

export const AlbumParticipantsList = connect(mapStateToProps)(ParticipantsList);
