import * as React from 'react';
import styled from 'styled-components';

type vPos = 'top' | 'bottom';
type hPos = 'left' | 'center' | 'right';
export type Position = {v: vPos, h: hPos};

const vPosInverse: Record<vPos, vPos> = {
    top: 'bottom',
    bottom: 'top',
};
const hPosInverse: Record<hPos, hPos> = {
    left: 'right',
    center: 'center',
    right: 'left',
};
const inversePosition = (pos: Position): Position => ({
    v: vPosInverse[pos.v],
    h: hPosInverse[pos.h],
});

const RelativeWrapper = styled.div`
    display: inline-block;
    position: relative;
`;

const vPosMap: Record<vPos, string> = {
    top: 'bottom: 100%;',
    bottom: 'top: 100%;',
};
const hPosMap: Record<hPos, string> = {
    left: 'left: 0;',
    center: 'left: 50%; transform: translateX(-50%);',
    right: 'right: 0;',
};

/**
 * Low-level component to make content hide/show by scaling the content.
 */
type ExpandableElementProps = {
    origin: Position,
    isExpanded: boolean,
    expandTime?: string, // Defaults to '0.2s'
    collapseTime?: string, // Defaults to '0.2s'
    position: Position,

};
export const ExpandableElement = styled.div`
    position: absolute;
    ${(props: ExpandableElementProps) => vPosMap[props.position.v]}
    ${(props) => hPosMap[props.position.h]}

    transform: ${(props) => props.isExpanded ? 'scale(1)' : 'scale(0)'};
    transition: transform ${(props) => (props.isExpanded ? props.expandTime : props.collapseTime) || '0.2s'};
    transform-origin: ${(props) => props.origin.v + ' ' + props.origin.h};
`;

/**
 * Mid-level component to place the `placed`-node scale at the `position` relative to the component children
 * based on the `isExpanded`-flag
 */
type ExpandablePlacementProps = {
    content: React.ReactNode
    isExpanded: boolean,
    position: Position,
};
export const ExpandablePlacement: React.SFC<ExpandablePlacementProps> = (props) => {
    const expandOrigin = {
        v: inversePosition(props.position).v, // Placed below, expand from top and vice versa
        h: props.position.h,
    };
    const stopPropagation = (e: any) => {
        e.stopPropagation();
    };

    return (
        <RelativeWrapper onClick={stopPropagation}>
            <ExpandableElement origin={expandOrigin} isExpanded={props.isExpanded} position={props.position}>
                {props.content}
            </ExpandableElement>
            {props.children}
        </RelativeWrapper>
    );
};

/**
 * Higher order-component for making an element toggle the visibility of its children when clicked.
 * Provide position of child-element relative to the button and a SFC-method for making the actual button-element
 */
type AddedButtonProps = {isExpanded: boolean, toggleExpand: () => void, doExpand: () => void};
export type ButtonProps<T = {}> = T & AddedButtonProps;
type AddedContentProps = {isExpanded: boolean, doCollapse: () => void};
export type ContentProps<T = {}> = T & AddedContentProps;
export const ButtonWithExpandingContent = (position: Position) =>
<TB extends {} = {}, TC = {}>(
    Button: React.ComponentType<ButtonProps<TB>>,
    Content: React.ComponentType<ContentProps<TC>>,
) => {
    type State = {isExpanded: boolean};
    return class SomeButtonWithExpandingOptions extends React.Component<TB & TC, State>{

        public state: State = {isExpanded: false};
        private toggle = () => {
            this.setState({isExpanded: !this.state.isExpanded});
        }
        private collapse = () => {
            this.setState({isExpanded: false});
        }
        private expand = () => {
            this.setState({isExpanded: true});
        }

        public render() {
            const addedButtonProps: AddedButtonProps = {
                isExpanded: this.state.isExpanded,
                toggleExpand: this.toggle,
                doExpand: this.expand,
            };
            const addedContentProps: AddedContentProps = {
                isExpanded: this.state.isExpanded,
                doCollapse: this.collapse,
            };
            return (
                <ExpandablePlacement
                    position={position}
                    isExpanded={this.state.isExpanded}
                    content={<Content {...addedContentProps} {...this.props} />}
                >
                    <Button {...addedButtonProps} {...this.props} />
                </ExpandablePlacement>
            );
        }
    };
};

export const ButtonWithBottomRightExpandingContent = ButtonWithExpandingContent({v: 'bottom', h: 'right'});
