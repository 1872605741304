import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */

export const InfoIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill={props.color || colors.defaultIconColor} fillRule="nonzero" transform="translate(2.000000, 2.000000)">
            <path d="M9,15 L11,15 L11,9 L9,9 L9,15 Z M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M10,18 C5.59,18 2,14.41 2,10 C2,5.59 5.59,2 10,2 C14.41,2 18,5.59 18,10 C18,14.41 14.41,18 10,18 Z M9,7 L11,7 L11,5 L9,5 L9,7 Z"/>
        </g>
    </svg>
);
