import * as React from 'react';
import styled from 'styled-components';
import {FullscreenOverlay} from './FullscreenOverlay';

const ComponentWrapper = styled.div`
    ${(props: {isMobile: boolean}) => props.isMobile ? 'width: 96%;' : ''}
    max-width: ${(props) => props.isMobile ? 400 : 600}px;
    max-height: ${(props) => props.isMobile ? 85 : 60}%;
    border-radius: 3px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: white;
    padding: 16px;
    box-sizing: border-box;
    transition: height 0.3s;

    display: flex;
    flex-direction: column;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
`;

const Content = styled.div`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
`;

type Props = {
    isMobile: boolean,
    onClose: () => any,
    headerElements?: React.ReactNode,
};

export class OverlayModal extends React.Component<Props> {
    private handleKeyUp = (event: KeyboardEvent) => {
        if (event.keyCode === 27) { // Escape
            this.props.onClose();
        }
    }
    public componentDidMount() {
        window.addEventListener('keyup', this.handleKeyUp);
    }
    public componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp);
    }
    public render() {

        return (
            <FullscreenOverlay onOverlayClick={this.props.onClose}>
                <ComponentWrapper isMobile={this.props.isMobile}>
                    <div>{this.props.headerElements}</div>
                    <Content>
                        {this.props.children}
                    </Content>
                </ComponentWrapper>
            </FullscreenOverlay>
        );
    }
}
