import * as React from 'react';
import {APP_STORE_LINK_ANDROID, APP_STORE_LINK_IOS} from '../../../../config/constants';
import {AppStoreBadge} from '../Icons/AppStoreBadge';
import {GooglePlayBadge} from '../Icons/GooglePlayBadge';

type AppBadgeLinkProps = {
    isMobile: boolean,
    link: string,
    badge: typeof AppStoreBadge| typeof GooglePlayBadge,
};

const AppBadgeLink: React.SFC<AppBadgeLinkProps> = (props) => (
    <a href={props.link}>
        <props.badge
            width={'135px'}
            height={props.isMobile ? '40px' : '100%'}
        />
    </a>
);

type BadgeLinkProps = {
    isMobile: boolean,
};

export const GooglePlayBadgeLink: React.SFC<BadgeLinkProps> = (props) => (
    <AppBadgeLink
        isMobile={props.isMobile}
        link={APP_STORE_LINK_ANDROID}
        badge={GooglePlayBadge}
    />
);

export const AppStoreBadgeLink: React.SFC<BadgeLinkProps> = (props) => (
    <AppBadgeLink
        isMobile={props.isMobile}
        link={APP_STORE_LINK_IOS}
        badge={AppStoreBadge}
    />
);
