import {createActionCreator, createVoidActionCreator} from '../common/actions';

export const EnteredCarouselView = createActionCreator<JobFileReference>('ENTERED_CAROUSEL_VIEW');
export const ChangedCurrentCarouselFile = createActionCreator<JobFileReference>('NAVIGATED_TO_FILE_CAROUSEL_VIEW');

export type SideModuleContentType = 'comments' | 'info';
export const CarouselViewSideModuleOpened = createActionCreator<SideModuleContentType>('SIDE_MODULE_CAROUSEL_VIEW_WAS_OPENED');
export const CarouselViewSideModuleClosed = createVoidActionCreator('SIDE_MODULE_CAROUSEL_VIEW_WAS_CLOSED');

export const FullscreenModeEntered = createVoidActionCreator('ENTERED_FULLSCREEN_MODE');
export const FullscreenModeExited = createVoidActionCreator('EXITED_FULLSCREEN_MODE');
