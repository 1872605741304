import {FileGroupType, Reaction} from '../../API/services/AppService';
import {FileTarget} from '../../utilities/fileTarget';
import {Action, isType} from '../common/actions';
import {FileMetadata} from '../fileMetadata/actions';
import {
    AllJobFilesWasFetched, FileDescription,
    FileRangeWasFetched, FilesDeletionStarted,
    FilesRestorationSucceeded, FileWasAddedToJob,
    FileWasCopiedToJob, FileWasRemovedFromJob,
} from '../job/actions';
import {
    FileCommentAborted, FileCommentError,
    FileCommentSubmitSuccessful, FileCommentSubmitted,
    FileDeletionFailed, FileDeletionStarted,
    FileDimensions, FileDimensionsDiscovered,
    ReactionChangesSubmitError, ReactionChangesSubmitSuccessfull, ReactionChangesSubmitted,
    VideoTranscodeError, VideoTranscodeReady, VideoTranscodeStarted,
} from './actions';

export enum CommentStatus {
    NOT_YET_SUBMITTED,  // Default state
    PENDING,            // Comment is sent to backend, waiting for response
    ERROR,              // Something went wrong when trying to save comment
}

export enum EncodingStatus {
    PENDING,    // In Queue encoding state
    ENCODED,    // Encoded
    ERROR,      // Encoding check failed
}

export type JobFile = {
    jobID: JobID
    fileID: FileID,
    addedBy: UserID,
    duration?: number,
    encodingStatus?: EncodingStatus,
    checksum: string,
    ctime?: number,  // Not present on all files
    mtime: number,
    timestamp: number, // timestamp when file was posted
    path: string,
    size: number,
    width?: number,
    height?: number,
    pendingComment: string,
    pendingCommentStatus: CommentStatus,
    isPendingServerDelete: boolean,
    pendingReaction?: Reaction,
    isDeleted: boolean,
    group?: {
        type: FileGroupType,
        id: string,
        isMaster: boolean,
    },
};

export type CaptureFile = JobFile & {
    type: FileTarget,
    thumbURLSmall: string,
    thumbURLMedium: string,
    thumbURLLarge: string,
    metadata?: FileMetadata,
};

export type FilesState = DictionaryOf<JobFile>;

function newStateWithElementChanges(state: FilesState, elementId: FileID, changes: any): FilesState {
    const element: JobFile = {
        ...state[elementId],
        ...changes,
    };
    return {
        ...state,
        [elementId]: element,
    };
}

const initialState = {};

const fileFromFileDescription = ({user_uuid, group_type, group_id, master, ...fileInfo}: FileDescription): JobFile => {
    const group = group_type !== undefined && group_id !== undefined ? {
        type: group_type,
        id: group_id,
        isMaster: master === '1',
    } : undefined;
    return {
        ...fileInfo,
        addedBy: user_uuid,
        pendingComment: '',
        pendingCommentStatus: CommentStatus.NOT_YET_SUBMITTED,
        isPendingServerDelete: false,
        isDeleted: false,
        group,
    };
};

// TODO: separate this list to single state
type FilePathList = DictionaryOf<DictionaryOf<FileID>>; // indexed by [path][jobID]
const filePathListFromFiles = (files: FilesState) => {
    return Object.keys(files).reduce<FilePathList>((list, f) => {
        const file = files[f];
        list[file.path] = {
            ...list[file.path],
            [file.jobID]: file.fileID,
        };
        return list;
    }, {});
};

const stateWithNewFiles = (originState: FilesState, newFiles: JobFile[]): FilesState => {
    const originList = filePathListFromFiles(originState);
    const newState = {...originState};

    newFiles.forEach((f) => {
        if (originList[f.path] && originList[f.path][f.jobID]) {
            const duplicateFile = originList[f.path][f.jobID];
            if (duplicateFile !== f.fileID) {
                delete newState[duplicateFile];
            }
        }

        originList[f.path] = {
            ...originList[f.path],
            [f.jobID]: f.fileID,
        };
        newState[f.fileID] = f;
    });

    return newState;
};

export function filesReducer(state: FilesState = initialState, action: Action<any>): FilesState {
    if (isType(action, FileWasAddedToJob)) {
        return stateWithNewFiles(state, [fileFromFileDescription(action.payload)]);
    }
    if (isType(action, FileWasCopiedToJob)) {
        if (state[action.payload.to.fileID] !== undefined || state[action.payload.from] === undefined) {
            return state;
        }
        const newFile: JobFile = {
            ...state[action.payload.from],
            fileID: action.payload.to.fileID,
            jobID: action.payload.to.jobID,
        };

        return {
            ...state,
            [newFile.fileID]: newFile,
        };
    }

    if (isType(action, FileRangeWasFetched) || isType(action, AllJobFilesWasFetched)) {
        return stateWithNewFiles(state, action.payload.files.map(fileFromFileDescription));
    }

    if (isType(action, FileWasRemovedFromJob)) {
        return newStateWithElementChanges(state, action.payload.fileID, {
            isDeleted: true,
            isPendingServerDelete: false,
        });
    }

    if (isType(action, FilesRestorationSucceeded)) {
        const changedState = action.payload.files.reduce((changedFiles: DictionaryOf<JobFile>, f) => {
            if (state[f]) {
                changedFiles[f] = {...state[f], isDeleted: false, isPendingServerDelete: false};
            }
            return changedFiles;
        }, {});

        return {...state, ...changedState};
    }

    if (isType(action, FilesDeletionStarted)) {
        const changedState = action.payload.files.reduce((changedFiles: DictionaryOf<JobFile>, f) => {
            if (state[f]) {
                changedFiles[f] = {...state[f], isPendingServerDelete: false};
            }
            return changedFiles;
        }, {});

        return {...state, ...changedState};
    }

    if (isType(action, FileDeletionStarted)) {
        if (state[action.payload]) {
            return newStateWithElementChanges(state, action.payload, {
                isPendingServerDelete: true,
            });
        }
    }

    if (isType(action, FileDeletionFailed)) {
        if (state[action.payload]) {
            return newStateWithElementChanges(state, action.payload, {
                isPendingServerDelete: false,
            });
        }
    }

    if (isType(action, FileCommentSubmitted)) {
        return newStateWithElementChanges(state, action.payload.fileID, {
            pendingCommentStatus: CommentStatus.PENDING,
            pendingComment: action.payload.comment,
        });
    }
    if (isType(action, FileCommentSubmitSuccessful)) {
        return newStateWithElementChanges(state, action.payload, {
            pendingComment: '',
            pendingCommentStatus: CommentStatus.NOT_YET_SUBMITTED,
        });
    }
    if (isType(action, FileCommentError)) {
        return newStateWithElementChanges(state, action.payload, {pendingCommentStatus: CommentStatus.ERROR});
    }
    if (isType(action, FileCommentAborted)) {
        return newStateWithElementChanges(state, action.payload, {
            pendingCommentStatus: CommentStatus.NOT_YET_SUBMITTED,
        });
    }

    if (isType(action, VideoTranscodeStarted)) {
        return newStateWithElementChanges(state, action.payload, {encodingStatus: EncodingStatus.PENDING});
    }
    if (isType(action, VideoTranscodeReady)) {
        return newStateWithElementChanges(state, action.payload, {encodingStatus: EncodingStatus.ENCODED});
    }
    if (isType(action, VideoTranscodeError)) {
        return newStateWithElementChanges(state, action.payload, {encodingStatus: EncodingStatus.ERROR});
    }

    if (isType(action, FileDimensionsDiscovered)) {
        return action.payload.reduce((preState: FilesState, fileDimensions: FileDimensions) => {
            return newStateWithElementChanges(preState, fileDimensions.fileID, {
                width: fileDimensions.width,
                height: fileDimensions.height,
            });
        }, state);
    }

    if (isType(action, ReactionChangesSubmitted)) {
        return newStateWithElementChanges(state, action.payload.fileID, {
            pendingReaction: action.payload.reaction,
        });
    }

    if (isType(action, ReactionChangesSubmitSuccessfull)) {
        return newStateWithElementChanges(state, action.payload, {
            pendingReaction: undefined,
        });
    }

    if (isType(action, ReactionChangesSubmitError)) {
        return newStateWithElementChanges(state, action.payload, {
            pendingReaction: undefined,
        });
    }

    return state;
}
