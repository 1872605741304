import 'core-js/es6/map';
import 'core-js/es6/promise';
import 'core-js/es6/set';
import 'core-js/fn/array/from';
import 'core-js/fn/object/assign';
import 'isomorphic-fetch';

export const bootstrap = (loadedFunc: () => void) => {
    // To make the scripts and document load before binding
    if (document.readyState !== 'loading') {
        loadedFunc();
    } else if (document.addEventListener) {
        document.addEventListener('DOMContentLoaded', loadedFunc, false);
    } else if (document.attachEvent) {
        document.attachEvent('onDOMContentLoaded', loadedFunc);
    }
};
