import * as React from 'react';

type Props = {size: number};

export const FacebookIconCircular: React.SFC<Props> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="60" height="60" rx="30" fill="#3B5998" fillRule="evenodd"></rect>
            <rect stroke="#FFFFFF" strokeWidth="2" x="1" y="1" width="58" height="58" rx="29"></rect>
            <path d="M30.6525843,43.4308863 L30.6525843,29.0938745 L34.725,29.0938745 L35.0115608,26.3959412 L30.6492196,26.3959412 L30.6615569,22.7962667 C30.6615569,21.1896196 31.0367216,19.6996157 33.7570863,19.6996157 L35.1085765,19.6996157 L35.1085765,17 L32.2704471,17 C28.5025373,17 26.9659882,18.5376706 26.9659882,22.3078235 L26.9659882,26.396502 L24,26.396502 L24,29.0944353 L26.9659882,29.0944353 L26.9659882,43.4308863 L30.6525843,43.4308863 Z" id="facebook" stroke="#FFFFFF" fill="#FFFFFF"></path>
        </g>
    </svg>
);

export const FacebookIconSquare: React.SFC<Props> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="#3C5A99" fillRule="evenodd">
            <path d="M2.64923152,48 C1.18567266,48 0,46.8135915 0,45.3506028 L0,2.64921096 C0,1.1858497 1.18585891,0 2.64923152,0 L45.3509547,0 C46.8139548,0 48,1.1858497 48,2.64921096 L48,45.3506028 C48,46.8137778 46.8137686,48 45.3509547,48 L33.1193053,48 L33.1193053,29.4117547 L39.3585212,29.4117547 L40.2927552,22.1675856 L33.1193053,22.1675856 L33.1193053,17.5426414 C33.1193053,15.4452873 33.7017116,14.0160097 36.7092896,14.0160097 L40.5453113,14.0143334 L40.5453113,7.53509054 C39.8818859,7.44680835 37.6047835,7.24957027 34.955552,7.24957027 C29.4248342,7.24957027 25.6383553,10.6255263 25.6383553,16.8252089 L25.6383553,22.1675856 L19.3831218,22.1675856 L19.3831218,29.4117547 L25.6383553,29.4117547 L25.6383553,48 L2.64923152,48 Z" />
        </g>
    </svg>
);
