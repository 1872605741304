import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {requireAccountInfo} from '../../API/login';
import {_} from '../../assets';
import {colors} from '../../assets/styleConstants';
import {TextButton} from '../Common/IconTextButton';
import {CaptureLogoHorizontal} from '../Icons/CaptureLogo';
import {TwoAreasTopNavBar} from './TwoAreasTopNavBar';

type DispatchProps = {
    doLogin: () => any,
};

type Props = DispatchProps;

class TopNavBar extends React.Component<Props> {

    public render() {
        const loginButton = (
            <TextButton
                onClick={this.props.doLogin}
                text={_('login').toLocaleUpperCase()}
                color={colors.captureBlue}
            />
        );

        return (
            <TwoAreasTopNavBar
                left={<CaptureLogoHorizontal />}
                right={loginButton}
            />
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doLogin: () => requireAccountInfo(dispatch),
});

export const NotLoggedInTopNavBar = connect(null, mapDispatchToProps)(TopNavBar);
