import {LOCATION_CHANGE} from 'react-router-redux';
import {Middleware} from 'redux';
import {FetchedAccountInfo} from '../state/currentUser/actions';
import {trackAction, trackPageChange, trackUserID} from './eventTracking';

export const analyticsMiddleware: Middleware = (store) => {
    return (next) => (action) => {
        switch (action.type) {
            case LOCATION_CHANGE:
                trackPageChange(action.payload.pathname);
                break;
            case FetchedAccountInfo.type:
                trackUserID(action.payload.kissmetrics_id);
                break;
            default:
                trackAction(action, store.getState);
        }
        next(action);
    };
};
