import * as React from 'react';
import styled from 'styled-components';
import {_, colors} from '../../assets';
import {fontSize} from '../../assets/styleConstants';
import {FileAcceptZone, Folder} from './FileAcceptZone';

const Wrapper = styled.div`
    position: fixed;
    z-index: 10;
    top: 0; left: 0; bottom: 0; right: 0;
    background: rgba(${colors.captureBlueRGB}, 0.9);
    border: 4px dashed white;
`;
const ContentWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
`;

const BigText = styled.div`
    margin-bottom: 5px;
    font-size: 88px;
    text-transform: uppercase;
`;

const Text = styled.div`
    margin-bottom: 20px;
    font-size: ${fontSize.large_24};
`;

type Props = {
    fileHandler: (files: Folder) => any;
    text: string,
    illustration: React.ReactNode,
};
type LocalState = {
    isDragTarget: boolean,
};

export class UploadOverlay extends React.Component<Props, LocalState> {

    public render() {

        return (
            <Wrapper>
                <FileAcceptZone fileHandler={this.props.fileHandler}>
                    <ContentWrapper>
                        {this.props.illustration}
                        <BigText>{_('drag_drop')}</BigText>
                        <Text>{this.props.text}</Text>
                    </ContentWrapper>
                </FileAcceptZone>
            </Wrapper>
        );
    }

}
