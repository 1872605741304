export enum FileTarget {Pictures, Documents, Movies}
export const getFileTargetFromName = (fileName: string) => {
    if (fileName.toLowerCase().match(/\.(jpe?g|png|gif|tif?f|webp|heic|heif|avci)$/)) {
        return FileTarget.Pictures;
    }
    if (fileName.toLowerCase().match(/\.(3gp|4gp|asf|asx|avci|avi|dv|flv|m1s|m2ts|m4v|mkv|mov|mp2v|mp4|mpa|mpeg|mpg|mts|ogm|qt|ram|rm|rmvb|webm|wma|vob|wmv|wmx)$/)) {
        return FileTarget.Movies;
    }
    return FileTarget.Documents;
};

export const getFileExtension = (fileName: string) => {
    const match = fileName.match(/\.([^.]+)$/);

    return match ? match[1] : undefined;
};
