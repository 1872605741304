/**
 * Collecting all the auto-creation mick-mack here.
 *
 * Strategy:
 *  - When adding files to be uploaded, they get a "placeholder"-job_uuid
 *  - When starting to upload the files, the album is created and jobId is replaced before uploading
 *    (using a UploadDecorator to handle the inBetweens)
 */

import {FileInformation} from './reducer';
import {UploadDecorator} from './uploadQueue';

const albumInfo: Array<{name: string, tempID: string}> = [];
const constJobIdPromises: DictionaryOf<Promise<JobID>> = {};

type CreateAlbumMethod = (name: string, tempID: string) => Promise<JobID>;
const makeIdProvider = (makeAlbum: CreateAlbumMethod) => (id: number): Promise<JobID> => {
    if (constJobIdPromises[id] === undefined) {
        constJobIdPromises[id] = makeAlbum(albumInfo[id].name, albumInfo[id].tempID);
    }
    return constJobIdPromises[id];
};

// For file-handling side to get a job-id that will turn into an album when files are uploaded
export const getJobIdForNewAlbum = (name: string): string => {
    const tempID = '__pending_album__' + (albumInfo.length);
    albumInfo.push({name, tempID});
    return tempID;
};

// For decorating uploadMethod to insert correct targetJob when uploading to new album
export const uploaderWithAlbumCreationSupport = (makeAlbum: CreateAlbumMethod): UploadDecorator => {
    const getAlbumID = makeIdProvider(makeAlbum);
    return (uploadMethod) => async (f: File, i: FileInformation, r?: XMLHttpRequest) => {
        const match = i.targetJob.match(/^__pending_album__(\d+)$/);
        if (match) {
            i = {...i, targetJob: await getAlbumID(parseInt(match[1], 10))};
        }
        return await uploadMethod(f, i, r);
    };
};
