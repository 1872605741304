import * as React from 'react';
import {getCurrentLocale} from '../../../../config/constants';
import {_} from '../../assets';

export const localizedDateStringShort = (date: Date) => date.toLocaleDateString(getCurrentLocale().replace('_', '-'));
export const localizedDateStringLong = (date: Date): string => date.toLocaleDateString(getCurrentLocale().replace('_', '-'), {day: 'numeric', month: 'long', year: 'numeric'});
export const localizedTimeString = (date: Date): string => date.toLocaleTimeString(getCurrentLocale().replace('_', '-'), {weekday: 'long'});

export const getFilesDateRangeString = (start: Date, end: Date, now?: Date): string => {
    const locale = getCurrentLocale().replace('_', '-');

    // hide year if date range is within the current year
    const hideCurrentYear = end.getFullYear() === (now || new Date()).getFullYear();

    let firstHalfOptions;
    let secondHalfOptions;
    if (start.getFullYear() !== end.getFullYear()) {
        firstHalfOptions = {month: 'short', day: 'numeric', year: 'numeric'};
        secondHalfOptions = {month: 'short', day: 'numeric', year: 'numeric'};
    }
    else if (start.getMonth() !== end.getMonth() || start.getDate() !== end.getDate()) { // same year, different months/days
        firstHalfOptions = {month: 'short', day: 'numeric'};
        secondHalfOptions = {month: 'short', day: 'numeric', year: hideCurrentYear ? undefined : 'numeric'};
    }
    else { // same day
        return start.toLocaleDateString(
            locale,
            {month: 'short', day: 'numeric', year: hideCurrentYear ? undefined : 'numeric'},
        );
    }

    const stringElements = [
        start.toLocaleDateString(locale, firstHalfOptions),
        end.toLocaleDateString(locale, secondHalfOptions),
    ];

    return stringElements.join(' - ');
};

type SinceType = {
    interval: number;
    singular: string;
    plural: string;
};

const timeSinceTypes: DictionaryOf<SinceType> = {
    year: {
        interval: 60 * 60 * 24 * 365,
        singular: _('year_ago__format'),
        plural: _('years_ago__format'),
    },
    month: {
        interval: 60 * 60 * 24 * 30,
        singular: _('month_ago__format'),
        plural: _('months_ago__format'),
    },
    day: {
        interval: 60 * 60 * 24,
        singular: _('day_ago__format'),
        plural: _('days_ago__format'),
    },
    hour: {
        interval: 60 * 60,
        singular: _('hour_ago__format'),
        plural: _('hours_ago__format'),
    },
    minute: {
        interval: 60,
        singular: _('minute_ago__format'),
        plural: _('minutes_ago__format'),
    },
};

const timeSince = (then: Date, now: Date) => {
    const seconds = Math.floor((now.getTime() - then.getTime()) / 1000);
    const timeSinceChecks = Object.keys(timeSinceTypes).map((k) => timeSinceTypes[k]).sort((t1, t2) => {
        return t2.interval - t1.interval;
    });

    let timeSinceResult: string = _('just_now');
    for (const timeSince of timeSinceChecks){
        const diff = Math.floor(seconds / timeSince.interval);

        if (diff > 0){
            const unit = diff > 1 ? timeSince.plural : timeSince.singular;
            timeSinceResult = unit.split('%d').join('' + diff);
            break;
        }
    }
    return timeSinceResult;
};

export const TimeSinceString = (props: {then: Date, now: Date}) => <span>{timeSince(props.then, props.now)}</span>;
