import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {IconButton, TextButton} from '../Common/IconTextButton';
import {BackArrowIcon} from '../Icons/BackArrowIcon';
import {CheckIcon} from '../Icons/CheckIcon';
import {ThreeAreasTopNavBar} from '../Navigation/ThreeAreasTopNavBar';
import {TwoAreasTopNavBar} from '../Navigation/TwoAreasTopNavBar';

const IconWrapper = styled.div`
    height: 24px;
`;
const TextButtonWrapper = styled.div`
    text-transform: uppercase;
    font-weight: bold;
`;
const PageTitle = styled.div`
    margin-left: 24px;
    font-size: ${fontSize.medium_16};
`;
const Count = styled.span`
    color: ${colors.captureBlue};
    margin-right: 16px;
    font-size: ${fontSize.small_14};
`;

type Props = {
    nextButtonLabel?: string,
    headerTitle?: string,
    onNextBtnClick: () => any;
    selectedCount: number;
    selectionMax?: number;
    isMobileMode: boolean;
};

export class PhotoSelectionNavbar extends React.Component<Props> {

    private goBack = () => {
        history.back();
    }

    public render() {
        const {selectedCount, selectionMax} = this.props;

        const leftElement = (
            <IconWrapper>
                <IconButton onClick={this.goBack} icon={BackArrowIcon} />
            </IconWrapper>
        );

        const isNextButtonActive = selectedCount > 0 && selectedCount <= (selectionMax || Infinity);
        const nextButtonProps = {
            onClick: isNextButtonActive ? this.props.onNextBtnClick : undefined,
            text: this.props.nextButtonLabel || _('next'),
            icon: CheckIcon,
            color: isNextButtonActive ? colors.captureBlue : colors.captureGrey400,
        };

        const selectedStr =  _('selected__format').replace(
            '%d',
            selectionMax ? `${selectedCount}/${selectionMax}` : `${selectedCount}`,
        );

        if (this.props.isMobileMode) {
            const rightElements = (
                <>
                    <Count>{selectedStr}</Count>
                    <IconButton
                        data-cy="photoSelectionNext"
                        {...nextButtonProps}
                    />
                </>
            );

            return (
                <TwoAreasTopNavBar
                    left={leftElement}
                    right={rightElements}
                    isMobile={true}
                />
            );
        } else {
            const middleElement = this.props.headerTitle && (<PageTitle>{this.props.headerTitle}</PageTitle>);
            const rightElements = (
                <>
                    <Count>{selectedStr}</Count>
                    <TextButtonWrapper>
                        <TextButton
                            data-cy="photoSelectionNext"
                            {...nextButtonProps}
                        />
                    </TextButtonWrapper>
                </>
            );
            return (
                <ThreeAreasTopNavBar
                    left={leftElement}
                    middle={middleElement}
                    right={rightElements}
                />
            )
        }
    }
}