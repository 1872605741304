import {createActionCreator} from '../common/actions';
import {HostDirectory} from '../hosts/actions';
import {JobInfo} from './reducer';

export const StartedFetchingJobInfo = createActionCreator<JobID>('STARTED_FETCHING_JOB_INFO');
export const FetchedJobHosts = createActionCreator<{job: JobID, hosts: HostDirectory}>('FETCHED_JOB_HOSTS');
export const FetchedJobInfo = createActionCreator<{job: JobID, info: JobInfo}>('FETCHED_JOB_INFO');

export const JobNotFound = createActionCreator<JobID>('JOB_NOT_FOUND');
export const JobRequiresPassword = createActionCreator<JobID>('JOB_REQUIRES_PASSWORD');
export const ErrorWhenFetchingJobInfo = createActionCreator<JobID>('ERROR_WHEN_FETCHING_JOB_INFO'); //  Network error, other unknown errors

export const JobDeletionStarted = createActionCreator<JobID>('JOB_DELETION_STARTED');
export const JobWasDeleted = createActionCreator<JobID>('JOB_WAS_DELETED');
export const JobDeletionFailed = createActionCreator<JobID>('JOB_DELETION_FAILED');

export const JobPasswordProvided = createActionCreator<{job: JobID, password: string}>('JOB_PASSWORD_PROVIDED');

export const JobWasCreated = createActionCreator<JobID>('JOB_WAS_CREATED');
export const JobWasConfirmed = createActionCreator<JobID>('JOB_WAS_CONFIRMED');

type JobInfoKey = keyof JobInfo;
type _ChangeJobPropertyPayload<T extends JobInfoKey> = {
    job: JobID,
    property: T, // only properties are changeable allowed by backend
    value: JobInfo[T],
};
type ChangeJobPropertyPayload = _ChangeJobPropertyPayload<JobInfoKey>;

export const ChangeJobPropertySubmitted = createActionCreator<ChangeJobPropertyPayload>('CHANGE_JOB_PROPERTY_SUBMITTED');
export const ChangeJobPropertySucceeded = createActionCreator<ChangeJobPropertyPayload>('CHANGE_JOB_PROPERTY_SUCCEEDED');
export const ChangeJobPropertyFailed = createActionCreator<{job: JobID, property: JobInfoKey}>('CHANGE_JOB_PROPERTY_FAILED');
