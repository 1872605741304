import {RoutePath} from '../../routing/routePath';
import {Action, State} from '../../state';
import {ClickedAlbumImage} from '../../state/album/actions';
import {EnteredCarouselView} from '../../state/carouselViewer/actions';
import {ActionType} from '../../state/common/actions';
import * as CurrentUserActions from '../../state/currentUser/actions';
import * as FilesActions from '../../state/files/actions';
import * as ReactionActions from '../../state/reaction/actions';
import {ActionsForAnalytics as UploaderActions} from '../../state/uploader/actions';
import {getAnalyticsSafeURL} from '../../utilities/urlParsing';
import {EventTracker} from '../eventTracking';
import {GATracker} from './GATracker';

export class GoogleAnalyticsTracker implements EventTracker {
    private tracker: GATracker;
    constructor(googleAnalyticsID: string) {
        this.tracker = new GATracker(googleAnalyticsID);

        this._initActionTracking();
    }

    private actionHandlers: Record<ActionType, (a: Action<any>) => void> = {};
    private _initActionTracking() {
        // Uploader-related actions to track directly
        UploaderActions.forEach(
            (action) => this.actionHandlers[action.type] = () => this.trackEvent('Uploader', action.type),
        );

        // Activation: Name-prompt
        [   CurrentUserActions.ValidUserInfoRequired,
            CurrentUserActions.ValidUserInfoRequestDeniedByUser,
            CurrentUserActions.AnonymousUserNameProvided,
        ].forEach((action) => this.actionHandlers[action.type] = () => this.trackEvent('NamePrompt', action.type));

        // Activation: Commenting
        [   FilesActions.FileCommentSubmitted,
            FilesActions.FileCommentSubmitSuccessful,
            FilesActions.FileCommentError,
        ].forEach((action) => this.actionHandlers[action.type] = () => this.trackEvent('Commenting', action.type));

        // Reactions (love)
        [   ReactionActions.ReactionAdded,
            ReactionActions.ReactionDeleted,
        ].forEach((action) => this.actionHandlers[action.type] = () => this.trackEvent('Reactions', action.type));

        this.actionHandlers[ClickedAlbumImage.type] = () => this.trackEvent('AlbumPage', ClickedAlbumImage.type);
        this.actionHandlers[EnteredCarouselView.type] = () => this.trackEvent('AlbumPage', EnteredCarouselView.type);
    }

    protected setOnTracker(key: string, value: string) {
        this.tracker.set(key, value);
    }

    public trackEvent(context: string, event: string, label?: string, value?: number) {
        this.tracker.sendEvent(context, event, label, value);
    }

    public trackPageChange(newPath: string) {
        // URLs to a specific album/share are considered private information and must be obfuscated

        this.tracker.set('page', getAnalyticsSafeURL(newPath));
        this.tracker.sendPageview();
    }

    public trackUserID(userId: string) {
        this.tracker.set('userId', userId);
    }

    public trackAction(action: Action<any>, _getState: () => State) {
        if (this.actionHandlers[action.type]) {
            this.actionHandlers[action.type](action);
        }
    }

}
