import * as React from 'react';
import styled from 'styled-components';
import {_, colors} from '../../assets';
import {fontSize} from '../../assets/styleConstants';
import {FileInformation, FileUploadStatus, RejectReason} from '../../state/uploader/reducer';
import {getConnectedInstance} from '../../state/uploader/uploadQueue';
import {isSafariBrowser} from '../../utilities/device';
import {CloseIcon} from '../Icons/CloseIcon';
import {WarningIcon} from '../Icons/WarningIcon';
import {AsyncPreviewThumb} from './AsyncPreviewThumb';
import {UploadStatusBar} from './UploadStatusBar';

const ListEntryContainer = styled.div`
    width: 100%;
    padding: 4px 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: default;

    &:hover {
        background-color: ${colors.captureBlue50};
    }
`;

const ThumbnailContainer = styled.div`
    position: relative;
    width: 40px;
    height: 40px;
`;
const GrayThumbOverlay = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(${colors.captureGrey800rgb},0.6);
`;

const FileInfoContainer = styled.div`
    flex: 1;
    font-size: ${fontSize.medium_16};
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
`;

const FileName = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    white-space: nowrap;
`;

const FileStatus = styled.div`
    font-size: ${fontSize.small_12};
    font-style: italic;
`;

const SmallIconContainer = styled.div`
    width: 24px;
    height: 24px;
    text-align: right;
`;

const StatusBar = styled.div`
    height: 4px;
    background-color: ${colors.captureGrey300};
    margin-top: 8px;
`;
const IconWrapper = styled.div`
    cursor: pointer;
`;
const EntryWithWarning = styled.div`
    background-color: #FAE5E8;
`;
const EntryWithGreyText = styled.div`
    color: ${colors.captureGrey400};
`;

const rejectReasonMessages = {
    [RejectReason.FileIsFolder] : isSafariBrowser() ? _('upload_folder_safari_reject') : _('upload_folder_reject'),
    [RejectReason.UnSupported] : _('unsupported_file_reject'),
    [RejectReason.NoStorage] : _('out_of_storage'),
};

type ListEntryProps = {
    file: FileInformation,
    status: React.ReactNode,
    icon?: React.ReactNode,
    greyOutThumb?: boolean,
};

const ListEntry: React.SFC<ListEntryProps> = (props) => {
    return (
        <ListEntryContainer key={props.file.id}>
            <ThumbnailContainer>
                <AsyncPreviewThumb fileName={props.file.name} uploadId={props.file.id} />
                {props.greyOutThumb && <GrayThumbOverlay />}
            </ThumbnailContainer>
            <FileInfoContainer>
                <FileName>{props.file.name}</FileName>
                <FileStatus>{props.status}</FileStatus>
            </FileInfoContainer>
            <SmallIconContainer>{props.icon}</SmallIconContainer>
        </ListEntryContainer>
    );
};

type Props = {
    isOffline: boolean,
    file: FileInformation,
};
export class UploadStatusBoxListEntry extends React.Component<Props> {
    private removeFile = () => {
        getConnectedInstance().removeFile(this.props.file.id);
    }

    public render() {

        const cancelIcon = (
            <IconWrapper onClick={this.removeFile}>
                <CloseIcon size={24}/>
            </IconWrapper>
        );
        switch (this.props.file.status) {
            case FileUploadStatus.Uploading:
                const uploadingStatus = (
                    <StatusBar>
                        <UploadStatusBar
                            progress={this.props.file.uploadedPercent}
                            runAnimation={true}
                        />
                    </StatusBar>
                );
                return (
                    <ListEntry
                        file={this.props.file}
                        status={uploadingStatus}
                        icon={cancelIcon}
                    />
                );
            case FileUploadStatus.Succeeded:
                return (
                    <ListEntry
                        file={this.props.file}
                        status={_('complete')}
                    />
                );
            case FileUploadStatus.Rejected:
                return (
                    <EntryWithWarning>
                        <ListEntry
                            file={this.props.file}
                            status={this.props.file.statusReason !== undefined && rejectReasonMessages[this.props.file.statusReason]}
                            icon={<WarningIcon size={24}/>}
                        />
                    </EntryWithWarning>
                );
            case FileUploadStatus.Cancelled:
                return (
                    <EntryWithGreyText>
                        <ListEntry
                            file={this.props.file}
                            status={_('cancelled')}
                            greyOutThumb={true}
                        />
                    </EntryWithGreyText>
                );
            default:
                let preparingStatus = _('preparing');
                if (this.props.isOffline) {
                    preparingStatus = _('waiting_for_internet_connection');
                }
                return (
                    <ListEntry
                        file={this.props.file}
                        status={preparingStatus}
                        icon={cancelIcon}
                    />
                );
        }
    }
}
