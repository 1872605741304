import * as React from 'react';
import {colors} from '../../assets';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const WarningIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path fill={props.color || colors.captureGrey800} d="M3,19.5454545 L21,19.5454545 L12,4 L3,19.5454545 L3,19.5454545 Z M12.8181818,17.0909091 L11.1818182,17.0909091 L11.1818182,15.4545455 L12.8181818,15.4545455 L12.8181818,17.0909091 L12.8181818,17.0909091 Z M12.8181818,13.8181818 L11.1818182,13.8181818 L11.1818182,10.5454545 L12.8181818,10.5454545 L12.8181818,13.8181818 L12.8181818,13.8181818 Z" />
        </g>
    </svg>
);
