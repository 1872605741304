import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {AlbumFileComment} from '../../state/album/selectors';
import {ButtonProps} from '../Common/Button';
import {OverflowMenu, OverflowMenuExpandingUpwards} from '../Common/OverflowMenu';
import {AlbumFileLoveInfo} from '../Reaction/AlbumFileLoveInfo';
import {CommentInputComponent} from './CommentInputComponent';
import {CommentsListComponent} from './CommentsListComponent';
import {DisplayCommentOverlayTrigger} from './DisplayCommentOverlayTrigger';

type Props = {
    maxInitiallyVisibleComments: number,
    comments: AlbumFileComment[],
    fileID: FileID,
    albumID: JobID,
    mobileMode: boolean,
    commentsCanBeAdded: boolean,
    lovesCanBeAdded: boolean,
    lovedByCurrentUser: boolean,
    totalLoveCount: number,
    onLoveChanged: (ifLove: boolean) => any;
    doShowLoveList: () => any,
    isPendingNewComment: boolean,
    elementHeight: number,
    overflowMenuOptions: ButtonProps[],
};

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: ${(props: {mobileMode: boolean}) => props.mobileMode ? 'visible' : 'hidden'};
    display: flex;
    flex-direction: column;
    position: relative;
`;
const TopElement = styled.div`
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    font-size: ${fontSize.small_14};
    padding: 16px 16px 8px;
    border-bottom: ${(props: {shouldHaveBorder: boolean}) =>
        props.shouldHaveBorder
        ? '1px solid rgba(' + `${colors.captureGrey800rgb}` + ', 0.2)'
        : ''
    };
`;

const CommentListWrapper = styled.div`
    flex: auto;
    -ms-flex-positive: 1;
    overflow-y: ${(props: {mobileMode: boolean, isListScrollable: boolean}) => props.mobileMode ? 'initial' : 'auto'};
    webkit-overflow-scrolling: touch;
    padding: 0 16px;
    position: ${(props) => props.mobileMode ? 'auto' : 'relative'};
`;

const ViewMoreWrapper = styled.div`
    padding: 0 16px;
    margin-bottom: 16px;
    font-size: ${fontSize.small_14};
`;

const OverflowWrapper = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
`;

export class CommentsSection extends React.Component<Props> {
    private list = React.createRef<HTMLDivElement>();
    private doScrollToBottomOnUpdate = false;
    private isListScrollable = false;

    public componentDidMount() {
        if (this.list.current) {
            this.list.current.scrollTop = this.list.current.scrollHeight;
            this.isListScrollable = (this.list.current.scrollHeight > this.list.current.clientHeight);
        }
    }

    public componentWillReceiveProps(newProps: Props) {
        if (this.list.current && newProps.comments.length > this.props.comments.length) {
            const didCurrentUserComment: boolean
                = newProps.comments[newProps.comments.length - 1].currentUserCan.editComment;
            const amAtBottom: boolean
                = this.list.current.scrollTop === this.list.current.scrollHeight - this.list.current.clientHeight;
            if (didCurrentUserComment || amAtBottom) {
                this.doScrollToBottomOnUpdate = true;
            }
        }
    }

    public componentDidUpdate() {
        if (this.list.current && this.doScrollToBottomOnUpdate) {
            this.list.current.scrollTop = this.list.current.scrollHeight;
            this.doScrollToBottomOnUpdate = false;
        }
    }

    public render() {

        const totalNumberOfComments = this.props.comments.length;
        const numberOfCommentsShown = this.props.maxInitiallyVisibleComments > 0 ?
            this.props.maxInitiallyVisibleComments : totalNumberOfComments;

        let showViewMoreBtn: React.ReactNode = null;

        if (this.props.maxInitiallyVisibleComments && numberOfCommentsShown < totalNumberOfComments) {
            showViewMoreBtn = (
                <ViewMoreWrapper>
                    <DisplayCommentOverlayTrigger
                        fileID={this.props.fileID}
                        albumID={this.props.albumID}
                    >
                        {_('view_all_comments') + ' (' + totalNumberOfComments + ')'}
                    </DisplayCommentOverlayTrigger>
                </ViewMoreWrapper>
            );
        }

        const loveElement = this.props.lovesCanBeAdded && (
            <AlbumFileLoveInfo
                loved={this.props.lovedByCurrentUser}
                totalLoveCounts={this.props.totalLoveCount}
                onLoveChanged={this.props.onLoveChanged}
                doShowLoveList={this.props.doShowLoveList}
            />
        );

        const Overflow = this.props.mobileMode ? OverflowMenuExpandingUpwards : OverflowMenu;
        const menu = this.props.overflowMenuOptions.length > 0 && (
            <OverflowWrapper>
                <Overflow menuOptions={this.props.overflowMenuOptions} iconColor={colors.captureGrey500}/>
            </OverflowWrapper>
        );

        const heightLimitInputField = this.props.mobileMode ? 216 : 384; // good limit number for visual
        const imageHeight = Math.max(this.props.elementHeight, 250); // can not be smaller than min-height of container
        const commentHeaderGap = 63;
        const commentInput = this.props.commentsCanBeAdded && (
            <CommentInputComponent
                fileID={this.props.fileID}
                albumID={this.props.albumID}
                maxHeight={Math.min(heightLimitInputField, (imageHeight - commentHeaderGap))}
            />
        );

        return (
            <Wrapper mobileMode={this.props.mobileMode}>
                <TopElement shouldHaveBorder={this.isListScrollable}>
                    {loveElement}
                </TopElement>
                <CommentListWrapper
                    mobileMode={this.props.mobileMode}
                    isListScrollable={this.isListScrollable}
                    innerRef={this.list}
                >
                    <CommentsListComponent
                        showNumberOfComments={numberOfCommentsShown}
                        comments={this.props.comments}
                        isPendingNewComment={this.props.isPendingNewComment}
                    />
                </CommentListWrapper>
                {showViewMoreBtn}
                {commentInput}
                {menu}
            </Wrapper>
        );
    }
}
