export class BasePath {
    constructor(public rootPath: string) {}

    public subPath(...subs: string[]): string {
        return [this.rootPath].concat(subs).join('/');
    }
}

const NotLoggedInAlbumPath = new BasePath('a');
const LoggedInAlbumPath = new BasePath('albums');
const SharePath = new BasePath('share');
const CarouselPath = new BasePath('carousel');
const TimelinePath = new BasePath('photos');

export const RoutePath = {
    Timeline: 'photos',
    Albums: 'albums',
    Trash: 'deleted_items',
    Documents: 'files',
    Settings: 'settings',
    Takeout: 'takeout',
    TakeoutFAQ: 'takeout/faq',
    ShutdownUser: 'shutdown-user',
    ShutdownInfo: 'shutdown_info', /* Placeholder for something that will probably come. Used from mobile at least */
    Help: 'help',
    CreatePrivateAlbum: 'createPrivate',
    CreateSharedAlbum: 'createShared',
    SelectPhotosToExternal: 'selectPhotos',
    getNotLoggedInAlbumPath: (aIDParam: string) => NotLoggedInAlbumPath.subPath(aIDParam),
    getLoggedInAlbumPath: (aIDParam: string) => LoggedInAlbumPath.subPath(aIDParam),
    getAlbumUnpackedUUIDPath: (aUUIDParam: string) => (new BasePath('album')).subPath(aUUIDParam),
    getEditAlbumPath: (aIDParam: string, goToAfter: string) => LoggedInAlbumPath.subPath(aIDParam, 'edit', goToAfter),
    getCreateAlbumLoginPath: (aIDParam: string) => NotLoggedInAlbumPath.subPath(aIDParam, 'createNew'),
    getCopyAlbumLoginPath: (aIDParam: string, fIDParam?: string) => NotLoggedInAlbumPath.subPath(aIDParam, fIDParam || '', 'copyAlbum'),
    getAlbumFileCommentsPath: (aIDParam: string, fIDParam: string) =>
        (new BasePath('comments')).subPath(aIDParam, fIDParam),
    morePhotosAlbum: (aIDParam: string) => LoggedInAlbumPath.subPath(aIDParam, 'addPhotos'),
    getShareReceiverPath: (sIDParam: string) => SharePath.subPath(sIDParam),
    getCopyShareLoginPath: (sIDParam: string) => SharePath.subPath(sIDParam, 'copyShare'),
    getAlbumCarouselPath: (aIDParam: string, fIDParam: string) => CarouselPath.subPath(aIDParam, fIDParam),
    getTimelineCarouselPath: (sourceGroup: string, fIDParam: string) => TimelinePath.subPath(sourceGroup, fIDParam),
};
