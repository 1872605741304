import {State} from '../store';

export type TakeoutStatus = 'notStarted'|'baking'|'ready';
export const getTakeoutStatus = (state: State): TakeoutStatus|undefined => state.takeout.status;

export const getNotificationEmail = (state: State): string|undefined => state.takeout.contactEmail;

export type TakeoutFile = {
    // TODO: add necessary props for takeout archive
    name: string,
    url: string,
    size: number,
    checksum: string,
};
export const getFileList = (state: State): TakeoutFile[] => {
    return state.takeout.files || [];
};
export const getTimestampForWhenDownloadLinksExpires = (state: State): number|undefined => state.takeout.filesExpires;
export const getTimestampForWhenTakeoutCanRestart = (state: State): number|undefined => state.takeout.rebakeAllowedAt;
