import {enc} from 'crypto-js';

// Method for packing Hex-uuid as Base64-string (used when passing UUIDs to mobile)
export const uuidToB64 = (input: string) => {
    const output = enc.Base64.stringify(enc.Hex.parse(input.replace(/-/g, '')));
    return output.replace(/\+/g, '-').replace(/\//g, '_').replace(/==$/, '');
};

// Method for unpacking Hex-uuid stored as Base64-string
export const b64ToUuid = (input: string) => {
    // Input was url-friendly:
    input = input.replace(/-/g, '+').replace(/_/g, '/');
    const charString = enc.Hex.stringify(enc.Base64.parse(input));

    // Format the string as UUID
    return charString.substr(0, 8) + '-' + charString.substr(8, 4) + '-' + charString.substr(12, 4) + '-' + charString.substr(16, 4) + '-' + charString.substr(20);
};
