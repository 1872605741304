import * as React from 'react';
import styled from 'styled-components';
import {colors} from '../../assets/styleConstants';
import {enterFullscreen, exitFullscreen} from '../../utilities/fullscreen';
import {NextCircleButton} from '../Common/NextButton';
import {OverflowMenu} from '../Common/OverflowMenu';
import {PrevCircleButton} from '../Common/PreviousButton';
import {BackArrowIcon} from '../Icons/BackArrowIcon';
import {ShareIcon} from '../Icons/ShareIcon';
import {InnerComponentProps} from './CarouselView';
import {CarouselViewSlideBar} from './CarouselViewSlideBar';
import {MediaElementComponent} from './MediaElement';

const FullscreenWrapper = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &:hover .nav-button,  &:hover .file-user-actions{
        visibility: visible;
    }
`;

const AlbumContentWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;

    &:fullscreen {
        background-color: ${colors.darkBackground};
        & .nav-button{
            visibility: hidden;
        }
    }
    &:-webkit-full-screen {
        background-color: ${colors.darkBackground};
        & .nav-button{
            visibility: hidden;
        }
    }
    &:-moz-full-screen .nav-button{
        visibility: hidden;
    }
    &:-ms-fullscreen .nav-button{
        visibility: hidden !important;
    }

    .slideshow-controls {
        opacity: ${(props: {showControls: boolean}) => props.showControls ? 1 : 0};
        transition: opacity 0.6s;

        &:hover {
            opacity: 1;
        }
    }
`;

type MediaElementProps = {
    translate: number,
    shouldAnimate: boolean,
    // hide play in fullscreen button for video when in slideshow fullscreen
    // Chrome bug when parent div is already in fullscreen https://bugs.chromium.org/p/chromium/issues/detail?id=583224
    isInsideFullscreenContainer: boolean,
};
const MediaElementWrapper = styled.div`
    transform: translate3d(${(props: MediaElementProps) => props.translate}px, 0, 0);
    transform-origin: left;
    transition: ${(props) => props.shouldAnimate ? 'transform 0.3s' : ''};

    ${(props) => props.isInsideFullscreenContainer ? `
        video::-webkit-media-controls-fullscreen-button {
            display: none;
        }
    ` : ''}
`;

const UserActionsWrapper = styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    height: 80px;
    visibility: hidden;
    background: linear-gradient(rgba(0,0,0,0.4) 5%, rgba(0,0,0,0) 60%);
    padding: 8px 12px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
`;
const IconsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;

    & > div {
        margin-right: 16px;
        height: 24px;
        cursor: pointer;

        &:last-child {
            margin: 0;
        }
    }
`;

const CloseBtnDiv = styled.div`
    cursor: pointer;
`;

const NavBtnHolder = styled.div`
    position: absolute;
    top: 50%;

    visibility: hidden;
`;

const NextNavBtn = styled(NavBtnHolder)`
    right: 10%;
    transform: translate(50%, -50%);
`;

const PrevNavBtn = styled(NavBtnHolder)`
    left: 10%;
    transform: translate(-50%, -50%);
`;

const NavBtnDiv = styled.div`
    circle{
        opacity: 0.5;
    }
    &:hover circle{
        opacity: 0.8;
    }
    cursor:pointer;
`;

const SlideBarWrapper = styled.div`
    width: 100%;
    padding-bottom: 16px;
    box-sizing: border-box;
`;

type ComponentState = {
    shouldAnimate: boolean,
    showSlideShowControls: boolean,
};

export class CarouselViewDesktop extends React.Component<InnerComponentProps, ComponentState> {
    public state: ComponentState = {
        shouldAnimate: false,
        showSlideShowControls: true,
    };
    private fullscreenElement = React.createRef<HTMLDivElement>();

    private getElementTranslateValue = (): number => {
        let unavailableWidth = 0;
        if (this.props.isSideModuleExpanded && this.props.slideShowControls === null) {
           unavailableWidth = 360; // Compensate for SideModule on right side.
        }
        return (window.innerWidth - unavailableWidth - this.props.viewerNodeSize.width) / 2;
    }

    private keypressListener = (event: KeyboardEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        switch (event.keyCode) {
            case 27: // Escape
                // disable esc key event on IE due to issues connected with Fullscreen Mode
                if (document.msFullscreenEnabled) {
                    return;
                }
                this.onCloseClick();
                break;
            case 37: // ArrowLeft
                this.props.navToPrevFile();
                break;
            case 39: // ArrowRight
                this.props.navToNextFile();
                break;
            default:
                return;
        }

        event.preventDefault();
    }
    private onCloseClick = () => {
        history.back();
    }

    public componentDidMount() {
        window.addEventListener('keyup', this.keypressListener);

        if (this.fullscreenElement.current) {
            this.fullscreenElement.current.addEventListener('mousemove', this.displaySlideShowControls);
            if (this.props.slideShowControls !== null) {
                enterFullscreen(this.fullscreenElement.current);
            } else {
                exitFullscreen(this.fullscreenElement.current);
            }
        }
    }
    public componentWillReceiveProps(newProps: InnerComponentProps) {
        this.setState((prevState) => ({
            ...prevState,
            shouldAnimate: newProps.isSideModuleExpanded !== this.props.isSideModuleExpanded,
        }));

        if (this.fullscreenElement.current) {
            if (newProps.slideShowControls !== null) {
                enterFullscreen(this.fullscreenElement.current);
            } else {
                exitFullscreen(this.fullscreenElement.current);
            }
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('keyup', this.keypressListener);
        if (this.fullscreenElement.current) {
            this.fullscreenElement.current.removeEventListener('mousemove', this.displaySlideShowControls);
        }
    }
    private timer: any;
    private displaySlideShowControls = () => {
        if (this.props.slideShowControls) {
            if (this.state.showSlideShowControls === false) {
                this.setState({...this.state, showSlideShowControls: true});
            }
            else {
                clearTimeout(this.timer);
                this.timer = setTimeout(
                    () => this.setState({...this.state, showSlideShowControls: false}),
                    2000,
                );
            }
        }
    }

    public render() {
        const node = this.props.currentViewerNode;

        let prevNavBtn = null;
        if (node.prev !== null) {
            prevNavBtn = (
                <PrevNavBtn className="nav-button">
                    <NavBtnDiv onClick={this.props.navToPrevFile}>
                        <PrevCircleButton size={68}/>
                    </NavBtnDiv>
                </PrevNavBtn>
            );
        }

        let nextNavBtn = null;
        if (node.next !== null) {
            nextNavBtn = (
                <NextNavBtn className="nav-button">
                    <NavBtnDiv onClick={this.props.navToNextFile}>
                        <NextCircleButton size={68}/>
                    </NavBtnDiv>
                </NextNavBtn>
            );
        }

        const overflowMenu = this.props.overflowMenuItems.length > 0 && (
            <OverflowMenu
                menuOptions={this.props.overflowMenuItems}
                iconColor={'white'}
                shadowColor={'black'}
            />
        );

        return (
            <FullscreenWrapper>
                <AlbumContentWrapper
                    innerRef={this.fullscreenElement}
                    showControls={this.state.showSlideShowControls}
                >
                    <MediaElementWrapper
                        translate={this.getElementTranslateValue()}
                        shouldAnimate={this.state.shouldAnimate}
                        isInsideFullscreenContainer={this.props.slideShowControls !== null}
                    >
                        <MediaElementComponent
                            currentFile={this.props.currentViewerNode.file}
                            viewerNodeSize={this.props.viewerNodeSize}
                            onVideoPlayStatusChanged={this.props.onVideoPlayStatusChanged}
                            onImageDimensionsDetected={this.props.onImageDimensionsDetected}
                        />
                    </MediaElementWrapper>
                    {prevNavBtn}
                    {nextNavBtn}
                    {this.props.slideShowControls}
                </AlbumContentWrapper>
                <UserActionsWrapper className="file-user-actions">
                    <CloseBtnDiv onClick={this.onCloseClick}>
                        <BackArrowIcon size={24} color={'white'}/>
                    </CloseBtnDiv>
                    <IconsWrapper>
                        {this.props.loveInfoElement}
                        {this.props.commentsElement}
                        {this.props.shareElement}
                        {this.props.infoElement}
                        {overflowMenu}
                    </IconsWrapper>
                </UserActionsWrapper>
                <SlideBarWrapper>
                    <CarouselViewSlideBar
                        thumbFiles={this.props.thumbFiles}
                        currentThumbIndex={this.props.currentThumbIndex}
                        thumbStyle={{size: 40, spaceBetween: 24}}
                        navBtnStyle={{width: 20, height: 38}}
                        onClickThumbImage={this.props.onClickThumbImage}
                        showNavArrows={false}
                    />
                </SlideBarWrapper>
            </FullscreenWrapper>
        );
    }
}
