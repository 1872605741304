import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {deleteComment, editComment} from '../../API/album';
import {_} from '../../assets/localizedStrings';
import {AlbumFileComment} from '../../state/album/selectors';
import {
    DeleteCommentCanceled, DeleteCommentClicked, EditCommentCanceled,
    EditCommentClicked,
} from '../../state/files/actions';
import {State} from '../../state/store';
import {isMobileMode} from '../../state/viewMode/selectors';
import {Button, ButtonProps} from '../Common/Button';
import {ConfirmPromptComponent} from '../Common/DialoguePromptComponent';
import {RippleLoaderOverlay} from '../Common/RippleLoaderOverlay';
import {EditCommentComponent} from './EditCommentComponent';
import {SingleComment} from './SingleComment';

const CommentWrapper = styled.div`
    position: ${(props: {shouldBeRelative: boolean}) => props.shouldBeRelative ? 'relative' : 'auto'};
`;

const Wrapper = styled.div`
    width: 90%;
    max-width: 100%;
    position: absolute;
    ${(props: {isCloseToBottom: boolean}) => props.isCloseToBottom ? 'bottom: 8px' : 'top: 100%'};
    left: 50%;
    transform: translateY(-12px) translateX(-50%);
    z-index: 10;
`;

type OwnProps = AlbumFileComment & {
    now: Date,
};

const getActionsForComment = (dispatch: Dispatch, ownProps: OwnProps): ButtonProps[] => {
    const actions = [];
    if (ownProps.currentUserCan.editComment) {
        actions.push(Button(_('edit'), () => dispatch(EditCommentClicked({commentID: ownProps.commentID}))));
    }
    if (ownProps.currentUserCan.deleteComment) {
        actions.push(Button(_('delete_'), () => dispatch(DeleteCommentClicked({commentID: ownProps.commentID}))));
    }
    return actions;
};

type StateProps = {
    isMobileMode: boolean,
};
type DispatchProps = {
    commentActions: ButtonProps[],
    deleteComment: () => any,
    cancelDeleteComment: () => any,
    cancelEditComment: () => any,
    submitEditedComment: (text: string) => void,
};

type Props = OwnProps & StateProps & DispatchProps;

type ComponentState = { promptIsCloseToBottom: boolean};

class _SingleCommentContainer extends React.Component<Props, ComponentState> {
    private commentWrapper = React.createRef<HTMLDivElement>();

    public state: ComponentState = {promptIsCloseToBottom: false};

    public componentWillReceiveProps(newProps: Props) {
        if (this.props.awaitsDecisionToBeDeleted !== newProps.awaitsDecisionToBeDeleted) {
            this.checkIfCommentisCloseToBottom();
        }
    }

    private checkIfCommentisCloseToBottom = () => {
        if (!this.commentWrapper.current) {
            return;
        }

        const totalDistance = window.innerHeight - this.commentWrapper.current.getBoundingClientRect().bottom;
        if (this.props.isMobileMode) {
            this.setState({promptIsCloseToBottom: totalDistance < 200});
        }
        else {
            const distanceToTopOfList: number
                = this.commentWrapper.current.offsetTop
                    + this.commentWrapper.current.offsetHeight
                    - this.commentWrapper.current.offsetParent.scrollTop;
            const distanceToBottomOfList = this.commentWrapper.current.offsetParent.clientHeight - distanceToTopOfList;

            this.setState({ promptIsCloseToBottom: Math.min(totalDistance, distanceToBottomOfList) < 100});
        }
    }

    public render() {
        let deletePrompt = null;

        if (this.props.awaitsDecisionToBeDeleted || this.props.isPendingServerDelete) {

            deletePrompt = (
                <Wrapper isCloseToBottom={this.state.promptIsCloseToBottom}>
                    <ConfirmPromptComponent
                        onConfirm={this.props.deleteComment}
                        onCancel={this.props.cancelDeleteComment}
                        confirmText={_('delete_')}
                    >
                        {_('delete_comment_prompt_text')}
                    </ConfirmPromptComponent>
                    {this.props.isPendingServerDelete && <RippleLoaderOverlay loaderSize={48}/>}
                </Wrapper>
            );
        }
        let commentElement = null;
        if (this.props.isEditing || this.props.isPendingServerEdit) {
            commentElement = (
                <EditCommentComponent
                    isSubmitting={this.props.isPendingServerEdit}
                    text={this.props.text}
                    doCancel={this.props.cancelEditComment}
                    doSubmit={this.props.submitEditedComment}
                />
            );
        } else {
            commentElement = <SingleComment {...this.props}/>;
        }

        return (
            <CommentWrapper
                innerRef={this.commentWrapper}
                shouldBeRelative={this.props.awaitsDecisionToBeDeleted || this.props.isPendingServerDelete}
            >
                {commentElement}
                {deletePrompt}
            </CommentWrapper>
        );
    }
}

const stateToProps = (state: State): StateProps => ({
    isMobileMode: isMobileMode(state),
});

const dispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    commentActions: getActionsForComment(dispatch, ownProps),
    cancelDeleteComment: () => dispatch(DeleteCommentCanceled({commentID: ownProps.commentID})),
    deleteComment: () => deleteComment(dispatch, ownProps.albumID, ownProps.fileID, ownProps.commentID),
    cancelEditComment: () => dispatch(EditCommentCanceled({commentID: ownProps.commentID})),
    submitEditedComment: (text: string) => editComment(dispatch, ownProps.albumID, ownProps.fileID, ownProps.commentID, text),
});

export const SingleCommentContainer = connect(stateToProps, dispatchToProps)(_SingleCommentContainer);
