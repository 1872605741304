import * as React from 'react';
import styled from 'styled-components';
import {isOpenInsideAppWebView} from '../../API/externals';
import {LogoutButton} from './FixedTopLeftLogoutButton';
import {ConfirmArea, InfoHighlight, TakeoutContent} from './TakeoutContent';
import {getTakeoutString} from './takeoutLocalizedStrings';

type Props = {
    email?: string,
};

const TextWrapper = styled.div`
    font-style: italic;
    padding: 5px;
`;

const getConfirmAreaContent = () => {
    if (isOpenInsideAppWebView()) {
        return <TextWrapper>{getTakeoutString('baking_close_tab_info')}</TextWrapper>;
    }
    return <LogoutButton />;
};

export const TakeoutBakingPage: React.SFC<Props> = (props: Props) => (
    <TakeoutContent title={getTakeoutString('takeout_service')}>
            <div>
                <p>{getTakeoutString('baking_thanks')}</p>
                <p>{getTakeoutString('baking_emailInfo')}</p>
                <InfoHighlight>{props.email}</InfoHighlight>
            </div>
            <ConfirmArea>
                {getConfirmAreaContent()}
            </ConfirmArea>
    </TakeoutContent>
    );
