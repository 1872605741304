import {Store} from 'redux';
import {PrintAppService} from '../../print/API/services/PrintAppService';
import {getAuthTokenForJob, getCurrentAuthToken, getForeignAuthForJob} from '../state/currentUser/selectors';
import {HostDirectory} from '../state/hosts/actions';
import {getHostDirectoryForJob} from '../state/hosts/selectors';
import {State} from '../state/store';
import {getStoredServiceDict} from './externals';
import {fetchHostDirectory} from './login';
import {AppService} from './services/AppService';
import {PollService} from './services/PollService';
import {ThumbService} from './services/ThumbService';
import {VideoService} from './services/VideoService';

class ServiceProvider {
    constructor(private store: Store<State>) {}

    private getHostDirectoryForJob(job: JobID): Promise<HostDirectory> {
        const hostDirectory = getHostDirectoryForJob(this.store.getState(), job);
        if (hostDirectory) {
            return Promise.resolve<HostDirectory>(hostDirectory);
        }
        return fetchHostDirectory(this.store.dispatch, job);
    }

    private getAuthTokenForJob(job: JobID): string {
        return getAuthTokenForJob(this.store.getState(), job);
    }

    // ForeignAuth is used when the current user is not the owner of the job and is required to perform write-operations on the job
    private getForeignAuthForJob(job: JobID): string|undefined {
        return getForeignAuthForJob(this.store.getState(), job);
    }

    public async getAppServiceForLoggedInUserDefaults(): Promise<AppService> {
        const hosts = getStoredServiceDict(); // TODO: Get this from state when refactored to be injected at app-init
        const token = getCurrentAuthToken(this.store.getState());
        if (hosts === undefined || token === undefined) {
            throw new Error('Service dict must be made available (by logging in etc)');
        }
        return new AppService(hosts.appHost, token);
    }

    public getAppServiceForJob(job: JobID): Promise<AppService> {
        return this.getHostDirectoryForJob(job).then(
            (hosts: HostDirectory) => new AppService(hosts.appHost, this.getAuthTokenForJob(job), this.getForeignAuthForJob(job)),
        );
    }

    public getPrintAppServiceForJob(job: JobID): Promise<PrintAppService> {
        return this.getHostDirectoryForJob(job).then(
            (hosts: HostDirectory) => new PrintAppService(hosts.appHost, this.getAuthTokenForJob(job)),
        );
    }

    public getPollServiceForJob(job: JobID): Promise<PollService> {
        return this.getHostDirectoryForJob(job).then(
            (hosts: HostDirectory) => new PollService(hosts.pollHost),
        );
    }

    public getThumbServiceForJob(job: JobID): Promise<ThumbService> {
        return this.getHostDirectoryForJob(job).then(
            (hosts: HostDirectory) => new ThumbService(hosts.thumbHost, this.getAuthTokenForJob(job)),
        );
    }

    public getVideoServiceForJob(job: JobID): Promise<VideoService> {
        return this.getHostDirectoryForJob(job).then(
            (hosts: HostDirectory) => new VideoService(hosts.videoHost, this.getAuthTokenForJob(job)),
        );
    }
}

let instance: ServiceProvider;

// To connect the ServiceProvider to the store so that it can
export const connectServiceProvider = (store: Store<State>) => {
    instance = new ServiceProvider(store);
};

// Allow setting other serviceProviders too (primarily for testing purposes)
export const setServiceProvider = (provider: ServiceProvider) => {
    instance = provider;
};

export const getServiceProvider = () => {
    if (instance === undefined) {
        // Getting this error? It is because connectHostProvider-method (above) has not been called
        throw new Error('HostProvider must be connected before it is being used');
    }
    return instance;
};
