import {consoleLog} from '../utilities/logging';

/**
 *
 * @param params: Dictionary of strings
 * @returns {string}
 */
export function getQueryString(params: DictionaryOf<any>): string {
    return Object.keys(params).filter((key) => {
        return params[key] !== undefined;
    }).map((key) => key + '=' + encodeURIComponent(params[key])).join('&');
}

export class HostUrl {
    constructor(private hostname: string, private commonQueryParams?: DictionaryOf<any>) {}

    public getPath(path: string, params?: DictionaryOf<any>): string {
        let queryParams: DictionaryOf<any>|undefined;
        if (params !== undefined || this.commonQueryParams !== undefined) {
            queryParams = {
                ...this.commonQueryParams,
                ...params,
            };
        }
        return `https://${this.hostname + path}${queryParams ? '?' + getQueryString(queryParams) : ''}`;
    }
}

export class ResponseNotOKError extends Error {
    public message: string;
    constructor(public response: Response) {
        super(response.statusText);
        this.message = response.statusText;
    }
}

function makeSureResponseIsOK(response: Response): Response {
    if (! response.ok) {
        if (response.status === 401) {
            onUnauthenticated.forEach((f) => f());
        }
        throw new ResponseNotOKError(response);
    }
    return response;
}

const onOffline: Array<() => any> = [];
export function whenNetworkGoesOffline(callback: () => any) {
    onOffline.push(callback);
}

const onOnline: Array<() => any> = [];
export function whenNetworkComesOnline(callback: () => any) {
    onOnline.push(callback);
}

const onUnauthenticated: Array<() => any> = [];
export function whenUnauthenticated(callback: () => any) {
    onUnauthenticated.push(callback);
}

let isOnline = true;
function detectedOnlineState(newState: boolean) {
    if (newState !== isOnline) {
        isOnline = newState;
        consoleLog('New online-state: isOnline = ', isOnline);
        (isOnline ? onOnline : onOffline).forEach((fun) => fun());
    }
}
function _fetch(url: string, init?: RequestInit): Promise<Response> {
    return fetch(url, init).then(
        (ok: Response) => { detectedOnlineState(true); return ok; },
        (err: any) => { detectedOnlineState(false); throw err; },
    ).then(makeSureResponseIsOK);
}

const makeDownloadIframe = (src?: string) => {
    const iframe = document.createElement('iframe');
    iframe.name = 'download-file-iframe' + new Date().getTime().toString();
    iframe.setAttribute('style', 'display: none');
    if (src) {iframe.src = src; }

    document.body.appendChild(iframe);
    return iframe;
};

const validateIFrameRequest = (iframe: HTMLIFrameElement): Promise<any> => {
    return new Promise((ok, err) => {
        setTimeout(() => {
            try {
                // accessing iFrame.contentBody will throw if there is some document there (CORS-policy).
                // If the file is downloading as expected, there will be no document, so then we are fine.
                // If we actually gets access to the error-document and it has more than 100 bytes of code, fail.
                if (iframe.contentDocument && iframe.contentDocument.body.innerHTML.length < 100) {
                    ok();
                } else {
                    err();
                }
            } catch (e) {
                err(e);
            }
            document.body.removeChild(iframe);
        }, 10000);
    });
};

export const downloadIframePOSTRequest = async (url: string, data: any) => {
    const iframe = makeDownloadIframe();

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;
    form.target = iframe.name;

    const inputElement = document.createElement('input');
    inputElement.name = 'data';
    inputElement.value = JSON.stringify(data);
    form.appendChild(inputElement);

    iframe.appendChild(form);
    await form.submit();

    return validateIFrameRequest(iframe);
};

export const downloadIframeGETRequest = (url: string) => {
    const iframe = makeDownloadIframe(url);
    return validateIFrameRequest(iframe);
};

/**
 * Tool-functions to abstract away the inner workings of fetch returning a Promise in the way we are used to when using
 * tools like jQuery (ie treating a HTTP-status-error as an error etc)
 *
 * @param url
 * @returns {Promise<any>}
 */

export function get(url: string, headers: DictionaryOf<string> = {}): Promise<Response> {
    return _fetch(url, {method: 'GET', headers});
}

export function getJSON<T>(url: string, headers: DictionaryOf<string> = {}): Promise<T> {
    return get(url, headers).then((response) => response.json() as Promise<T>);
}

export function post(url: string, body?: FormData|string, headers: DictionaryOf<string> = {}): Promise<Response> {
    return _fetch(url, {method: 'POST', body, headers});
}
export function postJSON<T>(url: string, body?: FormData|string, headers: DictionaryOf<string> = {}): Promise<T> {
    return post(url, body, headers).then((response) => response.json() as Promise<T>);
}

// delete-function is named Delete with capital D because the keyword delete is reserved...
export function Delete(url: string, body?: FormData|string, headers: DictionaryOf<string> = {}): Promise<Response> {
    return _fetch(url, {method: 'DELETE', body, headers});
}
export function deleteJSON<T>(url: string, body?: FormData|string, headers: DictionaryOf<string> = {}): Promise<T> {
    return Delete(url, body, headers).then((response) => response.json() as Promise<T>);
}

export function put(url: string, body?: FormData | string, headers: DictionaryOf<string> = {}): Promise<Response> {
    return _fetch(url, {method: 'PUT', body, headers});
}

export function putJSON<T>(url: string, body?: FormData|string, headers: DictionaryOf<string> = {}): Promise<T> {
    return put(url, body, headers).then((response) => response.json() as Promise<T>);
}
