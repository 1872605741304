import * as React from 'react';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const EditIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path fill={props.color || '#000000'} fillRule="nonzero" d="M4,16.6671296 L4,20 L7.33287043,20 L17.1626163,10.1702541 L13.8297459,6.8373837 L4,16.6671296 Z M19.7400361,7.59283433 C20.0866546,7.2462158 20.0866546,6.68629357 19.7400361,6.33967505 L17.660325,4.25996389 C17.3137064,3.91334537 16.7537842,3.91334537 16.4071657,4.25996389 L14.7807249,5.88640467 L18.1135953,9.2192751 L19.7400361,7.59283433 Z"/>
        </g>
    </svg>
);
