import * as React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {State} from '../../state';
import {isMobileMode} from '../../state/viewMode/selectors';
import {CaptureIconWhite} from '../Icons/CaptureLogo';
import {TakeoutLanguageSelector} from './TakeoutLanguageSelector';
import {getTakeoutString} from './takeoutLocalizedStrings';

type Props = {
    isMobile: boolean,
};

const Wrapper = styled.div`
    background-size: cover;
    height: 100vh;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 15%;
    box-sizing: border-box;

    color: white;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    div:last-child{
        margin-top: 32px;
    }
`;
const Header = styled.div`
    margin-top: 32px;
    font-weight: bold;
`;
const ShutdownUser: React.SFC<Props> = (props) => {
    const shutdownText = props.isMobile ? getTakeoutString('shutdown_capture_app_not_available') : getTakeoutString('shutdown_capture_not_available');
    return (
        <Wrapper style={{backgroundImage: 'url(../img/background_wallpaper_asia_exit.png)'}}>
            <TakeoutLanguageSelector color={'white'} />
            <ContentWrapper>
                <CaptureIconWhite size={80}/>
                <Header>{getTakeoutString('dear_customer')}</Header>
                <div>{shutdownText}</div>
                <div>{getTakeoutString('shutdown_remove_app')}</div>
            </ContentWrapper>
        </Wrapper>
    );
};

const mapStateToProps = (state: State): Props => ({
    isMobile: isMobileMode(state),
});
export const ShutdownUserPage = connect(mapStateToProps)(ShutdownUser);
