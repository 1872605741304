import * as React from 'react';
import {trackEvent} from '../../analytics';
import {_} from '../../assets';
import {getJobIdForNewAlbum} from '../../state/uploader/AlbumAutoCreator';
import {getConnectedInstance} from '../../state/uploader/uploadQueue';
import {AlbumIllustration} from '../Illustrations/AlbumIllustration';
import {flattenFolder, Folder} from './FileAcceptZone';
import {UploadOverlay} from './UploadOverlay';
import {VisibleOnDrag} from './VisibleOnDrag';

export class UploadOverlayAlbumListPlacement extends React.Component {
    private fileHandler = async (folder: Folder) => {
        trackEvent('Uploader', 'FilesAddedByDrop', '', flattenFolder(folder).length);
        getConnectedInstance().addFiles(folder.files, getJobIdForNewAlbum(_('auto_created_album_title')), '');
        if (folder.children.length > 0) {
            trackEvent('Uploader', 'FoldersAddedByDrop', '', folder.children.length);
            folder.children.forEach((d) => {
                getConnectedInstance().addFiles(flattenFolder(d), getJobIdForNewAlbum(d.name), d.name);
            });
        }
    }

    public render() {
        return (
            <VisibleOnDrag>
                <UploadOverlay
                    fileHandler={this.fileHandler}
                    text={_('dropZone_text__albums')}
                    illustration={<AlbumIllustration />}
                />
            </VisibleOnDrag>
        );
    }
}
