import {Action, isType} from '../common/actions';
import {FileMetadata, FileMetadataWasFetched} from './actions';

export type FileMetadataState = DictionaryOf<FileMetadata>;

const initialState = {};

export function fileMetadataReducer(state: FileMetadataState = initialState, action: Action<any>): FileMetadataState {

    if (isType(action, FileMetadataWasFetched)){
        return {
            ...state,
            [action.payload.fileID]: action.payload,
        };
    }

    return state;
}
