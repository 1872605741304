import * as React from 'react';

type CircleBtnProps = {
    size: number,
};

export const NextCircleButton: React.SFC<CircleBtnProps> = (props) =>
    <svg width={props.size} height={props.size} viewBox="0 0 68 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle fill="#000000" cx="34" cy="34" r="34"></circle>
            <g transform="translate(27.000000, 19.000000)" strokeWidth="3" stroke="#FFFFFF">
                <polyline points="0.260335805 0.86053601 14.6402442 15.2404444 -0.0471023665 29.9277909"></polyline>
            </g>
        </g>
    </svg>;

type ArrowBtnProps = {
    width: number,
    height: number,
};

export const NextArrowButton: React.SFC<ArrowBtnProps> = (props) => (
    <svg width={props.width} height={props.height} style={{filter: 'drop-shadow( 0 1px 1px #000000)'}} viewBox="0 0 20 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-342.000000, -618.000000)" strokeWidth="3" stroke="#FFFFFF">
                <g transform="translate(344.000000, 619.000000)">
                    <polyline points="0.260335805 0.86053601 14.6402442 15.2404444 -0.0471023665 29.9277909"></polyline>
                </g>
            </g>
        </g>
    </svg>
);
