import * as React from 'react';
import styled from 'styled-components';
import {TopNavBar} from './TopNavBar';

const Wrapper = styled.div`
    height: 100%;
    padding: 8px 24px;
    box-sizing: border-box;
    font-size: 14px;

    display: flex;
`;

const LeftArea = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const MidArea = styled.div`
    justify-self: center;
    text-align: center;
    display: flex;
`;
const RightArea = styled.div`
    flex: 1;
    text-align: right;
    justify-self: end;

    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

type Props = {
    left: React.ReactNode,
    middle: React.ReactNode,
    right: React.ReactNode,
};

export const ThreeAreasTopNavBar: React.SFC<Props> = (props) => {
    return (
        <TopNavBar>
            <Wrapper>
                <LeftArea>{props.left}</LeftArea>
                <MidArea>{props.middle}</MidArea>
                <RightArea>{props.right}</RightArea>
            </Wrapper>
        </TopNavBar>
    );
};
