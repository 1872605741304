import {getServiceProvider as getCaptureServiceProvider} from '../HostProvider';

export interface CommentService {
    addComment(jobID: JobID, fileID: FileID, comment: string): Promise<{comment_uuid: CommentID}>;
    deleteComment(jobID: JobID, fileID: FileID, commentID: CommentID): Promise<any>;
    editComment(jobID: JobID, fileID: FileID, commentID: CommentID, commentText: string): Promise<any>;
};

export interface ReactionService {
    loveFile(jobID: JobID, fileID: FileID): Promise<any>;
    unLoveFile(jobID: JobID, fileID: FileID): Promise<any>;
}

// Service-oriented providers.
// In an ideal world all calls comes through these (not specific API-implementations) as these are product-adaptable.

// TODO: Return service, not promise of service (and have the capture-service deal with async service-fetching)
// TODO: ^^ Also drop the jobID-parameter as it is always a part of the service calls and can be inferred from that.
export const getReactionService = (jobID: JobID): Promise<ReactionService> => {
    return getCaptureServiceProvider().getAppServiceForJob(jobID);
};

// TODO: (as above): Drop job-argument and Promise-type when refactoring and making service-layer for capture.
export const getCommentsService = (job: JobID): Promise<CommentService> => {
    return getCaptureServiceProvider().getAppServiceForJob(job);
};
