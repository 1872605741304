import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

export const PauseIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path fill={props.color || colors.defaultIconColor} d="M6,19 L10,19 L10,5 L6,5 L6,19 L6,19 Z M14,5 L14,19 L18,19 L18,5 L14,5 L14,5 Z"/>
        </g>
    </svg>
);
