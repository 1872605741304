import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {VersionNote} from '../../state/currentUser/selectors';
import {FilledTextButton, IconButton} from '../Common/IconTextButton';
import {CaptureIcon} from '../Icons/CaptureLogo';
import {CloseIcon} from '../Icons/CloseIcon';

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

const CancelWrapper = styled.div`
    position: absolute;
    right: 0;
`;
const TitleImageWrapper = styled.div`
    margin: 25px auto;
`;

const Header = styled.div`
    font-size: ${fontSize.large_24};
    font-weight: bold;
    line-height: 29px;
`;
const VersionNoteList = styled.ul`
    min-height: 100px;
    max-height: 300px;
    max-width: 350px;
    overflow: auto;

    font-size: ${fontSize.medium_16};
    text-align: left;
    margin: 16px 0 40px;
    line-height: 24px;
`;
const ButtonWrapper = styled.div`
    width: 160px;
    margin: 0 auto;
    font-size: 14px;
    text-transform: uppercase;

    & > div {
        margin: 8px 0;
    }
`;

type ComponentState = {
    lastSeenIndex: number;
};

type Props = {
    versionNotes: VersionNote[]; // all notes
    onConfirm: () => any;
};

export class NewFeaturesPopupContent extends React.Component<
    Props,
    ComponentState
> {
    public state: ComponentState = {
        lastSeenIndex: 0,
    };

    private onMoreClick = () => {
        this.setState({lastSeenIndex: this.state.lastSeenIndex + 1});
    }

    public render() {
        const isFinalNote =
            this.state.lastSeenIndex >= this.props.versionNotes.length - 1;
        const currentNotes = this.props.versionNotes[this.state.lastSeenIndex];
        const noteElements = currentNotes.notes.map((note, i) => (
            <li key={i}>{note}</li>
        ));

        const confirmButton = (
            <FilledTextButton
                data-cy="confirmNewFeatures"
                onClick={this.props.onConfirm}
                text={isFinalNote ? _('got_it') : _('skip')}
                color={isFinalNote ? '#fff' : colors.captureGrey800}
                fillColor={isFinalNote ? colors.captureBlue : '#fff'}
            />
        );

        const seeMoreButton = (
            <FilledTextButton
                onClick={this.onMoreClick}
                text={_('see_more')}
                color={'#fff'}
                fillColor={colors.captureBlue}
            />
        );

        return (
            <ContentWrapper>
                <CancelWrapper>
                    <IconButton
                        onClick={this.props.onConfirm}
                        icon={CloseIcon}
                        iconSize={24}
                    />
                </CancelWrapper>
                <TitleImageWrapper>
                    <CaptureIcon size={100} />
                </TitleImageWrapper>
                <Header>{currentNotes.header}</Header>
                <VersionNoteList>{noteElements}</VersionNoteList>
                <ButtonWrapper>
                    {!isFinalNote && seeMoreButton}
                    {confirmButton}
                </ButtonWrapper>
            </ContentWrapper>
        );
    }
}
