import {History} from 'history';
import {Middleware} from 'redux';
import {Album, Albums} from '../../routing/pages';
import {isType} from '../common/actions';
import {UserUnsubscribedFromAlbum} from '../currentUser/actions';
import {JobWasDeleted} from '../jobInfo/actions';
import {NavigateByReplacingCurrent, NavigateTo} from './actions';
import {isCurrentLocation} from './selectors';

export const routerMiddleware = (history: History): Middleware => {
    return (store) => (next) => (action) => {
        if (isType(action, NavigateTo)) {
            history.push(action.payload.url);
        }
        if (isType(action, NavigateByReplacingCurrent)) {
            history.replace(action.payload.url);
        }
        if (isType(action, JobWasDeleted) || isType(action, UserUnsubscribedFromAlbum)) {
            if (isCurrentLocation(store.getState(), Album(action.payload))) {
                store.dispatch(NavigateTo(Albums));
            }
        }

        next(action);
    };
};
