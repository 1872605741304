import {Action as ReduxAction, Dispatch as ReduxDispatch} from 'redux';
export type ActionType = string;

export type Action<TPayload = any> = ReduxAction<ActionType> & {
    payload: TPayload;
};

// Alias Redux.Dispatch to keep codebase using same Dispatch types,
// make re-factory with Redux.Dispatch type changes easier
export type Dispatch = ReduxDispatch;

export type ActionCreator<TPayload> = {(payload: TPayload): Action<TPayload>, type: ActionType};

/**
 * An action creator is created with an ActionType and parametrised with a PayloadType (TPayload).
 * It is a function that takes the action payload as a parameter and then returns the resulting Action.
 *
 * The ActionCreator also has a type-parameter that matches the type of the Actions it creates
 * (which enables the comparison in the below isType function)
 *
 * @param type: ActionType
 * @returns ActionCreator<TPayload>
 */
export function createActionCreator<TPayload>(type: ActionType): ActionCreator<TPayload> {
    const creator: any = (payload: TPayload) => ({type, payload});
    creator.type = type;
    return creator;
}

/**
 * As a lot of actions are without data, having to provide them with an empty payload-object is a bit redundant.
 * By using a VoidActionCreator the payload-argument is omitted and the actionCreator will not require an argument.
 *
 * Example:
 * const VoidAction = createVoidActionCreator('MY_VOID_ACTION'); // in someModule/actions.ts
 * dispatch(VoidAction());  // in someComponent/mapDispatchToProps
 */

export type VoidActionCreator = {(): Action<void>, type: ActionType};

export function createVoidActionCreator(type: ActionType): VoidActionCreator {
    const creator: any = () => ({type, payload: null});
    creator.type = type;
    return creator;
}

/**
 * Type-guard-function for Actions.
 *
 * Use in reducers to make compiler (and IDE) infer type of action:
 * if(isType(action, MyActionCreator) {
 *   // Here the compiler knows for sure the action is the same type that is emitted by MyActionCreator and infers the
 *   // type of action.payload accordingly
 * }
 *
 * @param action
 * @param actionType
 * @returns {boolean}
 */
export function isType<P>(action: ReduxAction, actionType: ActionCreator<P>): action is Action<P> {
    return action && actionType.type === action.type;
}

/**
 * Action for applying multiple actions atomically
 */
export const BulkOfActions = createActionCreator<Action[]>('_BULK_OF_ACTIONS');
