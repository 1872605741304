import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {createAlbum} from '../../API/album';
import {Pages} from '../../routing';
import {NavigateTo} from '../../state/routing/actions';
import {getConnectedInstance} from '../../state/uploader/uploadQueue';
import {FileAcceptElement} from '../Uploader/FileAcceptElement';

type DispatchProps = {
    doCreateAlbum: () => Promise<JobID>,
    addUploadedFiles: (files: File[], jobID: JobID) => any,
    navigateToAlbumCreationPage: (albumID: JobID) => any,
};

type CreateAlbumProps = DispatchProps;

class CreateAlbumElementComp extends React.Component<CreateAlbumProps> {

    private fileHandler = async (files: File[]) => {
        const newAlbumID = await this.props.doCreateAlbum();
        this.props.addUploadedFiles(files, newAlbumID);
        this.props.navigateToAlbumCreationPage(newAlbumID);
    }

    public render() {
        return (
            <FileAcceptElement fileHandler={this.fileHandler}>
                {this.props.children}
            </FileAcceptElement>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doCreateAlbum: () => createAlbum(dispatch, '__new_album__'),
    addUploadedFiles: (files: File[], jobID: JobID) => getConnectedInstance().addFiles(files, jobID),
    navigateToAlbumCreationPage: (albumID: JobID) => dispatch(NavigateTo(Pages.EditAlbum(albumID))),
});

export const CreateAlbumElement = connect(
    null, mapDispatchToProps,
)(CreateAlbumElementComp);
