import * as React from 'react';
import styled from 'styled-components';
import {fontSize} from '../../assets/styleConstants';
import {FullscreenOverlay} from '../Common/FullscreenOverlay';

const Wrapper = styled.div`
    width: 100%;
    max-width: 480px;
    max-height: 100%;
    overflow: auto;

    margin: 8px;
    background-color: white;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
    text-align: center;
`;

const Illustration = styled.img`
    object-fit: contain;
`;
const Header = styled.div`
    font-size: ${fontSize.large_24};
    font-weight: bold;
    margin: 8px 0;
`;
const Text = styled.div`
    font-size: ${fontSize.medium_18};
    margin: 24px 0;
`;
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const ButtonDiv = styled.div`
    margin: 8px;
    width: 240px;
    font-size: 14px;
    text-transform: uppercase;
`;

type Props = {
    illustration: string,
    headerText: string,
    textContent?: string,
    buttons: React.ReactNode[],
};

export const PopupVerticalLayout: React.SFC<Props> = (props) => {
    const buttons = props.buttons.map((button: React.ReactNode, i) => <ButtonDiv key={i}>{button}</ButtonDiv>);

    return (
        <FullscreenOverlay>
            <Wrapper>
                <Illustration src={props.illustration} />
                <Header>{props.headerText}</Header>
                {props.textContent && <Text>{props.textContent}</Text>}
                <ButtonWrapper>{buttons}</ButtonWrapper>
            </Wrapper>
        </FullscreenOverlay>
    );
};
