import {createSelector} from 'reselect';
import {StripeProduct, StripeUserGrant} from '../../API/services/AppService';
import {localizedDateStringShort} from '../../components/Common/TimeStrings';
import {cachedInArray} from '../../utilities/arrayUtils';
import {State} from '../store';

export const getAvailableProducts = (state: State) => state.storagePlan.availableProducts;
const getCurrentUserGrants = (state: State) => state.storagePlan.userGrants;

export const getCreditCardInfo = (state: State) => state.storagePlan.paymentInfo;

export type StoragePlan = StripeProduct & {
    isCanceled: boolean,
    planActionLink?: string,
    validToDate?: string,
};

export const getCurrentStoragePlan = createSelector(
    getAvailableProducts,
    getCurrentUserGrants,
    (products, grants): StoragePlan|undefined => {
        const isAvailable = cachedInArray(products.map((p) => p.id));
        const currentGrant = grants.filter((g): g is StripeUserGrant =>
            g.source === 'capture stripe' &&
            isAvailable(g.stripe_product_id),
        )[0];
        if (currentGrant) {
            const {_links, renews_at, expires_at, stripe_product_id} = currentGrant;
            const currentProduct = products.filter((p) => p.id === stripe_product_id)[0];
            const validToDate = renews_at || expires_at;
            return {
                ...currentProduct,
                isCanceled: expires_at !== undefined,
                planActionLink: _links.cancellation || _links.reactivation,
                validToDate: validToDate
                    ? localizedDateStringShort(new Date(validToDate * 1000))
                    : undefined,
            };
        }
    },
);

export type CaptureStripeProduct = StripeProduct & {isPurchasable: boolean};

export type PlanGroup = {
    size: number,
    monthly: CaptureStripeProduct,
    yearly: CaptureStripeProduct,
};
const isPlanUpgrade = (currentPlan: StoragePlan, consideredPlan: StripeProduct) => {
    if (currentPlan.id === consideredPlan.id) {
        return false;
    }
    if (currentPlan.period === 'yearly' && consideredPlan.period === 'monthly') {
        return false; // downgrade in billing cycle
    }
    return currentPlan.size <= consideredPlan.size;
};

export const getStoragePlanGroups = createSelector(
    getAvailableProducts,
    getCurrentStoragePlan,
    (products, currPlan) => {
        const monthlyList: Map<PlanGroup['size'], PlanGroup['monthly']> = new Map();
        const yearlyList: Map<PlanGroup['size'], PlanGroup['yearly']> = new Map();
        const sizeList: Set<PlanGroup['size']> = new Set();

        products.forEach((p) => {
            if (!sizeList.has(p.size)) {sizeList.add(p.size); }
            const isPurchasable = currPlan ? isPlanUpgrade(currPlan, p) : true;

            switch (p.period) {
                case 'monthly':
                    monthlyList.set(p.size, {...p, isPurchasable});
                    break;
                case 'yearly':
                    yearlyList.set(p.size, {...p, isPurchasable});
                    break;
            }
        });

        const groups: PlanGroup[] = [];
        sizeList.forEach((size) => {
            const monthly = monthlyList.get(size);
            const yearly = yearlyList.get(size);
            if (monthly && yearly) {
                groups.push({
                    size,
                    monthly,
                    yearly,
                });
            }
        });

        return groups.sort((a, b) => a.size - b.size);
    },
);
