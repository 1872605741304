import {Action} from '../state';
import {State} from '../state/store';

export interface EventTracker {
    trackEvent: (context: string, event: string, label?: string, value?: number) => any;
    trackPageChange: (newPath: string) => any;
    trackUserID: (userId: string) => any;
    trackAction: (action: Action<any>, getState: () => State) => any;
}
const trackers: EventTracker[] = [];

export function addTracker(t: EventTracker) {
    trackers.push(t);
}

export function trackEvent(context: string, event: string, label?: string, value?: number) {
    trackers.forEach((t) => t.trackEvent(context, event, label, value));
}

export const trackPageChange = (newPath: string) => {
    trackers.forEach((t) => t.trackPageChange(newPath));
};

export const trackUserID = (userId: string) => {
    trackers.forEach((t) => t.trackUserID(userId));
};

export const trackAction = (action: Action<any>, getState: () => State) => {
    trackers.forEach((t) => t.trackAction(action, getState));
};
