import * as React from 'react';
import styled from 'styled-components';
import {trackEvent} from '../../analytics';
import {doLogin} from '../../API/login';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {FullscreenOverlay} from '../Common/FullscreenOverlay';
import {CloseIcon} from '../Icons/CloseIcon';

type Props = {
    handleNameProvision: (name: string) => any;
    cancelNamePrompt: () => any;
};

const BoxWrapper = styled.div`
    max-width: 568px;
    background-color: white;
    border-radius: 3px;
    padding: 16px;
    box-sizing: border-box;
    text-align: center;
`;

const FormWrapper = styled.form`
    padding: 32px;
`;

const TopElement = styled.div`
    width: 100%;
    padding-bottom: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid ${colors.captureGrey400};
    text-align: right;

    span {
        display: inline-block;
        height: 24px;
        border-radius: 2px;
        cursor: pointer;

        &:active {
            background-color: ${colors.captureGrey300};
        }
    }
`;
const HeaderText = styled.div`
    font-weight: bold;
    font-size: ${fontSize.large_24};
    margin-bottom: 28px;
`;

const NameInput = styled.input`
    width: 238px;
    height: 48px;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.4);
    -webkit-appearance: none;
    border-radius: 2px;
    border: none;
    box-sizing: border-box;
    text-align: center;
    font-size: ${fontSize.medium_16};
    margin: 4px 0;
`;

const NameSubmit = styled.button`
    width: 238px;
    height: 48px;
    border-radius: 2px;
    border: none;
    background: ${colors.captureBlue};
    color: white;
    font-size: ${fontSize.small_14};
    margin: 12px 0;
    text-transform: uppercase;
`;

const RegisterOrSignInButton = styled.div`
    color: ${colors.captureBlue};
    font-size: ${fontSize.small_14};
    cursor: pointer;
    white-space: pre-line;
`;

export class ProvideNameOverlay extends React.Component<
    Props,
    {enteredText: string}
> {
    public state = {enteredText: ''};

    private handleTextChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({enteredText: e.currentTarget.value});
    }
    private doCancel = () => {
        trackEvent('ProvideNameOverlay', 'PromptCanceled');
        this.props.cancelNamePrompt();
    }
    private doSubmit = (e: React.FormEvent<any>) => {
        if (this.state.enteredText) {
            trackEvent('ProvideNameOverlay', 'NameSubmitted');
            this.props.handleNameProvision(this.state.enteredText);
        }
        e.preventDefault();
        return false;
    }
    private doSignIn = () => {
        trackEvent('ProvideNameOverlay', 'clickedRegister/SignIn');
        doLogin();
    }

    public render() {
        return (
            <FullscreenOverlay>
                <BoxWrapper>
                    <TopElement>
                        <span onClick={this.doCancel}>
                            <CloseIcon size={24} />
                        </span>
                    </TopElement>
                    <FormWrapper onSubmit={this.doSubmit}>
                        <HeaderText>{_('what_is_your_name')}</HeaderText>
                        <NameInput
                            data-cy="nameInput"
                            type="text"
                            placeholder={_('tap_here_to_enter_name')}
                            value={this.state.enteredText}
                            onChange={this.handleTextChange}
                        />
                        <NameSubmit
                            data-cy="submitName"
                            type="submit"
                            onClick={this.doSubmit}
                        >
                            {_('enter')}
                        </NameSubmit>
                        <RegisterOrSignInButton onClick={this.doSignIn}>
                            {`${_('for_best_experience')}, \n ${_(
                                'register_or_sign_in',
                            )}`}
                        </RegisterOrSignInButton>
                    </FormWrapper>
                </BoxWrapper>
            </FullscreenOverlay>
        );
    }
}
