import {copy} from 'clipboard-js';
import * as React from 'react';
import styled from 'styled-components';
import {_, Button, colors} from '../../assets';
import {preventDefault} from '../../utilities/eventHandling';
import {CopyLinkOutlinedBtn} from '../AlbumOptions/ShareToOptions';
import {FilledTextButton} from '../Common/IconTextButton';
import {OptionsOverlay} from '../Common/Overlay';
import {RippleLoaderOverlay} from '../Common/RippleLoaderOverlay';

const Wrapper = styled.div`
    width: ${(props: {isMobile: boolean, height?: number}) => props.isMobile ? 'auto' : '400px'};
    height: ${(props) => props.height ? `${props.height}px` : 'auto'};
    padding: 0 8%;
    box-sizing: border-box;

    display:flex;
    flex-direction: column;
    justify-content: center;
`;

const ShareLink = styled.a`
    display: block;
    margin-bottom: 20px;
    user-select: none;
    color: ${colors.captureBlue};
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

type Props = {
    link?: string,
    isMobile: boolean,
    isMobileDevice: boolean,
    onCancel: () => any,
    onConfirm: () => any,
    height?: number,
};

export class CopyShareLinkComponent extends React.Component<Props, {}> {

    private copyUrl = () => {
        copy(this.props.link + '#sc');
    }

    public componentWillMount() {
        document.addEventListener('copy', this.props.onConfirm);
    }

    public componentWillUnmount() {
        document.removeEventListener('copy', this.props.onConfirm);
    }

    private getContent = () => {
        if (!this.props.link) {
            return <RippleLoaderOverlay />;
        }
        else if (this.props.isMobileDevice) {
            return (
                <>
                    <ShareLink href={this.props.link} draggable={false}>{this.props.link}</ShareLink>
                    <CopyLinkOutlinedBtn href={this.props.link} onClick={preventDefault}>
                        {_('hold_to_copy_link')}
                    </CopyLinkOutlinedBtn>
                </>
            );
        }
        else {
            return (
                <>
                    <ShareLink href={this.props.link} draggable={false}>{this.props.link}</ShareLink>
                    <div style={{margin: '0 auto', width: '80%'}}>
                        <FilledTextButton
                            onClick={this.copyUrl}
                            text={_('copy_link')}
                            fillColor={colors.captureBlue}
                            hoverColor={colors.captureBlue900}
                            color="white"
                        />
                    </div>
                </>
            );
        }
    }

    public render() {
        return (
            <OptionsOverlay
                onClose={this.props.onCancel}
                cancelButton={Button(_('cancel'), this.props.onCancel)}
                isMobile={this.props.isMobile}
            >
                <Wrapper isMobile={this.props.isMobile} height={this.props.height}>
                    {this.getContent()}
                </Wrapper>
            </OptionsOverlay>
        );
    }
}
