import {Dispatch} from '../state/common/actions';
import {
    TakeoutInitiated, TakeoutIsBaking, TakeoutIsNotStarted,
    TakeoutIsReady,
    TakeoutStatusFetchFailed,
    TakeoutStatusFetchStarted,
} from '../state/takeout/actions';
import {isMobileDevice} from '../utilities/device';
import {getServiceProvider} from './HostProvider';
import {downloadIframeGETRequest, ResponseNotOKError} from './toolbox';

export const createTakeout = async (dispatch: Dispatch, email: string) => {
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        await service.createTakeout(email);
        dispatch(TakeoutInitiated({contactEmail: email}));
    } catch (e) {
        // nothing to do success is missing
    }
};

export const fetchTakeoutStatus = async (dispatch: Dispatch) => {
    try {
        dispatch(TakeoutStatusFetchStarted());
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        const response = await service.getTakeoutStatus();
        if (response.status === 'done') {
            dispatch(TakeoutIsReady({
                contactEmail: response.contact_info,
                files: response.file_links,
                filesExpires: response.expiration.archive,
                rebakeAllowedAt: response.expiration.request,
            }));
        } else {
            dispatch(TakeoutIsBaking({contactEmail: response.contact_info}));
        }
    } catch (err) {
        const e = err as ResponseNotOKError | undefined;
        if (e && e.response && e.response.status === 404) {
            dispatch(TakeoutIsNotStarted());
        } else {
            dispatch(TakeoutStatusFetchFailed());
        }
    }
};

export const resetTakeout = async () => {
    const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
    await service.resetTakeout();
    console.log('status is reset. Reload to reflect');
};

export const downloadTakeoutFile: (url: URLstring) => Promise<void> = async (url) => {
    if (isMobileDevice.any()) {
        window.location.href = url;
    } else {
        await downloadIframeGETRequest(url);
    }
};
