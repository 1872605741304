import * as React from 'react';
import {Dispatch} from 'react-redux';
import {PRODUCT_NAME} from '../../../../config/constants';
import {copyAlbumFilesToTimeline, copyAlbumToTimeline} from '../../API/job';
import {_} from '../../assets';
import {AlbumB64} from '../../routing/pages';
import {b64ToUuid} from '../../utilities/uuid';
import {RequireLoginActionPage} from './RequireLoginActionPage';

type Props = {
    params: {albumIDB64: string, fileID?: FileID},
};
export class LoginRequiredCopyAlbum extends React.Component<Props> {
    private copyToTimeline = (d: Dispatch) => {
        const albumID = b64ToUuid(this.props.params.albumIDB64);
        if (this.props.params.fileID !== undefined) {
            copyAlbumFilesToTimeline(d, albumID, [this.props.params.fileID]);
        }
        else {
            copyAlbumToTimeline(d, albumID);
        }
    }

    public render() {
        return (
            <RequireLoginActionPage
                text={_('needs_login_to_copy_album_to_timeline').replace('%product_name%', PRODUCT_NAME)}
                targetAfterLogin={AlbumB64(this.props.params.albumIDB64)}
                afterLoginAction={this.copyToTimeline}
            />
        );
    }
}
