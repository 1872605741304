import {colors} from '../../../common/assets';
import {fontSize} from '../../../common/assets/styleConstants';
import {CaptureTVButton} from './CaptureTVButton';

export const BackBtnTV = CaptureTVButton.extend`
    position: fixed;
    top: 16px;
    left: 16px;
    font-size: ${fontSize.medium_16};
    padding: 4px;
    background-color: rgba(${colors.captureGrey800rgb}, 0.2);
    border-color: transparent;

    & > * {
        vertical-align: middle;
    }
`;
