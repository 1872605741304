import * as React from 'react';
import styled from 'styled-components';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {Button} from '../Common/Button';
import {OptionsOverlay} from '../Common/Overlay';
import {RippleLoaderOverlay} from '../Common/RippleLoaderOverlay';
import {TextFormInput} from '../Common/TextInputFormRow';
import {BookIcon} from '../Icons/BookIcon';
import {CheckIcon} from '../Icons/CheckIcon';
import {FacebookIconSquare} from '../Icons/FacebookIcon';

const Wrapper = styled.div`
    margin-top: 24px;
    width: ${(props: {isMobile: boolean}) => props.isMobile ? '100%' : '400px'};
    position: relative;
`;

const ErrorMsgArea = styled.div`
    margin-top: 10px;
    color: ${colors.captureMagenta};
    text-align: center;
`;

const HeaderIcon = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
`;

const Header = styled.div`
    font-size: ${fontSize.small_12};
    color: ${colors.captureGrey500};
    margin: 8px auto;
    text-align: center;
`;

type Props = {
    isMobile: boolean;
    isPending: boolean;
    onCancel: () => any;
    onConfirm: (fbAlbumName: string) => any;
    hasError?: boolean,
};

type State = {
    fbAlbumName: string,
    isValid: boolean|undefined,
};

export class SharePhotosToFBForm extends React.Component<Props, State> {
    public state: State = {
        fbAlbumName: '',
        isValid: undefined,
    };

    private handleAlbumNameChanged: React.FormEventHandler<any> = (evt) => {
        this.setState({
            ...this.state,
            fbAlbumName: evt.currentTarget.value,
            isValid: evt.currentTarget.value !== '',
        });
    }

    private handleFBAlbumConfirmed = () => {
        if (!this.props.isPending && this.state.isValid === true) {
            this.props.onConfirm(this.state.fbAlbumName);
        }
    }

    private handleKeyUp = (event: KeyboardEvent) => {
        if (event.keyCode === 13) { // enter
            this.handleFBAlbumConfirmed();
        }
    }

    public componentWillMount() {
        window.addEventListener('keyup', this.handleKeyUp);
    }

    public componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp);
    }

    public render() {
        const confirmButton = Button(
            _('done'),
            this.handleFBAlbumConfirmed,
            {
                icon: CheckIcon,
                isActive: !this.props.isPending && this.state.isValid === true,
            },
        );

        const errorMsg = (
            <ErrorMsgArea>
                {_('fb_permissions_error')}
            </ErrorMsgArea>
        );

        return (
            <OptionsOverlay
                onClose={this.props.onCancel}
                cancelButton={Button(_('cancel'), this.props.onCancel)}
                confirmButton={confirmButton}
                isMobile={this.props.isMobile}
            >
                <Wrapper isMobile={this.props.isMobile}>
                    {this.props.isPending && <RippleLoaderOverlay />}
                    <HeaderIcon><FacebookIconSquare size={48} /></HeaderIcon>
                    <Header>{_('fb_album_share_desc')}</Header>
                    <TextFormInput
                        value={this.state.fbAlbumName}
                        placeholder={_('enter_fb_album_name')}
                        icon={<BookIcon size={16} color={colors.captureGrey800}/>}
                        onChange={this.handleAlbumNameChanged}
                    />
                    {this.props.hasError && errorMsg}
                </Wrapper>
            </OptionsOverlay>
        );
    }
}
