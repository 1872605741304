import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import {createAlbumWithFiles} from '../../API/album';
import {copyFilesToAlbum} from '../../API/job';
import {_} from '../../assets/localizedStrings';
import {Pages} from '../../routing';
import {JobFile} from '../../state/files/reducer';
import {NavigateByReplacingCurrent} from '../../state/routing/actions';
import {b64ToUuid} from '../../utilities/uuid';
import {PhotoSelectionPage} from '../Timeline/PhotoSelectionPage';

type CreateAlbumSelectionPageProps = {albumType: 'private'|'shared', headerTitle: string};
const CreateAlbumSelectionPage: React.ComponentClass<CreateAlbumSelectionPageProps> = connect(
    null,
    (dispatch: Dispatch, ownProps: CreateAlbumSelectionPageProps) => ({
        doHandleFiles: async (files: JobFile[]) => {
            const albumID = await createAlbumWithFiles(
                dispatch, '__new_album__', files, ownProps.albumType === 'shared',
            );
            dispatch(NavigateByReplacingCurrent(Pages.EditAlbum(albumID)));
        },
    }),
)(PhotoSelectionPage);

export const PhotoSelectionPrivateAlbumPage: React.SFC = () => (
    <CreateAlbumSelectionPage
        headerTitle={_('create_album')}
        albumType={'private'}
    />
);
export const PhotoSelectionSharedAlbumPage: React.SFC = () => (
    <CreateAlbumSelectionPage
        headerTitle={_('create_album')}
        albumType={'shared'}
    />
);

const AddPhotosToAlbumPage = connect(
    null,
    (dispatch: Dispatch, ownProps: {albumID: JobID}) => ({
        doHandleFiles: async (files: JobFile[]) => {
            await copyFilesToAlbum(dispatch, ownProps.albumID, files);
            history.back();
        },
    }),
)(PhotoSelectionPage);

export const MorePhotosSelectionPage: React.SFC<{
    params: {albumIDB64: string};
}> = ({params}) => (
    <AddPhotosToAlbumPage
        albumID={b64ToUuid(params.albumIDB64)}
        nextButtonLabel={_('add_photos')}
    />
);
