import * as React from 'react';
import {connect, Dispatch} from 'react-redux';
import styled from 'styled-components';
import {setCurrentVisibleRanges} from '../../API/syncers/TimelineChunkSyncer';
import {TimelineCarousel} from '../../routing/pages';
import {CarouselViewInfo} from '../../state/carouselViewer/reducer';
import {BulkOfActions} from '../../state/common/actions';
import {NavigateTo} from '../../state/routing/actions';
import {TimelineFileDeSelected, TimelineFileSelected} from '../../state/timeline/actions';
import {TimelineFilterMode} from '../../state/timeline/reducers';
import {ImageGroup, TimelineGroupStyle} from '../../state/timeline/selectors';
import {inArray} from '../../utilities/arrayUtils';
import {
    calcImagesPerRow, getElementSize,
} from '../../utilities/gridElementSizeCalculator';
import {isBodyScrollable} from '../../utilities/preventBodyScroll';
import {GroupListEntry} from './GroupListEntry';

const Wrapper = styled.div`
    width: ${(props: {width: number}) => props.width}px;
    margin: 0 auto;
`;
type OwnProps = {
    isInSelectMode: boolean,
    imagesGrouped: ImageGroup[],
    filterBy?: TimelineFilterMode,
    groupStyle: TimelineGroupStyle,
    disableSelection?: boolean,
    lastSeenElement?: FileID,
};
type DispatchProps = {
    doEnterCarouselView: (sourceGroup: string, fileID: FileID) => any,
    selectFile: (fileID: FileID) => any,
    selectGroup: (fileIDs: FileID[]) => any,
    deSelectFile: (fileID: FileID) => any,
    deSelectGroup: (fileIDs: FileID[]) => any,
};
type Props = OwnProps & DispatchProps;

export type VisibleRange = {top: number, bottom: number};
type ComponentState = {
    currentlyVisibleGroups: string[],
    currentVisibleRange: VisibleRange, // The range that the currentlyVisibleGroups are calculated from
};

const getVisibleRange = (relativeOffset: number = 0): VisibleRange => ({
    top: window.pageYOffset - relativeOffset,
    bottom: window.pageYOffset - relativeOffset + window.innerHeight,
});

const calcHeaderSize = (style: TimelineGroupStyle) => {
    return style.headerHeight + style.headerBottomGap;
};

const layoutChanged = (oldStyle: TimelineGroupStyle, newStyle: TimelineGroupStyle) => {
    return (calcImagesPerRow(oldStyle) !== calcImagesPerRow(newStyle)
        || getElementSize(oldStyle).width !== getElementSize(newStyle).width
        || getElementSize(oldStyle).height !== getElementSize(newStyle).height
        || calcHeaderSize(oldStyle) !== calcHeaderSize(newStyle));
};

// Find the month and row that makes sense to pivot around when layout changes.
const findPivotImage = (topPos: number, style: TimelineGroupStyle, imagesGrouped: ImageGroup[]) => {
    const elementSize = getElementSize(style);
    const boundaryPos = topPos + elementSize.height;
    // Lower edge of month image group must be below the boundary position for the pivot image.
    const month = (imagesGrouped.filter(({height, position}) => position + height >= boundaryPos)[0]
        || imagesGrouped[imagesGrouped.length - 1]);
    const monthFirstRowPos = month.position + calcHeaderSize(style);
    const rowIndex = Math.max(0, Math.floor((boundaryPos - monthFirstRowPos) / elementSize.height));
    return { month, rowIndex };
};

class TimelinePhotos extends React.Component<Props, ComponentState> {
    public state: ComponentState = {
        currentlyVisibleGroups: [],
        currentVisibleRange: {top: 0, bottom: window.innerHeight},
    };
    private groupListElem = React.createRef<HTMLDivElement>();
    private lastHandledScrollOffset: number = 0;
    private doScrollToPositionAfterUpdate: number | undefined;

    private getGroupsInRange = (props: Props, top: number, bottom: number): string[] => {
        return props.imagesGrouped
            .filter((g) => (
                // Bottom of group is below top of view and top of group is above bottom of view
                g.position + g.height > top && g.position < bottom
            ))
            .map((g) => g.header);
    }

    private maybeUpdateCurrentlyVisibleGroups = (props: Props) => {
        if (! this.groupListElem.current) {
            // We are not yet mounted or in the process of being unmounted while scrolling. No changes available
            return;
        }

        const range = getVisibleRange(Math.min(window.pageYOffset, this.groupListElem.current.offsetTop));

        const mustBeVisible = this.getGroupsInRange(props, range.top - 200, range.bottom + 200);
        const mayBeKeptVisible = this.getGroupsInRange(props, range.top - 400, range.bottom + 400);

        const current = this.state.currentlyVisibleGroups;
        const next = mayBeKeptVisible.filter((e) => inArray(mustBeVisible, e) || inArray(current, e));

        // Convert header-strings to months and pass that to the TimelineSyncher to get months fetched.
        const months = next.map((s): Month => {
            const [y, m] = s.split('-');
            return {year: parseInt(y, 10), month: parseInt(m, 10)};
        });
        setCurrentVisibleRanges(months);

        this.setState((state) => ({...state, currentlyVisibleGroups: next, currentVisibleRange: range}));
    }

    private handleScroll = () => {
        if (isBodyScrollable() && Math.abs(this.lastHandledScrollOffset - window.pageYOffset) > 30) {
            this.lastHandledScrollOffset = window.pageYOffset;
            // Handle the update outside the scroll-handler
            setTimeout(() => this.maybeUpdateCurrentlyVisibleGroups(this.props), 1);
        }
    }
    private getGroupListEntry = (group: ImageGroup) => {
        let groupListEntry = null;
        if (inArray(this.state.currentlyVisibleGroups, group.header)) {
            const selectionProps = this.props.disableSelection ? undefined : {
                isInSelectMode: this.props.isInSelectMode,
                onSelectFile: this.props.selectFile,
                onSelectGroup: this.props.selectGroup,
                onDeSelectFile: this.props.deSelectFile,
                onDeSelectGroup: this.props.deSelectGroup,
            };

            groupListEntry = (
                <GroupListEntry
                    {...group}
                    groupStyle={this.props.groupStyle}
                    visibleRange={this.state.currentVisibleRange}
                    onEnterCarouselView={this.props.doEnterCarouselView}
                    selection={selectionProps}
                    lastSeenElement={this.props.lastSeenElement}
                />
            );
        }
        return <div key={group.header} style={{height: group.height}}>{groupListEntry}</div>;
    }
    public componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.maybeUpdateCurrentlyVisibleGroups(this.props);
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    public componentDidUpdate() {
        if (this.doScrollToPositionAfterUpdate !== undefined) {
            window.scrollTo(window.pageXOffset, this.doScrollToPositionAfterUpdate);
            this.doScrollToPositionAfterUpdate = undefined;
        }
    }

    private findNewOffset = (newStyle: TimelineGroupStyle, newImagesGrouped: ImageGroup[]) => {
        const {month, rowIndex} =
            findPivotImage(this.state.currentVisibleRange.top, this.props.groupStyle, this.props.imagesGrouped);
        const newMonth = newImagesGrouped.filter(({header}) => header === month.header)[0];
        const monthDelta = newMonth.position - month.position;
        const oldRowLength = calcImagesPerRow(this.props.groupStyle);
        const newRowLength = calcImagesPerRow(newStyle);
        const newRowIndex = Math.floor(rowIndex * oldRowLength / newRowLength);
        const imageDelta = newRowIndex * getElementSize(newStyle).height - rowIndex * getElementSize(this.props.groupStyle).height;
        const headerDelta = calcHeaderSize(newStyle) - calcHeaderSize(this.props.groupStyle);
        return window.pageYOffset + monthDelta + imageDelta + headerDelta;
    }

    public componentWillReceiveProps(nextProps: Props) {

        if (layoutChanged(this.props.groupStyle, nextProps.groupStyle)) {
            this.doScrollToPositionAfterUpdate = this.findNewOffset(nextProps.groupStyle, nextProps.imagesGrouped);
        }

        // When filter changes: Compensate for content-shift by scrolling to where the currently visible content will be
        if (this.props.filterBy !== nextProps.filterBy) {
            const topGroup = this.state.currentlyVisibleGroups[0];
            const oldMonth = this.props.imagesGrouped.filter(({header}) => header === topGroup)[0];
            const newMonth = nextProps.imagesGrouped.filter(({header}) => header <= topGroup)[0];

            if (oldMonth && newMonth) {
                // Align top of old and new months
                let delta = newMonth.position - oldMonth.position;

                // If top of month is not visible: Take actions to avoid aligning things off screen (= odd jumps)
                const {top, bottom} = this.state.currentVisibleRange;
                if (oldMonth.position < top) {
                    if (oldMonth.position + oldMonth.height < bottom) {
                        // The bottom of month is visible: Align the bottoms of the months
                        delta += newMonth.height - oldMonth.height;
                    } else {
                        // Neither bottom or top of the month is visible: Align month so that its header is visible.
                        // Vertically align month on screen if entire month fits in view.
                        const gapForCenter = Math.max((window.innerHeight - newMonth.height) / 2, 0);
                        delta = newMonth.position - top - gapForCenter;
                    }
                }

                if (delta !== 0) {
                    this.doScrollToPositionAfterUpdate = window.pageYOffset + delta;
                }
            }
        }
        const shouldUpdateVisibleGroups = isBodyScrollable() || (
            this.state.currentlyVisibleGroups.length === 0 && nextProps.imagesGrouped.length > 0
        );
        if (shouldUpdateVisibleGroups && this.props.imagesGrouped !== nextProps.imagesGrouped) {
            this.maybeUpdateCurrentlyVisibleGroups(nextProps);
        }
    }

    public render(): JSX.Element {
        return (
            <Wrapper innerRef={this.groupListElem} width={this.props.groupStyle.width}>
                {this.props.imagesGrouped.map(this.getGroupListEntry)}
            </Wrapper>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    selectFile: (fileID: FileID) => dispatch(TimelineFileSelected(fileID)),
    selectGroup: (fileIDs: FileID[]) => dispatch(BulkOfActions(fileIDs.map(TimelineFileSelected))),
    deSelectFile: (fileID: FileID) => dispatch(TimelineFileDeSelected(fileID)),
    deSelectGroup: (fileIDs: FileID[]) => dispatch(BulkOfActions(fileIDs.map(TimelineFileDeSelected))),
    doEnterCarouselView: (sourceGroup: string, fileID: FileID) => dispatch(NavigateTo(TimelineCarousel(sourceGroup, fileID))),
});

export const TimelinePhotoGroups = connect(null, mapDispatchToProps)(TimelinePhotos);
