import {createActionCreator, createVoidActionCreator} from '../common/actions';

type UserInfo = {
    userID: UserID,
    name: string,
    email?: string,
    profilePicture?: string,
};

export const UserInfoWasFetched = createActionCreator<UserInfo[]>('USER_INFO_WAS_FETCHED');

export const ProfileNameChanged = createActionCreator<{name: string}>('PROFILE_NAME_CHANGED');
export const ProfileNameChangeFailed = createVoidActionCreator('PROFILE_NAME_CHANGE_FAILED');
