import * as React from 'react';
import {isOpenInsideAppWebView} from '../../API/externals';
import {trackTakeoutEvent} from './analytics';
import {FixedTopLeftLogoutButton} from './FixedTopLeftLogoutButton';
import {TakeoutContent} from './TakeoutContent';
import {getTakeoutString} from './takeoutLocalizedStrings';

export class TakeoutInfoPage extends React.Component<{}> {
    public componentDidMount() {
        trackTakeoutEvent('takeoutInfoPage_loaded');
    }
    public render() {
        return (
            <TakeoutContent title={getTakeoutString('takeout_service')}>
                {!isOpenInsideAppWebView() && <FixedTopLeftLogoutButton />}
                <div>
                    <p>{getTakeoutString('shutdown_capture_not_available')}</p>
                    <p>{getTakeoutString('info_takeout_ended')}</p>
                </div>
            </TakeoutContent>
        );
    }
}
