import {AlbumDetailsFetched} from '../state/album/actions';
import {BulkOfActions, Dispatch} from '../state/common/actions';
import {
    AnonymousUserNameProvided, ConnectedDevicesWasFetched, ConnectedDeviceWasDeleted, DeleteConnectedDeviceFailed,
    DeleteConnectedDeviceStarted, FetchedAnonymousUserInfo, FetchingConnectedDevicesFailed,
    FetchingConnectedDevicesStarted, JobSubscriptionsDetected,
} from '../state/currentUser/actions';
import {
    FileMetadata, FileMetadataFetchingFailed, FileMetadataFetchingStarted,
    FileMetadataWasFetched,
} from '../state/fileMetadata/actions';
import * as FilesActions from '../state/files/actions';
import {JobFile as AppJobFile} from '../state/files/reducer';
import {FetchedHostDirectory} from '../state/hosts/actions';
import {
    AllJobFilesWasFetched, FetchedDefaultJob,
    FetchedLastSerialOfDefaultJob, FileCopiedInfo,
    FileRangeFetchFailed, FileRangeFetchingStarted,
    FileRangeWasFetched, FileRestoreFailed,
    FilesCopiedToAlbum, FilesCopiedToAlbumFailed,
    FilesDeletionFailed, FilesDeletionStarted,
    FilesDeletionSucceeded, FilesDownloadFailed,
    FilesDownloadSuccess, FilesFetchedPayload,
    FilesRestorationFailed, FilesRestorationStarted,
    FilesRestorationSucceeded, FileWasCopiedToJob,
    FileWasRemovedFromJob, FileWasRestored,
    JobCopiedToTimeline, JobCopiedToTimelineFailed,
    JobPublishingFailed, JobWasPublished, ShareCreationFailed,
    ShareWasCreated, StartFetchingDefaultJob,
    UnableToFetchDefaultJob,
} from '../state/job/actions';
import {FetchedJobInfo} from '../state/jobInfo/actions';
import {LongRunningTaskFinished, LongRunningTaskStarted} from '../state/statusNotifications/actions';
import {SubscribersWereFetched} from '../state/subscribers/actions';
import {TimelineMonthsFetched} from '../state/timeline/actions';
import {
    TrashFileDeleted, TrashFileDeleteFailed,
    TrashFilesDeletionFailed, TrashFilesDeletionStarted,
    TrashFilesDeletionSucceeded, TrashLoadingFailed, TrashLoadingStarted, TrashLoadingSucceeded,
} from '../state/trash/actions';
import {FileInformation} from '../state/uploader/reducer';
import {UploadDecorator, UploadMethod} from '../state/uploader/uploadQueue';
import {UserInfoWasFetched} from '../state/users/actions';
import {withoutTheBools} from '../utilities/arrayUtils';
import {managedPromiseAll} from '../utilities/promises';
import {localStorageSet} from '../utilities/webStorage';
import {getAuthToken, getStoredServiceDict} from './externals';
import {getServiceProvider} from './HostProvider';
import {
    AlbumJobsResponse,
    AppService,
    ContributingUsersResponse,
    CreatedStoryUser,
    JobFilesRequestOptions,
} from './services/AppService';

export function fetchDefaultJobID(dispatch: Dispatch): Promise<JobID|undefined> {
    const hosts = getStoredServiceDict();
    if (hosts === undefined) {
        return Promise.reject(null);
    }
    dispatch(StartFetchingDefaultJob());
    const service = new AppService(hosts.appHost, getAuthToken());
    return service.getDefaultJob().then(
        (jobInfo) => {
            dispatch(FetchedHostDirectory({
                job: jobInfo.id,
                hosts: {...hosts, pollHost: hosts.pollHost.replace(/^(p\d+)/, 'ps')},
            }));
            // Workaround until backend actually starts providing the serial as a header
            if (jobInfo.lastEventSerial === -1) {
                service.getLastJobChangeID(jobInfo.id).then(
                    (serial) => dispatch(FetchedLastSerialOfDefaultJob(serial)),
                );
            } else {
                dispatch(FetchedLastSerialOfDefaultJob(jobInfo.lastEventSerial));
            }
            dispatch(FetchedDefaultJob(jobInfo.id));
            return jobInfo.id;
        },
        (error) => {
            dispatch(UnableToFetchDefaultJob(error));
            return undefined;
        });
}

export const fetchTimelineMonths = async (dispatch: Dispatch, jobID: JobID): Promise<void> => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(jobID);
        const {months} = await service.getTimelineMonths(jobID);
        dispatch(TimelineMonthsFetched({jobID, months}));
    } catch (e) {
        // Do nothing
    }
};

export const initTimeline = async (dispatch: Dispatch, jobID?: JobID) => {
    const timelineID = jobID || await fetchDefaultJobID(dispatch);
    if (timelineID) {
        await fetchTimelineMonths(dispatch, timelineID);
    }
};

export const copyAlbumFilesToTimeline
= async (dispatch: Dispatch, albumID: JobID, fileIDs: FileID[]): Promise<void> => {
    try {
        dispatch(LongRunningTaskStarted('filesAreBeingCopied'));

        const service = await getServiceProvider().getAppServiceForJob(albumID);
        await service.copyFilesToDefaultJob(albumID, fileIDs);

        dispatch(FilesActions.FilesCopiedToTimeline());
    }
    catch (e) {
        const reason = e.response && e.response.status === 413 ? 'out_of_storage' : 'unknown';
        dispatch(FilesActions.FilesCopiedToTimelineFailed({reason}));
    }

    dispatch(LongRunningTaskFinished('filesAreBeingCopied'));
};

export const copyAlbumToTimeline = async (dispatch: Dispatch, albumID: JobID): Promise<void> => {
    try {
        dispatch(LongRunningTaskStarted('filesAreBeingCopied'));
        const service = await getServiceProvider().getAppServiceForJob(albumID);
        await service.copyJobToDefaultJob(albumID);
        dispatch(JobCopiedToTimeline());
    }
    catch (e) {
        const reason = e.response && e.response.status === 413 ? 'out_of_storage' : 'unknown';
        dispatch(JobCopiedToTimelineFailed({reason}));
    }

    dispatch(LongRunningTaskFinished('filesAreBeingCopied'));
};

export const copyMultipleFilesToJob = async (
    dispatch: Dispatch,
    jobID: JobID,
    files: AppJobFile[],
): Promise<{succeeded: FileID[], failed: FileID[]}> => {
    const successFileInfos: FileCopiedInfo[] = [];
    const succeeded: FileID[] = [];
    const failed: FileID[] = [];
    const service = await getServiceProvider().getAppServiceForJob(jobID);

    await managedPromiseAll(files, async ({fileID, path, size, checksum, mtime, ctime}) => {
        try {
            const uuid = await service.dedupFile(jobID, {
                id: fileID,
                path: path.split('/').pop(), // Extract last segment of path (remove folders, CAPWEB-1172)
                size,
                checksum,
                mtime,
                ctime,
                policy: 'no_duplicates',
            });
            if (uuid !== undefined) {
                successFileInfos.push({from: fileID, to: {jobID, fileID: uuid}});
                succeeded.push(fileID);
            } else {
                throw Error();
            }
        } catch (e) {
            failed.push(fileID);
        }
    });

    dispatch(BulkOfActions(successFileInfos.map(FileWasCopiedToJob)));
    return {succeeded, failed};
};

export const tryCopyFilesToJobCompletely = async (
    dispatch: Dispatch,
    jobID: JobID,
    files: AppJobFile[],
): Promise<FileID[]> => {
    const {succeeded, failed} = await copyMultipleFilesToJob(dispatch, jobID, files);
    if (failed.length > 0) {
        throw Error();
    }

    return succeeded;
};

export const copyFilesToAlbum = async (
    dispatch: Dispatch,
    jobID: JobID,
    files: AppJobFile[],
): Promise<void> => {
    dispatch(LongRunningTaskStarted('filesAreBeingCopied'));

    const {succeeded, failed} = await copyMultipleFilesToJob(dispatch, jobID, files);
    dispatch(BulkOfActions(withoutTheBools([
        LongRunningTaskFinished('filesAreBeingCopied'),
        succeeded.length > 0 && FilesCopiedToAlbum({jobID, files: succeeded}),
        failed.length > 0 && FilesCopiedToAlbumFailed({jobID, files: failed}),
    ])));
};

const fetchFiles = async (
    jobID: JobID,
    user_uuid: UserID,
    options: JobFilesRequestOptions,
): Promise<FilesFetchedPayload> => {
    const service = await getServiceProvider().getAppServiceForJob(jobID);
    const result = await service.getFiles(jobID, options);
    return {
        jobID,
        lastEvent: result.lastEventSerial,
        files: result.files.map((f) => ({ ...f, jobID, fileID: f.id, user_uuid, timestamp: (f.ctime || f.mtime)})),
    };
};

export type FetchFileRangeMethod = (
    dispatch: Dispatch,
    jobID: JobID,
    currentUser: UserID,
    start: Month,
    end?: Month,
) => Promise<void>;
export const fetchFileRange: FetchFileRangeMethod =
async (dispatch: Dispatch, jobID: JobID, currentUser: UserID, start: Month, end?: Month) => {
    end = end || start;
    try {
        const options = {
            start: `${start.year}/${start.month}`,
            end: `${end.year}/${end.month}/32`, // Add day at end of month to make it end-inclusive
        };
        dispatch(FileRangeFetchingStarted({jobID, start, end}));
        const response = await fetchFiles(jobID, currentUser, options);
        dispatch(FileRangeWasFetched({...response, start, end}));
    } catch (e) {
        dispatch(FileRangeFetchFailed({jobID, start, end}));
    }
};

export const fetchAllUserFiles = async (dispatch: Dispatch, jobID: JobID, currentUser: UserID) => {
    try {
        const response = await fetchFiles(jobID, currentUser, {recursive: 1});
        dispatch(AllJobFilesWasFetched(response));
    } catch (e) {
        // Do nothing
    }
};

export const fetchListOfJobs = (dispatch: Dispatch, currentUserID: UserID): Promise<void> => {
    return getServiceProvider().getAppServiceForLoggedInUserDefaults()
        .then((service) => service.getJobList())
        // filter out broken albums when no updates
        .then((jobInfos) => jobInfos.filter((i): i is AlbumJobsResponse => i.type === 'story' && i.last_update !== 0))
        .then((albumInfos) => {
            const hostFetchedActions = albumInfos.map(({id, service}) => FetchedHostDirectory({
                job: id,
                hosts: {
                    appHost: service['app-host'],
                    thumbHost: service['thumb-host'],
                    videoHost: service['video-host'],
                    pollHost: service['app-host'].replace(/^(a\d+)/, 'ps'), // Compensating for backend!
                },
            }));

            // TODO: Refactor to deal with jobInfo changes related

            const jobInfoFetchedActions = albumInfos.map((info) => {
                // Backend provides permissions combined into bitmap when fetching list of jobs
                // tslint:disable-next-line:no-bitwise
                const hasPerm = (flag: number) => (info.permissions & flag) === flag;
                return FetchedJobInfo({
                    job: info.id,
                    info: {
                        type: info.type,
                        ctime: info.ctime,
                        mtime: info.mtime,
                        owner: info.owner.uuid,
                        title: info.name,
                        allow_comments: hasPerm(1),
                        allow_uploads: hasPerm(2),
                        coverPhoto: info.cover_id,
                        isShared: info.privacy_mode === 'shared',
                        last_update: info.last_update,
                        pendingProperties: {},
                    },
                });
            });

            const detailsFetchedActions = albumInfos.map((info) => AlbumDetailsFetched({
                albumID: info.id,
                title: info.name,
                owner: {
                    userID: info.owner.uuid,
                    name: info.owner.name,
                    email: info.owner.email,
                    profilePicture: info.owner.profile_picture_url,
                },
                coverPhotoID: info.cover_id,
                ctime: info.ctime,
                mtime: info.mtime,
                permissions: info.permissions,
                numberOf: {
                    contributors: info.participant_count,
                    files: info.media_count,
                    comments: info.comment_count,
                    loves: info.reaction_count,
                },
                isShared: info.privacy_mode === 'shared',
            }));

            const jobSubscriptionsAction = JobSubscriptionsDetected(
                albumInfos.filter(({owner}) => owner.uuid !== currentUserID).map(({id}) => id),
            );

            dispatch(BulkOfActions([
                ...hostFetchedActions,
                ...jobInfoFetchedActions,
                ...detailsFetchedActions,
                jobSubscriptionsAction,
            ]));

        },
        (error) => {
            dispatch(UnableToFetchDefaultJob(error));
        });
};

export const fetchContributingUsers = (dispatch: Dispatch, jobID: JobID): void => {
    getServiceProvider().getAppServiceForJob(jobID)
        .then((ah) => ah.getJobContributors(jobID, true))
        .then(
            (response: ContributingUsersResponse) => {
                const users = Object.keys(response.users).map((uuid) => ({
                    userID: uuid,
                    name: response.users[uuid].name,
                    profilePicture: response.users[uuid].profile_picture_url,
                }));
                dispatch(UserInfoWasFetched(users));
                dispatch(SubscribersWereFetched({jobID, subscribers: users}));
            },
        );
};

export function createAnonymousUser(dispatch: Dispatch, jobContext: JobID, name: string): Promise<void> {
    dispatch(AnonymousUserNameProvided({name}));
    return getServiceProvider().getAppServiceForJob(jobContext)
        .then((ah) => ah.createStoryUser(name))
        .then((created: CreatedStoryUser) => {
            const userInfo = {
                name: created.name,
                uuid: created.uuid,
                auth_token: created.auth,
            };
            localStorageSet('captureAnonymousUser', JSON.stringify(userInfo));
            dispatch(FetchedAnonymousUserInfo(userInfo));
        });
}

/* Provide a `uploadFile`-method that keeps files uploads to some other job than timeline on timeline too */
export const uploaderWithTimelineMirroring = (getDefaultJobID: () => JobID|undefined): UploadDecorator => (
    (upload: UploadMethod): UploadMethod => (
        async (f: File, i: FileInformation, r?: XMLHttpRequest) => {
            const mirrorID = getDefaultJobID();
            const response = await upload(f, i, r);

            if (response.uuid && mirrorID && i.targetJob !== mirrorID) {
                try {
                    const mirrorService = await getServiceProvider().getAppServiceForJob(mirrorID);
                    await mirrorService.copyFilesToDefaultJob(i.targetJob, [response.uuid]);
                } catch (e) {
                    // Ignore error from trying to keep the file (this is a hack anyway)
                }
            }
            return response;
        }
    )
);

export const uploadFile: UploadMethod = async (f: File, i: FileInformation, r?: XMLHttpRequest) => {
    // Last Modified Date is deprecated from the FileAPI and is removed form Safari 10. Fallback to "now" if not present
    const lastModified = f.lastModifiedDate instanceof Date ? f.lastModifiedDate : new Date();

    const mtime = Math.floor(lastModified.getTime() / 1000);
    const path = i.targetFolder + '/' + f.name;
    const ah = await getServiceProvider().getAppServiceForJob(i.targetJob);
    return ah.uploadFile(i.targetJob, path, mtime, f, r);
};
export const deleteTrashFile = async (dispatch: Dispatch, fileID: FileID): Promise<void> => {
    dispatch(FilesActions.FileDeletionStarted(fileID));
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        await service.emptyTrashCan(fileID);
        dispatch(TrashFileDeleted(fileID));
    } catch (e) {
        dispatch(TrashFileDeleteFailed(fileID));
    }
};

export const deleteMultipleTrashFiles = async (dispatch: Dispatch, files: FileID[]): Promise<void> => {
        try {
            dispatch(TrashFilesDeletionStarted(files));
            dispatch(LongRunningTaskStarted('filesAreBeingDeleted'));
            const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
            const successFiles: FileID[] = [];
            const failedFiles: FileID[] = [];

            await managedPromiseAll(files, async (fileID) => {
                try {
                    await service.emptyTrashCan(fileID);
                    successFiles.push(fileID);
                } catch (e) {
                    failedFiles.push(fileID);
                }
            });

            const actions = withoutTheBools([
                LongRunningTaskFinished('filesAreBeingDeleted'),
                successFiles.length > 0 && TrashFilesDeletionSucceeded(successFiles),
                failedFiles.length > 0 && TrashFilesDeletionFailed(failedFiles),
            ]);

            dispatch(BulkOfActions(actions));
    } catch (e) {
        // nothing
    }
};

export const deleteFile = async (dispatch: Dispatch, jobID: JobID, fileID: FileID): Promise<void> => {
    try {
        dispatch(FilesActions.FileDeletionStarted(fileID));
        const service = await getServiceProvider().getAppServiceForJob(jobID);
        await service.deleteFile(jobID, fileID);
        dispatch(BulkOfActions([
            FileWasRemovedFromJob({jobID, fileID}),
            FilesDeletionSucceeded({jobID, files: [fileID]}),
        ]));
    } catch (e) {
        dispatch(FilesActions.FileDeletionFailed(fileID));
    }
};

export const deleteMultipleFiles = async (dispatch: Dispatch, jobID: JobID, files: FileID[]): Promise<void> => {
    try {
        dispatch(FilesDeletionStarted({jobID, files}));
        dispatch(LongRunningTaskStarted('filesAreBeingDeleted'));

        const service = await getServiceProvider().getAppServiceForJob(jobID);
        const successFiles: FileID[] = [];
        const failedFiles: FileID[] = [];

        await managedPromiseAll(files, async (fileID) => {
            try {
                await service.deleteFile(jobID, fileID);
                successFiles.push(fileID);
            } catch (e) {
                failedFiles.push(fileID);
            }
        });

        const actions = withoutTheBools([
            ...successFiles.map((fileID) => FileWasRemovedFromJob({jobID, fileID})),
            ...failedFiles.map((fileID) => FilesActions.FileDeletionFailed(fileID)),
            LongRunningTaskFinished('filesAreBeingDeleted'),
            successFiles.length > 0 && FilesDeletionSucceeded({jobID, files: successFiles}),
            failedFiles.length > 0 && FilesDeletionFailed({jobID, files: failedFiles}),
        ]);

        dispatch(BulkOfActions(actions));
    } catch (e) {
        // error handled by individual deleteFile
    }
};

export const restoreFile = async (dispatch: Dispatch, jobID: JobID, fileID: FileID): Promise<void> => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(jobID);
        await service.restoreFile(jobID, fileID);
        dispatch(FileWasRestored({jobID, fileID}));
    } catch (e) {
        dispatch(FileRestoreFailed({jobID, fileID}));
    }
};

export const restoreMultipleFiles = async (dispatch: Dispatch, jobID: JobID, files: FileID[]): Promise<void> => {
    try {
        dispatch(FilesRestorationStarted({jobID, files}));
        dispatch(LongRunningTaskStarted('filesAreBeingRestored'));
        const service = await getServiceProvider().getAppServiceForJob(jobID);
        const successFiles: FileID[] = [];
        const failedFiles: FileID[] = [];

        await managedPromiseAll(files, async (fileID) =>  {
            try {
                await service.restoreFile(jobID, fileID);
                successFiles.push(fileID);
            } catch (e) {
                failedFiles.push(fileID);
            }
        });

        const actions = withoutTheBools([
            LongRunningTaskFinished('filesAreBeingRestored'),
            successFiles.length > 0 && FilesRestorationSucceeded({jobID, files: successFiles}),
            failedFiles.length > 0 && FilesRestorationFailed({jobID, files: failedFiles}),
        ]);

        dispatch(BulkOfActions(actions));
    } catch (e) {
        // error handled by individual restoreFile
    }
};

export const downloadFiles = async (dispatch: Dispatch, jobID: JobID, files: FileID[]) => {
    if (files.length > 500) {
        dispatch(FilesActions.DownloadCountExceedsLimit(jobID));
    }
    else {
        try {
            const service = await getServiceProvider().getAppServiceForJob(jobID);
            if (files.length > 1) {
                await service.downloadFilesAsArchiveFromJob(jobID, files);
            } else {
                await service.downloadSingleFileFromJob(jobID, files[0]);
            }
            dispatch(FilesDownloadSuccess({jobID, files}));
        } catch (e) {
            dispatch(FilesDownloadFailed({jobID, files}));
        }
    }
};

export const downloadSingleFile = async (dispatch: Dispatch, jobID: JobID, file: FileID) => {
    return downloadFiles(dispatch, jobID, [file]);
};

export const fetchFileMetadata = async (dispatch: Dispatch, jobID: JobID, fileID: FileID): Promise<void> => {
    dispatch(FileMetadataFetchingStarted(fileID));
    try {
        const service = await getServiceProvider().getAppServiceForJob(jobID);
        const response = await service.getFileMetadata(jobID, fileID);
        const payload: FileMetadata = {
            fileID,
            deviceManufacturer: response.Make,
            deviceModel: response.Model,
            iso: response.ISOSpeedRatings,
            aperture: response.ApertureValue,
            exposure: response.ExposureTime,
            focalLength: response.FocalLength,
        };

        dispatch(FileMetadataWasFetched(payload));

    } catch (e) {
        dispatch(FileMetadataFetchingFailed(fileID));
    }
};

export const fetchConnectedDevices = async (dispatch: Dispatch): Promise<void> => {
    dispatch(FetchingConnectedDevicesStarted());
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        const response = await service.getConnectedDevices();

        dispatch(ConnectedDevicesWasFetched({
            currentDeviceID: response.current,
            devices: response.devices,
        }));
    } catch (e) {
        dispatch(FetchingConnectedDevicesFailed());
    }
};
export const removeConnectedDevice = async (dispatch: Dispatch, deviceID: string): Promise<void> => {
    dispatch(DeleteConnectedDeviceStarted({deviceID}));
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        await service.deleteConnectedDevice(deviceID);
        dispatch(ConnectedDeviceWasDeleted({deviceID}));
    } catch (e) {
        dispatch(DeleteConnectedDeviceFailed({deviceID}));
    }
};

export const createShareWithFiles = async (
    dispatch: Dispatch,
    name: string,
    files: AppJobFile[],
    password?: string,
): Promise<JobID|undefined> => {
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        const {id} = await service.createJob({
            name,
            public: 1,
            password,
        });
        await tryCopyFilesToJobCompletely(dispatch, id, files);
        dispatch(ShareWasCreated(id));
        return id;
    } catch (e) {
        dispatch(ShareCreationFailed());
    }
};

export const publishJobByEmail = async (
    dispatch: Dispatch,
    jobID: JobID,
    toEmail: string,
    message?: string,
): Promise<void> => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(jobID);
        await service.publishJob(jobID, {
            to_email: toEmail,
            message,
        });
        dispatch(JobWasPublished(jobID));
    } catch (e) {
        dispatch(JobPublishingFailed(jobID));
    }
};

export const loadTrashContent = async (dispatch: Dispatch, offset?: number) => {
    dispatch(TrashLoadingStarted());
    try {
        const service = await getServiceProvider().getAppServiceForLoggedInUserDefaults();
        const trashContent = await service.getDeletedFiles({offset});

        dispatch(TrashLoadingSucceeded(trashContent));
    } catch (e) {
        dispatch(TrashLoadingFailed());
    }
};
