import {Action, isType} from '../common/actions';
import {AlbumUnsubscriptionFailed} from '../currentUser/actions';
import {DownloadCountExceedsLimit, FileDeletionFailed} from '../files/actions';
import {SetFileAsCoverPhotoFailed} from '../job/actions';
import {JobDeletionFailed} from '../jobInfo/actions';
import {UnavailablePlanClicked} from '../storagePlan/actions';
import {UserActionAlertHandled, UserActionConfirmHandled, UserActionInitiated} from './actions';

export enum UserActionsStatus {
    PENDING_USER,     // User has initiated action (delete, unsubscribe, edit)
    PENDING_SERVER,   // User has confirmed action
    SERVER_ERROR,     // Something went wrong when trying to perform action
}

export enum UserActionTypes {
    DELETE_ALBUM,
    UNSUBSCRIBE_ALBUM,
    SET_COVER_PHOTO,
    DELETE_FILE,
    DELETE_DEVICE,
    DOWNLOAD_MORE_THAN_LIMIT,
    CANCEL_STORAGE_PLAN,
    CHANGE_STORAGE_PLAN,
    REACTIVATE_STORAGE_PLAN,
    UNAVAILABLE_PLAN_CLICKED,
}

export type PendingUserAction = {
    type: UserActionTypes,
    target: string,
};

export type UserActionsState = {
    pendingConfirm?: PendingUserAction,
    alerts: PendingUserAction[],
};

const initialState: UserActionsState = {
    alerts: [],
};

const newStateWidthAddedAlert = (state: UserActionsState, alert: PendingUserAction) => {
    return {
        ...state,
        alerts: state.alerts.concat(alert),
    };
};

export const userActionsReducer = (state: UserActionsState = initialState, action: Action<any>): UserActionsState => {

    if (isType(action, UserActionInitiated)) {
        return {
            ... state,
            pendingConfirm: action.payload,
        };
    }
    if (isType(action, UserActionConfirmHandled)) {
        return {
            ... state,
            pendingConfirm: undefined,
        };
    }
    if (isType(action, UserActionAlertHandled)) {
        return {
            ... state,
            alerts: state.alerts.slice(1),
        };
    }

    if (isType(action, JobDeletionFailed)) {
        return newStateWidthAddedAlert(state, {type: UserActionTypes.DELETE_ALBUM, target: action.payload});
    }
    if (isType(action, AlbumUnsubscriptionFailed)) {
        return newStateWidthAddedAlert(state, {type: UserActionTypes.UNSUBSCRIBE_ALBUM, target: action.payload});
    }
    if (isType(action, SetFileAsCoverPhotoFailed)) {
        return newStateWidthAddedAlert(state, {type: UserActionTypes.SET_COVER_PHOTO, target: action.payload});
    }
    if (isType(action, FileDeletionFailed)) {
        return newStateWidthAddedAlert(state, {type: UserActionTypes.DELETE_FILE, target: action.payload});
    }
    if (isType(action, DownloadCountExceedsLimit)) {
        return newStateWidthAddedAlert(state, {type: UserActionTypes.DOWNLOAD_MORE_THAN_LIMIT, target: action.payload});
    }
    if (isType(action, UnavailablePlanClicked)) {
        return newStateWidthAddedAlert(state, {type: UserActionTypes.UNAVAILABLE_PLAN_CLICKED, target: ''});
    }

    return state;
};
