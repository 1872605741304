import React from 'react';
import styled from 'styled-components';
import {StripePaymentInfoResponse} from '../../API/services/AppService';
import {_} from '../../assets/localizedStrings';
import {colors, fontSize} from '../../assets/styleConstants';
import {BorderedTextButton} from '../Common/IconTextButton';

const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 640px;
`;

const ChangePaymentInfoButtonWrapper = styled.div`
    text-transform: uppercase;
    display: inline-block;
`;
const InfoText = styled.div`
    margin: 16px 0;
    font-weight: bold;
`;
const Label = styled.div`
    font-size: ${fontSize.small_14};
    color: ${colors.captureGrey400};
`;
const PaymentInfo = styled.div`
    display: inline-block;
    margin-right: 48px;
    margin-bottom: 16px;
`;
const CardInfo = styled.div`
    margin: 8px 0;
    font-size: ${fontSize.medium_18};
    letter-spacing: 0.75px;
`;

type Props = {
    doUpdateCreditCardInfo: () => any,
    currentCardInfo: StripePaymentInfoResponse,
};

export const PaymentSetting: React.SFC<Props> = (props) => {
    const month = ('0' + props.currentCardInfo.exp_month).slice(-2);
    const year = props.currentCardInfo.exp_year.toString().slice(-2);

    return (
        <Wrapper>
            <InfoText>{_('payment_settings_info_text')}</InfoText>
            <div>
                <PaymentInfo>
                    <Label>{_('card_number')}</Label>
                    <CardInfo>XXXX XXXX XXXX {props.currentCardInfo.card}</CardInfo>
                </PaymentInfo>
                <PaymentInfo>
                    <Label>{_('card_exp_date')}</Label>
                    <CardInfo>{month}/{year}</CardInfo>
                </PaymentInfo>
            </div>
            <ChangePaymentInfoButtonWrapper>
                <BorderedTextButton
                    text={_('update_card')}
                    color={colors.captureBlue}
                    onClick={props.doUpdateCreditCardInfo}
                    hoverFillColor={colors.captureBlue50}
                />
            </ChangePaymentInfoButtonWrapper>
        </Wrapper>
    );
};
