import * as React from 'react';
import {colors} from '../../assets/styleConstants';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const CheckIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon fill={props.color || colors.defaultIconColor} fillRule="nonzero" points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"/>
        </g>
    </svg>
);

export const CheckFilledIcon: React.SFC<IconProps & {stroke?: string}> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path fill={props.stroke} d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z" />
            <path fill={props.color || colors.captureGrey50} d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M9.9374958,14.8875 L7.0499958,12 L6.0874958,12.9625 L9.9374958,16.8125 L18.1874958,8.5625 L17.2249958,7.6 L9.9374958,14.8875 Z" />
        </g>
    </svg>
);
