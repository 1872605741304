import {createSelector} from 'reselect';
import {ThumbService} from '../../API/services/ThumbService';
import {
    calcImagesPerRow, GridStyle,
} from '../../utilities/gridElementSizeCalculator';
import {AutoGeneratedAlbumInfo} from '../album/actions';
import {FileComment} from '../comments/reducer';
import {getCommentsGroupedByImage} from '../comments/selectors';
import {
    getCurrentAuthToken, getCurrentUserUUID, getSubscribedJobs,
} from '../currentUser/selectors';
import {JobFile} from '../files/reducer';
import {getFiles, getFilesByJob, getFirstFileIDByJob} from '../files/selectors';
import {getThumbHosts} from '../hosts/selectors';
import {JobInfo, JobInfoElement} from '../jobInfo/reducer';
import {getJobInfoElements} from '../jobInfo/selectors';
import {getIdOfLastSeenAlbum} from '../lastSeenElement/selectors';
import {getTotalLoveCountPerFile} from '../reaction/selectors';
import {State} from '../store';
import {getEnquedFiles, getFileInformationByUploaderId} from '../uploader/selectors';
import {getUsersInfo, UserInfo} from '../users/selectors';
import {AlbumListFilter, AlbumNumberOf} from './reducer';
import {getViewportWidth, isMobileMode} from '../viewMode/selectors';

const getAlbumDetailsList = (state: State) => state.albumList.albumDetailsList;

export type AlbumJobInfo = {
    id: JobID,
    dateCreated: Date,
    title: string,
    owner?: UserInfo,
    coverPhoto?: string,
    numberOf?: AlbumNumberOf,
    currentUserCan: AvailableActions,
    isShared: boolean,
};

export const getCoverPhotoIDByAlbum = createSelector(
    getFiles,
    getJobInfoElements,
    getFirstFileIDByJob,
    (
        files: DictionaryOf<JobFile>,
        jobInfos: JobInfoElement[],
        firstFileInAlbum: DictionaryOf<FileID>,
    ) => {
        const result: DictionaryOf<FileID> = {};
        jobInfos.forEach(({jobID, jobInfo}) => {
            if (jobInfo) {
                const {coverPhoto} = jobInfo;
                const firstFile = firstFileInAlbum[jobID];

                // Use given CoverPhoto if it is set
                // and (files are not yet loaded or we know the coverPhoto is not deleted)
                const useCoverPhoto = coverPhoto !== undefined
                    && (firstFile === undefined || files[coverPhoto] !== undefined);
                result[jobID] = useCoverPhoto ? (coverPhoto as FileID) : firstFile;
            }
        });

        return result;
    },
);

export const getCoverPhotoURLByAlbum = createSelector(
    getJobInfoElements,
    getCoverPhotoIDByAlbum,
    getThumbHosts,
    getCurrentAuthToken,
    (
        jobInfos: JobInfoElement[],
        coverPhotoID: DictionaryOf<FileID>,
        thumbHost: DictionaryOf<string>,
        authToken: string|undefined,
    ) => {
        if (!authToken) {
            return {};
        }
        const result: DictionaryOf<URLstring> = {};
        jobInfos.forEach(({jobID, jobInfo}) => {
            if (jobInfo) {
                if (coverPhotoID[jobID] && thumbHost[jobID]) {
                    const th = new ThumbService(thumbHost[jobID], authToken);
                    result[jobID] = th.getThumbUrl(jobID, coverPhotoID[jobID], 512);
                }
            }
        });

        return result;
    },
);

export type AvailableActions = {
    deleteAlbum: boolean,
    leaveAlbum: boolean,
    addPhotos: boolean,
    addComment: boolean,
    editAlbum: boolean,
};

export const getAvailableActions: (state: State) => DictionaryOf<AvailableActions> = createSelector(
    getJobInfoElements,
    getCurrentUserUUID,
    getSubscribedJobs,
    (jobInfo: JobInfoElement[],
     currentUser: UserID | undefined,
     subscribedJobIDs: JobID[],
    ) => {
        const result: DictionaryOf<AvailableActions> = {};
        jobInfo.forEach((job) => {
            if (!job.jobInfo) {
                return;
            }

            result[job.jobID] = {
                deleteAlbum: job.jobInfo.owner === currentUser,
                leaveAlbum: subscribedJobIDs.indexOf(job.jobID) > -1,
                addPhotos: job.jobInfo.owner === currentUser || job.jobInfo.allow_uploads,
                addComment: job.jobInfo.owner === currentUser || job.jobInfo.allow_comments,
                editAlbum: job.jobInfo.owner === currentUser,
            };
        });

        return result;
    },
);

export const getAlbumNumberOfs = createSelector(
    getJobInfoElements,
    getCommentsGroupedByImage,
    getFilesByJob,
    getTotalLoveCountPerFile,
    (jobInfo: JobInfoElement[],
     allComments: DictionaryOf<FileComment[]>,
     allFiles: DictionaryOf<JobFile[]>,
     loveCounts: DictionaryOf<number>,
    ) => {
        const result: DictionaryOf<AlbumNumberOf> = {};
        jobInfo.forEach((j) => {
            if (!j.jobInfo) {
                return;
            }

            const contributors: DictionaryOf<boolean> = {[j.jobInfo.owner]: true};
            const files: JobFile[] = allFiles[j.jobID] || [];
            let comments = 0;
            let loves = 0;

            files.forEach(
                (f) => {
                    contributors[f.addedBy] = true;
                    comments += (allComments[f.fileID] || []).length;
                    loves += (loveCounts[f.fileID] || 0);
                },
            );

            result[j.jobID] = {
                contributors: Object.keys(contributors).length,
                files: files.length,
                comments,
                loves,
            };
        });

        return result;
    },
);

export const getAlbumListFilter = (state: State) => state.albumList.filter;

export const privacyModeFilter: Record<AlbumListFilter, (album: AlbumJobInfo) => boolean> = {
    all: (_album) => true,
    shared: (album) => album.isShared,
    private: (album) => !album.isShared,
};

// used outside this scope in unit tests
export const getFullAlbumListInfo = createSelector(
    getJobInfoElements,
    getAlbumNumberOfs,
    getUsersInfo,
    getCoverPhotoURLByAlbum,
    getAvailableActions,
    getAlbumDetailsList, // TODO: refactor to replace jobInfo
    (infos, numbers, users, coverPhoto, currentUserCan, detailsList): AlbumJobInfo[] => {
        return infos
            .filter((info: JobInfoElement): info is JobInfoElement & {jobInfo: JobInfo} => {
                const isKnownEmptyJob = info.lastSeenSerial !== undefined
                    && (numbers[info.jobID] === undefined || numbers[info.jobID].files === 0);
                return info.jobInfo !== undefined && info.jobInfo.type === 'story' && !isKnownEmptyJob;
            })
            .sort((a, b) => b.jobInfo.mtime - a.jobInfo.mtime)
            .map((info) => {// TODO: refactor to replace jobInfo
                if (detailsList[info.jobID]) {
                    const d = detailsList[info.jobID];
                    return {
                        id: d.albumID,
                        title: d.title,
                        owner: users[d.owner.userID] || d.owner,
                        coverPhoto: coverPhoto[d.albumID],
                        dateCreated: new Date((d.ctime) * 1000),
                        numberOf: d.numberOf,
                        currentUserCan: currentUserCan[d.albumID],
                        isShared: d.isShared,
                    };
                }

                return {
                    id: info.jobID,
                    title: info.jobInfo.title,
                    owner: users[info.jobInfo.owner],
                    coverPhoto: coverPhoto[info.jobID],
                    dateCreated: new Date((info.jobInfo.ctime || info.jobInfo.mtime) * 1000),
                    numberOf: numbers[info.jobID],
                    currentUserCan: currentUserCan[info.jobID] || {},
                    isShared: info.jobInfo.isShared,
                };
            });
    },
);

export const getPrivacyModeFilteredAlbumList = createSelector (getFullAlbumListInfo, getAlbumListFilter,
    (
        albums: AlbumJobInfo[],
        filter: AlbumListFilter,
    ): AlbumJobInfo[] => {
        return albums.filter(privacyModeFilter[filter]);
});

export const getAlbumsCurrentUserCanAdd: (state: State) => AlbumJobInfo[] = createSelector(
    getFullAlbumListInfo,
    (albumList) => albumList.filter((album) => album.currentUserCan.addPhotos));

export const getNextUnfetchedAlbumID: (state: State) => JobID|undefined = createSelector(
    getFullAlbumListInfo,
    (albumList) => (
        albumList.filter((e) => e.numberOf ? e.numberOf.files === 0 : true).map((e) => e.id)[0]
    ),
);

export const getPendingAlbums = (state: State) => state.albumList.pendingAlbums;

type CompletedAlbumSelector = (state: State, uploaderFileId: number) => AutoGeneratedAlbumInfo|undefined;
export const getRecentlyCompletedAlbumFromUploadedFileID: CompletedAlbumSelector = createSelector(
    getFileInformationByUploaderId,
    getEnquedFiles,
    getPendingAlbums,
    (file, enqueuedFiles, albums) => {
        if (file) {
            const album = albums.filter((a) => a.tempID === file.targetJob)[0];
            if (album && enqueuedFiles.every((f) => f.targetJob !== file.targetJob || f.id === file.id)) {
                return album;
            }
        }
    },
);
export const isPendingAutoCreatedAlbum = (state: State, jobID: JobID): boolean => {
    return getPendingAlbums(state).some((a) => a.tempID === jobID || a.jobID === jobID);
};
export const havePendingAutoCreatedAlbums = (state: State): boolean => getPendingAlbums(state).length > 0;

const albumListStyleMobile = {
    elementHeight: 190,
    elementSpaceAround: 2,
};
const albumListStyleDesktop = {
    elementHeight: 320,
    elementWidth: 296,
    elementSpaceAround: 2,
};

const maxContainerWidth = 1500;

export const getAlbumListStyle = createSelector(
    getViewportWidth,
    isMobileMode,
    (screenWidth: number, isMobile: boolean): GridStyle => {
        if (isMobile) {
            return ({
                ...albumListStyleMobile,
                elementWidth: screenWidth - albumListStyleMobile.elementSpaceAround * 2,
                width: screenWidth,
            });
        } else {
            const availableContainerWidth = Math.min(maxContainerWidth, screenWidth);
            const elementsInOneRow = Math.floor(availableContainerWidth / albumListStyleDesktop.elementWidth);
            return ({
                ...albumListStyleDesktop,
                width: elementsInOneRow * (albumListStyleDesktop.elementWidth + albumListStyleDesktop.elementSpaceAround * 2),
            });
        }
    });

export const getLastViewedAlbumOffset: (state: State) => number|undefined = createSelector(
    getPrivacyModeFilteredAlbumList,
    getIdOfLastSeenAlbum,
    getAlbumListStyle,
    (albums, lastSeenAlbumID, gridStyle) => {
        if (lastSeenAlbumID) {
            const albumIndex = albums.map((a) => a.id).indexOf(lastSeenAlbumID);
            const rowHeight = gridStyle.elementHeight + (gridStyle.elementSpaceAround * 2);
            return (Math.floor(albumIndex / calcImagesPerRow(gridStyle)) * rowHeight);
        }
        return undefined;
    },
);
