import styled from 'styled-components';
import {colors, fontSize} from '../../assets/styleConstants';
import {makeFastScroller} from './FastScrollerContent';

export const GroupWrapper = styled.div`
    display:flex;
    flex-direction:column;
    margin-bottom: 32px;
    user-select: none;

    .scroller-item:focus {
        outline-style: unset;
    }
`;
const GroupHeader = styled.div`
    font-size: ${fontSize.small_14};
    margin-bottom: 8px;
`;

export const GroupItemContent = styled.div`
    position: relative;
    display: flex;
    height: 30px;
    width: 40px;
    font-size: ${fontSize.small_12};
    cursor: pointer;
    align-items: center;
    color: ${(props: {isSelected: boolean}) => props.isSelected ? colors.captureBlue : 'inherit'};

    &:hover {
        color: ${colors.captureGrey500};
    }
    .scroller-item & {
        border-width: 0 2px 0 0;
        border-right: 2px solid ${(props) => props.isSelected ? colors.captureBlue : colors.captureGrey300};
        ${(props) => props.isSelected ? `border-image: radial-gradient(${colors.captureBlue} 0%, ${colors.captureGrey300} 100%) 3 50%;` : '' };
    }
    .scroller-item:first-child & {
        border-image: linear-gradient(to top, ${colors.captureGrey300},${(props) => props.isSelected ? colors.captureBlue : colors.captureGrey300} 50%, transparent 50%) 1;
    }

    .scroller-item:last-child & {
        border-image: linear-gradient(to bottom, ${colors.captureGrey300}, ${(props) => props.isSelected ? colors.captureBlue : colors.captureGrey300} 50%, transparent 50%) 1 ;
    }

    .scroller-item:only-child & {
        border-right: 2px solid transparent; // helps align the dots
        border-image: none;
    }

    &:after {
        content:'';
        position: absolute;
        width: ${(props) => props.isSelected ? 4 : 2}px;
        height: ${(props) => props.isSelected ? 4 : 2}px;
        border: 2px solid ${(props) => props.isSelected ? colors.captureBlue : colors.captureGrey400};
        border-radius: 50%;
        background-color: ${(props) => props.isSelected ? colors.captureBlue : colors.captureGrey400};
        right: -1px;
        top: 50%;
        transform: translate(50%, -50%);
        transition: all 1s;
    }

    /* halo effect */

    &:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        box-shadow: inset 0px 0px 18px 0px rgba(${colors.captureBlueRGB}, 0.3);
        border-radius: 50%;
        right: -1px;
        top: 50%;
        transform: translate(50%, -50%);
        transition: opacity 1s;

        opacity: ${(props) => props.isSelected ? 1 : 0};
    }
`;

export const FastScroller = makeFastScroller(GroupWrapper, GroupHeader, GroupItemContent);
