import {createSelector} from 'reselect';
import {DeletedFile} from '../../API/services/AppService';
import {ThumbService} from '../../API/services/ThumbService';
import {cachedInArray, inArray} from '../../utilities/arrayUtils';
import {FileTarget, getFileTargetFromName} from '../../utilities/fileTarget';
import {getAuthTokenForJob} from '../currentUser/selectors';
import {ThumbSelector} from '../files/selectors';
import {getThumbHostForJob} from '../hosts/selectors';
import {State} from '../store';

const getAllDeletedFiles = (state: State) => state.trash.files;
const getProcessingFiles = (state: State) => state.trash.processingFiles;
export const isLoadingTrash = (state: State): boolean => state.trash.fetchState === 'pending';
export const shouldTrashBeFetched = (state: State): boolean => state.trash.fetchState === 'not_started';
export const getSelectedTrashFiles = (state: State): FileID[] => state.trash.selectedFiles;
export const getTrashFilesNextResultOffset = (state: State): number => state.trash.nextResultOffset;
export const hasMoreTrashFilesToFetch = (state: State): boolean => state.trash.lastResultCount === state.trash.limit;

const getDeletedFilesForJob: (state: State, jobID: JobID) => DeletedFile[] = createSelector(
    getAllDeletedFiles,
    getProcessingFiles,
    (_: State, jobID: JobID) => jobID,
    (allFiles: DeletedFile[], processingFiles: FileID[], jobID: JobID) => {
        const isInProcessing = cachedInArray(processingFiles);
        return allFiles.filter((f) => f.job === jobID && !isInProcessing(f.id));
    },
);

const makeThumbForTrashFilesSelector = (size: number): ThumbSelector => createSelector(
    getThumbHostForJob,
    getAuthTokenForJob,
    getAllDeletedFiles,
    (host: string | null, auth: string, files: DeletedFile[]) => {
        const thumbURLDictionary: DictionaryOf<string> = {};
        if (host) {
            const service = new ThumbService(host, auth);

            files.forEach((file: DeletedFile) => {
                const {id, job, path} = file;
                if (getFileTargetFromName(path) !== FileTarget.Documents) {
                    thumbURLDictionary[id] = service.getThumbUrl(job, id, size);
                }
            });
        }

        return thumbURLDictionary;
    },
);

export type TrashFile = {
    id: FileID,
    jobID: JobID,
    name: string,
    deletedTime: Date,
    thumbnail?: string,
    isSelected: boolean,
};
export const getTrashFilesForJob: (state: State, jobID: JobID) => TrashFile[] = createSelector(
    getDeletedFilesForJob,
    makeThumbForTrashFilesSelector(256),
    getSelectedTrashFiles,
    (files, thumbs, selectedFiles) => {
        return files.map(({id, job, path, dtime}) => ({
            id,
            jobID: job,
            name: path,
            deletedTime: new Date(dtime * 1000),
            thumbnail: thumbs[id],
            isSelected: inArray(selectedFiles, id),
        }));
    },
);
