import * as React from 'react';
import {IconProps} from './index';

/* tslint:disable:max-line-length jsx-self-close */
export const MailIcon: React.SFC<IconProps> = (props) => (
    <svg width={props.size} height={props.size} viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="mail-linearGradient-1">
                <stop stopColor="#FF73C7" offset="0%" />
                <stop stopColor="#FF3F95" offset="100%" />
            </linearGradient>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="url(#mail-linearGradient-1)" x="0" y="0" width={props.size} height={props.size} rx="3" />
            <path fill="#FFFFFF" fillRule="nonzero" d="M37.7,11 L11.3,11 C9.485,11 8.0165,12.4625 8.0165,14.25 L8,33.75 C8,35.5375 9.485,37 11.3,37 L37.7,37 C39.515,37 41,35.5375 41,33.75 L41,14.25 C41,12.4625 39.515,11 37.7,11 Z M37.7,33.75 L11.3,33.75 L11.3,17.5 L24.5,25.625 L37.7,17.5 L37.7,33.75 Z M24.5,22.375 L11.3,14.25 L37.7,14.25 L24.5,22.375 Z" />
        </g>
    </svg>
);
